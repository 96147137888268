import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { SpaceService } from 'app/modules/shared/services/space.service';


@Component({
    selector: 'workshop-index-planner',
    templateUrl: 'workshop-index.component.html',
    styleUrls:['./workshop-index.component.css']
})
    
export class WorkshopIndexPagePlanner implements OnInit
{

    ngOnInit()
    {
    }

    constructor(public spaceService: SpaceService, public router: Router) {
    }
}
