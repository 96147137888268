import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReviewService } from 'app/modules/shared/services/review.service';
import { NotificationService } from 'app/modules/shared/services/notification.service';
import { PropertyManagerReview } from 'app/modules/shared/models/users/property-manager-review';
import { TimeAllocation } from 'app/modules/shared/models/data-classes/time-allocation';
import { NotificationType } from 'app/helpers/enums/notificationType';
import { SpaceBookingService } from '../../../shared/services/space-booking.service';
import { WorkshopBookingService } from '../../../shared/services/workshop-booking.service';
import { SpaceService } from '../../../shared/services/space.service';
import { WorkshopService } from '../../../shared/services/workshop.service';
import { AuthService } from '../../../shared/services/auth.service';

@Component({
    selector: 'review-manager',
    templateUrl: 'review-property-manager.component.html'
})

export class ReviewPropertyManager implements OnInit {

    constructor(
        private route: ActivatedRoute,
        public reviewService: ReviewService,
        private router: Router,
        private notificationService: NotificationService,
        private bookingService: SpaceBookingService,
        private wsBookingService: WorkshopBookingService,
        private spaceService: SpaceService,
        private workshopService: WorkshopService,
        private authService: AuthService
       ) { }

    get review(): PropertyManagerReview {
        return this.reviewService.propertyManagerReview;
    }

    set review(value: PropertyManagerReview) {
        this.reviewService.propertyManagerReview = value;
    }

    ngOnInit(): void {
        if (!this.authService.isLoggedIn())
            this.router.navigate(['']);
        var urlSegs = this.route.snapshot.url;
        console.log(urlSegs);
        if (urlSegs[0].path == "review-experience-booking") {
            var bookingId = parseInt(urlSegs[1].path);
            this.reviewService.space = null;
            this.wsBookingService.getBooking(bookingId).subscribe(
                res => {
                    if (res.reviewedByEventPlanner) {
                        this.notificationService.notify(NotificationType.Error, "Thank you, this booking is already reviewed.", "Booking Already Reviewed");
                        this.router.navigate(['']);
                    }
                    this.reviewService.propertyManagerReview = new PropertyManagerReview();
                    this.reviewService.propertyManagerReview.workshopBooking = res;
                    this.workshopService.getWorkshop(res.workshopID).subscribe(
                        workshop => {
                            this.reviewService.propertyManagerReview.propertyManagerID = workshop.propertyManager.id;
                            this.reviewService.workshop = workshop;
                        }
                    );

                }
            );
        }
        else if (urlSegs[0].path == "review-booking") {
            var bookingId = parseInt(urlSegs[1].path);
            this.reviewService.workshop = null;
            this.bookingService.getBooking(bookingId).subscribe(
                res => {
                    if (res.reviewedByEventPlanner) {
                        this.notificationService.notify(NotificationType.Error, "Thank you, this booking is already reviewed.", "Booking Already Reviewed");
                        this.router.navigate(['']);
                    }
                    this.reviewService.propertyManagerReview = new PropertyManagerReview();
                    this.reviewService.propertyManagerReview.booking = res;
                    this.spaceService.getSpace(res.spaceID).subscribe(
                        space => {
                            this.reviewService.propertyManagerReview.propertyManagerID = space.propertyManager.id;
                            this.reviewService.space = space;
                        }
                    );

                }
            );

        }
/*        this.reviewService.workshop = null;
        this.reviewService.space = this.space;

        this.reviewService.propertyManagerReview = new PropertyManagerReview();
        this.reviewService.propertyManagerReview.propertyManagerID = this.space.propertyManager.id;
        this.reviewService.propertyManagerReview.booking = this.booking;
        this.router.navigate(['review-property-manager']);*/
    }

    formatTimeAllocationString(ta: TimeAllocation): string
    {
        var result = "";
        var startDate: Date = new Date(ta.startTime);
        var endDate: Date = new Date(ta.endTime);
        result += startDate.toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: '2-digit'});
        result += " ";
        result += startDate.toLocaleString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false });
        result += " - "
        result += endDate.toLocaleString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false });
        return result;
    }

    submit() 
    {
        this.reviewService.reviewPropertyManager().subscribe(
            success => {
                this.notificationService.notify(NotificationType.Success, "Booking Reviewed", "Thank you for reviewing your past booking!");
                this.router.navigate(['/event-planner/reservations']);
            })
    }
}
