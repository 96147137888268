import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WorkshopForTile } from '../../models/search/workshop-for-tile';
import { AuthService } from '../../services/auth.service';
import { faUsers, faClock } from '@fortawesome/free-solid-svg-icons';
import { WorkshopBookingService } from '../../services/workshop-booking.service';

@Component({
    selector: 'workshop-search-result-tile',
    templateUrl: 'workshop-search-result-tile.component.html',
    styleUrls: ['workshop-search-result-tile.component.scss']
})

export class WorkshopSearchResultTile implements OnInit {

    //fa 
    faUsers = faUsers;
    faClock = faClock;

    public workshopphotourl: string;
    public currentphotoindex: number;
    @Input('workshopForTile') workshopForTile: WorkshopForTile;

    constructor(
        private router: Router,
        private workshopBookingService: WorkshopBookingService,
        public authService: AuthService
    ) { }

    ngOnInit(): void
    {
        if (this.workshopForTile != null &&
            this.workshopForTile.workshop.photoUrls != null &&
            this.workshopForTile.workshop.photoUrls.length > 0) {

            this.workshopphotourl = this.workshopForTile.workshop.photoUrls[0];            
        }
    }

    public Home()
    {
        if (this.authService.userType == "EventPlanner" && this.authService.isLoggedIn()) {
            this.workshopBookingService.startNewBooking(this.workshopForTile.workshop.id, true);
        }
        else {
            this.router.navigate(['experience-details/' + this.workshopForTile.workshop.id]);
        }
    }


}

