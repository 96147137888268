import { Injectable } from "@angular/core";
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
  } from "@angular/common/http";
import { Observable } from "rxjs";
import { Entertainment } from "app/modules/shared/models/entertainment/AddEntertainmentStep1";


@Injectable()
export class EntertainmentService{

    constructor(private _http: HttpClient) {}

    getPerfectEntertainments():Observable<Entertainment[]>{
        return this._http.get<Entertainment[]>("API/Entertainment/PerfectEntertainments");
    }

    getUniqueEntertainments():Observable<Entertainment[]>{
        return this._http.get<Entertainment[]>('API/Entertainment/uniqueEntertainments');
    }

    getNearbyEntertainments(lat:any=14.25,long:any=11.25):Observable<Entertainment[]>{
        return this._http.post<Entertainment[]>('API/Entertainment/GetNearbyEntertainments',{
            "latitude" : lat,
            "longitude" : long
        })
    }

}