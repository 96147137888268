import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class UploadService {

    constructor(private http: HttpClient) { }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    public uploadImage(coverImage: FormData): Observable<string> {
        return this.http.post<string>('api/upload/image', coverImage).
            pipe(
                map(res => { return res }),
                catchError(this.errorHandler)
            )
    }

    public uploadImages(images: FormData): Observable<string[]> {
        console.log('images upload',images);
        
        return this.http.post<string[]>('api/upload/images', images).
            pipe(
            map(res => {
                    console.log("RES:" + res);  
                    return res;
                }),
                catchError(this.errorHandler)
            )
    }

    public deleteImage(imageURL: string): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<boolean>("api/upload/delete-image", JSON.stringify(imageURL), httpOptions).
            pipe(
                map(res => {
                    return res
                }),
                catchError(this.errorHandler)
            )
    }
}