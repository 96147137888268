import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ContactUs } from "../models/users/contact-us";

@Injectable()
export class ContactUsService {

    constructor(
        private http: HttpClient
    ) {}

    contactUs(cu: ContactUs) {
        
        return this.http.post("api/messaging/sendemailcontactform", cu)
            .pipe(
                map(
                    () => {
                        return true;
                    },
                err => { console.log(err.Message) }
                )
            )
    }
}