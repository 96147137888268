import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Amenity } from 'app/modules/shared/models/properties/Amenity';
import { EbentoSpace } from 'app/modules/shared/models/properties/ebento-space';
import { SpaceForTile } from 'app/modules/shared/models/search/space-for-tile';
import { ReviewService } from 'app/modules/shared/services/review.service';
import { MessagingService } from 'app/modules/shared/services/messaging.service';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { ConfirmationModalsComponent } from 'app/modules/shared/modules/modals/components/confirmation-modals/confirmation-modals.component';
import { PropertyManagerReview } from 'app/modules/shared/models/users/property-manager-review';
import { EbentoMessage } from 'app/modules/shared/models/messaging/ebento-message';
import { WorkshopService } from 'app/modules/shared/services/workshop.service';
import { WorkshopBooking } from 'app/modules/shared/models/booking/workshop-booking';
import { EbentoWorkshop } from 'app/modules/shared/models/workshops/ebento-workshop';
import { WorkshopBookingService } from 'app/modules/shared/services/workshop-booking.service';
import { CancellationRule } from '../../../shared/models/properties/cancellation-rule';
import { SearchService } from '../../services/search.service';
import { EbentoModalService } from '../../../shared/services/ebento-modal.service';
import { PropertyService } from '../../../shared/services/property.service';
import { SpaceService } from '../../../shared/services/space.service';

@Component({
    selector: 'workshop-reservation-tile',
    templateUrl: './workshop-reservation-tile.component.html',
    styleUrls: ['./workshop-reservation-tile.component.scss']
})
export class WorkshopReservationTileComponent implements OnInit {

    @Input() booking: WorkshopBooking
    @Input() isActive: boolean
    @Output() bookingCancelled = new EventEmitter<boolean>();
    @Input() history:string='false';

    viewDetails:boolean = false;

    public bookingAmenities: Amenity[] = []
    public cateringAmenities: Amenity[] = []
    public workshop: EbentoWorkshop
    public space: EbentoSpace
    cancellationRules: CancellationRule[]

    constructor(
        public workshopService: WorkshopService,
        public searchService: SearchService,
        public spaceService: SpaceService,
        public propertyService: PropertyService,
        public workshopBookingService: WorkshopBookingService,
        private reviewService: ReviewService,
        public router: Router,
        private modalService: EbentoModalService,
        public messagingService: MessagingService,
        public authService: AuthService,
    ) {
        
     }

    ngOnInit() {
        
        this.workshopService.getWorkshop(this.booking.workshopID).subscribe(
            w => {
                this.workshop = w;                
                this.populateAmenities();
            },
            err => console.error(err)
        );

        this.workshopBookingService.getHistoryCancellationRules(this.booking.idBooking).subscribe(
            res => this.cancellationRules = res,
            err => console.error(err)
        )
    }

    viewMoreDetails(){
        this.viewDetails = !this.viewDetails;
    }
    //TODO: make full bookingAmenity that extends Amenity?
    private populateAmenities(): void {
        this.bookingAmenities = []
        for (let amenity of this.workshop.amenities) {
            for (let bookingAmenity of this.booking.bookingAmenities) {
                if (amenity.amenitytype.id == bookingAmenity.amenityTypeID) {
                    if(amenity.amenitytype.isCatering){
                        this.cateringAmenities.push(amenity);
                    }else{
                        this.bookingAmenities.push(amenity)
                    }
                }
            }
        }

        // console.log('history + amenities',this.bookingAmenities,this.history);
        
    }

    public bookingDetails(): void
    {
        this.router.navigate(["/experience-booking-details/" + this.booking.idBooking])
    }

    public modifyBooking(): void {
        /*if (!this.spaceForTile) {
            this.spaceForTile = new SpaceForTile()
            this.spaceForTile.space = this.space;
            this.spaceForTile.address = this.space.address;
            this.spaceForTile.propertyname = this.space.propertyName;
            this.spaceForTile.spacephotourl = this.space.photoUrls[0];
            this.spaceForTile.propertyphotourl = this.space.propertyPhotoUrl;
        }
        this.searchService.workshopForTile = this.workshopForTile;
        */this.router.navigate(["/event-planner/modify-experience-booking/" + this.booking.idBooking])
    }

    public viewPendingModification(): void {
        this.router.navigate(["/event-planner/pending-experience-modify-request/" + this.booking.pendingModificationID]);
    }


    public cancelBooking(): void {
        let startTime = new Date(this.booking.timeAllocations[0].startTime).getTime()
        let dateNow = Date.now()
        let msDelta = startTime - dateNow
        const msInDay = 1000 * 60 * 60 * 24

        let percentageToReturn = 100

        if (this.workshop.cancellationRules && this.workshop.cancellationRules.length > 0) {
            var lastCR = this.cancellationRules[this.workshop.cancellationRules.length - 1]
            if (lastCR.daysFrom * msInDay > msDelta) {
                //if last cr < deltaTime take its percentag
                for (var i = this.workshop.cancellationRules.length - 1; i >= 0; i--) {
                    let cr = this.workshop.cancellationRules[i]
                    if (cr.daysTo * msInDay < msDelta) {
                        percentageToReturn = cr.percentageToReturn;
                        break;
                    }
                }
            }
        }
        let returnAmount = this.booking.price.totalWithDiscount * percentageToReturn / 100;
        let chargeAmount = this.booking.price.totalWithDiscount - returnAmount;
        console.log('return amount is: ', returnAmount)

        const modalRef = this.modalService.open(ConfirmationModalsComponent);
        modalRef.componentInstance.title = 'Confirm cancellation';

        modalRef.componentInstance.body =
            'Are you sure you want to cancel '
            + this.workshop.name + '. '
            + 'If you cancel this reservation you will be charged for amount of '
        + chargeAmount + ' CHF';
        modalRef.componentInstance.actionName = 'Cancel Booking';

        modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            if (receivedEntry == 'confirmed') {
                this.workshopBookingService.cancelBooking(this.booking.idBooking).subscribe(
                    res => {
                        if (res) { this.bookingCancelled.emit(true); }
                    }
                );
                this.modalService.close();
            };
        })
    }

    leaveReview() {
        // this is a bit ugly. refactor?
        this.reviewService.workshop = this.workshop;
        this.reviewService.space = null;

        this.reviewService.propertyManagerReview = new PropertyManagerReview();
        this.reviewService.propertyManagerReview.propertyManagerID = this.workshop.propertyManager.id;
        this.reviewService.propertyManagerReview.workshopBooking = this.booking;
        this.router.navigate(['review-property-manager']);
    }

    sendMessage() {
        this.authService.getManagerInfoByID(this.booking.propertyManagerID).subscribe(
            res => this.messagingService.currentRecepient = res);
        this.messagingService.currentDraft = new EbentoMessage();
        this.router.navigate(['/messages/compose']);
    }

    redirectToPropertyManager() {
        this.propertyService.getProperty(this.booking.propertyID)
            .subscribe(
                () => this.router.navigate(['/property-details']));
    }

    twoDigits(a) {
        return Math.round(parseFloat(a) * 100) / 100;
    }
}
