import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "ebento-list-service",
  templateUrl: "./list-service.component.html",
  styleUrls: ["./list-service.component.scss"],
})
export class ListService {
  accordion1: boolean = true;
  accordion2: boolean = true;
  accordion3: boolean = true;
  accordion4: boolean = true;
  accordion5: boolean = true;
  accordion6: boolean = true;
  accordion7: boolean = true;
  accordion8: boolean = true;

  accordions = [
    {
      header: "Who is a Service Provider?",
      body: `A service provider is a business or individual who offers a service,
        such as an event space, an experience/workshop, or an entertainment
        service, to customers or clients for a fee. In the context of Ebento, a
        service provider would be someone who lists their services on the
        platform and connects with event planners to provide those services.`,
    },
    {
      header: "Do I pay for creating my profile/business on Ebento?",
      body: "Registering as a Service Provider on Ebento is completely free of charge. No credit card required.",
    },
    {
      header: "How do I get paid for my services on Ebento?",
      body: ` Ebento will act as an agent for the collection of your payments. The
        Event Planner is charged in full through our payment provider Stripe. As
        soon as the Invoice Amount is received by Ebento we will transfer the
        Invoice Amount paid by the Event Planner minus the Ebento fees (booking
        fee of 5%) that is charged to the Event Planners. Transfers are done via
        direct deposit.`,
    },
    {
      header:
        "After the beta period, what is the cost of listing my services on Ebento?",
      body: `At Ebento, we offer two options for service providers: 'Instant Bookings' and 'Booking by Inquiry'.
      <br>
        With 'Instant Bookings', there are no listing fees, we operate on a commission-based pricing model. You only pay a 10% fee when you successfully receive a booking through Ebento. This allows you to showcase your offerings to a wider audience without upfront costs.
        
        For 'Booking by Inquiry', we offer a flexible pay-per-use pricing plan. You only pay for 'Active' listings. Here is our pricing structure:
        <br/>
        - 1 Active listing: 15 CHF per month <br/>
        - 2 Active listings: 20 CHF per month <br/>
        - 3 Active listings: 25 CHF per month <br/>
        - 4 Active listings: 30 CHF per month <br/>
        - 5 Active listings: 35 CHF per month <br/>

        You will be charged based on the number of 'Active' listings you have each month. Your billing cycle starts when you have set at least 1 listing to ‘Active’. Your billing cycle is 30 days and your invoice amount at the end of the billing cycle is calculated on the basis of the number of listings you have set to ‘active’. You have the flexibility to set your listings from 'Active' to 'Inactive' at any time. However, if a listing is set to 'Active' for even a part of the month, you will be charged for the entire month.`,
    },
    {
      header: "Do I have to pay for Ebento during the Beta period?",
      body: `During the beta period (March 2023 - September 2023), if you choose the 'Instant Booking' option, you can receive unlimited bookings without paying any commission fees. If you opt for 'Booking by Inquiry', you can create as many services as you want during the trial period without any charges. We want to give you the opportunity to explore and experience the benefits of Ebento without any financial commitment.`,
    },
    {
      header: "What is meant by an 'Active' listing?",
      body: `An "Active" listing on Ebento is readily visible and bookable by Event Planners. As a Service Provider, you have the flexibility to set your listings to "Inactive" for any reason. When a listing is marked as "Inactive," it will no longer be visible or bookable by Event Planners. It's important to note that Service Providers are solely responsible for managing the status of their listings and ensuring they are accurately set to "Active" or "Inactive." Ebento cannot be held liable for any listings that are not correctly managed in this regard.`,
    },
    {
      header: "Does Ebento offer a trial-period for Service Providers?",
      body: `Yes, Ebento offers a 1-month trial period for Service Providers. During this trial period, if you choose the 'Instant Booking' option, you can receive unlimited bookings without paying any commission fees. If you opt for 'Booking by Inquiry', you can create as many services as you want during the trial period without any charges. We want to give you the opportunity to explore and experience the benefits of Ebento without any financial commitment.
      After the 1-month trial period, only applicable to ‘Booking by Inquiry’,  your billing cycle starts when you have set at least 1 listing to ‘Active’. Your billing cycle is 30 days and your invoice amount at the end of the billing cycle is calculated on the basis of the number of listings you have set to ‘active’. Please see pricing details for more information on our flexible pay-per-use pricing plan.`,
    },
    {
      header: "What is Ebento and how can it help me as a service provider?",
      body: `Ebento is a platform that connects event planners to event space
      managers, experience or workshop providers, and entertainment service
      providers. It provides a one-stop-shop for all event planning needs. As
      a service provider, you can showcase your services to a broader
      audience, gain exposure, and grow your business through our platform.`,
    },
  ];

  constructor(private router: Router) {}

  visibleAccordion(type: string) {
    switch (type) {
      case "accordion1":
        this.accordion1 = !this.accordion1;
        break;
      case "accordion2":
        this.accordion2 = !this.accordion2;
        break;
      case "accordion3":
        this.accordion3 = !this.accordion3;
        break;
      case "accordion4":
        this.accordion4 = !this.accordion4;
        break;
      case "accordion5":
        this.accordion5 = !this.accordion5;
        break;
      case "accordion6":
        this.accordion6 = !this.accordion6;
        break;
      case "accordion7":
        this.accordion7 = !this.accordion7;
        break;
      case "accordion8":
        this.accordion8 = !this.accordion8;
        break;
      default:
        break;
    }
  }

  proceedToSignup() {
    this.router.navigate(["/register-common/PropertyManager"]);
  }
}
