import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { map, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { Invoice } from "../models/users/invoice";
import { CreditCard } from "../models/users/credit-card";
import { BankAccount } from "../models/users/bank-account";


@Injectable()
export class SubmissionService {

    public submissionInProgress: boolean = false;

    constructor(
    ) { }

    public startSubmission() {
        console.log("start!");
        this.submissionInProgress = true;
    }

    public endSubmission() {
        console.log("end.");
        this.submissionInProgress = false;
    }

}