import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'admin-review-star-cell',
  templateUrl: './admin-review-star-cell.component.html',
  styleUrls: ['./admin-review-star-cell.component.scss']
})
export class AdminReviewStarCellComponent implements ICellRendererAngularComp {
    public params: any;

    constructor() { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return true;
    }

}
