import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MessagingService } from "app/modules/shared/services/messaging.service";
import { NotificationService } from "app/modules/shared/services/notification.service";
import { NotificationType } from "app/helpers/enums/notificationType";


@Component({
    selector: "verify",
    templateUrl: "verify-by-email.component.html",
    styleUrls: ['../../../../shared/css/user.css']
})
export class VerifyByEmail {

    email: string;
    action: string;
    errorMessage: string = "";
    showSupportDialog = false;
    supportMessageSent = false;
    supportMessageText = "";
    

    constructor(
        private activatedRouter: ActivatedRoute, public messagingService: MessagingService, private notificationService: NotificationService
    ) { }

    ngOnInit() {
        this.activatedRouter.params.subscribe(params => {
            this.email = params['email'];
            this.action = params['action'];
        });
    }

    openSupportDialog()
    {
        this.showSupportDialog = true;
        //console.log("Uh");
    }

    sendMessage()
    {
        this.showSupportDialog = false;
        this.supportMessageSent = true;

        this.messagingService.sendEmailSupportMessage(this.email, this.supportMessageText).subscribe(
            success => {
                if (success)
                    this.notificationService.notify(NotificationType.Success, "Message sent to Ebento Support Team. Thank you for your patience!", "Support Request Sent");
                else
                    this.notificationService.notify(NotificationType.Error, "Message not sent. Please try again later", "Message Error");
            }
        );
    }
}