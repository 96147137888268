/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input, Inject, InjectionToken, Injector, ReflectiveInjector, Injectable, OnChanges, SimpleChanges, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CarouselTile } from '../carousel-tile/carousel-tile.component';
import { InjectableItem } from './injectable-item';
import { concat } from 'rxjs/operators';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'index-carousel',
    templateUrl: 'index-carousel.component.html',
    styleUrls: ['./index-carousel.component.css']
})

export class IndexCarousel implements OnInit {

    // Override this
    @Input() color:string = '#212529';
    @ViewChild('carousel', { read: NgbCarousel }) carousel: NgbCarousel;
    @Input() headerLabel = 'Carousel';
    items;
    tests = [{ x:'y'},{ x:'y'},{ x:'y'}]
    public nplets = [];
    myInjector: Injector;
    public myInjectors: Injector[];
    domID = 'domID';
    public numbersOfNplets(n = 3): number[]
    {
        if (this.items == null)
            return null;
        var non = new Array();
        for (var i = 0; i < Math.ceil(this.items.length / n); i++) {
            non.push(i);
        }
        return non;
    }
    public errorMessage: string;

    // Override this
    protected tileComponent: any = CarouselTile;

    constructor(private injector: Injector) {
        this.getScreenSize();
    }

    // Override this
    get apiGetter()
    {
        return null;
    }

    // Override this ?
    itemsFromResult(items) {
        return items;
    }

    public calculateNumberOfNplets()
    {
        
    }
    ngOnInit()
    {
        if (this.apiGetter != null)
        this.apiGetter.subscribe(
            success => {
                if (success) {
                    console.log("success...", success);
                   // console.log("LLL" + JSON.stringify(success));
                    this.items = this.itemsFromResult(success);
                    this.buildInjectors();
                }
            },
            error => { this.errorMessage = error; console.error("fail. " + this.errorMessage); }
        );
    }

    buildInjectors() {
        var itemIndex = 0;
        this.myInjectors = new Array<Injector>();

        for (let item of this.items)
        {
            item.itemIndex = itemIndex;
            this.myInjectors.push(Injector.create([
                { provide: InjectableItem, useValue: new InjectableItem(item) }
            ], this.injector));
            itemIndex++;
        }
    }

    public GetNplet(index: number, n = 3) {
        var result = new Array();
        var i = 0;
        while (i < n && i + index < this.items.length) {
            var item = this.items[i + index];
            item.parentMethod = (arg) => this.invokable(arg);
            result.push(item);
            i++;
        }
        return result;
    }

    public invokable(arg)
    {
        //console.log("Invoked: " + JSON.stringify(arg));
    }


    injectItem(item)
    {
        if (item == null || this.myInjectors == null)
            return null;
        return this.myInjectors[item.itemIndex];
        // ReflectiveInjector.resolveAndCreate([{ provide: (new InjectionToken<any>('item')), useValue: item }], this.injector);
    }

    scrHeight: any;
    scrWidth: any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
       // console.log(this.scrHeight, this.scrWidth);
    }

    getColumnCount()
    {
        return (this.scrWidth >= 992) ? 3 : (this.scrWidth >= 773) ? 2 : 1;
    }

    public swipeLeft()
    {
        this.carousel.next();
    }

    public swipeRight() {
          this.carousel.prev();
    }
}
