/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input, Injector } from '@angular/core';
import { Router } from "@angular/router"; 
import { CityTile } from 'app/modules/shared/models/search/city-tile';
import { GeolocationService } from 'app/modules/shared/services/geolocation.service';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { SearchSpaceQuery } from 'app/modules/shared/models/search/search-space-query';
import { PropertyAddress } from 'app/modules/shared/models/properties/property-address';
import { SearchService } from 'app/modules/event-planner/services/search.service';
import { IndexCarousel } from '../index-carousel.component';
import { SearchCarouselTile } from '../../carousel-tile/search-tile/search-carousel-tile.component';
import { WorkshopTypeTile } from 'app/modules/shared/models/search/workshop-type-tile';
import { SearchWorkshopQuery } from 'app/modules/shared/models/search/search-workshop-query';
import { WorkshopService } from 'app/modules/shared/services/workshop.service';

@Component({
    selector: 'workshops-by-type',
    templateUrl: '../index-carousel.component.html'
})

export class WorkshopsByType extends IndexCarousel implements OnInit {

    @Input() color:string;
    @Input() headerLabel = 'Experiences for Everyone';
    protected tileComponent = SearchCarouselTile;
    domID = 'workshops-by-type-';

    constructor(private geolocation: GeolocationService, private router: Router, public workshopService: WorkshopService, public searchService: SearchService, injector: Injector) {
        super(injector);
    }

    get apiGetter() {
        return this.workshopService.getHomepageWorkshopTypeTags();
    }

    public invokable(typeTile: WorkshopTypeTile) {
        var query = new SearchWorkshopQuery(null, true,  null, null, undefined, [], [], [], [{ id: typeTile.id, name: typeTile.name, isPublic: true, childTags:[] }],[]);
        this.searchService.searchWorkshopQuery = query;
        this.searchService.findWorkshops().subscribe(
            success => {
                this.router.navigate(['experience-search-results']);
            },
            error => { console.error(error.message) }
        );
    }

}
