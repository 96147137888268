import { Component } from '@angular/core';


@Component({
    selector: 'space-list',
    templateUrl: 'space-list.component.html',
    styleUrls: ['./space-list.component.css']
  })

  export class SpaceListComponent{

  }