import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';

import { Injectable, OnInit } from "@angular/core";
import { map, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { EbentoUser } from "../models/users/ebento-user";
import { UploadService } from "./upload.service";
import { MessagingService } from "./messaging.service";
import { PropertyManager } from "../models/users/property-manager";
import { EventPlanner } from "../models/users/event-planner";
import { FavoritesService } from "app/modules/event-planner/services/favorites.service";
import { Booking } from "../models/booking/booking";

@Injectable()
export class AuthService {

    public user: EbentoUser
    public token: string
    private tokenExpiration: Date
    public userType: string
    public loginRedirectType: LoginRedirectType
    public loginRedirectData: string

    constructor(
        private http: HttpClient,
        private uploadService: UploadService,
        public messagingService: MessagingService,
        private favoritesService: FavoritesService
    ) { }

 
    public setLoginRedirectParams(_type: LoginRedirectType, _data: string): void
    {
        this.loginRedirectType = _type;
        this.loginRedirectData = _data;
    }

    public getUserName(): string {
        if (this.user) {
            if (this.user.firstName && this.user.lastName)
                return this.user.firstName + " " + this.user.lastName;
            return this.user.email;
        }
        return '';
    }

    public getUserAvatarURL(): string {
        if (this.user && this.user.avatarURL != null && this.user.avatarURL != "") {
            return this.user.avatarURL;
        }
        return 'assets/images/profile.png';
    }

    public isLoggedIn(): boolean {
        this.getTokenFromLocalStorage();
        if (this.token && this.token.length > 0
            && this.tokenExpiration
            && this.tokenExpiration > new Date()) {

            if (!this.user) {
                this.getUserInfo()
            }
            return true;
        }
        return false;
    }

    private setSession(tokenInfo): void {
        localStorage.setItem('id_token', tokenInfo.token);
        localStorage.setItem('expiration', tokenInfo.expiration);
        localStorage.setItem('user_type', tokenInfo.userType);
    }

    public login(creds) {
        return this.http.post("/Account/Login", creds)
            .pipe(
                switchMap(
                    (response: any) => {
                        this.setSession(response);
                        this.messagingService.unloadMessages();
                        this.messagingService.startNotifications(response.token);
                        this.messagingService.getInbox(true).subscribe(
                            success => {
                                //this.messagingService.openMessage = this.firstMessage;
                            }
                        );
                        return this.getUserInfo();
                    },
                err => { console.error(err); }
                )
            )
    }

    private getTokenFromLocalStorage() {
        if (localStorage.getItem("id_token") && localStorage.getItem("id_token").length > 0) {
            this.token = localStorage.getItem("id_token");
            this.tokenExpiration = new Date(localStorage.getItem('expiration'));
            this.userType = localStorage.getItem('user_type');
        }
    }



    public getBasicUserInfoForId(id: string ): Observable<EbentoUser>  {
        return this.http.get("api/adminusers/"+id)
            .pipe(
                map(
                    (response: EbentoUser) => {
                        this.user = response;
                        return response;
                    },
                    err => { console.error(err.message); }
                )
            )
    }

    public getUserInfo() {
        
        if (!this.token || this.token.length == 0) {
            this.getTokenFromLocalStorage();
        }

        return this.http.get("/Account/GetUser")
            .pipe(
                map(
                (response: EbentoUser) => {
                    this.user = response;
                    if (this.user.userType == 'Event Planner') {
                        this.favoritesService.getMyFavoriteSpacesIDs(this.user.id).subscribe();
                        this.favoritesService.getMyFavoriteWorkshopsIDs(this.user.id).subscribe();
                    }
                        return response;
                    },
                    err => {}
                )
            )
    }

    // Move to another service? Used to get info, not work Auth
    public getUserInfoByID(id: string): Observable<EbentoUser> {
        return this.http.get("/api/userInfoByID/" + id)
            .pipe(
                map(
                    (response: EbentoUser) => {
                        return response;
                    },
                    err => { console.error(err.message) }
                )
            )
    }

    // Move to another service? Used to get info, not work Auth
    public getManagerInfoByID(id: string): Observable<PropertyManager> {
        return this.http.get("/api/propertyManagerInfo/" + id)
            .pipe(
                map(
                    (response: PropertyManager) => {
                        return response;
                    },
                    err => { console.error(err.message) }
                )
            )
    }

    // Move to another service? Used to get info, not work Auth
    public getPlannerInfoByID(id: string): Observable<EventPlanner> {
        return this.http.get("/api/eventPlannerInfo/" + id)
            .pipe(
                map(
                    (response: EventPlanner) => {
                        return response;
                    },
                    err => { console.error(err.message) }
                )
            )
    }
    
    //TODO: do smarter JWT sync of BE FE
    public logout() {
        return this.http.get("/Account/Logout").pipe(
            map(
                () => {
                    this.clearLocalStorage();
                    this.messagingService.stopNotifications();
                    this.messagingService.unloadMessages();
                    this.clearServiceData();
                },
                err => {
                    this.clearLocalStorage();
                    this.messagingService.stopNotifications();
                    this.messagingService.unloadMessages();
                    this.clearServiceData();
                }
            )
        )

    }

    public forgotPassword(model) {
        return this.http.post("/Account/ForgotPassword", model)
            .pipe(
                map(
                    (res) => {
                        return res;
                    },
                    err => { console.error(err.message) }
                )
            )
    }

    public changePassword(creds) {
        return this.http.post("/Account/ChangePassword", creds)
            .pipe(
                switchMap(
                    (response: any) => {
                        this.setSession(response);
                        return this.getUserInfo();
                    },
                    err => { console.error(err.Message) }
                )
            )
    }

    

    public checkResetToken(creds) {
        return this.http.post("/Account/CheckResetToken", creds)
            .pipe(
                map(
                    () => {
                        return true;
                    },
                    err => { console.error(err.Message) }
                )
            )
    }


    public resetPassword(creds) {
        return this.http.post("/Account/ResetPassword", creds)
            .pipe(
                switchMap(
                    (response: any) => {
                        this.setSession(response);
                        return this.getUserInfo();
                    },
                    err => { console.error(err.Message) }
                )
            )
    }

    

    public changeEmail(creds) {
        return this.http.post("/Account/ChangeEmail", creds)
            .pipe(
                map(
                    () => {
                        return true;
                    },
                err => { console.error(err.Message) }
                )
            )
    }

    public confirmChangeEmail(creds) {
        return this.http.post("/Account/ConfirmChangeEmail", creds)
            .pipe(
                map(
                    () => {
                        this.logout()
                    },
                err => { console.error(err.Message) }
                )
            )
    }

    private clearLocalStorage() {
        localStorage.removeItem('id_token');
        localStorage.removeItem('expiration');
        localStorage.removeItem('user_type');
    }

    private clearServiceData() {
        this.token = null;
        this.userType = null;
        this.tokenExpiration = null;
        this.user = null;
    }

    public forceLogout() {
        this.clearLocalStorage();
        this.clearServiceData();
    }

    public register(creds) {
        console.log(creds);
        return this.http.post("/Account/Register", creds)
            .pipe(
                map(
                    () => {
                        return true;
                    },
                    //TODO: remove error handling for security reasons
                    err => { console.error(err.message) }
                )
            );
    }

    public registerWithReferral(creds, referralID) {
        return this.http.post("/Account/RegisterWithReferral/" + referralID, creds)
            .pipe(
                map(
                    () => {
                        return true;
                    },
                    //TODO: remove error handling for security reasons
                    err => { console.error(err.message) }
                )
            );
    }

    public confirmRegistration(model) {

        return this.http.post("/Account/ConfirmRegistration", model)
            .pipe(
                map(
                    (response: any) => {
                        this.setSession(response);
                        return true;
                    },
                    //TODO: remove error handling for security reasons
                    err => { console.error(err.message) }
                )
            );
    }

    public completeRegistrationAdministrator(image, model) {
        if (image) {
            return this.uploadService.uploadImage(image).pipe(
                switchMap(
                    (res: string) => {
                        model.avatarURL = res;
                        return this.completeRegistrationAdministratorWithoutUpload(model);
                    }
                )
            )

        }
        else {
            return this.completeRegistrationAdministratorWithoutUpload(model);
        }
    }

    private completeRegistrationAdministratorWithoutUpload(model) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post("/Account/CompleteRegistrationAdministrator", JSON.stringify(model), httpOptions)
            .pipe(
                map(
                    (response: any) => {
                        this.getUserInfo().subscribe();
                        return true;
                    },
                    //TODO: remove error handling for security reasons
                    err => { console.error(err.message) }
                )
            );
    }

    public completeRegistrationPropertyManager(model) {

        return this.http.post("/Account/CompleteRegistrationPropertyManager", model)
            .pipe(
                map(
                    (response: any) => {
                        this.getUserInfo().subscribe();
                        return true;
                    },
                    //TODO: remove error handling for security reasons
                    err => { console.error(err.message) }
                )
            );
    }

    public completeRegistrationEventPlanner(image, model) {
        if (image) {
            return this.uploadService.uploadImage(image).pipe(
                switchMap(
                    (res: string) => {
                        model.avatarURL = res;
                        return this.completeRegistrationEventPlannerWithoutUpload(model);
                    }
                )
            )

        }
        else {
            return this.completeRegistrationEventPlannerWithoutUpload(model);
        }
    }

    private completeRegistrationEventPlannerWithoutUpload(model) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post("/Account/CompleteRegistrationEventPlanner", JSON.stringify(model), httpOptions)
            .pipe(
                map(
                    (response: any) => {
                        this.getUserInfo().subscribe();
                        return true;
                    },
                    //TODO: remove error handling for security reasons
                    err => { console.error(err.message) }
                )
            );
    }

    public editProfileEventPlanner(image, model) {
        if (image) {
            return this.uploadService.uploadImage(image).pipe(
                switchMap(
                    (res: string) => {
                        model.avatarURL = res;
                        return this.editProfileWithoutUploadEventPlanner(model);
                    }
                )
            )

        }
        else {
            return this.editProfileWithoutUploadEventPlanner(model);
        }
    }

    public editProfilePropertyManager(image, model) {
        if (image) {
            return this.uploadService.uploadImage(image).pipe(
                switchMap(
                    (res: string) => {
                        model.avatarURL = res;
                        return this.editProfileWithoutUploadPropertyManager(model);
                    }
                )
            )

        }
        else {
            return this.editProfileWithoutUploadPropertyManager(model);
        }
    }

    public editProfileAdministrator(image, model) {
        if (image) {
            return this.uploadService.uploadImage(image).pipe(
                switchMap(
                    (res: string) => {
                        model.avatarURL = res;
                        return this.editProfileWithoutUploadAdministrator(model);
                    }
                )
            )

        }
        else {
            return this.editProfileWithoutUploadAdministrator(model);
        }
    }

    public editProfileWithoutUploadEventPlanner(model) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post("/Account/EditProfileEventPlanner", JSON.stringify(model), httpOptions)
            .pipe(
                map(
                    (response: EbentoUser) => {
                        this.user = response;
                        return true;
                    },
                    //TODO: remove error handling for security reasons
                    err => { console.error(err.message) }
                )
            );
    }

    public editProfileWithoutUploadPropertyManager(model) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post("/Account/EditProfilePropertyManager", JSON.stringify(model), httpOptions)
            .pipe(
                map(
                    (response: EbentoUser) => {
                        this.user = response;
                        return true;
                    },
                    //TODO: remove error handling for security reasons
                    err => { console.error(err.message) }
                )
            );
    }

    public editProfileWithoutUploadAdministrator(model) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post("/Account/EditProfileAdministrator", JSON.stringify(model), httpOptions)
            .pipe(
                map(
                    (response: EbentoUser) => {
                        this.user = response;
                        return true;
                    },
                    //TODO: remove error handling for security reasons
                    err => { console.error(err.message) }
                )
            );
    }

    public getReferrerName(referralId: string): Observable<string> {
        return this.http.get("api/getReferrerNameFromId/" + referralId)
            .pipe(
                map(
                    (response: string) => {
                        return response;
                    },
                    err => { console.error(err.message); }
                )
            )
    }

    public deactivate(): Observable<Booking[]> {
      
        return this.http.get('api/Account/deactivate-property-manager')
            .pipe(
                map(
                (response: Booking[]) => {
                        return response;
                    },
                    (err) => {
                        return err
                    }
                )
            )
    }

    public reactivate(emailAddress: string, rToken: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const model = { email: emailAddress, token: rToken };
        return this.http.post("api/Account/reactivate-property-manager", JSON.stringify(model), httpOptions)
            .pipe(
                switchMap(
                    (response: any) => {
                        this.setSession(response);
                        this.messagingService.startNotifications(response.token);
                        this.messagingService.getInbox(true).subscribe();
                        return this.getUserInfo();
                    },
                err => { console.error(err); }
                )
            )
    }
}

export enum LoginRedirectType {
    Index,
    SpaceListing,
    WorkshopListing,
    Message
}