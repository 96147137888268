import { Component, Input } from '@angular/core';
import { SpaceBookingService } from 'app/modules/shared/services/space-booking.service';
import { SpaceService } from 'app/modules/shared/services/space.service';

@Component({ 
    selector: 'payment-overview',
    templateUrl: 'payment-overview.component.html',
    styleUrls: ['./payment-overview.component.scss']
})

export class PaymentOverview
{

    submissionInProgress: boolean = false

    constructor(
        public bookingService: SpaceBookingService,
        public spaceService: SpaceService)
    {
    }


    public VenuePrice(): number {
        return Math.round(this.bookingService.VenuePrice() * 100) / 100;
    }


    public BasePrice(): number {
        return Math.round(this.bookingService.BasePrice() * 100) / 100;
    }

    public EbentoFee(): number {
        return Math.round(this.bookingService.EbentoFee() * 100) / 100;
    }

    public TotalPrice(): number {
        return Math.round(this.bookingService.TotalPrice(false) * 100) / 100;
    }

    public TotalPriceWithDiscount(): number {
        return Math.round(this.bookingService.TotalPrice(true) * 100)/ 100;
    }
    public PriceDifference(): string
    {
        var result = Math.round((this.TotalPriceWithDiscount() - this.bookingService.bookingToEdit.price.totalWithDiscount) * 100) / 100;
        var sign = (result < 0) ? "- CHF" : "CHF"
        return sign + " " + Math.abs(result);
    }
}
