import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { SpaceBooking } from "../models/booking/space-booking";
import { TimeAllocation } from "../models/data-classes/time-allocation";
import { EbentoWorkshop } from "../models/workshops/ebento-workshop";
import { WorkshopBookingsRequest } from "../models/booking/workshop-booking-request";
import { WorkshopBookingOptions } from "../models/booking/workshop-booking-options";
import { WorkshopTimeAllocation } from "../models/data-classes/workshop-time-allocation";
import { WorkshopMenu } from "../models/workshops/workshop-menu";
import { WsBookingTimeAllocation } from "../models/data-classes/ws-booking-time-allocation";
import { WorkshopBooking } from "../models/booking/workshop-booking";
import { WorkshopService } from "./workshop.service";
import { CancellationRule } from "../models/properties/cancellation-rule";
import { ModifySpaceBookingRequest } from "../models/booking/modify-space-booking-request";
import { ModifyWorkshopBookingRequest } from "../models/booking/modify-workshop-booking-request";
import { Coupon } from "../models/booking/coupon";
import { PayModify } from "../models/booking/pay-modify";
import { WorkshopBookingMenu } from "../models/booking/workshop-booking-menu";
import { SearchService } from "../../event-planner/services/search.service";
import { BookingSegment } from "../models/booking/booking-segment";
import { SpaceService } from "./space.service";
import { SpaceBookingOptions } from "../models/booking/space-booking-options";
import { BookingPrice } from "../models/booking/booking-price";
import { EbentoSpace } from "../models/properties/ebento-space";

@Injectable()
//TODO: maybe only needed bookings, booking and booking overview
export class WorkshopBookingService {

    public pricePerPerson;
    public selectedSlotAvailableCount: number; 
    workshopBookingsRequest: WorkshopBookingsRequest
    public bookingOptions: WorkshopBookingOptions
    public existingWorkshopBookings: WorkshopBooking[];
    public booking: WorkshopBooking;
    public bookingToEdit: WorkshopBooking;
    public bookingTimeslot: WsBookingTimeAllocation
    public workshop: EbentoWorkshop

    constructor(private http: HttpClient, private router: Router, private spaceService:SpaceService, public workshopService: WorkshopService, public searchService: SearchService) {
    }

    startNewBooking(workshopId: number, prefilFromSearchService: boolean = false)
    {
        //console.log('ma daj');
            this.spaceService.getAmenityTypes().subscribe();
            this.workshopService.getWorkshop(workshopId).subscribe(
                (workshop) => {
                    this.workshop = workshop;
                    this.bookingToEdit = null;
                    this.bookingTimeslot = null;
                    this.bookingOptions = new WorkshopBookingOptions();
                    this.bookingOptions.menus = new Array<WorkshopBookingMenu>();


                    if (this.workshop.menus != null)
                        // for (var i = 0; i < this.workshop.menus.length; i++) {
                        //     var wbm = new WorkshopBookingMenu();
                        //     wbm.menuID = this.workshop.menus[i].id;
                        //     wbm.quantity = 0;
                        //     wbm.unitPrice = this.workshop.menus[i].price;
                        //     this.bookingOptions.menus.push(wbm);
                        // }
                    if (prefilFromSearchService) {
                        this.SetAttendeeCount(this.searchService.searchWorkshopQuery.numberofattendees);
                        //this.bookingOptions.attendees = this.searchService.searchWorkshopQuery.numberofattendees;
                       // this.bookingTimeslot = this.getWorkshopTimeslotFromSearchCalendarTimeselection(this.searchService.calendarTimeSelection, this.bookingOptions.attendees, this.searchService.searchWorkshopQuery.customTimeslots, this.workshop, this.existingWorkshopBookings, this);
                    }
                    this.router.navigate(['experience-listing/' + workshopId]);
                }
            );

       
    }
   /* getAvailableAttendeesForSlot(timeAllocation) :number
    {
        return 1;
    }


    getFittingInstance(availableAllocation, requested)
    {
        return null;
    }

    getWorkshopTimeslotFromSearchCalendarTimeselection(segment: BookingSegment[], attendeeCount: number, allowCustomTimeSlots: boolean, workshop: EbentoWorkshop, wsBookings: WorkshopBooking[], space: EbentoSpace): WsBookingTimeAllocation
    {
        console.log(this.workshop);
        console.log("Horhr!");

        var availableInTimeslots = false;
        for (let it of this.workshop.availableTimeslots)
        {
            for (let requestedTA in segment)
            {
                // this if instead of double break? Luka
                if (!availableInTimeslots) {
                    var fittingInstance = this.getFittingInstance(it, requestedTA);
                    if (fittingInstance != null)
                        if (this.getAvailableAttendeesForSlot(fittingInstance) >= Math.max(attendeeCount, 1)) {
                            availableInTimeslots = true;
                        }
                }
            }
            console.log(">>" + it);
        }
        var availableInCustom = false;
        if (allowCustomTimeSlots) {
            var reqIndex = 0;
            while (reqIndex < segment.length) {
                var requestedTA = segment[reqIndex];
                var dayIndex = new Date(requestedTA.startTime).getDay();
                    dayIndex--;
                    if (dayIndex < 0) dayIndex = 6;
                    var workingHours = space.workingHours;
                    if (!workingHours.workingdays[dayIndex]) {
                    segment.splice(reqIndex,1);
                    }
                    else {
                        var spaceStartTime = workingHours.GetStartTime(dayIndex);
                        var spaceEndTime = workingHours.GetEndTime(dayIndex);
                        if (requestedTA.startTime.getTime() < spaceStartTime)
                            requestedTA.startTime = new Date(requestedTA.startTime).getDate() + spaceStartTime;

                        if (requestedTA.endTime.getTime() > spaceEndTime)
                            requestedTA.endTime = new Date(requestedTA.endTime) + spaceEndTime;
                        reqIndex++;
                    }
                }
            }

            reqIndex = 0;
            while (reqIndex < segment.length) {
                //split TAs with existing bookings. this might be optimized with booking time sorting etc?
                var ta = segment[reqIndex];

                for (let booking of wsBookings)
                {
                    var bookingTime = booking.timeAllocation;
                    if (bookingTime != null) {
                        if (bookingTime.startTime <= ta.startTime && bookingTime.endTime > ta.endTime) {
                            ta.startTime = new Date(ta.startTime).getDate() + new Date(bookingTime.endTime).getTime();
                        }
                        else if (bookingTime.endTime >= ta.endTime && bookingTime.startTime < ta.endTime) {
                            ta.EndTime = ta.EndTime.Date + new Date(bookingTime.startTime).getTime();
                        }
                        else if (bookingTime.StartTime > ta.StartTime && bookingTime.endTime < ta.endTime) {
                            var remain = new WorkshopTimeAllocation();
                            remain.startTime = bookingTime.endTime;
                            remain.endTime = ta.endTime;
                            ta.endTime = new Date(ta.endTime).getDate() + new Date(bookingTime.startTime).getTime();
                            segment.Add(remain);
                        }
                    }
                }
                reqIndex++;
            }

            for (let remainingRequest of segment)
            {
                availableInCustom = availableInCustom || (remainingRequest.endTime - remainingRequest.startTime).TotalHours >= workshop.workshopDuration;
            }
        return availableInTimeslots || availableInCustom;
    }
    */
    getBookingsForWorkshop(workshopID: number, anonimous: boolean = false): Observable<WorkshopBooking[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        this.workshopBookingsRequest = new WorkshopBookingsRequest();
        this.workshopBookingsRequest.workshopID = workshopID;
        this.workshopBookingsRequest.anonimous = anonimous;
        return this.http.get<WorkshopBooking[]>(
            "api/WorkshopBooking/GetAllWorkshopBookings/" + workshopID,
           ).pipe(
            map(data => {
                this.existingWorkshopBookings = data;
                    return data;
                }),
                catchError(this.errorHandler)
            );
    }
    
    getBooking(bookingID: number): Observable<WorkshopBooking> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.get<WorkshopBooking>("api/WorkshopBooking/GetWorkshopBookingById/" + bookingID)
            .pipe(
                map(
                    (result) => {
                        this.booking = result;
                        return result;
                    }),
                catchError(this.errorHandler)
            );
    }

    public sendEnquiryRequest(
        {
          descriptionofEvent,
          SpecificRequirements,
          descriptionOfEventspace,
          location,
        },
        spaceType: string
      ) {
    
        console.log("booking options",this.bookingOptions);
        
        let enquiryBookingOptions = {
            serviceid: this.workshop.id,
            requesttype: spaceType,
            attendees: this.bookingOptions.attendees,
            timeAllocationDTOs: [this.bookingOptions.timeAllocationDTO],
            totalPrice: this.TotalPrice(false),
            message: this.bookingOptions.message,
            currency: "RS",
            venueHire: this.VenuePrice(),
            optionalAmenities: this.bookingOptions.optionalAmenities,
            descriptionofEvent,
            SpecificRequirements,
            descriptionOfEventspace,
            location,
          };
    
          const httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
            }),
          };
    
        return this.http
          .post("api/SendBookingRequest", enquiryBookingOptions, httpOptions)
          .pipe(
            map((result) => {
              return result;
            })
          );
      }

    public populateWorkshopBookingOptions(b: WorkshopBooking): void {
        let bookingOptions = new WorkshopBookingOptions()
        bookingOptions.workshopID = b.workshopID;
        bookingOptions.totalPrice = b.price.totalWithDiscount;
        bookingOptions.optionalAmenities = b.bookingAmenities;
        bookingOptions.timeAllocationDTO = b.timeAllocation;
        bookingOptions.attendees = b.attendees;
        bookingOptions.coupon = b.usedCoupon;
        bookingOptions.menus = b.menus;
        this.bookingTimeslot = b.timeAllocation;

        this.selectedSlotAvailableCount = this.GetAvailableSlotsForAllocation(b.timeAllocation);
        this.SetAttendeeCount(b.attendees);
        this.bookingOptions = bookingOptions;        
    }

    public populateCartModification(b:any){
        console.log("modify cart booking options",b);
        
        let bookingOptions = new WorkshopBookingOptions()
        bookingOptions.workshopID = b.workshopID;
        bookingOptions.totalPrice = b.price.totalWithDiscount;
        bookingOptions.optionalAmenities = b.bookingAmenities;
        bookingOptions.timeAllocationDTO = b.timeAllocation;
        bookingOptions.attendees = b.attendees;
        bookingOptions.coupon = b.usedCoupon;
        bookingOptions.menus = b.menus;
        this.bookingTimeslot = b.timeAllocation;

        this.selectedSlotAvailableCount = this.GetAvailableSlotsForAllocation(b.timeAllocation);
        this.SetAttendeeCount(b.attendees);
        this.bookingOptions = bookingOptions; 
    }

    public SetAttendeeCount(count: number) {

        this.bookingOptions.attendees = count;
        console.log("set attendess",this.bookingOptions,this.workshop);
        
        if (this.workshop.pricingRules.length > 0) {

            const rule = this.workshop.pricingRules.filter(pr =>
                pr.personFrom <= this.bookingOptions.attendees &&
                pr.personTo >= this.bookingOptions.attendees)[0];
            
            if (rule != null) {
                this.pricePerPerson = rule.price;
            }
            else {
                this.pricePerPerson = this.workshop.pricePerPerson;
            }
        }
        else {
            this.pricePerPerson = this.workshop.pricePerPerson;
        }


    }

    cancelBooking(id: number): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post<boolean>("api/bookworkshop/cancel-booking", id, httpOptions)
            .pipe(
                map(
                    result => {
                        return result;
                    }
                )
            );
    }

    GetWorkshopMenuByID(menuId:number): WorkshopMenu
    {   
        for (var i = 0; i < this.workshop.menus.length; i++) {
            if (this.workshop.menus[i].id == menuId)
                return this.workshop.menus[i];
        }
        return null;
    }

    get bookingReady(): boolean
    {
        // console.log('booking ready',this.bookingOptions,this.bookingOptions.attendees,this.bookingTimeslot,this.selectedSlotAvailableCount);
        
        return this.bookingOptions != null
            && this.bookingOptions.attendees > 0
            && this.bookingTimeslot != null
            && this.selectedSlotAvailableCount >= this.bookingOptions.attendees
    }
    
    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    populateBookingOptions() {
        this.bookingOptions.timeAllocationDTO = this.bookingTimeslot;
        this.bookingOptions.workshopID = this.workshop.id;
        this.bookingOptions.totalPrice = this.TotalPrice(true);
    } 

    bookWorkshop(): Observable<boolean> {
        this.populateBookingOptions();
           //console.log("Bukiramo Wrkšop #### " + JSON.stringify(this.bookingOptions));
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
       
        return this.http.post<boolean>(
            "api/bookworkshop",
            JSON.stringify(this.bookingOptions),
            httpOptions)
            .pipe(

                map(data => {
                    ///console.log("## Booked WoRKSHOP (???) " + JSON.stringify(data));
                    return data;
                }),
                catchError(this.errorHandler)
            );
    }

    payForModification(request: ModifyWorkshopBookingRequest): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        var payForModify = new PayModify();
        payForModify.modificationID = request.requestID;
        payForModify.price = request.price.totalWithDiscount - request.originalBooking.price.totalWithDiscount;
        payForModify.paymentType = this.bookingOptions.paymentType;

        return this.http.post<boolean>(
            "api/invoice-pay-for-ws-modify",
            payForModify,
            httpOptions)
            .pipe(

                map(data => {
                    return data;
                }),
                catchError(this.errorHandler)
            );
    }

    GetAvailableSlotsForAllocation(timeAllocation: WsBookingTimeAllocation)
    {
        if (this.workshop == null)
            return 0;

        var remainingSeats = this.workshop.maxNumberOfGuests;
        //console.log("Q:" + timeAllocation.startTime);
        if (this.existingWorkshopBookings != null) {
            for (let booking of this.existingWorkshopBookings) {
                if (booking.timeAllocations != null)
                    for (let ta of booking.timeAllocations)
                    {
                        if ((this.bookingToEdit == null || booking.idBooking != this.bookingToEdit.idBooking) && WsBookingTimeAllocation.Overlaps(ta as WsBookingTimeAllocation, timeAllocation)) {
                        //console.log("!!! Overlaps with " + booking.idWorkshopBooking);
                        remainingSeats -= booking.attendees;
                        }
                    }
            }
        }
        return remainingSeats;
    }

    checkCoupon(couponCode: string, workshopId: number): Observable<Coupon> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.get<Coupon>("api/checkcouponworkshop/" + workshopId + "/" + couponCode, httpOptions)
            .pipe(
                map(
                    result => {
                        return result;
                    }),
                catchError(this.errorHandler)
            );
    }

    getBookingPricing(bookingOptions: WorkshopBookingOptions): Observable<BookingPrice> {
        this.populateBookingOptions();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post<BookingPrice>("api/get-workshop-booking-pricing", bookingOptions)
            .pipe(
                map(
                    result => {
                        return result;
                    }),
                catchError(this.errorHandler)
            );
    }

    public VenuePrice(): number {
        
        if (this.bookingOptions == null || this.bookingOptions.attendees == null || this.workshop == null){
            // console.log('workshop bookingoptions',this.bookingOptions,this.workshop);
            return 0;
        }else{
            if(this.workshop.pricingRules.length){
                return this.pricePerPerson * this.bookingOptions.attendees;
            }else{
                return this.pricePerPerson;
            }
            // console.log('price per person',this.pricePerPerson);
        }
        // return this.workshop.pricePerPerson * this.bookingOptions.attendees;
    }


    public BasePrice(): number {
        var basePrice = 0;
        basePrice += this.VenuePrice();

        if(this.bookingOptions.optionalAmenities !== null && this.bookingOptions.optionalAmenities.length){
            for (let amenity of this.bookingOptions.optionalAmenities) {
                basePrice += (amenity.quantity * amenity.unitPrice);
            }
        }
        if(this.bookingOptions.menus !== null && this.bookingOptions?.menus?.length){
            for (let menu of this.bookingOptions.menus) {
                basePrice += (menu.quantity * menu.unitPrice);
            }
        }

        if (this.bookingTimeslot != null && this.bookingTimeslot.isCustom)
            basePrice += this.workshop.customTimeslotsPrice;

        return basePrice;
    }

    public EbentoFee(): number // 5 % fee
    {
        var basePrice = this.BasePrice();
        return basePrice * 0.05;
    }

    public TotalPrice(applyDiscount: boolean): number {
        
        var totalPrice = this.BasePrice() + this.EbentoFee();;

        if (this.bookingOptions.coupon != null && applyDiscount)
            totalPrice *= (100.0 - this.bookingOptions.coupon.discountPercentage) / 100.0;
        return totalPrice;
    }

    getHistoryCancellationRules(bookingID: number): Observable<CancellationRule[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.get<CancellationRule[]>("api/WorkshopBooking/GetHistoryCancellationRules/" + bookingID)
            .pipe(
                map(
                    (result) => {
                        return result;
                    }),
                catchError(this.errorHandler)
            );
    }

    sendModifyRequest(): Observable<boolean> {
        this.populateBookingOptions();
        this.bookingOptions.bookingID = this.bookingToEdit.idBooking;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<boolean>(
            "api/modify-workshop-booking",
            JSON.stringify(this.bookingOptions),
            httpOptions)
            .pipe(

                map(data => {
                    return data;
                }),
                catchError(this.errorHandler)
            );
    }


    public getModifyRequest(id: number): Observable<ModifyWorkshopBookingRequest> {
        return this.http.get<ModifyWorkshopBookingRequest>("api/ep-modify-ws-booking-request/" + id)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    public cancelBookingModification(requestID: number): Observable<boolean> {
        return this.http.post<boolean>("api/cancel-ws-booking-modification/" + requestID, null)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

}
