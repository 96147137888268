import { Component, Input } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Router } from "@angular/router";
import { ManagerBookingService } from "../../services/manager-booking.service";
import { MessagingService } from "app/modules/shared/services/messaging.service";
import { AuthService } from "app/modules/shared/services/auth.service";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";

@Component({
    selector: 'clickable-planner-name',
    templateUrl: 'clickable-planner-name.component.html'
})
export class ClickablePlannerName implements ICellRendererAngularComp {
    // public params: any;
    @Input() params: any
    @Input() data: any


    constructor(public managerBookingService: ManagerBookingService, public messagingService: MessagingService, private router: Router, public authService: AuthService)
    { }

    agInit(params: any): void {
        this.params = params;
        console.log('params', this.data)
    }

    public invokeParentMethod() {
        this.params.context.componentParent.openPlannerModal(this.params.data.eventPlannerID);
    }

    refresh(): boolean {
        return false;
    }

    gotoProfile() {
        this.managerBookingService.getEventPlannerInfo(this.params.data.eventPlannerID).subscribe();
        this.router.navigate(['customer-details']);
    }

    sendMessage() {
        this.authService.getPlannerInfoByID(this.params.data.eventPlannerID).subscribe(user => {
            this.messagingService.currentRecepient = user;
            this.messagingService.currentDraft = new EbentoMessage();
            this.router.navigate(['/messages/compose']);
        });
    }
}