import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CancellationRule } from "../../../shared/models/properties/cancellation-rule";
import { AuthService } from "../../../shared/services/auth.service";
import { SpaceService } from "../../../shared/services/space.service";
import { SpaceBookingService } from "../../../shared/services/space-booking.service";
import { SubmissionService } from "app/modules/shared/services/submission.service";

import { WorkingHours } from "../../../shared/models/properties/working-hours";
import { DocumentUpload } from "../../../shared/models/users/document-upload";
import { AmenityType } from "../../../shared/models/properties/amenity-type";
import { Amenity } from "../../../shared/models/properties/Amenity";
import { EbentoWorkshop } from "../../../shared/models/workshops/ebento-workshop";
import { WorkshopService } from "../../../shared/services/workshop.service";
import { WorkshopPricingRule } from "../../../shared/models/workshops/workshop-pricing-rule";
import { WorkshopPricingRuleType } from "../../../shared/models/workshops/workshop-pricing-rule-type";
import { EbentoSpace } from "../../../../modules/shared/models/properties/ebento-space";
import { DraggableCalendarView } from "../../../../modules/shared/components/draggable-calendar-view/draggable-calendar-view.component";
import { WorkshopAmenity } from "../../../../modules/shared/models/workshops/workshop-amenity";
import { DomSanitizer } from "@angular/platform-browser";
import { EbentoModalService } from "../../../shared/services/ebento-modal.service";
import { WorkshopTypeTag } from "../../../shared/models/tags/workshop-type-tag";
import { WorkshopInspirationTag } from "../../../shared/models/tags/workshop-inspiration-tag";
import { faPlus, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { MatStepper } from "@angular/material/stepper";

@Component({
  selector: "add-workshop",
  templateUrl: "add-workshop.component.html",
  styleUrls: ["./add-workshop.component.css"],
})
export class AddWorkshopForm implements OnInit, AfterViewInit {
  faPlus = faPlus;
  faWindowClose = faWindowClose;
  isLinear = false;

  @ViewChild("stepper") stepper: MatStepper;
  @ViewChild("draggableCalendar") nonworkingCalendar: DraggableCalendarView;
  public WeekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  public formPageIndex: number = 0;

  @Output() voted = new EventEmitter<string>();
  @Output("validate") validate = new EventEmitter<boolean>();

  menusValid: boolean; // if all menus are saved
  menusValidationRequested: boolean; // if continue is clicked

  //TODO: make amenity model
  public selectedAmenities: boolean[];
  public amenityPrice: number[];
  public amenityIsOptional: boolean[];
  public amenityHasAdjustableQuantity: boolean[];
  public amenityPricePerPerson: boolean[];
  public amenityDocumentUrl: string[];
  public workshopTypeTags: any;
  public workshopInspirationTags: any;

  public customAmenities: Amenity[];

  public spaceID: number;
  public space: EbentoSpace;

  public maxImgNo: number[];
  public formData: FormData;
  public hasImages: boolean;
  public localImages = [];

  editMode: boolean = false;

  myWorkshop = new EbentoWorkshop();

  experienceTypes: WorkshopTypeTag[] = [];
  experienceInspirations: WorkshopInspirationTag[] = [];

  response = "";

  public errorMessage: string;
  public errors: string[] = [];

  public pricingRuleTypes: WorkshopPricingRuleType[];
  public selectedPricingRuleType: WorkshopPricingRuleType;
  public pricingRulesErrors: string[];
  public pricingRules: WorkshopPricingRule[];

  public cancellationRules: CancellationRule[];

  public hasMinMaxGuestError: boolean;
  public hasRecommendedGuestError: boolean;

  public customTypetag: string;
  public customInspiration: string;

  public tagovi = "tackica";

  constructor(
    public authService: AuthService,
    public workshopService: WorkshopService,
    public spaceService: SpaceService,
    public bookingService: SpaceBookingService,
    private router: Router,
    public route: ActivatedRoute,
    private modalService: EbentoModalService,
    private sanitizer: DomSanitizer,
    private submissionService:SubmissionService
  ) {}

  ngOnInit() {
    // TODO: Refaktorisati da ili vucemo po id-u sa subscribe na route.params ili da workshop sacuvamo
    // u servisu kao workshop to edit pa odatle da vucemo id od spacea i workshopa
    // Ovako je blagi pakao realno :D

    var routeId = this.route.snapshot.params.id;

    // uh ovo je zapetljano relativno... razmrsiti možda?
    if (routeId != null) this.workshopService.workshopToEdit = null;
    this.spaceID =
      routeId != null ? routeId : this.workshopService.workshopToEdit.spaceId;

    console.log("daj vamo " + this.spaceID);

    this.workshopService.getAllWorkshopTypeTags().subscribe((res) => {
      this.workshopTypeTags = res;
      this.experienceTypes.forEach((type) => {
        if (type.isPublic == false) this.workshopTypeTags.push(type);
      });
    });

    this.workshopService.getAllInspirationTypeTags().subscribe((res) => {
      this.workshopInspirationTags = res;
      this.experienceInspirations.forEach((type) => {
        if (type.isPublic == false) this.workshopInspirationTags.push(type);
      });
    });

    this.spaceService.getSpace(this.spaceID).subscribe((space) => {
      // console.log("Eto prvog " + this.spaceID);
      this.space = space;
      this.spaceService.getWorkingHours(this.spaceID).subscribe((success) => {
        // console.log('working hours',success);
        this.space.workingHours = WorkingHours.FromDTO(success);
        // console.log('this.space.workingHours',this.space.workingHours);

        this.nonworkingCalendar.workingHours = this.space.workingHours;
        this.nonworkingCalendar.Initialize();
      });
    });

    // this.spaceService.getAmenityTypes()

    this.selectedAmenities = new Array(this.spaceService.amenityTypes.length);
    this.amenityPrice = new Array(this.spaceService.amenityTypes.length);
    this.amenityHasAdjustableQuantity = new Array(
      this.spaceService.amenityTypes.length
    );
    this.amenityIsOptional = new Array(this.spaceService.amenityTypes.length);
    this.amenityPricePerPerson = new Array(
      this.spaceService.amenityTypes.length
    );
    this.amenityDocumentUrl = new Array(this.spaceService.amenityTypes.length);

    this.formData = new FormData();
    this.hasImages = false;

    //TODO: import real data - this is quick fix
    this.pricingRuleTypes = this.workshopService.pricingRuleTypes;
    this.pricingRules = [];

    this.cancellationRules = [];

    if (this.workshopService.workshopToEdit != null) {
      this.preloadValues();
      this.editMode = true;
    }
  }

  ngAfterViewInit(): void {
    this.stepper.selectedIndex = this.workshopService.selectedIndex;
  }

  checkTypeTags(id: number) {
    // const result = this.myWorkshop.typeTags.some((e) => e.id === id);
    const result =
      this.myWorkshop.typeTags?.length &&
      this.myWorkshop.typeTags.some((e) => e.id === id);

    // console.log("resultss typetags", this.myWorkshop.typeTags);
    return result;
  }

  checkInspirationTags(id: number) {
    // const result = this.myWorkshop.inspirationTags.some((e) => e.id === id);
    const result =
      this.experienceInspirations?.length &&
      this.experienceInspirations.some((e) => e.id === id);

    // console.log("resultss inspiration",id,result);
    return result;
  }

  AddToTypeTags(type: WorkshopTypeTag) {
    console.log("see typess", type);

    let exist = false;
    // this.myWorkshop.typeTags.forEach((e) => {
    //     if(e.id === type.id){
    //         exist = true;
    //     }
    // });
    this.experienceTypes.forEach((e) => {
      if (e.id === type.id) {
        exist = true;
      }
    });
    if (exist) {
      // this.myWorkshop.typeTags = this.myWorkshop.typeTags.filter((e) => e.id !== type.id)
      this.experienceTypes = this.experienceTypes.filter(
        (e) => e.id !== type.id
      );
    } else {
      // this.myWorkshop.typeTags.push(type);
      this.experienceTypes.push(type);
    }
    this.myWorkshop.typeTags = this.experienceTypes;
  }

  SetCustom(e: any, type: string) {
    if (type === "experiencetype") {
      this.customTypetag = e.target.value;
    } else if (type === "experienceinspiration") {
      this.customInspiration = e.target.value;
    }
  }

  AddCustomTypeTag(type: string) {
    if (type === "type") {
      this.experienceTypes.push({
        id: -1,
        name: this.customTypetag,
        isPublic: false,
        childTags: [],
      });
    } else if (type === "inspiration") {
      this.experienceInspirations.push({
        id: -1,
        name: this.customInspiration,
        isPublic: false,
      });
    }

    console.log("inspiration tags", this.experienceInspirations);
    console.log("type tags", this.experienceTypes);
  }

  removeTag(name: string, type: string) {
    if (type === "type") {
      this.experienceTypes = this.experienceTypes.filter((e) => {
        return e.name !== name;
      });
    } else if (type === "inspiration") {
      this.experienceInspirations = this.experienceInspirations.filter((e) => {
        return e.name !== name;
      });
    }
  }

  AddToInspirationTags(inspiration: WorkshopInspirationTag) {
    let exist = false;
    this.experienceInspirations.forEach((e) => {
      if (e.id === inspiration.id) {
        exist = true;
      }
    });
    if (exist) {
      this.experienceInspirations = this.experienceInspirations.filter(
        (e) => e.id !== inspiration.id
      );
    } else {
      this.experienceInspirations.push(inspiration);
    }
    this.myWorkshop.inspirationTags = this.experienceInspirations;
    console.log("experienceInspirations", this.experienceInspirations);
  }

  preloadValues() {
    this.myWorkshop = this.workshopService.workshopToEdit;
    this.experienceTypes = this.myWorkshop.typeTags;
    this.experienceInspirations = this.myWorkshop.inspirationTags;
    this.spaceID = this.myWorkshop.spaceId;

    var amenities = new Array();
    for (var i = 0; i < this.myWorkshop.amenities.length; i++) {
      var index = -1;
      for (var a = 0; a < this.spaceService.amenityTypes.length; a++) {
        if (
          this.spaceService.amenityTypes[a].id ==
          this.myWorkshop.amenities[i].amenitytype.id
        ) {
          if (this.myWorkshop.amenities[i].amenitytype.isGlobal) index = a;
        }
      }
      if (index == -1) {
        console.error("index error");
        if (this.customAmenities == null) this.customAmenities = [];
        this.customAmenities.push(this.myWorkshop.amenities[i]);
      } else {
        this.selectedAmenities[index] = true;
        this.amenityPrice[index] = this.myWorkshop.amenities[i].price;
        this.amenityIsOptional[index] = this.myWorkshop.amenities[i].isoptional;
        this.amenityHasAdjustableQuantity[index] =
          this.myWorkshop.amenities[i].hasadjustablequantity;
        this.amenityPricePerPerson[index] =
          this.myWorkshop.amenities[i].priceperperson;
        this.amenityDocumentUrl[index] =
          this.myWorkshop.amenities[i].documenturl;
      }
    }
    this.pricingRules = this.myWorkshop.pricingRules;
    if (this.pricingRules != null && this.pricingRules.length > 0)
      this.selectedPricingRuleType = this.pricingRuleTypes.find(
        (r) => r.id == this.pricingRules[0].type.id
      );

    this.cancellationRules = this.myWorkshop.cancellationRules;
    this.cancellationRules.sort((a, b) => {
      if (a.daysFrom < b.daysFrom) return -1;
      if (a.daysFrom > b.daysFrom) return 1;
      return 0;
    });
  }

  goForward(step: string) {
    const validator = new Validator(this.myWorkshop);
    this.errors = validator[step]();
    if (!this.errors.length && step === "step6") {
      if (!this.pricingRules.length || this.validatePricingRules()) {
        if (!this.myWorkshop.pricePerPerson) {
          this.errors.push("Please fill the pricing details correctly");
        } else {
          this.stepper.next();
        }
      } else {
        this.stepper.next();
      }
    } else if (!this.errors.length && step !== "step7" && step !== "step6") {
      this.stepper.next();
    } else if (!this.errors.length && step === "step7") {
      this.onSubmit();
    }
  }

  clickContinue(isValid: boolean) {
    if (!isValid) return;

    this.formPageIndex++;
  }

  clickContinueMenus() {
    this.menusValidationRequested = true;

    if (this.menusValid === false) {
      this.errorMessage = "Please save all the menus";
      return;
    } else {
      this.menusValidationRequested = false;
      this.errorMessage = "";
      this.formPageIndex++;
    }
  }

  clickContinueDescription() {
    if (
      this.myWorkshop.description != null &&
      this.myWorkshop.description != ""
    ) {
      this.formPageIndex++;
    }
  }

  clickBack() {
    if (this.formPageIndex > 0) this.formPageIndex--;
  }

  ignore() {}

  onImageUploaded(event) {
    console.log("onImage upload", event);

    for (let i = 0; event.files.length > i; i++) {
      this.formData.append(event.files[i].name, event.files[i]);
    }

    this.hasImages = true;
    this.myWorkshop.images = this.formData;
    this.localImages = event.urls;
    // this.formData.append(file.name, file);
    // this.currentImgNo++;
  }

  deleteUploadedPhoto(url: string) {
    for (var i = 0; i < this.myWorkshop.photoUrls.length; i++) {
      if (this.myWorkshop.photoUrls[i] == url) {
        this.myWorkshop.photoUrls.splice(i, 1);
      }
    }
  }

  deleteLocalPhoto(file: string) {
    this.formData.delete(file);
  }

  onDocumentUploaded(docObj: DocumentUpload) {
    //console.log("doc url asc", docObj.url);
    //console.log("doc url asc", docObj.amenityType.name);
    this.SetDocumentUrl(docObj.amenityType, docObj.url);
    //console.log(docObj.url);
    this.voted.emit(docObj.url);
  }

  public SetPerPerson(p: AmenityType) {
    //console.log("Set PPP on " + this.spaceService.amenityTypes.indexOf(p));
    this.amenityPricePerPerson[this.spaceService.amenityTypes.indexOf(p)] =
      true;
  }

  public SetFixed(p: AmenityType) {
    //console.log("Set FIX on " + this.spaceService.amenityTypes.indexOf(p));
    this.amenityPricePerPerson[this.spaceService.amenityTypes.indexOf(p)] =
      false;
  }

  public SetDocumentUrl(p: AmenityType, url: string) {
    //console.log("Set FIX on " + this.spaceService.amenityTypes.indexOf(p));
    this.amenityDocumentUrl[this.spaceService.amenityTypes.indexOf(p)] = url;
  }

  public GetWorkingHours(): string[] {
    console.error("Removed this??");
    /*
        var results = new Array();
        for (var i = 0; i < 7; i++) {
            if (this.myWorkshop.workingHours.workingdays[i])
                results.push(this.WeekDays[i] + ": " + this.myWorkshop.workingHours.starttimes[i].HoursMinutesString + "-" + this.myWorkshop.workingHours.endtimes[i].HoursMinutesString);
            else
                results.push(this.WeekDays[i] + ": CLOSED");
        }
        return results;*/
    return null;
  }

  public GetFixedAmenities(): string[] {
    var results = new Array();
    for (var i = 0; i < this.selectedAmenities.length; i++) {
      if (
        this.selectedAmenities[i] &&
        !this.amenityIsOptional[i] &&
        !this.spaceService.amenityTypes[i].isCatering
      )
        results.push(this.spaceService.amenityTypes[i].name);
    }
    return results;
  }

  public GetOptionalAmenities(): string[] {
    var results = new Array();
    for (var i = 0; i < this.selectedAmenities.length; i++) {
      if (this.selectedAmenities[i] && this.amenityIsOptional[i])
        results.push(
          this.spaceService.amenityTypes[i].name +
            (this.amenityPrice[i] > 0
              ? " (" +
                this.amenityPrice[i] +
                " CHF" +
                (this.amenityHasAdjustableQuantity[i] ? " per item" : "") +
                ")"
              : " (Free)")
        );
    }
    return results;
  }

  public GetCaterings(): string[] {
    var results = new Array();
    for (var i = 0; i < this.selectedAmenities.length; i++) {
      if (
        this.selectedAmenities[i] &&
        this.spaceService.amenityTypes[i].isCatering
      )
        results.push(
          this.spaceService.amenityTypes[i].name +
            (this.amenityPrice[i] > 0
              ? " (" +
                this.amenityPrice[i] +
                " CHF" +
                (this.amenityPricePerPerson[i] ? " per person" : "") +
                ")"
              : " (Free)")
        );
    }
    return results;
  }

  public minMaxValidation(isValidForm: boolean): void {
    if (!isValidForm) return;

    if (this.myWorkshop.minNumberOfGuests > this.myWorkshop.maxNumberOfGuests) {
      this.hasMinMaxGuestError = true;
      return;
    }

    this.hasMinMaxGuestError = false;
    this.formPageIndex++;
  }

  validateMenus(event) {
    this.menusValid = event;
    this.menusValidationRequested = false;
  }

  collectMenus(event) {
    this.myWorkshop.menus = event;
  }

  //pricing rules part

  addPricingRule(): void {
    let personTo: number =
      this.pricingRules.length > 0
        ? this.pricingRules[this.pricingRules.length - 1].personTo
        : 0;
    let newRule = new WorkshopPricingRule();
    newRule.personFrom = personTo;
    this.pricingRules.push(newRule);
  }

  removePricingRule(index: number): void {
    if (index != this.pricingRules.length - 1) return;
    this.pricingRules.splice(index, 1);
  }

  personFromChanged(index: number): void {
    if (index == this.pricingRules.length - 1) return;

    this.pricingRules[index + 1].personTo = this.pricingRules[index].personFrom;
  }

  validatePricingRules(): boolean {
    let invalidData: boolean = false;
    for (var i = 0; i < this.pricingRules.length; i++) {
      var rule = this.pricingRules[i];
      this.pricingRulesErrors = [];
      if (rule.personFrom && rule.personTo && rule.price) {
        if (rule.personFrom >= rule.personTo || rule.price <= 0) {
          invalidData = true;
          this.errors.push(
            "rule - " + (i + 1) + ": Person From is larger then Person To"
          );
        }
        if (i > 0 && rule.personFrom < this.pricingRules[i - 1].personTo) {
          invalidData = true;
          this.errors.push(
            "rule - " +
              (i + 1) +
              ": Person From is less then Person To of previous rule"
          );
        }

        if (rule.personTo > this.myWorkshop.maxNumberOfGuests) {
          invalidData = true;
          this.errors.push(
            "rule - " +
              (i + 1) +
              ": Person To is Larger then Max Number of Guests" +
              " (" +
              this.myWorkshop.minNumberOfGuests +
              ")"
          );
        }
      } else {
        invalidData = true;
      }
    }
    console.log("invalid data", invalidData);

    return invalidData;
  }

  onPricingRuleTypeChange() {
    this.pricingRules.forEach((rule) => {
      rule.price = null;
    });
    this.pricingRulesErrors = [];
  }
  //end pricing rules part

  //cancellation rules part
  addCancellationRule(): void {
    let daysTo: number =
      this.cancellationRules.length > 0
        ? this.cancellationRules[this.cancellationRules.length - 1].daysFrom
        : 0;
    let newRule = new CancellationRule();
    newRule.daysTo = daysTo;
    this.cancellationRules.push(newRule);
    this.myWorkshop.cancellationRules = this.cancellationRules;
  }

  removeCancellationRule(index: number): void {
    if (index != this.cancellationRules.length - 1) return;
    this.cancellationRules.splice(index, 1);
  }

  daysFromChanged(index: number): void {
    if (index == this.cancellationRules.length - 1) return;

    this.cancellationRules[index + 1].daysTo =
      this.cancellationRules[index].daysFrom;
  }

  validateCancellationRules(isValid: boolean): void {
    if (!isValid) return;

    let invalidData: boolean = false;
    this.cancellationRules.forEach(function (rule) {
      if (
        rule.daysFrom <= rule.daysTo ||
        rule.percentageToReturn < 0 ||
        rule.percentageToReturn > 100
      )
        invalidData = true;
    });

    if (invalidData) return;
    this.formPageIndex++;
  }
  //end cancellation rules part

  addCustomAmenity() {
    var newCustomAmType = new AmenityType();
    var nextNegativeIndex = -1;
    if (this.customAmenities != null)
      for (var cam of this.customAmenities) {
        if (cam.amenitytype.id <= nextNegativeIndex) {
          nextNegativeIndex = cam.amenitytype.id - 1;
        }
      }
    newCustomAmType.id = nextNegativeIndex;

    newCustomAmType.isCatering = false;
    var newCustomAmenity = new Amenity(
      null,
      newCustomAmType,
      false,
      false,
      false,
      null
    );
    if (this.customAmenities == null) this.customAmenities = [];
    this.customAmenities.push(newCustomAmenity);
  }

  removeCustomAmenity(amenity: Amenity) {
    this.customAmenities.splice(this.customAmenities.indexOf(amenity), 1);
  }

  onPaste(event) {
    event.preventDefault();
    event.stopPropagation();
    const plaintext = event.clipboardData.getData("Text");
    document.execCommand("inserttext", false, plaintext);
  }

  durationChanged(val) {
    this.nonworkingCalendar.updateDuration(val);
  }

  onSubmit() {
    this.submissionService.startSubmission();
    this.myWorkshop.typeTags = this.experienceTypes;
    console.log("workshopTags", this.workshopService.workshopTypeTags);

    //this.workshopService.selectedNonWorkingHours = this.nonworkingCalendar.GetAllSelections();
    this.myWorkshop.availableTimeslots =
      this.nonworkingCalendar.workshopTimeAllocations;
    let spacetypeids = new Array();
    this.myWorkshop.spaceId = this.spaceID;
    this.myWorkshop.images = this.formData;
    let amenities = new Array();

    for (var i = 0; i < this.selectedAmenities.length; i++) {
      if (this.selectedAmenities[i]) {
        var amenity = new WorkshopAmenity(
          this.amenityPrice[i],
          this.spaceService.amenityTypes[i],
          this.amenityIsOptional[i],
          this.amenityHasAdjustableQuantity[i],
          this.amenityPricePerPerson[i],
          this.amenityDocumentUrl[i]
        );

        amenities.push(amenity);
      }
    }

    if (this.customAmenities != null)
      for (var i = 0; i < this.customAmenities.length; i++) {
        amenities.push(this.customAmenities[i]);
      }

    this.myWorkshop.amenities = amenities;

    this.pricingRules.forEach((pr) => {
      pr.type = this.selectedPricingRuleType;
    });

    this.myWorkshop.pricingRules = this.pricingRules;
    this.myWorkshop.cancellationRules = this.cancellationRules;

    this.workshopService.workshop = this.myWorkshop;

    // striping edited myWorkshop to comply with DTO.
    //I do not understand this problem, but this solves it.
    this.myWorkshop.address = null;
    this.myWorkshop.propertyPhotoUrl = null;
    this.myWorkshop.propertyName = null;
    var pmID =
      this.myWorkshop.propertyManager != null
        ? this.myWorkshop.propertyManager.id
        : -1;
    this.myWorkshop.propertyManager = null;
    this.myWorkshop.spaceName = null;

    if (!this.editMode) {
      //console.log("jasno");
      this.workshopService.addWorkshop(this.hasImages).subscribe(
        (newId) => {
          this.submissionService.endSubmission();
          this.router.navigate([
            `/property-manager/experience-preview/${newId}`,
          ]);
        },
        (error) => (this.errorMessage = error)
      );
    } else {
      //console.log("jasno");
      this.workshopService.editWorkshop(this.hasImages).subscribe(
        (newId) => {
          this.submissionService.endSubmission();
          //console.log('workshop UPDATED (WTF?) id: ' + newId);
          this.workshopService.workshopToEdit = null;
          this.router.navigate([
            `/property-manager/experience-preview/${newId}`,
          ]);
        },

        (error) => {
          this.errorMessage = error;
          console.error(error);
        }
      );
    }
  }
}

class Validator {
  constructor(private myWorkshop: EbentoWorkshop) {}

  public step1(): string[] {
    let errors: string[] = [];
    try {
      if (Object.keys(this.myWorkshop).length > 3) {
        if (!this.myWorkshop.name.length) {
          errors.push("Name is required!");
        } else if (this.myWorkshop.workshopDuration === 0) {
          errors.push("Experience Duration is required!");
        } else if (!this.myWorkshop.description.length) {
          errors.push("Description is Required");
        } else if (!this.myWorkshop.workshopIncluded.length) {
          errors.push("What is included is Required!");
        } else if (!this.myWorkshop.workshopNotIncluded.length) {
          errors.push("What is not included is required!");
        } else if (!this.myWorkshop.covidMeasures.length) {
          errors.push("Covid measures is required!");
        }
      } else {
        errors.push("Please fill in the details below!");
      }
    } catch (err) {
      console.log("thrown error", err);
      errors = ["Please fill in the details below!"];
    }

    return errors;
  }

  public step2(): string[] {
    let errors: string[] = [];
    try {
      if (Object.keys(this.myWorkshop).length > 5) {
        if (!this.myWorkshop.images && !this.myWorkshop.photoUrls) {
          errors.push("Please upload images!");
        } else if (!this.myWorkshop.bookingType) {
          errors.push("Please check the booking type!");
        }
      } else {
        errors.push("Please fill in the details below!");
      }
    } catch (err) {
      errors = ["Please fill in the details below!"];
    }
    return errors;
  }

  public step3(): string[] {
    let errors: string[] = [];
    try {
      if (Object.keys(this.myWorkshop).length > 11) {
        if (
          !this.myWorkshop.inspirationTags.length ||
          !this.myWorkshop.typeTags.length
        ) {
          errors.push("Please add inspiration and type tags!");
        }
      } else {
        errors.push("Please fill all the details!");
      }
    } catch (err) {
      errors = ["Please fill all the details!"];
    }
    return errors;
  }

  public step4(): string[] {
    let errors: string[] = [];
    // console.log("this.myworkshop",this.myWorkshop);

    return errors;
  }

  public step5(): string[] {
    let errors: string[] = [];

    // console.log("this.myworkshop",this.myWorkshop);

    return errors;
  }

  public step6(): string[] {
    let errors: string[] = [];

    try {
      if (Object.keys(this.myWorkshop).length > 13) {
        if (this.myWorkshop.minNumberOfGuests < 1) {
          errors.push("Please fill minimum Number of guests correctly");
        }
        if (this.myWorkshop.maxNumberOfGuests < 1) {
          errors.push("Please fill max number of guests correctly");
        }
        if (
          this.myWorkshop.minNumberOfGuests > this.myWorkshop.maxNumberOfGuests
        ) {
          errors.push(
            "Max number of guests should be larger than minimum number of guests"
          );
        }
      } else {
        errors.push("Please fill all the details!");
      }
    } catch (err) {
      console.log("error caught", err);

      errors = ["Please fill all the details!"];
    }

    return errors;
  }

  public step7(): string[] {
    let errors: string[] = [];
    console.log("this.myworkshop", this.myWorkshop);
    if (Object.keys(this.myWorkshop).length > 15) {
      if (!this.myWorkshop.cancellationRules.length) {
        errors.push("Please fill  the cancellation rules!");
      } else {
        for (let c of this.myWorkshop.cancellationRules) {
          if (c.percentageToReturn && c.daysTo >= 0 && c.daysFrom > 0) {
            continue;
          } else {
            errors.push("Please fill the cancellation rule details correctly");
            break;
          }
        }
      }
    } else {
      errors.push("Please fill all the details!");
    }

    return errors;
  }
}
