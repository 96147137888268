import { Component, OnInit, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { NotificationType } from 'app/helpers/enums/notificationType';
import { AdminNewsService } from '../../services/admin-news.service';
import { NotificationService } from 'app/modules/shared/services/notification.service';
import { AdminWorkshopService } from '../../services/admin-workshop.service';

@Component({
    selector: 'admin-tag-cell',
    templateUrl: './admin-tag-cell.component.html',
    styleUrls: ['./admin-tag-cell.component.scss']
})
export class AdminTagCellComponent implements ICellRendererAngularComp {
    public params: any;

    @ViewChild('tagSelector') tagSelector;

    constructor(private router: Router, public adminWorkshopService: AdminWorkshopService, private notificationService: NotificationService) { }

    agInit(params: any): void {
        this.params = params;
        //console.log(params.data.name + ": " + params.data.childTags);
    }

    helou()
    {
        var selectorHeight = (this.tagSelector.getPixelHeight());
        //console.log(this.params.node.rowHeight);
        this.params.node.setRowHeight(Math.max(80, selectorHeight + 20));
        this.params.api.onRowHeightChanged();
    }

    printUs()
    {
        this.adminWorkshopService.setWorkshopTypeTagChildren(this.params.data.id, this.params.data.childTags).subscribe(
            (success) => {
                this.notificationService.notify(NotificationType.Success, "Tag " + this.params.data.name + " child tags updated", "Tag updated");
            }
        );
    }

    isPopup() {
        return true;
    }

    refresh(): boolean {
        return true;
    }

    get allTypeTags()
    {
        return this.adminWorkshopService.workshopTypeTags
    }

    publishToggle()
    {
        this.adminWorkshopService.setWorkshopTypeTagPublic(this.params.data.id, !this.params.data.isPublic).subscribe(
            (success) =>
            {
                this.params.data.isPublic = !this.params.data.isPublic;
                if (this.params.data.isPublic) {
                    this.notificationService.notify(NotificationType.Success, "Tag " + this.params.data.name + " is now public", "Tag updated");
                }
                else
                {
                    this.notificationService.notify(NotificationType.Success, "Tag " + this.params.data.name + " is now private", "Tag updated");
                }  
                
                this.params.api.redrawRows();
            }
        );
    }
}
