import { TimeSpan } from "../data-classes/time-span";
import { WorkshopPricingRuleType } from "./workshop-pricing-rule-type";

export class WorkshopPricingRule {

    public id: number
    public price: number
    public type: WorkshopPricingRuleType
    public personTo: number
    public personFrom: number

}