import { BookingSegment } from "../booking/booking-segment";

export class WorkshopTimeAllocation
{
    public id: number
    public startTime: string
    public endTime: string    
    public allocationType: number
    public isRecurring: boolean

    constructor()
    {
        // default value for Available Slots. Is it needed?
        this.allocationType = 2;
    }

    // copypasted. To do / remove redundancy
    public static FromBooking(booking: BookingSegment): WorkshopTimeAllocation {
        var allocation = new WorkshopTimeAllocation();
        allocation.startTime = booking.startTime.toLocaleString('en-US');
        var endTime: Date;
        endTime = new Date(booking.startTime);
        var hours = booking.startTime.getHours() + booking.duration.Hours;
        var minutes = booking.startTime.getMinutes() + booking.duration.Minutes;
        console.log(booking);
        console.log("H " + hours + " M " + minutes);
        while (minutes >= 60) {
            minutes -= 60;
            hours++;
        }
        endTime.setHours(hours);
        endTime.setMinutes(minutes);

        allocation.endTime = endTime.toLocaleString('en-US');
        allocation.allocationType = 0;
        allocation.id = -1;
        return allocation;
    }
}