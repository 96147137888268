import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpaceForTile } from '../../models/search/space-for-tile';
import { AuthService } from '../../services/auth.service';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { SpaceBookingService } from '../../services/space-booking.service';

@Component({
    selector: 'space-search-result-tile',
    templateUrl: 'space-search-result-tile.component.html',
    styleUrls: ['space-search-result-tile.component.scss']
})

export class SpaceSearchResultTile implements OnInit {
    
    //fa 
    faUsers = faUsers;

    public spacephotourl: string;
    public currentphotoindex: number;
    @Input('spaceForTile') spaceForTile: SpaceForTile

    constructor(
        private router: Router,
        public authService: AuthService,
        public spaceBookingService: SpaceBookingService
    ) { }

    ngOnInit(): void {
        if (this.spaceForTile != null &&
            this.spaceForTile.space.photoUrls != null &&
            this.spaceForTile.space.photoUrls.length > 0) {

            this.spacephotourl = this.spaceForTile.space.photoUrls[0];
            
        }
    }

    public Home()
    {
        if (this.authService.userType == "EventPlanner" && this.authService.isLoggedIn()) {
            this.spaceBookingService.startNewBooking(this.spaceForTile.space.spaceId, true);
        }
        else {
            this.router.navigate(['space-details/' + this.spaceForTile.space.spaceId]);
        }
    }


}

