/// <reference types="@types/googlemaps" />

import { Component, OnInit, Injector, Input } from '@angular/core';
import { Router } from "@angular/router";
import { SpaceForTile } from 'app/modules/shared/models/search/space-for-tile';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { AdminSpaceService } from 'app/modules/admin/services/admin-space.service';
import { SpaceCarouselTile } from '../../carousel-tile/space-carousel-tile/space-carousel-tile.component';
import { IndexCarousel } from '../index-carousel.component';

@Component({
    selector: 'perfect-spaces',
    templateUrl: '../index-carousel.component.html'
})

export class PerfectSpaces extends IndexCarousel implements OnInit {
    @Input() color:string;
    @Input() headerLabel = 'Find your Perfect Space for your next Event';
    protected tileComponent = SpaceCarouselTile;
    domID = 'perfect';

    constructor(public spaceService: SpaceService, injector: Injector) {
        super(injector);
    }

    get apiGetter() {
        return this.spaceService.getPerfectSpaces();
    }
}
