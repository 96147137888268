import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationType } from 'app/helpers/enums/notificationType';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html'
})
export class ChangePasswordModalComponent implements OnInit {

    @Input() title: string = "Change Password"
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    errorMessage: string = "";

    public creds = {
        OldPassword: "",
        Email: "",
        Password: "",
        ConfirmPassword: "",
    };

    constructor(
        public authService: AuthService,
        private router: Router,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(["/index"]);
        }
        this.creds.Email = this.authService.user.email
    }

    changePassword() {
        if (!this.validatePassword()) return;

        this.authService.changePassword(this.creds)
            .subscribe(
                () => {
                    if (this.authService.userType == "EventPlanner") {
                        this.router.navigate(["index"]);
                    } else if (this.authService.userType == "PropertyManager") {
                        this.router.navigate(['/property-manager/properties']);
                    }
                    this.notificationService.notify(NotificationType.Success, "Password successfully changed", null);
                    this.passEntry.emit();
                },
                err => { console.log(JSON.stringify(err)); this.errorMessage = err.error; }
            );
    }

    //TODO: create validation service which will take all params from server
    private validatePassword(): boolean {
        if (this.creds.Password != this.creds.ConfirmPassword) {
            this.errorMessage = "Passwords do not match.";
            return false;
        }

        let minMaxLength = new RegExp(/^[\s\S]{8,64}$/);
        let upper = new RegExp(/[A-Z]/);
        let lower = new RegExp(/[a-z]/);
        let number = new RegExp(/[0-9]/);

        if (this.creds.Password.match(minMaxLength) &&
            this.creds.Password.match(upper) &&
            this.creds.Password.match(lower) &&
            this.creds.Password.match(number)) {

            return true;
        }
        this.errorMessage = "Password must contain at least 8 characters. The password must contain at least three character categories among the following:<br/>&#8226 1 Uppercase character(A - Z)<br/>&#8226 1 Lowercase character(a - z)<br/>&#8226 1 Digit(0 - 9)";
        return false;
    }

}
