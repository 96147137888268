import { Component, OnInit, ViewChild, AfterViewChecked, ElementRef, AfterViewInit, AfterContentInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StripeService} from "ngx-stripe";
import { StripeElements, StripeCardElement, StripeElementsOptions } from '@stripe/stripe-js';
import { Router } from '@angular/router';
import { SpaceBookingService } from 'app/modules/shared/services/space-booking.service';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { NotificationService } from 'app/modules/shared/services/notification.service';
import { NotificationType } from 'app/helpers/enums/notificationType';
import { ModifySpaceBookingRequest } from 'app/modules/shared/models/booking/modify-space-booking-request';
import { request } from 'https';
import { SpaceBookingOptions } from 'app/modules/shared/models/booking/space-booking-options';
import { Dropdown } from '../../../shared/components/dropdown/dropdown.component';
import { PaymentService } from '../../services/payment.service';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { SubmissionService } from '../../../shared/services/submission.service';

@Component({
    selector: 'modify-payment',
    templateUrl: './modify-payment.component.html',
    styleUrls: ['./modify-payment.component.scss']
})
export class ModifyPaymentComponent implements AfterViewInit, OnInit  {


    faTimesCircle = faTimesCircle;
    @Input('request') request: ModifySpaceBookingRequest
    @ViewChild('cardDropdown') cardDropdown: Dropdown
    @ViewChild('cardElement') cardElement: ElementRef
    elements: StripeElements
    selectedSavedCard: null;
    card: StripeCardElement
    amount: number
    isSubmitted: boolean = false
    submissionInProgress: boolean = false
    userName: string

    // optional parameters
    elementsOptions: StripeElementsOptions = {
        locale: 'en',
    };

    stripeTest: FormGroup;

    constructor(
        public submissionService: SubmissionService,
        private fb: FormBuilder,
        private stripeService: StripeService,
        public bookingService: SpaceBookingService,
        public authService: AuthService,
        private notificationService: NotificationService,
        public paymentService: PaymentService,
        private router: Router) { }

    ngOnInit() {
        this.paymentService.getCards().subscribe();
    }

    ngAfterViewInit() {

        this.submissionInProgress = false;
        this.isSubmitted = false;
        this.amount = this.request.price.totalWithDiscount - this.request.originalBooking.price.totalWithDiscount;

        this.stripeTest = this.fb.group({
            name: [this.userName]
        });
        this.submissionInProgress = false;
        this.isSubmitted = false;
        this.userName = this.authService.getUserName()

        this.stripeService.elements(this.elementsOptions)
            .subscribe(elements => {
                this.elements = elements;

                this.card = this.elements.create('card', {
                    style: {
                        base: {
                            iconColor: '#666EE8',
                            color: '#31325F',
                            lineHeight: '40px',
                            fontWeight: 300,
                            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                            fontSize: '18px',
                            '::placeholder': {
                                color: '#CFD7E0'
                            }
                        },
                    },
                    hidePostalCode: true
                });
                this.card.mount(this.cardElement.nativeElement);
            });
    }


    cardInputComplete() {
        if (this.cardElement == null || this.cardElement.nativeElement == null) return false;
        return this.cardElement.nativeElement.classList.contains('StripeElement--complete');
    }

    pay()
    {
        this.submissionService.startSubmission();
        this.paymentService.takePayment(this.card).subscribe(
            result => {
                if (result.paymentIntent) {
                    //TODO: get real type not hardcoded
                    this.submissionInProgress = true;
                    this.bookingService.bookingOptions = new SpaceBookingOptions();
                    this.bookingService.bookingOptions.paymentType.name = "card";
                    this.bookingService.payForModification(this.request).subscribe((res) => {
                      this.submissionService.endSubmission();
                      if (res) {    
                        this.notificationService.notify(
                          NotificationType.Success,
                          "You successfully completed the booking modification"
                        );
                        this.router.navigate(["/index"]);
                      } else {
                        this.notificationService.notify(
                          NotificationType.Error,
                          "Modification could not be completed. Please try again later.",
                          "Booking Error"
                        );
                        this.submissionInProgress = false;
                      }
                    });
                } else if (result.error) {
                    this.notificationService.notify(NotificationType.Error, "Payment Failed. Please check your card information.", "Payment Error");
                    this.submissionInProgress = false;
                }
            }
        );
    }

    paySavedCard()
    {
        this.submissionService.startSubmission();
        this.paymentService.takePaymentSavedCard(this.selectedSavedCard).subscribe(
            result => {
                if (result.paymentIntent) {
                    this.submissionInProgress = true;
                    this.bookingService.bookingOptions = new SpaceBookingOptions();
                    this.bookingService.bookingOptions.paymentType.name = "card";
                    this.bookingService.payForModification(this.request).subscribe((res) => {
                      this.submissionService.endSubmission();
                      if (res) {    
                        this.notificationService.notify(
                          NotificationType.Success,
                          "You successfully completed the booking modification"
                        );
                        this.router.navigate(["/index"]);
                      } else {
                        this.notificationService.notify(
                          NotificationType.Error,
                          "Modification could not be completed. Please try again later.",
                          "Booking Error"
                        );
                        this.submissionInProgress = false;
                      }
                    });

                } else if (result.error) {
                    // Error creating the token
                    console.error(result.error.message);
                    this.notificationService.notify(NotificationType.Error, "Payment Failed. Please check your card information.", "Payment Error");
                    this.submissionInProgress = false;
                }
            }
        );
    }

    public getPayButtonLabel() {
        return "Book Space: CHF " + Math.round(this.amount * 100) / 100;
    }

    setSavedCard(evt) {
        this.selectedSavedCard = evt;
        console.log(evt);
    }

    savedCardToText(item): string {
        if (item.card != null)
            return item.card.brand.toUpperCase() + " ending in " + item.card.last4 + " (expires " + item.card.expMonth + "/" + item.card.expYear + ")";
    }

    removeCard() {
        this.selectedSavedCard = null;
        this.cardDropdown.currentValue = null;
    }
    public goBack() {
        console.error("Not implemented");
    }
}
