import { Component, ViewChild } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { AuthService } from "app/modules/shared/services/auth.service";
import { AdminWorkshopService } from "app/modules/admin/services/admin-workshop.service";

@Component({
    selector: 'unique-workshop-checkbox',
    templateUrl: 'unique-workshop-checkbox.component.html'
})
export class UniqueWorkshopCheckbox implements ICellRendererAngularComp {
    public params: any;
    public submitting: boolean;

    constructor(public authService: AuthService, public adminWorkshopService: AdminWorkshopService)
    { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    uniqueChanged(isChecked: boolean)
    {
        this.submitting = true;
        this.adminWorkshopService.setWorkshopUnique(this.params.data.workshopId, isChecked).subscribe(
            (success) => {
                this.submitting = false;
                if (success)
                {

                }
                else
                {
                    console.error("api error");
                    this.params.data.workshopId = !isChecked;
                }   
            }
        );

    }
}