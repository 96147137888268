import { Injectable } from "@angular/core";
import { throwError, Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { map, catchError, switchMap } from "rxjs/operators";
import { AdminPMReview } from "../models/admin-pm-review";

@Injectable()
export class AdminReviewService {
    
    public pmReviews: AdminPMReview[];

    constructor(private http: HttpClient) {

    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    getAllReviews(reportedOnly: boolean): Observable<AdminPMReview[]> {
        return this.http.get<AdminPMReview[]>("api/adminallreviews/" + reportedOnly)
            .pipe(
            map(result => {
                    this.pmReviews = result;
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    removeReview(id: number): Observable<boolean>
    {
        return this.http.delete<boolean>("api/adminRemoveReview/" + id)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    resolveReports(id: number): Observable<boolean> {
        return this.http.post<boolean>("api/adminResolveReports/" + id, null)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }
}