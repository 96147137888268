import { Component, Input, OnInit} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { AdminSpaceService } from "../../services/admin-space.service";
import { AdminWorkshopService } from "../../services/admin-workshop.service";
import { EbentoSpace } from "../../../shared/models/properties/ebento-space";
import { EbentoWorkshop } from "../../../shared/models/workshops/ebento-workshop";
import { PropertyManager } from "../../../shared/models/users/property-manager";
import { AdminUserService } from "../../services/admin-user.service";
import { ListingActivePeriod } from "../../../shared/models/billing/listing-active-period";
import { BillingCycle } from "../../../shared/models/billing/billing-cycle";

@Component({
    selector: 'listing-active-modal',
    templateUrl: "listing-active-modal.component.html"
})
export class ListingActiveModal implements OnInit {

    constructor(private activeModal: NgbActiveModal, private adminSpaceService: AdminSpaceService, private adminWorkshopService: AdminWorkshopService, private adminUserService: AdminUserService)
    { 
    }
    @Input() managerID: string;
    @Input() spaceID: string;
    @Input() workshopID: string;
    @Input() spaceName: string;
    @Input() workshopName: string;

    manager: PropertyManager;
    activePeriods: ListingActivePeriod[]

    ngOnInit()
    {
        console.log("rode");
        this.adminUserService.getUserInfo(this.managerID).subscribe(
            pm => {
                this.manager = pm as PropertyManager;
                console.log(this.manager.billingCycles);
                this.manager.billingCycles.sort((a, b) => { return new Date(b.firstDay).getTime() - new Date(a.firstDay).getTime()});
                console.log(this.manager.billingCycles);
            })
        if (this.spaceID != null)
            this.adminSpaceService.getSpaceActivity(this.spaceID).subscribe(
                (res) => { this.activePeriods = res; }
            );
        if (this.workshopID != null)
            this.adminWorkshopService.getWorkshopActivity(this.workshopID).subscribe(
                (res) => { this.activePeriods = res; }
            );
    }

    public getCycleDates(cycle) {
        var str = new Date(cycle.firstDay).toLocaleDateString() + ' - ' + ((cycle.isOngoing) ? ' Ongoing ' :  new Date(cycle.lastDay).toLocaleDateString());
        return str;
    }

    public getActivePeriodsForCycle(cycle: BillingCycle) {
        var cycleFirst = new Date(cycle.firstDay);
        var cycleLast = new Date(cycle.lastDay);
    
        var result = [];
        for (let ap of this.activePeriods) {
            var apEnd = null;
            var apStart = new Date(ap.startTime);
            if (ap.endTime == null)
                apEnd = new Date();
            else
                apEnd = new Date(ap.endTime);

            if (apStart < cycleFirst && apEnd >= cycleFirst && apEnd <= cycleLast) {
                var period = new ListingActivePeriod();
                period.startTime = cycleFirst;
                period.endTime = apEnd;
                result.push(period);
            }
            else if (apStart < cycleFirst && apEnd >= cycleLast) {
                var period = new ListingActivePeriod();
                period.startTime = cycleFirst;
                period.endTime = cycleLast;
                result.push(period);
            }
            else if (apStart >= cycleFirst && apEnd <= cycleLast) {
                var period = new ListingActivePeriod();
                period.startTime = apStart;
                period.endTime = apEnd;
                result.push(period);
            }
            else if (apStart >= cycleFirst && apStart <= cycleLast && apEnd > cycleLast) {
                var period = new ListingActivePeriod();
                period.startTime = apStart;
                period.endTime = cycleLast;
                result.push(period);
            }
        }
        return result;
    }

    getLapString(lap: ListingActivePeriod) {
        return "ACTIVE: " + new Date(lap.startTime).toLocaleDateString() + ' - ' + new Date(lap.endTime).toLocaleDateString();
    }

    Close()
    {
        this.activeModal.close('Close click');
    }

    getListingName() {
        if (this.spaceName != null)
            return this.spaceName;
        if (this.workshopName != null)
            return this.workshopName;
        return "";
    }

}