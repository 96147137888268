export class AmenityType {

    public name: string
    public id: number
    public isCatering: boolean
    public isGlobal: boolean

    constructor() { }


}