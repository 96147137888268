import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EbentoNews } from '../../../../models/misc/ebento-news';
import { NewsService } from '../../../../services/news.service';


@Component({
    selector: 'news-preview',
    templateUrl: "news-preview.component.html",
    styleUrls: ['./news-preview.component.css']
})
export class NewsPreview
{
    @Input() news: EbentoNews

    constructor(private router: Router, private newsService: NewsService)
    { }
        
    GetTimestamp(): string
    {
        return "" + new Date(this.news.publishDate).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false });
    }

    open()
    {
        this.newsService.openNews = this.news;
        this.router.navigate(["news-page"]);
    }
}

