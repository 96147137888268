import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EbentoUser } from 'app/modules/shared/models/users/ebento-user';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { ChangePasswordModalComponent } from 'app/modules/shared/modules/modals/components/change-password-modal/change-password-modal.component';
import { EditEmailModalComponent } from 'app/modules/shared/modules/modals/components/edit-email-modal/edit-email-modal.component';
import { BasicModal } from '../../../shared/modules/modals/components/basic-modal/basic-modal.component';
import { NotificationService } from '../../../shared/services/notification.service';
import { NotificationType } from '../../../../helpers/enums/notificationType';
import { TranslateService } from '@ngx-translate/core';
import { Booking } from '../../../shared/models/booking/booking';
import { AccountDeactivationModalComponent } from '../../../shared/modules/modals/components/account-deactivation-modal/account-deactivation-modal.component';
import { ConfirmationModalsComponent } from '../../../shared/modules/modals/components/confirmation-modals/confirmation-modals.component';
import { EbentoModalService } from '../../../shared/services/ebento-modal.service';

@Component({
    selector: 'app-account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {

    user: EbentoUser;
    accountCreated: string;
    isPM: boolean;

    constructor(
        public authService: AuthService,
        private router: Router,
        private modalService: EbentoModalService,
        private notificationService: NotificationService,
        private translate: TranslateService) { }

    ngOnInit() {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(["/index"])
        }
        this.user = this.authService.user;

        this.isPM = this.user.userType === "Property Manager";

        this.accountCreated = new Date(this.user.created).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false });
    }

    changePasswordModal() {

        const modalRef = this.modalService.open(ChangePasswordModalComponent);
        modalRef.componentInstance.title = 'Change Password';
        modalRef.componentInstance.passEntry.subscribe(() => this.modalService.close());
    }

    changeEmailModal() {

        const modalRef = this.modalService.open(EditEmailModalComponent);
        modalRef.componentInstance.title = 'Change Email Address';
        modalRef.componentInstance.passEntry.subscribe(() => this.modalService.close());
    }

    deactivate() {

        const modalRef = this.modalService.open(ConfirmationModalsComponent);
        modalRef.componentInstance.title = this.translate.instant('Account Deactivation');
        modalRef.componentInstance.body = this.translate.instant('Do you really want to deactivate your account?');
        modalRef.componentInstance.passEntry.subscribe((answer) => {
            if (answer === 'confirmed') {
                this.authService.deactivate().subscribe(
                    (res: Booking[]) => {
                        if (res.length === 0) {
                            const message = this.translate.instant('Account successfully deactivated');
                            this.notificationService.notify(NotificationType.Success, message, null, { timeOut: 10000 });
                            this.authService.logout().subscribe();
                            this.router.navigate(['/index']);
                        } else {
                            const title = this.translate.instant('To deactivate your account please cancel following bookings');
                            const modalRef = this.modalService.open(AccountDeactivationModalComponent);
                            modalRef.componentInstance.title = title;
                            modalRef.componentInstance.bookings = res;
                            modalRef.componentInstance.passEntry.subscribe(() => this.modalService.close());
                        }
                    },
                    (err) => {
                        this.notificationService.notify(NotificationType.Error, err.error, null, { timeOut: 10000 });
                    });
            }
            this.modalService.close()
        });
    }

    editEmail () {
        this.router.navigate(['edit-email']);
    }

    editPassword () {
        this.router.navigate(['edit-password']);
    }

}
