import { TimeAllocation } from "../data-classes/time-allocation";
import { PropertyAddress } from "../properties/property-address";
import { SpaceType } from "../properties/space-type";

export class SearchSpaceQuery
{
    constructor(
        public timeAllocations: TimeAllocation[], 
        public addressSearchString: string, 
        public address: PropertyAddress, 
        public numberofattendees: number, 
        public amenityids: number[], 
        public spacetypeids: number[],
        public spaceTypes: SpaceType[])
    { }
}
