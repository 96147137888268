import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { ManagerBookingService } from "../../services/manager-booking.service";
import { SpaceService } from "app/modules/shared/services/space.service";
import { WorkshopService } from 'app/modules/shared/services/workshop.service'
import { ClickablePlannerName } from "../clickable-planner-name/clickable-planner-name.component";
import { BookerPlannerModal } from "../booker-planner-modal/booker-planner-modal.component";
import { TimeAllocation } from "app/modules/shared/models/data-classes/time-allocation";
import { BookingAmenity } from "app/modules/shared/models/booking/booking-amenity";
import { WsBookingTimeAllocation } from "app/modules/shared/models/data-classes/ws-booking-time-allocation";
import { BookingNameCell } from "../booking-name-cell/booking-name-cell.component";
import { BookedOnCell } from "../booked-on-cell/booked-on-cell.component";
import { EbentoModalService } from "../../../shared/services/ebento-modal.service";
import { AuthService } from "app/modules/shared/services/auth.service";
import { MessagingService } from "app/modules/shared/services/messaging.service";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "booking-table",
  templateUrl: "booking-table.component.html",
  styleUrls: ["./booking-table.component.scss"],
})
export class BookingTable {
  
    modalRef: NgbModalRef;
  gridOptions: GridOptions;
  apiRef;
  
  constructor(
    public messagingService: MessagingService,
    public authService: AuthService,
    public managerBookingService: ManagerBookingService,
    public spaceService: SpaceService,
    private modalService: EbentoModalService,
    private router: Router,
    public workshopService:WorkshopService
  ) {
    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this,
      },
      enableColResize: true,
    };
  }

  columnDefs = [
    {
      headerName: "Booking",
      field: "spaceName",
      sortable: true,
      cellRendererFramework: BookingNameCell,
      autoHeight: true,
    },
    {
      headerName: "Time",
      field: "timeAllocations",
      sortable: true,
      comparator: this.TimeAllocationComparator,
      minWidth: 140,
      autoHeight: true,
      cellRenderer: (param) =>
        this.FormatTimeAllocations(param.data.timeAllocations),
    },
    {
      headerName: "Booked On",
      field: "created",
      sortable: true,
      cellRendererFramework: BookedOnCell,
      autoHeight: true,
    },
    {
      headerName: "Customer",
      field: "planner",
      sortable: true,
      cellRendererFramework: ClickablePlannerName,
      autoHeight: true,
    },
    {
      headerName: "Price",
      field: "price.totalWithDiscount",
      sortable: true,
      cellRenderer: function (param) {
        return "CHF " + param.data.price.totalWithDiscount;
      },
      autoHeight: true,
    },
    {
      headerName: "Payment",
      field: "paymentStatus.name",
      sortable: true,
      autoHeight: true,
    },
    {
      headerName: "Special",
      field: "specialRequest",
      sortable: true,
      autoHeight: true,
    },
    {
      headerName: "Catering",
      field: "bookingAmenities",
      sortable: true,
      cellRenderer: (param) =>
        this.GetCateringString(param.data.bookingAmenities),
      autoHeight: true,
    },
    {
      headerName: "Amenities",
      field: "bookingAmenities",
      sortable: true,
      cellRenderer: (param) =>
        this.GetAmenitiesString(param.data.bookingAmenities),
      autoHeight: true,
    },
    {
      headerName: "Attendees",
      field: "attendees",
      sortable: true,
      autoHeight: true,
    },
  ];

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
    this.apiRef = params.api;
  }

  gotoProfile(data) {
    this.managerBookingService
      .getEventPlannerInfo(data.eventPlannerID)
      .subscribe();
    this.router.navigate(["customer-details"]);
  }

  sendMessage(data) {
    console.log("data>>>>>>>", data);
    this.authService
      .getPlannerInfoByID(data.eventPlannerID)
      .subscribe((user) => {
        this.messagingService.currentRecepient = user;
        this.messagingService.currentDraft = new EbentoMessage();
        this.router.navigate(["/messages/compose"]);
      });
  }

  public invokeParentMethod(data) {
    this.openPlannerModal(data.eventPlannerID);
    // data.context.componentParent.openPlannerModal(data.eventPlannerID);
  }

  cancelBooking(data) {
    if (data.workshopID != null)
      this.router.navigate([
        "/property-manager/cancel-experience/" + data.idBooking,
      ]);
    else
      this.router.navigate([
        "/property-manager/cancel-booking/" + data.idBooking,
      ]);
  }

  openPlannerModal(userID: number) {
    // const modalRef = this.modalService.open(ModalComponent);
    var modalRef = this.modalService.open(BookerPlannerModal, { size: "md" });
    modalRef.componentInstance.title = "Reservation Calendar";
    modalRef.componentInstance.plannerID = userID;
  }

  TimeAllocationComparator(ta1: TimeAllocation[], ta2: TimeAllocation[]) {
    var MinDate1 = new Date(ta1[0].startTime);
    for (let allocation of ta1) {
      var newMinDate1 = new Date(allocation.startTime);
      if (newMinDate1 < MinDate1) MinDate1 = newMinDate1;
    }
    var MinDate2 = new Date(ta2[0].startTime);
    for (let allocation of ta2) {
      var newMinDate2 = new Date(allocation.startTime);
      if (newMinDate2 < MinDate2) MinDate2 = newMinDate2;
    }
    var result = 0;
    if (MinDate1 > MinDate2) result = 1;
    else if (MinDate1 < MinDate2) result = -1;
    //if (isInverted)
    //    result = -result;
    return result;
  }

  FormatTimeAllocations(timeAllocations: TimeAllocation[]): string {
    var result = "";

    timeAllocations.sort(function (a, b) {
      var date1 = new Date(a.startTime);
      var date2 = new Date(b.startTime);
      if (date1 < date2) return -1;
      if (date1 > date2) return 1;
      return 0;
    });
    for (let allocation of timeAllocations) {
      var dateStart = new Date(allocation.startTime);
      var dateEnd = new Date(allocation.endTime);
      result +=
        "<b>" +
        dateStart.toLocaleDateString() +
        "</b> <br/> " +
        "    " +
        dateStart.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }) +
        " - " +
        dateEnd.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
        " <br/> ";
    }
    return result;
  }

  GetCateringString(amenities: BookingAmenity[]): string {
    var result = "";
    for (let amenity of amenities) {
      var amenityType = this.spaceService.GetAmenityTypeByID(
        amenity.amenityTypeID
      );
      if (amenityType != null)
        if (amenityType.isCatering) {
          if (result != "") result += "</br>";
          if (amenity.quantity > 1) result += amenity.quantity + " × ";
          result += amenityType.name;
        }
    }
    return result;
  }

  GetAmenitiesString(amenities: BookingAmenity[]): string {
    var result = "";
    for (let amenity of amenities) {
      var amenityType = this.spaceService.GetAmenityTypeByID(
        amenity.amenityTypeID
      );
      if (amenityType != null)
        if (!amenityType.isCatering) {
          if (result != "") result += "</br>";
          if (amenity.quantity > 1) result += amenity.quantity + " × ";
          result += amenityType.name;
        }
    }
    return result;
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.apiRef.sizeColumnsToFit();
    this.apiRef.resetRowHeights();
  }
}
