import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationType } from "app/helpers/enums/notificationType";
import { ContactUs } from "app/modules/shared/models/users/contact-us";
import { AuthService } from "app/modules/shared/services/auth.service";
import { ContactUsService } from "app/modules/shared/services/contact-us.service";
import { NotificationService } from "app/modules/shared/services/notification.service";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.css"],
})
export class ContactUsComponent implements OnInit {
  
  // public contact: ContactUs;

  contact = new ContactUs('', '', '', '', '');

  constructor(
    public authService: AuthService,
    public router: Router,
    public contactUsService: ContactUsService,
    public notificationService: NotificationService
  ) {}

  ngOnInit() {
    // if (!this.authService.isLoggedIn()) {
    //   this.router.navigate(["/index"]);
    // }
  }

  submit() {
    // console.log("contact us ", this.contact);
    this.contactUsService.contactUs(this.contact).subscribe(
      (res) => {
        if (res) {
          this.notificationService.notify(
            NotificationType.Success,
            "Contact us form submitted"
          );
        }
      },
      () => {
        this.notificationService.notify(
          NotificationType.Error,
          "Errors on sending the form"
        );
      }
    );
  }
}
