import { Component, Input } from '@angular/core';
import { SpaceBookingService } from 'app/modules/shared/services/space-booking.service';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { BookingAmenity } from '../../../shared/models/booking/booking-amenity';
// import { PaymentService } from '../../services/payment.service';
import { PaymentService } from '../../../event-planner/services/payment.service';
import { UpdatePaymentIntent } from '../../../shared/models/payment/update-payment-intent';
import { AddEntertainmentService } from '../../../property-manager/services/add-entertainment.service';

@Component({ 
    selector: 'entertainment-booking-recap',
    templateUrl: 'entertainment-booking-recap.component.html',
    styleUrls: ['entertainment-booking-recap.component.css']
})

export class EntertainmentBookingRecap
{

    @Input() isEnquiry: boolean = false;
    couponCode: string
    couponStatus = 'none'

    constructor(
        public bookingService: SpaceBookingService,
        public paymentService: PaymentService,
        public spaceService: SpaceService,
        public entertainmentService:AddEntertainmentService
        )
    {
    }

    amenityString(amenity: BookingAmenity) : string
    {
        return this.spaceService.GetAmenityTypeByID(amenity.amenityTypeID).name;
    }

    checkCouponCode()
    {
        this.couponStatus = 'loading';
        if (this.couponCode == "")
        {
            this.couponStatus = 'none';
        }
        else
        {
            this.entertainmentService.bookingOptions.uniqueCode = this.couponCode;
            this.bookingService.checkCoupon(this.couponCode, this.bookingService.space.spaceId).subscribe(coupon => {
            if (coupon != null) {
                this.entertainmentService.bookingOptions.coupon = coupon;
                this.couponStatus = 'valid';
                     }
            else {
                this.bookingService.bookingOptions.coupon = null;
                this.couponStatus = 'invalid';
                }

                this.paymentService.updatePaymentIntent(new UpdatePaymentIntent(this.entertainmentService.TotalPrice(true), this.entertainmentService.bookingOptions.paymentIntentID)).subscribe();
            });
        }
    }
}
