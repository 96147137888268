import { Component, OnInit, InjectionToken, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { AdminSpaceService } from 'app/modules/admin/services/admin-space.service';
import { InjectableItem } from '../../index-carousel/injectable-item';
import { CarouselTile } from '../carousel-tile.component';
import { EbentoWorkshop } from 'app/modules/shared/models/workshops/ebento-workshop';
import { WorkshopBookingService } from '../../../../../shared/services/workshop-booking.service';

@Component({
    selector: 'favorite-workshop-carousel-tile',
    templateUrl: 'favorite-workshop-carousel-tile.component.html',
    styleUrls: ['favorite-workshop-carousel-tile.component.css'],
})
export class FavoriteWorkshopCarouselTile extends CarouselTile implements OnInit {

    constructor(injection: InjectableItem, private bookingService: WorkshopBookingService, private router: Router, public authService: AuthService)
    {
            super(injection);
    }
    
    ngOnInit() {
      }

    public Redirect()
    {
        var workshop = this.item as EbentoWorkshop;
        if (this.authService.isLoggedIn() && this.authService.userType == "EventPlanner") {
            this.bookingService.startNewBooking(workshop.id);
        }
        else {
            this.router.navigate(['experience-details/' + workshop.id ]);

        }
    }
}
