/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input, Injector, ChangeDetectorRef } from '@angular/core';
import { IndexCarousel } from '../index-carousel.component';
import { WorkshopService } from 'app/modules/shared/services/workshop.service';
import { WorkshopCarouselTile } from '../../carousel-tile/workshop-carousel-tile/workshop-carousel-tile.component';
import { SearchCarouselTile } from '../../carousel-tile/search-tile/search-carousel-tile.component';
import { GeolocationService } from 'app/modules/shared/services/geolocation.service';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { SearchService } from 'app/modules/event-planner/services/search.service';
import { Router } from '@angular/router';
import { PropertyAddress } from 'app/modules/shared/models/properties/property-address';
import { SearchWorkshopQuery } from 'app/modules/shared/models/search/search-workshop-query';
import { CityTile } from 'app/modules/shared/models/search/city-tile';

@Component({
    selector: 'local-workshops',
    templateUrl: '../index-carousel.component.html'
})


// !!!
// Now shows all workshops
// !!!

export class LocalWorkshops extends IndexCarousel implements OnInit {

    @Input() color:string = '#212529';
    @Input() headerLabel = 'Popular Experiences in ' + ((this.geolocation.myCity != null) ? this.geolocation.myCity.country : 'Local Area');
    protected tileComponent = SearchCarouselTile;
    domID = 'local-workshops-';

    constructor(private cdr: ChangeDetectorRef, private geolocation: GeolocationService, private router: Router, public spaceService: SpaceService, public searchService: SearchService, injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        if (this.geolocation.myCity == null) {
            this.geolocation.GetLocation.subscribe(
                (myCity) => {
                    this.spaceService.getLocalCitiesForCity(myCity).subscribe(
                        success => {
                            if (success) {
                                if (success != null && success.length > 0)
                                {
                                    this.items = this.itemsFromResult(success);

                                    this.buildInjectors();
                                    this.calculateNumberOfNplets();
                                    this.cdr.detectChanges();
                                }
                            }
                        },
                        error => { this.errorMessage = error; console.error("fail. " + this.errorMessage); }
                    );
                });
        }
        else
            this.spaceService.getLocalCitiesForCity(this.geolocation.myCity).subscribe(
                success => {
                    if (success) {
                        if (success != null && success.length > 0) {
                            this.items = this.itemsFromResult(success);
                            this.buildInjectors();
                            this.calculateNumberOfNplets();
                            this.cdr.detectChanges();
                        }
                    }
                },
                error => { this.errorMessage = error; console.error("fail. " + this.errorMessage); }
            );
    }

    get apiGetter() {
        return null;
    }
    public invokable(cityTile: CityTile) {
        var query = new SearchWorkshopQuery(null, true, cityTile.name + ", " + cityTile.country, new PropertyAddress(cityTile.country, cityTile.name, null, null, null, null, -1, -1),undefined, [], [],[],[],[]);
        this.searchService.searchWorkshopQuery = query;
        this.searchService.findWorkshops().subscribe(
            success => {
                this.router.navigate(['experience-search-results']);
            },
            error => { console.error(error.message) }
        );
    }
}
