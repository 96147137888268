import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { SpaceService } from 'app/modules/shared/services/space.service';


@Component({
  selector: 'space-index-planner',
  templateUrl: 'space-index.component.html',
  styleUrls: ['./space-index.component.css']
})
    
export class SpaceIndexPagePlanner implements OnInit
{

    ngOnInit()
    {
    }

    constructor(public spaceService: SpaceService, public router: Router) {
    }
}
