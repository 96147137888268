export class PMBookingsOverview
{
    public monthOfYear: string
    public propertyBookings: PropertyBookingOverview[]
}

export class PropertyBookingOverview
{
    public propetyName: string
    public spaceBookings: SpaceBookingOverview[]
}

export class SpaceBookingOverview
{
    public spaceName: string
    public bookingPercentages: BookingPercentage[]
}

export class BookingPercentage
{
    public date: string
    public percentage: number
}