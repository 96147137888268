import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'boolean-cell',
  templateUrl: './boolean-cell.component.html',
  styleUrls: ['./boolean-cell.component.scss']
})
export class BooleanCellComponent implements ICellRendererAngularComp {
    //fa
    faCheckSquare = faCheckSquare;
    faSquare = faSquare;
    //fa

    public params: any;

    constructor() { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return true;
    }

}
