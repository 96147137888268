import { Injectable } from "@angular/core";
import { throwError, Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { map, catchError, switchMap } from "rxjs/operators";
import { EbentoSpace } from "../models/properties/ebento-space";
import { PropertyManagerReview } from "../models/users/property-manager-review";
import { PropertyManager } from "../models/users/property-manager";
import { EbentoWorkshop } from "../models/workshops/ebento-workshop";

@Injectable()
export class ReviewService {
    public space: EbentoSpace
    public workshop: EbentoWorkshop
    public propertyManagerReview: PropertyManagerReview;

    constructor(private http: HttpClient) {

    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    getReviewsForSpace(spaceId: number): Observable<PropertyManagerReview[]> {
        return this.http.get<PropertyManagerReview[]>("api/reviewsForSpace/" + spaceId)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    getReviewsForWorkshop(workshopId: number): Observable<PropertyManagerReview[]> {
        return this.http.get<PropertyManagerReview[]>("api/reviewsForWorkshop/" + workshopId)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    reviewPropertyManager(): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post<boolean>("api/reviewPropertyManager", JSON.stringify(this.propertyManagerReview), httpOptions)
            .pipe(
                map(
                    result => {
                        //console.log("Reviewed. " + result);
                        return result;
                    }),
                catchError(this.errorHandler)
            );
    }

    reportPMReview(reviewID: number): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post<boolean>("api/reportPMReview/" + reviewID, null, httpOptions)
            .pipe(
                map(
                    result => {
                        //console.log("Reviewed. " + result);
                        return result;
                    }),
                catchError(this.errorHandler)
            );
    }

    // misc

    public getPMName(): string
    {
        var propertyManager = null;
        if (this.space != null)
            propertyManager = this.space.propertyManager;
        if (this.workshop != null)
            propertyManager = this.workshop.propertyManager;
        return propertyManager.firstName + ' ' + propertyManager.lastName;
    }
}