import { Component, OnInit, Input } from '@angular/core';

import { Router } from "@angular/router";
import { EbentoSpace } from '../../models/properties/ebento-space';
import { AuthService } from '../../services/auth.service';
import { faUsers} from '@fortawesome/free-solid-svg-icons';
import { SpaceBookingService } from '../../services/space-booking.service';

@Component({
    selector: 'property-details-space-tile',
    templateUrl: 'property-details-space-tile.component.html',
    styleUrls: ['property-details-space-tile.component.scss']
})

export class PropertyDetailsSpaceTile {

    //fa 
    faUsers = faUsers;

    @Input() space: EbentoSpace

    constructor(private router: Router, public bookingService: SpaceBookingService, public authService: AuthService) {
    }

    openSpaceDetails() {
        if (this.authService.userType == "EventPlanner")
            this.bookingService.startNewBooking(this.space.spaceId);

        else
            this.router.navigate(['space-details/' + this.space.spaceId]);
    }
}
