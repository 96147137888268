export class BookingAmenity
{
    public amenityTypeID: number
    public quantity: number
    public unitPrice: number

    //TODO: doesn't recognize this function in space listing preview mode?!
    public get totalPrice(): number
    {
        return this.quantity * this.unitPrice;
    }
}