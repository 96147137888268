import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { throwError } from 'rxjs';
import { SpaceBooking } from "app/modules/shared/models/booking/space-booking";
import { PaymentStatus } from "app/modules/shared/models/booking/payment-status";
import { EventPlanner } from "app/modules/shared/models/users/event-planner";
import { PMBookingsFilter } from "app/modules/shared/models/booking/pm-bookings-filter";
import { Booking } from "app/modules/shared/models/booking/booking";
import { SpaceBookingOptions } from "app/modules/shared/models/booking/space-booking-options";
import { SpaceBookingRequest } from "app/modules/shared/models/booking/space-booking-request";
import { ModifySpaceBookingRequest } from "app/modules/shared/models/booking/modify-space-booking-request";
import { ModifyRequestReply } from "app/modules/shared/models/booking/modify-booking-request-reply";
import { ModifyWorkshopBookingRequest } from "app/modules/shared/models/booking/modify-workshop-booking-request";
import { PMBookingCancellation } from "app/modules/shared/models/booking/pm-booking-cancellation";

@Injectable()
export class ManagerBookingService {


    public bookings: Booking[];
    public paymentStatuses: PaymentStatus[];
    public eventPlanner: EventPlanner;

    constructor(private http: HttpClient)
    {
    }

    getEventPlannerInfo(id: string): Observable<EventPlanner> {
        return this.http.get <EventPlanner>("api/eventPlannerInfo/"+id)
            .pipe(
                map(result => this.eventPlanner = result),
                catchError(this.errorHandler)
            );
    }

    getPaymentStatuses(): Observable<PaymentStatus[]> {
        return this.http.get<PaymentStatus[]>("api/paymentstatus")
            .pipe(
            map(result => this.paymentStatuses = result),
                catchError(this.errorHandler)
            );
    }

    getBookingsWithFilter(pmBookingsFilter: PMBookingsFilter): Observable<SpaceBooking[]> {
        return this.http.post<SpaceBooking[]>("api/pmbookings", pmBookingsFilter)
            .pipe(
                map(result => this.bookings = result),
                catchError(this.errorHandler)
            );
    }

    getBookings(): Observable<Booking[]> {
        return this.http.get<Booking[]>("api/pmbookings")
            .pipe(
            map(result => { this.bookings = result;
       // console.log(JSON.stringify(result));
        return result;}),
                catchError(this.errorHandler)
            );
    }

    getBookingHistory(): Observable<Booking[]>{
        return this.http.get<Booking[]>("API/GetBookingHistoryAll").pipe(
            map(result => { 
                this.bookings = result;
                console.log("history",JSON.stringify(result));
                 return result;
                }),
                catchError(this.errorHandler) 
        )
    }

    getUpcomingBooking(): Observable<Booking[]>{
        return this.http.get<Booking[]>("API/GetUpcomingBookings")
        .pipe(
            map(result => { 
                this.bookings = result;
       // console.log(JSON.stringify(result));
        return result;}),
                catchError(this.errorHandler)
        );
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    public getModifyRequest(id: number): Observable<ModifySpaceBookingRequest> {
        return this.http.get<ModifySpaceBookingRequest>("api/pm-modify-booking-request/" + id)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    public acceptBookingModification(requestID: number): Observable<boolean> {
        var reply = new ModifyRequestReply(requestID, true, "");
        return this.http.post<boolean>("api/reply-booking-modification", reply)
            .pipe(
                map(result => {
                return result; 
                }),
                catchError(this.errorHandler)
            );
    }

    public rejectBookingModification(requestID: number, rejectMessage: string): Observable<boolean> {
        var reply = new ModifyRequestReply(requestID, false, rejectMessage);
        return this.http.post<boolean>("api/reply-booking-modification", reply)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    public getWsModifyRequest(id: number): Observable<ModifyWorkshopBookingRequest> {
        return this.http.get<ModifyWorkshopBookingRequest>("api/pm-modify-ws-booking-request/" + id)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    public acceptWsBookingModification(requestID: number): Observable<boolean> {
        var reply = new ModifyRequestReply(requestID, true, "");
        return this.http.post<boolean>("api/reply-ws-booking-modification", reply)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    public rejectWsBookingModification(requestID: number, rejectMessage: string): Observable<boolean> {
        var reply = new ModifyRequestReply(requestID, false, rejectMessage);
        return this.http.post<boolean>("api/reply-ws-booking-modification", reply)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    public cancelBooking(bookingID: number, message: string): Observable<boolean> {
        var reply = new PMBookingCancellation(bookingID, message);
        return this.http.post<boolean>("api/pm-cancel-booking", reply)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    public cancelWsBooking(bookingID: number, message: string): Observable<boolean> {
        var reply = new PMBookingCancellation(bookingID, message);
        return this.http.post<boolean>("api/pm-cancel-ws-booking", reply)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }
}