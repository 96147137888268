import { Component, Input } from "@angular/core";

@Component({
    selector: 'custom-accordion-component',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss']
})

export class AccordionComponent{

    @Input() header:string;
    @Input() body:string;

    public visible:boolean = true;

    constructor(){
        console.log("header and body",this.header,this.body);
        
    }

    makeVisible(){
        this.visible = !this.visible;
    }
}