import { Component, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EbentoProperty } from '../../models/properties/ebento-property';
import { PropertyService } from '../../services/property.service';
import { SpaceService } from '../../services/space.service';
import { EbentoSpace } from '../../models/properties/ebento-space';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'property-spaces-carousel',
    templateUrl: 'property-spaces-carousel.component.html',
    styleUrls: ['property-spaces-carousel.component.scss']
})
export class PropertySpacesCarousel {
    @Input() property: EbentoProperty;
    public truthyValue = false;

    @ViewChild('carousel', { read: NgbCarousel }) carousel: NgbCarousel;
    constructor(private propertyService: PropertyService, public spaceService: SpaceService, private router: Router)
    {
        this.getScreenSize();
    }

    public numbersOfNplets(n): number[] {
        var non = new Array();
        for (var i = 0; i < Math.ceil(this.property.spaces.filter(s => !s.isDummyForWorkshops).length / n); i++) {
            non.push(i);
        }
        return non;
    }
    public GetNplet(index: number,n): EbentoSpace[] {
        var result: EbentoSpace[] = new Array();
        var i = 0;
        while (i < n && i + index < this.property.spaces.filter(s => !s.isDummyForWorkshops).length) {
            result.push(this.property.spaces.filter(s => !s.isDummyForWorkshops)[i + index]);
            i++;
        }
        // console.log('first carousel',result);
        
        return result;
    }

    public propertyDetails() {
        this.propertyService.getProperty(this.property.id)
            .subscribe(
                () => this.router.navigate(['/property-details']),
                //error => this.errorEmiter.emit(error) <- Treba li nam ovo ?
            );
    }

    public spaceDetails(space: EbentoSpace) {
        this.spaceService.getSpace(space.spaceId)
            .subscribe(
                () => this.router.navigate(['/space-details/'+space.spaceId]),
                //error => this.errorEmiter.emit(error) <- Treba li nam ovo ?
            );
    }

    scrHeight: any;
    scrWidth: any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        // console.log(this.scrHeight, this.scrWidth);
    }

    getColumnCount() {
        //under 576px it should be 1
        //above 1300 4 
        return (this.scrWidth >= 1200) ? 4 : (this.scrWidth >= 992) ? 3 : this.scrWidth < 576 ? 1 : 2;
    }

    public swipeLeft() {
        this.carousel.next();
    }

    public swipeRight() {
        this.carousel.prev();
    }

    public stop(evt: Event, space: EbentoSpace) {
        evt.stopImmediatePropagation();
        evt.preventDefault();
        var newValue = !space.isListingActive;
        this.spaceService.setSpaceActive(space.spaceId, newValue).subscribe(
            (res) => { space.isListingActive = newValue; }
        );
    }
}

