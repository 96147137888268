/// <reference types="@types/googlemaps" />

import { Component , OnInit , Input , ViewChild, AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PropertyAddress } from '../../models/properties/property-address';
import { PropertyService } from '../../services/property.service';
import { SpaceService } from '../../services/space.service';
import { SearchService } from '../../../event-planner/services/search.service';
import { SearchCalendarModal } from '../../modules/modals/components/search-calendar-modal/search-calendar-modal.component'
import { WorkshopService } from '../../services/workshop.service';
import { SearchWorkshopQuery } from '../../models/search/search-workshop-query';
import { WorkshopTimeAllocation } from '../../models/data-classes/workshop-time-allocation';
import { BookingSegment } from '../../models/booking/booking-segment';
import { EbentoModalService } from '../../services/ebento-modal.service';
import { AddEntertainmentService } from '../../../property-manager/services/add-entertainment.service';
import { Category } from '../../models/entertainment/categories';
import { SubmissionService } from '../../services/submission.service';


@Component({
    selector: 'ebento-searchBox',
    templateUrl: './search-box.component.html',
    styleUrls: [ './search-box.component.css' ]
})

export class SearchBox implements OnInit,AfterViewInit{

    faMinusCircle = faMinusCircle;

    numberOfAttendees: number=4;
    modalRef: NgbModalRef;
    address: PropertyAddress;
    autocomplete: google.maps.places.Autocomplete;
    addressSearchString: string; 
    categories:Category[];
    selectedCategory:number = 0;
    initCategoryId:number;

    componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'

    };

    propertyMapping = {
        street_number: "streetnumber",
        route: "streetaddress",
        locality: "city",
        administrative_area_level_1: "",
        country: "country",
        postal_code: "postalcode"
    }

    constructor(
        private modalService: EbentoModalService,
        private propertyService: PropertyService,
        public workshopService: WorkshopService,
        public spaceService: SpaceService,
        public searchService: SearchService,
        private router: Router,
        private submissionService:SubmissionService,
        private entertainmentService:AddEntertainmentService
    ){
        this.initCategoryId = this.entertainmentService.searchCategory;
    }

    ngOnInit(){
        this.entertainmentService.getCatogories().subscribe((response) => {
            this.categories = response;
        })
        this.searchService.calendarTimeSelection = null;
        
    }

    ngAfterViewInit(): void {
        this.autocomplete = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(<HTMLInputElement>document.getElementById('autocomplete-wsfi')),
            { types: ['geocode'] });

        // When the user selects an address from the dropdown, populate the address
        // fields in the form.
        this.autocomplete.addListener('place_changed', () => { this.fillInAddress(); });
        this.address = new PropertyAddress(null, null, null, null, null, null, 0, 0);
    }

    fillInAddress() {

        var place = this.autocomplete.getPlace();
        var searchPlaceAutocompleteLatLng = place.geometry.location;
        
        this.address.googlemapcoordx = place.geometry.location.lat();
        this.address.googlemapcoordy = place.geometry.location.lng();
        var streetSpecific = false;
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (this.propertyMapping[addressType] == "streetaddress")
                streetSpecific = true;
        }

        //console.log("StreetSpecific: " + streetSpecific);
        if (!streetSpecific) {

            var geocoder = new google.maps.Geocoder;
            geocoder.geocode({
                'location': searchPlaceAutocompleteLatLng}, (results, status) => {
                // console.log("wow." + JSON.stringify(results[0]));

                var placeFromGeocode = results[0];
                var fields = [];
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (this.propertyMapping[addressType]) {
                        //console.log("1. set " + this.propertyMapping[addressType] + " to null.");
                        fields[this.propertyMapping[addressType]] = true;
                        //this.searchService.searchSpaceQuery.address[this.propertyMapping[addressType]] = null;
                    }
                }
                this.address = new PropertyAddress(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

                for (var i = 0; i < placeFromGeocode.address_components.length; i++) {

                    var addressType = placeFromGeocode.address_components[i].types[0];
                    if (this.componentForm[addressType]) {
                        var val = placeFromGeocode.address_components[i][this.componentForm[addressType]];
                        if (this.propertyMapping[addressType] && fields[this.propertyMapping[addressType]]) {
                            //console.log("2. set " + this.propertyMapping[addressType] + " to [" + val + "].");
                            this.address[this.propertyMapping[addressType]] = val;
                        }
                    }
                }
            });
        }

        else {
            /*
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (this.propertyMapping[addressType]) {
                    //console.log("1. set " + this.propertyMapping[addressType] + " to null.");
                    //this.searchService.searchSpaceQuery.address[this.propertyMapping[addressType]] = null;
                }
            }*/
            this.address = new PropertyAddress(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

            for (var i = 0; i < place.address_components.length; i++) {

                var addressType = place.address_components[i].types[0];
                if (this.componentForm[addressType]) {
                    var val = place.address_components[i][this.componentForm[addressType]];
                    if (this.propertyMapping[addressType]) {
                        //console.log("2. set " + this.propertyMapping[addressType] + " to [" + val + "].");
                        this.address[this.propertyMapping[addressType]] = val;
                    }
                }
            }

        }
        this.addressSearchString = place.formatted_address;
    }

    public SetAttendees(t: number)
    {
        if (t == 0 || t == null || t == undefined)
            this.numberOfAttendees = undefined;
        else
            this.numberOfAttendees = t;

    }

    //=== new set attendeees ========
    reduceAttendees(){
        --this.numberOfAttendees;
    }

    incrementAttendees(){
        ++this.numberOfAttendees;
    }

    setCategory(e:any){
        console.log("category",e);
        this.selectedCategory = e.target.value;
    }

    openTimeModal()
    {
        this.modalRef = this.modalService.open(SearchCalendarModal, { size: 'xl', });
        this.modalRef.componentInstance.title = 'Mark required timeslots';
        if (this.searchService.calendarTimeSelection != null)
            this.modalRef.componentInstance.SetSelections(this.searchService.calendarTimeSelection);
        this.modalRef.componentInstance.passBookings.subscribe((receivedEntry) => {
            this.searchService.calendarTimeSelection = receivedEntry;
        })
    }

    Search()
    {   
        this.searchService.searchType = 'workshop';
        var timeAllocations = new Array<WorkshopTimeAllocation>();
        if (this.searchService.calendarTimeSelection != null)
            for (let selection of this.searchService.calendarTimeSelection)
        {
            timeAllocations.push(WorkshopTimeAllocation.FromBooking(selection));
            }

        let allocation = timeAllocations.map((e)=>{
                let [startTimedate,startTimetime] = e.startTime.split(', ');
                let [endTimedate,endTimetime] = e.endTime.split(', ');
                startTimedate = this.formatDate(startTimedate);
                endTimedate = this.formatDate(endTimedate);
                return {
                    Enter_DayName: this.returnDayname(startTimedate),
                    Enter_FromTime: `${startTimedate} ${startTimetime}`,
                    Enter_ToTime: `${endTimedate} ${endTimetime}`,
                    allocationType: '3'
                }
            })

            const temp = {
                availableTimeslots: allocation,
                CategoryID:this.selectedCategory,
                address: {country:this.address.country, city:this.address.city}
            }

            console.log('search data',temp);
            this.submissionService.startSubmission();
            this.entertainmentService.searchEntertainment(temp).subscribe((response)=>{
                console.log('search response',response);
                this.submissionService.endSubmission();
                this.entertainmentService.searchResult = response;
                this.router.navigate(['entertainment-search-results']);
            })
    }

    removeTimeSlot(timeslot: BookingSegment) {
        this.searchService.calendarTimeSelection.splice(this.searchService.calendarTimeSelection.indexOf(timeslot), 1);
        if (this.searchService.calendarTimeSelection.length == 0)
            this.searchService.calendarTimeSelection = null;
    }

    formatDate(dataInput:string){
        const [month,date,year] = dataInput.split('/');
        // return `${year}-${month}-${date}`;
        return `${date}-${month}-${year}`;
    }
 
    returnDayname(dataInput:string){
        console.log('datainput',dataInput);
        
        const [day,month,year] = dataInput.split('-');

        let date = new Date(`${month}-${day}-${year}`).toDateString();
        const weeknames = {
            Fri: 'Friday',
            Mon: 'Monday',
            Tue: 'Tuesday',
            Wed: 'Wednesday',
            Thu: 'Thursday',
            Sat: 'Saturday',
            Sun: 'Sunday'
            }

        console.log('date from returndayname',date);
        
          const [week,...rest]= date.split(' ');

          console.log('week name from returndayname',week);
          
          return weeknames[week];
    }

}