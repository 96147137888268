import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "app/modules/shared/services/auth.service";
import { NotificationType } from "../../../../helpers/enums/notificationType";
import { NotificationService } from "../../../shared/services/notification.service";

@Component({
    selector: "change-password",
    templateUrl: "change-password.component.html",
    styleUrls:['./change-password.component.css']
})
export class ChangePassword {


    errorMessage: string = "";
    public creds = {
        Email: "",
        Password: "",
        ConfirmPassword: "",
        Token: ""
    };

    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.creds.Email = params['email'];
            this.creds.Token = params['token'];
            this.checkResetToken();
        })
    }

    checkResetToken() {
        this.authService.checkResetToken(this.creds)
            .subscribe(
                success => {
                },
                err => {
                    this.notificationService.notify(NotificationType.Error, err.error, "Reset password failed");
                    this.router.navigate(["index"]);
                }
            );
    }

    resetPassword() {
        if (!this.validatePassword()) return;

        this.authService.resetPassword(this.creds)
            .subscribe(
                success => {
                    if (this.authService.userType == "EventPlanner") {
                        this.router.navigate(["index"]);
                    } else if (this.authService.userType == "PropertyManager") {
                        this.router.navigate(['/property-manager/properties']);
                    }
                },
                err => this.errorMessage = "Failed to reset password. Please try again or contact Ebento team."
            );
    }

    //TODO: create validation service which will take all params from server
    private validatePassword(): boolean {
        if (this.creds.Password != this.creds.ConfirmPassword) {
            this.errorMessage = "Passwords do not match.";
            return false;
        }

        let minMaxLength = new RegExp(/^[\s\S]{8,64}$/);
        let upper = new RegExp(/[A-Z]/);
        let lower = new RegExp(/[a-z]/);
        let number = new RegExp(/[0-9]/);

        if (this.creds.Password.match(minMaxLength) &&
            this.creds.Password.match(upper) &&
            this.creds.Password.match(lower) &&
            this.creds.Password.match(number)) {

            return true;
        }
        this.errorMessage = "Password must contain at least 8 characters. The password must contain at least three character categories among the following:<br/>&#8226 1 Uppercase character(A - Z)<br/>&#8226 1 Lowercase character(a - z)<br/>&#8226 1 Digit(0 - 9)";
        return false;
    }

    forgotPassword() {
        if (!this.creds.Email) {
            this.errorMessage = "Please enter Email address"
            return;
        }

        let model = {
            Email: ""
        }

        this.authService.forgotPassword(model)
            .subscribe(
                success => {

                },
                err => this.errorMessage = "Failed to login"
            );
    }
}