import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/modules/shared/services/auth.service';

@Component({
    selector: 'app-change-email',
    templateUrl: './change-email.component.html'
})
export class ChangeEmailComponent implements OnInit {

    errorMessage: string = "";
    message: string;
    public creds = {
        Email: "",
        NewEmail: "",
        Token: ""
    };

    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.creds.Email = params['email'];
            this.creds.NewEmail = params['new-email'];
            this.creds.Token = params['token'];

            this.confirmChangeEmail();
        })
    }

    private confirmChangeEmail() {
        this.authService.confirmChangeEmail(this.creds)
            .subscribe(
                (res) => {
                    this.message = "Successfully changed email to" + this.creds.NewEmail
                },
                (err) => {
                    this.message = "ERROR: " + err;
                }
            )
    }


}
