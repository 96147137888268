import { Amenity } from "../properties/Amenity";
import { AmenityType } from "../properties/amenity-type";

export class DocumentUpload {

    constructor(public url: string, public amenityType: AmenityType) {}

}

export class AmenityDocumentUpload {

    constructor(public url: string, public amenity: Amenity) { }

}