/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input , AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PropertyAddress } from '../../../shared/models/properties/property-address';
import { PropertyService } from '../../../shared/services/property.service';
import { SpaceService } from '../../../shared/services/space.service';
import { SearchService } from '../../services/search.service';
import { SearchCalendarModal } from '../../../shared/modules/modals/components/search-calendar-modal/search-calendar-modal.component';
import { TimeAllocation } from '../../../shared/models/data-classes/time-allocation';
import { SearchSpaceQuery } from '../../../shared/models/search/search-space-query';
import { BookingSegment } from 'app/modules/shared/models/booking/booking-segment';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { EbentoModalService } from '../../../shared/services/ebento-modal.service';

@Component({
    selector: 'search-filter-index',
    templateUrl: 'search-filter-index.component.html',
    styleUrls: ['./search-filter-index.component.scss']
})

export class SearchFilterIndex implements OnInit,AfterViewInit {
    //fa 

    @Input() pagename:string;
    faMinusCircle = faMinusCircle;

    numberOfAttendees: number;
    modalRef: NgbModalRef;
    address: PropertyAddress;
    autocomplete: google.maps.places.Autocomplete;
    addressSearchString: string; 

    componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'

    };
    propertyMapping = {
        street_number: "streetnumber",
        route: "streetaddress",
        locality: "city",
        administrative_area_level_1: "",
        country: "country",
        postal_code: "postalcode"
    }

    constructor(
        private modalService: EbentoModalService,
        public propertyService: PropertyService,
        public spaceService: SpaceService,
        public searchService: SearchService,
        private router: Router
    ) { }

    ngOnInit() {
       
        this.searchService.calendarTimeSelection = null;
        
    }

    ngAfterViewInit(): void {
        this.autocomplete = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(<HTMLInputElement>document.getElementById('autocomplete-sfi')),
            { types: ['geocode'] });

        // When the user selects an address from the dropdown, populate the address
        // fields in the form.
        this.autocomplete.addListener('place_changed', () => { this.fillInAddress(); });
        this.address = new PropertyAddress(null, null, null, null, null, null, 0, 0);
    }

    fillInAddress() {

        var place = this.autocomplete.getPlace();
        var searchPlaceAutocompleteLatLng = place.geometry.location;
        
        this.address.googlemapcoordx = place.geometry.location.lat();
        this.address.googlemapcoordy = place.geometry.location.lng();
        var streetSpecific = false;
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (this.propertyMapping[addressType] == "streetaddress")
                streetSpecific = true;
        }

        //console.log("StreetSpecific: " + streetSpecific);
        if (!streetSpecific) {

            var geocoder = new google.maps.Geocoder;
            geocoder.geocode({
                'location': searchPlaceAutocompleteLatLng}, (results, status) => {
                // console.log("wow." + JSON.stringify(results[0]));

                var placeFromGeocode = results[0];
                var fields = [];
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (this.propertyMapping[addressType]) {
                        //console.log("1. set " + this.propertyMapping[addressType] + " to null.");
                        fields[this.propertyMapping[addressType]] = true;
                        //this.searchService.searchSpaceQuery.address[this.propertyMapping[addressType]] = null;
                    }
                }
                this.address = new PropertyAddress(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

                for (var i = 0; i < placeFromGeocode.address_components.length; i++) {

                    var addressType = placeFromGeocode.address_components[i].types[0];
                    if (this.componentForm[addressType]) {
                        var val = placeFromGeocode.address_components[i][this.componentForm[addressType]];
                        if (this.propertyMapping[addressType] && fields[this.propertyMapping[addressType]]) {
                            //console.log("2. set " + this.propertyMapping[addressType] + " to [" + val + "].");
                            this.address[this.propertyMapping[addressType]] = val;
                        }
                    }
                }
            });
        }

        else {
            /*
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (this.propertyMapping[addressType]) {
                    //console.log("1. set " + this.propertyMapping[addressType] + " to null.");
                    //this.searchService.searchSpaceQuery.address[this.propertyMapping[addressType]] = null;
                }
            }*/
            this.address = new PropertyAddress(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

            for (var i = 0; i < place.address_components.length; i++) {

                var addressType = place.address_components[i].types[0];
                if (this.componentForm[addressType]) {
                    var val = place.address_components[i][this.componentForm[addressType]];
                    if (this.propertyMapping[addressType]) {
                        //console.log("2. set " + this.propertyMapping[addressType] + " to [" + val + "].");
                        this.address[this.propertyMapping[addressType]] = val;
                    }
                }
            }

        }
        this.addressSearchString = place.formatted_address;
    }


    /*
    OLD VERSION
    fillInAddress2() {
        var place = this.autocomplete.getPlace();
            this.searchService.searchPlaceAutocompleteLatLng = place.geometry.location;
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (this.componentForm[addressType]) {
                var val = place.address_components[i][this.componentForm[addressType]];
                if (this.propertyMapping[addressType]) {
                    this.address[this.propertyMapping[addressType]] = val;
                }
            }
        }
        this.address.googlemapcoordx = place.geometry.location.lat();
        this.address.googlemapcoordy = place.geometry.location.lng();
        this.addressSearchString = place.formatted_address;
        //console.log(JSON.stringify(this.address));
    }
    */
    public SetAttendees(t: number)
    {
        if (t == 0 || t == null || t == undefined)
            this.numberOfAttendees = undefined;
        else
            this.numberOfAttendees = t;

    }

      //=== new set attendeees ========
      reduceAttendees(){
        --this.numberOfAttendees;
    }

    incrementAttendees(){
        ++this.numberOfAttendees;
    }

    openTimeModal()
    {
        this.modalRef = this.modalService.open(SearchCalendarModal, { size: 'xl', });
        this.modalRef.componentInstance.title = 'Mark required timeslots';
        if (this.searchService.calendarTimeSelection != null)
            this.modalRef.componentInstance.SetSelections(this.searchService.calendarTimeSelection);
        this.modalRef.componentInstance.passBookings.subscribe((receivedEntry) => {
            this.searchService.calendarTimeSelection = receivedEntry;
        })
    }

    Search()
    {

        this.searchService.searchType = 'space';
        //console.log("@dr " + JSON.stringify(this.address));
        var timeAllocations = new Array<TimeAllocation>();
        if (this.searchService.calendarTimeSelection != null)
            for (let selection of this.searchService.calendarTimeSelection)
        {
            timeAllocations.push(TimeAllocation.FromBooking(selection));
        }

        this.searchService.searchSpaceQuery = new SearchSpaceQuery(timeAllocations, this.addressSearchString, this.address, this.numberOfAttendees, [], [], []);
        this.searchService.findSpaces()
            .subscribe( () => this.router.navigate(['space-search-results'])
        );
    }

    removeTimeSlot(timeslot: BookingSegment) {
        this.searchService.calendarTimeSelection.splice(this.searchService.calendarTimeSelection.indexOf(timeslot), 1);
        if (this.searchService.calendarTimeSelection.length == 0)
            this.searchService.calendarTimeSelection = null;
    }
}
