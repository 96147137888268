/// <reference types="@types/googlemaps" />

import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PropertyManagerReview } from "app/modules/shared/models/users/property-manager-review";
import { AuthService } from "app/modules/shared/services/auth.service";
import { PropertyService } from "app/modules/shared/services/property.service";
import { ReviewService } from "app/modules/shared/services/review.service";
import { MessagingService } from "app/modules/shared/services/messaging.service";
import { PropertyAddress } from "app/modules/shared/models/properties/property-address";
import { Amenity } from "app/modules/shared/models/properties/Amenity";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";
import { EbentoWorkshop } from "app/modules/shared/models/workshops/ebento-workshop";
import { WorkshopService } from "app/modules/shared/services/workshop.service";
import { WorkshopBookingService } from "app/modules/shared/services/workshop-booking.service";
import { DraggableCalendarModal } from "app/modules/shared/modules/modals/components/draggable-calendar-modal/draggable-calendar-modal.component";
import { WorkshopTimeAllocation } from "app/modules/shared/models/data-classes/workshop-time-allocation";
import { TimeSpan } from "app/modules/shared/models/data-classes/time-span";
import { EbentoModalService } from "../../../shared/services/ebento-modal.service";
import { CartService } from "../../services/cart.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { NotificationType } from "../../../../helpers/enums/notificationType";
import { WorkshopBookingOptions } from "../../../shared/models/booking/workshop-booking-options";

@Component({
  selector: "modify-cart-experience",
  templateUrl: "modify-cart-experience.component.html",
  styleUrls: ["./modify-cart-experience.component.scss"],
})
export class ModifyCartExperience implements OnInit, AfterViewInit {
  cartId: number;
  workshop: any;
  reviews: PropertyManagerReview[];
  modalRef: NgbModalRef;
  attendees: number = 0;
  activeImage: string;
  prevTimeSlots: any[];
  timeslots: any[];

  @ViewChild("gmap") gmapElement: any;

  public map: google.maps.Map;

  get workshopPhotoUrl(): string {
    if (
      this.workshop != null &&
      this.workshop.photoUrls != null &&
      this.workshop.photoUrls.length > 0
    )
      return this.workshop.photoUrls[0];
    return "images/ebento.png";
  }

  amenityQuantity: Object;

  constructor(
    public authService: AuthService,
    public workshopBookingService: WorkshopBookingService,
    public propertyService: PropertyService,
    public workshopService: WorkshopService,
    public messagingService: MessagingService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private reviewService: ReviewService,
    private modalService: EbentoModalService,
    private cartService: CartService,
    private notificationService: NotificationService
  ) {
    this.amenityQuantity = {};
  }

  ngOnInit(): void {
    let spaceIdParam = this.activatedRoute.snapshot.params;
    this.workshopBookingService.bookingOptions = new WorkshopBookingOptions();
    if (spaceIdParam) {
      this.cartService
        .getModify(spaceIdParam.cartId, spaceIdParam.serviceId, "experience")
        .subscribe((res) => {
          console.log("response for experience modify", res);
          this.workshopBookingService.workshop = res.spaceDetails;
          this.prevTimeSlots = res.bookedTimeSlots.map((e) => {
            return {
              startTime: this.formatTime(e.startTime),
              endTime: this.formatTime(e.endTime),
              allocationType: e.allocationType,
            };
          });
          console.log("this. prevTimeslots",this.prevTimeSlots);
          this.timeslots = res.bookedTimeSlots.map((e) => {
            return {
              startTime: this.formatTime(e.startTime),
              allocationType: e.allocationType,
              endTime: this.formatTime(e.endTime),
              isCustom: false
            };
          });

          console.log("this timeslots",this.timeslots);
          
          const temp = {
            bookingAmenities: res.optionalAmenities,
            attendees: res.attendees,
            bookingType: "workshop",
            price: {
              couponDiscount: 0,
              ebentoFee:0,
              extras: 0,
              total: res.totalPrice,
              totalWithDiscount: res.totalPrice,
              venueHire: res.totalPrice,
            },
            timeAllocation:this.timeslots[0],
            workshopID: res.serviceId,
            usedCoupon:null,
            menus:[]
          }
          this.workshopBookingService.populateCartModification(temp);
          this.cartId = res.cartId;
          this.workshop = res.spaceDetails;
          this.attendees = res.attendees;
          // this.workshopBookingService.SetAttendeeCount(res.attendees);
          this.init();

        });
    }
  }

  ngAfterViewInit() {
    if (this.workshop != null) this.SetMap();
  }

  init() {
    // this.workshop = this.workshopBookingService.workshop;
    this.activeImage = this.workshop.photoUrls[0];
    this.reviewService
      .getReviewsForWorkshop(this.workshop.id)
      .subscribe((reviews) => {
        this.reviews = reviews;
      });

    window.scrollTo(0, 0);
    this.SetMap()
  }

  setBigImage(i: number) {
    this.activeImage = this.workshop.photoUrls[i];
  }

  AddToCart(){

    console.log("add to cart----",this.workshopBookingService.bookingTimeslot);
    console.log("add to cart booking options----",this.workshopBookingService.bookingOptions,this.workshop.id, this.workshop.spaceId);
    let slots;
    if(this.workshopBookingService.bookingTimeslot !== null){
        slots = JSON.parse(JSON.stringify(this.workshopBookingService.bookingTimeslot))
    }else{
        slots = this.prevTimeSlots.filter((e) => {
            return {
              startTime: this.formatTime(e.startTime),
              endTime: this.formatTime(e.endTime),
              allocationType: e.allocationType,
            };
          });

        slots = slots[0];
    }
    

    const temp = {
        cartId:this.cartId,
        bookedTimeSlots: [{
                startTime: slots.startTime,
                endTime: slots.endTime,
                allocationType: slots.allocationType
            }],
        attendees:this.attendees,
        spaceType: 'experience',
        optionalAmenities: this.workshopBookingService.bookingOptions.optionalAmenities,
        totalPrice: this.workshopBookingService.BasePrice(),
        serviceId: this.workshop.id,
        spaceName: this.workshop.name,
        propertyName: this.workshop.propertyName,
        image: this.workshop.photoUrls[0]
    }

    this.cartService.updateCartModify(temp).subscribe(
        (resp) => {
            console.log('response',resp);
            this.notificationService.notify(NotificationType.Success, `${this.workshop.name} is updated`, "Cart Item updated");
            this.router.navigate(['/cart'])
        },
        (err) => {
            console.log('add cart error',err);
            this.notificationService.notify(NotificationType.Error, `${this.workshop.name} cannot be updated`, "Please try again later");
            this.router.navigate(['/cart'])
        })
}

  SetMap() {
    var mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    var map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.map = map;
    var address = this.workshop.address;
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        address.googlemapcoordx,
        address.googlemapcoordy
      ),
      map: this.map,
    });

    var bounds = new google.maps.LatLngBounds();
    bounds.extend(marker.getPosition());

    this.map.fitBounds(bounds);
    var listener = google.maps.event.addListener(map, "idle", function () {
      if (map.getZoom() > 16) map.setZoom(16);
      google.maps.event.removeListener(listener);
    });
  }

  openModal() {
    // const modalRef = this.modalService.open(ModalComponent);
    this.modalRef = this.modalService.open(DraggableCalendarModal, {
      size: "xl",
    });

    this.modalRef.componentInstance.title = "Reservation Calendar";
    // this.modalRef.componentInstance.SetSelections(this.timeslots);
    /*if (this.workshopBookingService.bookingTimeslot != null)
            this.modalRef.componentInstance.SetSelections(this.workshopBookingService.bookingTimeslot);*/
    //this.modalRef.result.then(() => { this.bookingTimeslot = this.modalRef.componentInstance.result; }, () => { this.bookingTimeslot = this.modalRef.componentInstance.result; })
    this.modalRef.componentInstance.passBookings.subscribe((receivedEntry) => {
      console.log("slecteed slots:" + receivedEntry);
      this.workshopBookingService.bookingTimeslot = receivedEntry;

      console.log(
        "booking options",
        this.workshopBookingService.bookingOptions
      );

      this.workshopBookingService.selectedSlotAvailableCount =
        this.workshopBookingService.GetAvailableSlotsForAllocation(
          receivedEntry
        );
      //console.log("Conso" + this.workshopBookingService.selectedSlotAvailableCount);
    });
  }

  public AddressText(address: PropertyAddress) {
    var result: string = "";
    if (address.streetaddress != null && address.streetaddress != "")
      result += address.streetaddress;
    if (address.streetnumber != null && address.streetnumber != "")
      result += " " + address.streetnumber;
    if (address.city != null && address.city != "") {
      if (result != "") result += ", ";
      if (address.postalcode != null && address.postalcode != "")
        result += address.postalcode + " ";
      result += address.city;
    }
    if (address.country != null && address.country != "") {
      if (result != "") result += ", ";
      result += address.country;
    }
    return result;
  }

  public OptionalAmenities(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.workshop.amenities != null)
      for (var a of this.workshop.amenities) {
        if (a.isoptional) result.push(a);
      }
    return result;
  }

  public Catering(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.workshop.amenities != null)
      for (var a of this.workshop.amenities) {
        if (a.amenitytype.isCatering) result.push(a);
      }
    return result;
  }

  public FixedAmenities(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.workshop.amenities != null)
      for (var a of this.workshop.amenities) {
        if (!a.isoptional && !a.amenitytype.isCatering) result.push(a);
      }
    return result;
  }

  get numbersOfAttendees() {
    var array = new Array();
    for (
      var i = this.workshop.minNumberOfGuests;
      i <= this.workshop.maxNumberOfGuests;
      i++
    ) {
      array.push(i);
    }
    return array;
  }

  public GetDateString(allocation: WorkshopTimeAllocation): Date {
    return new Date(allocation.startTime);
  }

  public GetTimeString(allocation: WorkshopTimeAllocation): string {
    var start = new Date(allocation.startTime);
    var end = new Date(allocation.endTime);
    var startTime = new TimeSpan(0, start.getHours(), start.getMinutes(), 0);
    var endTime = new TimeSpan(0, end.getHours(), end.getMinutes(), 0);
    return startTime.HoursMinutesString + " - " + endTime.HoursMinutesString;
  }

  incrementAttendees() {
    ++this.attendees;
    this.workshopBookingService.SetAttendeeCount(this.attendees);
  }

  reduceAttendees() {
    if (this.attendees > 0) {
      --this.attendees;
    }
    this.workshopBookingService.SetAttendeeCount(this.attendees);
  }

  formatTime(d: string) {
    console.log("d string",d);
    const [date, time, format] = d.split(" ");
    const [dd, mm, yy] = date.split("/");
    const newDate = `${mm}/${dd}/${yy} ${time} ${format}`;
    console.log("new date",newDate);

    return newDate;
  }

  calculateDuration(startTime: string, endTime: string) {
    const [sdate, stime, sformat] = startTime.split(" ");
    const [edate, etime, eformat] = endTime.split(" ");
    console.log("stime", stime, "etime", etime, "formats", sformat, eformat);

    const [shours, sminutes, sseconds] = stime.split(":");
    const [ehours, eminutes, eseconds] = etime.split(":");

    let time_start = new Date();
    let time_end = new Date();
    time_start.setHours(Number(shours), Number(sminutes), Number(sseconds), 0);
    time_end.setHours(Number(ehours), Number(eminutes), Number(eseconds), 0);
    console.log("time diff", time_start.getTime(), time_end.getTime());

    const duration = (time_end.getTime() - time_start.getTime())/1000;
    let difference = duration;
    difference = difference / 1000;
    let hourDifference = Math.floor(difference / 3600);
    difference -= hourDifference * 3600;
    let minuteDifference = Math.floor(difference / 60);
    difference -= minuteDifference * 60;
    console.log("duration time", hourDifference, minuteDifference, difference);
    return duration;
  }

  returnDate(d: string): Date {
    const [date, time] = d.split(" ");
    const [dd, mm, yy] = date.split("/");
    const [hh, mn, ss] = time.split(":");
    const newDate = new Date(
      Number(yy),
      Number(mm) - 1,
      Number(dd),
      Number(hh),
      Number(mn),
      Number(ss)
    );
    console.log("calculated new Date", newDate);
    return newDate;
  }
}
