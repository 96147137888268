import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { SpaceBookingRequest } from "../models/booking/space-booking-request";
import { SpaceBookingOptions } from "../models/booking/space-booking-options";
import { SpaceBooking } from "../models/booking/space-booking";
import { BookingSegment, BookingDTO } from "../models/booking/booking-segment";
import { PMBookingOverviewRequest } from "../models/booking/pm-booking-overview-request";
import { PMBookingsOverview } from "../models/booking/pm-bookings-overview";
import { EbentoSpace } from "../models/properties/ebento-space";
import { TimeSpan } from "../models/data-classes/time-span";
import { TimeAllocation } from "../models/data-classes/time-allocation";
import { Coupon, CouponMessage } from "../models/booking/coupon";
import { Booking } from "../models/booking/booking";
import { CancellationRule } from "../models/properties/cancellation-rule";
import { ModifySpaceBookingRequest } from "../models/booking/modify-space-booking-request";
import { ModifyRequestReply } from "../models/booking/modify-booking-request-reply";
import { PayModify } from "../models/booking/pay-modify";
import { SpaceService } from "./space.service";
import { SearchService } from "../../event-planner/services/search.service";
import { BookingPrice } from "../models/booking/booking-price";

@Injectable()
//TODO: maybe only needed bookings, booking and booking overview
export class SpaceBookingService {
  
  spaceBookingRequest: SpaceBookingRequest;
  public bookingOptions: SpaceBookingOptions;
  public bookings: Booking[];
  public booking: SpaceBooking;
  public bookingToEdit: SpaceBooking;
  public bookingSegments: BookingSegment[];
  public bookingTimeslots: BookingSegment[];
  public pmBookingOverviewRequest: PMBookingOverviewRequest;
  public bookingOverview: PMBookingsOverview;
  public coupons: Coupon[];
  // space reference needed for pricing. Value is externaly inserted in code. This is probably bad solution, should refactor.
  public space: EbentoSpace;

  constructor(
    private http: HttpClient,
    private router: Router,
    private spaceService: SpaceService,
    private searchService: SearchService
  ) {}

  startNewBooking(spaceId: number, prefilFromSearchService: boolean = false) {
    this.spaceService.getAmenityTypes().subscribe();
    this.spaceService.getSpace(spaceId).subscribe((success) => {
      this.space = this.spaceService.space;
      this.bookingTimeslots = null;
      // ugly solution. we should handle this is service directly?
      this.bookingToEdit = null;
      this.bookingOptions = new SpaceBookingOptions();
      if (prefilFromSearchService) {
        this.bookingOptions.attendees =
          this.searchService.searchSpaceQuery.numberofattendees;
        this.bookingTimeslots = this.searchService.calendarTimeSelection;
      }
      this.router.navigate(["space-listing/" + spaceId]);
    });
  }

  getPMBookingOverview(
    propertyManagerID: string,
    type: number
  ): Observable<PMBookingsOverview> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token",
      }),
    };
    this.pmBookingOverviewRequest = new PMBookingOverviewRequest();
    this.pmBookingOverviewRequest.propertyManagerID = propertyManagerID;
    this.pmBookingOverviewRequest.type = type;

    return this.http
      .post<PMBookingsOverview>(
        "api/pmbookingoverview",
        JSON.stringify(this.pmBookingOverviewRequest),
        httpOptions
      )
      .pipe(
        map((data) => {
          this.bookingOverview = data;
          return data;
        }),
        catchError(this.errorHandler)
      );
  }

  getBookingsForSpace(
    spaceID: number,
    anonimous: boolean = false
  ): Observable<BookingDTO[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    this.spaceBookingRequest = new SpaceBookingRequest();
    this.spaceBookingRequest.spaceID = spaceID;
    this.spaceBookingRequest.anonimous = anonimous;
    return this.http
      .post<BookingDTO[]>(
        "api/spaceboookings",
        JSON.stringify(this.spaceBookingRequest),
        httpOptions
      )
      .pipe(
        map((data) => {
          this.bookingSegments = Array<BookingSegment>();
          for (let dto of data) {
            this.bookingSegments.push(BookingSegment.BookingFromDTO(dto));
          }
          return data;
        }),
        catchError(this.errorHandler)
      );
  }

  // Returns ALL bookings, both workshop and space. It might be a bit clunky solution?
  getBookingsForPlanner(id: string): Observable<Booking[]> {
    return this.http.get<Booking[]>("api/booking/GetEPBookings/" + id).pipe(
      map((result) => {
        console.log("#############");
        console.log(result);
        console.log("#############");
        this.bookings = result;
        return result;
      }),
      catchError(this.errorHandler)
    );
  }

  getBooking(bookingID: number): Observable<SpaceBooking> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .get<SpaceBooking>("api/booking/GetBookingById/" + bookingID)
      .pipe(
        map((result) => {
          this.booking = result;
          console.log("space booking population", result);

          this.populateSpaceBookingOptions(result);
          this.populateBookingTimeslots(result);
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  getHistoryCancellationRules(
    bookingID: number
  ): Observable<CancellationRule[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .get<CancellationRule[]>(
        "api/booking/getHistoryCancellationRules/" + bookingID
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public sendEnquiryRequest(
    {
      descriptionofEvent,
      SpecificRequirements,
      descriptionOfEventspace,
      location,
    },
    spaceType: string
  ) {

    // console.log("booking options",this.bookingOptions);
    
    let enquiryBookingOptions = {
        serviceid: this.space.spaceId,
        requesttype: spaceType,
        attendees: this.bookingOptions.attendees,
        timeAllocationDTOs: this.bookingOptions.timeAllocationDTOs,
        totalPrice: this.TotalPrice(false),
        message: this.bookingOptions.message,
        currency: "RS",
        venueHire: this.VenuePrice(),
        optionalAmenities: this.bookingOptions.optionalAmenities,
        descriptionofEvent,
        SpecificRequirements,
        descriptionOfEventspace,
        location,
      };

      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };

    return this.http
      .post("api/SendBookingRequest", enquiryBookingOptions, httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  private populateSpaceBookingOptions(b: SpaceBooking): void {
    let bookingOptions = new SpaceBookingOptions();
    bookingOptions.spaceID = b.spaceID;
    bookingOptions.totalPrice = b.price.totalWithDiscount;
    bookingOptions.optionalAmenities = b.bookingAmenities;
    bookingOptions.timeAllocationDTOs = b.timeAllocations;
    bookingOptions.coupon = b.usedCoupon;
    bookingOptions.attendees = b.attendees;

    this.bookingOptions = bookingOptions;
  }

  private populateBookingTimeslots(b: SpaceBooking): void {
    this.bookingTimeslots = [];
    for (let ta of b.timeAllocations) {
      let startTime = new Date(ta.startTime);
      let endTime = new Date(ta.endTime);
      let durationInSeconds = (endTime.getTime() - startTime.getTime()) / 1000;
      let timeSpan = new TimeSpan(0, 0, 0, durationInSeconds);
      let bookingSegment = new BookingSegment(startTime, timeSpan, "");
      this.bookingTimeslots.push(bookingSegment);
    }
  }

  public cartModifyBookingPopulate(b: any) {
    
    let bookingOptions = new SpaceBookingOptions();
    bookingOptions.spaceID = b.spaceID;
    bookingOptions.totalPrice = b.price.totalWithDiscount;
    bookingOptions.optionalAmenities = b.bookingAmenities;
    bookingOptions.timeAllocationDTOs = b.timeAllocations;
    bookingOptions.coupon = b.usedCoupon;
    bookingOptions.attendees = b.attendees;

    this.bookingOptions = bookingOptions;

    this.bookingTimeslots = [];
    for (let ta of b.timeAllocations) {
      let startTime = new Date(ta.startTime);
      let endTime = new Date(ta.endTime);      
      let durationInSeconds = (endTime.getTime() - startTime.getTime()) / 1000;
      let timeSpan = new TimeSpan(0, 0, 0, durationInSeconds);
      let bookingSegment = new BookingSegment(startTime, timeSpan, "");
      this.bookingTimeslots.push(bookingSegment);
    }
  }

  cancelBooking(id: number): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post<boolean>("api/bookspace/cancel-booking", id, httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }

  populateBookingOptions() {
    var allocations: Array<TimeAllocation>;
    allocations = new Array<TimeAllocation>();
    for (let timeslot of this.bookingTimeslots) {
      allocations.push(TimeAllocation.FromBooking(timeslot));
    }
    this.bookingOptions.timeAllocationDTOs = allocations;
    this.bookingOptions.spaceID = this.space.spaceId;
    this.bookingOptions.totalPrice = this.TotalPrice(true);
  }

  bookSpace(): Observable<boolean> {
    this.populateBookingOptions();
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http
      .post<boolean>(
        "api/bookspace",
        JSON.stringify(this.bookingOptions),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.errorHandler)
      );
  }

  payForModification(request: ModifySpaceBookingRequest): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    var payForModify = new PayModify();
    payForModify.modificationID = request.requestID;
    payForModify.price =
      request.price.totalWithDiscount -
      request.originalBooking.price.totalWithDiscount;
    payForModify.paymentType = this.bookingOptions.paymentType;

    return this.http
      .post<boolean>("api/invoice-pay-for-modify", payForModify, httpOptions)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.errorHandler)
      );
  }

  sendModifyRequest(): Observable<boolean> {
    this.populateBookingOptions();
    this.bookingOptions.bookingID = this.bookingToEdit.idBooking;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http
      .post<boolean>(
        "api/modify-space-booking",
        JSON.stringify(this.bookingOptions),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.errorHandler)
      );
  }

  generateCoupon(coupon: Coupon): Observable<Coupon> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post<Coupon>("api/generatecoupon", JSON.stringify(coupon), httpOptions)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  sendCouponMessage(message: CouponMessage): Observable<Coupon> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post<Coupon>(
        "api/sendcouponmessage",
        JSON.stringify(message),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  getAllCoupons(): Observable<Coupon[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.get<Coupon[]>("api/getAllCouponsPM", httpOptions).pipe(
      map((result) => {
        this.coupons = result;
        return result;
      }),
      catchError(this.errorHandler)
    );
  }

  checkCoupon(couponCode: string, spaceId: number): Observable<Coupon> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .get<Coupon>(
        "api/checkcouponspace/" + spaceId + "/" + couponCode,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  getBookingPricing(
    bookingOptions: SpaceBookingOptions
  ): Observable<BookingPrice> {
    this.populateBookingOptions();
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post<BookingPrice>("api/get-booking-pricing", bookingOptions)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public VenuePrice(): number {
    var totalHoursForDay = 0;
    var venuePrice = 0;
    if (this.bookingTimeslots != null && this.space != null) {
      // sorting timeslot by days, so that we can apply max daily price for each day

      var uniqueDates = new Array<Date>();
      for (let timeslot of this.bookingTimeslots) {
        var added = false;
        for (let date of uniqueDates) {
          if (
            date.toLocaleDateString() == timeslot.startTime.toLocaleDateString()
          ) {
            added = true;
          }
        }
        if (!added) {
          uniqueDates.push(timeslot.startTime);
        }
      }
      for (let date of uniqueDates) {
        var venuePriceForDay = 0;
        totalHoursForDay = 0;
        for (let timeslot of this.bookingTimeslots) {
          if (
            timeslot.startTime.toLocaleDateString() == date.toLocaleDateString()
          ) {
            var timeslotduration = timeslot.duration.Hours;
            timeslotduration += timeslot.duration.Minutes / 60;
            for (let pricingrule of this.space.pricingRules) {
              if (
                pricingrule.type.id != 1 &&
                timeslot.startTime.getHours() * 60 +
                  timeslot.startTime.getMinutes() <=
                  pricingrule.startTime.Minutes +
                    pricingrule.startTime.Hours * 60 &&
                timeslot.startTime.getHours() * 60 +
                  timeslot.startTime.getMinutes() +
                  (timeslot.duration.Hours * 60 + timeslot.duration.Minutes) >=
                  pricingrule.endTime.Minutes + pricingrule.endTime.Hours * 60
              ) {
                totalHoursForDay -=
                  pricingrule.endTime.Hours +
                  pricingrule.endTime.Minutes / 60 -
                  (pricingrule.startTime.Hours +
                    pricingrule.startTime.Minutes / 60);
                venuePriceForDay += pricingrule.price;
              }
            }
            totalHoursForDay += timeslotduration;
          }
        }
        venuePriceForDay += this.space.pricePerHour * totalHoursForDay;
        for (let pricingrule of this.space.pricingRules)
          if (pricingrule.type.id == 1) {
            venuePriceForDay = Math.min(venuePriceForDay, pricingrule.price);
          }
        venuePrice += venuePriceForDay;
      }
    }
    return venuePrice;
  }

  public BasePrice(): number {
    var basePrice = 0;
    basePrice += this.VenuePrice();
    if (
      this.bookingOptions?.optionalAmenities !== null &&
      this.bookingOptions?.optionalAmenities?.length
    ) {
      for (let amenity of this.bookingOptions?.optionalAmenities) {
        basePrice += amenity.quantity * amenity.unitPrice;
      }
    }
    return basePrice;
  }

  public EbentoFee(): number { // 5 % fee
    var basePrice = this.BasePrice();
    return basePrice * 0.05;
  }

  public TotalPrice(applyDiscount: boolean): number {
    var totalPrice = this.BasePrice() + this.EbentoFee();

    if (this.bookingOptions?.coupon != null && applyDiscount)
      totalPrice *=
        (100.0 - this.bookingOptions?.coupon?.discountPercentage) / 100.0;
    return totalPrice;
  }

  public getModifyRequest(id: number): Observable<ModifySpaceBookingRequest> {
    return this.http
      .get<ModifySpaceBookingRequest>("api/ep-modify-booking-request/" + id)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public cancelBookingModification(requestID: number): Observable<boolean> {
    return this.http
      .post<boolean>("api/cancel-booking-modification/" + requestID, null)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }
}
