const SECONDS_IN_A_MINUTE: number = 60;
const MINUTES_IN_AN_HOUR: number = 60;
const HOURS_IN_A_DAY: number = 24;

const SECONDS_IN_AN_HOUR = SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR;
const SECONDS_IN_A_DAY = SECONDS_IN_AN_HOUR * HOURS_IN_A_DAY;


export class TimeSpan {

    public totalseconds: number;

    public get Days(): number
        {
            return Math.trunc(this.totalseconds / SECONDS_IN_A_DAY);
        }
    
    public get Hours(): number
    { 
        return Math.trunc((this.totalseconds - (this.Days * SECONDS_IN_A_DAY)) / SECONDS_IN_AN_HOUR);
    }

    public get TotalHours(): number {
        return Math.trunc(this.totalseconds / SECONDS_IN_AN_HOUR);
    }

    public get Minutes(): number {
        return Math.trunc((this.totalseconds - (this.Days * SECONDS_IN_A_DAY) - (this.Hours* SECONDS_IN_AN_HOUR)) / SECONDS_IN_A_MINUTE);
    }

    public get Seconds(): number {
        return this.totalseconds - (this.Days * SECONDS_IN_A_DAY) - (this.Hours * SECONDS_IN_AN_HOUR) - (this.Minutes* SECONDS_IN_A_MINUTE);
    }

    public get HoursMinutesString(): string
    {
        let s: string = "";
        if (this.TotalHours < 10) s += "0";
        s += this.TotalHours;
        s += ":"
        if (this.Minutes < 10) s += "0";
        s += this.Minutes;
        return s;
    }

    constructor
        (days: number,
        hours: number,
        minutes: number,
        seconds: number)
    {
        this.totalseconds = seconds + SECONDS_IN_A_MINUTE * (minutes + MINUTES_IN_AN_HOUR * (hours + HOURS_IN_A_DAY * days));
    }

    public static FromDTO(dto: TimeSpan): TimeSpan
    {
        return new TimeSpan(0, 0, 0, dto.totalseconds);
    }

    public Add(ts2: TimeSpan)
    {
        this.totalseconds += ts2.totalseconds;
    }

    public Subtract (ts2: TimeSpan)
    {
        this.totalseconds -= ts2.totalseconds;
    }

    public SetTo(ts: TimeSpan)
    {
        this.totalseconds = ts.totalseconds;
    }

    public static Min(ts1: TimeSpan, ts2: TimeSpan) : TimeSpan
    {
        return (ts1.totalseconds <= ts2.totalseconds) ? ts1 : ts2;
    }

    public static Max(ts1: TimeSpan, ts2: TimeSpan): TimeSpan
    {
        return (ts1.totalseconds >= ts2.totalseconds) ? ts1 : ts2;
    }

    public static Diff(date1: Date, date2: Date): TimeSpan
    {
        var timeSpan = new TimeSpan(0, 0, 0, 0);
        timeSpan.totalseconds = ((date2.getTime() - date1.getTime()) / 1000);
        return timeSpan;
    }
}