import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, LoginRedirectType } from "app/modules/shared/services/auth.service";
import { MessagingService } from "app/modules/shared/services/messaging.service";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";

@Component({
    selector: "forgot-password",
    templateUrl: "forgot-password.component.html",
    styleUrls:['./forgot-password.component.css']
})
export class ForgotPassword {

    constructor(public authService: AuthService, private router: Router, public messagingService: MessagingService) { }

    errorMessage: string = "";
    public creds = {
        Email: "",
        Password: ""
    };

    forgotPassword() {
        if (!this.creds.Email) {
            this.errorMessage = "Please enter Email address"
            return;
        }

        let model = {
            Email: this.creds.Email
        }

        this.authService.forgotPassword(model)
            .subscribe(
                success => this.router.navigate(["forgot-password-link-sent", "reset password", this.creds.Email]),
            
                err => this.router.navigate(["forgot-password-link-sent", "reset password", this.creds.Email])
            );
    }
}