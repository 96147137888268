/// <reference types="@types/googlemaps" />

import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { LocalCitiesRequest } from "../models/search/local-cities-request";
import { Geolocation } from "../models/search/geolocation";

@Injectable()
export class GeolocationService
{
    public geolocation: Geolocation
    public isAvailable: boolean
    public myCity: LocalCitiesRequest
    public gotLocationSubject: Subject<string>;

    public constructor()
    {
        this.gotLocationSubject = new Subject<string>();
    }

    public GetLocation = new Observable((observer) => {
        // Get the next and error callbacks. These will be passed in when
        // the consumer subscribes.
        const { next, error } = observer;
        let watchId;

        // Simple geolocation API check provides values to publish
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.geolocation = new Geolocation(position.coords.latitude, position.coords.longitude);
                    this.isAvailable = true;
                    
                    var gcd = new google.maps.Geocoder;
                    
                    var latlng = { lat: this.geolocation.latitude, lng: this.geolocation.longitude };
                    gcd.geocode({ 'location': latlng }, (results, status) => {
                        if (results[0] != null) {
                        var cityName = "";
                            var countryName = "";
                            for (var i = 0; i < results[0].address_components.length; i++) {
                                if (results[0].address_components[i].types[0] == "locality")
                                    cityName = results[0].address_components[i].long_name;
                                if (results[0].address_components[i].types[0] == "country")
                                    countryName = results[0].address_components[i].long_name;

                                this.myCity = new LocalCitiesRequest(cityName, countryName);
                                this.gotLocationSubject.next(JSON.stringify(this.myCity));
                               observer.next(this.myCity);
                        }

                        }
                    });
                }
            );
        }
        else {
            console.warn("Geolocation is not supported by this browser.");
            observer.error(0);
        }   
        // When the consumer unsubscribes, clean up data ready for next subscription.
        return { unsubscribe() {  } };
    });
}