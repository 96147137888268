import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

import { EbentoProperty } from '../../models/properties/ebento-property';
import { AuthService } from '../../services/auth.service';
import { SpaceService } from '../../services/space.service';
import { PropertyService } from '../../services/property.service';
import { MessagingService } from '../../services/messaging.service';
import { EbentoMessage } from '../../models/messaging/ebento-message';
import { NotificationService } from '../../services/notification.service';
import { NotificationType } from '../../../../helpers/enums/notificationType';

@Component({
    selector: 'property-details',
    templateUrl: 'property-details.component.html',
    styleUrls: ['property-details.component.scss']
})

export class PropertyDetails implements OnInit {

    public property: EbentoProperty;
    public reportText: string;
    public reportBoxOpen: boolean;
    public errorMessage: string;
    public photoUrl: string;
    
    constructor(
        public authService: AuthService,
        public spaceService: SpaceService,
        private propertyService: PropertyService,
        private messagingService: MessagingService,
        private notificationService: NotificationService,
        public router: Router,
        public route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.property = this.propertyService.property;
        console.log("this.property;", this.property);
        if(this.property.photourl) {
            this.photoUrl = `${this.property.photourl}`
        }
    }

    getPropertyImg() {
        if(this.property.photourl) return `${this.property.photourl}`
        else 'assets/images/Rectangle 152.png'
    }

    getProfile() {
        if(this.property.propertyManager.avatarURL) return `${this.property.propertyManager.avatarURL}`
        return "assets/images/reviewProfile.png"
    }

    checkIsOwner(): boolean {
        if (this.property.propertyManager == null)
            return false;
        return this.authService.isLoggedIn()
            && this.property.propertyManager.id == this.authService.user.id;

    }



    AddSpace() {
        // this can be solved better? Too coupled this way.
        this.spaceService.spaceToEdit = null;
        this.router.navigate(['/add-space/' + this.property.id]);
    }

    EditProperty() {
        this.propertyService.propertyToEdit = this.property;
        this.router.navigate(['/add-property']);
    }

    viewProfile() {
        this.router.navigate(['pm-user-details/' + this.property.propertyManager.id]);
    }

    sendMessage() {
        this.messagingService.currentRecepient = this.property.propertyManager;
        this.messagingService.currentDraft = new EbentoMessage();
        this.router.navigate(['/messages/compose']);
    }

    reportManager() {
        this.reportBoxOpen = true;
    }

    sendReport() {
        this.messagingService.reportPM(this.property.propertyManager.id, this.reportText).subscribe(
            (result) => {
                if (result) {
                    this.reportBoxOpen = false;
                    this.reportText = "";
                    this.notificationService.notify(NotificationType.Success, "Thank you for your help!", "Report Sent");
                }
            }
        );
    }

    public stringify(obj: any): string {
        return JSON.stringify(obj);
    }
}
