import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { EbentoProperty } from '../../models/properties/ebento-property';
import { AuthService } from '../../services/auth.service';
import { PropertyService } from '../../services/property.service';
import { SpaceService } from '../../services/space.service';

@Component({
    selector: 'property-list-display',
    templateUrl: "property-list-display.component.html",
    styleUrls: [ './property-list-display.component.css' ]
})
export class PropertyListDisplay {
    @Input('properties') properties: EbentoProperty[];
    @Output('errorEmiter') errorEmiter = new EventEmitter();

    constructor(private propertyService: PropertyService, public authService: AuthService, public spaceService: SpaceService, private router: Router) { }

    public deleteClick(id: number) {
        this.propertyService.deleteProperty(id)
            .subscribe(
                success => {
                    if (success) {
                        this.router.navigate[''];
                    }
                },
                error => this.errorEmiter.emit(error)
            )
    }

    public stringify(obj: any): string {
        return JSON.stringify(obj);
    }

    checkIsOwner(property: EbentoProperty): boolean {
        if (property.propertyManager == null)
            return false;
        return this.authService.isLoggedIn()
            && property.propertyManager.id == this.authService.user.id;
    }

    AddSpace(property: EbentoProperty) {
        // this can be solved better? Too coupled this way.
        this.spaceService.spaceToEdit = null;
        this.router.navigate(['/add-space/' + property.id]);
    }

    AddExperience(property: EbentoProperty) {
        console.log("ADd experience",property);
        const dummySpace = property.spaces.find(s => s.isDummyForWorkshops);
        
        this.router.navigate(['/add-experience/' + dummySpace.spaceId]);
    }

    AddEntertainment(property: EbentoProperty){
        console.log("property details",property);
        
        // const dummySpace = property.spaces.find(s => s.isDummyForWorkshops);
        this.router.navigate(['/add-entertainment/' + property.id])
    }

    EditProperty(property: EbentoProperty) {
        this.propertyService.propertyToEdit = property;
        this.router.navigate(['/add-property']);
    }

}
