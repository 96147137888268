import { PricingRuleType } from "./pricing-rule-type";
import { TimeSpan } from "../data-classes/time-span";

export class PricingRule {

    public id: number
    public price: number
    public type: PricingRuleType
    public startTime: TimeSpan
    public endTime: TimeSpan
    public displayEndTime: string
    public displayStartTime: string
    public selected: boolean
    public availableStartTime: number[][]
    public availableEndTime: number[][]

    //TODO: refactor this
    constructor(type: PricingRuleType) {
        this.type = type
        this.selected = false
        this.initialAvailableTimePairs()
    }

    public setEndTime(numArr: number[]): void {
        this.endTime = new TimeSpan(0, numArr[0], numArr[1], 0)
        this.displayEndTime = this.endTime.HoursMinutesString
    }

    public setStartTime(numArr: number[]): void {
        this.startTime = new TimeSpan(0, numArr[0], numArr[1], 0)
        this.displayStartTime = this.startTime.HoursMinutesString
    }

    public static FromDTO(dto: PricingRule)
    {
        var rule = new PricingRule(dto.type);
        rule.id = dto.id;
        rule.price = dto.price;
        rule.startTime = TimeSpan.FromDTO(dto.startTime);
        rule.endTime = TimeSpan.FromDTO(dto.endTime);
        rule.displayEndTime = rule.endTime.HoursMinutesString;
        rule.displayStartTime = rule.startTime.HoursMinutesString;
        rule.selected = true;
        return rule;
    }

    //populate availableTimes with values [0,0], [0,30] .. [23,30]
    private initialAvailableTimePairs() {
        this.availableStartTime = []
        for (var i = 0; i < 24; i++) {
            for (var j = 0; j < 31; j += 30) {
                var newTimePair = [i, j]
                this.availableStartTime.push(newTimePair)
            }
        }

        //copy
        this.availableEndTime = Object.create(this.availableStartTime);
        this.availableEndTime.push([24, 0])

    }

}