import { Component, Input, OnInit, ViewChild, EventEmitter, Output, AfterViewInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { WeekCalendarView } from "../../../../components/week-calendar-view/week-calendar-view.component";
import { WorkingHours } from "../../../../models/properties/working-hours";
import { BookingSegment } from "../../../../models/booking/booking-segment";

@Component({
    selector: 'search-calendar-modal',
    templateUrl: "search-calendar-modal.component.html",
    styleUrls: ['./search-calendar-modal.component.scss']
})
export class SearchCalendarModal implements AfterViewInit {

    private presetSelections: BookingSegment[];
    @ViewChild('calendarView') calendarView : WeekCalendarView;
    @Input() spaceId: number;
    @Input() title: string;
    @Input() isInputCalendar: boolean;
    @Input() isWorkingTimeSetup: boolean;
    @Input() markWorkingHours: boolean;
    @Input() workingHours: WorkingHours;
    @Output() passBookings: EventEmitter<any> = new EventEmitter();

    constructor(public activeModal: NgbActiveModal
    ) { }

    public GetAllSelections () : BookingSegment[]
    {
        var result = this.calendarView.GetAllSelections(); 
        return result;
    }

    public SetSelections(selections: BookingSegment[])
    {
        this.presetSelections = selections;
    }

    ngAfterViewInit()
    {
        if (this.presetSelections != null)
            this.calendarView.SetSelections(this.presetSelections);
    }

    Close()
    {
        this.passBookings.emit(this.GetAllSelections());
        this.GetAllSelections();
        this.activeModal.close('Close click');
    }
}