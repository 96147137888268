import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { EbentoUser } from 'app/modules/shared/models/users/ebento-user';

@Component({
  selector: 'app-users-list-element',
  templateUrl: './users-list-element.component.html',
  styleUrls: ['./users-list-element.component.scss']
})
export class UsersListElementComponent implements OnInit {

    constructor(private router: Router, public authService: AuthService) { }

    @Input() user: EbentoUser;
    errorMessage: string;


  ngOnInit() {
  }

    viewDetails()
    {
        if (this.user.userType == 'Property Manager')
            this.router.navigate(['pm-user-details/' + this.user.id]);
        else if (this.user.userType == 'Event Planner')
            this.router.navigate(['ep-user-details/' + this.user.id]);
    }

    viewMessages()
    {
        this.router.navigate(["/admin/messages/inspect/" + this.user.id]);
    }

    manageProperties() {
        this.router.navigate(["/admin/properties/" + this.user.id]);
    }
}
