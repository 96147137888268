import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EbentoProperty } from '../../models/properties/ebento-property';
import { PropertyService } from '../../services/property.service';
import { WorkshopService } from '../../services/workshop.service';
import { WorkshopTile } from '../../models/search/workshop-tile';
import { EbentoWorkshop } from '../../models/workshops/ebento-workshop';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'property-workshops-carousel',
    templateUrl: 'property-workshops-carousel.component.html',
    styleUrls: ['property-workshops-carousel.component.scss']
})
export class PropertyWorkshopsCarousel implements OnInit {
    @Input() property: EbentoProperty;
    @ViewChild('carousel', { read: NgbCarousel }) carousel: NgbCarousel;
    workshops: WorkshopTile[];
    constructor(private propertyService: PropertyService, public workshopService: WorkshopService, private router: Router)
    {
        this.getScreenSize();
    }

    ngOnInit()
    {
        this.workshopService.getAllWorkshopForProperty(this.property.id).subscribe(
            (result) => { this.workshops = result; });
    }

    public numbersOfNplets(n:number): number[] {
        var non = new Array();
        for (var i = 0; i < Math.ceil(this.workshops.length / n); i++) {
            non.push(i);
        }
        return non;
    }
    public GetNplet(index: number,n:number): WorkshopTile[] {
        var result: WorkshopTile[] = new Array();
        var i = 0;
        while (i < n && i + index < this.workshops.length) {
            result.push(this.workshops[i + index]);
            i++;
        }
        return result;
    }

    public propertyDetails() {
        this.propertyService.getProperty(this.property.id)
            .subscribe(
                () => this.router.navigate(['/property-details']),
                //error => this.errorEmiter.emit(error) <- Treba li nam ovo ?
            );
    }

    public workshopDetails(workshopTile: WorkshopTile) {
        this.workshopService.getWorkshop(workshopTile.id)
            .subscribe(
            () => this.router.navigate([`/property-manager/experience-preview/${workshopTile.id}`]),
                //error => this.errorEmiter.emit(error) <- Treba li nam ovo ?
            );
    }

    scrHeight: any;
    scrWidth: any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        // console.log(this.scrHeight, this.scrWidth);
    }

    getColumnCount() {
        return (this.scrWidth >= 1200) ? 4 : (this.scrWidth >= 992) ? 3 : (this.scrWidth < 576) ? 1 : 2;
    }

    public swipeLeft() {
        this.carousel.next();
    }

    public swipeRight() {
        this.carousel.prev();
    }

    public stop(evt: Event, workshop: EbentoWorkshop) {
        evt.stopImmediatePropagation();
        evt.preventDefault();
        var newValue = !workshop.isListingActive;
        this.workshopService.setWorkshopActive(workshop.id, newValue).subscribe(
            (res) => { workshop.isListingActive = newValue; }
        );
    }
}

