// Modules
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxWigModule } from "ngx-wig";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxCaptchaModule } from "ngx-captcha";
// import {MatExpansionModule} from '@angular/material/expansion';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { HighlightSearch } from "app/helpers/highlighter";
import { LineBreaker } from "app/helpers/linebreaker";
import { AccountStatusCheckboxComponent } from "../admin/components/admin-invoice-status-overview-table/account-status-checkbox/account-status-checkbox.component";
import { ClickableUserTypeComponent } from "../admin/components/admin-invoice-status-overview-table/clickable-user-type/clickable-user-type.component";
import { InvoiceStatusCheckboxComponent } from "../admin/components/admin-invoice-status-overview-table/invoice-status-checkbox/invoice-status-checkbox.component";
import { ClickableSpaceName } from "../admin/components/admin-space-picker-table/clickable-space-name/clickable-space-name.component";
import { PerfectCheckbox } from "../admin/components/admin-space-picker-table/perfect-checkbox/perfect-checkbox.component";
import { UniqueCheckbox } from "../admin/components/admin-space-picker-table/unique-checkbox/unique-checkbox.component";
import { ClickableWorkshopName } from "../admin/components/admin-workshop-picker-table/clickable-workshop-name/clickable-workshop-name.component";
import { PerfectWorkshopCheckbox } from "../admin/components/admin-workshop-picker-table/perfect-workshop-checkbox/perfect-workshop-checkbox.component";
import { UniqueWorkshopCheckbox } from "../admin/components/admin-workshop-picker-table/unique-workshop-checkbox/unique-workshop-checkbox.component";
import { UsersListElementComponent } from "../admin/components/users-list/users-list-element/users-list-element.component";
import { AccountSettingsComponent } from "../auth/components/account-settings/account-settings.component";
import { ChangeEmailComponent } from "../auth/components/change-email/change-email.component";
import { ChangePassword } from "../auth/components/change-password/change-password.component";
import { RegisterCommon } from "../auth/components/register/register-common/register-common.component";
import { Register } from "../auth/components/register/register/register.component";
import { VerifyByEmail } from "../auth/components/register/verify-by-email/verify-by-email.component";
import { BookingDetails } from "../event-planner/components/booking-details/booking-details.component";
//import { BookingPage } from "../event-planner/components/booking-page/booking-page.component";
import { BookingRecap } from "../event-planner/components/booking-recap/booking-recap.component";
import { CarouselTile } from "../event-planner/components/carousel/carousel-tile/carousel-tile.component";
import { FavoriteSpaceCarouselTile } from "../event-planner/components/carousel/carousel-tile/favorite-space-carousel-tile/favorite-space-carousel-tile.component";
import { FavoriteWorkshopCarouselTile } from "../event-planner/components/carousel/carousel-tile/favorite-workshop-carousel-tile/favorite-workshop-carousel-tile.component";
import { SearchCarouselTile } from "../event-planner/components/carousel/carousel-tile/search-tile/search-carousel-tile.component";
import { SpaceCarouselTile } from "../event-planner/components/carousel/carousel-tile/space-carousel-tile/space-carousel-tile.component";
import { WorkshopCarouselTile } from "../event-planner/components/carousel/carousel-tile/workshop-carousel-tile/workshop-carousel-tile.component";
import { ClosestWorkshops } from "../event-planner/components/carousel/index-carousel/closest-workshops/closest-workshops.component";
import { FavoriteSpaces } from "../event-planner/components/carousel/index-carousel/favorite-spaces/favorite-spaces.component";
import { FavoriteWorkshops } from "../event-planner/components/carousel/index-carousel/favorite-workshops/favorite-workshops.component";
import { LocalCities } from "../event-planner/components/carousel/index-carousel/local-cities/local-cities.component";
import { LocalWorkshops } from "../event-planner/components/carousel/index-carousel/local-workshops/local-workshops.component";
import { PerfectSpaces } from "../event-planner/components/carousel/index-carousel/perfect-spaces/perfect-spaces.component";
import { UniqueSpaces } from "../event-planner/components/carousel/index-carousel/unique-spaces/unique-spaces.component";
import { WorkshopsByType } from "../event-planner/components/carousel/index-carousel/workshops-by-type/workshops-by-type.component";
import { WorkshopsThisWeek } from "../event-planner/components/carousel/index-carousel/workshops-this-week/workshops-this-week.component";
import { FavoriteSpaceButton } from "../event-planner/components/favorite-space-button/favorite-space-button.component";
import { FavoriteWorkshopButton } from "../event-planner/components/favorite-workshop-button/favorite-workshop-button.component";
import { ModifyPaymentComponent } from "../event-planner/components/modify-payment/modify-payment.component";
import { ModifyWorkshopPaymentComponent } from "../event-planner/components/modify-ws-payment/modify-ws-payment.component";
import { ReservationTileComponent } from "../event-planner/components/reservation-tile/reservation-tile.component";
import { SpaceListing } from "../event-planner/components/space-listing/space-listing.component";
import { WorkshopBookingDetails } from "../event-planner/components/workshop-booking-details/workshop-booking-details.component";
import { WorkshopBookingPage } from "../event-planner/components/workshop-booking-page/workshop-booking-page.component";
import { WorkshopBookingRecap } from "../event-planner/components/workshop-booking-recap/workshop-booking-recap.component";
import { WorkshopIndexPagePlanner } from "../event-planner/components/workshop-index/workshop-index.component";
import { WorkshopListingMenus } from "../event-planner/components/workshop-listing-menus/workshop-listing-menus.component";
import { WorkshopListingPricing } from "../event-planner/components/workshop-listing-pricing/workshop-listing-pricing.component";
import { WorkshopListing } from "../event-planner/components/workshop-listing/workshop-listing.component";
import { WorkshopPaymentComponent } from "../event-planner/components/workshop-payment/workshop-payment.component";
import { WorkshopReservationTileComponent } from "../event-planner/components/workshop-reservation-tile/workshop-reservation-tile.component";
import { WorkshopSearchFilterIndex } from "../event-planner/components/workshop-search-filter-index/workshop-search-filter-index.component";
import { WorkshopSearchResults } from "../event-planner/components/workshop-search-results/workshop-search-results.component";
import { MenuUploaderComponent } from "../property-manager/components/add-workshop/menu-uploader/menu-uploader.component";
import { BookedOnCell } from "../property-manager/components/booked-on-cell/booked-on-cell.component";
import { BookerPlannerModal } from "../property-manager/components/booker-planner-modal/booker-planner-modal.component";
import { BookingNameCell } from "../property-manager/components/booking-name-cell/booking-name-cell.component";
import { BookingTable } from "../property-manager/components/booking-table/booking-table.component";
import { ClickablePlannerName } from "../property-manager/components/clickable-planner-name/clickable-planner-name.component";
import { SuccessPageAddSpace } from "../property-manager/components/success-page-add-space/success-page-add-space.component";
import { AmenityBooker } from "./components/amenity-booker/amenity-booker.component";
import { BookingOverviewPanelComponent } from "./components/booking-overview-panel/booking-overview-panel.component";
import { BooleanCellComponent } from "./components/boolean-cell/boolean-cell.component";
import { DraggableCalendarView } from "./components/draggable-calendar-view/draggable-calendar-view.component";
import { Dropdown } from "./components/dropdown/dropdown.component";
import { FiveStarScale } from "./components/five-star-scale/five-star-scale.component";
import { MonthCalendarView } from "./components/month-calendar-view/month-calendar-view.component";
import { MultipleSpacesOverview } from "./components/multiple-spaces-overview/multiple-spaces-overview.component";
import { PropertyDetails } from "./components/property-details/property-details.component";
import { PropertyListDisplay } from "./components/property-list-display/property-list-display.component";
import { PropertySpacesCarousel } from "./components/property-spaces-carousel/property-spaces-carousel.component";
import { PropertyWorkshopsCarousel } from "./components/property-workshops-carousel/property-workshops-carousel.component";
import { SpaceDetails } from "./components/space-details/space-details.component";
import { SpaceReviewOverall } from "./components/space-review-overall/space-review-overall.component";
import { SpaceReviewTile } from "./components/space-review-tile/space-review-tile.component";
import { SpaceSearchResultTile } from "./components/space-search-result-tile/space-search-result-tile.component";
import { TagSelectorComponent } from "./components/tag-selector/tag-selector.component";
import { TimeSpanSelector } from "./components/time-span-selector/time-span-selector.component";
import { WeekCalendarView } from "./components/week-calendar-view/week-calendar-view.component";
import { WorkshopDetails } from "./components/workshop-details/workshop-details.component";
import { WorkshopSearchResultTile } from "./components/workshop-search-result-tile/workshop-search-result-tile.component";
import { WsBookingOverviewPanelComponent } from "./components/ws-booking-overview-panel/ws-booking-overview-panel.component";
import { CustomTooltipDirective } from "./directives/custom-tooltip.directive";
import { AccountDeactivationModalComponent } from "./modules/modals/components/account-deactivation-modal/account-deactivation-modal.component";
import { AddRuleModal } from "./modules/modals/components/add-rule-modal/add-rule-modal.component";
import { BasicModal } from "./modules/modals/components/basic-modal/basic-modal.component";
import { ChangePasswordModalComponent } from "./modules/modals/components/change-password-modal/change-password-modal.component";
import { ConfirmationModalsComponent } from "./modules/modals/components/confirmation-modals/confirmation-modals.component";
import { DraggableCalendarModal } from "./modules/modals/components/draggable-calendar-modal/draggable-calendar-modal.component";
import { EditEmailModalComponent } from "./modules/modals/components/edit-email-modal/edit-email-modal.component";
import { ModifyBookingInfoModal } from "./modules/modals/components/modify-booking-info-modal/modify-booking-info-modal.component";
import { RequestRejectMessageModal } from "./modules/modals/components/request-reject-message-modal/request-reject-message-modal.component";
import { SearchCalendarModal } from "./modules/modals/components/search-calendar-modal/search-calendar-modal.component";
import { WeekCalendarModal } from "./modules/modals/components/week-calendar-modal/week-calendar-modal.component";
import { UploadAttachment } from "./modules/upload/upload-attachment/upload-attachment.component";
import { UploadImagesComponent } from "./modules/upload/upload-images/upload-images.component";
import { UploadImage } from "./modules/upload/upload-image/upload-image.component";
import { NewsfeedPage } from "./modules/footer-pages/components/newsfeed-page/newsfeed-page.component";
import { NewsPreview } from "./modules/footer-pages/components/news-preview/news-preview.component";
import { NewsPage } from "./modules/footer-pages/components/news-page/news-page.component";
import { PricingInfoComponent } from "./modules/footer-pages/components/pricing-info/pricing-info.component";
import { FaqComponent } from "./modules/footer-pages/components/faq/faq.component";
import { AboutComponent } from "./modules/footer-pages/components/about/about.component";
import { CareerComponent } from "./modules/footer-pages/components/career/career.component";
import { TermsComponent } from "./modules/footer-pages/components/terms/terms.component";
import { PropertyDetailsCard } from "./components/property-details-card/property-details-card.component";
import { ExpandablePropertyDetailsCard } from "./components/expandable-property-details-card/expandable-property-details-card.component";
import { PropertyDetailsSpaceTile } from "./components/property-details-space-tile/property-details-space-tile.component";
import { PropertyDetailsWorkshopTile } from ".//components/property-details-workshop-tile/property-details-workshop-tile.component";
import { EntertainmentPropertyList } from "./components/entertainment-property-list/entertainment-property-list.component";
import { EntertainmentSpaceList } from "./components/entertainment-perfect-list/entertainment-space-list.component";
import { EntertainmentHomePage } from "./components/entertainment-home-page/entertainment-home-page.component";
import { PropertyDetailsSpaceCardComponent } from "./components/property-details-space-card/property-details-space-card.component";
import { PropertyDetailsExperienceCardComponent } from "./components/property-details-experience-card/property-details-experience-card.component";
import { PropertyDetailsEntertainmentCardComponent } from "./components/property-details-entertainment-card/property-details-entertainment-card.component";
import { CartStripePaymentComponent } from './components/cart-stripe-payment/cart-stripe-payment.component';
import { AdditionalCosts } from './components/additionalCost/additionalCost.component';
import { AccordionComponent } from './components/accordion/accordion.component';

import { RouterModule } from "@angular/router";
import { AgGridModule } from "ag-grid-angular";
import { AdminEditNewsCellComponent } from "../admin/components/admin-edit-news-cell/admin-edit-news-cell.component";
import { AdminInspirationTagCellComponent } from "../admin/components/admin-inspiration-tag-cell/admin-inspiration-tag-cell.component";
import { AdminReviewBookingCellComponent } from "../admin/components/admin-review-booking-cell/admin-review-booking-cell.component";
import { AdminReviewCellComponent } from "../admin/components/admin-review-cell/admin-review-cell.component";
import { AdminReviewStarCellComponent } from "../admin/components/admin-review-star-cell/admin-review-star-cell.component";
import { AdminTagCellComponent } from "../admin/components/admin-tag-cell/admin-tag-cell.component";
import { UploadDocument } from "./modules/upload/upload-document/upload-document.component";
import { PaymentOverview } from "../event-planner/components/payment-overview/payment-overview.component";
import { WorkshopPaymentOverview } from "../event-planner/components/workshop-payment-overview/workshop-payment-overview.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { NgSwitcheryModule } from "angular-switchery-ios";
import { CustomAccordionComponent } from "./components/custom-accordion/custom-accordion.component";
import { OverlayLoaderComponent } from "./components/overlay-loader/overlay-loader.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { OverlayLoadingDirective } from "./components/overlay-loader/overlay-loading.directive";
import { ListingActiveModal } from "../admin/components/listing-active-modal/listing-active-modal.component";
import { ListingActiveCell } from "../admin/components/listing-active-cell/listing-active-cell.component";
import { MatTabsModule } from "@angular/material/tabs";
import { AddEntertainmentService } from "../property-manager/services/add-entertainment.service";
import { SearchBox } from "./components/search-box/search-box.component";
import { EntertainmentCalendar } from './components/entertainment-calendar/entertainment-calendar.component';
import { EntertainmentPayment } from "./components/entertainment-payment/entertainment-payment.component";
import { EntertainmentBookingRecap } from './components/entertainment-booking-recap/entertainment-booking-recap.component';
import { EntertainmentPaymentComponent } from './components/entertainment-stripe-payment/entertainment-stripe-payment.component';
import { BookTimeSlot } from './components/book-timeslot/book-timeslot.component';
import { CartBookedItemComponent } from './components/booked-item/booked-item.component';
// import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';

@NgModule({
  declarations: [
    PropertyDetailsCard,
    ExpandablePropertyDetailsCard,
    PropertyDetailsSpaceTile,
    PropertyDetailsWorkshopTile,
    PropertyListDisplay,
    PropertySpacesCarousel,
    PropertyWorkshopsCarousel,
    PropertyDetails,
    SpaceDetails,
    DraggableCalendarView,
    WeekCalendarView,
    MonthCalendarView,
    MultipleSpacesOverview,
    BookingDetails,
    WorkshopBookingDetails,
    BookingTable,
    ClickablePlannerName,
    TimeSpanSelector,
    ChangePassword,
    LineBreaker,
    UploadAttachment,
    UploadDocument,
    UsersListElementComponent,
    VerifyByEmail,
    Register,
    RegisterCommon,
    BasicModal,
    ListingActiveModal,
    WeekCalendarModal,
    DraggableCalendarModal,
    SearchCalendarModal,
    AddRuleModal,
    ReservationTileComponent,
    WorkshopReservationTileComponent,
    FiveStarScale,
    ConfirmationModalsComponent,
    BookerPlannerModal,
    HighlightSearch,
    UniqueCheckbox,
    PerfectCheckbox,
    ClickableSpaceName,
    BookingNameCell,
    BookedOnCell,
    WorkshopPaymentComponent,
    AccountSettingsComponent,
    ChangePasswordModalComponent,
    EditEmailModalComponent,
    ChangeEmailComponent,
    CarouselTile,
    SpaceCarouselTile,
    WorkshopCarouselTile,
    FavoriteSpaceCarouselTile,
    FavoriteWorkshopCarouselTile,
    SearchCarouselTile,
    FavoriteWorkshops,
    FavoriteSpaces,
    LocalWorkshops,
    AccountDeactivationModalComponent,
    MenuUploaderComponent,
    UploadImagesComponent,
    WorkshopListingMenus,
    WorkshopListingPricing,
    WorkshopSearchResultTile,
    CustomTooltipDirective,
    InvoiceStatusCheckboxComponent,
    AccountStatusCheckboxComponent,
    ClickableUserTypeComponent,
    UniqueWorkshopCheckbox,
    PerfectWorkshopCheckbox,
    ClickableWorkshopName,
    BooleanCellComponent,
    ListingActiveCell,
    SuccessPageAddSpace,
    PerfectSpaces,
    UniqueSpaces,
    LocalCities,
    WorkshopBookingRecap,
    FavoriteSpaceButton,
    FavoriteWorkshopButton,
    TagSelectorComponent,
    ModifyPaymentComponent,
    ModifyWorkshopPaymentComponent,
    ModifyBookingInfoModal,
    RequestRejectMessageModal,
    BookingOverviewPanelComponent,
    WsBookingOverviewPanelComponent,
    WorkshopsByType,
    WorkshopsThisWeek,
    ClosestWorkshops,
    Dropdown,
    WorkshopSearchResults,
    SpaceSearchResultTile,
    SpaceListing,
    WorkshopListing,
    WorkshopDetails,
    WorkshopIndexPagePlanner,
    WorkshopSearchFilterIndex,
    SpaceReviewTile,
    SpaceReviewOverall,
    // BookingPage,
    WorkshopBookingPage,
    AmenityBooker,
    BookingRecap,
    PaymentOverview,
    WorkshopPaymentOverview,
    UploadDocument,
    UploadImage,
    UploadImagesComponent,
    NewsfeedPage,
    NewsPreview,
    NewsPage,
    PricingInfoComponent,
    FaqComponent,
    AboutComponent,
    CareerComponent,
    TermsComponent,
    NotFoundComponent,
    CustomAccordionComponent,
    OverlayLoaderComponent,
    OverlayLoadingDirective,
    EntertainmentPropertyList,
    EntertainmentSpaceList,
    EntertainmentHomePage,
    SearchBox,
    PropertyDetailsSpaceCardComponent,
    PropertyDetailsExperienceCardComponent,
    PropertyDetailsEntertainmentCardComponent,
    EntertainmentCalendar,
    EntertainmentPayment,
    EntertainmentBookingRecap,
    EntertainmentPaymentComponent,
    BookTimeSlot,
    CartStripePaymentComponent,
    AdditionalCosts,
    AccordionComponent
    // BookedItemComponent,
    // PaymentSuccessComponents
  ],
  imports: [
    AgGridModule.withComponents([
      ClickablePlannerName,
      BookingNameCell,
      BookedOnCell,
      UniqueCheckbox,
      UniqueWorkshopCheckbox,
      PerfectCheckbox,
      PerfectWorkshopCheckbox,
      ClickableSpaceName,
      ClickableWorkshopName,
      ClickableUserTypeComponent,
      AdminEditNewsCellComponent,
      AccountStatusCheckboxComponent,
      InvoiceStatusCheckboxComponent,
      BooleanCellComponent,
      AdminReviewCellComponent,
      AdminReviewStarCellComponent,
      AdminReviewBookingCellComponent,
      AdminTagCellComponent,
      AdminInspirationTagCellComponent,
    ]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    AgGridModule,
    NgSelectModule,
    FontAwesomeModule,
    NgxPaginationModule,
    NgxWigModule,
    NgxCaptchaModule,
    NgSwitcheryModule,
    OverlayModule,
    MatTabsModule,
    NgbDropdownModule
    // MatExpansionModule
  ],
  providers: [
    // AddEntertainmentService
    NgbDropdownModule
  ],
  exports: [
    // Modules
    // AgGridModule.withComponents(
    //     [ClickablePlannerName,
    //         BookingNameCell,
    //         BookedOnCell,
    //         UniqueCheckbox,
    //         UniqueWorkshopCheckbox,
    //         PerfectCheckbox,
    //         PerfectWorkshopCheckbox,
    //         ClickableSpaceName,
    //         ClickableWorkshopName,
    //         ClickableUserTypeComponent,
    //         AdminEditNewsCellComponent,
    //         AccountStatusCheckboxComponent,
    //         InvoiceStatusCheckboxComponent,
    //         BooleanCellComponent,
    //         AdminReviewCellComponent,
    //         AdminReviewStarCellComponent,
    //         AdminReviewBookingCellComponent,
    //         AdminTagCellComponent,
    //         AdminInspirationTagCellComponent
    // ]),
    NgbModule,
    AgGridModule,
    FontAwesomeModule,
    NgxPaginationModule,
    NgxWigModule,
    NgSelectModule,
    AgGridModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    NgbDropdownModule,
    OverlayModule,
    SearchBox,
    CartStripePaymentComponent,
    PropertyDetailsCard,
    ExpandablePropertyDetailsCard,
    PropertyDetailsSpaceTile,
    PropertyDetailsWorkshopTile,
    PropertyListDisplay,
    PropertySpacesCarousel,
    PropertyWorkshopsCarousel,
    PropertyDetails,
    SpaceDetails,
    DraggableCalendarView,
    WeekCalendarView,
    MonthCalendarView,
    MultipleSpacesOverview,
    BookingDetails,
    WorkshopBookingDetails,
    BookingTable,
    ClickablePlannerName,
    TimeSpanSelector,
    ChangePassword,
    LineBreaker,
    UploadAttachment,
    UploadDocument,
    UsersListElementComponent,
    VerifyByEmail,
    Register,
    RegisterCommon,
    BasicModal,
    WeekCalendarModal,
    DraggableCalendarModal,
    SearchCalendarModal,
    AddRuleModal,
    ReservationTileComponent,
    WorkshopReservationTileComponent,
    FiveStarScale,
    ConfirmationModalsComponent,
    BookerPlannerModal,
    HighlightSearch,
    UniqueCheckbox,
    PerfectCheckbox,
    ClickableSpaceName,
    BookingNameCell,
    BookedOnCell,
    WorkshopPaymentComponent,
    AccountSettingsComponent,
    ChangePasswordModalComponent,
    EditEmailModalComponent,
    ChangeEmailComponent,
    MenuUploaderComponent,
    CarouselTile,
    SpaceCarouselTile,
    WorkshopCarouselTile,
    FavoriteSpaceCarouselTile,
    FavoriteWorkshopCarouselTile,
    SearchCarouselTile,
    FavoriteWorkshops,
    FavoriteSpaces,
    LocalWorkshops,
    AccountDeactivationModalComponent,
    UploadImagesComponent,
    WorkshopListingMenus,
    WorkshopListingPricing,
    WorkshopSearchResultTile,
    CustomTooltipDirective,
    InvoiceStatusCheckboxComponent,
    AccountStatusCheckboxComponent,
    ClickableUserTypeComponent,
    UniqueWorkshopCheckbox,
    PerfectWorkshopCheckbox,
    ClickableWorkshopName,
    BooleanCellComponent,
    SuccessPageAddSpace,
    PerfectSpaces,
    UniqueSpaces,
    LocalCities,
    WorkshopBookingRecap,
    FavoriteSpaceButton,
    FavoriteWorkshopButton,
    TagSelectorComponent,
    ModifyPaymentComponent,
    ModifyWorkshopPaymentComponent,
    ModifyBookingInfoModal,
    RequestRejectMessageModal,
    BookingOverviewPanelComponent,
    WsBookingOverviewPanelComponent,
    WorkshopsByType,
    WorkshopsThisWeek,
    ClosestWorkshops,
    Dropdown,
    WorkshopSearchResults,
    SpaceSearchResultTile,
    SpaceListing,
    WorkshopListing,
    WorkshopDetails,
    WorkshopIndexPagePlanner,
    WorkshopSearchFilterIndex,
    SpaceReviewTile,
    SpaceReviewOverall,
    // BookingPage,
    WorkshopBookingPage,
    AmenityBooker,
    BookingRecap,
    PaymentOverview,
    WorkshopPaymentOverview,
    UploadImage,
    UploadImagesComponent,
    UploadDocument,
    NewsfeedPage,
    NewsPreview,
    NewsPage,
    PricingInfoComponent,
    FaqComponent,
    AboutComponent,
    CareerComponent,
    TermsComponent,
    CustomAccordionComponent,
    OverlayLoaderComponent,
    OverlayLoadingDirective,
    EntertainmentSpaceList,
    EntertainmentHomePage,
    EntertainmentPayment,
    EntertainmentBookingRecap,
    EntertainmentPaymentComponent,
    BookTimeSlot,
    AdditionalCosts,
    AccordionComponent
  ],
})
export class SharedModule {}
