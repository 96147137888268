import { TimeAllocation } from "../data-classes/time-allocation";
import { PropertyAddress } from "../properties/property-address";
import { WorkshopTimeAllocation } from "../data-classes/workshop-time-allocation";
import { WorkshopTypeTag } from "../tags/workshop-type-tag";
import { AmenityType } from "../properties/amenity-type";
import { WorkshopInspirationTag } from "../tags/workshop-inspiration-tag";

export class SearchWorkshopQuery
{
    constructor(
        public timeAllocations: WorkshopTimeAllocation[], 
        public customTimeslots: boolean = true,
        public addressSearchString: string,
        public address: PropertyAddress, 
        public numberofattendees: number, 
        public amenityids: number[],
        public amenities: AmenityType[], 
        public spacetypeids: number[],
        public typeTags: WorkshopTypeTag[],
        public inspirationTags: WorkshopInspirationTag[])   
    { }
}
