import { Component, OnInit, Input } from '@angular/core';

import { Router, ActivatedRoute } from "@angular/router";
import { NotificationType } from 'app/helpers/enums/notificationType';
import { AuthService } from '../../services/auth.service';
import { PropertyService } from '../../services/property.service';
import { MessagingService } from '../../services/messaging.service';
import { NotificationService } from '../../services/notification.service';
import { PropertyManager } from '../../models/users/property-manager';
import { EbentoProperty } from '../../models/properties/ebento-property';
import { EbentoMessage } from '../../models/messaging/ebento-message';

@Component({
    selector: 'property-manager-details',
    templateUrl: 'property-manager-details.component.html'
})

export class PropertyManagerDetails implements OnInit {

    constructor(private router: Router, private route: ActivatedRoute, public authService: AuthService, private propertyService: PropertyService, public messagingService: MessagingService, private notificationService: NotificationService) { }

    managerID: string;
    propertyManager: PropertyManager;
    properties: EbentoProperty[];
    reportBoxOpen: boolean;
    reportText: string;

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.managerID = params['id'];
            this.authService.getManagerInfoByID(this.managerID).subscribe(user => {
                this.propertyManager = user;
            });
            this.propertyService.getPropertiesForUserID(this.managerID).subscribe(properties => {
                this.properties = properties;
                console.log(this.properties);
            });
        });
    }

    sendMessage() {
        this.messagingService.currentRecepient = this.propertyManager;
        this.messagingService.currentDraft = new EbentoMessage();
        this.router.navigate(['/messages/compose']);
    }

    reportManager()
    {
        this.reportBoxOpen = true;
    }

    sendReport()
    {
        this.messagingService.reportPM(this.managerID, this.reportText).subscribe(
            (result) => {
                if (result)
                {
                    this.reportBoxOpen = false;
                    this.reportText = "";
                    this.notificationService.notify(NotificationType.Success, "Thank you for your help!", "Report Sent");
                }
            }
        );
    }
}
