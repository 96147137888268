import { Component } from "@angular/core";

@Component({
    selector: "register",
    templateUrl: "register.component.html",
    styleUrls: ['../../../../shared/css/user.css']
})
export class Register {

    constructor() { }

   
}