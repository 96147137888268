import { Component, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddEntertainmentService } from "app/modules/property-manager/services/add-entertainment.service";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";
import { MessagingService } from "app/modules/shared/services/messaging.service";

@Component({
    selector: 'reject-booking-modal',
    templateUrl: 'reject-booking-modal.component.html',
    styleUrls: ['reject-booking-modal.component.css']
})

export class RejectBookingModal{

    @Input() id: number;
    @Input() message: EbentoMessage
    @Input() spaceType:string;

    public rejectReason: string = "";

    constructor(public activeModal: NgbActiveModal, private entertainmentService: AddEntertainmentService, private messagingService: MessagingService){}

    reject() {
        this.entertainmentService.rejectBooking(this.id, this.rejectReason).subscribe((response)=> {
            this.messagingService.currentDraft = new EbentoMessage();
            this.messagingService.sendEnquiryRejectMessage(this.id, this.message, this.rejectReason).subscribe((res) => {
                console.log("res", res);
            });
            if(response) this.activeModal.close('Close click');
        })
    }

    close() {
        this.activeModal.close('Close click');
    }
}