import { Component, HostListener, OnChanges, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError} from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../modules/shared/services/auth.service';
import { MessagingService } from '../../../../modules/shared/services/messaging.service';
import { CartService } from '../../services/cart.service';

@Component({
    selector: 'ebento-header',
    templateUrl: 'event-planner-ebento-header.component.html',
    styleUrls: ['../../../shared/css/ebento-header.css']
})

export class EbentoHeader implements OnInit{

    public active: string = 'home';
    public profileUrl: string;
    public placement: string = 'bottom-right';
    public href: string;
    public showTransparant: boolean = false;
    // public cartcount:number = 0;

    public SelectedLanguage = "EN";
    public showDropdown: boolean;
   
    constructor(
        public authService: AuthService,
        public router: Router,
        public translate: TranslateService,
        public messagingService: MessagingService,
        public cartService:CartService
    ) {
        this.getScreenSize();

        this.href = this.router.url;

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show progress spinner or progress bar
                console.log('Route change detected');
            }
    
            if (event instanceof NavigationEnd) {
                // Hide progress spinner or progress bar
                this.href = event.url;          
                console.log("href", this.href)
                if(this.href == '/index' || this.href == '/' || this.href == '/spaces' || this.href == '/experiences' || this.href == '/entertainment' )
                    this.showTransparant = true;
                else 
                    this.showTransparant = false;
                console.log("showTransparant", this.showTransparant);
            }
    
            if (event instanceof NavigationError) {
                 // Hide progress spinner or progress bar
    
                // Present error to user
                console.log(event.error);
            }
        });

       
    }

    ngOnInit(): void {
        this.cartService.getCartCount().subscribe((res) => {
            console.log('cart count res',res);
            this.cartService.cartcount = res.count;
        })
    }


    get userAvatar()
    {
        return this.authService.user.avatarURL;
    }


    selectTab (value) {
        this.active = value;
    }

    //TODO: refactor/put this functionality somewhere else
    onLogout() {
        this.authService.logout().subscribe(
            () => { this.router.navigate(["/index"]) },
            (err) => {
                this.router.navigate(["/index"])
                this.authService.forceLogout();
                console.error(err);
            }
        );
    }

    public isLoggedIn(): boolean {
        this.profileUrl = this.authService.getUserAvatarURL();
        this.profileUrl = `${this.profileUrl}`
        return this.authService.isLoggedIn();
    }

    public IsProfileComplete(): boolean {
        return this.authService.isLoggedIn() && this.authService.user && this.authService.user.isProfileCompleted;
    }

    public redirectToProfilePage() {

        let route;

        switch(this.authService.userType) {

            case 'EventPlanner':
                route = '/event-planner/edit-profile'
                break;
            
            case 'PropertyManager':
                route = '/property-manager/profile';
                break;

            case 'Admin':
                route = '/admin/profile';
                break;
        }
        
        // let route = "/my-profile-" + this.authService.userType.toLowerCase();
        this.router.navigate([route]);
    }

    public SetLanguage(val: string) {
        this.SelectedLanguage = val;
        this.translate.use(this.SelectedLanguage);
    }

    toggleDropdown() {
    }

    scrHeight: any;
    scrWidth: any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
    }
}