/// <reference types="@types/googlemaps" />

import { Component, ViewChild, OnInit,AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PropertyManagerReview } from 'app/modules/shared/models/users/property-manager-review';
import { AuthService, LoginRedirectType } from 'app/modules/shared/services/auth.service';
import { PropertyService } from 'app/modules/shared/services/property.service';
import { ReviewService } from 'app/modules/shared/services/review.service';
import { MessagingService } from 'app/modules/shared/services/messaging.service';
import { PropertyAddress } from 'app/modules/shared/models/properties/property-address';
import { Amenity } from 'app/modules/shared/models/properties/Amenity';
import { EbentoWorkshop } from 'app/modules/shared/models/workshops/ebento-workshop';
import { WorkshopService } from 'app/modules/shared/services/workshop.service';
import { WorkshopTimeAllocation } from 'app/modules/shared/models/data-classes/workshop-time-allocation';
import { TimeSpan } from 'app/modules/shared/models/data-classes/time-span';

@Component({
    selector: 'workshop-details',
    templateUrl: 'workshop-details.component.html',
    styleUrls: ['./workshop-details.component.scss']
})

export class WorkshopDetails implements OnInit,AfterViewInit {

    workshop: EbentoWorkshop
    reviews: PropertyManagerReview[];
    modalRef: NgbModalRef;
    activeImage:string;

    @ViewChild('gmap') gmapElement: any;

    public map: google.maps.Map;

    get workshopPhotoUrl(): string
    {
        if (this.workshop != null && this.workshop.photoUrls != null && this.workshop.photoUrls.length > 0)
            return this.workshop.photoUrls[0];
        return "images/ebento.png";
    }

    amenityQuantity: Object;
    
    constructor(
        public authService: AuthService,
        public propertyService: PropertyService,
        public workshopService: WorkshopService,
        public messagingService: MessagingService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private reviewService: ReviewService
    ) {
        this.amenityQuantity = {};
    }

    ngOnInit() {
       
        let workshopId = this.activatedRoute.snapshot.params.id;
        if (workshopId != null) {
            this.workshopService.getWorkshop(workshopId).subscribe(
                (workshop) => {
                    this.workshop = workshop;
                    this.activeImage = this.workshop.photoUrls[0];
                    this.init();
                }
            );
        }
    }

    ngAfterViewInit(): void {
        if (this.workshop!=null){
            this.SetMap();
        }
    }

    checkIsOwner(): boolean {
        return this.authService.isLoggedIn()
            && this.workshop.propertyManager.id == this.authService.user.id;
    }

    checkIsAdmin(): boolean {
        return this.authService.isLoggedIn()
            && this.authService.userType == "Administrator";
    }

    editWorkshop() {
        this.workshopService.workshopToEdit = this.workshop;
        this.router.navigate(['/add-experience']);
    }

    setBigImage(i:number){
        this.activeImage = this.workshop.photoUrls[i];
    }

    init()
    {        
        this.reviewService.getReviewsForWorkshop(this.workshop.id).subscribe(
            reviews => {
                this.reviews = reviews;
            }
        );
        if (this.workshop!=null)
            // this.SetMap();
        window.scrollTo(0, 0);
    }

    SetMap() {
        var mapProp = {
            center: new google.maps.LatLng(18.5793, 73.8143),
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        var map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
        this.map = map;
        var address = this.workshop.address;
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(address.googlemapcoordx, address.googlemapcoordy),
            map: this.map
        });

        var bounds = new google.maps.LatLngBounds();
        bounds.extend(marker.getPosition());

        this.map.fitBounds(bounds);
        var listener = google.maps.event.addListener(map, "idle", function () {
            if (map.getZoom() > 16) map.setZoom(16);
            google.maps.event.removeListener(listener);
        });
    }
    
    public AddressText(address: PropertyAddress) {
        var result: string = "";
        if (address.streetaddress != null && address.streetaddress != "")
            result += address.streetaddress;
        if (address.streetnumber != null && address.streetnumber != "")
            result += " " + address.streetnumber;
        if (address.city != null && address.city != "") {
            if (result != "")
                result += ", ";
            if (address.postalcode != null && address.postalcode != "")
                result += address.postalcode + " ";
            result += address.city;
        }
        if (address.country != null && address.country != "") {
            if (result != "")
                result += ", ";
            result += address.country;
        }
        return result;
    }

    public OptionalAmenities(): Amenity[] {
        var result: Amenity[] = new Array();
        if (this.workshop.amenities != null)
            for (var a of this.workshop.amenities) {
                if (a.isoptional)
                    result.push(a);
            }
        return result;
    }

    public Catering(): Amenity[] {
        var result: Amenity[] = new Array();
        if (this.workshop.amenities != null)
            for (var a of this.workshop.amenities) {
                if (a.amenitytype.isCatering)
                    result.push(a);
            }
        return result;
    }

    public FixedAmenities(): Amenity[] {
        var result: Amenity[] = new Array();
        if (this.workshop.amenities != null)
            for (var a of this.workshop.amenities) {
                if (!a.isoptional && !a.amenitytype.isCatering)
                    result.push(a);
            }
        return result;
    }

    get numbersOfAttendees()
    {
        var array = new Array();
        for (var i = this.workshop.minNumberOfGuests; i <= this.workshop.maxNumberOfGuests; i++) {
            array.push(i);
        }
        return array;
    }
    
    public GetDateString(allocation: WorkshopTimeAllocation): string {
        return new Date(allocation.startTime).toLocaleDateString();

    }
    public GetTimeString(allocation: WorkshopTimeAllocation): string {
        var start = new Date(allocation.startTime);
        var end = new Date(allocation.endTime);
        var startTime = new TimeSpan(0, start.getHours(), start.getMinutes(), 0);
        var endTime = new TimeSpan(0, end.getHours(), end.getMinutes(), 0);
        return startTime.HoursMinutesString + " - " + endTime.HoursMinutesString;
    }

    gotoLoginWorkshopBook() {
        this.authService.setLoginRedirectParams(LoginRedirectType.WorkshopListing, "" + this.workshop.id);
        this.router.navigate(['login']);
    }

    gotoLoginMessage() {
        this.authService.setLoginRedirectParams(LoginRedirectType.Message, "" + this.workshop.propertyManager.id);
        this.router.navigate(['login']);
    }
}
