import { Component , OnInit } from '@angular/core';
// import { EntertainmentService } from 'app/modules/event-planner/services/entertainment.service';
import { Entertainment } from '../../models/entertainment/AddEntertainmentStep1';
import { Category } from '../../models/entertainment/categories';
import { AddEntertainmentService } from '../../../property-manager/services/add-entertainment.service';
import { GeolocationService } from '../../services/geolocation.service';

@Component({
    selector: 'ebento-entertainment',
    templateUrl:'./entertainment.component.html',
    styleUrls: ['./entertainment.component.css']
})

export class EntertainmentComponent implements OnInit {

    public categoryData:Category[] = [];
    public perfectEntertainment:Entertainment[] =[];
    public uniqueEntertainment:Entertainment[] = [];
    public entertainmentNearyou:Entertainment[] = [];
    constructor(
        private entertainmentService:AddEntertainmentService,
        private geolocation:GeolocationService
        ){}

    ngOnInit(): void {
        this.entertainmentService.getPerfectEntertainments().subscribe((response) => {
            this.perfectEntertainment = response;
        })

        this.entertainmentService.getUniqueEntertainments().subscribe((response) => {
            this.uniqueEntertainment = response;
        })

        this.entertainmentService.getNearbyEntertainments(this.geolocation.geolocation).subscribe((response) => {
            this.entertainmentNearyou =response;
        })

        this.entertainmentService.getCatogories().subscribe((response) => {
            this.categoryData = response;
        })

    }

}