// Angular Modules
import { AppRoutingModule } from "./app-routing.module";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  BrowserModule,
  EVENT_MANAGER_PLUGINS,
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
  HAMMER_LOADER,
  ɵHammerGesturesPlugin,
} from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./modules/shared/shared.module";
import {
  CommonModule,
  DOCUMENT,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import {
  ClassProvider,
  NgModule,
  APP_INITIALIZER,
  Optional,
  ɵConsole,
} from "@angular/core";

//property manager module
// import { PropertyManagerModule } from './modules/property-manager/property-manager.module';

// 3rd party modules
import { AgmCoreModule } from "@agm/core";
import { DeviceDetectorModule } from "ngx-device-detector";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxStripeModule } from "ngx-stripe";
import { ToastrModule } from "ngx-toastr";
import {
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
  TranslateService,
} from "@ngx-translate/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatStepperModule } from "@angular/material/stepper";
import { MdbCollapseModule } from "mdb-angular-ui-kit/collapse";
import { MdbTabsModule } from "mdb-angular-ui-kit/tabs";
import { NgWizardModule, NgWizardConfig, THEME } from "ng-wizard";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { NgbRatingModule } from "@ng-bootstrap/ng-bootstrap";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
// import {MatExpansionModule} from '@angular/material/expansion';

// Components

import { AppComponent } from "./app.component";
import { EbentoHeader } from "./modules/event-planner/components/event-planner-ebento-header/event-planner-ebento-header.component";
import { AdminHeader } from "./modules/admin/components/admin-ebento-header/admin-ebento-header.component";
import { PropertyManagerHeader } from "./modules/property-manager/components/property-manager-ebento-header/property-manager-ebento-header.component";
import { EbentoFooter } from "./modules/shared/components/ebento-footer/ebento-footer.component";
import { ContactUsComponent } from "./modules/shared/modules/footer-pages/components/contact-us/contact-us.component";
import { CovidComponent } from "./modules/shared/modules/footer-pages/components/covid/covid.component";
import { IndexPagePlanner } from "./modules/event-planner/components/index/index.component";
import { SpaceIndexPagePlanner } from "./modules/event-planner/components/space-index/space-index.component";
import { WhyUseEbento } from "./modules/event-planner/components/why-use-ebento/why-use-ebento.component";
import { SearchFilterIndex } from "./modules/event-planner/components/search-filter-index/search-filter-index.component";
import { CustomerDetails } from "./modules/property-manager/components/customer-details/customer-details.component";
import { Login } from "./modules/auth/components/login/login.component";
import { MyProfileAdmin } from "./modules/admin/components/my-profile-admin/my-profile-admin.component";
import { EditProfileAdmin } from "./modules/admin/components/edit-profile-admin/edit-profile-admin.component";
import { FeedbackComponent } from "./modules/shared/modules/footer-pages/components/feedback/feedback.component";
import { CreditCardComponent } from "./modules/shared/components/credit-card/credit-card.component";
import { CreditCardModalComponent } from "./modules/shared/modules/modals/components/credit-card-modal/credit-card-modal.component";
import { InvoiceModalComponent } from "./modules/shared/modules/modals/components/invoice-modal/invoice-modal.component";
import { BankAccountModalComponent } from "./modules/shared/modules/modals/components/bank-account-modal/bank-account-modal.component";
import { AddPropertyForm } from "./modules/property-manager/components/add-property/add-property.component";
import { AddSpaceForm } from "./modules/property-manager/components/add-space/add-space.component";
import { AddWorkshopForm } from "./modules/property-manager/components/add-workshop/add-workshop.component";
import { EntertainmentComponent } from "./modules/shared/components/entertainment/entertainment.component";
// import { SearchBox } from './modules/shared/components/search-box/search-box.component';
import { AddEntertainmentComponent } from "./modules/property-manager/components/add-entertainment/add-entertainment.component";
import { AddEntertainment4Component } from "./modules/property-manager/components/add-entertainment/add-entertainment-4/add-entertainment-4.component";
import { AddEntertainment5Component } from "./modules/property-manager/components/add-entertainment/add-entertainment-5/add-entertainment-5.component";
import { AddEntertainment6Component } from "./modules/property-manager/components/add-entertainment/add-entertainment-6/add-entertainment-6.component";
import { PreviewComponent } from "./modules/property-manager/components/add-entertainment/preview-page/preview.component";
import { BannerComponent } from "./modules/shared/components/banner/banner.component";
import { EditEntertainment } from "./modules/property-manager/components/edit-entertainment/edit-entertainment.component";
import { SpaceListComponent } from "./modules/event-planner/components/space-list/space-list.component";
import { EditEmailComponent } from "./modules/auth/components/edit-email/edit-email.component";
import { EditPasswordComponent } from "./modules/auth/components/edit-password/edit-password.component";
import { EbentoCart } from "./modules/event-planner/components/ebento-cart/ebento-cart.component";
import { CartTileComponent } from "./modules/event-planner/components/cart-tile/cart-tile.component";
import { CartPaymentComponent } from "./modules/event-planner/components/cart-payment/cart-payment.component";
import { LandingComponent } from "./modules/event-planner/components/landing/landing.component";
import { PaymentSuccessComponent } from "./modules/shared/components/payment-success/payment-success.component";
import { CartBookedItemComponent } from "./modules/shared/components/booked-item/booked-item.component";
import { CartCreditCardComponent } from "./modules/event-planner/components/cart-credit-card/cart-credit-card.component";
import { EnquiryBookingComponent } from "./modules/event-planner/components/enquiry-booking/enquiry-booking.component";
import { EnquirySuccessComponent } from "./modules/event-planner/components/enquiry-success/enquiry-success.component";
import { BookedItemComponent } from "./modules/event-planner/components/booked-item/booked-item.component";
import { RejectBookingModal } from "./modules/shared/modules/modals/components/reject-booking-modal/reject-booking-modal.component";
import { CartPaymentOverview } from "./modules/event-planner/components/cart-payment-overview/cart-payment-overview.component";
// import { EntertainmentPropertyList } from './modules/shared/components/entertainment-property-list/entertainment-property-list.component';
import { ModifyCartSpace } from "./modules/event-planner/components/modify-cart-space/modify-cart-space.component";
import { ModifyCartExperience } from "./modules/event-planner/components/modify-cart-experience/modify-cart-experience.component";
import { ListService } from "./modules/event-planner/components/list-service/list-service.component";

//=== services =======

import { AuthService } from "./modules/shared/services/auth.service";
import { AdminUserService } from "./modules/admin/services/admin-user.service";
import { PropertyService } from "./modules/shared/services/property.service";
import { AdminPropertyService } from "./modules/admin/services/admin-property.service";
import { SpaceService } from "./modules/shared/services/space.service";
import { AdminSpaceService } from "./modules/admin/services/admin-space.service";
import { AdminWorkshopService } from "./modules/admin/services/admin-workshop.service";
import { SpaceBookingService } from "./modules/shared/services/space-booking.service";
import { AdminBookingService } from "./modules/admin/services/admin-booking.service";
import { ReviewService } from "./modules/shared/services/review.service";
import { ManagerBookingService } from "./modules/property-manager/services/manager-booking.service";
import { UploadService } from "./modules/shared/services/upload.service";
import { GeolocationService } from "./modules/shared/services/geolocation.service";
import { SearchService } from "./modules/event-planner/services/search.service";
import { MessagingService } from "./modules/shared/services/messaging.service";
import { AdminMessagingService } from "./modules/admin/services/admin-messaging.service";
import { NotificationService } from "./modules/shared/services/notification.service";
import { NewsService } from "./modules/shared/services/news.service";
import { AdminNewsService } from "./modules/admin/services/admin-news.service";
import { AdminReviewService } from "./modules/admin/services/admin-review.service";
import { ReferralService } from "./modules/shared/services/referral.service";
import { SimplePagesService } from "./modules/shared/services/simple-pages.service";
import { CustomerService } from "./modules/shared/services/customer.service";
import { WorkshopService } from "./modules/shared/services/workshop.service";
import { WorkshopBookingService } from "./modules/shared/services/workshop-booking.service";
import { FavoritesService } from "./modules/event-planner/services/favorites.service";
import { AppInitService } from "../app-init.service";
import { AdminDemolitionService } from "./modules/admin/services/admin-demolition.service";
import { AddEntertainmentService } from "./modules/property-manager/services/add-entertainment.service";
import { EntertainmentService } from "./modules/event-planner/services/entertainment.service";
import { ContactUsService } from "./modules/shared/services/contact-us.service";
import { CartService } from "./modules/event-planner/services/cart.service";

// Interceptors
import { LoggingInterceptor } from "./helpers/httpInterceptor";
import { JwtInterceptor } from "./helpers/jwt.interceptor";
import { EbentoGmapComponent } from "./modules/shared/components/ebento-gmap/ebento-gmap.component";
import { SpaceSearchResults } from "./modules/event-planner/components/space-search-results/space-search-results.component";
import { ReviewPropertyManager } from "./modules/event-planner/components/review-property-manager/review-property-manager.component";
import { EpReferralComponent } from "./modules/auth/components/ep-referral/ep-referral.component";
import { PmReferralComponent } from "./modules/auth/components/pm-referral/pm-referral.component";
import { ConfirmRegistration } from "./modules/auth/components/register/confirm-registration/confirm-registration.component";
import { EbentoModalService } from "./modules/shared/services/ebento-modal.service";
import { PropertyManagerDetails } from "./modules/shared/components/property-manager-details/property-manager-details.component";
import { ForgotPassword } from "./modules/auth/components/forgot-password/forgot-password.component";
import { ForgotPasswordSent } from "./modules/auth/components/register/forgot-password-sent/forgot-password-sent.component";

import "hammerjs";
import { PaymentService } from "./modules/event-planner/services/payment.service";
import { SubmissionService } from "./modules/shared/services/submission.service";
import { MyInterceptor } from "./temp/customInterceptor.interceptor";
import { BookingDetailsModal } from "./modules/shared/modules/modals/components/booking-details-modal/booking-details.modal.component";
// ADMIN

// import { AdminEditAboutComponent } from './modules/admin/components/admin-edit-about/admin-edit-about.component';
// import { AdminFeedbackReviewComponent } from './modules/admin/components/admin-feedback-review/admin-feedback-review.component';
// import { AdminEditTermComponent } from './modules/admin/components/admin-edit-term/admin-edit-term.component';
// import { AdminEditCareerComponent } from './modules/admin/components/admin-edit-career/admin-edit-career.component';
// import { AdminIndexComponent } from './modules/admin/components/admin-index/admin-index.component';
// import { AdminSpacePickerComponent } from './modules/admin/components/admin-space-picker/admin-space-picker.component';
// import { ConversationsAdmin } from './modules/admin/modules/messaging/components/admin-conversations/admin-conversations.component';
// import { UsersListComponent } from './modules/admin/components/users-list/users-list.component';
// import { AdminWorkshopPickerComponent } from './modules/admin/components/admin-workshop-picker/admin-workshop-picker.component';
// import { AdminEditPricingInfoComponent } from './modules/admin/components/admin-edit-pricing-info/admin-edit-pricing-info.component';
// import { AdminEditNewsComponent } from './modules/admin/components/admin-edit-news/admin-edit-news.component';
// import { AdminNewsEditorComponent } from './modules/admin/components/admin-news-editor/admin-news-editor.component';
// import { AdminManageReviewsComponent } from './modules/admin/components/admin-manage-reviews/admin-manage-reviews.component';
// import { AdminEditFaqComponent } from './modules/admin/components/admin-edit-faq/admin-edit-faq.component';
// import { AdminEditWsTypeTagsComponent } from './modules/admin/components/admin-wstype-tags/admin-wstype-tags.component';
// import { AdminEditWsInspirationTagsComponent } from './modules/admin/components/admin-wsinspiration-tags/admin-wsinspiration-tags.component';
// import { AdminCleanupComponent } from './modules/admin/components/admin-cleanup/admin-cleanup.component';
// import { AdminInvoiceStatusOverviewComponent } from './modules/admin/components/admin-invoice-status-overview/admin-invoice-status-overview.component';
// import { AdminEditCovidComponent } from './modules/admin/components/admin-edit-covid/admin-edit-covid.component';

// import { AdminEditAboutComponent } from './modules/admin/components/admin-edit-about/admin-edit-about.component';
// import { AdminEditCareerComponent } from './modules/admin/components/admin-edit-career/admin-edit-career.component';
// import { AdminEditTermComponent } from './modules/admin/components/admin-edit-term/admin-edit-term.component';
// import { AdminFeedbackReviewComponent } from './modules/admin/components/admin-feedback-review/admin-feedback-review.component';
// import { AdminIndexComponent } from './modules/admin/components/admin-index/admin-index.component';
// import { AdminSpacePickerComponent } from './modules/admin/components/admin-space-picker/admin-space-picker.component';
// import { AdminEditNewsComponent } from './modules/admin/components/admin-edit-news/admin-edit-news.component';
// import { AdminEditFaqComponent } from './modules/admin/components/admin-edit-faq/admin-edit-faq.component';
// import { AdminNewsEditorComponent } from './modules/admin/components/admin-news-editor/admin-news-editor.component';
// import { AdminManageReviewsComponent } from './modules/admin/components/admin-manage-reviews/admin-manage-reviews.component';
// import { UsersListComponent } from './modules/admin/components/users-list/users-list.component';
// import { ConversationsAdmin } from './modules/admin/modules/messaging/components/admin-conversations/admin-conversations.component';
// import { AdminWorkshopPickerComponent } from './modules/admin/components/admin-workshop-picker/admin-workshop-picker.component';
// import { AdminEditWsTypeTagsComponent } from './modules/admin/components/admin-wstype-tags/admin-wstype-tags.component';
// import { AdminEditWsInspirationTagsComponent } from './modules/admin/components/admin-wsinspiration-tags/admin-wsinspiration-tags.component';
// import { AdminCleanupComponent } from './modules/admin/components/admin-cleanup/admin-cleanup.component';
// import { AdminEditPricingInfoComponent } from './modules/admin/components/admin-edit-pricing-info/admin-edit-pricing-info.component';
// import { AdminInvoiceStatusOverviewComponent } from './modules/admin/components/admin-invoice-status-overview/admin-invoice-status-overview.component';
// import { AdminEditCovidComponent } from './modules/admin/components/admin-edit-covid/admin-edit-covid.component';

// EVENT PLANNER

// import { ReferralPageEpComponent } from './modules/event-planner/components/referral-page-ep/referral-page-ep.component';
// import { EpReferralComponent } from './modules/event-planner/components/ep-referral/ep-referral.component';
// import { EventPlannerBillingSettingsComponent } from './modules/event-planner/components/event-planner-billing-settings/event-planner-billing-settings.component';
// import { MyProfileEventPlanner } from './modules/event-planner/components/my-profile-event-planner/my-profile-event-planner.component';
// import { EditProfileEventPlanner } from './modules/event-planner/components/edit-profile-event-planner/edit-profile-event-planner.component';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

const LOGGING_INTERCEPTOR_PROVIDER: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoggingInterceptor,
  multi: true,
};

const JWT_INTERCEPTOR_PROVIDER: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: JwtInterceptor,
  multi: true,
};

//// ovo ne znam sta je. kaze:   AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

//<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBwt0tpToxBlVaUU65KmCRPDZHscHQrdb8&libraries=places&callback=initAutocomplete"
//async defer > </script>

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  };
}

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default,
};

@NgModule({
  declarations: [
    // APP MODULE

    AppComponent,
    EbentoHeader,
    AdminHeader,
    PropertyManagerHeader,
    EbentoFooter,
    WhyUseEbento,
    SearchFilterIndex,
    SpaceSearchResults,
    Login,
    ForgotPassword,
    ForgotPasswordSent,
    EpReferralComponent,
    PmReferralComponent,
    AddPropertyForm,
    // AddSpaceForm,
    AddWorkshopForm,
    CustomerDetails,
    PropertyManagerDetails,
    IndexPagePlanner,
    SpaceIndexPagePlanner,
    CovidComponent,
    FeedbackComponent,
    MyProfileAdmin,
    EditProfileAdmin,
    EbentoGmapComponent,
    ReviewPropertyManager,
    ConfirmRegistration,
    EditEntertainment,
    EditEmailComponent,
    EditPasswordComponent,
    CartPaymentOverview,
    // SHARED MODULE

    EntertainmentComponent,
    // SearchBox,
    BannerComponent,
    // EntertainmentPropertyList,
    // WorkshopPaymentComponent,
    // AccountSettingsComponent,
    // ChangePasswordModalComponent,
    // EditEmailModalComponent,
    // ChangeEmailComponent,
    // MenuUploaderComponent,
    // CarouselTile,
    // SpaceCarouselTile,
    // WorkshopCarouselTile,
    // FavoriteSpaceCarouselTile,
    // FavoriteWorkshopCarouselTile,
    // SearchCarouselTile,
    // FavoriteWorkshops,
    // FavoriteSpaces,
    // LocalWorkshops,
    // AccountDeactivationModalComponent,
    // MenuUploaderComponent,
    // UploadImagesComponent,
    // WorkshopListingMenus,
    // WorkshopListingPricing,
    // WorkshopSearchResultTile,
    // CustomTooltipDirective,
    // InvoiceStatusCheckboxComponent,
    // AccountStatusCheckboxComponent,
    // ClickableUserTypeComponent,
    // UniqueWorkshopCheckbox,
    // PerfectWorkshopCheckbox,
    // ClickableWorkshopName,
    // BooleanCellComponent,
    // SuccessPageAddSpace,
    // UsersListComponent,
    // ConversationsAdmin,
    // BookingDetails,
    // ModifyBookingComponent,
    // WorkshopBookingDetails,
    // ModifyWsBookingComponent,
    // BookingTable,
    // CustomerDetails,
    // ClickablePlannerName,
    // TimeSpanSelector,
    // CompleteRegistrationEventPlanner,
    // CompleteRegistrationAdministrator,
    // ConfirmRegistration,
    // UploadDocument,
    // UploadImage,
    // MultipleImageUpload,

    // ADMIN

    // AdminCouponGenerator,
    // AdminEditAboutComponent,
    // AdminFeedbackReviewComponent,
    // AdminEditTermComponent,
    // AdminEditPricingInfoComponent,
    // AdminEditCareerComponent,
    // AdminEditCovidComponent,
    // AdminIndexComponent,
    // AdminWorkshopPickerComponent,
    // AdminInvoiceStatusOverviewComponent,
    // AdminInvoiceStatusOverviewTableComponent,
    // AdminWorkshopPickerTableComponent,
    // AdminEditNewsComponent,
    // AdminSpacePickerComponent,
    // AdminSpacePickerTableComponent,
    // AdminEditNewsCellComponent,
    // AdminNewsEditorComponent,
    // AdminManageReviewsComponent,
    // AdminEditWsTypeTagsComponent,
    // AdminEditWsInspirationTagsComponent,
    // AdminCleanupComponent,
    // AdminEditFaqComponent,
    // AdminReviewCellComponent,
    // AdminReviewStarCellComponent,
    // AdminReviewBookingCellComponent,
    // AdminTagCellComponent,
    // AdminInspirationTagCellComponent,
    // AdminProperties,

    // PROPERTY MANAGER
    // ReferralPagePmComponent,
    // PmReferralComponent,
    // PropertyManagerBillingSettingsComponent,
    // AddWorkshopForm,
    // ReactivateAccountComponent,
    // ModifyWsBookingReviewPageComponent,
    // CancelBookingPMPageComponent,
    // CancelWSBookingPMPageComponent,
    // CouponGenerator,
    // BookingIndex,
    // CalendarPage,
    // AddPropertyForm,
    // AddSpaceForm,
    // SuccessPageAddSpace,
    // CompleteRegistrationPropertyManager,
    // MyProfilePropertyManager,
    // EditProfilePropertyManager,
    // MyProperties,
    // ModifyBookingReviewPageComponent,
    // PmReferralComponent,
    // CompleteRegistrationPropertyManager,
    // MyProfilePropertyManager,
    // EditProfilePropertyManager,
    // CouponGenerator,
    // PropertyManagerBillingSettingsComponent,
    // MyProperties,
    // BookingIndex,
    // CalendarPage,
    // PropertyListDisplay,
    // PropertySpacesCarousel,
    // PropertyWorkshopsCarousel,
    // PropertyDetails,
    // SpaceDetails,
    // DraggableCalendarView,
    // WeekCalendarView,
    // MonthCalendarView,
    // MultipleSpacesOverview,

    // WorkshopBookingRecap,
    // FavoriteSpaceButton,
    // FavoriteWorkshopButton,
    // TagSelectorComponent,
    // ModifyPaymentComponent,
    // ModifyWorkshopPaymentComponent,
    // ModifyBookingInfoModal,
    // PendingModifyRequestPageComponent,
    // PendingWsModifyRequestPageComponent,
    // RequestRejectMessageModal,
    // BookingOverviewPanelComponent,
    // WsBookingOverviewPanelComponent,
    // CancelBookingPMPageComponent,
    // CancelWSBookingPMPageComponent,
    // ModifyWsBookingReviewPageComponent,
    // ModifyBookingReviewPageComponent,
    // ReactivateAccountComponent,

    // ReferralPageEpComponent,
    // EpReferralComponent,
    // EventPlannerBillingSettingsComponent,
    CreditCardComponent,
    CreditCardModalComponent,
    InvoiceModalComponent,
    BankAccountModalComponent,
    ContactUsComponent,
    AddEntertainmentComponent,
    AddEntertainment4Component,
    AddEntertainment5Component,
    AddEntertainment6Component,
    PreviewComponent,
    SpaceListComponent,
    EbentoCart,
    CartTileComponent,
    CartPaymentComponent,
    LandingComponent,
    PaymentSuccessComponent,
    CartBookedItemComponent,
    CartCreditCardComponent,
    EnquiryBookingComponent,
    EnquirySuccessComponent,
    BookedItemComponent,
    RejectBookingModal,
    BookingDetailsModal,
    ListService,
    // ModifyCartSpace,
    // ModifyCartExperience
    // EntertainmentPropertyList
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HammerModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
    CommonModule,
    MatStepperModule,
    MatToolbarModule,
    MdbCollapseModule,
    NgbRatingModule,
    NgxStripeModule.forRoot("pk_test_hVEqd12BKviuO4Jl6ogVNLBe00tqWCmypS"),
    //NgxStripeModule.forRoot('pk_live_oUqKn4WEylRPg0tyKH2HRKo700OAQAyAc6'),
    AgmCoreModule.forRoot({
      apiKey: "=AIzaSyAFajXVlJrLnM0-H-TxPrUoRu2Asl5QZeo",
      libraries: ["places"],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    // MatSlideToggleModule,
    NgbModule,
    NgWizardModule.forRoot(ngWizardConfig),
    MdbTabsModule,
    NgxMaterialTimepickerModule,
    MatTabsModule,
    // PropertyManagerModule
    // MatExpansionModule,
  ],
  providers: [
    AuthService,
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: ɵHammerGesturesPlugin,
      multi: true,
      deps: [
        DOCUMENT,
        HAMMER_GESTURE_CONFIG,
        ɵConsole,
        [new Optional(), HAMMER_LOADER],
      ],
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig, deps: [] },
    AdminUserService,
    PropertyService,
    PaymentService,
    AdminPropertyService,
    SpaceService,
    WorkshopService,
    AdminSpaceService,
    AdminWorkshopService,
    SpaceBookingService,
    WorkshopBookingService,
    AdminBookingService,
    ReviewService,
    ManagerBookingService,
    UploadService,
    GeolocationService,
    SearchService,
    NgbActiveModal,
    EbentoModalService,
    MessagingService,
    AdminMessagingService,
    AdminDemolitionService,
    NotificationService,
    NewsService,
    AdminNewsService,
    AdminReviewService,
    ReferralService,
    LOGGING_INTERCEPTOR_PROVIDER,
    JWT_INTERCEPTOR_PROVIDER,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    SimplePagesService,
    CustomerService,
    FavoritesService,
    TranslatePipe,
    TranslateService,
    SubmissionService,
    AddEntertainmentService,
    EntertainmentService,
    ContactUsService,
    CartService,
    // { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
  ],

  bootstrap: [AppComponent],

  /* entryComponents: [
        WeekCalendarModal,
        DraggableCalendarModal,
        SearchCalendarModal,
        AddRuleModal,
        ClickablePlannerName,
        UniqueCheckbox,
        UniqueWorkshopCheckbox,
        PerfectCheckbox,
        PerfectWorkshopCheckbox,
        ClickableSpaceName,
        ClickableWorkshopName,
        AdminEditNewsCellComponent,
        BooleanCellComponent,
        AdminReviewCellComponent,
        AdminReviewStarCellComponent,
        AdminReviewBookingCellComponent,
        ConfirmationModalsComponent,
        BookerPlannerModal,
        ChangePasswordModalComponent,
        EditEmailModalComponent,
        CreditCardModalComponent,
        InvoiceModalComponent,
        BankAccountModalComponent,
        AccountDeactivationModalComponent,
        CarouselTile,
        SpaceCarouselTile,
        WorkshopCarouselTile,
        FavoriteSpaceCarouselTile,
        FavoriteWorkshopCarouselTile,
        SearchCarouselTile,
        BasicModal,
        ModifyBookingInfoModal,
        RequestRejectMessageModal,
        InvoiceStatusCheckboxComponent,
        AccountStatusCheckboxComponent,
        ClickableUserTypeComponent

    ],*/
})
export class AppModule {}
