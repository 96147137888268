import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Administrator } from "app/modules/shared/models/users/administrator";
import { AuthService } from "app/modules/shared/services/auth.service";

@Component({
    selector: "my-profile-admin",
    templateUrl: "my-profile-admin.component.html",
    styleUrls: ['../../../shared/css/profile-settings.css']
})
export class MyProfileAdmin implements OnInit {
    public user: Administrator

    constructor(public authService: AuthService, private router: Router) { }

    ngOnInit(): void {
        this.user = this.authService.user as Administrator
        if (!this.user.isActive) {
            /*
            let redirectRoute = "complete-registration"()
            this.router.navigate([redirectRoute, this.user.email]);
*/
            console.error("Admin account inactive. Contact dev team for support.");
        }
    }
}