/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input, Injector, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router"; 
import { CityTile } from 'app/modules/shared/models/search/city-tile';
import { GeolocationService } from 'app/modules/shared/services/geolocation.service';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { SearchSpaceQuery } from 'app/modules/shared/models/search/search-space-query';
import { PropertyAddress } from 'app/modules/shared/models/properties/property-address';
import { SearchService } from 'app/modules/event-planner/services/search.service';
import { IndexCarousel } from '../index-carousel.component';
import { SearchCarouselTile } from '../../carousel-tile/search-tile/search-carousel-tile.component';

@Component({
    selector: 'local-cities',
    templateUrl: '../index-carousel.component.html'
})

export class LocalCities extends IndexCarousel implements OnInit {

    @Input() color:string = '#212529';
    @Input() headerLabel = 'Event Spaces in ' + ((this.geolocation.myCity != null) ? this.geolocation.myCity.country : 'Local Area');
    protected tileComponent = SearchCarouselTile;
    domID = 'local-cities-';

    constructor(private cdr: ChangeDetectorRef, private geolocation: GeolocationService, private router: Router, public spaceService: SpaceService, public searchService: SearchService, injector: Injector) {
        super(injector);
    }


    ngOnInit() {
        if (this.geolocation.myCity == null) {
            this.geolocation.GetLocation.subscribe(
                (myCity) => {
                    this.spaceService.getLocalCitiesForCity(myCity).subscribe(
                        success => {
                            if (success) {
                                if (success != null && success.length > 0) {
                                    this.items = this.itemsFromResult(success);
                                    this.buildInjectors();
                                    this.calculateNumberOfNplets();
                                    this.cdr.detectChanges();
                                }
                            }
                        },
                        error => { this.errorMessage = error; console.error("fail. " + this.errorMessage); }
                    );
                });
        }
        else
            this.spaceService.getLocalCitiesForCity(this.geolocation.myCity).subscribe(
                success => {
                    if (success) {
                        if (success != null && success.length > 0) {
                            this.items = this.itemsFromResult(success);
                            this.buildInjectors();
                            this.calculateNumberOfNplets();
                            this.cdr.detectChanges();
                        }
                    }
                },
                error => { this.errorMessage = error; console.error("fail. " + this.errorMessage); }
            );
    }


    get apiGetter() {
        return null;        
    }

    public invokable(cityTile: CityTile) {
        var query = new SearchSpaceQuery(null, cityTile.name + ", " + cityTile.country, new PropertyAddress(cityTile.country, cityTile.name, null, null, null, null, -1, -1), undefined, [], [],[]);
        this.searchService.searchSpaceQuery = query;
        this.searchService.findSpaces().subscribe(
            success => {
                this.router.navigate(['space-search-results']);
            },
            error => { console.error(error.message) }
        );
    }

}
