import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "upload-images",
  templateUrl: "./upload-images.component.html",
  styleUrls: ["./upload-images.component.scss"],
})
export class UploadImagesComponent implements OnInit {
  @Input("max") maxImages: number;
  @Output() imagesForUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<string> = new EventEmitter<string>();

  urls = [];
  files = [];
  showFileSizeLimitWarning: boolean;
  skippedFilenames: string;

  constructor() {}

  ngOnInit() {}

  onSelect(event) {
    // this.files = [];
    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      var allGood = true;
      this.skippedFilenames = "";
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();
        // FILE SIZE LIMIT, for HTTP BODY (~1MB)
        if (event.target.files[i].size > 1048576) {
          this.showFileSizeLimitWarning = true;
          this.skippedFilenames +=
            event.target.files[i].name +
            " (" +
            Math.round(event.target.files[i].size / 1024) +
            "KB), ";
          allGood = false;
        } else {
          reader.onload = (e: any) => {
            if (this.urls.length < this.maxImages) {
              this.urls.push(e.target.result);
              // this.files.push(event.target.files[i]);
            }
          };
          reader.readAsDataURL(event.target.files[i]);
          this.files.push(event.target.files[i]);
        }
      }
      if (allGood) this.showFileSizeLimitWarning = false;
      console.log("files & urls", this.files, this.urls);
      this.imagesForUpload.emit({ files: this.files, urls: this.urls });
      //console.log("FILS: " + this.files.length + " :: URLZL:" + this.urls.length);
    }
  }

  removeImage(i) {
    this.delete.emit(this.files[i].name);
    this.urls.splice(i, 1);
    this.files.splice(i, 1);
  }
}
