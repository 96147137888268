import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { PricingRule } from "../../../../models/properties/pricing-rule";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'request-reject-message-modal',
    templateUrl: "request-reject-message-modal.component.html"
})
export class RequestRejectMessageModal implements OnInit {

    textMessage: string;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    constructor(public activeModal: NgbActiveModal
    ) { }

    Close() {
        this.passEntry.emit(this.textMessage);
        this.activeModal.close('Close click');
    }
        
    ngOnInit() {
    }
}