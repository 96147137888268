import { Component, Input } from '@angular/core';
import { NotificationType } from '../../../../helpers/enums/notificationType';
import { AuthService } from '../../services/auth.service';
import { ReviewService } from '../../services/review.service';
import { NotificationService } from '../../services/notification.service';
import { PropertyManagerReview } from '../../models/users/property-manager-review';

@Component({
    selector: 'space-review-tile',
    templateUrl: 'space-review-tile.component.html',
    styleUrls: ['./space-review-tile.component.scss']
})

export class SpaceReviewTile {

    constructor(public authService: AuthService, private reviewService: ReviewService, private notificationService: NotificationService) { }
    @Input() propertyManagerReview: PropertyManagerReview

    GetAverage(): number {
        var review = this.propertyManagerReview;
        return Math.round((review.amenitiesScore + review.cateringScore + review.locationScore + review.overallScore) / 4);
    }

    formatTimestamp(timestamp: Date): string {
        var result = new Date(timestamp).toLocaleString(undefined, { day: 'numeric', month: 'short', year: '2-digit' });
        return result;
    }

    report()
    {
        if (confirm('Are you sure you want to report this review to the Adminsitrator?'))
            this.reviewService.reportPMReview(this.propertyManagerReview.idReview).subscribe(
                (success) => {
                    if (success)
                        this.notificationService.notify(NotificationType.Success, "Review reported to Ebento Administrator. Thank you for your assistance!", "Review Reported");
                  }
            );
    }
}
