import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-admin-review-booking-cell',
  templateUrl: './admin-review-booking-cell.component.html',
  styleUrls: ['./admin-review-booking-cell.component.scss']
})
export class AdminReviewBookingCellComponent implements ICellRendererAngularComp {

    public params: any;

    constructor(private router: Router) { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return true;
    }

    openBooking()
    {
        if (this.params.data.bookingID != -1)
            this.router.navigate(['booking-details/' + this.params.data.bookingID]);
        else if(this.params.data.wsBookingID != -1)
            this.router.navigate(['experience-booking-details/' + this.params.data.wsBookingID]);
    }

    getLabel(): string
    {
        if (this.params.data.bookingID != -1)
            return 'Booking';
        else if (this.params.data.wsBookingID != -1)
            return 'WS Booking';
        return '!!';
    }
}
