import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AddEntertainmentService } from "app/modules/property-manager/services/add-entertainment.service";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";
import { EbentoUser } from "app/modules/shared/models/users/ebento-user";
import { EventPlanner } from "app/modules/shared/models/users/event-planner";
import { AuthService } from "app/modules/shared/services/auth.service";
import { MessagingService } from "app/modules/shared/services/messaging.service";
import { NotificationService } from "app/modules/shared/services/notification.service";
import { PropertyService } from "app/modules/shared/services/property.service";
import { SpaceBookingService } from "app/modules/shared/services/space-booking.service";

@Component({
  selector: "app-enquiry-booking",
  templateUrl: "./enquiry-booking.component.html",
  styleUrls: ["./enquiry-booking.component.scss"],
})
export class EnquiryBookingComponent implements OnInit {
  
  public scrHeight: number;
  public scrWidth: number;
  public email: string;
  public eventPlanner: EbentoUser;
  public propertyManager: EbentoUser;

  public AdditionalInfo: any = {
    location: "",
    descriptionOfEventspace: "",
    descriptionofEvent: "",
    SpecificRequirements: "",
  };

  constructor(
    public router: Router,
    public propertyService: PropertyService,
    public authService: AuthService,
    public bookingService: SpaceBookingService,
    public entertainmentService: AddEntertainmentService,
    public notificationService: NotificationService,
    private messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    try {
      this.getScreenSize();

      if (this.authService?.user) {
        console.log("user:", this.authService.user);
        this.email = this.authService.user.email;
      } else {
        console.error("User is not defined!");
      }

      this.eventPlanner = this.authService.user;

      // this.getPlanner();
      // console.log("Property", this.entertainmentService.entertainmentData);
      this.entertainmentService.propertyId =
        this.entertainmentService.entertainmentData.Property.IDEbentoProperty;
      this.propertyService
        .getProperty(Number(this.entertainmentService.propertyId))
        .subscribe((response) => {
          this.propertyManager = response.propertyManager;
        });
    } catch (error) {
      console.error(error);
    }
  }

  // private getPlanner() {
  //   this.authService.getPlannerInfoByID(this.authService.user.id)
  //       .subscribe(
  //           res => {
  //               this.eventPlanner = res
  //           },
  //           err => console.error(err)
  //       )
  // }

  // Here's how the improved version of the sendMessage() function looks like:
  sendMessage(
    id: string,
    spaceName: string,
    propertyName: string,
    spaceType: string
  ) {
    this.messagingService.currentDraft = new EbentoMessage();
    this.messagingService
      .sendEnquiryMessage(
        this.eventPlanner,
        this.propertyManager,
        id,
        spaceName,
        propertyName,
        spaceType
      )
      .subscribe({
        next: (response) => {
          console.log("message send:", response);
          this.router.navigate(["enquiry-booking/success"]);
        },
        error: (error) => {
          console.error("Failed to send message:", error);
          // You can show a toast message or an alert box to the user indicating message sending failure.
        },
      });
  }

  sendRequest() {
    if (!this.entertainmentService.bookingOptions) {
      console.warn("Booking options are missing. Unable to send request.");
      return;
    }
    console.log("additional infor",this.AdditionalInfo);
    
    this.entertainmentService.sendEnquiryRequest(this.AdditionalInfo,'Entertainment').subscribe(
      (res: any) => {
        console.log("res", res);
        this.sendMessage(
          res.requestId,
          this.entertainmentService.entertainmentData.Entertain_ServiceName,
          this.entertainmentService.entertainmentData.Property.Name,
          "Entertainment"
        );
      },
      (err) => {
        console.error("An error occurred while sending the request:", err);
        // Handle the error, e.g. by displaying a message to the user.
      }
    );
  }

  goBack() {
    console.log("");
    this.router.navigate([
      "entertainment-details/" + this.entertainmentService.entertainmentData.serviceId,
    ]);
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }
}
