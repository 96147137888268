import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Router } from "@angular/router";

@Component({
    selector: 'booking-name-cell',
    templateUrl: 'booking-name-cell.component.html'
})
export class BookingNameCell implements ICellRendererAngularComp {
    public params: any;

    constructor(private router: Router)
    { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    gotoModification() {        
        if (this.params.data.workshopID != null)
            this.router.navigate(['property-manager/modify-experience-booking-review/' + this.params.data.pendingModificationID]);
        else
            this.router.navigate(['property-manager/modify-booking-review/' + this.params.data.pendingModificationID]);
    }
}