import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import { SpaceBookingService } from "app/modules/shared/services/space-booking.service";
import { SpaceBookingOptions } from "app/modules/shared/models/booking/space-booking-options";
import { PaymentIntent, StripeCardElement } from "@stripe/stripe-js";
import { AuthService } from "../../shared/services/auth.service";
import { CreatePaymentIntent } from "../../shared/models/payment/create-payment-intent";
import { StripeService } from "ngx-stripe";
import { UpdatePaymentIntent } from "../../shared/models/payment/update-payment-intent";

@Injectable()
export class PaymentService {
    public publishableKey: string = "pk_test_hVEqd12BKviuO4Jl6ogVNLBe00tqWCmypS"
   // public publishableKey: string = "pk_live_oUqKn4WEylRPg0tyKH2HRKo700OAQAyAc6"
    public paymentIntentClientSecret: string;
    public paymentIntentID: string;
    public savedPaymentMethods;

    constructor(
        private http: HttpClient,
        private router: Router,
        public bookingService: SpaceBookingService, public stripeService: StripeService
    ) { }

    public getCards() {
        return this.http.get<any>(
            "api/get-cards")
            .pipe(
                map(data => {
                    console.log(data);
                    this.savedPaymentMethods = data;
                    return data;
                }),
                catchError(this.errorHandler)
            );
    }

    public createPaymentIntent(createIntentOptions: CreatePaymentIntent): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<string>(
            "api/create-payment-intent",
            JSON.stringify(createIntentOptions),
            httpOptions)
            .pipe(
                map(data => {
                    this.paymentIntentClientSecret = data;
                    this.paymentIntentID = data.split('_secret')[0];
                    console.log("$$");
                    console.log(this.paymentIntentClientSecret); 
                    console.log(this.paymentIntentID);
                    return data;
                }),
                catchError(this.errorHandler)
            );

    }

    public createPaymentIntentSpaceBookModification(createIntentOptions: CreatePaymentIntent, mBookReqId: number): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<string>(
            "api/create-payment-intent-mod/" + mBookReqId,
            JSON.stringify(createIntentOptions),
            httpOptions)
            .pipe(
                map(data => {
                    this.paymentIntentClientSecret = data;
                    this.paymentIntentID = data.split('_secret')[0];
                    console.log("$$");
                    console.log(this.paymentIntentClientSecret);
                    console.log(this.paymentIntentID);
                    return data;
                }),
                catchError(this.errorHandler)
            );

    }


    public createPaymentIntentWorkshopBookModification(createIntentOptions: CreatePaymentIntent, mBookReqId: number): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<string>(
            "api/create-payment-intent-ws-mod/" + mBookReqId,
            JSON.stringify(createIntentOptions),
            httpOptions)
            .pipe(
                map(data => {
                    this.paymentIntentClientSecret = data;
                    this.paymentIntentID = data.split('_secret')[0];
                    console.log("$$");
                    console.log(this.paymentIntentClientSecret);
                    console.log(this.paymentIntentID);
                    return data;
                }),
                catchError(this.errorHandler)
            );

    }


    public updatePaymentIntent(updateIntentOptions: UpdatePaymentIntent): Observable<string> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<string>(
            "api/update-payment-intent",
            JSON.stringify(updateIntentOptions),
            httpOptions)
            .pipe(
                map(data => {
                    this.paymentIntentClientSecret = data;
                    this.paymentIntentID = data.split('_secret')[0];
                    console.log("$$");
                    console.log(this.paymentIntentClientSecret);
                    console.log(this.paymentIntentID);
                    return data;
                }),
                catchError(this.errorHandler)
            );

    }


    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    takePayment(cardElement: StripeCardElement): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.stripeService
            .confirmCardPayment(this.paymentIntentClientSecret,
                {
                payment_method: {
                    card: cardElement,
                },
            })
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    takePaymentSavedCard(paymentMethod): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.stripeService
            .confirmCardPayment(this.paymentIntentClientSecret,
                {
                    payment_method :paymentMethod.id
                })
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }
}