import { Component, OnInit } from '@angular/core';
import { EbentoNews } from '../../../../models/misc/ebento-news';
import { NewsService } from '../../../../services/news.service';


@Component({
    selector: 'newsfeed-page',
    templateUrl: "newsfeed-page.component.html",
    styleUrls: ['./newsfeed-page.component.css']
})
export class NewsfeedPage implements OnInit
{
    public news: EbentoNews[]

    constructor(private newsService: NewsService)
    { }
    
    ngOnInit(): void 
        {
            this.getNews();
        }

    getNews()
    {
        this.newsService.getNews().subscribe(
            success => {
                if (success) {
                    this.news = success;
                }
            },
            error => { console.error("news error. " + error); }
        );
    }
}

