import { Component, Input } from '@angular/core';
import { PropertyManagerReview } from '../../models/users/property-manager-review';

@Component({
    selector: 'space-review-overall',
    templateUrl: 'space-review-overall.component.html'
})

export class SpaceReviewOverall {
    @Input() reviews: PropertyManagerReview[]
    starNumbers = [1, 2, 3, 4, 5]

    constructor() { }

    GetAverage(): number {
        var average = 0;
        if (this.reviews != null) {
            for (let review of this.reviews) {
                average += review.amenitiesScore;
                average += review.cateringScore;
                average += review.locationScore;
                average += review.overallScore;
            }
            average /= (this.reviews.length * 4);
        }
        return Math.round(average * 100) / 100;
    }

    GetStarCount(starNumber: number): number {
        var count = 0;
        if (this.reviews != null)
            for (let review of this.reviews) {
                if (review.amenitiesScore == starNumber)
                    count++;
                if (review.cateringScore == starNumber)
                    count++;
                if (review.locationScore == starNumber)
                    count++;
                if (review.overallScore == starNumber)
                    count++;
            }
        return count;
    }

    GetStarPercentage(starNumber: number): number {
        if (this.reviews == null)
            return 100;
        return this.GetStarCount(starNumber) / (this.reviews.length * 4) * 100;
    }
}
