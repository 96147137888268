import { Component, OnInit } from '@angular/core';
import { AdminUserService } from '../../../services/admin-user.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-account-status-checkbox',
  templateUrl: './account-status-checkbox.component.html',
  styleUrls: ['./account-status-checkbox.component.scss']
})
export class AccountStatusCheckboxComponent implements ICellRendererAngularComp {
  public params: any;
  public submitting: boolean;

  constructor(public adminUserService: AdminUserService)
  { }

  agInit(params: any): void {
      this.params = params;
  }

  refresh(): boolean {
      return false;
  }

  statusChanged(isChecked: boolean)
  {
      this.submitting = true;
      this.adminUserService.changeAccountStatus(this.params.data.id, isChecked).subscribe(
          (success) => {
              this.submitting = false;
              if (success)
              {

              }
              else
              {
                  console.error("api error");
                  this.params.data.isActive = !isChecked;
              }   
          }
      );
  }
}