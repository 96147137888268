import { Component, OnInit } from "@angular/core";
import { EditCancellationRule } from "../../../../shared/models/entertainment/AddEntertainmentStep1";
import { AddEntertainmentService } from "../../../services/add-entertainment.service";
import { Router } from "@angular/router";
import { SubmissionService } from "app/modules/shared/services/submission.service";


@Component({
  selector: "add-entertainment-6",
  templateUrl: "./add-entertainment-6.component.html",
  styleUrls: ["./add-entertainment-6.component.css"],
})
export class AddEntertainment6Component implements OnInit {
  constructor(
    private addEntertainmentService: AddEntertainmentService,
    private router: Router,
    private submissionService:SubmissionService
  ) {}

  public errors: string = "";
  ngOnInit(): void {
    this.preloadValues();
  }

  preloadValues() {
    if (
      this.addEntertainmentService.entertainmentData.ecancellationRules.length >
      0
    ) {
      this.cancellationRules =
        this.addEntertainmentService.entertainmentData.ecancellationRules;
    }
  }

  public cancellationRules: EditCancellationRule[] = [
    {
      daysFrom: 2,
      daysTo: 0,
      percentageToReturn: 20,
    },
  ];

  addCancellationRule() {
    if(this.cancellationRules.length){
      const length = this.cancellationRules.length;
      const last = this.cancellationRules[length - 1];
      this.cancellationRules[length] = {
        daysFrom: last.daysFrom + 5,
        daysTo: last.daysFrom,
        percentageToReturn: last.percentageToReturn + 5,
      };
    }else{
      this.cancellationRules[0] = {
        daysFrom: 2,
        daysTo: 0,
        percentageToReturn: 20,
      }
    }
    console.log("cancelling rules", this.cancellationRules);
  }

  removeCancellationRule(i: number) {
    this.cancellationRules = this.cancellationRules.filter((e, index) => {
      return index !== i;
    });
  }

  changeDaysFrom(e: any, i: number) {
    this.cancellationRules[i].daysFrom = Number(e.target.value);
  }

  changeDaysTo(e: any, i: number) {
    this.cancellationRules[i].daysTo = Number(e.target.value);
  }

  changePercentageToReturn(e: any, i: number) {
    this.cancellationRules[i].percentageToReturn = Number(e.target.value);
  }

  checkCancellationRules(){
    let valid = true;
    if(!this.cancellationRules.length){
        valid = false;
    }else{
        for(let rule of this.cancellationRules){
            if(!rule.daysFrom && !rule.daysTo && !rule.percentageToReturn){
                valid = false;
                break;
            }else{
                if(rule.daysFrom < rule.daysTo || rule.daysFrom === rule.daysTo ){
                    valid = false;
                    break;
                }else{
                    continue;
                }
            }
        }
    }
    return valid;
  }

  submitData() {
    if(this.checkCancellationRules){
        this.submissionService.startSubmission();
        if (this.addEntertainmentService.editMode) {
          this.addEntertainmentService.updateCancellationRules(
            this.cancellationRules
          );
          this.addEntertainmentService
            .updateEntertainment()
            .subscribe((response) => {
              this.submissionService.endSubmission();
              this.addEntertainmentService.editMode = false;
              this.addEntertainmentService.custom = { type: "" };
              this.router.navigate([
                `/add-entertainment-preview/${this.addEntertainmentService.serviceId}`,
              ]);
            });
        } else {
          this.addEntertainmentService.setCancellationRules(this.cancellationRules);
          this.addEntertainmentService.addRules().subscribe((response: number) => {
            this.submissionService.endSubmission();
            this.addEntertainmentService.custom = { type: "" };
            this.router.navigate([
              `/add-entertainment-preview/${this.addEntertainmentService.serviceId}`,
            ]);
          });
        }
    }else{
        this.errors = `Please add cancellation rules correctly
            days from should be grater than days to and please add the percentage to return
        `
    }
  }
}
