import { PaymentType } from "./payment-type";


export class PayModify {

    public paymentType: PaymentType;
    public price: number;
    public modificationID: number;
    
    constructor() { }
}