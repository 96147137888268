import { HttpClient, HttpErrorResponse} from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';

import { Injectable } from "@angular/core";
import { map, catchError, switchMap } from "rxjs/operators";

import { Observable } from "rxjs";
import { throwError } from 'rxjs';
import { AdminWorkshopListingItem } from "../models/admin-workshop-listing-item";
import { AdminWorkshopFilter } from "../models/admin-workshop-filter";
import { WorkshopTypeTag } from "app/modules/shared/models/tags/workshop-type-tag";
import { WorkshopInspirationTag } from "app/modules/shared/models/tags/workshop-inspiration-tag";
import { ListingActivePeriod } from "../../shared/models/billing/listing-active-period";


@Injectable()
export class AdminWorkshopService {

    public workshops: AdminWorkshopListingItem[]
    public workshopInspirationTags: WorkshopInspirationTag[]
    public workshopTypeTags: WorkshopTypeTag[]
    public locations: string[]
    public emails: string[]
    
    constructor(
        private http: HttpClient) {
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    public getAllWorkshops(): Observable<any> {
        return this.http.get<any>("api/adminAllWorkshops")
            .pipe(
                map(result => this.workshops = result),
                catchError(this.errorHandler)
            );
    }

    public getWorkshopActivity(workshopID): Observable<ListingActivePeriod[]> {
        return this.http.get<ListingActivePeriod[]>("api/getWorkshopLAPs/" + workshopID)
            .pipe(
                map(result => { console.log(result); return result; }),
                catchError(this.errorHandler)
            );
    }

    public getLocations(): Observable<string[]> {
        return this.http.get<string[]>("api/adminAllLocationsWorkshops")
            .pipe(
                map(result => this.locations = result),
                catchError(this.errorHandler)
                );
    }

    public getEmails(): Observable<string[]> {
        return this.http.get<string[]>("api/adminAllEmailsWorkshops")
            .pipe(
                map(result => this.emails = result),
                catchError(this.errorHandler)
            );
    }

    public getFilteredWorkshops(filter: AdminWorkshopFilter): Observable<AdminWorkshopListingItem[]> {
        return this.http.post<AdminWorkshopListingItem[]>("api/adminFilteredWorkshops",filter)
            .pipe(
                map(result => this.workshops = result),
                catchError(this.errorHandler)
            );
    }

    setWorkshopUnique(id: number, state: boolean): Observable<boolean> {
        return this.http.post<boolean>("api/setWorkshopUnique/" + id + "/" + state, null)
            .pipe(
                map(result => {
                    return result;
                },
                    catchError(this.errorHandler)
                ));
    }

    setWorkshopPerfect(id: number, state: boolean): Observable<boolean> {
        return this.http.post<boolean>("api/setWorkshopPerfect/" + id + "/" + state, null)
            .pipe(
                map(result => {
                    return result;
                },
                    catchError(this.errorHandler)
                ));
    }

    setWorkshopTypeTagPublic(tagId: number, isPublic: boolean): Observable<boolean> {
        return this.http.post<boolean>("api/setWsTypeTagPublic/" + tagId + "/" + isPublic, null)
            .pipe(
                map(result => {
                    return result;
                },
                    catchError(this.errorHandler)
                ));
    }

    setWorkshopTypeTagChildren(tagId: number, childTags: WorkshopTypeTag[]): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<boolean>("api/setWsTypeTagChildren/" + tagId, childTags, httpOptions)
            .pipe(
                map(result => {
                    return result;
                },
                    catchError(this.errorHandler)
                ));
    }


    public getAllTypeTags(): Observable<WorkshopTypeTag[]> {
        return this.http.get<WorkshopTypeTag[]>("api/AdminWorkshopTypeTags")
            .pipe(
                map(result => this.workshopTypeTags = result),
                catchError(this.errorHandler)
            );
    }

    setWorkshopInspirationTagPublic(tagId: number, isPublic: boolean): Observable<boolean> {
        return this.http.post<boolean>("api/setWsInspirationTagPublic/" + tagId + "/" + isPublic, null)
            .pipe(
                map(result => {
                    return result;
                },
                    catchError(this.errorHandler)
                ));
    }


    public getAllInspirationTags(): Observable<WorkshopInspirationTag[]> {
        return this.http.get<WorkshopInspirationTag[]>("api/AdminWorkshopInspirationTags")
            .pipe(
                map(result => this.workshopInspirationTags = result),
                catchError(this.errorHandler)
            );
    }    

    public addInspirationTag(tagName: string): Observable<number> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        var dto = new WorkshopInspirationTag();
        dto.name = tagName;
        dto.isPublic = true;
        dto.id = -1;

        return this.http.post<number>("api/AdminAddWorkshopInspirationTag", JSON.stringify(dto), httpOptions)
            .pipe(
                map(result => { return result; }),
                    catchError(this.errorHandler)
                );
    }
}