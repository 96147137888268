import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
import { EbentoNews } from "app/modules/shared/models/misc/ebento-news";
import { UploadService } from "app/modules/shared/services/upload.service";

@Injectable()
export class AdminNewsService {

    public news: EbentoNews[] = [];
    public openNews: EbentoNews;

    constructor(private http: HttpClient, private uploadService: UploadService) {
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    public getNewsByID(id: number): Observable<EbentoNews> {

        return this.http.get<EbentoNews>(
            "api/getnews/" + id)
            .pipe(
                map(success => {
                    return success;
                }),
                catchError(this.errorHandler)
            );
    }

    public getAllNews(): Observable<EbentoNews[]> {

        return this.http.get<EbentoNews[]>(
            "api/getnewsadmin")
            .pipe(
                map(success => {
                    this.news = success;
                    return success;
                }),
                catchError(this.errorHandler)
            );
    }

    public addNews(news: EbentoNews, hasImages: boolean): Observable<number> {
        if (hasImages) {
            return this.uploadService.uploadImages(news.images)
                .pipe(
                    switchMap(
                        (res: string[]) => {
                            news.photourls = res;
                            return this.addNewsWithoutUpload(news);
                        }
                    )
                )

        }
        else {
            return this.addNewsWithoutUpload(news);
        }
    }

    private addNewsWithoutUpload(news: EbentoNews): Observable<number> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };

        return this.http.post<number>("api/addnews", JSON.stringify(news), httpOptions)
            .pipe(
                map(res => {
                    return res;
                }
                )
            )

    }


    public editNews(news: EbentoNews, hasImages: boolean): Observable<boolean> {
        if (hasImages) {
            return this.uploadService.uploadImages(news.images)
                .pipe(
                    switchMap(
                    (res: string[]) => {
                        for (let url of res)
                            news.photourls.push(url);
                            return this.editNewsWithoutUpload(news);
                        }
                    )
                )

        }
        else {
            return this.editNewsWithoutUpload(news);
        }
    }

    public  editNewsWithoutUpload(news: EbentoNews): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };

        return this.http.post<boolean>("api/editnews", JSON.stringify(news), httpOptions)
            .pipe(
                map(res => {
                    return res;
                }
                )
            )

    }

    public  setPublished(id: number, publish: boolean): Observable<boolean>
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };

        return this.http.post<boolean>("api/publishnews/"+id+"/"+publish, null, httpOptions)
            .pipe(
                map(res => {
                    return res;
                }
                )
            )
        
    }

    public delete(id: number): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };

        return this.http.delete<boolean>("api/deletenews/" + id, httpOptions)
            .pipe(
                map(res => {
                    return res;
                }
                )
            )

    }
}