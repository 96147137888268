/// <reference types="@types/googlemaps" />

import { Component, ViewChild, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SpaceForTile } from "../../models/search/space-for-tile";
import { PropertyManagerReview } from "../../models/users/property-manager-review";
import { PropertyService } from "../../services/property.service";
import { ReviewService } from "../../services/review.service";
import { SpaceService } from "../../services/space.service";
import { AuthService, LoginRedirectType } from "../../services/auth.service";
import { PropertyAddress } from "../../models/properties/property-address";
import { Amenity } from "../../models/properties/Amenity";
import { NgbRatingConfig, NgbRatingModule } from "@ng-bootstrap/ng-bootstrap";
import { EbentoSpace } from "../../models/properties/ebento-space";

@Component({
  selector: "space-details",
  templateUrl: "space-details.component.html",
  styleUrls: ["./space-details.component.css"],
})
export class SpaceDetails implements OnInit, AfterViewInit {
  
  spaceForTile: SpaceForTile;
  reviews: PropertyManagerReview[];
  modalRef: NgbModalRef;

  @ViewChild("gmap") gmapElement: any;

  public map: google.maps.Map;

  public spacephotourl: string;

  amenityQuantity: Object;

  public previewMode: boolean;

  constructor(
    public propertyService: PropertyService,
    private reviewService: ReviewService,
    public spaceService: SpaceService,
    public authService: AuthService,
    public router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    config: NgbRatingConfig
  ) {
    this.amenityQuantity = {};
    config.max = 5;
  }

  ngOnInit() {
    let spaceIdParam = this.activatedRoute.snapshot.params.id;

    if (spaceIdParam) {
      this.spaceService.getSpaceTile(spaceIdParam).subscribe((success) => {
        console.log("suceess", success);
        this.spaceForTile = success;
        if (
          this.spaceForTile != null &&
          this.spaceForTile.space.photoUrls != null &&
          this.spaceForTile.space.photoUrls.length > 0
        ) {
          this.spacephotourl = this.spaceForTile.space.photoUrls[0];
        }
        // this.SetMap();
      });

      this.reviewService
        .getReviewsForSpace(spaceIdParam)
        .subscribe((reviews) => {
          this.reviews = reviews;
        });
    }

    window.scrollTo(0, 0);
  }

  publishSpace(evt: Event, space: EbentoSpace) {
    evt.stopImmediatePropagation();
    evt.preventDefault();
    var newValue = !space.isListingActive;
    if(newValue) {
        this.spaceService
          .setSpaceActive(space.spaceId, newValue)
          .subscribe((res) => {
            space.isListingActive = newValue;
        });
    }
    this.router.navigate(['property-manager/properties']); 
  }

  setBigImage(i: number) {
    this.spaceForTile.spacephotourl = this.spaceForTile.space.photoUrls[i];
  }

  ngAfterViewInit() {
    this.SetMap();
  }

  checkIsOwner(): boolean {
    return (
      this.authService.isLoggedIn() &&
      this.spaceForTile.space.propertyManager.id == this.authService.user.id
    );
  }

  checkIsAdmin(): boolean {
    return (
      this.authService.isLoggedIn() &&
      this.authService.userType == "Administrator"
    );
  }

  editSpace() {
    this.spaceService.spaceToEdit = this.spaceForTile.space;
    this.router.navigate(["/add-space"]);
  }

  editAvailability(){
    this.spaceService.spaceToEdit = this.spaceForTile.space;
    this.spaceService.selectedIndex = 3;
    this.router.navigate(["/add-space"]);
  }

  addWorkshop() {
    this.router.navigate([
      "/add-experience/" + this.spaceForTile.space.spaceId,
    ]);
  }

  gotoLoginSpaceBook() {
    this.authService.setLoginRedirectParams(
      LoginRedirectType.SpaceListing,
      "" + this.spaceForTile.space.spaceId
    );
    this.router.navigate(["login"]);
  }

  gotoLoginMessage() {
    this.authService.setLoginRedirectParams(
      LoginRedirectType.Message,
      "" + this.spaceForTile.space.propertyManager.id
    );
    this.router.navigate(["login"]);
  }

  SetMap() {
    var mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    var map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.map = map;
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        this.spaceForTile.address.googlemapcoordx,
        this.spaceForTile.address.googlemapcoordy
      ),
      map: this.map,
    });

    var bounds = new google.maps.LatLngBounds();
    bounds.extend(marker.getPosition());

    this.map.fitBounds(bounds);
    var listener = google.maps.event.addListener(map, "idle", function () {
      if (map.getZoom() > 16) map.setZoom(16);
      google.maps.event.removeListener(listener);
    });
  }

  public AddressText(address: PropertyAddress) {
    var result: string = "";
    if (address.streetaddress != null && address.streetaddress != "")
      result += address.streetaddress;
    if (address.streetnumber != null && address.streetnumber != "")
      result += " " + address.streetnumber;
    if (address.city != null && address.city != "") {
      if (result != "") result += ", ";
      if (address.postalcode != null && address.postalcode != "")
        result += address.postalcode + " ";
      result += address.city;
    }
    if (address.country != null && address.country != "") {
      if (result != "") result += ", ";
      result += address.country;
    }
    return result;
  }

  public OptionalAmenities(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.spaceForTile.space.amenities != null)
      for (var a of this.spaceForTile.space.amenities) {
        if (a.isoptional) result.push(a);
      }
    return result;
  }

  public Catering(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.spaceForTile.space.amenities != null)
      for (var a of this.spaceForTile.space.amenities) {
        if (a.amenitytype.isCatering) result.push(a);
      }
    return result;
  }

  public FixedAmenities(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.spaceForTile.space.amenities != null)
      for (var a of this.spaceForTile.space.amenities) {
        if (!a.isoptional && !a.amenitytype.isCatering) result.push(a);
      }
    return result;
  }

  //TODO: move to service
  downloadFile(amenity: Amenity) {
    return this.http
      .get(window.location.origin + "/" + amenity.documenturl, {
        responseType: "blob",
      })
      .subscribe(
        (res) => {
          //console.log('start download:', res);
          var url = window.URL.createObjectURL(res);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download =
            this.spaceForTile.space.name +
            "_" +
            amenity.amenitytype.name +
            "_details";
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        },
        (error) => {
          console.error("download error:", JSON.stringify(error));
        },
        () => {
          //console.log('Completed file download.')
        }
      );
  }
}
