import { Component } from '@angular/core';
import { AddEntertainmentService } from '../../../property-manager/services/add-entertainment.service';
import { Entertainment } from '../../../shared/models/entertainment/AddEntertainmentStep1';
import { Router } from '@angular/router'

@Component({
    selector: 'entertainment-search-result',
    templateUrl: 'entertainment-search-result.component.html',
    styleUrls: ['./entertainment-search-result.component.scss']
})

export class EntertainmentSearchResult{

    
    public data:Entertainment[]=[];
    constructor(
        public entertainmentService:AddEntertainmentService,
        private router:Router
        ){
        this.data = this.entertainmentService.searchResult
    }

    Redirect(id:number){
        this.router.navigate(['entertainment-details/' + id]);
    }

}