import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AddEntertainmentService } from "app/modules/property-manager/services/add-entertainment.service";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";
import { MessagingService } from "app/modules/shared/services/messaging.service";
import { MessageService } from "ngx-text-editor/app/ngx-editor/common/services/message.service";

@Component({
  selector: "accept-booking-modal",
  templateUrl: "accept-booking-modal.component.html",
  styleUrls: ["accept-booking-modal.component.css"],
})
export class AcceptBookingModalComponent {

  @Input() id: number;
  @Input() spaceName: string;
  @Input() propertyName: string;
  @Input() message: EbentoMessage;
  @Input() closeCallback: () => void;
  @Input() spaceType:string;

  constructor(
    public activeModal: NgbActiveModal,
    private entertainmentService: AddEntertainmentService,
    private messagingService: MessagingService
  ) {
    console.log("message ebenot accept booking modal",this.message,this.spaceName,this.propertyName);

  }


  

  close() {
    this.activeModal.close("Close click");
  }

  accept() {
    console.log("message ebenot accept booking modal",this.message,this.spaceName,this.propertyName);
    this.entertainmentService.acceptBooking(this.id).subscribe((response) => {
      
      this.messagingService.currentDraft = new EbentoMessage();
      this.messagingService
        .sendEnquiryAcceptedMessage(
          this.message.receiver,
          this.message.sender,
          this.id,
          this.spaceName,
          this.propertyName,
          this.spaceType
        )
        .subscribe((res) => {
          console.log("res", res);
        });
        if(response){
          console.log("reaching here=---");
          
          this.activeModal.close("Close click");
          this.closeCallback();

        }
    });
  }
}
