import { BookingSegment } from "../booking/booking-segment";
import { TimeSpan } from "./time-span";

export class TimeAllocation
{
    public id: number
    public startTime: string
    public endTime: string
    public allocationType: number

    public static FromBooking(booking: BookingSegment): TimeAllocation
    {
        var allocation = new TimeAllocation();
        allocation.startTime = booking.startTime.toLocaleString('en-US');
        var endTime: Date;
        endTime = new Date(booking.startTime);
        var hours = booking.startTime.getHours() + booking.duration.Hours;
        var minutes = booking.startTime.getMinutes() + booking.duration.Minutes;
        while (minutes >= 60)
        {
            minutes -= 60;
            hours++;
        }
        endTime.setHours(hours);
        endTime.setMinutes(minutes);
        
        allocation.endTime = endTime.toLocaleString('en-US');
        allocation.allocationType = 0;
        allocation.id = -1;
        return allocation;
    }

    public static GetDateString(allocation: TimeAllocation): string {        
        return new Date(allocation.startTime).toLocaleDateString();

    }
    public static GetTimeString(allocation: TimeAllocation): string {
        var start = new Date(allocation.startTime);
        var end = new Date(allocation.endTime);        
        var sT = new TimeSpan(0, start.getHours(), start.getMinutes(), 0);
        var eT = new TimeSpan(0, end.getHours(), end.getMinutes(), 0);
        return sT.HoursMinutesString + " - " + eT.HoursMinutesString;
    }
}