/// <reference types="@types/googlemaps" />

import { Component, ViewChild, OnInit, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { EbentoProperty } from "app/modules/shared/models/properties/ebento-property";
import { AuthService } from "app/modules/shared/services/auth.service";
import { PropertyService } from "app/modules/shared/services/property.service";
import { PropertyAddress } from "app/modules/shared/models/properties/property-address";
import { UploadService } from "../../../shared/services/upload.service";

@Component({
  selector: "add-property",
  templateUrl: "add-property.component.html",
  styleUrls: ["./add-property.component.css"],
})
export class AddPropertyForm implements OnInit, AfterViewInit {
  myProperty: EbentoProperty;
  editDetailsEnabled = false;
  formPageIndex: number = 0;
  response = "Waiting";
  errorMessage: string;
  marker;

  editMode: boolean = false;
  constructor(
    public authService: AuthService,
    private propertyService: PropertyService,
    private router: Router,
    private uploadService: UploadService
  ) {}

  ngOnInit() {
    this.myProperty = new EbentoProperty();
    this.address = new PropertyAddress(
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      0
    );
    if (this.propertyService.propertyToEdit != null) {
      this.editMode = true;
    }
  }

  ngAfterViewInit() {
    var lat = 47.3769;
    var lon = 8.5417;
    if (this.propertyService.propertyToEdit != null) {
      lat = this.propertyService.propertyToEdit.address.googlemapcoordx;
      lon = this.propertyService.propertyToEdit.address.googlemapcoordy;
    }
    var mapProp = {
      center: new google.maps.LatLng(lat, lon),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    AddPropertyForm.map = new google.maps.Map(
      this.gmapElement.nativeElement,
      mapProp
    );
    AddPropertyForm.autocomplete = new google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */ <HTMLInputElement>(
        document.getElementById("autocomplete")
      ),
      { types: ["geocode"] }
    );

    // When the user selects an address from the dropdown, populate the address
    // fields in the form.
    AddPropertyForm.autocomplete.addListener("place_changed", () =>
      this.fillInAddress()
    );

    AddPropertyForm.static_pinplaced = false;
    if (this.propertyService.propertyToEdit != null) {
      this.preloadValues();
    }
  }

  onImageUploaded(file: File) {
    let formData = new FormData();
    formData.append(file.name, file);

    this.myProperty.coverImage = formData;
  }

  onImageDeleted(imageUrl: string) {
    // confirm dialog
    this.uploadService.deleteImage(imageUrl).subscribe((res) => {});
  }

  ignore() {}

  preloadValues() {
    this.myProperty = this.propertyService.propertyToEdit;
    console.log("property value preloaded",this.myProperty);
    
    this.address = this.myProperty.address;
    this.editDetailsEnabled = true;
    this.marker = new google.maps.Marker({
      position: {
        lat: this.address.googlemapcoordx,
        lng: this.address.googlemapcoordy,
      },
      draggable: true,
      map: AddPropertyForm.map,
    });
    google.maps.event.addListener(this.marker, "dragend", (evt: any) => {
      this.address.googlemapcoordx = evt.latLng.lat();
      this.address.googlemapcoordy = evt.latLng.lng();
      //console.log("moved to " + this.address.googlemapcoordx + ": " + this.address.googlemapcoordy);
    });
  }

  onSubmit(isValid: boolean) {
    if (!isValid) {
      return;
    }

    this.myProperty.address = this.address;
    //this.myProperty.address.streetaddress += " " + this.myProperty.address.streetnumber;

    if (!this.editMode) {
      this.propertyService.addProperty(this.myProperty).subscribe(
        (newId) => {
          //console.log('property created id: ', newId);
          if (this.authService.userType == "Administrator")
            this.router.navigate(["/admin-properties"]);
          else this.router.navigate(["/property-manager/properties"]);
        },
        (error) => (this.errorMessage = error)
      );
    } else {
      this.propertyService.editProperty(this.myProperty).subscribe(
        (newId) => {
          if (this.authService.userType == "Administrator")
            this.router.navigate([
              "/admin-properties/" + this.myProperty.propertyManager.id,
            ]);
          else this.router.navigate(["/property-manager/properties"]);
        },
        (error) => (this.errorMessage = error)
      );
    }
  }

  //TODO: Create separate component for map?

  //map part
  static static_pinplaced = false;
  static placeSearch: any;
  static autocomplete: any;

  static componentForm = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    postal_town: "long_name",
    administrative_area_level_1: "short_name",
    country: "long_name",
    postal_code: "short_name",
  };

  static propertyMapping = {
    street_number: "streetnumber",
    route: "streetaddress",
    locality: "city",
    postal_town: "city",
    administrative_area_level_1: "",
    country: "country",
    postal_code: "postalcode",
  };

  static map: google.maps.Map;
  address: PropertyAddress;
  static static_address: PropertyAddress;

  @ViewChild("gmap") gmapElement: any;

  public get pinplaced() {
    return AddPropertyForm.static_pinplaced;
  }

  public fillInAddress() {
    // Get the place details from the autocomplete object.
    var place = AddPropertyForm.autocomplete.getPlace();
    AddPropertyForm.map.panTo(place.geometry.location);
    if (this.marker != null) this.marker.setMap(null);

    this.marker = new google.maps.Marker({
      position: place.geometry.location,
      draggable: true,
      map: AddPropertyForm.map,
    });
    var compound_code = "";
    var gcd = new google.maps.Geocoder();

    gcd.geocode({ location: place.geometry.location }, (results, status) => {
      {
        {
          AddPropertyForm.static_pinplaced = true;
          google.maps.event.addListener(this.marker, "dragend", (evt: any) => {
            this.address.googlemapcoordx = evt.latLng.lat();
            this.address.googlemapcoordy = evt.latLng.lng();
            //console.log("moved to " + this.address.googlemapcoordx + ": " + this.address.googlemapcoordy);
          });
          this.editDetailsEnabled = true;
          this.marker.setVisible(true);

          this.address.addressline2 = null;
          this.address.city = null;
          this.address.country = null;
          this.address.postalcode = null;
          this.address.streetaddress = null;
          this.address.streetnumber = null;
          this.address.googlemapcoordx = place.geometry.location.lat();
          this.address.googlemapcoordy = place.geometry.location.lng();

          // Get each component of the address from the place details
          // and fill the corresponding field on the form.
          for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (AddPropertyForm.componentForm[addressType]) {
              var val =
                place.address_components[i][
                  AddPropertyForm.componentForm[addressType]
                ];
              if (AddPropertyForm.propertyMapping[addressType]) {
                this.address[AddPropertyForm.propertyMapping[addressType]] =
                  val;
              }
            }
          }
        }
      }
    });
  }

  // Bias the autocomplete object to the user's geographical location,
  // as supplied by the browser's 'navigator.geolocation' object.
  public geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        var circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        AddPropertyForm.autocomplete.setBounds(circle.getBounds());
      });
    }
  }

  clickContinue(isValid: boolean) {
    console.log(isValid, "validate");
    console.log(this.myProperty, "validate");

    if (isValid) {
      this.formPageIndex++;
    }
  }

  clickBack() {
    if (this.formPageIndex > 0) {
      this.formPageIndex--;
    }
  }
}
