import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "app/modules/shared/services/auth.service";

@Component({
    selector: "register",
    templateUrl: "confirm-registration.component.html",
    styleUrls: ['../../../../shared/css/user.css']
})
export class ConfirmRegistration implements OnInit {

    email: string;
    token: string;
    type: string;
    errorMessage: string = null

    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit(): void {
        
        this.activatedRoute.params.subscribe(params => {
            this.email = params['email'];
            this.token = params['token'];
            this.type = params['type'];
            var registrationConfirmationDTO = {
                Email: this.email,
                Token: this.token
            }
            this.authService.confirmRegistration(registrationConfirmationDTO)
                .subscribe(
                    (response: any) => {
                        console.log(this.type.toLowerCase()+ " <<<<");
                        var typeRoute = this.type.toLowerCase();
                        if (this.type.toLowerCase() == "eventplanner")
                            typeRoute = "event-planner";
                        else if (this.type.toLowerCase() == "propertymanager")
                            typeRoute = "property-manager";

                        let redirectRoute = typeRoute + "/complete-registration";
                    this.router.navigate([redirectRoute]);
                    },
                    err => console.error(err.message)
                );


        })
    }

}