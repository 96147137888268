import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { map, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class AdminDemolitionService {
    
    constructor(
        private http: HttpClient
    )
    { }

    public removeBooking(bookingID: string): Observable<boolean>
    {
        return this.http.post("/api/demolition/booking/" + bookingID,null)
            .pipe(
            map(
                (response: boolean) => {
                    console.log("Space Booking (ID " + bookingID + ") Removed");
                    return response;
                    },
                err => {
                    console.error(err.message)
                }
                )
            )
    }

    public removeSpace(spaceID: string): Observable<boolean> {
        return this.http.post("/api/demolition/space/" + spaceID, null)
            .pipe(
                map(
                    (response: boolean) => {
                        console.log("Space (ID " + spaceID + ") Removed");
                        return response;
                    },
                    err => {
                        console.error(err.message)
                    }
                )
            )
    }

    public removeWorkshopBooking(workshopBookingID: string): Observable<boolean> {
        return this.http.post("/api/demolition/wsbooking/" + workshopBookingID, null)
            .pipe(
                map(
                    (response: boolean) => {
                        console.log("Workshop Booking (ID " + workshopBookingID + ") Removed");
                        return response;
                    },
                    err => {
                        console.error(err.message)
                    }
                )
            )
    }

    public removeCorruptBookings(): Observable<boolean> {
        return this.http.post("/api/demolition/bookingscleanup", null)
            .pipe(
                map(
                    (response: boolean) => {
                        console.log("All Corrupt Bookings Removed");
                        return response;
                    },
                    err => {
                        console.error(err.message)
                    }
                )
            )
    }

    public removeWorkshop(workshopID: string): Observable<boolean> {
        return this.http.post("/api/demolition/workshop/" + workshopID, null)
            .pipe(
                map(
                    (response: boolean) => {
                        console.log("Workshop (ID " + workshopID + ") Removed");
                        return response;
                    },
                    err => {
                        console.error(err.message)
                    }
                )
            )
    }

    public removeProperty(propertyID: string): Observable<boolean> {
        return this.http.post("/api/demolition/property/" + propertyID, null)
            .pipe(
                map(
                    (response: boolean) => {
                        console.log("Property (ID " + propertyID + ") Removed");
                        return response;
                    },
                    err => {
                        console.error(err.message)
                    }
                )
            )
    }

    public removeUser(userID: string): Observable<boolean> {
        return this.http.post("/api/demolition/user/" + userID, null)
            .pipe(
                map(
                    (response: boolean) => {
                        console.log("User Account (ID " + userID + ") Removed");
                        return response;
                    },
                    err => {
                        console.error(err.message)
                    }
                )
            )
    }
}