import { PropertyAddress } from "./property-address";
import { EbentoSpace } from "./ebento-space";
import { PropertyManager } from "../users/property-manager";

export class EbentoProperty {

    public name: string
    public description: string
    public address: PropertyAddress
    public photourl: string
    public id: number
    public spaces: EbentoSpace[]
    public coverImage: FormData
    public propertyManager: PropertyManager
}