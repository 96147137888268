import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EbentoUser } from '../../../../shared/models/users/ebento-user';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-clickable-user-type',
  templateUrl: './clickable-user-type.component.html',
  styleUrls: ['./clickable-user-type.component.scss']
})
export class ClickableUserTypeComponent implements ICellRendererAngularComp {
  public params: any;

  constructor(private router: Router)
  { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
      
      return false;
  }

  openDetails()
  {
    if(this.params.data.userType == "Event Planner") {
      this.router.navigate(["ep-user-details/" + this.params.data.id]);
    }

    if(this.params.data.userType == "Property Manager") {
        this.router.navigate(["pm-user-details/" + this.params.data.id]);
    }
      
  }
}