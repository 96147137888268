import { Component, OnInit } from '@angular/core';
import { SimplePagesService } from '../../../../services/simple-pages.service';
import { PricingInfoDTO } from 'app/modules/shared/models/simple-pages/pricing-info-dto';

@Component({
    selector: 'pricing-info',
    templateUrl: './pricing-info.component.html',
    styleUrls: ['./pricing-info.component.scss']
})
export class PricingInfoComponent implements OnInit {

    model: PricingInfoDTO;
    text: string = '';

    constructor(private simplePagesService: SimplePagesService) { }

    ngOnInit() {
        this.simplePagesService.getPricingInfoPageData().subscribe(
            res => {
                if (res != null) {
                    this.model = res;
                    this.text = this.model.body;
                }
                else {
                    this.model = new PricingInfoDTO()
                }
            }
        )
    }

}
