import { Component, Input, EventEmitter, Output, ViewChild, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { Attachment } from 'app/modules/shared/models/messaging/attachment';
import { Subject } from 'rxjs';

@Component({
    selector: 'upload-attachment',
    templateUrl: 'upload-attachment.component.html'
})

export class UploadAttachment implements OnInit, OnChanges, OnDestroy {
    
    public attachments: Attachment[];
    public progress: number;
    public message: string;
    url: string = '';
    saved: boolean;
    subscription: any;

    @Input('preloadedAttachment') preloadedAttachment: Attachment;
    @Input('uploaderIndex') uploaderIndex: number;
    @Input('maxFiles') maxFiles: number;
    @Input('showFiles') showFiles = true;
    @Input('update') update;
    
    @Output('fileUploaded') fileUploaded: Subject<any> = new Subject<any>(); 
    @Output('fileRemoved') fileRemoved: Subject<any> = new Subject<any>();

    @ViewChild('file') myFileInput;



    constructor(private http: HttpClient) {
        this.attachments = [];
    }

    ngOnInit()
    {
        if (this.preloadedAttachment != null)
        {
            this.attachments.push(this.preloadedAttachment);
            this.saved = true;
        }
    }
    ngOnChanges() {
        
        this.subscription = this.update.subscribe(e => {
            if(this.uploaderIndex == e.index) {
                this.saved = e.saved;
            }
        });

        this.subscription;
    }

    upload(files) {

        if (files.length === 0)
            return;

        //TODO: check and remove previous uploaded documents for that amenity

        const formData = new FormData();

        var file = files[0];
            formData.append(file.name, file);

        var attachment = new Attachment();
        attachment.fileReference = file;
        attachment.uploadInProgress = true;
        this.attachments.push(attachment);

        const uploadReq = new HttpRequest('POST', 'api/upload/document', formData, {
            reportProgress: true,
        });

        this.myFileInput.nativeElement.value = '';
        //TODO: refactor this part to be in spaceService 
        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress)
                attachment.uploadProgress = Math.round(100 * event.loaded / event.total);
            else if (event.type === HttpEventType.Response) {
                if (event.status == 200) {
                    
                    this.message = 'Successful upload';
                    attachment.uploadInProgress = false;
                    attachment.url = event.body.toString();
                    this.fileUploaded.next(attachment);

                } else {
                    attachment.uploadInProgress = false;
                    attachment.uploadFailed = true;
                    this.message = 'Upload failed'
                }
            }
            else if (event.type === HttpEventType.ResponseHeader)
            {
                if (event.status != 200) {
                    attachment.uploadInProgress = false;
                    attachment.uploadFailed = true;
                    this.message = 'Upload failed'
                }
            }
        });
    }

    removeAttachment(attachment: Attachment)
    {
        // We shuold add an option to remove files from server

        this.fileRemoved.next();
        this.attachments.splice(this.attachments.indexOf(attachment),1);
    }

    formatFilesize(size: number): string
    {
        var unitIndex = 0;
        while (unitIndex < 3 && size > 200)
        {
            unitIndex++;
            size /= 1024;
        }
        var unit = "";
        switch (unitIndex)
        {
            case 0:
                unit = "B";
                break;
            case 1:
                unit = "KB";
                break;
            case 2:
                unit = "MB";
                break;
            case 3:
                unit = "GB";
                break;
        }
        return '' + Math.floor(size * 100) / 100 + "\u00A0" + unit;
    }

    ngOnDestroy()
    {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
    }
}