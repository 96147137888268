import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'five-star-scale',
    templateUrl: 'five-star-scale.component.html'
})

export class FiveStarScale {

    @Input() readonly: boolean = false;
    @Input() rating: number;
    @Output() ratingChange = new EventEmitter();

    numbers = [1, 2, 3, 4, 5];

    setToNumber(number: number)
    {
        if (!this.readonly)
        {
            this.rating = number;
            this.ratingChange.emit(this.rating);
        }
    }

    roundedRating(): number
    {
        return Math.round(this.rating);
    }

    constructor() {
    }
}

