import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EbentoModalService } from "app/modules/shared/services/ebento-modal.service";
import { RejectBookingModal } from "../reject-booking-modal/reject-booking-modal.component";
import { AddEntertainmentService } from "app/modules/property-manager/services/add-entertainment.service";
import { Entertainment } from "app/modules/shared/models/entertainment/AddEntertainmentStep1";
import { AcceptBookingModalComponent } from "../accept-booking-modal/accept-booking-modal.component";
import { MessagingService } from "app/modules/shared/services/messaging.service";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";
import { SpaceService } from "app/modules/shared/services/space.service";

@Component({
  selector: "booking-details-modal",
  templateUrl: "booking-details-modal.component.html",
  styleUrls: ["booking-details-modal.component.css"],
})
export class BookingDetailsModal implements OnInit {

  @Input() requestId: number;
  @Input() message: EbentoMessage;
  @Input() entertainmentData: any;
  @Input() entertainmentDetails: any;
  @Input() name: string;
  @Input() email: string;
  @Input() closeCallback: () => void;
  @Input() spaceType:string;
  public isLoaded: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: EbentoModalService,
    private entertainmentService: AddEntertainmentService,
    public spaceService:SpaceService
  ) {}

  ngOnInit(): void {
    console.log("entertianment data && details", this.entertainmentData, this.entertainmentDetails);
  }

  close() {
    this.activeModal.close("Close click");
  }

  OpenAcceptConfirmationModal() {

    const modalRef = this.modalService.open(AcceptBookingModalComponent, {
      size: "md",
      centered: true,
    });
    modalRef.componentInstance.id = this.requestId;
    modalRef.componentInstance.spaceType = this.entertainmentData.requestType;
    modalRef.componentInstance.message = this.message;

    if(this.entertainmentData.requestType === 'Entertainment'){
      modalRef.componentInstance.spaceName =
        this.entertainmentDetails.entertain_ServiceName;
      modalRef.componentInstance.propertyName =
        this.entertainmentDetails.property.name;
    }else if(this.entertainmentData.requestType === 'Space'){
      modalRef.componentInstance.spaceName = this.entertainmentData.spaceDetails.name;
      modalRef.componentInstance.propertyName = this.entertainmentData.spaceDetails.propertyName;
    }else if(this.entertainmentData.requestType === 'Workshop'){
      modalRef.componentInstance.spaceName = this.entertainmentData.spaceDetails.name;
      modalRef.componentInstance.propertyName = this.entertainmentData.spaceDetails.propertyName;
    }
    console.log("entertianment data in booking details",this.entertainmentData);
    this.close();
    // modalRef.componentInstance.closeCallback = this.close();
    // this.closeCallback();
  }

  openRejectModal() {
    const modalRef = this.modalService.open(RejectBookingModal, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.id = this.requestId;
    modalRef.componentInstance.message = this.message;
    this.activeModal.close("Close click");
    // this.closeCallback();

  }
}
