import { Component } from '@angular/core';
import { CartService } from '../../../event-planner/services/cart.service';
@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent {

  constructor(public cartService:CartService){}

}
