import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
import { Coupon, CouponMessage } from "app/modules/shared/models/booking/coupon";

@Injectable()
 //TODO: maybe only needed bookings, booking and booking overview
export class AdminBookingService {

    public coupons: Coupon[]

    constructor(private http: HttpClient) {
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    generateCoupon(coupon: Coupon): Observable<Coupon> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post<Coupon>("api/generatecouponadmin", JSON.stringify(coupon), httpOptions)
            .pipe(
                map(
                result => { 
                    //console.log("Generated: ");
                    return result;}),
                catchError(this.errorHandler)
            );
    }

    sendCouponMessage(message: CouponMessage): Observable<Coupon> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post<Coupon>("api/sendcouponmessage", JSON.stringify(message), httpOptions)
            .pipe(
                map(
                result => {
                        //console.log("Poslato!");
                        return result;
                    }),
                catchError(this.errorHandler)
            );
    }


    getAllCoupons(): Observable<Coupon[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.get<Coupon[]>("api/getAllCouponsAdmin", httpOptions)
            .pipe(
                map(
                    result => {
                        this.coupons = result;
                        return result;
                    }),
                catchError(this.errorHandler)
            );
    }
}
