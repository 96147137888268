import { Component, OnInit, InjectionToken, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { AdminSpaceService } from 'app/modules/admin/services/admin-space.service';
import { InjectableItem } from '../../index-carousel/injectable-item';
import { CarouselTile } from '../carousel-tile.component';
import { SpaceBookingService } from '../../../../../shared/services/space-booking.service';

@Component({
  selector: 'favorite-space-carousel-tile',
  templateUrl: 'favorite-space-carousel-tile.component.html',
  styleUrls: ['favorite-space-carousel-tile.component.css']
})
export class FavoriteSpaceCarouselTile extends CarouselTile implements OnInit {

    constructor(injection: InjectableItem, private bookingService: SpaceBookingService, private router: Router, public authService: AuthService, public adminSpaceService: AdminSpaceService)
    {
            super(injection);
        // da li sam realan...2
    }
    
    ngOnInit() {
      }

    public Redirect()
    {
        if (this.authService.isLoggedIn() && this.authService.userType == "EventPlanner") {
            this.bookingService.startNewBooking(this.item.id);
        }
        else {
            this.router.navigate(['space-details/' + this.item.id]);

        }
    }
}
