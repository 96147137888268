import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { map, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { Invoice } from "../models/users/invoice";
import { CreditCard } from "../models/users/credit-card";
import { BankAccount } from "../models/users/bank-account";


@Injectable()
export class CustomerService {

    constructor(
        private http: HttpClient,
        public authService: AuthService
    ) { }



    createOrUpdateInvoice(inv: Invoice) {
        return this.http.post("api/Invoice", inv)
            .pipe(
                map(
                    () => {
                        return true;
                    },
                err => { console.error(err.Message) }
                )
            )
    }

    createOrUpdateCreditCard(cc: CreditCard) {
        return this.http.post("api/CreditCard", cc)
            .pipe(
                map(
                    () => {
                        return true;
                    },
                err => { console.error(err.Message) }
                )
            )
    }

    createOrUpdateBankAccount(ba: BankAccount) {
        return this.http.post("api/BankAccount", ba)
            .pipe(
                map(
                    () => {
                        return true;
                    },
                err => { console.error(err.Message) }
                )
            )
    }

}