import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/modules/shared/services/auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
    public host: string;

    constructor(private authService: AuthService, private router: Router) {}

    ngOnInit(): void {
      this.host = window.location.host;
      if(this.authService.isLoggedIn()) {
        this.router.navigate(['index'])
      }
    }
}
