/// <reference types="@types/googlemaps" />

import { Component, ViewChild, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SpaceForTile } from "app/modules/shared/models/search/space-for-tile";
import { PropertyManagerReview } from "app/modules/shared/models/users/property-manager-review";
import { AuthService } from "app/modules/shared/services/auth.service";
import { SpaceBookingService } from "app/modules/shared/services/space-booking.service";
import { PropertyService } from "app/modules/shared/services/property.service";
import { ReviewService } from "app/modules/shared/services/review.service";
import { SpaceService } from "app/modules/shared/services/space.service";
import { MessagingService } from "app/modules/shared/services/messaging.service";
import { SpaceBookingOptions } from "app/modules/shared/models/booking/space-booking-options";
import { WeekCalendarModal } from "app/modules/shared/modules/modals/components/week-calendar-modal/week-calendar-modal.component";
import { PropertyAddress } from "app/modules/shared/models/properties/property-address";
import { Amenity } from "app/modules/shared/models/properties/Amenity";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";
import { EbentoModalService } from "../../../shared/services/ebento-modal.service";
import { CartService } from "../../services/cart.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { NotificationType } from "../../../../helpers/enums/notificationType";

@Component({
  selector: "modify-cart-space",
  templateUrl: "modify-cart-space.component.html",
  styleUrls: ["./modify-cart-space.component.scss"],
})
export class ModifyCartSpace implements OnInit, AfterViewInit {
  
  spaceForTile: any;
  reviews: PropertyManagerReview[];
  modalRef: NgbModalRef;
  public attendees: number = 0;
  activeImage: string;
  @ViewChild("gmap") gmapElement: any;
  timeslots: any[];
  cartId: number;
  prevTimeSlots: any[];

  public map: google.maps.Map;

  public spacephotourl: string;

  amenityQuantity: Object;

  constructor(
    public authService: AuthService,
    public bookingService: SpaceBookingService,
    public propertyService: PropertyService,
    private reviewService: ReviewService,
    public spaceService: SpaceService,
    private modalService: EbentoModalService,
    public messagingService: MessagingService,
    public router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private cartService: CartService,
    private notificationService: NotificationService
  ) {
    this.amenityQuantity = {};
  }

  ngOnInit() {
    if (
      !(
        this.authService.isLoggedIn() &&
        this.authService.userType == "EventPlanner"
      )
    )
      this.router.navigate(["index"]);
    else {
      this.spaceService.getSpaceTypes().subscribe((res) => {
        let spaceIdParam = this.activatedRoute.snapshot.params;
        console.log("space params", spaceIdParam);
        console.log("space service types", this.spaceService.spaceTypes);

        if (spaceIdParam != null) {
          this.cartService
            .getModify(spaceIdParam.cartId, spaceIdParam.serviceId, "space")
            .subscribe((res) => {
              // console.log('get modify res',res);
              const response = JSON.parse(JSON.stringify(res));
              this.spaceForTile = response.spaceDetails;
              this.bookingService.space = this.spaceForTile;
              this.activeImage = this.spaceForTile.photoUrls[0];
              this.cartId = res.cartId;
              this.attendees = res.attendees;
              this.init();
              this.prevTimeSlots = res.bookedTimeSlots.map((e) => {
                return {
                  startTime: this.formatTime(e.startTime),
                  endTime: this.formatTime(e.endTime),
                  allocationType: e.allocationType,
                };
              });
              this.timeslots = res.bookedTimeSlots.map((e) => {
                return {
                  startTime: this.formatTime(e.startTime),
                  allocationType: e.allocationType,
                  endTime: this.formatTime(e.endTime),
                  isCustom: false
                };
              });

              console.log("selected time slots", this.timeslots);

              const temp = {
                bookingAmenities: res.optionalAmenities,
                attendees:this.attendees,
                bookingType: "space",
                price: {
                  couponDiscount: 0,
                  ebentoFee:0,
                  extras: 0,
                  total: res.totalPrice,
                  totalWithDiscount: res.totalPrice,
                  venueHire: res.totalPrice,
                },
                timeAllocations:this.timeslots,
                spaceID: res.serviceId,
                usedCoupon:null
              }

              this.bookingService.cartModifyBookingPopulate(temp);


            });
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.SetMap();
  }

  init() {
    // this.bookingService.bookingOptions = new SpaceBookingOptions();
    // this.bookingService.bookingOptions.attendees = this.attendees;
    // console.log("booking options", this.bookingService.bookingOptions);

    this.reviewService
      .getReviewsForSpace(this.spaceForTile.spaceId)
      .subscribe((reviews) => {
        this.reviews = reviews;
      });

    if (
      this.spaceForTile != null &&
      this.spaceForTile.photoUrls != null &&
      this.spaceForTile.photoUrls.length > 0
    ) {
      this.spacephotourl = this.spaceForTile.photoUrls[0];
    }

    // this.SetMap();

    window.scrollTo(0, 0);
  }

  returnSpaceTypeName(i: number): string {
    return this.spaceService?.spaceTypes[i - 1].name;
  }

  SetMap() {
    var mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    var map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.map = map;
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        this.spaceForTile.address.googlemapcoordx,
        this.spaceForTile.address.googlemapcoordy
      ),
      map: this.map,
    });

    var bounds = new google.maps.LatLngBounds();
    bounds.extend(marker.getPosition());

    this.map.fitBounds(bounds);
    var listener = google.maps.event.addListener(map, "idle", function () {
      if (map.getZoom() > 16) map.setZoom(16);
      google.maps.event.removeListener(listener);
    });
  }

  reduceAttendees() {
    if (this.attendees > 0) {
      --this.attendees;
    }
    this.bookingService.bookingOptions.attendees = this.attendees;
  }

  incrementAttendees() {
    ++this.attendees;
    this.bookingService.bookingOptions.attendees = this.attendees;
  }

  setBigImage(i: number) {
    this.activeImage = this.spaceForTile.photoUrls[i];
  }

  openModal() {
    this.modalRef = this.modalService.open(WeekCalendarModal, { size: "xl" });

    this.modalRef.componentInstance.title = "Reservation Calendar";
    this.modalRef.componentInstance.spaceId = this.spaceForTile.spaceId;
    console.log("selected time slots", this.timeslots);
    if (this.bookingService.bookingTimeslots != null)
      this.modalRef.componentInstance.SetSelections(this.bookingService.bookingTimeslots);
    this.modalRef.componentInstance.passBookings.subscribe((receivedEntry) => {
      this.bookingService.bookingTimeslots = receivedEntry;
    });
  }

  public AddressText(address: PropertyAddress) {
    var result: string = "";
    if (address?.streetaddress != null && address?.streetaddress != "")
      result += address?.streetaddress;
    if (address?.streetnumber != null && address?.streetnumber != "")
      result += " " + address?.streetnumber;
    if (address?.city != null && address?.city != "") {
      if (result != "") result += ", ";
      if (address?.postalcode != null && address?.postalcode != "")
        result += address?.postalcode + " ";
      result += address?.city;
    }
    if (address?.country != null && address?.country != "") {
      if (result != "") result += ", ";
      result += address?.country;
    }
    return result;
  }

  public OptionalAmenities(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.spaceForTile?.amenities != null)
      for (var a of this.spaceForTile?.amenities) {
        if (a.isoptional) result.push(JSON.parse(JSON.stringify(a)));
      }
    return result;
  }

  public Catering(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.spaceForTile?.amenities != null)
      for (var a of this.spaceForTile?.amenities) {
        if (a.amenitytype.isCatering)
          result.push(JSON.parse(JSON.stringify(a)));
      }
    return result;
  }

  public FixedAmenities(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.spaceForTile?.amenities != null)
      for (var a of this.spaceForTile?.amenities) {
        if (!a.isoptional && !a.amenitytype.isCatering)
          result.push(JSON.parse(JSON.stringify(a)));
      }
    return result;
  }

  downloadFile(amenity: Amenity) {
    return this.http
      .get(window.location.origin + "/" + amenity.documenturl, {
        responseType: "blob",
      })
      .subscribe(
        (res) => {
          //console.log('start download:', res);
          var url = window.URL.createObjectURL(res);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download =
            this.spaceForTile.name +
            "_" +
            amenity.amenitytype.name +
            "_details";
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        },
        (error) => {
          console.error("download error:", JSON.stringify(error));
        },
        () => {
          //console.log('Completed file download.')
        }
      );
  }

  AddToCart() {
    this.bookingService.populateBookingOptions();
    let slots;
    if (
      this.bookingService.bookingOptions.timeAllocationDTOs !== null &&
      this.bookingService.bookingOptions.timeAllocationDTOs.length
    ) {
      slots = this.bookingService.bookingOptions.timeAllocationDTOs.map((e) => {
        return {
          startTime: e.startTime,
          endTime: e.endTime,
          allocationType: e.allocationType,
        };
      });
    } else {
      slots = this.prevTimeSlots.filter((e) => {
        return {
          startTime: this.formatTime(e.startTime),
          endTime: this.formatTime(e.endTime),
          allocationType: e.allocationType,
        };
      });
    }

    const temp = {
      cartId: this.cartId,
      bookedTimeSlots: slots,
      attendees: this.attendees,
      spaceType: "Space",
      optionalAmenities: this.bookingService.bookingOptions.optionalAmenities,
      totalPrice: this.bookingService.BasePrice(),
      serviceId: this.spaceForTile.spaceId,
      spaceName: this.spaceForTile.name,
      propertyName: this.spaceForTile.propertyName,
      image: this.spaceForTile.photoUrls[0],
    };

    this.cartService.updateCartModify(temp).subscribe(
      (resp) => {
        console.log("response", resp);
        this.notificationService.notify(
          NotificationType.Success,
          `${this.spaceForTile.name} is updated`,
          "Cart Item Updated"
        );
        this.router.navigate(['/cart'])

      },
      (err) => {
        console.log("add cart error", err);
        this.notificationService.notify(
          NotificationType.Error,
          `${this.spaceForTile.name} cannot be updated`,
          "Please try again later"
        );
        this.router.navigate(['/cart'])

      }
    );
  }

  gotoProfile() {
    this.propertyService
      .getProperty(this.spaceForTile.propertyId)
      .subscribe(() => this.router.navigate(["/property-details"]));
  }

  sendMessage() {
    this.messagingService.currentRecepient = this.spaceForTile.propertyManager;
    this.messagingService.currentDraft = new EbentoMessage();
    this.router.navigate(["/messages/compose"]);
  }

  formatTime(d: string) {
    const [date, time, format] = d.split(" ");
    const [dd, mm, yy] = date.split("/");
    const newDate = `${mm}/${dd}/${yy} ${time} ${format}`;
    return newDate;
  }

  calculateDuration(startTime: string, endTime: string) {
    const [sdate, stime, sformat] = startTime.split(" ");
    const [edate, etime, eformat] = endTime.split(" ");
    console.log("stime", stime, "etime", etime, "formats", sformat, eformat);

    const [shours, sminutes, sseconds] = stime.split(":");
    const [ehours, eminutes, eseconds] = etime.split(":");

    let time_start = new Date();
    let time_end = new Date();
    time_start.setHours(Number(shours), Number(sminutes), Number(sseconds), 0);
    time_end.setHours(Number(ehours), Number(eminutes), Number(eseconds), 0);
    console.log("time diff", time_start.getTime(), time_end.getTime());

    const duration = (time_end.getTime() - time_start.getTime())/1000;
    let difference = duration;
    difference = difference / 1000;
    let hourDifference = Math.floor(difference / 3600);
    difference -= hourDifference * 3600;
    let minuteDifference = Math.floor(difference / 60);
    difference -= minuteDifference * 60;
    return duration;
  }

  returnDate(d: string): Date {
    const [date, time] = d.split(" ");
    const [dd, mm, yy] = date.split("/");
    const [hh, mn, ss] = time.split(":");
    const newDate = new Date(
      Number(yy),
      Number(mm) - 1,
      Number(dd),
      Number(hh),
      Number(mn),
      Number(ss)
    );
    console.log("calculated new Date", newDate);
    return newDate;
  }
}
