import { Component } from '@angular/core';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { WorkshopBookingService } from 'app/modules/shared/services/workshop-booking.service';
import { WorkshopBookingMenu } from 'app/modules/shared/models/booking/workshop-booking-menu';
import { WorkshopService } from '../../../shared/services/workshop.service';

@Component({ 
    selector: 'workshop-payment-overview',
    templateUrl: 'workshop-payment-overview.component.html',
    styleUrls: ['./workshop-payment-overview.component.scss']
})

export class WorkshopPaymentOverview
{
    constructor(
        public workshopBookingService: WorkshopBookingService,
        public workshopService: WorkshopService,
        public spaceService: SpaceService)
    {
    }

    public VenuePrice(): number {
        return this.workshopBookingService.VenuePrice();
    }

    public BasePrice(): number {
        return Math.round(this.workshopBookingService.BasePrice() * 100) / 100;
    }

    public EbentoFee(): number {
        return Math.round(this.workshopBookingService.EbentoFee() * 100) / 100;
    }


    public TotalPrice(): number {
        return Math.round(this.workshopBookingService.TotalPrice(false) * 100) / 100;
    }

    public TotalPriceWithDiscount(): number {
        return Math.round(this.workshopBookingService.TotalPrice(true) * 100)/ 100;
    }

    calculateMenuPrice(bookingMenu: WorkshopBookingMenu): number
    {
        return bookingMenu.quantity * bookingMenu.unitPrice;
    }

    public nonNullMenus()
    {
        var result = new Array<WorkshopBookingMenu>();
        if (this.workshopBookingService.bookingOptions != null && this.workshopBookingService.bookingOptions.menus != null)
            for (let menu of this.workshopBookingService.bookingOptions.menus)
            {
                if (menu.quantity>0) result.push(menu);
            }   
        return result;
    }
    public PriceDifference(): string {
        var result = Math.round((this.TotalPriceWithDiscount() - this.workshopBookingService.bookingToEdit.price.totalWithDiscount) * 100) / 100;
        var sign = (result < 0) ? "- CHF" : "CHF"
        return sign + " " + Math.abs(result);
    }
}
