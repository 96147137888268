import { Component, Input, OnInit, OnChanges, SimpleChanges, HostListener, DoCheck } from '@angular/core';
import { SpaceBookingService } from '../../services/space-booking.service';
import { BookingSegment } from '../../models/booking/booking-segment';

@Component({
    selector: 'month-calendar-view',
    templateUrl: 'month-calendar-view.component.html',
    styleUrls: ['month-calendar-view.component.scss']

})

export class MonthCalendarView implements OnInit, DoCheck, OnChanges 
{
    @Input() spaceId: number
    mousedown = false;
    currentMonth: number;
    currentYear: number;
    weekdaysAbrev = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    weekdaysLetters = ["M", "T", "W", "T", "F", "S", "S"];
    weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    weekIndexes: Array<number>;
    cells: Array<Array<Date>>;
    firstOfMonth: Date;
    lastOfMonth: Date;
    status;
    starttime = 12;
    lastClickTime = 0;
    lastClickDay = 0;
    fillvalue = false;

    constructor(public bookingService: SpaceBookingService)
    {
        var today = new Date();
        this.currentMonth = today.getMonth();
        this.currentYear = today.getFullYear();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.GetHeadWidth();
        this.GetCardMaxWidth();
        this.GetCellWidth();
    }
    scrollable;
    headWidth: string;
    cellWidth: string;
    cardMaxWidth: string;

    GetHeadWidth(): void {
        if (this.scrollable != null) {
            var newHeadWidth = this.scrollable.clientWidth + "px";

            if (this.headWidth != newHeadWidth)
                this.headWidth = newHeadWidth;
        }
    }


    GetCellWidth(): void {
        if (this.scrollable != null)
        {
            var newCellWidth = this.scrollable.clientWidth / 7 + "px";
            if (this.cellWidth != newCellWidth)
                this.cellWidth = newCellWidth;
        }
    }

    GetCardMaxWidth(): void {
        if (this.scrollable != null) {
            var newCardMaxWidth = this.scrollable.clientWidth / 7 - 16  + "px";
            if (this.cardMaxWidth != newCardMaxWidth)
                this.cardMaxWidth = newCardMaxWidth;
        }
    }


    ngDoCheck() {
        this.GetHeadWidth();
        this.GetCardMaxWidth();
        this.GetCellWidth();
    }


    ngOnInit(): void {
        this.GetBookings();
    }


    ngOnChanges(changes: SimpleChanges) {
        this.GetBookings();
        this.InitalizeForMonthYear();
    }


    GetBookings() {
        this.bookingService.getBookingsForSpace(this.spaceId).subscribe(success => {
            if (success) {
                this.InitalizeForMonthYear();
            }
            else {
                console.error("Error");
            }
        });
    }

    InitalizeForMonthYear() {
        this.weekIndexes = new Array<number>();
        var weekIndex = 0;
        this.firstOfMonth = new Date(this.currentYear, this.currentMonth, 1);
        this.lastOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);
        var numberOfDays = this.lastOfMonth.getDate();
        this.cells = new Array<Array<Date>>();
        // first week
        var firstDay = this.firstOfMonth.getDay();
        if (firstDay == 0) firstDay = 7;
        firstDay--;

        var dayIndex = 1;
        var tempWeek = new Array<Date>();
        for (var i = 0; i < 7; i++) {
            if (i < firstDay)
                tempWeek.push(null);
            else {
                tempWeek.push(new Date(this.currentYear, this.currentMonth, dayIndex));
                dayIndex++;
            }
        }

        this.cells.push(tempWeek);
        this.weekIndexes.push(weekIndex);
        weekIndex++;

        tempWeek = new Array<Date>();
        // other weeks
        var curDay = 0;
        while (dayIndex <= numberOfDays) {
            tempWeek.push(new Date(this.currentYear, this.currentMonth,dayIndex));
            dayIndex++;
            curDay++;
            if (curDay == 7) {
                this.cells.push(tempWeek);
                tempWeek = new Array<Date>();
                this.weekIndexes.push(weekIndex);
                weekIndex++;
                curDay = 0;
            }
        }

        // last week, if overflow
        if (curDay > 0) {
            while (curDay < 7) {
                tempWeek.push(null);
                curDay++;
            }
            this.cells.push(tempWeek);
            this.weekIndexes.push(weekIndex);
            weekIndex++;
        }
    }

    NextMonth() {
        this.currentMonth++;
        if (this.currentMonth > 11) {
            this.currentYear++;
            this.currentMonth = 0;
        }
        this.InitalizeForMonthYear();
        this.ngDoCheck();
    }

    PreviousMonth() {
        this.currentMonth--;
        if (this.currentMonth < 0) {
            this.currentYear--;
            this.currentMonth = 11;
        }
        this.InitalizeForMonthYear();
        this.ngDoCheck();
    }

    GetCaption(): string
    {
       return this.monthNames[this.currentMonth] + " " + this.currentYear;
    }


    GetDayName(index: number)
    {
        var env = this.findBootstrapEnvironment();
        if (env == 'xs')
            return this.weekdaysLetters[index];
        if (env == 'sm' || env == 'md')
            return this.weekdaysAbrev[index];
        return this.weekdaysAbrev[index];
    }

    findBootstrapEnvironment() : string {
    let envs = ['xs', 'sm', 'md', 'lg', 'xl'];

    let el = document.createElement('div');
    document.body.appendChild(el);

    let curEnv = envs.shift();

    for (let env of envs.reverse()) {
        el.classList.add(`d-${env}-none`);

        if (window.getComputedStyle(el).display === 'none') {
            curEnv = env;
            break;
        }
    }

    document.body.removeChild(el);
    return curEnv;
}

    GetColor(weekIndex: number, dayIndex: number): string {
        
        //var result = "";
        //if (this.status[dayIndex][timeIndex] == 1)
        //    result = '#b28097';
        //else if (this.status[dayIndex][timeIndex] == 2)
        //    result = '#a02c1c';
        //else if (this.bools[dayIndex][timeIndex])
        //    result = '#02d13c';
        //else
        //    result = '#F0F2F5';

        //if (timeIndex % 2 == 0) {
        //    if (result == '#F0F2F5')
        //        result = '#F9FAFB';
        //    else
        //        result += 'EE';
        //}
        var result = '#F0F2F5';
        if (this.cells[weekIndex][dayIndex] == null)
            result = '#c7d6ea';

        if (weekIndex % 2 == 0)
        {
            if (result == '#F0F2F5')
                result = '#F9FAFB';
            else
                result += 'EE';
        }
        return result;
    }

    GetCellDate(weekIndex: number, dayIndex: number): Date
    {
        return this.cells[weekIndex][dayIndex];
    }

    GetBookingsForDate(date: Date): Array<BookingSegment>
    {
        if (date == null)
            return null;
        else
        {
            var result = new Array<BookingSegment>();
            if (this.bookingService.bookingSegments != null)
                for (let b of this.bookingService.bookingSegments)
            {
                if (b.startTime.getDate() == date.getDate() && b.startTime.getMonth() == date.getMonth() && b.startTime.getFullYear() == date.getFullYear())
                    result.push(b);
            }
            return result;
        }
            
    }
}