import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { PricingRule } from "../../../../models/properties/pricing-rule";

@Component({
    selector: 'add-rule-modal',
    templateUrl: "add-rule-modal.component.html"
})
export class AddRuleModal implements OnInit {

    @Input() title: string
    @Input() availablePricingRules: PricingRule[]
    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    @Input() type:string;

    constructor() { }

    passBack() {
        this.passEntry.emit(this.availablePricingRules);
    }
    
    ngOnInit() {
    }
}