import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, AfterViewInit } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dropdown',
    templateUrl: 'dropdown.component.html',
    styleUrls: ['dropdown.component.scss']
})

export class Dropdown implements OnChanges, AfterViewInit {

    @Output() onChangeValue: EventEmitter<any> = new EventEmitter();
    @Input() showLabel = true;
    @Input() customClass: string;
    @Input() addBlankItem = true;
    @Input() items: Array<any>;
    @Input() label: string;
    @Input() elementToText: (a: any) => string;
    @Input() startValue: any;
    public currentValue: any;

    ngAfterViewInit()
    {
        this.currentValue = this.startValue;
    }

    ngOnChanges(changes: SimpleChanges)
    {
        
    }

    public onClick(clickedEntry: any): void
    {
        this.onChangeValue.emit(clickedEntry);
        this.currentValue = (this.elementToText != null) ? this.elementToText(clickedEntry) : clickedEntry;
    }

    getLabel() {
        return (this.currentValue == null || this.showLabel) ? this.label : "";
    }
    
}

