/// <reference types="@types/googlemaps" />

import { Component, ViewChild , OnInit , AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AddEntertainmentService } from '../../../services/add-entertainment.service';
import { Entertainment } from '../../../../shared/models/entertainment/AddEntertainmentStep1';
import { PropertyService } from '../../../../shared/services/property.service';
import { MessagingService } from '../../../../shared/services/messaging.service';
import { EbentoMessage } from '../../../../shared/models/messaging/ebento-message';
import { PropertyAddress } from '../../../../shared/models/properties/property-address';

@Component({
    selector: 'add-entertainment-preview',
    templateUrl: './preview.component.html',
    styleUrls: [ './preview.component.css' ]
})

export class PreviewComponent implements AfterViewInit,OnInit{
    
    @ViewChild('gmap') gmapElement: any;
    public map: google.maps.Map;
    public entertainmentData:Entertainment;
    public activeImage:string;
    public videoData:any[] = [];
    public audioData:any[] = [];
    public images:any[] = [];   

    constructor(

            private router:Router,
            private activatedRoute: ActivatedRoute,
            private addEntertainmentService:AddEntertainmentService,
            public propertyService: PropertyService,
            public messagingService: MessagingService,

            ){
                console.log('params id',this.activatedRoute.snapshot.params.id);
               this.addEntertainmentService.serviceId = this.activatedRoute.snapshot.params.id
            }

    ngAfterViewInit(){
        this.setMap();
    }

    ngOnInit(): void {
        this.addEntertainmentService.editMode = false;
        this.addEntertainmentService.getPreviewData(this.addEntertainmentService.serviceId)
            .subscribe((response) => {
                // this.entertainmentData = response[0];
                this.entertainmentData ={
                    entertain_ServiceId: response[0].Entertain_ServiceId,
                    entertain_ServiceName: response[0].Entertain_ServiceName,
                    enter_CatID: response[0].Enter_CatID,
                    entertainTypeId: response[0].EntertainTypeId.map((e) => { 
                        return { 
                            entertainTypeId:e.EntertainTypeId, 
                            entertainTypeName: e.EntertainTypeName 
                        }
                    }),
                    enter_LocationID: response[0].Enter_LocationID,
                    Enter_LocationName:response[0].Enter_LocationName,
                    enter_Desription: response[0].Enter_Desription,
                    enter_DistanceID: response[0].Enter_DistanceID,
                    bookingType: response[0].BookingType,
                    enter_CovidMeasures: response[0].Enter_CovidMeasures,
                    documentUrl: response[0].documentUrl.map((e) => {
                        return {
                            enter_DocumentID:e.Enter_DocumentID,
                            enter_DocumentName:e.Enter_DocumentName, 
                            enter_Documenttype:e.Enter_Documenttype,
                            enter_DocumentURL:e.Enter_DocumentURL ,
                            enter_FileName:e.Enter_FileName ,
                            enter_Filesize:e.Enter_Filesize ,
                            entertain_ServiceId:e.Entertain_ServiceId 
                        }
                    }),
                    pricingRules: response[0].pricingRules,
                    ecancellationRules: response[0].EcancellationRules.map((e) => {
                        return {
                            id:e.ID,
                            daysFrom:e.DaysFrom,
                            daysTo:e.DaysTo,
                            percentageToReturn:e.PercentageToReturn,
                            serviceId:e.ServiceId,
                        }
                    }),
                    isActive: response[0].isActive,
                    Enter_priceperEvent: response[0]?.Enter_priceperEvent,
                    additionalCosts: response[0].AdditionalCosts,
                    pricingType: response[0].PricingType,
                    availableTimeslots: response[0].availableTimeslots,
                    propertyIDEbentoProperty: response[0].PropertyIDEbentoProperty,
                    propertyDetails: response[0].Property
                };

                this.addEntertainmentService.entertainmentData = this.entertainmentData;
                this.addEntertainmentService.propertyId = String(response[0].propertyIDEbentoProperty);
                // this.activeImage = response[0].documentUrl[0]['Enter_DocumentURL'];
                // console.log('active Image',this.activeImage);
                
                this.entertainmentData.documentUrl.forEach((e) => {
                    const [dot,type] = e.enter_DocumentURL.split('.');

                    if(e.enter_DocumentURL.length && type === 'jpg'){
                        this.images.push(e.enter_DocumentURL);
                    }
                })

                this.activeImage = this.images[0];
        })

        this.addEntertainmentService.getMedia(this.addEntertainmentService.serviceId)
            .subscribe((response) => {
                response.forEach((e)=>{
                    if(e.enter_Documenttype === '.mp4' || e.enter_Documenttype === '.mkv' && e.enter_DocumentURL.length ){
                        this.videoData.push(e.enter_DocumentURL);
                    }
                    else if(e.enter_Documenttype=== '.m4a' || e.enter_Documenttype === '.mp3' && e.enter_DocumentURL.length ){
                        this.audioData.push(e.enter_DocumentURL);
                    }
                })                
            })
    }


    setMap() {
        var mapProp = {
            center: new google.maps.LatLng(18.5793, 73.8143),
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        let map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
        this.map = map;
        
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(this.entertainmentData.propertyDetails.Address.GoogleMapCoordX, this.entertainmentData.propertyDetails.Address.GoogleMapCoordY),
            // position: { lat: -34.397, lng: 150.644 },
            map: this.map
        });

        var bounds = new google.maps.LatLngBounds();
        bounds.extend(marker.getPosition());

        this.map.fitBounds(bounds);
        var listener = google.maps.event.addListener(map, "idle", function () {
            if (this.map.getZoom() > 16) this.map.setZoom(16);
            google.maps.event.removeListener(listener);
        });
    }

    editEntertainment(){
        this.addEntertainmentService.editMode = true;
        this.router.navigate(['/edit-entertainment']);
    }

    editAvailability(){
        this.addEntertainmentService.selectedIndex = 3;
        this.addEntertainmentService.editMode = true;
        this.router.navigate(['/edit-entertainment']);
    }

    publishEntertainment(){
        this.addEntertainmentService.publishEntertainment(this.addEntertainmentService.serviceId).subscribe((response) => {
            console.log("publish entertainment",response);
            this.router.navigate(['/property-manager/properties'])
        })
    }

    removeEntertainment(){
        this.addEntertainmentService.removeEntertainment(this.addEntertainmentService.serviceId).subscribe((response) => {
            console.log("remove entertainment",response);
            this.router.navigate(['/property-manager/properties'])
        })
    }

    setBigImage(i:number){
        this.activeImage = this.images[i];
    }

    gotoProfile() {
        this.propertyService.getProperty(this.entertainmentData.propertyIDEbentoProperty)
            .subscribe(
                () => this.router.navigate(['/property-details']));
    }

    sendMessage()
    {
        // this.messagingService.currentRecepient = this..propertyManager;
        this.messagingService.currentDraft = new EbentoMessage();
        this.router.navigate(['/messages/compose']);
    }

    public AddressText(address: any) {
        let result = `${address.StreetAddress},${address.StreetNumber},  
            ${address.PostalCode},
            ${address.City},
            ${address.Country}
        `
        return result;
    }


}