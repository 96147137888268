import { Component, Input } from "@angular/core";
import { AdditionalCost } from "../../models/entertainment/AddEntertainmentStep1";
import { AddEntertainmentService } from "../../../property-manager/services/add-entertainment.service";

@Component({
  selector: "additional-cost",
  templateUrl: "./additionalCost.component.html",
  styleUrls: ["./additionalCost.component.scss"],
})
export class AdditionalCosts {
  @Input() nonInput: boolean;
  @Input() data: AdditionalCost;
  public count: number = 1;

  constructor(public entertainmentService: AddEntertainmentService) {}

  AmenitySelectionChanged(e: any) {
    if (this.checkIfExists()) {
      this.entertainmentService.bookingOptions.optionalAmenities =
        this.entertainmentService.bookingOptions.optionalAmenities.filter(
          (e) => e.id !== this.data.ID
        );
    } else {
      this.entertainmentService.bookingOptions.optionalAmenities.push({
        id: this.data.ID,
        quantity: this.count,
        price: this.data.price,
        name: this.data.name,
      });
    }
  }

  checkIfExists() {
    let status = false;
    if (this.entertainmentService.bookingOptions.optionalAmenities.length) {
      status =
        this.entertainmentService.bookingOptions?.optionalAmenities?.some(
          (e) => e.id === this.data.ID
        );
    }
    return status;
  }

  updateCount(){
    let ind = this.entertainmentService.bookingOptions.optionalAmenities.findIndex((obj => obj.id == this.data.ID));
    this.entertainmentService.bookingOptions.optionalAmenities[ind].quantity = this.count;
  }

}
