import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Item } from "../../shared/models/cart/cartItem";
import { BoookingOptions } from "../../shared/models/cart/bookingOptions";

@Injectable()
export class CartService {

  public cartData: Item[] = [];
  public message: string = "";
  public bookingData: any[];
  public bookingOptions: BoookingOptions = {
    paymentIntentID: "",
    message: "",
    paymentType: {
      name: "",
    },
    currency: "",
  };
  public cartcount:number=0;
  public messageData = {};

  constructor(private _http: HttpClient) {}

  getCartCount(): Observable<any> {
    return this._http.get<any>("api/Cart/CartCount");
  }

  getCartItems(): Observable<any[]> {
    return this._http.get<any[]>("api/Cart/GetCartDetails");
  }

  getSaveForLater(): Observable<any[]> {
    return this._http.get<any[]>("api/Cart/GetSaveForLater");
  }

  addToCart(data: any): Observable<any> {
    return this._http.post<any>("api/Cart/AddToCart", data);
  }

  removeCartItem(id: number): Observable<any> {
    return this._http.delete<any>(`api/Cart/RemoveItemFromCart?cartId=${id}`);
  }

  addToSaveForLater(id: number): Observable<any> {
    return this._http.post<any>(`api/Cart/SaveForLater`, { cartId: id });
  }

  moveToCart(id: number): Observable<any> {
    return this._http.post(`api/Cart/MoveToCart`, { cartId: id });
  }

  removeSaveForLater(id: number) {
    return this._http.delete<any>(
      `api/Cart/RemoveItemFromSaveForLater?cartId=${id}`
    );
  }

  getModify(cartId: number, spaceId: number, type: string): Observable<any> {
    return this._http.get<any>(
      `api/Cart/GetModify?cartId=${cartId}&spaceId=${spaceId}&spaceType=${type}`
    );
  }

  updateCartModify(data: any) {
    return this._http.put<any>("api/Cart/UpdateModify", data);
  }

  bookThroughCart() {
    console.log("cartdata booking options---", this.bookingOptions);
    this.bookingData = this.cartData.map((e) => {
      e.bookedTimeSlots = e.bookedTimeSlots.map((e) => {
        return {
          startTime: this.formatTime(e.startTime),
          endTime: this.formatTime(e.endTime),
          allocationType: e.allocationType,
        };
      });
      let temp;
      if (e.spaceType === "Experience") {
        temp = {
          bookingID: 0,
          spaceType: "experience",
          serviceId: e.serviceId,
          attendees: e.attendees,
          totalPrice: e.totalPrice,
          workshopTimeAllocationDTO: e.bookedTimeSlots[0],
          workshopOptionalAmenities: e.optionalAmenities,
          coupon: this.bookingOptions.coupon !== null ? this.bookingOptions.coupon : {},
          currency: "CHF",
          paymentType: this.bookingOptions.paymentType,
          paymentIntentID: this.bookingOptions.paymentIntentID,
          message: this.messageData[e.cartId],
          workshopMenus: []
        };
       
      } else if (e.spaceType === "Space") {
        temp = {
          bookingID: 0,
          spaceType: "space",
          serviceId: e.serviceId,
          attendees: e.attendees,
          totalPrice: e.totalPrice,
          spaceTimeAllocationDTOs: e.bookedTimeSlots,
          spaceOptionalAmenities: e.optionalAmenities,
          coupon:
            this.bookingOptions.coupon !== null
              ? this.bookingOptions.coupon
              : {},
          currency: "CHF",
          paymentType: this.bookingOptions.paymentType,
          paymentIntentID: this.bookingOptions.paymentIntentID,
          message: this.messageData[e.cartId],
        };
      } else if (e.spaceType === "Entertainment") {
        temp = {
          bookingID: 0,
          spaceType: "entertainment",
          serviceId: e.serviceId,
          attendees: e.attendees,
          totalPrice: e.totalPrice,
          entertainmentTimeAllocationDTO: e.bookedTimeSlots[0],
          coupon:
            this.bookingOptions.coupon !== null
              ? this.bookingOptions.coupon
              : {},
          currency: "CHF",
          paymentType: this.bookingOptions.paymentType,
          paymentIntentID: this.bookingOptions.paymentIntentID,
          message: this.messageData[e.cartId],
        };
      }
      return temp;
    });

    console.log("booking data", this.bookingData);

    return this._http.post("api/Cart/BookFromCart", this.bookingData);
  }

  formatTime(d: string) {
    const [date, time, format] = d.split(" ");
    const [dd, mm, yy] = date.split("/");
    const newDate = `${mm}/${dd}/${yy}, ${time} ${format}`;
    return newDate;
  }
}
