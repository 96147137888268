import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  OnDestroy,
  DoCheck,
  ViewChild,
  HostListener,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { WeekCalendarView } from "../../../shared/components/week-calendar-view/week-calendar-view.component";
// import { Amenity } from '../../../shared/models/properties/Amenity';
import { Amenity } from "../../../shared/models/properties/Amenity";
import { AmenityType } from "../../../shared/models/properties/amenity-type";
import { CancellationRule } from "../../../shared/models/properties/cancellation-rule";
import { EbentoSpace } from "../../../shared/models/properties/ebento-space";
import { PricingRule } from "../../../shared/models/properties/pricing-rule";
import { PricingRuleType } from "../../../shared/models/properties/pricing-rule-type";
import { SittingArangement } from "../../../shared/models/properties/sitting-arangement";
import { SpaceType } from "../../../shared/models/properties/space-type";
import { WorkingHours } from "../../../shared/models/properties/working-hours";
import {
  AmenityDocumentUpload,
  DocumentUpload,
} from "../../../shared/models/users/document-upload";
import { AddRuleModal } from "../../../shared/modules/modals/components/add-rule-modal/add-rule-modal.component";
import { AuthService } from "../../../shared/services/auth.service";
import { EbentoModalService } from "../../../shared/services/ebento-modal.service";
import { SpaceBookingService } from "../../../shared/services/space-booking.service";
import { SpaceService } from "../../../shared/services/space.service";
import { UploadService } from "../../../shared/services/upload.service";
import { SubmissionService } from "app/modules/shared/services/submission.service";

import { faPlus, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { BookingSegment } from "../../../shared/models/booking/booking-segment";
import { MatStepper } from "@angular/material/stepper";

// import { TimeSpan } from '../../../../shared/models/data-classes/time-span';
import { TimeSpan } from "app/modules/shared/models/data-classes/time-span";
// import {WorkshopTimeAllocation} from '../../../../shared/models/data-classes/workshop-time-allocation';
import { WorkshopTimeAllocation } from "app/modules/shared/models/data-classes/workshop-time-allocation";
// import { WorkshopBookingService } from '../../../../shared/services/workshop-booking.service';
import { WorkshopBookingService } from "../../../shared/services/workshop-booking.service";
import { WsBookingTimeAllocation } from "../../../shared/models/data-classes/ws-booking-time-allocation";

@Component({
  selector: "add-space",
  templateUrl: "add-space.component.html",
  styleUrls: ["./add-space.component.css"],
})
export class AddSpaceForm
  implements AfterViewInit, OnInit, OnChanges, DoCheck, OnDestroy
{
  @ViewChild("table") tableRef;
  @ViewChild("stepper") stepper: MatStepper;
  @Input() preloadTimeslots: WorkshopTimeAllocation[];
  @Input() getBookingsOnInit: boolean = true;
  @Input() isDayCalendar: boolean;
  @Input() calendarMode: string;
  @Input() isWorkingTimeSetup: boolean;
  @Input() useSpaceWorkngHours: boolean;
  @Input() markWorkingHours: boolean;
  @Input() workingHours: WorkingHours;
  @Input() spaceId: number;
  @Input() starttime: number = 12;
  @Input() eventDuration: number = 1; // number of half hour steps

  public errors: string[] = [];
  public typeError: string = "";

  public isLinear: boolean = false;
  mondayDate: Date = new Date();
  dayIndex = 0;
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  monthAbbrevs = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  weekdaysLetters = ["M", "T", "W", "T", "F", "S", "S"];
  weekdaysAbrev = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  env;
  singleRowHeight = "";
  mouseUpResponder;
  mouseMoveResponder;
  touchEndResponder;
  scrollable;
  headWidth: string;
  tabela;
  times: Array<string>;
  rowHeight = 10;
  rowWidth = 10;
  status;
  mouseX: number;
  mouseY: number;
  dragStartMouseX: number;
  dragStartMouseY: number;
  currentDragged: Draggable;
  currentSelectedTimeAllocation: WsBookingTimeAllocation;

  startScrollPosSet: boolean;

  mousedown = false;

  scrollPending = false;

  scrolling = false;

  lastCellX = -1;
  lastCellY = -1;

  startTouchDist = 0;
  scrollStartTop = 0;

  dragStartX = 0;
  dragStartY = 0;

  maxDragDist = 20;

  draggables: Array<Draggable>;

  customDraggable: Draggable;

  //amenity types
  amenityTypesNonCatering: any;

  // we need this for calendar overlaps ?
  bookingSegments: Array<Array<BookingSegment>>;

  workshopTimeAllocations: Array<WorkshopTimeAllocation>;

  // single "draggable" Timeslot for customTime
  customTimeAllocation: WorkshopTimeAllocation;

  faPlus = faPlus;
  faWindowClose = faWindowClose;
  @ViewChild("weekCalendarView") nonworkingCalendar: WeekCalendarView;
  public WeekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  public formPageIndex: number = 0;
  public selectedSpaceTypes: boolean[];

  //TODO: make amenity model
  public selectedAmenities: boolean[];
  public amenityPrice: number[];
  public amenityIsOptional: boolean[];
  public amenityHasAdjustableQuantity: boolean[];
  public amenityPricePerPerson: boolean[];
  public amenityDocumentUrl: string[];

  public customAmenities: Amenity[];
  public customCatering: Amenity[];

  public selectedSittingType: boolean[];
  public sittingNumberOfSeats: number[];
  public propertyID: number;

  public maxImgNo: number[];
  public formData: FormData;
  public hasImages: boolean;
  public currentImgNo: number;

  editMode: boolean = null;
  mySpace: EbentoSpace = null;
  response = "";

  public errorMessage: string;

  public pricingRuleTypes: PricingRuleType[];
  public pricingRulesErrors: string[];
  public availablePricingRules: PricingRule[];

  public cancellationRules: CancellationRule[];

  public hasMinMaxGuestError: boolean;
  public hasRecommendedGuestError: boolean;

  public localImages = [];
  public pricingType: string = "hourly";

  constructor(
    public authService: AuthService,
    public spaceService: SpaceService,
    private uploadService: UploadService,
    public bookingService: SpaceBookingService,
    private router: Router,
    public route: ActivatedRoute,
    private modalService: EbentoModalService,
    public workshopBookingService: WorkshopBookingService,
    private submissionService:SubmissionService

  ) {}

  GetHeadWidth(): void {
    if (this.scrollable != null) {
      var newHeadWidth = this.scrollable.clientWidth + "px";

      if (this.headWidth != newHeadWidth) this.headWidth = newHeadWidth;
    }
  }

  ngOnInit(): void {
    this.times = new Array<string>();

    for (var i = 0; i < 23; i++) {
      var hour = i.toString();
      if (i < 10) hour = "0" + hour;
      this.times.push(hour + ":00");
      this.times.push(hour + ":30");
    }

    this.initialise();

    this.editMode = false;
    this.mySpace = new EbentoSpace();

    this.propertyID = this.route.snapshot.params.id;
    this.selectedSpaceTypes = new Array(this.spaceService.spaceTypes.length);
    this.selectedAmenities = new Array(this.spaceService.amenityTypes.length);
    this.amenityPrice = new Array(this.spaceService.amenityTypes.length);
    this.amenityHasAdjustableQuantity = new Array(
      this.spaceService.amenityTypes.length
    );
    this.amenityIsOptional = new Array(this.spaceService.amenityTypes.length);
    this.amenityPricePerPerson = new Array(
      this.spaceService.amenityTypes.length
    );
    this.amenityDocumentUrl = new Array(this.spaceService.amenityTypes.length);

    this.selectedSittingType = new Array(this.spaceService.sittingTypes.length);
    this.sittingNumberOfSeats = new Array(
      this.spaceService.sittingTypes.length
    );

    //TODO: create multipleImageUpload component to handle this functionality
    this.maxImgNo = new Array(6);

    this.formData = new FormData();
    this.hasImages = false;
    this.currentImgNo = 0;

    //TODO: import real data - this is quick fix
    this.pricingRuleTypes = this.spaceService.pricingRuleTypes;

    this.availablePricingRules = [];

    this.cancellationRules = [];

    this.mondayDate = new Date();

    this.touchEndResponder = (ev) => {
      if (this.scrollPending) {
        this.MouseDownTable(ev);
      } else {
        this.mouseUpResponder();
      }
      this.scrolling = false;
      this.scrollPending = false;
      this.mousedown = false;
    };

    this.getPricingRuleTypes()
      .then((__res) => {
        if (this.spaceService.spaceToEdit != null) {
          console.log("space to edit", this.availablePricingRules);
          this.preloadValues();
          this.editMode = true;
        }
      })
      .catch((__err) => {
        console.error("error of the get pricing types", __err);
      });

    //for editing
  }

  initialise(): void {
    this.getSpaceTypes();

    this.getSittingTypes();

    this.getAmenityTypeNonCatering();
  }

  getPricingRuleTypes() {
    //Available pricing rules getting created
    return new Promise((resolve, reject) => {
      this.spaceService.getPricingRuleTypes().subscribe((res) => {
        if (res) {
          this.pricingRuleTypes = res;
          for (let i = 0; i < this.pricingRuleTypes.length; i++) {
            var pricingRule = new PricingRule(this.pricingRuleTypes[i]);
            //var pricingType = new PricingRuleType();
            //pricingType.name = this.pricingRuleTypes[i];
            //pricingRule.type = pricingType;
            this.availablePricingRules.push(pricingRule);
          }
          resolve("success");
        } else {
          reject("no pricing rule types");
        }
      });
    });
  }

  getSpaceTypes() {
    this.spaceService.getSpaceTypes().subscribe((success) => {
      if (success) {
        this.spaceService.spaceTypes = success;
      } else {
      }
    });
  }

  getSittingTypes() {
    this.spaceService.getSittingTypes().subscribe((success) => {
      if (success) {
        this.spaceService.sittingTypes = success;
      }
    });
  }

  getAmenityTypeNonCatering() {
    this.spaceService.getAmenityTypes().subscribe((success) => {
      if (success) {
        this.amenityTypesNonCatering = success;
        console.log("amenityNon", this.amenityTypesNonCatering);
      } else {
        console.log("error...");
      }
    });
  }

  checkValue(event: any) {
    console.log("event:", event);
    const box = document.getElementsByClassName("item-box");
    // box.style.border = '1px solid red';
  }

  @HostListener("window:resize", ["$event"])
  getEnv() {
    this.env = this.findBootstrapEnvironment();
    this.singleRowHeight = "2rem";
    if (this.env == "xs" || this.env == "sm" || this.env == "md")
      this.singleRowHeight = "4rem";
  }

  OnDown(dayIndex: number, timeIndex: number, event): void {
    console.log(dayIndex, timeIndex, this.calendarMode, this.draggables);
    // if (this.calendarMode == "add-workshop-wizard") {
    var timeOffset = -1;
    var foundEmptySlot = false;
    while (timeOffset < this.eventDuration - 1 && !foundEmptySlot) {
      timeOffset++;
      foundEmptySlot = this.isAvailable(
        dayIndex,
        timeIndex - timeOffset,
        this.eventDuration
      );
    }
    if (foundEmptySlot) {
      this.mousedown = true;
      var workshopTimeAllocation = new WorkshopTimeAllocation();

      workshopTimeAllocation.startTime = this.GetDateForSlot(
        dayIndex,
        timeIndex - timeOffset
      ).toLocaleString("en-US");
      workshopTimeAllocation.endTime = this.GetDateForSlot(
        dayIndex,
        timeIndex - timeOffset + this.eventDuration
      ).toLocaleString("en-US");
      //console.log("START @ " + workshopTimeAllocation.startTime);
      this.workshopTimeAllocations.push(workshopTimeAllocation);
      this.createDraggableForTimeAllocation(workshopTimeAllocation);
    }

    console.log("workshop time allocations", this.workshopTimeAllocations);

    // }

    // var timeOffset = -1;
    // var foundEmptySlot = false;
    // while (timeOffset < this.eventDuration - 1 && !foundEmptySlot) {
    //     timeOffset++;
    //     foundEmptySlot = this.isAvailable(dayIndex, timeIndex - timeOffset, this.eventDuration);
    // }
    // if (foundEmptySlot) {
    //     this.mousedown = true;
    //     var workshopTimeAllocation = new WorkshopTimeAllocation();

    //     workshopTimeAllocation.startTime = this.GetDateForSlot(dayIndex, timeIndex - timeOffset).toLocaleString('en-US');
    //     workshopTimeAllocation.endTime = this.GetDateForSlot(dayIndex, timeIndex - timeOffset + this.eventDuration).toLocaleString('en-US');
    //     //console.log("START @ " + workshopTimeAllocation.startTime);
    //     if (this.customDraggable != null)
    //     {
    //         this.customDraggable.delete();
    //     }
    //     this.customTimeAllocation = workshopTimeAllocation;
    //     this.customDraggable = this.createDraggableForTimeAllocation(workshopTimeAllocation);
    //     // this.customDraggable.isCustom = true;
    //     this.selectDraggable(this.customDraggable);
    // }
  }

  GetColor(dayIndex: number, timeIndex: number): string {
    console.log("dayIndex", dayIndex, "timeIndex", timeIndex);

    if (this.GetDateForSlot(dayIndex, timeIndex) < this.GetDateToday()) {
      if (timeIndex % 2 != 0) return "#bab9c7";
      return "#c5c5d1";
    }
    var result = "";
    if (this.status[dayIndex][timeIndex] == 1) result = "#b28097";
    else if (this.status[dayIndex][timeIndex] == 2) {
      if (this.calendarMode == "add-workshop-wizard") result = "#a02c1c";
      else result = "#02d13c";
    } else result = "#F0F2F5";

    if (timeIndex % 2 == 0) {
      if (result == "#F0F2F5") result = "#F9FAFB";
      else result += "EE";
    }
    return result;
  }

  GetRowHeight(): void {
    if (this.tabela != null) {
      var tableHeight = this.tabela.offsetHeight;
      var totalRowInTable = this.times.length - this.starttime;

      var trTagHeight = tableHeight / totalRowInTable;
      if (trTagHeight != this.rowHeight) this.rowHeight = trTagHeight;
    }
  }

  GetRowWidth(): void {
    if (this.tabela != null) {
      var tableWidth = this.tabela.offsetWidth;
      var trTagWidth = (0.92 * tableWidth) / 7;
      if (trTagWidth != this.rowWidth) this.rowWidth = trTagWidth;
    }
  }

  GetBookings() {
    this.bookingService
      .getBookingsForSpace(this.spaceId)
      .subscribe((success) => {
        if (success) {
          if (this.workshopBookingService.workshop != null) {
            this.workshopBookingService
              .getBookingsForWorkshop(this.workshopBookingService.workshop.id)
              .subscribe((success) => {
                if (success) {
                  this.Initialize();
                } else {
                  console.error("Error");
                }
              });
          } else {
            this.Initialize();
          }
        } else {
          console.error("Error");
        }
      });
  }

  Initialize(weekChanged: boolean = false) {
    if (!weekChanged) {
      // WAS THIS
      //for (let selection of this.GetAllVisibleSelections())
      //    this.selections.push(selection);
    }

    this.bookingSegments = new Array<Array<BookingSegment>>();
    for (var d = 0; d < 7; d++) {
      var tempSegmentDay = new Array<BookingSegment>();
      for (var t = 0; t < 23; t++) {
        tempSegmentDay.push(null);
        tempSegmentDay.push(null);
      }
      this.bookingSegments.push(tempSegmentDay);
    }

    var defaultCellState =
      this.workingHours != null || this.useSpaceWorkngHours ? 1 : 0;

    this.status = new Array<Array<number>>();
    for (var d = 0; d < 7; d++) {
      var tempday2 = new Array<number>();
      for (var t = 0; t < 23; t++) {
        tempday2.push(defaultCellState);
        tempday2.push(defaultCellState);
      }
      this.status.push(tempday2);
    }

    if (this.workingHours != null)
      for (var i = 0; i < 7; i++) {
        this.SetWorkingHours(
          i,
          this.workingHours.starttimes[i],
          this.workingHours.endtimes[i]
        );
        if (!this.workingHours.workingdays[i]) this.SetNonWorkingDay(i);
      }

    //console.log("BS (lol): " + JSON.stringify(this.bookingService.bookingSegments));
    this.MarkBookings();
    this.rebuildDraggables();
  }

  rebuildDraggables() {
    this.draggables = [];
    if (this.workshopTimeAllocations != null)
      for (var i = 0; i < this.workshopTimeAllocations.length; i++) {
        this.createDraggableForTimeAllocation(this.workshopTimeAllocations[i]);
      }
    this.customDraggable = null;
    if (this.customTimeAllocation != null) {
      this.customDraggable = this.createDraggableForTimeAllocation(
        this.customTimeAllocation
      );
      this.customDraggable.isCustom = true;
    }
  }

  isAvailable(dayIndex: number, timeIndex: number, duration: number): boolean {
    console.log("is available", dayIndex, timeIndex, duration);

    if (this.GetDateForSlot(dayIndex, timeIndex) < this.GetDateToday())
      return false;
    var available = true;
    if (this.status[dayIndex]) {
      for (
        var i = 0;
        i < duration && timeIndex + i < this.status[dayIndex].length;
        i++ // treba me udaviti [L]
      ) {
        available = available && !this.status[dayIndex][timeIndex + i];
      }
    }
    return available;
  }

  GetDateToday(): Date {
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  createDraggableForTimeAllocation(
    allocation: WorkshopTimeAllocation
  ): Draggable {
    var startTime = new Date(allocation.startTime);
    var endTime = new Date(allocation.endTime);
    var duration =
      (endTime.getHours() - startTime.getHours()) * 2 +
      (endTime.getMinutes() - startTime.getMinutes()) / 30;
    var nextMonday = new Date(this.mondayDate);
    nextMonday.setDate(nextMonday.getDate() + 7);
    if (
      (startTime > this.mondayDate || allocation.isRecurring) &&
      startTime < nextMonday
    ) {
      var dayIndex = startTime.getDay();
      dayIndex--;
      if (dayIndex < 0) dayIndex = 6;
      var timeIndex = startTime.getHours() * 2 - this.starttime;
      if (startTime.getMinutes() > 0) timeIndex++;

      var actualStartDate = this.GetDateForSlot(
        dayIndex,
        timeIndex + this.starttime
      );
      var actualEndDate = this.GetDateForSlot(
        dayIndex,
        timeIndex + duration + this.starttime
      );
      var actualAllocation = new WsBookingTimeAllocation();
      actualAllocation.startTime = actualStartDate.toLocaleString("en-US");
      actualAllocation.endTime = actualEndDate.toLocaleString("en-US");

      var newDraggable = new Draggable(dayIndex, timeIndex, duration, this);
      this.draggables.push(newDraggable);
      newDraggable.timeAllocation = allocation;
      newDraggable.availableSeats =
        this.workshopBookingService.GetAvailableSlotsForAllocation(
          actualAllocation
        );
      if (!newDraggable.invalidInstance)
        this.setSlots(dayIndex, timeIndex + this.starttime, duration, true);
      return newDraggable;
    }
  }

  //ovo za startTimeIncluded je odvratno
  setSlots(
    dayIndex: number,
    timeIndex: number,
    duration: number,
    occupied: boolean
  ) {
    for (var i = 0; i < duration; i++) {
      //console.log("D" + dayIndex + " T" + (timeIndex + i) + "=" + occupied);
      this.status[dayIndex][timeIndex + i] = occupied;
    }
  }

  MouseDownTable(e) {
    if (e.touches != null) {
      this.mouseX = e.touches[0].pageX;
      this.mouseY = e.touches[0].pageY;
    }
    var target;
    if (e.center == null) {
      if (e.touches != null)
        target = document.elementFromPoint(
          e.touches[0].clientX,
          e.touches[0].clientY
        ).id;
      else target = document.elementFromPoint(e.clientX, e.clientY).id;
    } else {
      if (isNaN(e.center.x) || isNaN(e.center.y)) return;

      target = document.elementFromPoint(e.center.x, e.center.y).id;
    }
    var strd = target.indexOf("d");

    var timeIndex = parseInt(target.substring(1, strd));
    var dayIndex = parseInt(target.substring(strd + 1, target.length));

    this.lastCellX = dayIndex;
    this.lastCellY = timeIndex;
  }

  GetDateForSlot(dayIndex: number, timeIndex: number): Date {
    var hours = Math.floor(timeIndex / 2);
    var minutes = (timeIndex % 2) * 30;
    var result = new Date(
      this.mondayDate.getFullYear(),
      this.mondayDate.getMonth(),
      this.mondayDate.getDate() + dayIndex,
      hours,
      minutes
    );
    return result;
  }

  panStart(ev) {
    ev.preventDefault();
    if (!this.mousedown) {
      this.scrolling = false;
      this.scrollPending = true;
      this.startTouchDist = 0;
      this.scrollStartTop = this.tableRef.nativeElement.scrollTop;
      this.dragStartX = ev.touches[0].clientX;
      this.dragStartY = ev.touches[0].clientY;
      window.setTimeout(() => {
        if (this.scrollPending) {
          this.scrollPending = false;
          if (this.startTouchDist < this.maxDragDist) this.MouseDownTable(ev);
          else this.scrolling = true;
        }
      }, 100);
    }
  }

  MarkBookings() {
    if (this.bookingService.bookingSegments != null)
      for (let bookingSegment of this.bookingService.bookingSegments) {
        var nextMonday = new Date(this.mondayDate);
        nextMonday.setDate(nextMonday.getDate() + 7);
        if (
          bookingSegment.startTime > this.mondayDate &&
          bookingSegment.startTime < nextMonday
        ) {
          var dayIndex = bookingSegment.startTime.getDay();
          dayIndex--;
          if (dayIndex < 0) dayIndex = 6;
          var timeIndex = bookingSegment.startTime.getHours() * 2;
          if (bookingSegment.startTime.getMinutes() > 0) timeIndex++;
          var count = bookingSegment.duration.Hours * 2;
          if (bookingSegment.duration.Minutes > 0) count++;
          for (var i = 0; i < count; i++) {
            this.status[dayIndex][i + timeIndex] = 2;
          }
          this.bookingSegments[dayIndex][timeIndex] = bookingSegment;
        }
      }
    //console.log("Ufff.." + JSON.stringify(this.workshopBookingService.existingWorkshopBookings));
    if (this.workshopBookingService.existingWorkshopBookings != null)
      for (let workshopBooking of this.workshopBookingService
        .existingWorkshopBookings) {
        if (
          workshopBooking.timeAllocation != null &&
          workshopBooking.timeAllocation.isCustom
        ) {
          var wsTimeAllocation = workshopBooking.timeAllocation;
          var wsStarTime = new Date(wsTimeAllocation.startTime);
          var wsEndTime = new Date(wsTimeAllocation.endTime);
          var wsDuration = TimeSpan.Diff(wsStarTime, wsEndTime);
          var nextMonday = new Date(this.mondayDate);
          nextMonday.setDate(nextMonday.getDate() + 7);
          if (wsStarTime > this.mondayDate && wsStarTime < nextMonday) {
            var dayIndex = wsStarTime.getDay();
            dayIndex--;
            if (dayIndex < 0) dayIndex = 6;
            var timeIndex = wsStarTime.getHours() * 2;
            if (wsStarTime.getMinutes() > 0) timeIndex++;
            var count = wsDuration.Hours * 2;
            if (wsDuration.Minutes > 0) count++;
            for (var i = 0; i < count; i++) {
              this.status[dayIndex][i + timeIndex] = 1;
            }
            //this.bookingSegments[dayIndex][timeIndex] = wsTimeAllocation;
          }
        }
      }
  }

  ngAfterViewInit() {
    this.getEnv();
    this.stepper.selectedIndex = this.spaceService.selectedIndex;
    this.tableRef.nativeElement.addEventListener(
      "mousemove",
      this.mouseMoveResponder,
      { passive: true, capture: true }
    );
    this.tableRef.nativeElement.addEventListener(
      "touchmove",
      this.mouseMoveResponder,
      { passive: true, capture: true }
    );
  }

  ngDoCheck() {
    this.GetHeadWidth();
    this.GetRowHeight();
    this.GetRowWidth();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.spaceId != null) {
      if (this.getBookingsOnInit) this.GetBookings();
      if (this.useSpaceWorkngHours) this.GetWorkingHours();
    }
  }

  ngOnDestroy(): void {
    document.body.removeEventListener("mouseup", this.mouseUpResponder);
    document.body.removeEventListener("mousemove", this.mouseMoveResponder);
  }

  ngAfterViewChecked() {
    if (!this.startScrollPosSet) {
      this.startScrollPosSet = true;
      document.getElementById("scrollable").scrollTop =
        ((12 - this.starttime) / 3) * 100;
    }
  }

  SetWorkingHours(dayIndex: number, startTime: TimeSpan, endTime: TimeSpan) {
    var startIndex = startTime.Hours * 2;
    if (startTime.Minutes > 1) startIndex++;
    var endIndex = endTime.Hours * 2;
    if (endTime.Minutes > 1) endIndex++;
    if (!this.markWorkingHours) {
      startIndex = 0;
      endIndex = 46;
    }
    for (var i = startIndex; i < endIndex; i++) {
      this.status[dayIndex][i] = 0;
    }
  }

  SetNonWorkingDay(dayIndex: number) {
    for (var i = 0; i < 46; i++) {
      this.status[dayIndex][i] = 1;
    }
  }

  preloadValues() {
    this.mySpace = this.spaceService.spaceToEdit;
    this.mySpace.workingHours = WorkingHours.Default();
    this.propertyID = this.mySpace.propertyId;
    for (var i = 0; i < this.mySpace.spaceTypeIds.length; i++) {
      this.selectedSpaceTypes[this.mySpace.spaceTypeIds[i] - 1] = true;
    }
    var amenities = new Array();
    for (var i = 0; i < this.mySpace.amenities.length; i++) {
      var index = -1;
      for (var a = 0; a < this.spaceService.amenityTypes.length; a++) {
        if (
          this.spaceService.amenityTypes[a].id ==
          this.mySpace.amenities[i].amenitytype.id
        ) {
          if (this.mySpace.amenities[i].amenitytype.isGlobal) index = a;
        }
      }
      if (index == -1) {
        console.error("index error");
        if (this.mySpace.amenities[i].amenitytype.isCatering) {
          if (this.customCatering == null) this.customCatering = [];
          this.customCatering.push(this.mySpace.amenities[i]);
        } else {
          if (this.customAmenities == null) this.customAmenities = [];
          this.customAmenities.push(this.mySpace.amenities[i]);
        }
      } else {
        this.selectedAmenities[index] = true;
        this.amenityPrice[index] = this.mySpace.amenities[i].price;
        this.amenityIsOptional[index] = this.mySpace.amenities[i].isoptional;
        this.amenityHasAdjustableQuantity[index] =
          this.mySpace.amenities[i].hasadjustablequantity;
        this.amenityPricePerPerson[index] =
          this.mySpace.amenities[i].priceperperson;
        this.amenityDocumentUrl[index] = this.mySpace.amenities[i].documenturl;
      }
    }

    for (var i = 0; i < this.mySpace.sittingArangements.length; i++) {
      var index = -1;
      for (var a = 0; a < this.spaceService.sittingTypes.length; a++) {
        if (
          this.spaceService.sittingTypes[a].id ==
          this.mySpace.sittingArangements[i].type.id
        )
          index = a;
      }
      if (index == -1) console.error("index error");
      else {
        this.selectedSittingType[index] = true;
        this.sittingNumberOfSeats[index] =
          this.mySpace.sittingArangements[i].numberofseats;
      }
    }
    /// this.mySpace.images = this.formData; <- sta sa ovim?

    for (var i = 0; i < this.availablePricingRules.length; i++) {
      for (let rule of this.mySpace.pricingRules) {
        if (rule.type.id == this.availablePricingRules[i].type.id) {
          this.availablePricingRules[i] = PricingRule.FromDTO(rule);
        }
      }
    }

    this.cancellationRules = this.mySpace.cancellationRules;
    this.cancellationRules.sort((a, b) => {
      if (a.daysFrom < b.daysFrom) return -1;
      if (a.daysFrom > b.daysFrom) return 1;
      return 0;
    });

    this.spaceService
      .getWorkingHours(this.mySpace.spaceId)
      .subscribe((success) => {
        this.mySpace.workingHours = WorkingHours.FromDTO(success);
        this.nonworkingCalendar.workingHours = this.mySpace.workingHours;

        this.nonworkingCalendar.SetSelectionsToTimeAllocations(
          this.mySpace.closedHours
        );
        this.nonworkingCalendar.Initialize();
      });
  }

  checkSpaceTypes(): boolean {
    return this.selectedSpaceTypes.some((e) => (e === true ? true : false));
  }

  clickContinue(isValid: boolean) {
    this.initialise();
    console.log("form1Valid", isValid);
    if (!isValid) return;

    this.formPageIndex++;
  }

  checkPricingRules() {
    let state = true;
    let count = 0;
    for (let e of this.availablePricingRules) {
      if (e.selected) {
        if (this.pricingType === "hourly") {
          if (e.displayStartTime && e.displayEndTime && e.price) {
            continue;
          } else {
            state = false;
            break;
          }
        } else if (this.pricingType === "fixed") {
          if (e.price) {
            continue;
          } else {
            state = false;
            break;
          }
        }
      } else {
        ++count;
        continue;
      }
    }
    if (count === 6) {
      state = false;
    }
    return state;
  }

  checkCancellationRules() {
    let state = true;

    for (let c of this.cancellationRules) {
      if (c.daysFrom > 0 && c.daysTo >= 0 && c.percentageToReturn) {
        continue;
      } else {
        state = false;
        break;
      }
    }
    return state;
  }

  returnSelectedIndex(arr: boolean[]) {
    let indexArr = [];
    this.selectedSittingType.forEach((e, index) => {
      if (e) {
        indexArr.push(index);
      }
    });
    return indexArr;
  }

  validateSittingArrangement(): boolean {
    let state = true;
    console.log(
      "validate sitting",
      this.sittingNumberOfSeats,
      this.selectedSittingType
    );
    console.log(
      "total validate sitting",
      this.returnSelectedIndex(this.selectedSittingType)
    );

    for (let e of this.returnSelectedIndex(this.selectedSittingType)) {
      console.log("e of sitting", e);

      if (
        !this.sittingNumberOfSeats[e] ||
        this.sittingNumberOfSeats[e] < this.mySpace.minNumberOfGuests ||
        this.sittingNumberOfSeats[e] > this.mySpace.maxNumberOfGuests
      ) {
        state = false;
        break;
      } else {
        continue;
      }
    }

    return state;
  }

  resetErrors() {
    this.errors = [];
    this.typeError = "";
  }

  goForward(step: string) {
    const validator = new Validator(this.mySpace);
    this.errors = validator[step]();
    if (step === "step2") {
      const state = this.checkSpaceTypes();
      if (state) {
        this.resetErrors();
        this.stepper.next();
      } else {
        this.typeError = "Please add space type";
      }
    } else if (step === "step6" && !this.errors.length) {
      if (!this.validateSittingArrangement()) {
        this.errors.push(
          "Please fill the Number of seats correctly, it should be grater than or equal to the min number of seats and less than or equal to  max number of seats"
        );
      } else {
        this.resetErrors();
        this.stepper.next();
      }
    } else if (step === "step7" && !this.errors.length) {
      if (!this.checkPricingRules()) {
        this.typeError =
          "Please add pricing Rules properly fill out the start and end time and the price!";
      } else {
        this.resetErrors();
        this.stepper.next();
      }
    } else if (step === "step8" && !this.errors.length) {
      if (!this.checkCancellationRules() || !this.cancellationRules.length) {
        this.typeError =
          "Please properly add from days, to days and percentage to return in cancellation rule!";
      } else {
        this.resetErrors();
        this.onSubmit();
      }
    } else if (!this.errors.length) {
      this.resetErrors();
      this.stepper.next();
    }
  }

  clickContinueDescription() {
    if (this.mySpace.description != null && this.mySpace.description != "") {
      this.formPageIndex++;
    }
  }

  clickBack() {
    if (this.formPageIndex > 0) this.formPageIndex--;
  }

  ignore() {}

  // onImageUploaded(file: File) {

  //     this.formData.append(file.name, file);
  //     this.hasImages = true;
  //     this.currentImgNo++;
  //     //this.mySpace.images.push(formData);
  // }

  onImageUploaded(event) {
    this.formData = new FormData();
    for (let i = 0; event.files.length > i; i++) {
      this.formData.append(event.files[i].name, event.files[i]);
    }

    this.hasImages = true;
    this.mySpace.images = this.formData;
    this.localImages = event.urls;
  }

  deleteUploadedPhoto(url) {
    if (confirm("Are you sure you want to delete this image?")) {
      const index = this.mySpace.photoUrls.indexOf(url);
      this.mySpace.photoUrls.splice(index, 1);
    }
  }

  onPaste(event) {
    event.preventDefault();
    event.stopPropagation();
    const plaintext = event.clipboardData.getData("Text");
    document.execCommand("inserttext", false, plaintext);
  }

  deleteLocalPhoto(url: string) {
    if (this.mySpace.images) {
      this.mySpace.images.delete(url);
      let imgCount = 0;
      this.mySpace.images.forEach((a) => {
        imgCount++;
      });
      if (imgCount === 0) {
        this.hasImages = false;
      }
    }
  }

  onDocumentUploaded(docObj: DocumentUpload) {
    //console.log("doc url asc", docObj.url);
    //console.log("doc url asc", docObj.amenityType.name);
    this.SetDocumentUrl(docObj.amenityType, docObj.url);
  }

  onCustomDocumentUploaded(docObj: AmenityDocumentUpload) {
    this.SetCustomDocumentUrl(docObj.amenity, docObj.url);
  }

  public SetPerPerson(p: AmenityType) {
    //console.log("Set PPP on " + this.spaceService.amenityTypes.indexOf(p));
    this.amenityPricePerPerson[this.spaceService.amenityTypes.indexOf(p)] =
      true;
  }

  public SetFixed(p: AmenityType) {
    //console.log("Set FIX on " + this.spaceService.amenityTypes.indexOf(p));
    this.amenityPricePerPerson[this.spaceService.amenityTypes.indexOf(p)] =
      false;
  }

  public SetCustomPerPerson(p: Amenity) {
    //console.log("Set PPP on " + this.spaceService.amenityTypes.indexOf(p));
    p.priceperperson = true;
  }

  public SetCustomFixed(p: Amenity) {
    //console.log("Set FIX on " + this.spaceService.amenityTypes.indexOf(p));
    p.priceperperson = false;
  }

  public SetDocumentUrl(p: AmenityType, url: string) {
    //console.log("Set FIX on " + this.spaceService.amenityTypes.indexOf(p));
    this.amenityDocumentUrl[this.spaceService.amenityTypes.indexOf(p)] = url;
  }

  public SetCustomDocumentUrl(p: Amenity, url: string) {
    //console.log("Set FIX on " + this.spaceService.amenityTypes.indexOf(p));
    p.documenturl = url;
  }

  public GetSelectedSpaceTypes(): SpaceType[] {
    var results = new Array();
    for (var i = 0; i < this.selectedSpaceTypes.length; i++) {
      if (this.selectedSpaceTypes[i])
        results.push(this.spaceService.spaceTypes[i]);
    }
    return results;
  }

  public GetSittingArrangements(): string[] {
    var results = new Array();
    for (var i = 0; i < this.selectedSittingType.length; i++) {
      if (this.selectedSittingType[i])
        results.push(
          this.spaceService.sittingTypes[i].name +
            " (" +
            this.sittingNumberOfSeats[i] +
            ")"
        );
    }
    return results;
  }

  public GetWorkingHours(): string[] {
    var results = new Array();
    for (var i = 0; i < 7; i++) {
      if (this.mySpace.workingHours.workingdays[i])
        results.push(
          this.WeekDays[i] +
            ": " +
            this.mySpace.workingHours.starttimes[i].HoursMinutesString +
            "-" +
            this.mySpace.workingHours.endtimes[i].HoursMinutesString
        );
      else results.push(this.WeekDays[i] + ": CLOSED");
    }
    return results;
  }

  public GetDayHeading(i: number) {
    if (this.scrWidth >= 992) return this.WeekDays[i];
    else return this.WeekDays[i].substring(0, 1);
  }

  scrHeight: any;
  scrWidth: any;

  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  public GetFixedAmenities(): string[] {
    var results = new Array();
    for (var i = 0; i < this.selectedAmenities.length; i++) {
      if (
        this.selectedAmenities[i] &&
        !this.amenityIsOptional[i] &&
        !this.spaceService.amenityTypes[i].isCatering
      )
        results.push(this.spaceService.amenityTypes[i].name);
    }
    return results;
  }

  public GetOptionalAmenities(): string[] {
    var results = new Array();
    for (var i = 0; i < this.selectedAmenities.length; i++) {
      if (this.selectedAmenities[i] && this.amenityIsOptional[i])
        results.push(
          this.spaceService.amenityTypes[i].name +
            (this.amenityPrice[i] > 0
              ? " (" +
                this.amenityPrice[i] +
                " CHF" +
                (this.amenityHasAdjustableQuantity[i] ? " per item" : "") +
                ")"
              : " (Free)")
        );
    }
    return results;
  }

  public GetCaterings(): string[] {
    var results = new Array();
    for (var i = 0; i < this.selectedAmenities.length; i++) {
      if (
        this.selectedAmenities[i] &&
        this.spaceService.amenityTypes[i].isCatering
      )
        results.push(
          this.spaceService.amenityTypes[i].name +
            (this.amenityPrice[i] > 0
              ? " (" +
                this.amenityPrice[i] +
                " CHF" +
                (this.amenityPricePerPerson[i] ? " per person" : "") +
                ")"
              : " (Free)")
        );
    }
    return results;
  }

  public minMaxValidation(isValidForm: boolean): void {
    if (!isValidForm) return;

    if (this.mySpace.minNumberOfGuests > this.mySpace.maxNumberOfGuests) {
      this.hasMinMaxGuestError = true;
      return;
    }

    this.hasMinMaxGuestError = false;
    this.formPageIndex++;
  }

  public minMaxRecommendedValidation(isValidForm: boolean): void {
    if (!isValidForm) return;

    if (this.mySpace.recomendedGuestsFrom > this.mySpace.recomendedGuestsTo) {
      this.hasRecommendedGuestError = true;
      return;
    }

    this.hasRecommendedGuestError = false;
    this.formPageIndex++;
  }

  //pricing rules part

  addPricingRuleModal() {
    console.log("images>>>>", this.mySpace.images);
    // const modalRef = this.modalService.open(ModalComponent);
    //console.log(this.availablePricingRules)
    const modalRef = this.modalService.open(AddRuleModal);
    modalRef.componentInstance.title = "Add / remove pricing rules";
    modalRef.componentInstance.availablePricingRules =
      this.availablePricingRules;
    modalRef.componentInstance.type = this.pricingType;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      this.availablePricingRules = receivedEntry;
    });
  }

  removePricingRule(rule: PricingRule) {
    rule.selected = false;
  }

  //order of pricing rules must be forced
  validatePricingRules(isValidForm: boolean): void {
    if (!isValidForm) return;

    this.pricingRulesErrors = [];

    for (var i = 0; i < this.availablePricingRules.length; i++) {
      let rule = this.availablePricingRules[i];

      if (rule.selected && !rule.price) {
        this.pricingRulesErrors.push(rule.type.name + " price is required");
      }

      if (rule.selected && rule.type.hasPeriod) {
        if (!rule.endTime || !rule.startTime) {
          this.pricingRulesErrors.push(
            rule.type.name + " start date and end date are required"
          );
          break;
        }
        if (rule.startTime.totalseconds >= rule.endTime.totalseconds) {
          this.pricingRulesErrors.push(
            rule.type.name + " start time must be less then its end time"
          );
        }

        for (var j = 0; j < i; j++) {
          let previousRule = this.availablePricingRules[j];
          if (previousRule.selected && previousRule.type.hasPeriod) {
            if (
              rule.startTime.totalseconds < previousRule.endTime.totalseconds
            ) {
              this.pricingRulesErrors.push(
                rule.type.name +
                  " start time can not be less then " +
                  previousRule.type.name +
                  " end time"
              );
            }
          }
        }
      }
    }

    if (this.pricingRulesErrors.length > 0) return;

    this.formPageIndex++;
  }
  //end pricing rules part

  //cancellation rules part
  addCancellationRule(): void {
    let daysTo: number =
      this.cancellationRules.length > 0
        ? this.cancellationRules[this.cancellationRules.length - 1].daysFrom
        : 0;
    let newRule = new CancellationRule();
    newRule.daysTo = daysTo;
    this.cancellationRules.push(newRule);
  }

  removeCancellationRule(index: number): void {
    if (index != this.cancellationRules.length - 1) return;
    this.cancellationRules.splice(index, 1);
  }

  daysFromChanged(index: number): void {
    if (index == this.cancellationRules.length - 1) return;

    this.cancellationRules[index + 1].daysTo =
      this.cancellationRules[index].daysFrom;
  }

  validateCancellationRules(isValid: boolean): void {
    if (!isValid) return;

    let invalidData: boolean = false;
    this.cancellationRules.forEach(function (rule) {
      if (
        rule.daysFrom <= rule.daysTo ||
        rule.percentageToReturn < 0 ||
        rule.percentageToReturn > 100
      )
        invalidData = true;
    });

    if (invalidData) return;
    this.formPageIndex++;
  }
  //end cancellation rules part

  addCustomAmenity() {
    var newCustomAmType = new AmenityType();
    var nextNegativeIndex = -1;
    if (this.customAmenities != null)
      for (var cam of this.customAmenities) {
        if (cam.amenitytype.id <= nextNegativeIndex) {
          nextNegativeIndex = cam.amenitytype.id - 1;
        }
      }
    newCustomAmType.id = nextNegativeIndex;

    newCustomAmType.isCatering = false;
    var newCustomAmenity = new Amenity(
      null,
      newCustomAmType,
      false,
      false,
      false,
      null
    );
    if (this.customAmenities == null) this.customAmenities = [];
    this.customAmenities.push(newCustomAmenity);
  }

  addCustomCatering() {
    var newCustomAmType = new AmenityType();
    var nextNegativeIndex = -1;
    if (this.customCatering != null)
      for (var cam of this.customCatering) {
        if (cam.amenitytype.id <= nextNegativeIndex) {
          nextNegativeIndex = cam.amenitytype.id - 1;
        }
      }
    newCustomAmType.id = nextNegativeIndex;

    newCustomAmType.isCatering = true;
    var newCustomAmenity = new Amenity(
      null,
      newCustomAmType,
      false,
      false,
      false,
      null
    );
    if (this.customCatering == null) this.customCatering = [];
    this.customCatering.push(newCustomAmenity);
  }

  removeCustomAmenity(amenity: Amenity) {
    this.customAmenities.splice(this.customAmenities.indexOf(amenity), 1);
  }

  removeCustomCatering(catering: Amenity) {
    this.customCatering.splice(this.customCatering.indexOf(catering), 1);
  }

  findBootstrapEnvironment(): string {
    let envs = ["xs", "sm", "md", "lg", "xl"];

    let el = document.createElement("div");
    document.body.appendChild(el);

    let curEnv = envs.shift();

    for (let env of envs.reverse()) {
      el.classList.add(`d-${env}-none`);

      if (window.getComputedStyle(el).display === "none") {
        curEnv = env;
        break;
      }
    }

    document.body.removeChild(el);
    return curEnv;
  }

  formatTimeText(time: string) {
    if (this.env == "xs") return time.replace(":", "\n");
    return time;
  }

  NextWeek() {
    if (!this.isDayCalendar || this.dayIndex == 6) {
      this.mondayDate.setDate(this.mondayDate.getDate() + 7);
      this.dayIndex = 0;
      this.Initialize(true);
    } else {
      this.dayIndex++;
    }
  }

  PreviousWeek() {
    if (!this.isDayCalendar || this.dayIndex == 0) {
      this.mondayDate.setDate(this.mondayDate.getDate() - 7);
      this.dayIndex = 6;
      this.Initialize(true);
    } else {
      this.dayIndex--;
    }
  }

  GetCaption(): string {
    var monthIndex = this.mondayDate.getMonth();
    console.log("monthIndex", monthIndex);
    if (this.isDayCalendar) {
      var date = new Date(this.mondayDate);
      date.setDate(date.getDate() + this.dayIndex);
      monthIndex = date.getMonth();
    }
    return this.monthNames[monthIndex] + " " + this.mondayDate.getFullYear();
  }

  GetDate(index: number) {
    console.log("index", index);
    var env = this.findBootstrapEnvironment();
    var date = new Date(this.mondayDate);
    date.setDate(date.getDate() + index);
    if (env == "sm" || env == "md")
      return this.monthAbbrevs[date.getMonth()] + " " + date.getDate();
    else if (env == "xs") return date.getDate();
    else return date.getDate();
  }

  GetDayName(index: number) {
    var env = this.findBootstrapEnvironment();
    if (env == "xs") return this.weekdaysLetters[index];
    if (env == "sm" || env == "md") return this.weekdaysAbrev[index];
    return this.weekdays[index];
  }

  deleteDraggable(draggable: Draggable) {
    var timeslotIndex = this.workshopTimeAllocations.indexOf(
      draggable.timeAllocation
    );
    if (timeslotIndex >= 0)
      this.workshopTimeAllocations.splice(timeslotIndex, 1);
    this.draggables.splice(this.draggables.indexOf(draggable), 1);
  }

  selectDraggable(draggable: Draggable) {
    console.log(draggable, "select draggable");

    if (draggable != this.customDraggable) {
      console.log("not equal");

      if (this.customDraggable != null) {
        this.customDraggable.delete();
        this.customDraggable = null;
      }
      this.customTimeAllocation = null;
    }
    if (draggable.isBookable) {
      var allocation = new WsBookingTimeAllocation();
      var startTime = draggable.occurenceDate;
      var endTime: Date;
      endTime = new Date(startTime);
      var hours = startTime.getHours() + Math.floor(draggable.duration / 2);
      var minutes =
        startTime.getMinutes() + ((draggable.duration / 2) % 1) * 30;
      while (minutes >= 60) {
        minutes -= 60;
        hours++;
      }
      endTime.setHours(hours);
      endTime.setMinutes(minutes);

      allocation.allocationType = 1;
      allocation.startTime = startTime.toLocaleString("en-US");
      allocation.endTime = endTime.toLocaleString("en-US");
      allocation.isCustom = this.customTimeAllocation != null;

      this.currentSelectedTimeAllocation = allocation;
    }
    //console.log(draggable.occurenceDate.toString());
  }

  submitData() {
    if (this.spaceService.editMode) {
      // this.addEntertainmentService.updateCancellationRules(this.cancellationRules);
      // this.addEntertainmentService.updateEntertainment().subscribe((response) => {
      //     console.log(response,"edit done");
      //     this.router.navigate(['/add-entertainment-preview']);
      //     this.addEntertainmentService.editMode = false;
      // })
    } else {
      // this.addEntertainmentService.setCancellationRules(this.cancellationRules);
      // this.addEntertainmentService.addRules()
      //     .subscribe((response:number) =>{
      //         console.log(response);
      //     })
      // }
      this.router.navigate(["/add-event-space-preview"]);
    }
  }

  onSubmit() {
    this.submissionService.startSubmission();
    this.spaceService.selectedNonWorkingHours =
      this.nonworkingCalendar.GetAllSelections();
    var spacetypeids = new Array();
    this.mySpace.propertyId = this.propertyID;
    for (var i = 0; i < this.selectedSpaceTypes.length; i++) {
      if (this.selectedSpaceTypes[i]) spacetypeids.push(i + 1);
    }
    this.mySpace.spaceTypeIds = spacetypeids;

    var amenities = new Array();
    for (var i = 0; i < this.selectedAmenities.length; i++) {
      if (this.selectedAmenities[i]) {
        var amenity = new Amenity(
          this.amenityPrice[i],
          this.spaceService.amenityTypes[i],
          this.amenityIsOptional[i],
          this.amenityHasAdjustableQuantity[i],
          this.amenityPricePerPerson[i],
          this.amenityDocumentUrl[i]
        );

        amenities.push(amenity);
      }
    }

    if (this.customCatering != null)
      for (var i = 0; i < this.customCatering.length; i++) {
        amenities.push(this.customCatering[i]);
      }

    if (this.customAmenities != null)
      for (var i = 0; i < this.customAmenities.length; i++) {
        amenities.push(this.customAmenities[i]);
      }
    this.mySpace.amenities = amenities;

    var sittings = new Array();
    for (var i = 0; i < this.selectedSittingType.length; i++) {
      if (this.selectedSittingType[i]) {
        var sitting = new SittingArangement(
          this.sittingNumberOfSeats[i],
          this.spaceService.sittingTypes[i]
        );
        sittings.push(sitting);
      }
    }

    this.mySpace.sittingArangements = sittings;
    // this.mySpace.images = this.formData;

    this.mySpace.pricingRules = [];
    for (var i = 0; i < this.availablePricingRules.length; i++) {
      if (this.availablePricingRules[i].selected) {
        this.mySpace.pricingRules.push(this.availablePricingRules[i]);
      }
    }
    this.mySpace.cancellationRules = this.cancellationRules;

    this.spaceService.space = this.mySpace;

    if (!this.editMode) {
      this.spaceService.addSpace(this.hasImages).subscribe(
        (newId: number) => {
          this.submissionService.endSubmission();
          // this.router.navigate(['/success-add-page/' + this.propertyID + '/' + newId]);
          this.router.navigate(["/space-details/" + newId]);
        },
        (error) => {
          this.errorMessage = error;
        }
      );
      // this.spaceService.addSpace(this.hasImages).
      //     subscribe(
      //        ( newId :number) => {
      //             this.router.navigate(['/add-event-space-preview/' + this.propertyID + '/' + newId]);
      //         },
      //         error => {
      //             this.errorMessage = error
      //         }
      //     )
    } else {
      this.spaceService.editSpace(this.hasImages).subscribe(
        (newId) => {
          this.submissionService.endSubmission();
          this.router.navigate(["/space-details/" + this.mySpace.spaceId]);
        },
        (error) => {
          this.errorMessage = error;
          console.error(error);
        }
      );
    }
  }
}

class Validator {
  constructor(private mySpace: EbentoSpace) {}

  public step1(): string[] {
    let errors: string[] = [];
    try {
      if (this.mySpace === null) {
        throw new Error("Please fill the details");
      } else {
        if (Object.keys(this.mySpace).length >= 4) {
          if (!this.mySpace.name) {
            throw new Error("Please fill name");
          } else if (!this.mySpace.description) {
            throw new Error("Space Description is required");
          } else if (!this.mySpace.floorSizeSqm) {
            throw new Error("Floor size is required");
          } else if (!this.mySpace.images && !this.mySpace.photoUrls) {
            throw new Error("Please add images!");
          }
        } else {
          throw new Error("Please fill the details!");
        }
      }
    } catch (error) {
      errors.push(error);
    }

    return errors;
  }

  public step2(): string[] {
    let errors: string[] = [];
    // console.log("my space step2",this.mySpace);
    return errors;
  }

  public step4(): string[] {
    let errors: string[] = [];
    // console.log("my space step4",this.mySpace);
    if (!this.mySpace.workingHours) {
      errors.push("Please add Available Time!");
    }
    return errors;
  }

  public step5(): string[] {
    let errors: string[] = [];

    if (!this.mySpace.bookingType) {
      errors.push("Please add booking type!");
    } else if (!this.mySpace.covidMeasures) {
      errors.push("Please add covid measures!");
    }

    return errors;
  }

  public step6(): string[] {
    let errors: string[] = [];
    try {
      if (this.mySpace.minNumberOfGuests && this.mySpace.maxNumberOfGuests) {
        if (this.mySpace.minNumberOfGuests > this.mySpace.maxNumberOfGuests) {
          throw new Error("Min should be less than Max number of guests");
        }
      } else {
        throw new Error("Please fill Min and Max number of guests");
      }
    } catch (error) {
      errors.push(error);
    }
    return errors;
  }

  public step7(): string[] {
    let errors: string[] = [];

    try {
      // if (!this.mySpace.pricePerHour) {
      //   throw new Error("Please fill Price Per hour!");
      // }
    } catch (error) {
      errors.push(error);
    }
    return errors;
  }

  public step8(): string[] {
    let errors: string[] = [];

    return errors;
  }
}
class Draggable {
  duration: number; // duration expressed in cell count
  get label(): string {
    return (
      this.tableRef.times[this.tempTableY + this.tableRef.starttime] +
      " - " +
      this.tableRef.times[
        this.tempTableY + this.tableRef.starttime + this.duration
      ]
    );
  }

  get originalOcurrenceDate(): string {
    var startDate = new Date(this.timeAllocation.startTime);
    return startDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  }

  get occurenceDate(): Date {
    return this.tableRef.GetDateForSlot(
      this.tableX,
      this.tableY + this.tableRef.starttime
    );
  }

  isCustom: boolean;
  tableRef: AddSpaceForm;
  availableSeats: number;
  tableX: number;
  tableY: number;
  isDragged: boolean;
  invalidInstance: boolean;
  timeAllocation: WorkshopTimeAllocation;

  get positionX(): number {
    return this.tableX * this.tableRef.rowWidth + this.tempX;
  }

  get positionY(): number {
    return this.tableY * this.tableRef.rowHeight + this.tempY;
  }

  get tempX(): number {
    if (!this.isDragged) return 0;
    return this.tableRef.mouseX - this.tableRef.dragStartMouseX;
  }

  get tempY(): number {
    if (!this.isDragged) return 0;
    return this.tableRef.mouseY - this.tableRef.dragStartMouseY;
  }

  get tempTableX(): number {
    return this.tableX + Math.round(this.tempX / this.tableRef.rowWidth);
  }

  get tempTableY(): number {
    return this.tableY + Math.round(this.tempY / this.tableRef.rowHeight);
  }

  get color(): string {
    if (
      this.tableRef.calendarMode == "booking-modal" ||
      this.tableRef.calendarMode == "custom-booking-modal"
    ) {
      if (this.isDragged) {
        if (this.validTempPosition) return "yellow";
        return "red";
      }
      if (this.isSelected) return "#f7d720";

      if (this.isPast) return "#bab9c7";

      if (!this.isBookable) return "#e3526c";
    }
    if (!this.isDragged) {
      if (this.invalidInstance) return "#e3526c";
      else return "#5ee682"; //#60db81
    }

    if (this.validTempPosition) return "yellow";
    return "red";
  }

  constructor(
    tableX: number,
    tableY: number,
    duration: number,
    tableRef: AddSpaceForm
  ) {
    this.duration = duration;
    this.tableX = tableX;
    this.tableY = tableY;
    this.tableRef = tableRef;
    var names = ["Luk", "Dimitr", "Rus", "Anuk", "Aleks", "Senz", "SevnN"];
    this.invalidInstance = !this.validSpawnPosition;
  }

  snap() {
    if (
      this.tableRef.isAvailable(
        this.tempTableX,
        this.tempTableY + this.tableRef.starttime,
        this.duration
      )
    ) {
      this.tableX = this.tempTableX;
      this.tableY = this.tempTableY;
      this.timeAllocation.startTime = this.tableRef
        .GetDateForSlot(this.tableX, this.tableY + this.tableRef.starttime)
        .toLocaleString("en-US");
      this.timeAllocation.endTime = this.tableRef
        .GetDateForSlot(
          this.tableX,
          this.tableY + this.tableRef.starttime + this.duration
        )
        .toLocaleString("en-US");
      this.invalidInstance = false;
      this.tableRef.setSlots(
        this.tableX,
        this.tableY + this.tableRef.starttime,
        this.duration,
        true
      );
    } else if (!this.invalidInstance) {
      this.tableRef.setSlots(
        this.tableX,
        this.tableY + this.tableRef.starttime,
        this.duration,
        true
      );
    }
  }

  get validSpawnPosition(): boolean {
    return this.tableRef.isAvailable(
      this.tableX,
      this.tableY + this.tableRef.starttime,
      this.duration
    );
  }

  get validTempPosition(): boolean {
    return this.tableRef.isAvailable(
      this.tempTableX,
      this.tempTableY + this.tableRef.starttime,
      this.duration
    );
  }

  dragStart(ev) {
    if (ev.touches != null) {
      this.tableRef.mouseX = ev.touches[0].pageX;
      this.tableRef.mouseY = ev.touches[0].pageY;
    }
    if (ev.touches != null) {
      ev.preventDefault();
    }
    if (!this.invalidInstance)
      this.tableRef.setSlots(
        this.tableX,
        this.tableY + this.tableRef.starttime,
        this.duration,
        false
      );
    this.tableRef.dragStartMouseX = this.tableRef.mouseX;
    this.tableRef.dragStartMouseY = this.tableRef.mouseY;
    this.tableRef.currentDragged = this;
    this.isDragged = true;
  }

  endDrag() {
    this.snap();
    this.isDragged = false;
    this.tableRef.currentDragged = null;
    if (this.isCustom) this.tableRef.selectDraggable(this);
  }

  delete(ev = null) {
    if (ev != null) ev.preventDefault();
    this.tableRef.setSlots(
      this.tableX,
      this.tableY + this.tableRef.starttime,
      this.duration,
      false
    );
    this.tableRef.deleteDraggable(this);
  }

  get isBookable(): boolean {
    if (this.availableSeats <= 0) return false;
    if (this.isPast) return false;
    if (this.tableRef.workshopBookingService.bookingOptions == null)
      return true;
    if (this.tableRef.workshopBookingService.bookingOptions.attendees == null)
      return true;
    return (
      this.availableSeats >=
      this.tableRef.workshopBookingService.bookingOptions.attendees
    );
  }

  get isSelected(): boolean {
    if (this.tableRef.currentSelectedTimeAllocation == null) return false;
    return (
      new Date(
        this.tableRef.currentSelectedTimeAllocation.startTime
      ).toLocaleString("en-US") == this.occurenceDate.toLocaleString("en-US")
    );
  }

  get isPast(): boolean {
    return this.occurenceDate <= new Date();
  }
}
