/// <reference types="@types/googlemaps" />

import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeolocationService } from 'app/modules/shared/services/geolocation.service';
import { WorkshopService } from 'app/modules/shared/services/workshop.service';
import { WorkshopCarouselTile } from '../../carousel-tile/workshop-carousel-tile/workshop-carousel-tile.component';
import { IndexCarousel } from '../index-carousel.component';

@Component({
    selector: 'closest-workshops',
    templateUrl: '../index-carousel.component.html'
})


// !!!
// Now shows all workshops
// !!!

export class ClosestWorkshops extends IndexCarousel implements OnInit {

    @Input() color:string = '#212529';
    @Input() headerLabel = 'Experiences closest to you';
    protected tileComponent = WorkshopCarouselTile;
    domID = 'closest-workshops-';

    constructor(private geolocation: GeolocationService, private router: Router, public workshopService: WorkshopService, injector: Injector) {
        super(injector);
    }

    get apiGetter() {
        return this.workshopService.getClosestWorkshops(this.geolocation.geolocation);
    }
}
