import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Invoice } from '../../../../models/users/invoice';
import { AuthService } from '../../../../services/auth.service';
import { CustomerService } from '../../../../services/customer.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationType } from 'app/helpers/enums/notificationType';

@Component({
    selector: 'app-invoice-modal',
    templateUrl: './invoice-modal.component.html',
})
export class InvoiceModalComponent implements OnInit {

    @Input() title: string
    @Input() invoice: Invoice
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    errorMessage: string = "";

    constructor(
        public authService: AuthService,
        private customerService: CustomerService,
        private router: Router,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(["/index"]);
        }
        if (this.authService.user.userType != "Event Planner") {
            this.router.navigate(["/index"]);
        }
        if (!this.invoice) {
            this.invoice = new Invoice();
        }
        else {
            let invCopy = Object.assign({}, this.invoice)
            this.invoice = invCopy;
        }
    }
    cancel() {
        this.passEntry.emit();
    }

    save() {
        this.customerService.createOrUpdateInvoice(this.invoice)
            .subscribe(
                res => {
                    if (res) {
                        this.notificationService.notify(NotificationType.Success, "Invoice address successfully updated");
                        this.passEntry.emit();
                    }
                },
                () => {
                    this.notificationService.notify(NotificationType.Error, "Invoice Address update failed");
                })
    }

}
