import { Component, Input, OnInit } from "@angular/core";
import { CartService } from "../../services/cart.service";

@Component({
  selector: "cart-payment-overview",
  templateUrl: "cart-payment-overview.component.html",
  styleUrls: ["./cart-payment-overview.component.scss"],
})
export class CartPaymentOverview implements OnInit {
  public hireFee: number;
  public ebentoFee: number;
  public totalFee: number;

  constructor(public cartService: CartService) {}

  ngOnInit(): void {}

  VenuHire() {
    this.hireFee = this.cartService.cartData.reduce(
      (acc, current) => acc + current.totalPrice,
      0
    );
    return Math.round(this.hireFee);
  }

  EbentoFee() {
    this.ebentoFee = (this.hireFee * 5) / 100;
    return Math.round(this.ebentoFee);
  }

  TotalPrice() {
    this.totalFee = this.hireFee + this.ebentoFee;
    return Math.round(this.totalFee);
  }
}
