import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Booking } from '../../../../models/booking/booking';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-deactivation-modal',
  templateUrl: './account-deactivation-modal.component.html'
})
export class AccountDeactivationModalComponent implements OnInit {

    @Input() title: string;
    @Input() bookings: Booking[];
    @Output() passEntry = new EventEmitter();
     
    constructor(
        public activeModal: NgbActiveModal,
        private router: Router
    ) { }

    ngOnInit() {

    }

    redirectToBooking(booking: Booking) {
        //this.router.navigate(['/bookings/' + booking.idBooking]);
        //or add cancel button
        this.passEntry.emit('close');
    }

    cancelBooking(booking: Booking) {
        // npr. this.bookingService.cancelBookingPropertyManager(booking);
    }

    public confirm(): void {
    }

    public close(): void {
        this.activeModal.close('Close click');
    }

}
