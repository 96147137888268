import { Component, Input, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http'
import { AmenityType } from '../../../models/properties/amenity-type';
import { AmenityDocumentUpload, DocumentUpload } from '../../../models/users/document-upload';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { Amenity } from '../../../models/properties/Amenity';

@Component({
    selector: 'upload-document',
    templateUrl: 'upload-document.component.html',
    styleUrls: ['../../../css/upload.css']
})

export class UploadDocument {
    // public disabled : boolean = true;
    //fa
    faMinusCircle = faMinusCircle;

    @Input('url') url: string = '';
    @Input('disabled') disabled: false;
    @Input('amenity') amenity: Amenity;
    @Input('amenityType') amenityType: AmenityType;
    @Output() documentUrl = new EventEmitter<DocumentUpload>();
    @Output() amenityDocumentUrl = new EventEmitter<AmenityDocumentUpload>();


    public progress: number;
    public message: string;

    constructor(private http: HttpClient) { }

    upload(files) {
        if (files.length === 0)
            return;

        //TODO: check and remove previous uploaded documents for that amenity

        const formData = new FormData();

        for (let file of files)
            formData.append(file.name, file);

        const uploadReq = new HttpRequest('POST', 'api/upload/document', formData, {
            reportProgress: true,
        });

        //TODO: refactor this part to be in spaceService 
        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress)
                this.progress = Math.round(100 * event.loaded / event.total);
            else if (event.type === HttpEventType.Response) {
                if (event.status == 200) {
                    this.message = 'Successful upload'
                    if (this.documentUrl != null)
                    { 
                        let docUploadObj = new DocumentUpload(event.body.toString(), this.amenityType);
                        this.documentUrl.emit(docUploadObj);
                    }
                    if (this.amenityDocumentUrl != null) {
                        let docUploadObj = new AmenityDocumentUpload(event.body.toString(), this.amenity);
                        this.amenityDocumentUrl.emit(docUploadObj);
                    }
                } else {
                    this.message = 'Upload failed'
                }
            }
        });
    }

    remove()
    {
        this.url = "";
        this.documentUrl.emit(new DocumentUpload("", this.amenityType));
    }
}