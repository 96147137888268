import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FavoritesService } from '../../services/favorites.service';
import { AuthService } from '../../../shared/services/auth.service';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'favorite-workshop-button',
  templateUrl: './favorite-workshop-button.component.html',
  styleUrls: ['./favorite-workshop-button.component.scss']
})
export class FavoriteWorkshopButton implements OnInit, OnDestroy {
    // fa
    faHeart = faHeart;

    @Input() workshopID : number
    //currentState: boolean = true;

    constructor(public favoritesService: FavoritesService, public authService: AuthService) {
      
    }

    get currentState(): boolean
    {
        if (this.favoritesService.myFavoriteWorkshops == null)
            return false;
        return this.favoritesService.myFavoriteWorkshops.indexOf(this.workshopID) > -1;
    }


    changeState(event) {
        event.stopPropagation();
        this.favoritesService.setWorkshopFavoriteStatus(!this.currentState, this.workshopID).subscribe();
    }

    ngOnInit() {

      /* if (this.favoritesService._myFavoriteWorkshops != null)
            this.currentState = this.favoritesService._myFavoriteWorkshops.indexOf(this.spaceID) > -1;
        this.favoritesService.myFavoriteWorkshops.subscribe(
            (result) => {
                console.log(this.me + " Ola!! " + this.spaceID + "): " + (result.indexOf(this.spaceID) > -1));
                this.currentState = result.indexOf(this.spaceID) > -1;
                return result;
            }
        );*/
    }

    ngOnDestroy() {
     //   this.favoritesService.myFavoriteWorkshops.unsubscribe();
    }

}

