import { Component, OnInit } from '@angular/core';
import { CovidDTO } from '../../../../models/simple-pages/covid-dto';
import { SimplePagesService } from '../../../../services/simple-pages.service';

@Component({
  selector: 'app-covid',
    templateUrl: './covid.component.html',
    styleUrls: ['./covid.component.scss']
})
export class CovidComponent implements OnInit {

    model: CovidDTO;
    imageUrl: string = '';
    text: string = '';

    constructor(private simplePagesService: SimplePagesService) { }

    ngOnInit() {
        this.simplePagesService.getCovidPageData().subscribe(
            res => {
                if (res != null) {
                    this.model = res;
                    this.text = this.model.body;
                    this.imageUrl = this.model.imageUrl;
                }
                else {
                    this.model = new CovidDTO()
                }
            }
        )
    }

}
