import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from 'app/modules/shared/services/messaging.service';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'admin-header',
    templateUrl: 'admin-ebento-header.component.html',
    styleUrls: ['../../../shared/css/ebento-header.css']
})

export class AdminHeader implements OnInit {

    public SelectedLanguage = "EN";
    @ViewChild('userDropdown') userDropdown;
    constructor(
        public authService: AuthService,
        public router: Router,
        public translate: TranslateService,
        public messagingService: MessagingService
    ) { }

    get userAvatar()
    {
        return this.authService.user.avatarURL;
    }

    ngOnInit(): void {
        this.getScreenSize();
        /// not here
        //this.authService.getUserInfo().subscribe();
    }

    //TODO: refactor/put this functionality somewhere else
    onLogout() {
        this.authService.logout().subscribe(
            () => { this.router.navigate(["/index"]) },
            (err) => {
                this.router.navigate(["/index"])
                this.authService.forceLogout();
                console.error(err);
            }
        );
    }

    public isLoggedIn(): boolean {
        return this.authService.isLoggedIn();
    }

    public redirectToProfilePage() {
        let route = "/admin/profile";
        this.router.navigate([route]);
    }

    public SetLanguage(val: string) {
        this.SelectedLanguage = val;
        this.translate.use(this.SelectedLanguage);
    }

    toggleDropdown()
    {
        this.userDropdown.dropdown();
    }


    scrHeight: any;
    scrWidth: any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
    }
}