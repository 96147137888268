import { TimeAllocation } from "./time-allocation";

export class WsBookingTimeAllocation extends TimeAllocation
{
    public isCustom : boolean;
    constructor()
    {
        super();
        // default value for Available Slots. Is it needed?
        this.allocationType = 3;
    }

    public static Overlaps(allocation1: WsBookingTimeAllocation, allocation2: WsBookingTimeAllocation): boolean
    {
        var start1 = new Date(allocation1.startTime);
        var start2 = new Date(allocation2.startTime);
        var end1 = new Date(allocation1.endTime);
        var end2 = new Date(allocation2.endTime);
        return !((end1 <= start2) || (start1 >= end2));
    }
}