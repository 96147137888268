import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'custom-accordion',
  templateUrl: './custom-accordion.component.html',
  styleUrls: ['./custom-accordion.component.scss'],
  animations: [trigger('expandable',
    [state('expanded', style({ visibility: 'visible', height: 'fit-content' })),
    state('colapsed', style({ visibility: 'hidden', height: '0px' })),
    transition('colapsed=>expanded', animate('0ms ease-in')),
    transition('expanded=>colapsed', animate('0ms ease-out'))])]
})
export class CustomAccordionComponent implements OnInit {

  constructor() { }

  @Input() heading: string;
  @Input() text: string;
  state = 'colapsed';

  ngOnInit(): void {

  }

  showHide() {
    this.state = this.state == 'expanded' ? 'colapsed' : 'expanded';
  }

}
