import { PaymentStatus } from "./payment-status";

export class PMBookingsFilter
{
    public dateRange: string;
    public paymentStatus: PaymentStatus;
    public spaceID: number;
    public workshopID: number;
    public plannerID: string;

    constructor()
    {
        this.spaceID = -1;
        this.workshopID = -1;
    }
}