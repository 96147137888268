/// <reference types="@types/googlemaps" />

import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as util from 'util' // has no default export
import { inspect } from 'util' // or directly
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SearchSpaceQuery } from 'app/modules/shared/models/search/search-space-query';
import { SpaceForTile } from 'app/modules/shared/models/search/space-for-tile';
import { PropertyService } from 'app/modules/shared/services/property.service';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { SearchService } from '../../services/search.service';
import { PropertyAddress } from 'app/modules/shared/models/properties/property-address';
import { SearchCalendarModal } from 'app/modules/shared/modules/modals/components/search-calendar-modal/search-calendar-modal.component';
import { TimeAllocation } from 'app/modules/shared/models/data-classes/time-allocation';
import { concat } from 'rxjs';
import { BookingSegment } from 'app/modules/shared/models/booking/booking-segment';
import { EbentoGmapComponent } from '../../../shared/components/ebento-gmap/ebento-gmap.component';
import { GmapMarker } from '../../../shared/models/misc/gmap-marker';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { EbentoModalService } from '../../../shared/services/ebento-modal.service';

@Component({
    selector: 'space-search-results',
    templateUrl: "space-search-results.component.html",
    styleUrls: ['./space-search-results.component.scss']
})

export class SpaceSearchResults implements OnInit {
    //fa 
    faMinusCircle = faMinusCircle;

    @ViewChild('ebentoGmap') ebentoGmap: EbentoGmapComponent;

    autocomplete: google.maps.places.Autocomplete;
    public componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'

    };
    public propertyMapping = {
        street_number: "streetnumber",
        route: "streetaddress",
        locality: "city",
        administrative_area_level_1: "",
        country: "country",
        postal_code: "postalcode"
    }
    public searchResultMarkers: GmapMarker[];
    modalRef: NgbModalRef;
    public selectedAmenities: boolean[];
    public selectedSpaceTypes: boolean[];
    public searchQuery: SearchSpaceQuery;
    numbersOfAttendees = 0;


    public tiles: SpaceForTile[];

    constructor(
        private modalService: EbentoModalService,
        private propertyService: PropertyService,
        public spaceService: SpaceService,
        public searchService: SearchService,
        private router: Router
    ) { }

    ngOnInit() {

        this.searchQuery = this.searchService.searchSpaceQuery;
        this.searchQuery.numberofattendees = this.numbersOfAttendees;
        this.autocomplete = new google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(<HTMLInputElement>document.getElementById('autocompleteSearch')),
            { types: ['geocode'] });

        // When the user selects an address from the dropdown, populate the address
        // fields in the form.
        this.autocomplete.addListener('place_changed', () => { this.fillInAddress(); });

        // this.searchQuery = this.searchService.searchSpaceQuery;

        //ZZ cemu niz SpaceForTile[] cemu dto SpaceForTiles koji ima u sebi niz
        this.tiles = this.searchService.searchResults;
        this.selectedAmenities = new Array(this.spaceService.amenityTypes.length);
        this.selectedSpaceTypes = new Array(this.spaceService.spaceTypes.length);
    }

    ngAfterContentInit()
    {
        this.updateMarkers();
    }

    updateMarkers()
    {
        this.searchResultMarkers = [];
        for (var i = 0; i < this.tiles.length; i++) {
            var marker = new GmapMarker(
                this.tiles[i].address.googlemapcoordx,
                this.tiles[i].address.googlemapcoordy,
                false, "");
            this.searchResultMarkers.push(marker);
        }
    }

    fillInAddress() {

        var place = this.autocomplete.getPlace();
        this.searchService.searchPlaceAutocompleteLatLng = place.geometry.location;
        var streetSpecific = false;
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (this.propertyMapping[addressType] == "streetaddress")
                streetSpecific = true;
        }

        //console.log("StreetSpecific: " + streetSpecific);
        if (!streetSpecific) {

            var geocoder = new google.maps.Geocoder;
            geocoder.geocode({ 'location': this.searchService.searchPlaceAutocompleteLatLng }, (results, status) => {
                // console.log("wow." + JSON.stringify(results[0]));

                var placeFromGeocode = results[0];
                var fields = [];
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (this.propertyMapping[addressType]) {
                        //console.log("1. set " + this.propertyMapping[addressType] + " to null.");
                        fields[this.propertyMapping[addressType]] = true;
                        //this.searchService.searchSpaceQuery.address[this.propertyMapping[addressType]] = null;
                    }
                }
                this.searchService.searchSpaceQuery.address = new PropertyAddress(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

                for (var i = 0; i < placeFromGeocode.address_components.length; i++) {

                    var addressType = placeFromGeocode.address_components[i].types[0];
                    if (this.componentForm[addressType]) {
                        var val = placeFromGeocode.address_components[i][this.componentForm[addressType]];
                        if (this.propertyMapping[addressType] && fields[this.propertyMapping[addressType]]) {
                            //console.log("2. set " + this.propertyMapping[addressType] + " to [" + val + "].");
                            this.searchService.searchSpaceQuery.address[this.propertyMapping[addressType]] = val;
                        }
                    }
                }
            });
        }

        else {
            /*
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (this.propertyMapping[addressType]) {
                    //console.log("1. set " + this.propertyMapping[addressType] + " to null.");
                    //this.searchService.searchSpaceQuery.address[this.propertyMapping[addressType]] = null;
                }
            }*/
            this.searchService.searchSpaceQuery.address = new PropertyAddress(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

            for (var i = 0; i < place.address_components.length; i++) {

                var addressType = place.address_components[i].types[0];
                if (this.componentForm[addressType]) {
                    var val = place.address_components[i][this.componentForm[addressType]];
                    if (this.propertyMapping[addressType]) {
                        //console.log("2. set " + this.propertyMapping[addressType] + " to [" + val + "].");
                        this.searchService.searchSpaceQuery.address[this.propertyMapping[addressType]] = val;
                    }
                }
            }

        }
        this.searchService.searchSpaceQuery.addressSearchString = place.formatted_address;
    }

    removeTimeSlot(timeslot: BookingSegment )
    {
        this.searchService.calendarTimeSelection.splice(this.searchService.calendarTimeSelection.indexOf(timeslot), 1);
        if (this.searchService.calendarTimeSelection.length == 0)
            this.searchService.calendarTimeSelection = null;
    }

    SetAttendees(t: number) {
        if (t == 0 || t == null || t == undefined)
            this.searchQuery.numberofattendees = undefined;
        else
            this.searchQuery.numberofattendees = t;
    }

    reduceAttendees(){
        --this.numbersOfAttendees;
        this.searchQuery.numberofattendees = this.numbersOfAttendees;
    }

    incrementAttendees(){
        ++this.numbersOfAttendees;
        this.searchQuery.numberofattendees = this.numbersOfAttendees;
    }

    openTimeModal() {
        this.modalRef = this.modalService.open(SearchCalendarModal, { size: 'lg', });
        this.modalRef.componentInstance.title = 'Mark required timeslots';
        if (this.searchService.calendarTimeSelection != null)
            this.modalRef.componentInstance.SetSelections(this.searchService.calendarTimeSelection);
        this.modalRef.componentInstance.passBookings.subscribe((receivedEntry) => {
            this.searchService.calendarTimeSelection = receivedEntry;
        })
    }

    Search() {
        if (this.searchQuery.addressSearchString == "" || this.searchQuery.addressSearchString == null)
            this.searchQuery.address = new PropertyAddress(null, null, null, null, null, null, 0, 0);

        var queryAmenities = new Array();
        for (var i = 0; i < this.spaceService.amenityTypes.length; i++) {
            if (this.selectedAmenities[i])
                queryAmenities.push(this.spaceService.amenityTypes[i].id);
        }
        this.searchQuery.amenityids = queryAmenities;

        var querySpaceTypes = new Array();
        for (var spaceType of this.searchQuery.spaceTypes) {
            querySpaceTypes.push(spaceType.id);
        }
        this.searchQuery.spacetypeids = querySpaceTypes;

        var timeAllocations = new Array<TimeAllocation>();
        if (this.searchService.calendarTimeSelection != null)
            for (let selection of this.searchService.calendarTimeSelection) {
                timeAllocations.push(TimeAllocation.FromBooking(selection));
            }
        this.searchQuery.timeAllocations = timeAllocations;

        this.searchService.findSpaces().subscribe(
            () => {
                //console.log("SRCH::::: " + JSON.stringify(this.searchService.searchResults));
                this.tiles = this.searchService.searchResults
                this.updateMarkers();
                //this.ebentoGmap.SetupMap();
                this.router.navigate(['space-search-results']);
            },
            err => console.error(err)
        );
    }


}
