import { Component, OnInit } from '@angular/core';
import { TermDTO } from '../../../../models/simple-pages/term-dto';
import { SimplePagesService } from '../../../../services/simple-pages.service';
import { PrivacyDTO } from '../../../../models/simple-pages/privacy-policy-dto';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

    terms: TermDTO;
    privacy: PrivacyDTO;
    text: string;

    constructor(private simplePagesService: SimplePagesService) { }

    ngOnInit() {

        this.simplePagesService.getTermPageData().subscribe(
            res => {
                if (res != null) {
                    this.terms = res;
                }
                else {
                    this.terms = new TermDTO()
                }
            }
        );

        this.simplePagesService.getPrivacyPloicyPageData().subscribe(
            res => {
                if (res != null) {
                    this.privacy = res;
                }
                else {
                    this.privacy = new PrivacyDTO()
                }
            }
        );
    }

}
