import { Component } from '@angular/core';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { WorkshopBookingService } from 'app/modules/shared/services/workshop-booking.service';
import { WorkshopBookingMenu } from 'app/modules/shared/models/booking/workshop-booking-menu';
import { BookingAmenity } from '../../../shared/models/booking/booking-amenity';
import { WorkshopService } from '../../../shared/services/workshop.service';
import { WorkshopMenu } from '../../../shared/models/workshops/workshop-menu';
import { WorkshopTimeAllocation } from '../../../shared/models/data-classes/workshop-time-allocation';
import { TimeSpan } from '../../../shared/models/data-classes/time-span';
import { PaymentService } from '../../services/payment.service';
import { UpdatePaymentIntent } from 'app/modules/shared/models/payment/update-payment-intent';

@Component({ 
    selector: 'workshop-booking-recap',
    templateUrl: 'workshop-booking-recap.component.html',
    styleUrls: ['workshop-booking-recap.component.css']
})

export class WorkshopBookingRecap
{

    couponCode: string
    couponStatus = 'none'

    constructor(
        public workshopBookingService: WorkshopBookingService,
        public paymentService: PaymentService,
        public workshopService: WorkshopService, public spaceService: SpaceService)
    {
    }

    menuString(menu: WorkshopBookingMenu): string {
        return this.workshopService.GetMenuByID(menu.menuID).name;
    }

    checkCouponCode()
    {
        this.couponStatus = 'loading';
        if (this.couponCode == "")
        {
            this.couponStatus = 'none';
        }
        else
        {
            this.workshopBookingService.checkCoupon(this.couponCode, this.workshopBookingService.workshop.id).subscribe(coupon => {
            if (coupon != null) {
                this.workshopBookingService.bookingOptions.coupon = coupon;
                this.couponStatus = 'valid';
            }
            else {
                this.workshopBookingService.bookingOptions.coupon = null;
                this.couponStatus = 'invalid';
            }
            });
        }
        console.log(this.paymentService.paymentIntentID);
        this.paymentService.updatePaymentIntent(new UpdatePaymentIntent(this.workshopBookingService.TotalPrice(true), this.paymentService.paymentIntentID)).subscribe();

    }


    amenityString(amenity: BookingAmenity): string {
        return this.spaceService.GetAmenityTypeByID(amenity.amenityTypeID).name;
    }

    public GetDateString(allocation: WorkshopTimeAllocation): string {
        return new Date(allocation.startTime).toLocaleDateString();

    }
    public GetTimeString(allocation: WorkshopTimeAllocation): string {
        var start = new Date(allocation.startTime);
        var end = new Date(allocation.endTime);
        var startTime = new TimeSpan(0, start.getHours(), start.getMinutes(), 0);
        var endTime = new TimeSpan(0, end.getHours(), end.getMinutes(), 0);
        return startTime.HoursMinutesString + " - " + endTime.HoursMinutesString;
    }

}
