import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpHeaders, HttpClient, HttpErrorResponse } from "@angular/common/http";
import { map, catchError, switchMap } from "rxjs/operators";
import { UploadService } from "./upload.service";
import { FaqDTO } from "../models/simple-pages/faq-dto";
import { AboutDTO } from "../models/simple-pages/about-dto";
import { CareerDTO } from "../models/simple-pages/career-dto";
import { CovidDTO } from "../models/simple-pages/covid-dto";
import { TermDTO } from "../models/simple-pages/term-dto";
import { FeedbackDTO } from "../models/simple-pages/feedback-dto";
import { PricingInfoDTO } from "../models/simple-pages/pricing-info-dto";
import { PrivacyDTO } from "../models/simple-pages/privacy-policy-dto";

@Injectable()
export class SimplePagesService {

    constructor(private http: HttpClient, private uploadService: UploadService) { }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    getFaqPageData(): Observable<FaqDTO> {

        return this.http.get<FaqDTO>("api/faq")
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }


    getAboutPageData(): Observable<AboutDTO> {

        return this.http.get<AboutDTO>("api/about")
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    getCareerPageData(): Observable<CareerDTO> {

        return this.http.get<AboutDTO>("api/career")
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    getCovidPageData(): Observable<CovidDTO> {

        return this.http.get<AboutDTO>("api/covid")
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }


    getTermPageData(): Observable<TermDTO> {

        return this.http.get<TermDTO>("api/term")
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    getPrivacyPloicyPageData(): Observable<PrivacyDTO> {

        return this.http.get<AboutDTO>("api/privacy")
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    getPricingInfoPageData(): Observable<PricingInfoDTO> {

        return this.http.get<PricingInfoDTO>("api/PricingInfo")
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }


    getFeedbacks(): Observable<FeedbackDTO[]> {

        return this.http.get<FeedbackDTO[]>("api/feedback")
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    sendFeedback(feedback: FeedbackDTO): Observable<boolean> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<boolean>("api/feedback", JSON.stringify(feedback), httpOptions)
            .pipe(
                map(data => {
                    return data;
                }),
                catchError(this.errorHandler)
            );
    }

    addAboutPageData(about: AboutDTO): Observable<boolean> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<boolean>(
            "api/about",
            JSON.stringify(about),
            httpOptions)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    addFaqPageData(faq: FaqDTO): Observable<boolean> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<boolean>(
            "api/faq",
            JSON.stringify(faq),
            httpOptions)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }


    addCareerPageData(career: CareerDTO): Observable<boolean> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<boolean>(
            "api/career",
            JSON.stringify(career),
            httpOptions)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    addCovidPageData(covid: CovidDTO): Observable<boolean> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<boolean>(
            "api/covid",
            JSON.stringify(covid),
            httpOptions)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    addTermPageData(term: TermDTO): Observable<boolean> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<boolean>(
            "api/term",
            JSON.stringify(term),
            httpOptions)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    addPrivacyPageData(term: PrivacyDTO): Observable<boolean> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<boolean>(
            "api/privacy",
            JSON.stringify(term),
            httpOptions)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }


    addPricingInfoPageData(pricingInfo: PricingInfoDTO): Observable<boolean> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<boolean>(
            "api/PricingInfo",
            JSON.stringify(pricingInfo),
            httpOptions)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }

    public addAboutPageDataWithUpload(image, model) {
        if (image) {
            return this.uploadService.uploadImage(image).pipe(
                switchMap(
                    (res: string) => {
                        model.imageUrl = res;
                        return this.addAboutPageData(model);
                    }
                )
            )
        }
        else {
            return this.addAboutPageData(model);
        }
    }

    public addCareerPageDataWithUpload(image, model) {
        if (image) {
            return this.uploadService.uploadImage(image).pipe(
                switchMap(
                    (res: string) => {
                        model.imageUrl = res;
                        return this.addCareerPageData(model);
                    }
                )
            )
        }
        else {
            return this.addCareerPageData(model);
        }
    }

    public addCovidPageDataWithUpload(image, model) {
        if (image) {
            return this.uploadService.uploadImage(image).pipe(
                switchMap(
                    (res: string) => {
                        model.imageUrl = res;
                        return this.addCovidPageData(model);
                    }
                )
            )
        }
        else {
            return this.addCovidPageData(model);
        }
    }
}