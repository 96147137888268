import { Component, OnInit, Input, Injector } from "@angular/core";

import { EbentoProperty } from "../../models/properties/ebento-property";
import { WorkshopService } from "../../services/workshop.service";
import { WorkshopTile } from "../../models/search/workshop-tile";
import { PropertyDetailsSpaceTile } from "../property-details-space-tile/property-details-space-tile.component";
import { IndexCarousel } from "app/modules/event-planner/components/carousel/index-carousel/index-carousel.component";
import { WorkshopCarouselTile } from "app/modules/event-planner/components/carousel/carousel-tile/workshop-carousel-tile/workshop-carousel-tile.component";
import { SpaceService } from "../../services/space.service";
import { PropertyService } from "../../services/property.service";

@Component({
  selector: "app-property-details-experience-card",
  templateUrl: '../../../event-planner/components/carousel/index-carousel/index-carousel.component.html',
  styleUrls: ["./property-details-experience-card.component.scss"],
})
export class PropertyDetailsExperienceCardComponent extends IndexCarousel implements OnInit {
  @Input() property: EbentoProperty;
  @Input() headerLabel = "Experiences";
  workshops: WorkshopTile[];
  spaces;
  propertySpaces;

  protected tileComponent = WorkshopCarouselTile;

  constructor(
    public spaceService: SpaceService,
    public propertyService: PropertyService,
    public workshopService: WorkshopService,
    injector: Injector
  ) {
    super(injector);
  }


  get apiGetter() {
     return this.workshopService.getAllWorkshopForProperty(this.property.id);
  }
}
