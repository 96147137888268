import { Component, Input } from '@angular/core';
import { TimeSpan } from '../../models/data-classes/time-span';
import { WeekCalendarView } from '../week-calendar-view/week-calendar-view.component';

@Component({
    selector: 'time-span-selector',
    templateUrl: 'time-span-selector.component.html'
})

export class TimeSpanSelector {

    @Input('timeSpan') timeSpan: TimeSpan;
    @Input('intervalMinutes') intervalMinutes: number;
    @Input() weekCalendarView: WeekCalendarView;

    public Increase()
    {
        this.timeSpan.Add(new TimeSpan(0, 0, this.intervalMinutes, 0));
        this.timeSpan.SetTo(TimeSpan.Min(this.timeSpan, new TimeSpan(1, 0, 0, 0)));
        if (this.weekCalendarView != null)
        this.weekCalendarView.Initialize();
    }

    public Decrease()
    {
        this.timeSpan.Subtract(new TimeSpan(0, 0, this.intervalMinutes, 0));
        this.timeSpan.SetTo(TimeSpan.Max(this.timeSpan, new TimeSpan(0, 0, 0, 0)));
        if (this.weekCalendarView != null)
            this.weekCalendarView.Initialize();
    }
}

