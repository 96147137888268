import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { CartService } from "../../services/cart.service";
import { Item } from "../../../shared/models/cart/cartItem";
import { SubmissionService } from "../../../shared/services/submission.service";

@Component({
  selector: "ebento-cart",
  templateUrl: "ebento-cart.component.html",
  styleUrls: ["./ebento-cart.component.scss"],
})
export class EbentoCart implements OnInit {
  public cartData: Item[] = [];
  public hireFee: number;
  public ebentoFee: number;
  public totalFee: number;
  public count: number = 0;

  constructor(
    private router: Router,
    private cartService: CartService,
    private submissionService: SubmissionService
  ) {}

  public activeTab: string = "cart";

  ngOnInit() {
    console.log("OnInit", this.activeTab);
    this.GetCartItems();
  }

  changeTab(name: string) {
    this.activeTab = name;
    console.log("activeTab", this.activeTab);
    if (this.activeTab === "cart") {
      this.GetCartItems();
    } else if (this.activeTab === "saved") {
      this.GetSaveForLater();
    }
  }

  GetCartItems() {
    this.submissionService.startSubmission();
    this.cartService.getCartItems().subscribe((response) => {
      this.cartData = response;
      this.submissionService.endSubmission();
      this.count = response.length;
      this.cartService.cartData = response;
      console.log("cart items", this.cartData);
      this.hireFee = response.reduce(
        (acc, current) => acc + current.totalPrice,
        0
      );
      console.log("calculated price", this.hireFee);
      this.ebentoFee = (this.hireFee * 5) / 100;
      console.log("ebento fee", this.ebentoFee);
      this.totalFee = this.hireFee + this.ebentoFee;
      console.log("total fee", this.totalFee);
    });
  }

  GetSaveForLater() {
    this.submissionService.startSubmission();
    this.cartService.getSaveForLater().subscribe((response) => {
      this.cartData = response;
      this.submissionService.endSubmission();
      this.cartService.cartData = response;
      console.log("saveforlater", this.cartData);
    });
  }

  moveItem(type: string) {
    console.log("emitted move event", type);

    if (type === "cart") {
      this.GetCartItems();
    } else if (type === "saved") {
      this.GetSaveForLater();
    }
  }

  book() {
    this.router.navigate(["/cart/payment"]);
  }
}
