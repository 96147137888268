import { Component, HostListener, OnInit } from "@angular/core";
import { EventPlanner } from "app/modules/shared/models/users/event-planner";
import { PaymentComponent } from "../payment/payment.component";
import { SubmissionService } from "app/modules/shared/services/submission.service";
import { AuthService } from "app/modules/shared/services/auth.service";
import { SpaceBookingService } from "app/modules/shared/services/space-booking.service";
import { Router } from "@angular/router";
import { NotificationService } from "app/modules/shared/services/notification.service";
import { PaymentService } from "../../services/payment.service";
import { FormBuilder } from "@angular/forms";
import { StripeService } from "ngx-stripe";
import { HttpClient } from "@angular/common/http";
import { EbentoModalService } from "app/modules/shared/services/ebento-modal.service";
import { InvoiceModalComponent } from "app/modules/shared/modules/modals/components/invoice-modal/invoice-modal.component";
import { NotificationType } from "app/helpers/enums/notificationType";
import { UpdatePaymentIntent } from "app/modules/shared/models/payment/update-payment-intent";
import { CreatePaymentIntent } from "app/modules/shared/models/payment/create-payment-intent";
import { CartService } from "../../services/cart.service";
import { CartStripePaymentComponent } from "../../../shared/components/cart-stripe-payment/cart-stripe-payment.component";
import { SpaceService } from "../../../shared/services/space.service";
import { WorkshopService } from "../../../shared/services/workshop.service";

@Component({
  selector: "app-cart-payment",
  templateUrl: "./cart-payment.component.html",
  styleUrls: ["./cart-payment.component.scss"],
})
export class CartPaymentComponent
  extends CartStripePaymentComponent
  implements OnInit
{
  selectedTab: string = "credit";
  couponCode: string;
  email: string;
  couponStatus = "none";
  isSentInvoice: boolean;
  eventPlanner: EventPlanner;
  submissionInProgress: boolean = false;
  public totalFee: number;
  public paymentIntentID: string;

  constructor(
    public submissionService: SubmissionService,
    public authService: AuthService,
    public bookingService: SpaceBookingService,
    router: Router,
    notificationService: NotificationService,
    paymentService: PaymentService,
    fb: FormBuilder,
    stripeService: StripeService,
    http: HttpClient,
    private modalService: EbentoModalService,
    public cartService: CartService,
    public spaceService: SpaceService,
    public workshopService: WorkshopService
  ) {
    super(
      submissionService,
      fb,
      stripeService,
      bookingService,
      authService,
      notificationService,
      paymentService,
      router,
      http,
      cartService
    );
  }

  ngOnInit() {
    console.log("cart service cartdata", this.cartService.cartData);
    this.paymentService
      .createPaymentIntent(
        new CreatePaymentIntent(this.VenuHire(), this.authService.user.id)
      )
      .subscribe((res) => {
        console.log("response for create payment intend", res);
        this.paymentIntentID = res.split("_secret")[0];
        this.cartService.bookingOptions.paymentIntentID = this.paymentIntentID;
      });
    this.cartService.cartData.forEach((e) => {
      this.cartService.messageData[e.cartId] = "";
    });
    this.getScreenSize();
    this.email = this.authService.user.email;
    this.getPlanner();
  }

  selectTab(tab: any) {
    this.selectedTab = tab;
  }

  VenuHire() {
    const hireFee = this.cartService.cartData.reduce(
      (acc, current) => acc + current.totalPrice,
      0
    );

    const ebentoFee = (hireFee * 5) / 100;

    const totalFee = hireFee + ebentoFee;
    return Math.round(totalFee);
  }

  setMessage(e: any, id: number) {
    this.cartService.messageData[id] = e.target.value;    
  }

  checkCouponCode() {
    this.couponStatus = "loading";
    if (this.couponCode == "") {
      this.couponStatus = "none";
    } else {
      this.bookingService
        .checkCoupon(this.couponCode, this.bookingService.space.spaceId)
        .subscribe((coupon) => {
          if (coupon != null) {
            this.bookingService.bookingOptions.coupon = coupon;
            this.couponStatus = "valid";
          } else {
            this.bookingService.bookingOptions.coupon = null;
            this.couponStatus = "invalid";
          }

          this.paymentService
            .updatePaymentIntent(
              new UpdatePaymentIntent(
                this.bookingService.TotalPrice(true),
                this.paymentService.paymentIntentID
              )
            )
            .subscribe();
        });
    }
  }

  payByInvoice() {
    this.submissionService.startSubmission();
    this.submissionInProgress = true;
    this.cartService.bookingOptions.paymentType.name = "invoice";
    this.cartService.bookingOptions.paymentIntentID =
      this.paymentService.paymentIntentID;
    this.cartService.bookThroughCart().subscribe(
      (res) => {
        console.log("res of booking trhough cart", res);
        this.submissionService.endSubmission();
        let state = true;
        const spaces = Object.keys(res);
        spaces.forEach((e) => {
          const space = this.cartService.cartData.find((d) => {
            if (d.serviceId === Number(e)) {
              return e;
            }
          });
          if (res[e] === "Successfully booked") {
            this.notificationService.notify(
              NotificationType.Success,
              `${space.spaceName} is successfully booked`,
              "You have successfully booked this space"
            );
          } else {
            state = false;
            this.notificationService.notify(
              NotificationType.Error,
              `${space.spaceName} is unable to book now`,
              "Please try again later"
            );
          }
        });
        if (state) {
          this.cartService.cartcount = 0;
          this.router.navigate(["success"]);
        } else {
          this.router.navigate(["cart"]);
        }
      },
      (err) => console.log("error in booking cart", err)
    );
  }

  /* invoiceEligible(): boolean
{
    if (this.eventPlanner == null || this.eventPlanner.invoice == null)
        return false;
    return true;
}
*/
  openInvoiceModal() {
    const modalRef = this.modalService.open(InvoiceModalComponent);
    if (this.eventPlanner.invoice) {
      modalRef.componentInstance.title = "Edit Invoice Address";
      modalRef.componentInstance.invoice = this.eventPlanner.invoice;
    } else {
      modalRef.componentInstance.title = "Add Invoice Address";
    }
    modalRef.componentInstance.passEntry.subscribe(() => {
      this.authService.getUserInfo();
      this.getPlanner();
      this.modalService.close();
    });
  }

  private getPlanner() {
    this.authService.getPlannerInfoByID(this.authService.user.id).subscribe(
      (res) => {
        this.eventPlanner = res;
      },
      (err) => console.error(err)
    );
  }

  invoiceExists(): boolean {
    return this.eventPlanner != null && this.eventPlanner.invoice != null;
  }

  modify() {
    this.router.navigate(["/cart"]);
  }

  scrHeight: any;
  scrWidth: any;

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    console.log("screen-size get called");
  }
}
