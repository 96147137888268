import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { NotificationType } from 'app/helpers/enums/notificationType';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})

export class ConfirmRegistrationResolver implements Resolve<boolean> {

  constructor(private router: Router, private authService: AuthService, private nofification: NotificationService) { }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
      console.log(route); 

      if(this.authService.user) {
        
        if(!this.authService.user.isProfileCompleted && this.authService.user.userType == 'Property Manager' && this.authService.isLoggedIn) {
          this.nofification.notify(NotificationType.Info, 'Please complete your profile first.');
          this.router.navigate(['/property-manager/complete-registration']);
          return false;
          }

          if (!this.authService.user.isProfileCompleted && this.authService.user.userType == 'Event Planner' && this.authService.isLoggedIn) {
              this.nofification.notify(NotificationType.Info, 'Please complete your profile first.');
              this.router.navigate(['/event-planner/complete-registration']);
              return false;
          }

        if(this.authService.user.isProfileCompleted && this.authService.user.userType == 'Property Manager' && this.authService.isLoggedIn) {

          if(route.params.info == 'list-space') {
            this.router.navigate(['/property-manager/properties']);
          }
          if(route.params.info == 'list-experience') {
            this.router.navigate(['/property-manager/properties']);
          }
          if(route.params.info == 'list-entertainment') {
            this.router.navigate(['/property-manager/properties']);
          }
          return false;
        }

        else {
          return true;
        }
  
      }
     
      else {
        return true;
      }
  }
}
