import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationType } from 'app/helpers/enums/notificationType';

@Component({
    selector: 'app-edit-email-modal',
    templateUrl: './edit-email-modal.component.html'
})
export class EditEmailModalComponent implements OnInit {

    @Input() title: string = "Change Email Address"
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    errorMessage: string = "";

    public creds = {
        Email: "",
        ConfirmEmail: "",
        Password: "",
    };

    constructor(
        public authService: AuthService,
        private router: Router,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(["/index"]);
        }
    }

    cancel() {
        this.passEntry.emit();
    }

    validateEmails() {
        if (this.creds.Email != this.creds.ConfirmEmail) {
            this.errorMessage = "Email addresses do not match.";
            return false;
        }
        return true;
    }

    editEmail() {
        if (!this.validateEmails()) return;
        this.errorMessage = "";
        this.authService.changeEmail(this.creds).subscribe(
            (res) => {
                if (this.authService.userType == "EventPlanner") {
                    this.router.navigate(["index"]);
                } else if (this.authService.userType == "PropertyManager") {
                    this.router.navigate(['/property-manager/properties']);
                }
                this.notificationService.notify(NotificationType.Success, "Email for changing email address is sent", null);
                this.passEntry.emit();
            },
            err => this.errorMessage = err.error
        )

    }
}
