import { Component, OnInit, InjectionToken, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { AdminSpaceService } from 'app/modules/admin/services/admin-space.service';
import { InjectableItem } from '../index-carousel/injectable-item';

@Component({
  selector: 'carousel-tile',
  templateUrl: './carousel-tile.component.html',
  styleUrls: ['./carousel-tile.component.scss']
})
export class CarouselTile implements OnInit {

    item: any
    constructor(private itemInjection: InjectableItem)
    {
        this.item = itemInjection.item;
    }
    
    ngOnInit() {
      }

    public Redirect()
    {   
        console.error("Ovo nije dobro.");
    }

    // methode invoked on parent (carousel) is pased in item as item.parentmethod. 
    // It is defined in carousel as invokable(arg) override.
    public InvokeParent()
    {
        this.item.parentMethod(this.item);
    }
}
