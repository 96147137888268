
import { WorkshopPricingRule } from "./workshop-pricing-rule";
import { CancellationRule } from "../properties/cancellation-rule";
import { PropertyAddress } from "../properties/property-address";
import { WorkshopAmenity } from "./workshop-amenity";
import { WorkshopMenu } from './workshop-menu';
import { WorkshopTimeAllocation } from "../data-classes/workshop-time-allocation";
import { WorkshopTypeTag } from "../tags/workshop-type-tag";
import { WorkshopInspirationTag } from "../tags/workshop-inspiration-tag";
import { PropertyManager } from "../users/property-manager";

export class EbentoWorkshop
{
    public id: number = -1
    public name: string
    
    public spaceId: number = -1

    public propertyId: number = -1
    public minNumberOfGuests: number
    public maxNumberOfGuests: number
    public workshopDuration: number
    public description: string
    public typeTags: WorkshopTypeTag[]
    public inspirationTags: WorkshopInspirationTag[]
    public amenities: WorkshopAmenity[]
    public workshopIncluded: string
    public workshopNotIncluded: string
    public menus: WorkshopMenu[]
    public pricingRules: WorkshopPricingRule[]
    public pricePerPerson: number
    public cancellationRules: CancellationRule[]
    public photoUrls: string[]
    public images: FormData

    public availableTimeslots: WorkshopTimeAllocation[]
    public customTimeslotsEnabled: boolean
    public customTimeslotsPrice : number

    public address: PropertyAddress
    public propertyPhotoUrl: string
    public propertyManager: PropertyManager

    public propertyName: string
    public spaceName: string
    public isListingActive: boolean
    public covidMeasures: string
    public bookingType:string
}