/// <reference types="@types/googlemaps" />

import { Component, ViewChild, AfterViewInit,OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PropertyManagerReview } from 'app/modules/shared/models/users/property-manager-review';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { PropertyService } from 'app/modules/shared/services/property.service';
import { ReviewService } from 'app/modules/shared/services/review.service';
import { MessagingService } from 'app/modules/shared/services/messaging.service';
import { PropertyAddress } from 'app/modules/shared/models/properties/property-address';
import { Amenity } from 'app/modules/shared/models/properties/Amenity';
import { EbentoMessage } from 'app/modules/shared/models/messaging/ebento-message';
import { EbentoWorkshop } from 'app/modules/shared/models/workshops/ebento-workshop';
import { WorkshopService } from 'app/modules/shared/services/workshop.service';
import { WorkshopBookingService } from 'app/modules/shared/services/workshop-booking.service';
import { DraggableCalendarModal } from 'app/modules/shared/modules/modals/components/draggable-calendar-modal/draggable-calendar-modal.component';
import { WorkshopTimeAllocation } from 'app/modules/shared/models/data-classes/workshop-time-allocation';
import { TimeSpan } from 'app/modules/shared/models/data-classes/time-span';
import { EbentoModalService } from '../../../shared/services/ebento-modal.service';
import { CartService } from '../../services/cart.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { NotificationType } from '../../../../helpers/enums/notificationType';

@Component({
    selector: 'workshop-listing',
    templateUrl: 'workshop-listing.component.html',
    styleUrls: ['./workshop-listing.component.scss']
})

export class WorkshopListing implements AfterViewInit,OnInit {

    workshop: EbentoWorkshop
    reviews: PropertyManagerReview[];
    modalRef: NgbModalRef;
    attendees:number = 0;
    activeImage:string;
    propertyImage:string = "assets/images/profile.png"

    @ViewChild('gmap') gmapElement: any;

    public map: google.maps.Map;

    get workshopPhotoUrl(): string
    {
        if (this.workshop != null && this.workshop.photoUrls != null && this.workshop.photoUrls.length > 0)
            return this.workshop.photoUrls[0];
        return "images/ebento.png";
    }

    amenityQuantity: Object;
    
    constructor(
        public authService: AuthService,
        public workshopBookingService: WorkshopBookingService,
        public propertyService: PropertyService,
        public workshopService: WorkshopService,
        public messagingService: MessagingService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private reviewService: ReviewService,
        private modalService: EbentoModalService,
        private cartService: CartService,
        private notificationService: NotificationService,
        
    ) {
        this.amenityQuantity = {};
    }

    ngOnInit(): void {
        if(this.workshopBookingService?.bookingOptions?.attendees > 0 ){
            this.attendees = this.workshopBookingService.bookingOptions.attendees;
        }
        if (!(this.authService.isLoggedIn() && this.authService.userType == "EventPlanner"))
            this.router.navigate(['index']);
        else
            this.init();
    }

    ngAfterViewInit() {
        if (this.workshop!=null)
            this.SetMap();
    }

    init()
    {
        this.workshop = this.workshopBookingService.workshop;
        this.activeImage = this.workshop.photoUrls[0];
        this.propertyImage = this.workshop.propertyPhotoUrl ? this.workshop.propertyPhotoUrl : 'assets/images/profile.png'; 
        this.reviewService.getReviewsForWorkshop(this.workshop.id).subscribe(
            reviews => {
                this.reviews = reviews;
            }
        );
        
        window.scrollTo(0, 0);
    }

    setBigImage(i:number){
        this.activeImage = this.workshop.photoUrls[i];
    }

    SetMap() {
        var mapProp = {
            center: new google.maps.LatLng(18.5793, 73.8143),
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        var map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
        this.map = map;
        var address = this.workshop.address;
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(address.googlemapcoordx, address.googlemapcoordy),
            map: this.map
        });

        var bounds = new google.maps.LatLngBounds();
        bounds.extend(marker.getPosition());

        this.map.fitBounds(bounds);
        var listener = google.maps.event.addListener(map, "idle", function () {
            if (map.getZoom() > 16) map.setZoom(16);
            google.maps.event.removeListener(listener);
        });
    }

    openModal() {
        
        // const modalRef = this.modalService.open(ModalComponent);
        this.modalRef = this.modalService.open(DraggableCalendarModal, { size: 'xl', });

        this.modalRef.componentInstance.title = 'Reservation Calendar';
        /*if (this.workshopBookingService.bookingTimeslot != null)
            this.modalRef.componentInstance.SetSelections(this.workshopBookingService.bookingTimeslot);*/
        //this.modalRef.result.then(() => { this.bookingTimeslot = this.modalRef.componentInstance.result; }, () => { this.bookingTimeslot = this.modalRef.componentInstance.result; })
        this.modalRef.componentInstance.passBookings.subscribe((receivedEntry) => {
            //console.log("PA kaze:" + JSON.stringify(receivedEntry));
            this.workshopBookingService.bookingTimeslot = receivedEntry;
            this.workshopBookingService.selectedSlotAvailableCount = this.workshopBookingService.GetAvailableSlotsForAllocation(receivedEntry);
            //console.log("Conso" + this.workshopBookingService.selectedSlotAvailableCount);
        })
    }

    public AddressText(address: PropertyAddress) {
        var result: string = "";
        if (address.streetaddress != null && address.streetaddress != "")
            result += address.streetaddress;
        if (address.streetnumber != null && address.streetnumber != "")
            result += " " + address.streetnumber;
        if (address.city != null && address.city != "") {
            if (result != "")
                result += ", ";
            if (address.postalcode != null && address.postalcode != "")
                result += address.postalcode + " ";
            result += address.city;
        }
        if (address.country != null && address.country != "") {
            if (result != "")
                result += ", ";
            result += address.country;
        }
        return result;
    }

    public OptionalAmenities(): Amenity[] {
        var result: Amenity[] = new Array();
        if (this.workshop.amenities != null)
            for (var a of this.workshop.amenities) {
                if (a.isoptional)
                    result.push(a);
            }
        return result;
    }

    public Catering(): Amenity[] {
        var result: Amenity[] = new Array();
        if (this.workshop.amenities != null)
            for (var a of this.workshop.amenities) {
                if (a.amenitytype.isCatering)
                    result.push(a);
            }
        return result;
    }

    public FixedAmenities(): Amenity[] {
        var result: Amenity[] = new Array();
        if (this.workshop.amenities != null)
            for (var a of this.workshop.amenities) {
                if (!a.isoptional && !a.amenitytype.isCatering)
                    result.push(a);
            }
        return result;
    }

    get numbersOfAttendees()
    {
        var array = new Array();
        for (var i = this.workshop.minNumberOfGuests; i <= this.workshop.maxNumberOfGuests; i++) {
            array.push(i);
        }
        return array;
    }
    
    public GetDateString(allocation: WorkshopTimeAllocation): Date {
        return new Date(allocation.startTime);
    }

    public GetTimeString(allocation: WorkshopTimeAllocation): string {
        var start = new Date(allocation.startTime);
        var end = new Date(allocation.endTime);
        var startTime = new TimeSpan(0, start.getHours(), start.getMinutes(), 0);
        var endTime = new TimeSpan(0, end.getHours(), end.getMinutes(), 0);
        return startTime.HoursMinutesString + " - " + endTime.HoursMinutesString;
    }

    incrementAttendees(){
        ++this.attendees;
        this.workshopBookingService.SetAttendeeCount(this.attendees)
    }

    reduceAttendees(){
        if(this.attendees > 0){
            --this.attendees;
        }
        this.workshopBookingService.SetAttendeeCount(this.attendees)
    }

    AddToCart(){

        console.log("add to cart----",this.workshopBookingService.bookingTimeslot);
        console.log("add to cart booking options----",this.workshopBookingService.bookingOptions,this.workshop.id, this.workshop.spaceId);

        const slots = JSON.parse(JSON.stringify(this.workshopBookingService.bookingTimeslot))
        

        const temp = {
            bookedTimeSlots: [{
                    startTime: slots.startTime,
                    endTime: slots.endTime,
                    allocationType: slots.allocationType
                }],
            attendees:this.attendees,
            spaceType: 'Experience',
            optionalAmenities: this.workshopBookingService.bookingOptions.optionalAmenities,
            totalPrice: this.workshopBookingService.BasePrice(),
            serviceId: this.workshop.id,
            spaceName: this.workshop.name,
            propertyName: this.workshop.propertyName,
            image: this.workshop.photoUrls[0]
        }

        this.cartService.addToCart(temp).subscribe(
            (resp) => {
                ++this.cartService.cartcount;
                this.notificationService.notify(NotificationType.Success, `${this.workshop.name} is added to the cart`, "Experience Added to the cart");
            },
            (err) => {
                console.log('add cart error',err);
                this.notificationService.notify(NotificationType.Error, `${this.workshop.name} cannot be added to the cart`, "Please try again later");
            })
    }

    /*
    //TODO: move to service. Did we?
    downloadFile(amenity: Amenity) {
        return this.http
            .get(window.location.origin + '/' + amenity.documenturl, {
                responseType: 'blob'
            }).subscribe(res => {
                //console.log('start download:', res);
                var url = window.URL.createObjectURL(res);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = this.spaceForTile.space.name + "_" + amenity.amenitytype.name + "_details";
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            }, error => {
                console.log('download error:', JSON.stringify(error));
            }, () => {
                //console.log('Completed file download.')
            });
    }*/ 

    bookNow() {
        this.router.navigate(['experience-booking-page']);
    }

    bookByInquiry(){
        this.workshopBookingService.populateBookingOptions();
        this.router.navigate(['enquiry-booking/experience']);
    }

    gotoProfile() {

        this.propertyService.getProperty(this.workshop.propertyId)
            .subscribe(
                () => this.router.navigate(['/property-details']));
    }

    sendMessage()
    {
        var propertyManager = this.workshop.propertyManager;
        this.messagingService.currentRecepient = propertyManager;
        this.messagingService.currentDraft = new EbentoMessage();
        this.router.navigate(['/messages/compose']);
    }
}
