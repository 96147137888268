import { Component, OnInit, Input } from '@angular/core';
import { NewsService } from '../../../../services/news.service';


@Component({
    selector: 'news-page',
    templateUrl: "news-page.component.html",
    styleUrls:['./news-page.component.css']
})
export class NewsPage
{
    constructor(public newsService: NewsService)
    { }
        
    GetTimestamp(): string
    {
        return "" + new Date(this.newsService.openNews.publishDate).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false });
    }

    get formatedText() {
        return this.newsService.openNews.text.replace(new RegExp('\n', 'g'), '<br/>');
    }
}

