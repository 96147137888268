import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Amenity } from '../../models/properties/Amenity';
import { IBookingOptions } from '../../models/booking/booking-options-interface';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'amenity-booker',
    templateUrl: 'amenity-booker.component.html',
    styleUrls: ['amenity-booker.component.scss']
})

export class AmenityBooker {
    @Input() bookingOptions: IBookingOptions
    @Input() amenity: Amenity;
    @Input() nonInput: boolean;
    @Input() disableEdit: boolean;
    @ViewChild('qtyInput') qtyInput: ElementRef;
    get quantity()
    {
        if (this.bookingOptions == null)
            return 0;
        return this.bookingOptions.GetAmenityQty(this.amenity);
    }
    get isChecked()
    {
        if (this.bookingOptions == null)
            return false;
        return this.bookingOptions.GetAmenityStatus(this.amenity);
    }

    constructor(
        private http: HttpClient) {
    }
    
    private addRemoveAmenity(status: boolean) {
        if (status) {
            this.bookingOptions.AddAmenity(this.amenity);
            this.bookingOptions.GetBookingAmenityByAmenityType(this.amenity.amenitytype.id).quantity = this.quantity;
            this.bookingOptions.GetBookingAmenityByAmenityType(this.amenity.amenitytype.id).unitPrice = this.amenity.price;
        }
        else
            this.bookingOptions.RemoveAmenity(this.amenity);
    }

    AmenitySelectionChanged(e) {
        var status = e.target.checked;
        this.addRemoveAmenity(status);
        if (status)
        {
            this.SetQuantity(1);
        }
    }

    SetQuantity(newValue: number) {
        this.bookingOptions.GetBookingAmenityByAmenityType(this.amenity.amenitytype.id).quantity = newValue;
    }

    ChangeQuantity() {
        var value = this.qtyInput.nativeElement.value;
        if (value < 1)
            value = 1;
        this.SetQuantity(Math.round(value));
    }

    downloadFile(amenity: Amenity) {
        return this.http
            .get(window.location.origin + '/' + amenity.documenturl, {
                responseType: 'blob'
            }).subscribe(res => {
                //console.log('start download:', res);
                var url = window.URL.createObjectURL(res);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = amenity.amenitytype.name + "_details";
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            }, error => {
                console.error('download error:', JSON.stringify(error));
            }, () => {
                //console.log('Completed file download.')
            });
    }
}

