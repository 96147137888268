import { Component, Input, OnInit, ViewChild, EventEmitter, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { WeekCalendarView } from "../../../../components/week-calendar-view/week-calendar-view.component";
import { WorkingHours } from "../../../../models/properties/working-hours";
import { BookingSegment } from "../../../../models/booking/booking-segment";
import { DraggableCalendarView } from "app/modules/shared/components/draggable-calendar-view/draggable-calendar-view.component";
import { WorkshopTimeAllocation } from "app/modules/shared/models/data-classes/workshop-time-allocation";
import { all } from "q";
import { EbentoWorkshop } from "app/modules/shared/models/workshops/ebento-workshop";
import { WorkshopBookingService } from "app/modules/shared/services/workshop-booking.service";
import { WsBookingTimeAllocation } from "app/modules/shared/models/data-classes/ws-booking-time-allocation";

@Component({
    selector: 'draggable-calendar-modal',
    templateUrl: "draggable-calendar-modal.component.html"
})
export class DraggableCalendarModal implements OnInit {

    @ViewChild('calendarView') calendarView : DraggableCalendarView;
    @Input() title: string;
    @Input() isInputCalendar: boolean;
    @Input() isWorkingTimeSetup: boolean;
    @Input() markWorkingHours: boolean;
    @Input() workingHours: WorkingHours;
    @Output() passBookings: EventEmitter<any> = new EventEmitter();
    spaceId: number;
    customTimeslotBooking: boolean;
    customTimeslotPrice: number;

    constructor(public activeModal: NgbActiveModal, public workshopBookingService: WorkshopBookingService)
    { }

    public GetSelectedTimeAllocation(): WsBookingTimeAllocation
    {
        return this.calendarView.GetCurrentSelectedTimeAllocation();         
    }

    ngOnInit() {
        this.spaceId = this.workshopBookingService.workshop.spaceId;
        this.customTimeslotBooking = this.workshopBookingService.workshop.customTimeslotsEnabled;
        this.customTimeslotPrice = this.workshopBookingService.workshop.customTimeslotsPrice;
    }

    Close()
    {
        this.passBookings.emit(this.GetSelectedTimeAllocation());
        this.GetSelectedTimeAllocation();
        this.activeModal.close('Close click');
    }
}