import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { SpaceBookingService } from 'app/modules/shared/services/space-booking.service';
import { NotificationService } from 'app/modules/shared/services/notification.service';
import { NotificationType } from 'app/helpers/enums/notificationType';
import { WorkshopBookingService } from 'app/modules/shared/services/workshop-booking.service';
import { EventPlanner } from 'app/modules/shared/models/users/event-planner';
import { InvoiceModalComponent } from 'app/modules/shared/modules/modals/components/invoice-modal/invoice-modal.component';
import { EbentoModalService } from '../../../shared/services/ebento-modal.service';
import { PaymentService } from '../../services/payment.service';
import { CreatePaymentIntent } from '../../../shared/models/payment/create-payment-intent';
import { UpdatePaymentIntent } from '../../../shared/models/payment/update-payment-intent';
import { SubmissionService } from '../../../shared/services/submission.service';

@Component({
    selector: 'workshop-booking-page',
    templateUrl: 'workshop-booking-page.component.html',
    styleUrls: ['workshop-booking-page.component.css']
})

export class WorkshopBookingPage implements OnInit {

    selectedTab: string = 'credit';

    couponCode: string
    email: string
    couponStatus = 'none'
    isSentInvoice: boolean
    eventPlanner: EventPlanner
    submissionInProgress: boolean = false

    constructor(
        public submissionService: SubmissionService,
        public authService: AuthService,
        public workshopBookingService: WorkshopBookingService,
        private router: Router,
        private notificationService: NotificationService,
        private paymentService: PaymentService,
        private modalService: EbentoModalService) {
        this.getScreenSize();
    }

    ngOnInit(): void {
        this.email = this.authService.user.email
        this.getPlanner(); 
        this.workshopBookingService.getBookingPricing(this.workshopBookingService.bookingOptions).subscribe(
            res => {
                console.log("check the pricing",res.totalWithDiscount,this.workshopBookingService.TotalPrice(true));
                if (Math.abs(res.totalWithDiscount - this.workshopBookingService.TotalPrice(true)) < 2) {
                    this.paymentService.createPaymentIntent(new CreatePaymentIntent(this.workshopBookingService.TotalPrice(true), this.authService.user.id)).subscribe();
                }
                else {
                    this.notificationService.notify(NotificationType.Error, "Pricing error. Please try again or contact Ebento.", "Pricing Error");
                }
            });
    }

    selectTab(tab: any) {
        console.log("tabvalue:",tab)
        this.selectedTab = tab;
    }
        

    checkCouponCode()
    {
        this.couponStatus = 'loading';
        if (this.couponCode == "")
        {
            this.couponStatus = 'none';
        }
        else
        {
            this.workshopBookingService.checkCoupon(this.couponCode, this.workshopBookingService.workshop.id).subscribe(coupon => {
            if (coupon != null) {
                this.workshopBookingService.bookingOptions.coupon = coupon;
                this.couponStatus = 'valid';
            }
            else {
                this.workshopBookingService.bookingOptions.coupon = null;
                this.couponStatus = 'invalid';
            }
            });
        }
        console.log(this.paymentService.paymentIntentID);
        this.paymentService.updatePaymentIntent(new UpdatePaymentIntent(this.workshopBookingService.TotalPrice(true), this.paymentService.paymentIntentID)).subscribe();

    }
    payByInvoice() {
        this.submissionInProgress = true;

        this.submissionService.startSubmission();
        this.workshopBookingService.bookingOptions.paymentType.name = "invoice"
        this.workshopBookingService.bookWorkshop().subscribe(
            (res) => {
                this.submissionService.endSubmission();
                console.log("$$$$$" + res);
                if (res) {
                    this.notificationService.notify(NotificationType.Success, "Invoice will be sent to your email", "You successfully booked the worshop");
                    this.router.navigate(["/index"]);
                }
                else {
                    this.notificationService.notify(NotificationType.Error, "Payment by Invoice not accepted. Please check if your account is eligible for Invoice payment, and try again.", "Payment Error");
                }
            },

            (err) => {
                this.submissionService.endSubmission();
                console.error(err)
            }
        );
    }

    /*invoiceEligible(): boolean {
        if (this.eventPlanner == null || this.eventPlanner.invoice == null)
            return false;
        return this.eventPlanner.invoice.invoiceApproved;
    }
    */
    openInvoiceModal() {
        const modalRef = this.modalService.open(InvoiceModalComponent);
        if (this.eventPlanner.invoice) {
            modalRef.componentInstance.title = 'Edit Invoice Address';
            modalRef.componentInstance.invoice = this.eventPlanner.invoice;
        }
        else {
            modalRef.componentInstance.title = "Add Invoice Address";
        }
        modalRef.componentInstance.passEntry
            .subscribe(() => {
                this.authService.getUserInfo();
                this.getPlanner();
                this.modalService.close();
            });
    }

    addAddress () {
        this.router.navigate(['/event-planner/billing-settings']);
    }

    private getPlanner() {
        this.authService.getPlannerInfoByID(this.authService.user.id)
            .subscribe(
                res => {
                    this.eventPlanner = res
                },
                err => console.error(err)
            )
    }

    private invoiceExists(): boolean {
        return (this.eventPlanner != null && this.eventPlanner.invoice != null);
    }

    private goBack() {
        this.router.navigate(["experience-listing/" + this.workshopBookingService.workshop.id]);
    }

    scrHeight: any;
    scrWidth: any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
    }
}
