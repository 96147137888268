import { Component, Input, OnInit, SimpleChanges, OnChanges, DoCheck, HostListener, AfterViewInit, ViewChild } from '@angular/core';
import { WorkingHours } from '../../models/properties/working-hours';
import { BookingSegment } from '../../models/booking/booking-segment';
import { SpaceService } from '../../services/space.service';
import { SpaceBookingService } from '../../services/space-booking.service';
import { TimeSpan } from '../../models/data-classes/time-span';
import { TimeAllocation } from '../../models/data-classes/time-allocation';

@Component({
    selector: 'entertainment-calendar',
    templateUrl: 'entertainment-calendar.component.html',
    styleUrls: ['entertainment-calendar.component.scss']
})

export class EntertainmentCalendar implements OnInit, DoCheck, AfterViewInit, OnChanges {

    @ViewChild("scrollable") scrollable;
    @ViewChild('table') tableRef;
    @Input() getBookingsOnInit: boolean = true
    @Input() isDayCalendar: boolean;
    @Input() isInputCalendar: boolean;
    @Input() isWorkingTimeSetup: boolean;
    @Input() useSpaceWorkngHours: boolean;
    @Input() markWorkingHours: boolean;
    @Input() workingHours: WorkingHours;
    @Input() spaceId: number;
    @Input() starttime: number = 12;
    @Input() hidePast: boolean = true;
    @Input() modalUse:string = 'addSpace';

    addSpace: boolean = true;

    lastCellX = -1;
    lastCellY = -1;
    mouseUpResponder;
    mouseMoveResponder;
    maxDragDist = 20;
    dragStartX = 0;
    dragStartY = 0;
    scrolling = false;
    startTouchDist = 0;
    scrollStartTop = 0;
    scrollPending = false;
    mergeHideLUT;
    rowspanLUT;
    colorLUT;
    textLUT;
    rowHeightLUT;
    get LUTsReady() 
    {
        return this.mergeHideLUT != null && this.rowspanLUT != null && this.colorLUT != null && this.rowHeightLUT != null && this.textLUT != null;
    }

    startScrollPosSet: boolean;
    selections: Array<BookingSegment>;
    bookingSegments: Array<Array<BookingSegment>>;
    dayIndex = 0;
    mousedown = false;
    numbers = [1, 2, 3, 4, 5, 6];
    mondayDate: Date;
    weekdaysAbrev = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    weekdaysLetters = ["M", "T", "W", "T", "F", "S", "S"];
    weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    monthAbbrevs = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    times: Array<string>;
    bools; //TODO: brateee
    fulldayToggles: Array<boolean>;

    status;
    lastClickTime = 0;
    lastClickDay = 0;
    fillvalue = false;
    env;

    constructor(public spaceService: SpaceService, public bookingService: SpaceBookingService) {

        this.fulldayToggles = Array<boolean>();
        for (var b = 0; b < 7; b++) {
            this.fulldayToggles.push(false);
        }

        this.selections = new Array<BookingSegment>();
        this.times = new Array<string>();
        for (var i = 0; i <= 23; i++) {
            var hour = i.toString();
            if (i < 10)
                hour = "0" + hour;
            this.times.push(hour + ":00");
            this.times.push(hour + ":30");
        }

        this.mouseUpResponder = () => {

            this.mousedown = false;
        }

        this.mouseMoveResponder = (e) => {

            /*var recto = e.target.getBoundingClientRect();
            console.log(recto);*/
            // console.log(rect);
            var target;
            if (e.center == null) {
                if (e.touches != null)
                {
                    target = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY).id;
                    this.startTouchDist = Math.max(this.startTouchDist, Math.abs(e.touches[0].clientY - this.dragStartY));
                    if (this.scrollPending && this.startTouchDist > this.maxDragDist)
                    {
                        this.scrolling = true;
                        this.scrollPending = false;
                    }
                    if (this.scrolling) {
                        //console.log("$$$$$$" + (e.touches[0].clientY - this.dragStartY));
                        this.tableRef.nativeElement.scrollTop = this.scrollStartTop - (e.touches[0].clientY - this.dragStartY);
                    }
                }
                else
                    target = document.elementFromPoint(e.clientX, e.clientY).id;
            }
            else {
                if (isNaN(e.center.x) || isNaN(e.center.y))
                    return;

                target = document.elementFromPoint(e.center.x, e.center.y).id;
            }
            var strd = target.indexOf('d');
            var timeIndex = parseInt(target.substring(1, strd));
            var dayIndex = parseInt(target.substring(strd + 1, target.length));
            if (this.mousedown) {
                if (!isNaN(timeIndex) && !isNaN(dayIndex)) {
                    if (!(this.lastCellX == dayIndex && this.lastCellY == timeIndex)) {
                        if (Math.abs(dayIndex - this.lastCellX) > Math.abs(timeIndex - this.lastCellY)) {
                                  for (var d = dayIndex; d != this.lastCellX; d += Math.sign(this.lastCellX - dayIndex)) {
                                      var t = Math.round(timeIndex + ((this.lastCellY - timeIndex) * (d - dayIndex) / (this.lastCellX - dayIndex)));
                                      this.Switch(d, t, null);
                                  }
                        }
                        else {
                             for (var t = timeIndex; t != this.lastCellY; t += Math.sign(this.lastCellY - timeIndex)) {
                                 var d = Math.round(dayIndex + ((this.lastCellX - dayIndex) * (t - timeIndex) / (this.lastCellY - timeIndex)));
                                 this.Switch(d, t, null);
                             }
                        }
                        this.Switch(dayIndex, timeIndex, null);
                        this.lastCellX = dayIndex;
                        this.lastCellY = timeIndex
                    }
                }
            }
        }

        document.body.addEventListener("mouseup", this.mouseUpResponder);

        this.mondayDate = new Date();
        var day = this.mondayDate.getDay();

        day--;
        if (day < 0) day = 6;
        this.dayIndex = day;
        this.mondayDate.setDate(this.mondayDate.getDate() - day);
        this.mondayDate.setHours(0, 0, 0);

        this.Initialize(true);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.GetHeadWidth();
    }

    scrHeight: any;
    scrWidth: any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
    }
    
    headWidth: string;

    GetHeadWidth(): void {
        if (this.scrollable != null) {

            var newHeadWidth = this.scrollable.nativeElement.clientWidth + "px";

            if (this.headWidth != newHeadWidth) {
                this.headWidth = newHeadWidth;
            }
        }
    }

    ngDoCheck() {
        this.GetHeadWidth();
    }

    ngOnDestroy(): void {
        document.body.removeEventListener("mouseup", this.mouseUpResponder);
    }

    ngOnInit(): void {

        this.env = this.findBootstrapEnvironment();
        this.getScreenSize()
        if (this.getBookingsOnInit)
            this.GetBookings();
        if (this.useSpaceWorkngHours)
            this.GetWorkingHours();
    }

    ngAfterViewInit() {
        this.GetHeadWidth();
        this.tableRef.nativeElement.addEventListener("mousemove", this.mouseMoveResponder, { passive: true, capture: true });
        this.tableRef.nativeElement.addEventListener("touchmove", this.mouseMoveResponder, { passive: true, capture: true });
    }

    ngAfterViewChecked() {
        if (!this.startScrollPosSet) {
            this.startScrollPosSet = true;
            this.scrollable.scrollTop = (12 - this.starttime) / 3 * 100;
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.getBookingsOnInit)
            this.GetBookings();
        if (this.useSpaceWorkngHours)
            this.GetWorkingHours();

    }

    GetBookings() {
        this.bookingService.getBookingsForSpace(this.spaceId).subscribe(success => {
            if (success) {
                this.Initialize();
            }
            else {
                console.error("Error");
            }
        });
    }

    GetWorkingHours() {
        this.spaceService.getWorkingHours(this.spaceId).subscribe(success => {
            if (success) {
                this.workingHours = this.spaceService.workingHours;
                this.Initialize();
            }
            else {
                console.error("Error");
            }
        });
    }

    SetWorkingHours(dayIndex: number, startTime: TimeSpan, endTime: TimeSpan) {
        var startIndex = startTime.Hours * 2;
        if (startTime.Minutes > 1)
            startIndex++;
        var endIndex = endTime.Hours * 2 + endTime.Days * 48;
        if (endTime.Minutes > 1)
            endIndex++;
        if (!this.markWorkingHours) {
            startIndex = 0;
            endIndex = 46;
        }
        for (var i = startIndex; i < endIndex; i++) {
            this.status[dayIndex][i] = 0;
        }
    }

    SetNonWorkingDay(dayIndex: number) {
        for (var i = 0; i < 46; i++) {
            this.status[dayIndex][i] = 0;
        }
        this.rebuildLUTs();
    }

    public SetSelectionsToTimeAllocations(allocations: TimeAllocation[]) {
        var _selections = [];
        for (let allocation of allocations) {
            _selections.push(BookingSegment.FromTimeAllocation(allocation));
        }

        this.selections = _selections;
        this.Initialize();
    }

    public SetSelections(_selections: BookingSegment[]) {
        this.selections = _selections;
        this.Initialize();
    }

    Initialize(weekChanged: boolean = false) {
        if (!weekChanged) {
            for (let selection of this.GetAllVisibleSelections())
                this.selections.push(selection);
        }

        this.bools = new Array<Array<boolean>>();
        this.bookingSegments = new Array<Array<BookingSegment>>();
        for (var d = 0; d < 7; d++) {
            var tempSegmentDay = new Array<BookingSegment>();
            var tempday = new Array<boolean>();
            for (var t = 0; t <= 23; t++) {
                tempSegmentDay.push(null);
                tempSegmentDay.push(null);
                tempday.push(false);
                tempday.push(false);
            }
            this.bools.push(tempday);
            this.bookingSegments.push(tempSegmentDay);
        }

        // var defaultCellState = (this.workingHours != null || this.useSpaceWorkngHours) ? 1 : 0;
        var defaultCellState = 0;

        this.status = new Array<Array<number>>();
        for (var d = 0; d < 7; d++) {
            var tempday2 = new Array<number>();
            for (var t = 0; t <= 23; t++) {
                tempday2.push(defaultCellState);
                tempday2.push(defaultCellState);
            }
            this.status.push(tempday2);
            
        }


        var minStartIndex = 12;
        if (this.workingHours != null)
            for (var i = 0; i < 7; i++) {
                if (this.workingHours.workingdays[i]) {
                    var startIndex = this.workingHours.starttimes[i].Hours * 2;
                    if (this.workingHours.starttimes[i].Minutes > 1)
                        startIndex++;
                    startIndex -= 2;
                    minStartIndex = Math.min(minStartIndex, startIndex);
                }
                this.SetWorkingHours(i, this.workingHours.starttimes[i], this.workingHours.endtimes[i]);
                if (!this.workingHours.workingdays[i])
                    this.SetNonWorkingDay(i);
            }

        this.starttime = Math.max(0, minStartIndex);

        this.MarkBookings();
        this.MarkSelections();
        this.RemoveVisibleSelecitonsFromList();
        this.rebuildLUTs();
        /*window.addEventListener("scroll", preventMotion, { passive: false });
        window.addEventListener("touchmove", preventMotion, { passive: false });
   
        function preventMotion(event) {
            window.scrollTo(0, 0);
            event.preventDefault();
            event.stopPropagation();
        }     */
    }

    MarkSelections() {
        if (this.selections != null) {
            for (let selection of this.selections) {

                var nextMonday = new Date(this.mondayDate);
                nextMonday.setDate(nextMonday.getDate() + 7);
                if (selection.startTime > this.mondayDate && selection.startTime < nextMonday) {
                    var dayIndex = selection.startTime.getDay();
                    dayIndex--;
                    if (dayIndex < 0) dayIndex = 6;
                    var timeIndex = selection.startTime.getHours() * 2;
                    if (selection.startTime.getMinutes() > 0)
                        timeIndex++;
                    var count = selection.duration.Hours * 2;
                    if (selection.duration.Minutes > 0)
                        count++;
                    for (var i = 0; i < count; i++) {
                        this.bools[dayIndex][i + timeIndex] = true;
                    }
                }
            }
        }
    }

    RemoveVisibleSelecitonsFromList() {
        var newList = new Array<BookingSegment>();
        if (this.selections != null)
            for (let selection of this.selections) {
                var nextMonday = new Date(this.mondayDate);
                nextMonday.setDate(nextMonday.getDate() + 7);
                if (selection.startTime < this.mondayDate || selection.startTime > nextMonday) {
                    newList.push(selection);
                }
            }
        this.selections = newList;
    }

    MarkBookings() {
        if (this.bookingService.bookingSegments != null)
            for (let bookingSegment of this.bookingService.bookingSegments) {
                if (this.bookingService.bookingToEdit == null || bookingSegment.bookingID != this.bookingService.bookingToEdit.idBooking) {
                    var nextMonday = new Date(this.mondayDate);
                    nextMonday.setDate(nextMonday.getDate() + 7);
                    if (bookingSegment.startTime > this.mondayDate && bookingSegment.startTime < nextMonday) {
                        var dayIndex = bookingSegment.startTime.getDay();
                        dayIndex--;
                        if (dayIndex < 0) dayIndex = 6;
                        var timeIndex = bookingSegment.startTime.getHours() * 2;
                        if (bookingSegment.startTime.getMinutes() > 0)
                            timeIndex++;
                        var count = bookingSegment.duration.Hours * 2;
                        if (bookingSegment.duration.Minutes > 0)
                            count++;
                        for (var i = 0; i < count; i++) {
                            this.status[dayIndex][i + timeIndex] = 2;
                        }
                        this.bookingSegments[dayIndex][timeIndex] = bookingSegment;
                    }
                }
            }
    }

    NextWeek() {
        if (!this.isDayCalendar || this.dayIndex == 6) {
            for (let selection of this.GetAllVisibleSelections())
                this.selections.push(selection);
            this.mondayDate.setDate(this.mondayDate.getDate() + 7);
            this.dayIndex = 0;
            this.Initialize(true);
        }
        else {
            this.dayIndex++;
        }
    }

    PreviousWeek() {
        if (!this.isDayCalendar || this.dayIndex == 0) {
            for (let selection of this.GetAllVisibleSelections())
                this.selections.push(selection);
            this.mondayDate.setDate(this.mondayDate.getDate() - 7);
            this.dayIndex = 6;
            this.Initialize(true);
        }
        else {
            this.dayIndex--;
        }
    }

    GetCaption(): string {
        var monthIndex = this.mondayDate.getMonth();
        if (this.isDayCalendar) {
            var date = new Date(this.mondayDate);
            date.setDate(date.getDate() + this.dayIndex);
            monthIndex = date.getMonth();
        }
        return this.monthNames[monthIndex] + " " + this.mondayDate.getFullYear();
    }

    Switch(dayIndex: number, timeIndex: number, event): void {
        if (this.isInputCalendar) {
                if (!this.hidePast || !(this.GetDateForSlot(dayIndex, timeIndex) < this.GetDateToday()))
                    if (!this.status[dayIndex][timeIndex])
                        this.bools[dayIndex][timeIndex] = this.fillvalue;
        }
        this.updateLUTs(dayIndex, timeIndex);
    }

    FulldayToggle(dayIndex: number) {
        for (var i = 0; i < 46; i++) {
            this.bools[dayIndex][i] = this.fulldayToggles[dayIndex];
        }
    }

    OnDown(dayIndex: number, timeIndex: number, event): void {
        console.log("down::" + dayIndex + ":: " + timeIndex);
        if (this.isInputCalendar) {
            if (!this.status[dayIndex][timeIndex])
                if (!this.hidePast || !(this.GetDateForSlot(dayIndex, timeIndex) < this.GetDateToday())) {
                    console.log("YE!");
                    this.mousedown = true;
                    this.fillvalue = !this.bools[dayIndex][timeIndex];
                    this.bools[dayIndex][timeIndex] = this.fillvalue;
                    this.lastClickTime = timeIndex;
                    this.lastClickDay = dayIndex;
                }
            this.updateLUTs(dayIndex, timeIndex);
        }
    }

    GetDayName(index: number) {
        var env = this.findBootstrapEnvironment();
        if (env == 'xs')
            return this.weekdaysLetters[index];
        if (env == 'sm' || env == 'md')
            return this.weekdaysAbrev[index];
        return this.weekdays[index];
    }

    GetDate(index: number) {
        var env = this.findBootstrapEnvironment();
        var date = new Date(this.mondayDate);
        date.setDate(date.getDate() + index);
        if (env == 'sm' || env == 'md'|| env == 'lg' )
            return this.monthAbbrevs[date.getMonth()] + " " + date.getDate();
        else if (env == 'xs')
            return date.getDate();
        else
            return this.monthNames[date.getMonth()] + " " + date.getDate();
    }

    GetDateHeaderColor(index: number) {
        var date = new Date(this.mondayDate);
        date.setDate(date.getDate() + index);
        if (this.hidePast && date < this.GetDateToday())
            return "#c5c5d1";
        return "#ffffff";
    }

    GetDateToday(): Date {
        var date = new Date();
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }


    GetBookingSegmentStartForCell(dayIndex: number, timeIndex: number): BookingSegment {
        if (this.bookingSegments == null)
            return null;
        return this.bookingSegments[dayIndex][timeIndex];
    }

    findBootstrapEnvironment(): string {
        let envs = ['xs', 'sm', 'md', 'lg', 'xl'];

        let el = document.createElement('div');
        document.body.appendChild(el);

        let curEnv = envs.shift();

        for (let env of envs.reverse()) {
            el.classList.add(`d-${env}-none`);

            if (window.getComputedStyle(el).display === 'none') {
                curEnv = env;
                break;
            }
        }

        document.body.removeChild(el);
        return curEnv;
    }

    /// Test Feature Only
    ToggleInputMode()
    {
        this.isInputCalendar = !this.isInputCalendar;
    }

    GetDateForSlot(dayIndex: number, timeIndex: number): Date
    {
        var hours = Math.floor(timeIndex / 2);
        var minutes = timeIndex % 2 * 30;
        var result = new Date(this.mondayDate.getFullYear(), this.mondayDate.getMonth(), this.mondayDate.getDate() + dayIndex, hours, minutes);
        return result;
    }

    GetAllVisibleSelections() : Array <BookingSegment>
    {
        var crvici = new Array<BookingSegment>();
        var temp_crvic: BookingSegment;
        var halfHours: number;
        for (var dayIndex = 0; dayIndex < 7; dayIndex++)
        {
            temp_crvic = null;
            for (var timeIndex = 0; timeIndex < 47; timeIndex++)
            {
                if (this.bools[dayIndex][timeIndex]) {
                    if (timeIndex == 0 || !this.bools[dayIndex][timeIndex - 1]) {
                        temp_crvic = new BookingSegment(this.GetDateForSlot(dayIndex, timeIndex), new TimeSpan(0, 0, 0, 0));
                        halfHours = 1;
                    }
                    else {
                        halfHours++;
                    }
                }
                else {
                    if (temp_crvic != null) {
                        temp_crvic.duration = new TimeSpan(0, Math.floor(halfHours / 2), halfHours % 2 * 30, 0);
                        crvici.push(temp_crvic);
                        temp_crvic = null;
                    }
                }
            }
            if (temp_crvic != null) {
                temp_crvic.duration = new TimeSpan(0, Math.floor(halfHours / 2), halfHours % 2 * 30, 0);
                crvici.push(temp_crvic);
                temp_crvic = null;
            }
        }
        return crvici;
    }

    LogAllSelections()
    {
        console.log("SELECTED CRVITCI:");
        for (let c of this.GetAllVisibleSelections() ) {
            console.log(c.startTime.toLocaleString('en-US') + " FOR " + c.duration.HoursMinutesString);
        }
        for (let c of this.selections) {
            console.log(c.startTime.toLocaleString('en-US') + " FOR " + c.duration.HoursMinutesString);
        }       
    }

    public GetAllSelections(): BookingSegment[]
    {
        var result = new Array<BookingSegment>();
            
        for (let c of this.GetAllVisibleSelections()) {
            result.push(c);
        }
        for (let c of this.selections) {
            result.push(c);
        }
        return result;
    }

    SearchAvailableSpaces()
    {
        var tas = new Array<TimeAllocation>();
        for (let c of this.GetAllVisibleSelections()) {
            var ta = new TimeAllocation();
            ta.startTime = c.startTime.toLocaleString('en-US');
            var endTime = new Date(c.startTime);
            endTime.setHours(endTime.getHours() + c.duration.Hours);
            endTime.setMinutes(endTime.getMinutes() + c.duration.Minutes);
            ta.endTime = endTime.toLocaleString('en-US');
            tas.push(ta);
        }
        for (let c of this.selections) {
            var ta = new TimeAllocation();
            ta.startTime = c.startTime.toLocaleString('en-US');
            var endTime = new Date(c.startTime);
            endTime.setHours(endTime.getHours() + c.duration.Hours);
            endTime.setMinutes(endTime.getMinutes() + c.duration.Minutes);
            ta.endTime = endTime.toLocaleString('en-US');
            tas.push(ta);
        }
        this.spaceService.getAvailableSpaces(tas).subscribe(success => {
            if (success) {
            }
            else {
                console.error("Error.");
            }
        });
    }

    MouseDownTable(e) {
        //console.log(e);
        var target;
        if (e.center == null) {
            if (e.touches != null)
                target = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY).id;
            else
                target = document.elementFromPoint(e.clientX, e.clientY).id;
        }
        else {
            if (isNaN(e.center.x) || isNaN(e.center.y))
                return;

            target = document.elementFromPoint(e.center.x, e.center.y).id;
        }
        var strd = target.indexOf('d');

        var timeIndex = parseInt(target.substring(1, strd));
        var dayIndex = parseInt(target.substring(strd + 1, target.length));
        this.OnDown(dayIndex, timeIndex, null);

        this.lastCellX = dayIndex;
        this.lastCellY = timeIndex;
    }

    rebuildLUTs() {
        this.buildMergeHideLUT();
        this.buildRowspanLUT();
        this.buildColorLUT();
        this.buildRowHeightLUT();
        this.buildTextLUT();
    }

    buildRowspanLUT() {
        this.rowspanLUT = new Array<Array<number>>();
        for (var d = 0; d < 7; d++) {
            var col = new Array<number>();
            for (var ti = 0; ti < this.times.length; ti++) {
                var val = 1;
                if (this.isInputCalendar) {
                    if (this.status[d][ti] == 2) {
                        if (ti == this.starttime || this.status[d][ti - 1] != 2) {
                            var t = ti;
                            while (t < 46 && this.status[d][t] == 2)
                                t++;
                            val = t - ti;
                        }
                        else val = 1;
                    }
                }

                else if (this.status[d][ti] != 0) {
                    if (ti == this.starttime || this.status[d][ti - 1] != this.status[d][ti] || this.GetBookingSegmentStartForCell(d, ti) != null) {
                        var t = ti + 1;
                        while (t <= 47 && this.status[d][t] == this.status[d][t - 1] && this.GetBookingSegmentStartForCell(d, t) == null)
                            t++;
                        val = t - ti;
                    }
                    else val = 1;
                }
                col.push(val);
            }
            this.rowspanLUT.push(col);
        }
    }


    buildMergeHideLUT() {
        this.mergeHideLUT = new Array<Array<boolean>>();
        for (var d = 0; d < 7; d++) {
            var col = new Array<boolean>();
            for (var t = 0; t < this.times.length; t++) {
                var val = false;
                if (this.isInputCalendar) {
                    if (this.status[d][t] == 2) {
                        if (t == this.starttime || this.status[d][t - 1] != 2) {
                            val = false;
                        }
                        else {
                            val = true;
                        }
                    }
                }
                else {
                    if (this.status[d][t] != 0) {
                        if ((t == this.starttime) || this.status[d][t - 1] != this.status[d][t] || this.GetBookingSegmentStartForCell(d, t) != null) {
                            val = false;
                        }
                        else
                            val = true;
                    }
                }
                col.push(val);
            }
            this.mergeHideLUT.push(col);
        }
    }

    buildColorLUT() {
        this.colorLUT = new Array<Array<string>>();
        for (var d = 0; d < 7; d++) {
            var col = new Array<string>();
            for (var t = 0; t < this.times.length; t++) {
                var val = "F0F2F5";
                if (this.hidePast && this.GetDateForSlot(d, t) < this.GetDateToday()) {
                    if (t % 2 != 0)
                        val = "#bab9c7";
                    else
                        val = "#c5c5d1";
                }
                else {
                    // console.log('status values',this.status);
                    
                    if (this.status[d][t] == 1)
                        val = '#b28097'; //non working hour color
                    else if (this.status[d][t] == 2) {
                        if (this.isInputCalendar)
                            val = '#a02c1c';
                        else
                            val = '#02d13c';
                    }
                    else if (this.bools[d][t]) {
                        if (this.isWorkingTimeSetup)
                            val = '#a02c1c'; //grey
                        else
                            val = '#02d13c'; //green
                    }
                    else
                        val = '#F0F2F5'; //white
                    if (t % 2 == 0) {
                        if (val == '#F0F2F5')
                            val = '#F9FAFB'; //white
                        else
                            val += 'EE';
                    }
                }
                col.push(val);
            }
            this.colorLUT.push(col);
        }

        // console.log("bg colors",this.colorLUT);
        
    }

    buildRowHeightLUT() {
        this.rowHeightLUT = new Array<Array<string>>();
        for (var d = 0; d < 7; d++) {
            var col = new Array<string>();
            for (var t = 0; t < this.times.length; t++) {
                var val = "2rem";
                if (this.env == 'xs' || this.env == 'sm' || this.env == 'md')
                    val = (4 * this.rowspanLUT[d][t] + 'rem');
                col.push(val);
            }
            this.rowHeightLUT.push(col);
        }
    }

    buildTextLUT() {
        this.textLUT = new Array<Array<string>>();
        for (var d = 0; d < 7; d++) {
            var col = new Array<string>();
            for (var t = 0; t < this.times.length; t++) {
                var val = "";
                this.GetBookingSegmentStartForCell(d, t);
                if (this.status[d][t] == 2) {
                    var bookingSegment = this.GetBookingSegmentStartForCell(d, t);
                    if (bookingSegment != null) {
                        var text = "";
                        if (bookingSegment.duration.totalseconds > 1800)
                            text += bookingSegment.GetTimeString() + "\r\n";
                        //WORKAROUND. there should be no name in dto.
                        if (!this.isInputCalendar)
                            text += bookingSegment.name;
                        val = text;
                    }
                    else {
                        if (t == 0 || this.status[d][t - 1] != 2) {
                            val = "Space Booked";
                        }
                    }
                }

                else if (this.bools[d][t]) {
                    if (t == 0 || !this.bools[d][t - 1]) {
                        var tLoop = t;
                        while (tLoop < 47 && this.bools[d][tLoop])
                            tLoop++;
                        val = this.times[t] + " - " + this.times[tLoop];
                    }
                    else val = "";
                }
                col.push(val);
            }
            this.textLUT.push(col);
        }
    }

    updateLUTs(d: number, t: number) {
        var rowspanVal = 1;
        if (this.isInputCalendar) {
            if (this.status[d][t] == 2) {
                if (t == this.starttime || this.status[d][t - 1] != 2) {
                    var tloop = t;
                    while (tloop < 46 && this.status[d][tloop] == 2)
                        tloop++;
                    rowspanVal = tloop - t;
                }
                else rowspanVal = 1;
            }
        }

        if (this.status[d][t] != 0) {
            if (t == this.starttime || this.status[d][t - 1] != this.status[d][t] || this.GetBookingSegmentStartForCell(d, t) != null) {
                var tloop = t + 1;
                while (tloop <= 47 && this.status[d][tloop] == this.status[d][tloop - 1] && this.GetBookingSegmentStartForCell(d, tloop) == null)
                    tloop++;
                rowspanVal = tloop - t;
            }
            else rowspanVal = 1;
        }

        this.rowspanLUT[d][t] = rowspanVal;

        var rowHeightVal = "2rem";
        var env = this.findBootstrapEnvironment();
        if (env == 'xs' || env == 'sm' || env == 'md')
            rowHeightVal = (4 * this.rowspanLUT[d][t] + 'rem');
        this.rowHeightLUT[d][t] = rowHeightVal;

        var colorVal = "F0F2F5";
        if (this.hidePast && this.GetDateForSlot(d, t) < this.GetDateToday()) {
            if (t % 2 != 0)
                colorVal = "#bab9c7";
            else
                colorVal = "#c5c5d1";
        }
        else {
            if (this.status[d][t] == 1)
                colorVal = '#b28097';
            else if (this.status[d][t] == 2) {
                if (this.isInputCalendar)
                    colorVal = '#a02c1c';
                else
                    colorVal = '#02d13c';
            }
            else if (this.bools[d][t]) {
                if (this.isWorkingTimeSetup)
                    colorVal = '#a02c1c';
                else
                    colorVal = '#02d13c';
            }
            else
                colorVal = '#F0F2F5';
            if (t % 2 == 0) {
                if (colorVal == '#F0F2F5')
                    colorVal = '#F9FAFB';
                else
                    colorVal += 'EE';
            }
        }
        this.colorLUT[d][t] = colorVal;

        var mergeVal = false;
        if (this.isInputCalendar) {
            if (this.status[d][t] == 2) {
                if (t == this.starttime || this.status[d][t - 1] != 2) {
                    mergeVal = false;
                }
                else
                    mergeVal = true;
            }
        }
        else {
            if (this.status[d][t] != 0) {
                if ((t == this.starttime) || this.status[d][t - 1] != this.status[d][t] || this.GetBookingSegmentStartForCell(d, t) != null) {
                    mergeVal = false;
                }
                else
                    mergeVal = true;
            }
        }
        this.mergeHideLUT[d][t] = mergeVal;

        this.buildTextLUT();
    }

    formatTimeText(time: string) {
        if (this.env == 'xs')
            return time.replace(":", "\n");
        return time;
    }

    panStart(ev)
    {
        ev.preventDefault();
        if (!this.mousedown) {
            this.scrolling = false;
            this.scrollPending = true;
            this.startTouchDist = 0;
            this.scrollStartTop = this.tableRef.nativeElement.scrollTop;
            this.dragStartX = ev.touches[0].clientX;
            this.dragStartY = ev.touches[0].clientY;
            window.setTimeout(() => {
                if (this.scrollPending) {
                    this.scrollPending = false;
                    if (this.startTouchDist < this.maxDragDist)
                        this.MouseDownTable(ev);
                    else
                        this.scrolling = true;
                }
            }, 100);
        }
    }

    touchEnd(ev) {
        if (this.scrollPending)
        {
            this.MouseDownTable(ev);
        }
        this.scrolling = false;
        this.scrollPending = false;
        this.mousedown = false;
    }
}


//GetRowHeight(dayIndex: number, timeIndex: number) {
//    var env = this.findBootstrapEnvironment();
//    if (env == 'xs' || env == 'sm' || env == 'md')
//        return (4 * this.GetRowspan(dayIndex, timeIndex) + 'rem');
//    else return '2rem'
//}


//GetRowspan(dayIndex: number, timeIndex: number): number {
//    // blokirano za input calendar, merge samo prebukiranih
//    if (this.isInputCalendar) {
//        if (this.status[dayIndex][timeIndex] == 2) {
//            if (timeIndex == this.starttime || this.status[dayIndex][timeIndex - 1] != 2) {
//                var t = timeIndex;
//                while (t < 46 && this.status[dayIndex][t] == 2)
//                    t++;
//                return t - timeIndex;
//            }
//            return 1;
//        }
//        return 1;
//    }

//    if (this.status[dayIndex][timeIndex] != 0) {
//        if (timeIndex == this.starttime || this.status[dayIndex][timeIndex - 1] != this.status[dayIndex][timeIndex] || this.GetBookingSegmentStartForCell(dayIndex, timeIndex) != null) {
//            var t = timeIndex + 1;
//            while (t <= 47 && this.status[dayIndex][t] == this.status[dayIndex][t - 1] && this.GetBookingSegmentStartForCell(dayIndex, t) == null)
//                t++;
//            return t - timeIndex;

//        }
//        return 1;
//    }
//    return 1;
//}

//HideMerged(dayIndex: number, timeIndex: number) {
//    // blokirano za input calendar, merge samo prebukiranih
//    if (this.isInputCalendar) {
//        if (this.status[dayIndex][timeIndex] == 2) {
//            if (timeIndex == this.starttime || this.status[dayIndex][timeIndex - 1] != 2) {
//                return false;
//            }
//            return true;
//        }
//        return false;
//    }

//    if (this.status[dayIndex][timeIndex] != 0) {
//        if ((timeIndex == this.starttime) || this.status[dayIndex][timeIndex - 1] != this.status[dayIndex][timeIndex] || this.GetBookingSegmentStartForCell(dayIndex, timeIndex) != null) {
//            return false;
//        }
//        return true;
//    }
//    return false;
//}

//GetColor(dayIndex: number, timeIndex: number): string {
//    if (this.hidePast && this.GetDateForSlot(dayIndex, timeIndex) < this.GetDateToday()) {
//        if (timeIndex % 2 != 0)
//            return "#bab9c7";
//        return "#c5c5d1";
//    }
//    var result = "";
//    if (this.status[dayIndex][timeIndex] == 1)
//        result = '#b28097';
//    else if (this.status[dayIndex][timeIndex] == 2) {
//        if (this.isInputCalendar)
//            result = '#a02c1c';
//        else
//            result = '#02d13c';
//    }
//    else if (this.bools[dayIndex][timeIndex]) {
//        if (this.isWorkingTimeSetup)
//            result = '#a02c1c';
//        else
//            result = '#02d13c';
//    }
//    else
//        result = '#F0F2F5';

//    if (timeIndex % 2 == 0) {
//        if (result == '#F0F2F5')
//            result = '#F9FAFB';
//        else
//            result += 'EE';
//    }
//    return result;
//}

//GetCellText(dayIndex: number, timeIndex: number): string {
//    this.GetBookingSegmentStartForCell(dayIndex, timeIndex);
//    if (this.status[dayIndex][timeIndex] == 2) {
//        var bookingSegment = this.GetBookingSegmentStartForCell(dayIndex, timeIndex);
//        if (bookingSegment != null) {
//            var text = "";
//            if (bookingSegment.duration.totalseconds > 1800)
//                text += bookingSegment.GetTimeString() + "\r\n";
//            //WORKAROUND. there should be no name in dto.
//            if (!this.isInputCalendar)
//                text += bookingSegment.name;
//            return text;
//        }
//        if (timeIndex == 0 || this.status[dayIndex][timeIndex - 1] != 2) {
//            return "Space Booked";
//        }
//        return "";
//    }

//    if (this.bools[dayIndex][timeIndex]) {
//        if (timeIndex == 0 || !this.bools[dayIndex][timeIndex - 1]) {
//            var t = timeIndex;
//            while (t < 47 && this.bools[dayIndex][t])
//                t++;
//            return this.times[timeIndex] + " - " + this.times[t];
//        }
//        return "";
//    }
//    return "";
//}