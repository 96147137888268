import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Router } from "@angular/router";

@Component({
    selector: 'booked-on-cell',
    templateUrl: 'booked-on-cell.component.html'
})
export class BookedOnCell implements ICellRendererAngularComp {
    public params: any;

    constructor(private router: Router)
    { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    GetDateTimeString(dateString): string {
        var date = new Date(dateString);
        return date.toLocaleString();
    }

    cancelBooking() {        
        if (this.params.data.workshopID != null)
            this.router.navigate(['/property-manager/cancel-experience/' + this.params.data.idBooking]);
        else
            this.router.navigate(['/property-manager/cancel-booking/' + this.params.data.idBooking]);
    }
}