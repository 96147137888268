export class CreditCard {

    public id: number
    public cardName: string
    public ccNumber: string
    public expiryYear: string
    public expiryMonth: string
    public cvvNumber: string

    constructor() { }
}