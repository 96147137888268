import { Component, OnInit } from '@angular/core';
import { CareerDTO } from '../../../../models/simple-pages/career-dto';
import { SimplePagesService } from '../../../../services/simple-pages.service';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {

    model: CareerDTO;
    imageUrl: string = '';
    text: string = '';

    constructor(private simplePagesService: SimplePagesService) { }

    ngOnInit() {
        this.simplePagesService.getCareerPageData().subscribe(
            res => {
                if (res != null) {
                    this.model = res;
                    this.text = this.model.body;
                    this.imageUrl = this.model.imageUrl;
                }
                else {
                    this.model = new CareerDTO()
                }
            }
        )
    }

}
