import { EbentoUser } from "../users/ebento-user";
import { EbentoDocument } from "./ebento-document";

export class EbentoMessage
{
    public idMessage: number
    public sender: EbentoUser
    public receiver: EbentoUser
    public messageText: string
    public subject: string
    public timeSent: Date
    public lastEdit: Date
    public isMine: boolean
    public isRead: boolean
    public isSent: boolean
    public isDeleted: boolean
    public isDeletedBySender: boolean
    public isMarkedForDeletion: boolean
    public isMarkedForDeletionBySender: boolean
    public attachments: EbentoDocument[]
    public attachmentURLs: string[]
    public requestId : string
    public type : string
    public spaceName: string
    public propertyName: string
}
