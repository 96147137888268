/// <reference types="@types/googlemaps" />

import { Component, OnInit, Injector, Input } from '@angular/core';
import { Router } from "@angular/router";
import { SpaceForTile } from 'app/modules/shared/models/search/space-for-tile';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { AdminSpaceService } from 'app/modules/admin/services/admin-space.service';
import { IndexCarousel } from '../index-carousel.component';
import { SpaceCarouselTile } from '../../carousel-tile/space-carousel-tile/space-carousel-tile.component';

@Component({
    selector: 'unique-spaces',
    templateUrl: '../index-carousel.component.html'
})

export class UniqueSpaces extends IndexCarousel implements OnInit {
    
    @Input() color:string = '#212529';
    @Input() headerLabel = 'Discover Your Unique Space';
    protected tileComponent = SpaceCarouselTile;
    domID = 'unique';

    constructor(public spaceService: SpaceService, injector: Injector)
    {
        super(injector);
    }

    get apiGetter() {
        return this.spaceService.getUniqueSpaces();
    }


}
