import { Component, OnInit, ViewChild, AfterViewChecked, ElementRef, AfterViewInit, AfterContentInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { StripeService } from "ngx-stripe";
import { StripeElements, StripeCardElement, StripeElementsOptions, PaymentIntent } from '@stripe/stripe-js';
import { Router } from '@angular/router';
import { SpaceBookingService } from 'app/modules/shared/services/space-booking.service';
import { CartService } from '../../../event-planner/services/cart.service';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { NotificationService } from 'app/modules/shared/services/notification.service';
import { NotificationType } from 'app/helpers/enums/notificationType';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaymentService } from '../../../event-planner/services/payment.service';
import { CreatePaymentIntent } from '../../../shared/models/payment/create-payment-intent';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '../../../shared/components/dropdown/dropdown.component';
import { SubmissionService } from '../../../shared/services/submission.service';

@Component({
  selector: 'cart-stripe-payment',
  templateUrl: './cart-stripe-payment.component.html',
  styleUrls: ['./cart-stripe-payment.component.scss']
})
export class CartStripePaymentComponent implements AfterViewInit, OnInit {

    faTimesCircle = faTimesCircle;
    @ViewChild('cardDropdown') cardDropdown: Dropdown
    @ViewChild('cardElement') cardElement: ElementRef
    elements: StripeElements
    selectedSavedCard: null;
    card: StripeCardElement
    
    isSubmitted: boolean = false
    submissionInProgress: boolean = false
    userName: string
    // optional parameters
    elementsOptions: StripeElementsOptions = {
        locale: 'en',
    };

    stripeTest: FormGroup;

    constructor(
        public submissionService: SubmissionService,
        private fb: FormBuilder,
        private stripeService: StripeService,
        public bookingService: SpaceBookingService,
        public authService: AuthService,
        public notificationService: NotificationService,
        public paymentService: PaymentService,
        public router: Router, 
        public http: HttpClient,
        public cartService:CartService
        ) { }

    ngOnInit() {
        this.paymentService.getCards().subscribe();
        
    }

    ngAfterViewInit() {
        this.stripeTest = this.fb.group({
            name: [this.userName]
        });
        this.submissionInProgress = false;
        this.isSubmitted = false;
        this.userName = this.authService.getUserName()
        
        this.stripeService.elements(this.elementsOptions)
            .subscribe(elements => {
                this.elements = elements;
            
                    this.card = this.elements.create('card', {
                        style: {
                            base: {
                                iconColor: '#666EE8',
                                color: '#31325F',
                                lineHeight: '40px',
                                fontWeight: 300,
                                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                                fontSize: '18px',
                                '::placeholder': {
                                    color: '#CFD7E0'
                                }
                            },
                        },
                        hidePostalCode: true
                    });
                this.card.mount(this.cardElement.nativeElement);
            });
    }

    cardInputComplete() {
        if (this.cardElement == null || this.cardElement.nativeElement == null) return false;
        return this.cardElement.nativeElement.classList.contains('StripeElement--complete');
    }

    

    book() {
        
        this.submissionService.startSubmission();
        this.cartService.bookingOptions.paymentType.name = "card";
        this.cartService.bookThroughCart().subscribe((res) => {
            console.log('res of booking trhough cart',res);
            let state = true;
            this.submissionService.endSubmission();
            const spaces = Object.keys(res);
            spaces.forEach((e) => {
                const space = this.cartService.cartData.find((d) => {
                    if(d.serviceId === Number(e)){
                        return e;
                    }
                })
                if(res[e] === 'Successfully booked'){
                    this.notificationService.notify(NotificationType.Success,`${space.spaceName} is successfully booked`,'You have successfully booked this space');
                }else{
                    state = false;
                    this.notificationService.notify(NotificationType.Error,`${space.spaceName} is unable to book now`,'Please try again later');
                }
            });
            if(state){
                this.cartService.cartcount = 0;
                this.router.navigate(['success'])
            }else{
                this.router.navigate(['cart'])
            }
        },
        (err) => console.log('error in booking cart',err)
        )

    }

    bookSavedCard() {

        this.submissionService.startSubmission();
        this.bookingService.bookingOptions.paymentIntentID = this.paymentService.paymentIntentID;
        this.bookingService.bookingOptions.paymentType.name = "card";
        this.bookingService.bookSpace().subscribe(res => {
            console.log("wauuu@@");
            console.log(res);
            if (res) {
                this.paymentService.takePaymentSavedCard(this.selectedSavedCard).subscribe(
                    result => {
                        this.submissionService.endSubmission();
                        console.log("!!!!");
                        console.log(result);
                        if (result.paymentIntent) {
                            this.notificationService.notify(NotificationType.Success, "You successfully booked the space");
                            this.router.navigate(["/index"]);

                        } else if (result.error) {
                            // Error creating the token
                            console.error(result.error.message);
                            this.notificationService.notify(NotificationType.Error, "Payment Failed. Please check your card information.", "Payment Error");
                            this.submissionInProgress = false;
                        }
                    }
                );
            }
            else {
                this.submissionService.endSubmission();
                this.notificationService.notify(NotificationType.Error, "Booking could not be made. Please check if desired timeslots are still available.", "Booking Error");
                this.submissionInProgress = false;
            }
        });

        /*if (!this.submissionInProgress) {
            this.submissionInProgress = true;
            const name = this.stripeTest.get('name').value;
            this.stripeService
                .createToken(this.card, { name })
                .subscribe(result => {
                    if (result.token) {
                        this.bookingService.bookingOptions.token = result.token
                        this.bookingService.bookingOptions.paymentType.name = "card";
                        this.bookingService.bookSpace().subscribe(res => {
                            if (res)
                            {
                                this.notificationService.notify(NotificationType.Success, "You successfully booked the space");
                                this.router.navigate(["/index"]);
                            }
                            else
                            {
                                this.notificationService.notify(NotificationType.Error, "Booking could not be made. Please check if desired timeslots are still available.", "Booking Error");                                
                                this.submissionInProgress = false;
                            }
                        });
                    } else if (result.error) {
                        // Error creating the token
                        console.error(result.error.message);
                        this.submissionInProgress = false;
                    }
                });
        }*/
    }

    public getPayButtonLabel() {
        return "Book Space: CHF " + Math.round(this.bookingService.TotalPrice(true) * 100) / 100;
    }

    setSavedCard(evt)
    {
        this.selectedSavedCard = evt;
        console.log(evt);
    }

    savedCardToText(item) :string
    {
        if (item.card != null)
            return item.card.brand.toUpperCase() + " ending in " + item.card.last4 + " (expires " + item.card.expMonth + "/" + item.card.expYear + ")";
    }

    removeCard() {
        this.selectedSavedCard = null;
        this.cardDropdown.currentValue = null;
    }

    public goBack() {
        this.router.navigate(["space-listing/" + this.bookingService.space.spaceId]);
    }
}
