import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Location } from '@angular/common';
import { PMBookingsOverview } from './modules/shared/models/booking/pm-bookings-overview';
import { AuthService } from './modules/shared/services/auth.service';
import { SpaceService } from './modules/shared/services/space.service';
import { GeolocationService } from './modules/shared/services/geolocation.service';
import { MessagingService } from './modules/shared/services/messaging.service';
import { NotificationService } from './modules/shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HammerModule } from '@angular/platform-browser';
import { SubmissionService } from './modules/shared/services/submission.service';


@Component({
    selector: 'ebento',
    templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit, AfterViewInit {
    title: string = 'Ebento Properties';
    bookingOverview: PMBookingsOverview;
    isMobile;
    isTablet;
    isDesktop;

    constructor(
        public submissionService: SubmissionService,
        private deviceService: DeviceDetectorService,
        public authService: AuthService,
        private location: Location,
        public translate: TranslateService,
        public spaceService: SpaceService,
        public router: Router,
        public geolocationService: GeolocationService,
        public messagingService: MessagingService,
        private notificationService: NotificationService
    ) {

        this.bookingOverview = new PMBookingsOverview();
        // this language will be used as a fallback when a translation isn't found in the current language
        // translate.setDefaultLang('en');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        // translate.use('en');
    }


    ngAfterViewInit(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                (<any>window).ga('set', 'page', event.urlAfterRedirects);
                (<any>window).ga('send', 'pageview');
                (<any>window).gtag('config', 'UA-144015874-1', { 'page_path': <any>window.location.pathname + <any>window.location.hash });
            }
        });
    }

    ngOnInit() {
        
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktop = this.deviceService.isDesktop();
        // alert("Ebento - [v: 09-may-2022]");
        console.log("Ebento - [v: 09-may-2022]");
        this.geolocationService.GetLocation.subscribe(
            position => {
                console.log('GEOLOCATION SUCCESS: ', position);
            },
            msg => {
                console.error('Error Getting Location: ', msg);
            });

        var pathString = this.location.path();

        //console.log("pathString=" + pathString);
        if (!pathString.includes("confirm-registration")
            && !pathString.includes("change-password")
            && !pathString.includes("reset-password")
            && !pathString.includes("ep-referral")
            && !pathString.includes("pm-referral")
            && !pathString.includes("review")
            && !pathString.includes("faq")
            && !pathString.includes("register-common")
            && !pathString.includes("experience-details/")
            && !pathString.includes("space-details/")
            && !pathString.includes("change-email")
            && !pathString.includes("reactivate-account")
            && !pathString.includes("messages/compose")
            && !pathString.includes("landing")
            && !pathString.includes("login")
            && !pathString.includes("terms")
        ) {
            if (this.authService.isLoggedIn() &&
                this.authService.userType == "PropertyManager") {
                this.router.navigate(['/property-manager/properties']);
            } else {
                this.router.navigate(['/index']);
            }
        }

        if (pathString.includes("ep-referral") || pathString.includes("pm-referral") || pathString.includes("reset-password") || pathString.includes("pm-referral")) {
            this.authService.logout().subscribe();
        }

        if (this.authService.isLoggedIn()) {
            this.authService.getUserInfo().subscribe(
                success => {
                    if (!success.isProfileCompleted) {

                        //console.log("CONF REG");
                        this.router.navigate(["confirm-registration/" + success.verificationToken + "/" + success.email + "/" + success.userType.replace(" ", "-")]);

                    }
                    else {
                        this.messagingService.startNotifications(this.authService.token);
                        this.messagingService.getInbox(true).subscribe(
                            success => {
                                //this.messagingService.openMessage = this.firstMessage;
                            }
                        );
                    }
                }
            );

        }
    }

    redirect(pagename: string) {
        this.router.navigate([pagename]);
    }
}
