export class PropertyAddress
{
    public constructor(
        public country: string,
        public city: string,
        public streetaddress: string,
        public addressline2: string,
        public postalcode: string,
        public streetnumber: string,
        public googlemapcoordx: number,
        public googlemapcoordy: number
    )
    { }
}