/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input, Injector } from '@angular/core';
import { IndexCarousel } from '../index-carousel.component';
import { WorkshopService } from 'app/modules/shared/services/workshop.service';
import { WorkshopCarouselTile } from '../../carousel-tile/workshop-carousel-tile/workshop-carousel-tile.component';
import { GeolocationService } from 'app/modules/shared/services/geolocation.service';

@Component({
    selector: 'workshops-this-week',
    templateUrl: '../index-carousel.component.html'
})


// !!!
// Now shows all workshops
// !!!

export class WorkshopsThisWeek extends IndexCarousel implements OnInit {

    @Input() color:string = '#212529';
    @Input() headerLabel = 'This week' + ((this.geolocation.myCity != null) ? ' in ' + this.geolocation.myCity.city : '');
    protected tileComponent = WorkshopCarouselTile;
    domID = 'workshops-this-week';
    
    constructor(private geolocation: GeolocationService,  public workshopService: WorkshopService, injector: Injector) {
        super(injector);
    }

    get apiGetter() {
        return (this.geolocation.myCity != null) ?
        this.workshopService.getWorkshopsThisWeek(this.geolocation.myCity.city)
        : this.workshopService.getWorkshopsThisWeek(null);
    }


}
