import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Item } from "../../../shared/models/cart/cartItem";
import { CartService } from "../../services/cart.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SubmissionService } from "../../../shared/services/submission.service";
import { SpaceService } from '../../../shared/services/space.service';

@Component({
  selector: "app-cart-tile",
  templateUrl: "./cart-tile.component.html",
  styleUrls: ["./cart-tile.component.scss"],
})
export class CartTileComponent {
  @Input("type") type: string;
  @Input() data: Item;
  @Output() onMove = new EventEmitter<string>();

  constructor(
    private cartService: CartService,
    private router: Router,
    private submissionService: SubmissionService,
    public spaceService:SpaceService
  ) {
    console.log("type", this.type);
    console.log("item data", this.data);
  }

  removeItem() {
    if (confirm("Are you sure you want to remove this item?")) {
      this.submissionService.startSubmission();
      if (this.type === "cart") {
        this.cartService.removeCartItem(this.data.cartId).subscribe(
          (res) => {
            if (this.cartService.cartcount !== 0) {
              --this.cartService.cartcount;
            }
            this.submissionService.endSubmission();
            this.onMove.emit(this.type);
          },
          (err) => {
            console.log("error of response", err);
          }
        );
      } else {
        this.cartService.removeSaveForLater(this.data.cartId).subscribe(
          (res) => {
            this.submissionService.endSubmission();
            this.onMove.emit(this.type);
          },
          (err) => {
            console.error("response of removeSave for later", err);
          }
        );
      }
    }
  }

  saveForLater() {
    this.submissionService.startSubmission();
    this.cartService.addToSaveForLater(this.data.cartId).subscribe(
      (res) => {
        this.submissionService.endSubmission();
        this.onMove.emit(this.type);
      },
      (err) => console.error("error from add to save for later", err)
    );
  }

  moveToCart() {
    this.submissionService.startSubmission();
    this.cartService.moveToCart(this.data.cartId).subscribe(
      (res) => {
        this.submissionService.endSubmission();
        this.onMove.emit(this.type);
      },
      (err) => console.error("error from move to cart", err)
    );
  }

  modifyCartItem() {
    // this.cartService
    //   .getModify(this.data.cartId, this.data.serviceId, this.data.spaceType)
    //   .subscribe(
    //     (res) => {
    //       console.log("res from getmodify", res)

    //     },
    //     (err) => console.error("error from modify", err)
    //   );
    if (this.data.spaceType === "Entertainment") {
      this.router.navigate([
        `modify/entertainment/${this.data.cartId}/${this.data.serviceId}`,
      ]);
    } else if (this.data.spaceType === "Space") {
      this.router.navigate([
        `modify/space/${this.data.cartId}/${this.data.serviceId}`,
      ]);
    } else {
      this.router.navigate([
        `modify/experience/${this.data.cartId}/${this.data.serviceId}`,
      ]);
    }
  }
}
