import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';

import { Injectable } from "@angular/core";
import { map, catchError, switchMap, mergeMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { throwError } from 'rxjs';
import { BookingSegment } from "../models/booking/booking-segment";
import { EbentoSpace } from "../models/properties/ebento-space";
import { SpaceForTile } from "../models/search/space-for-tile";
import { CityTile } from "../models/search/city-tile";
import { WorkingHours } from "../models/properties/working-hours";
import { SpaceType } from "../models/properties/space-type";
import { AmenityType } from "../models/properties/amenity-type";
import { SittingArType } from "../models/properties/sitting-ar-type";
import { PricingRuleType } from "../models/properties/pricing-rule-type";
import { GeolocationService } from "./geolocation.service";
import { UploadService } from "./upload.service";
import { PropertyService } from "./property.service";
import { TimeSpan } from "../models/data-classes/time-span";
import { TimeAllocation } from "../models/data-classes/time-allocation";
import { SpaceTile } from "../models/properties/space-tile";

@Injectable()
export class SpaceService {
    //TODO: add caching logic to avoid unnecesary calls to ss

    // TODO: We can and should probably move this somewhere else:
    public selectedNonWorkingHours: BookingSegment[]
    public spaceToEdit: EbentoSpace
    public perfectSpaces: SpaceTile[]
    public uniqueSpaces: SpaceTile[]
    public localCities: CityTile[]
    public spaces: EbentoSpace[]
    public space: EbentoSpace
    public spaceHistory: EbentoSpace
    public workingHours: WorkingHours
    public spaceTypes: SpaceType[]
    public amenityTypes: AmenityType[]
    public allAmenityTypes: AmenityType[]
    public amenityTypesNonCatering: AmenityType[]
    public amenityTypesCatering: AmenityType[]
    public sittingTypes: SittingArType[]
    public pricingRuleTypes: PricingRuleType[]
    public editMode: boolean = false;
    public numbersOfAttendees = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300, 350, 400, 450, 500, 1000, 2000, 3000, 4000, 5000];
    public workshopDuration = [
        {name: '30 minutes', value: 0.5}, 
        {name: '1 hour', value: 1},
        {name: '1.5 hours', value: 1.5},
        {name: '2 hours', value: 2},
        {name: '2.5 hours', value: 2.5},
        {name: '3 hours', value: 3},
        { name: '4 hours', value: 4 }
        //      { name: 'Half day', value: 'half' },    
        //{ name: 'Full day', value: 'full' }, removed due to type incompatibility and logic problems
    ];
    public selectedIndex:number = 0;
    public uploadError:boolean = false;

    constructor(
        private http: HttpClient,
        private geolocation_service: GeolocationService,
        private uploadService: UploadService,
        private propertyService: PropertyService
    ) {
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    getSpaces(): Observable<EbentoSpace[]> {
        return this.http.get<EbentoSpace[]>("api/space")
            .pipe(
                map(result => this.spaces = result),
                catchError(this.errorHandler)
            );
    }

    getPerfectSpaces(): Observable<SpaceTile[]> {
        if (this.geolocation_service.isAvailable) {
            return this.http.post<SpaceTile[]>("api/perfectspaces", this.geolocation_service.geolocation)
                .pipe(
                    map(result => this.perfectSpaces = result),
                    catchError(this.errorHandler)
                );
        }
        else {
            return this.http.get<SpaceTile[]>("api/perfectspaces")
                .pipe(
                    map(result => this.perfectSpaces = result),
                    catchError(this.errorHandler)
                );
        }
    }

    getUniqueSpaces(): Observable<SpaceTile[]> {
        if (this.geolocation_service.isAvailable) {
            return this.http.post<SpaceTile[]>("api/uniquespaces", this.geolocation_service.geolocation)
                .pipe(
                    map(result => this.uniqueSpaces = result),
                    catchError(this.errorHandler)
                );
        }
        else {
            return this.http.get<SpaceTile[]>("api/uniquespaces")
                .pipe(
                    map(result => this.uniqueSpaces = result),
                    catchError(this.errorHandler)
                );
        }
    }

    getLocalCitiesForCity(city): Observable<CityTile[]> {
        return this.http.post<CityTile[]>("api/localcities", city)
            .pipe(
                map(result => this.localCities = result),
                catchError(this.errorHandler)
            );
    }
    
    getSpace(spaceID: number): Observable<EbentoSpace> {
        return this.http.get<EbentoSpace>("api/space/" + spaceID)
            .pipe(
                map(result => {
                    this.space = result;
                    //console.log("Stiglo! " + JSON.stringify(result));
                    // rebuilding real TimeSpan objects for pricingRules (they need to have methods)
                    // refactor => remove ALL methods from all DTOs (recreate them as static, i.e.: hours = TimeSpan.GetHours(timespan);)
                    if (result.pricingRules != null && result.pricingRules.length > 0) {
                        for (let rule of result.pricingRules) {
                            rule.startTime = new TimeSpan(0, 0, 0, rule.startTime.totalseconds);
                            rule.endTime = new TimeSpan(0, 0, 0, rule.endTime.totalseconds);
                        }
                    }
                    return result;

                }),
                catchError(this.errorHandler)
            );
    }

    getSpaceHistorySnapshotForBooking(bookingID: number): Observable<EbentoSpace> {
        return this.http.get<EbentoSpace>("api/space-history-for-booking/" + bookingID)
            .pipe(
                map(result => {
                    this.spaceHistory = result;
                    //console.log("Stiglo! " + JSON.stringify(result));
                    // rebuilding real TimeSpan objects for pricingRules (they need to have methods)
                    // refactor => remove ALL methods from all DTOs (recreate them as static, i.e.: hours = TimeSpan.GetHours(timespan);)
                    if (result.pricingRules != null && result.pricingRules.length > 0) {
                        for (let rule of result.pricingRules) {
                            rule.startTime = new TimeSpan(0, 0, 0, rule.startTime.totalseconds);
                            rule.endTime = new TimeSpan(0, 0, 0, rule.endTime.totalseconds);
                        }
                    }
                    return result;

                }),
                catchError(this.errorHandler)
            );
    }

    getSpaceTile(spaceID: number): Observable<SpaceForTile> {
        return this.http.get<SpaceForTile>("api/spacetile/" + spaceID)
            .pipe(
                map(result => {
                    //console.log("Stiglo! " + JSON.stringify(result));
                    // rebuilding real TimeSpan objects for pricingRules (they need to have methods)
                    // refactor => remove ALL methods from all DTOs (recreate them as static, i.e.: hours = TimeSpan.GetHours(timespan);)
                    return result;
                }),
                catchError(this.errorHandler)
            );
    }


    deleteSpace(spaceID: number) {
        return this.http.delete("api/space/" + spaceID)
            .pipe(
                map((data: EbentoSpace) => {
                    return true;
                }),
                catchError(this.errorHandler)
            );
    }

    setSpaceActive(spaceID: number, active: boolean) {
        return this.http.post("api/set-space-active/" + spaceID, active)
            .pipe(
                map((result: boolean) => {
                    return true;
                }),
                catchError(this.errorHandler)
            );
    }

    public addSpace(hasImages: boolean): Observable<number> {
        if (hasImages) {
            return this.uploadService.uploadImages(this.space.images)
                .pipe(
                    mergeMap(
                        (res: string[]) => {
                            if(res.length){
                                this.space.photoUrls = res;
                                return this.addSpaceWithoutUpload();
                            }else{
                                this.uploadError = true;
                            }
                        }
                    )
                )

        }
        else {
            return this.addSpaceWithoutUpload();
        }
    }

    private addSpaceWithoutUpload(): Observable<number> {
        var closedHours = new Array<TimeAllocation>();
        for (let timeslot of this.selectedNonWorkingHours) {
            closedHours.push(TimeAllocation.FromBooking(timeslot));
        }
        this.space.closedHours = closedHours;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };

        return this.http.post<number>("api/space", JSON.stringify(this.space), httpOptions)
            .pipe(
                map((res:number) => {
                    return res;
                },
                    error => {
                        return error;
                    }
                )
            )

    }

    public editSpace(hasImages: boolean): Observable<number> {
        if (hasImages) {
            return this.uploadService.uploadImages(this.space.images)
                .pipe(
                    switchMap(
                    (res: string[]) => {
                        if(res.length){
                            for (let url of res)
                                this.space.photoUrls.push(url);
                                return this.editSpaceWithoutUpload();
                            }else{
                                this.uploadError = true;
                                // return 0;
                            }
                        }
                    )
                )

        }
        else {
            return this.editSpaceWithoutUpload();
        }
    }


    private editSpaceWithoutUpload(): Observable<number> {
        var closedHours = new Array<TimeAllocation>();
        for (let timeslot of this.selectedNonWorkingHours) {
            closedHours.push(TimeAllocation.FromBooking(timeslot));
        }
        
        this.space.closedHours = closedHours;
        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };

        return this.http.post<number>("api/updateSpace", JSON.stringify(this.space), httpOptions)
            .pipe(
                map(res => {
                    return res;
                }
                )
            )

    }

    private updateSpaces() {
        this.getSpaces();
        this.propertyService.refreshProperty()
            .subscribe(
                //() => console.log("Updated Property"),
            (er) => console.error(er)
            )
    }


    public GetAmenityTypeByID(id: number): AmenityType {
       // console.log("GET AMEN " + id);
         //console.log((this.allAmenityTypes != null) ? JSON.stringify(this.amenityTypes) : "X");
        if (this.allAmenityTypes == null) {
         //   console.log("DUD");
            return null;
        }
        for (var at of this.allAmenityTypes)
            if (at.id == id)
                return at;
        //console.warn("Found no Amenity for ID" + id);
        return null;
    }

    public getAmenityTypes(): Observable<AmenityType[]> {
        return this.http.get<AmenityType[]>("api/amenityTypes")
        .pipe(
            map(result => {
                this.allAmenityTypes = result;
                this.amenityTypesNonCatering = new Array();
                this.amenityTypesCatering = new Array();
                var globalResults = new Array();
                for (var a of result) {
                    if (a.isGlobal)
                    {
                        globalResults.push(a);
                        if (a.isCatering) 
                            this.amenityTypesCatering.push(a);
                        else
                        this.amenityTypesNonCatering.push(a);
                    }
                }
                this.amenityTypes = globalResults;
                return globalResults;
                },
                    err => console.error("error:",err)
                ));
    }

    public getSittingTypes(): Observable<SittingArType[]> {
        return this.http.get<SittingArType[]>("api/sittingTypes")
            .pipe(
                map(result => this.sittingTypes = result
                ));
    }

    public getSpaceTypes(): Observable<SpaceType[]> {
        return this.http.get<SpaceType[]>("api/spaceTypes")
            .pipe(
                map(result => this.spaceTypes = result
                ));
    }

    public getWorkingHours(spaceID: number): Observable<WorkingHours> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };

        return this.http.post<WorkingHours>("api/workinghours", spaceID, httpOptions)
            .pipe(
                map(res => {
                    this.workingHours = WorkingHours.FromDTO(res);
                    console.log('working hours',this.workingHours);
                    
                    return res;
                }),
                catchError(this.errorHandler)
            )
    }

    public getAvailableSpaces(timeallocations: TimeAllocation[]): Observable<EbentoSpace[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };

        return this.http.post<EbentoSpace[]>("api/timeslotfilter", JSON.stringify(timeallocations), httpOptions)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.errorHandler)
            )
    }

    // public getPricingRuleTypes(): Observable<PricingRuleType[]> {
    //     return this.http.get<PricingRuleType[]>("api/PricingRule/types")
    //         .pipe(
    //             map(result => this.pricingRuleTypes = result.filter(rule => rule.hasPeriod) ),
    //             catchError(this.errorHandler)
    //         );
    // }

    public getPricingRuleTypes(): Observable<PricingRuleType[]> {
        return this.http.get<PricingRuleType[]>("api/PricingRule/types")
            .pipe(
                map(result => this.pricingRuleTypes = result ),
                catchError(this.errorHandler)
            );
    }

    GetAvailabeNumbersOfAttendees(): Array<number> {
        var results = new Array<number>();
        for (let no of this.numbersOfAttendees) {
            if (no < this.space.maxNumberOfGuests)
                results.push(no);
        }
        results.push(this.space.maxNumberOfGuests);
        return results;
    }
}