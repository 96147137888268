import { Component } from '@angular/core';
import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
  providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers
})

export class BannerComponent {
  showNavigationArrows = false;
	showNavigationIndicators = false;
	// images = [1055, 194, 368].map((n) => `https://picsum.photos/id/${n}/900/500`);
  images = ['assets/images/Hero_1x (2).png', 'assets/images/Hero_1x (3).png']

  constructor(config: NgbCarouselConfig) {
		// customize default values of carousels used by this component tree
		config.showNavigationArrows = true;
		config.showNavigationIndicators = true;
	}
}