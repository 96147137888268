import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessLevelGuard implements CanActivate {

  constructor(private auth: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let accessLevel;

    switch (this.auth.userType) {
      case 'EventPlanner':
        accessLevel = 2;
        break;

      case 'PropertyManager':
        accessLevel = 1;
        break;

      case 'Administrator':
        accessLevel = 0;
        break;
      }

    if (accessLevel <= route.data.expectedAccessLevel) {
      return true;
    }
    else {

        //BYPASSING ACCESS GUARD FOR QUICK FIX
        if (route.data.expectedAccessLevel > 0) {
            console.error("Bypassing Accesguard! Quick fix for non-admin routes. Needs update.")
            return true;
        }
      return false;
    }
  }

}
