import { Component, ViewChild } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { AuthService } from "app/modules/shared/services/auth.service";
import { AdminSpaceService } from "../../../services/admin-space.service";

@Component({
    selector: 'unique-checkbox',
    templateUrl: 'unique-checkbox.component.html'
})
export class UniqueCheckbox implements ICellRendererAngularComp {
    public params: any;
    public submitting: boolean;

    constructor(public authService: AuthService, public adminSpaceService: AdminSpaceService)
    { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    uniqueChanged(isChecked: boolean)
    {
        this.submitting = true;
        this.adminSpaceService.setSpaceUnique(this.params.data.spaceId, isChecked).subscribe(
            (success) => {
                this.submitting = false;
                if (success)
                {

                }
                else
                {
                    console.error("api error");
                    this.params.data.spaceId = !isChecked;
                }   
            }
        );

    }
}