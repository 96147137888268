import { Component, OnInit } from '@angular/core';
import { EbentoModalService } from 'app/modules/shared/services/ebento-modal.service';
import { BookingDetailsModal } from 'app/modules/shared/modules/modals/components/booking-details-modal/booking-details.modal.component';
import { Router } from '@angular/router';
import { AddEntertainmentService } from 'app/modules/property-manager/services/add-entertainment.service';
import { Entertainment } from 'app/modules/shared/models/entertainment/AddEntertainmentStep1';
import { MessagingService } from 'app/modules/shared/services/messaging.service';
import { EbentoMessage } from 'app/modules/shared/models/messaging/ebento-message';

@Component({
  selector: 'app-booked-item',
  templateUrl: './booked-item.component.html',
  styleUrls: ['./booked-item.component.scss']
})
export class BookedItemComponent implements OnInit{

  public entertainmentData:Entertainment;

  constructor(
    private router: Router, public entertainmentService: AddEntertainmentService, private messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    // console.log("entertainmentData>>>>", this.entertainmentService.entertainmentData)
    this.entertainmentService.getPreviewData(this.entertainmentService.serviceId)
      .subscribe((response) => {
        this.entertainmentData ={
          entertain_ServiceId: response[0].Entertain_ServiceId,
          entertain_ServiceName: response[0].Entertain_ServiceName,
          enter_CatID: response[0].Enter_CatID,
          entertainTypeId: response[0].EntertainTypeId,
          enter_Desription: response[0].Enter_Desription,
          enter_LocationID: response[0].Enter_LocationID,
          Enter_LocationName:response[0].Enter_LocationName,
          enter_DistanceID: response[0].Enter_DistanceID,
          bookingType: response[0].BookingType,
          enter_CovidMeasures: response[0].Enter_CovidMeasures,
          documentUrl: response[0].documentUrl,
          pricingRules: response[0].pricingRules,
          ecancellationRules: response[0].EcancellationRules,
          Enter_priceperEvent: response[0]?.Enter_priceperEvent,
          isActive: response[0].isActive,
          additionalCosts: response[0].AdditionalCosts,
          pricingType: response[0].PricingType,
          availableTimeslots: response[0].avaavailableTimeslots,
          propertyIDEbentoProperty: response[0].PropertyIDEbentoProperty,
          propertyDetails: response[0].Property
      };
      console.log("propertyDetails",response)
      })

  }

  viewSpace() {
    this.router.navigate(["entertainment-details/" + this.entertainmentService.serviceId]);
  }

  sendMessage()
    {
        // var propertyManager = this.entertainmentData.propertyDetails.propertyManager;
        // this.messagingService.currentRecepient = propertyManager;
        this.messagingService.currentDraft = new EbentoMessage();
        this.router.navigate(['/messages/compose']);
    }
}
