import { Injectable } from "@angular/core";
import { Observable, throwError, Subject } from "rxjs";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { EbentoUser } from "app/modules/shared/models/users/ebento-user";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";

@Injectable()
export class AdminMessagingService {

    constructor(private http: HttpClient, private router: Router) { }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    public observedUser: EbentoUser;
    public receivedMessages: EbentoMessage[]
    public sentMessages: EbentoMessage[]
    public openMessage: EbentoMessage
    public searchKeyword = ""

    public getReceived(): Observable<EbentoMessage[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.get<EbentoMessage[]>("api/adminmessaging/getreceived/" + this.observedUser.id, httpOptions)
            .pipe(
                map(
                result => {
                    this.receivedMessages = result;
                    return result; }),
                catchError(this.errorHandler)
            );
    }

    public getSent(): Observable<EbentoMessage[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.get<EbentoMessage[]>("api/adminmessaging/getsent/" + this.observedUser.id, httpOptions)
            .pipe(
                map(
                    result => {
                        this.sentMessages = result;
                        return result;
                    }),
                catchError(this.errorHandler)
            );
    }

      public open(message: EbentoMessage)
    {
        this.openMessage = message;
    }
}