import { CircleManager } from '@agm/core';
import { Component, Input, OnInit } from '@angular/core';
// import { SpaceBookingService } from 'app/modules/shared/services/space-booking.service';
// import { SpaceService } from 'app/modules/shared/services/space.service';
import { AddEntertainmentService } from '../../../property-manager/services/add-entertainment.service';

@Component({
    selector: 'entertainment-payment',
    templateUrl: 'entertainment-payment.component.html',
    styleUrls: ['./entertainment-payment.component.scss']
})

export class EntertainmentPayment implements OnInit{
    @Input() isEnquiry: boolean = false;
    
    submissionInProgress: boolean = false

    constructor(
        public bookingService: AddEntertainmentService,
        // public spaceService: SpaceService
        )
    {
    }

    ngOnInit(): void {
        console.log("enquiry payment", this.bookingService.entertainmentData)
    }

    public VenuePrice(): number {
        let price = Math.round(this.bookingService.VenuePrice() * 100) / 100;
        // console.log('venue price',price);
        return price;
    }




    public EbentoFee(): number {
        let price = Math.round(this.bookingService.EbentoFee() * 100) / 100;
        // console.log('ebento fee',price);
        return price;
    }

    public TotalPrice(): number {
        let price = Math.round(this.bookingService.TotalPrice(false) * 100) / 100;
        // console.log('total price',price);
        return price;
        
    }

    // public TotalPriceWithDiscount(): number {
    //     return Math.round(this.bookingService.TotalPrice(true) * 100)/ 100;
    // }
    // public PriceDifference(): string
    // {
    //     var result = Math.round((this.TotalPriceWithDiscount() - this.bookingService.bookingToEdit.price.totalWithDiscount) * 100) / 100;
    //     var sign = (result < 0) ? "- CHF" : "CHF"
    //     return sign + " " + Math.abs(result);
    // }
}