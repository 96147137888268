import { Component,Input, HostListener, ViewChild } from '@angular/core';
import { Category } from '../../models/entertainment/categories';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router'
import { AddEntertainmentService } from '../../../property-manager/services/add-entertainment.service';

@Component({
    selector: 'entertainment-home-page',
    templateUrl: 'entertainment-home-page.component.html',
    styleUrls: ['./entertainment-home-page.component.css']
})

export class EntertainmentHomePage{

    @ViewChild('carousel', { read: NgbCarousel }) carousel: NgbCarousel;
    @Input() categories:Category[];
    @Input('headerLabel') headerLabel : string;

    scrHeight: any;
    scrWidth: any;

    constructor(private router:Router,private entertainmentService:AddEntertainmentService){
        this.getScreenSize();
    }

    public GetNplet(index: number, n = 3) {
        var result = new Array();
        var i = 0;
        while (i < n && i + index < this.categories.length) {
            var item = this.categories[i + index];
            result.push(item);
            i++;
        }
        return result;
    }

    public numbersOfNplets(n = 3): number[]
    {
        if (this.categories == null)
            return null;
        var non = new Array();
        for (var i = 0; i < Math.ceil(this.categories.length / n); i++) {
            non.push(i);
        }
        
        return non;
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
       // console.log(this.scrHeight, this.scrWidth);
    }
    
    getColumnCount()
    {
        return (this.scrWidth >= 992) ? 3 : (this.scrWidth >= 500) ? 2 : 1;

    }

    public swipeLeft()
    {
        this.carousel.next();
    }

    public swipeRight() {
          this.carousel.prev();
    }


    public Redirect(cat:Category)
    {
        console.log('redirect cate',cat);
        this.entertainmentService.searchCategory = cat.enter_CatID;
        this.entertainmentService.getEntertainmentACategory(cat.enter_CatID).subscribe((res) => {
            console.log('entertainments of this category',res);
            this.entertainmentService.searchResult = res;
            this.router.navigate(['entertainment-search-results']);
        })
    }

}