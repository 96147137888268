/// <reference types="@types/googlemaps" />

import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { AddEntertainmentService } from "../../services/add-entertainment.service";
import { Category } from "../../../shared/models/entertainment/categories";
import { EntertainmentTypes } from "../../../shared/models/entertainment/entertainmentTypes";
import {
  AddEntertainmentStep1,
  Type,
} from "../../../shared/models/entertainment/AddEntertainmentStep1";
import { FormBuilder, Validators } from "@angular/forms";
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: "edit-entertainment-1",
  templateUrl: "./edit-entertainment.component.html",
  styleUrls: ["./edit-entertainment.component.css"],
})
export class EditEntertainment implements OnInit,AfterViewInit {

  @ViewChild('stepper') stepper:MatStepper;
  @ViewChild('addresstext') addresstext: any;
  autocomplete: google.maps.places.Autocomplete;

  public isLinear: boolean = false;
  public entertainmentCategories: Category[];
  public entertainmentTypes: EntertainmentTypes[];
  public files = [];
  public videoFile: any;
  public audioFile: any;
  public typeValues: Type[] = [];
  public eData: AddEntertainmentStep1 = {
    Entertain_ServiceName: "",
    Enter_CatID: null,
    EntertainTypeId: [],
    Enter_Desription: "",
    Enter_LocationName: "",
    Enter_DistanceID: "",
    BookingType: "",
    Enter_CovidMeasures: "",
    documentUrl: [],
  };
  public previewImages = [];
  public previewVideo: any;
  public previewAudio: any;
  public errors:string[] = [];
  public customEntertainmentType = new FormControl("");
  public typeName: string = "";

  constructor(
    public entertainmentService: AddEntertainmentService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initialise();
    if (this.entertainmentService.editMode) {
      this.preloadValues();
    }
    this.entertainmentService.stepperNext = this.stepper.next;
  }

  
  ngAfterViewInit(): void {
    console.log("add entertainmnet sleected index",this.entertainmentService.selectedIndex);
    this.stepper.selectedIndex = this.entertainmentService.selectedIndex; 
    this.autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      { types: ['geocode'] });

      google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
        const place = this.autocomplete.getPlace();
        console.log('place',place);
        this.eData.Enter_LocationName = place.formatted_address;
    });

    this.entertainmentService.stepperNext = this.stepper;

  }

  preloadValues() {
    console.log("preload data", this.entertainmentService.entertainmentData);
    
    this.entertainmentService.propertyId = String(
      this.entertainmentService.entertainmentData.propertyIDEbentoProperty
    );
    this.eData.Entertain_ServiceName =
      this.entertainmentService.entertainmentData?.entertain_ServiceName;
    this.eData.Enter_CatID =
      this.entertainmentService.entertainmentData?.enter_CatID;
    this.eData.Enter_Desription =
      this.entertainmentService.entertainmentData?.enter_Desription;
    this.eData.Enter_LocationName = String(
      this.entertainmentService.entertainmentData?.Enter_LocationName
    );
    this.eData.Enter_DistanceID = String(
      this.entertainmentService.entertainmentData?.enter_DistanceID
    );
    this.eData.Enter_CovidMeasures =
      this.entertainmentService.entertainmentData?.enter_CovidMeasures;
    this.eData.BookingType = String(
      this.entertainmentService.entertainmentData?.bookingType
    );
    let temp = this.entertainmentService.entertainmentData.documentUrl.map(
      (e) => {
        let [rest, dom] = e.enter_DocumentURL.split(".");
        if (dom === "jpg") {
          return {
            Enter_DocumentURL: e.enter_DocumentURL,
            Enter_Documenttype: ".jpg",
          };
        }
      }
    );

    this.entertainmentService.entertainmentData.entertainTypeId.forEach((e) => {
      this.typeValues.push({EntertainTypeId: e.entertainTypeId });
    })

    this.eData.documentUrl = temp.filter((e) => {
      return e !== undefined;
    });

    this.getTypesEntertainment(this.eData.Enter_CatID);
    this.entertainmentService.entertainmentData.documentUrl.forEach((e) => {
      let [rest, dom] = e.enter_DocumentURL.split(".");
      if (dom === "jpg") {
        this.previewImages.push(
          `${e.enter_DocumentURL}`
        );
      } else if (dom === "mp4") {
        this.previewVideo = `${e.enter_DocumentURL}`;
      } else if (dom === "mp3") {
        this.previewAudio = `${e.enter_DocumentURL}`;
      }
    });

    console.log("edit data", this.eData);
  }

  getCategoriesEntertainment() {
    this.entertainmentService
      .getCatogories()
      .subscribe((response: Category[]) => {
        console.log(response);
        this.entertainmentCategories = response;
      });
  }

  getTypesEntertainment(id: string | number): void {
    this.entertainmentService
      .getEntertainmentTypes(id)
      .subscribe((response: EntertainmentTypes[]) => {
        console.log("response of get types", response);
        this.entertainmentTypes = response;
      });
  }

  initialise(): void {
    this.getCategoriesEntertainment();
  }

  check(id: number) {
    // console.log("entertainmentTypes", this.entertainmentTypes);
    const result = this.entertainmentService.entertainmentData.entertainTypeId.some((e) => e.entertainTypeId === id);
    return result;
  }

  onFileChange(event) {
    console.log("event files", Object.values(event.target.files));

    this.files = Object.values(event.target.files);
    console.log("file selected", this.files);
    for (let i of this.files) {
      var reader = new FileReader();
      reader.readAsDataURL(i);
      reader.onload = (event) => {
        this.previewImages.push((<FileReader>event.target).result);
      };
    }
    console.log("preview images", this.previewImages);
  }

  onVideoChange(event: any) {
    console.log("event video upload", event.target.files[0]);
    this.videoFile = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.videoFile);
    reader.onload = (event) => {
      this.previewVideo = (<FileReader>event.target).result;
    };
  }

  onAudioChange(event: any) {
    console.log("event audio upload", event.target.files[0]);
    this.audioFile = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.audioFile);
    reader.onload = (event) => {
      this.previewAudio = (<FileReader>event.target).result;
    };
  }

  uploadImages() {
    console.log("files", this.files);
    this.entertainmentService
      .uploadImages(this.files)
      .subscribe((response: string[]) => {
        console.log("images upload response", response);
        let newArray = [];
        response.forEach((value) => {
          console.log("value", value);
          newArray.push({
            Enter_DocumentURL: value,
            Enter_Documenttype: ".jpg",
          });
        });

        console.log("upload image new array", newArray);
        this.eData.documentUrl = newArray;
      });
  }

  uploadMedia(type: string) {
    console.log(type, "typess");

    if (type === "audio") {
      this.entertainmentService
        .uploadMultiMedia(this.audioFile)
        .subscribe((response: string) => {
          console.log("media upload response", response);
          const [rest, type] = response.split(".");
          console.log("types of media", type);
          console.log("rest of media", rest);

          this.entertainmentService.mediaUrls.push({
            Enter_Documenttype: `.${type}`,
            Enter_DocumentURL: response,
          });
        });
    } else if (type === "video") {
      this.entertainmentService
        .uploadMultiMedia(this.videoFile)
        .subscribe((response: string) => {
          console.log("media upload response", response);
          const [rest, type] = response.split(".");
          console.log("types of media", type);
          console.log("rest of media", rest);

          this.entertainmentService.mediaUrls.push({
            Enter_Documenttype: `.${type}`,
            Enter_DocumentURL: response,
          });
        });
    }
  }

  setEntertainmentType(e: any) {
    console.log("changeInput: --------------", e.target.value);
    this.customEntertainmentType = e.target.value;
  }

  AddCustomEntertainmentType() {
    this.entertainmentService.custom.type = this.customEntertainmentType.value;
    this.typeName = this.customEntertainmentType.value;
    this.customEntertainmentType.reset();
  }

  removeCustomType() {
    this.customEntertainmentType.reset();
    this.typeName = "";
    this.entertainmentService.custom.type = null;
  }

  changeValue(e: any, type: string) {
    if (type === "Enter_CatID") {
      this.getTypesEntertainment(e.target.value);
      this.customEntertainmentType.reset();
      this.entertainmentService.custom.type = null;
      this.typeName = "";
    }else if(type === 'Enter_Desription' || type === 'Enter_CovidMeasures' || type === 'BookingType'){
      this.eData[type] = e;
    }else{
      this.eData[type] = e.target.value;
    }
  }

  updateEntertainmentType(id: number) {
    console.log("type change", this.typeValues);
    if (this.typeValues.includes({ EntertainTypeId: id })) {
      this.typeValues = this.typeValues.filter((e) => {
        return e.EntertainTypeId !== id;
      });
    } else {
      this.typeValues.push({ EntertainTypeId: id });
    }
    
    console.log("entertainmentTypes", this.eData.EntertainTypeId);
  }

  updateBookingType(type: string) {
    this.eData.BookingType = type;
  }

  goForward(step:string){
    console.log("go forward",step);
    
    const validator = new Validator(this.eData);
    this.errors = validator[step]();
    console.log("errors",this.errors);

    if(!this.errors.length && step !== 'step3'){
      console.log("calling for next step");
      this.stepper.next();
    }else if(!this.errors.length && step === 'step3'){
      this.submitData();
    }
  }

  submitData() {
    this.eData.EntertainTypeId = this.typeValues;
    this.entertainmentService.updateFirstThreeSteps(this.eData);
    this.stepper.next();
  }
}


class Validator {
  constructor(private entertainmentData: AddEntertainmentStep1) {}

  public step1(): string[] {
    let errors: string[] = [];
    try {
      if (this.entertainmentData !== null) {
        if (!this.entertainmentData.Entertain_ServiceName.length) {
          throw new Error("Please Fill the Entertainment Service name");
        } else if (!this.entertainmentData.Enter_CatID) {
          throw new Error("Please Pick an Entertainment category");
        } else if (!this.entertainmentData.EntertainTypeId) {
          throw new Error("Please select the entertainment types");
        }
      } else {
        throw new Error("Please fill all the details");
      }
    } catch (error) {
      errors.push(error);
    }

    return errors;
  }

  public step2(): string[] {
    let errors: string[] = [];
    try {
      if (this.entertainmentData !== null) {
        if (!this.entertainmentData.Enter_Desription.length) {
          throw new Error("Please add the Description");
        }
      } else {
        throw new Error("Please fill all the details");
      }
    } catch (error) {
      errors.push(error);
    }

    return errors;
  }

  public step3(): string[] {
    let errors: string[] = [];

    try {
      if (this.entertainmentData !== null) {
        if ( !this.entertainmentData.Enter_LocationName) {
          throw new Error("Please add a location name");
        } else if (!this.entertainmentData.Enter_DistanceID) {
          throw new Error("Please add Distance willing to travel");
        } else if (!this.entertainmentData.BookingType) {
          throw new Error("Please choose a booking type");
        } else if (!this.entertainmentData.Enter_CovidMeasures ) {
          throw new Error("Please fill the covid measures");
        }
      }  else {
        throw new Error("Please fill all the details");
      }
    } catch (error) {
      errors.push(error);
    }

    return errors;
  }
}