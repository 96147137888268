import { TimeAllocation } from "../data-classes/time-allocation";
import { Amenity } from "./Amenity";
import { WorkingHours } from "./working-hours";
import { SittingArangement } from "./sitting-arangement";
import { PricingRule } from "./pricing-rule";
import { CancellationRule } from "./cancellation-rule";
import { PropertyAddress } from "./property-address";
import { EbentoUser } from "../users/ebento-user";

export class EbentoSpace
{
    // TODO: move this!
    public closedHours: TimeAllocation[]
    public name: string
    public minNumberOfGuests: number
    public maxNumberOfGuests: number
    public propertyId: number = -1
    public propertyName: string
    public spaceId: number = -1
    public spaceTypeIds: number[]
    public amenities: Amenity[]
    public photoUrls: string[]
    public images: FormData
    public description: string
    public floorSizeSqm: number
    public recomendedGuestsFrom: number
    public recomendedGuestsTo: number
    public workingHours: WorkingHours = WorkingHours.Default()
    public sittingArangements: SittingArangement[]
    public pricePerHour: number
    public pricingRules: PricingRule[]
    public cancellationRules: CancellationRule[]
    public address: PropertyAddress
    public propertyPhotoUrl: string
    public propertyManager: EbentoUser
    public forWorkshopsOnly: boolean
    public isDummyForWorkshops: boolean
    public isListingActive: boolean
    public covidMeasures: string
    public bookingType:string
}