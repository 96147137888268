import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackDTO } from '../../../../models/simple-pages/feedback-dto';
import { SimplePagesService } from '../../../../services/simple-pages.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationType } from 'app/helpers/enums/notificationType';
import { AuthService } from '../../../../services/auth.service';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

    model: FeedbackDTO;
    modelType: string = "Feedback";
    imageUrl: string = '';
    constructor(
        private simplePagesService: SimplePagesService,
        private notificationService: NotificationService,
        private authService: AuthService,
        public router: Router
    ) {
        this.model = new FeedbackDTO()
        if (authService.user != null)
        {
            this.model.userType = authService.userType;
            this.model.email = authService.user.email;
        }
    }

    ngOnInit() {

    }

    sendFeedback(form) {
        this.simplePagesService.sendFeedback(this.model).subscribe(
            res => {
                if (res) {
                    this.notificationService.notify(NotificationType.Success, this.modelType + " successfully sent");
                    this.model = new FeedbackDTO();
                    form.resetForm();
                    this.router.navigate(['index']);
                } else {
                    this.notificationService.notify(NotificationType.Error, this.modelType + " sending failed");
                }
            });
    }

}
