import { Amenity } from "../properties/Amenity";
import { Coupon } from "./coupon";
import { TimeAllocation } from "../data-classes/time-allocation";
import { BookingAmenity } from "./booking-amenity";
import { PaymentType } from "./payment-type";
import { IBookingOptions } from "./booking-options-interface";

export class SpaceBookingOptions implements IBookingOptions
{
    public bookingID: number
    public spaceID: number
    public timeAllocationDTOs: TimeAllocation[]
    public optionalAmenities: BookingAmenity[]
    public attendees: number
    public totalPrice: number
    public currency: string
    public paymentType: PaymentType
    public coupon: Coupon
    public message: string
    public paymentIntentID: string

    constructor()
    {
        this.optionalAmenities = new Array<BookingAmenity>();
        this.message = "";
        this.paymentType = new PaymentType()
    }

    RemoveAmenity(amenity: Amenity)
    {
        for (let bookingAmenity of this.optionalAmenities)
        {
            if (bookingAmenity.amenityTypeID == amenity.amenitytype.id)
            {
                this.optionalAmenities.splice(this.optionalAmenities.indexOf(bookingAmenity),1);
                break;
            }
        }
    }

    AddAmenity(amenity: Amenity)
    {
        for (let bookingAmenity of this.optionalAmenities) {
            if (bookingAmenity.amenityTypeID == amenity.amenitytype.id)
            {
                //console.log("item already present");
                return;
            }
        }
        var newAmenity = new BookingAmenity();
        newAmenity.amenityTypeID = amenity.amenitytype.id;
        
        this.optionalAmenities.push(newAmenity);
    }

    GetAmenityStatus(amenity: Amenity) : boolean {
        for (let bookingAmenity of this.optionalAmenities) {
            if (bookingAmenity.amenityTypeID == amenity.amenitytype.id) {
                return true;
            }
        }
        return false;
    }

    GetAmenityQty(amenity: Amenity) : number {
        for (let bookingAmenity of this.optionalAmenities) {
            if (bookingAmenity.amenityTypeID == amenity.amenitytype.id) {
                return bookingAmenity.quantity;
            }
        }
        return 0;
    }


    public GetBookingAmenityByAmenityType(typeId: number): BookingAmenity
    {
        for (let bookingAmenity of this.optionalAmenities) {
            if (bookingAmenity.amenityTypeID == typeId) {
                return bookingAmenity;
            }
        }
        return null;
    }
}
