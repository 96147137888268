
export class BankAccount {

    public iban: string
    public beneficiaryName: string
    public bankName: string
    public bankAddress: string
    public swift: string
    public id: number
    constructor() { }
}