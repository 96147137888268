import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { NotificationService } from '../../../services/notification.service';
import { NotificationType } from '../../../../../helpers/enums/notificationType';
import { UploadService } from '../../../services/upload.service';

@Component({
    selector: 'upload-image',
    templateUrl: 'upload-image.component.html',
    styleUrls: ['../../../css/upload.css']
})

export class UploadImage implements OnInit {
    @Input('type') type: string;
    @Input('imageURL') imageURL: string;
    @Input('isAddProperty') isAddProperty: string; 
    @Output() imageForUpload = new EventEmitter<File>();
    @Output() delete = new EventEmitter<string>();

    selectedImg: boolean = false;

    constructor(private http: HttpClient, private notificationService: NotificationService, private service: UploadService) { }

    ngOnInit(): void {
        console.log("value________________________________", this.isAddProperty );
    }

    public readURL(event: Event): void {
        let imgFile = (<HTMLInputElement>event.target).files[0];
        if (imgFile) {
            let file = imgFile;
            if (file.size > 1048576)
            {
                this.notificationService.notify(NotificationType.Error, "Image is too large. Please upload image less than 1MB")
            }
            else
            {
                this.selectedImg = true;
                const reader = new FileReader();
                reader.onload = e => this.imageURL = reader.result.toString();
                reader.readAsDataURL(file);
                this.imageForUpload.emit(file);
            }
        }
    }

    public deleteImage(): void {
        this.selectedImg = false;
        this.delete.emit(this.imageURL);
        this.imageURL = '';
        this.service.deleteImage(this.imageURL).subscribe(d => {
            console.log(d);
        })
    }
}