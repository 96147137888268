import { Component, OnInit, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { NotificationType } from 'app/helpers/enums/notificationType';
import { AdminNewsService } from '../../services/admin-news.service';
import { NotificationService } from 'app/modules/shared/services/notification.service';
import { AdminWorkshopService } from '../../services/admin-workshop.service';

@Component({
    selector: 'admin-inspiration-tag-cell',
    templateUrl: './admin-inspiration-tag-cell.component.html',
    styleUrls: ['./admin-inspiration-tag-cell.component.scss']
})
export class AdminInspirationTagCellComponent implements ICellRendererAngularComp {
    public params: any;
    
    constructor(private router: Router, public adminWorkshopService: AdminWorkshopService, private notificationService: NotificationService) { }

    agInit(params: any): void {
        this.params = params;
    }
    
    refresh(): boolean {
        return true;
    }

    publishToggle()
    {
        this.adminWorkshopService.setWorkshopInspirationTagPublic(this.params.data.id, !this.params.data.isPublic).subscribe(
            (success) =>
            {
                this.params.data.isPublic = !this.params.data.isPublic;
                if (this.params.data.isPublic) {
                    this.notificationService.notify(NotificationType.Success, "Tag " + this.params.data.name + " is now public", "Tag updated");
                }
                else
                {
                    this.notificationService.notify(NotificationType.Success, "Tag " + this.params.data.name + " is now private", "Tag updated");
                }  
                
                this.params.api.redrawRows();
            }
        );
    }
}
