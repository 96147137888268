import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from 'app/modules/shared/services/auth.service';
import { environment } from "../../../../../../environments/environment.prod";

@Component({
    selector: "register-common",
    templateUrl: "register-common.component.html",
    styleUrls: ['../../../../shared/css/user.css', './register-common.component.css']
})
export class RegisterCommon implements OnInit {

    constructor(
        public authService: AuthService,
        private activatedRouter: ActivatedRoute,
        private router: Router,
        private cdr: ChangeDetectorRef
    ) { }

    siteKey = environment.recaptcha;
    testKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

    public theme: 'light' | 'dark' = 'light';
    public size: 'compact' | 'normal' = 'normal';
    public lang = 'en';
    public type: 'image' | 'audio';
    public useGlobalDomain: boolean = false;

    public captchaIsLoaded = false;
    public captchaSuccess = false;
    public captchaIsExpired = false;
    public captchaResponse?: string;

    registrationForm = new FormGroup({
        email: new FormControl('', [Validators.required,Validators.email]),
        password: new FormControl('', Validators.required),
        confirmPassword: new FormControl('', Validators.required),
        recaptcha: new FormControl('')
    });


    showWorkshopHostingInfo: boolean
    showSpaceHostingInfo: boolean
    referralId: string
    referrerName: string
    errorMessage: string = "";

    public creds = {
        Email: "",
        Password: "",
        ConfirmPassword: "",
        UserType: ""
    };

    private sub: any;

    ngOnInit() {

        this.sub = this.activatedRouter.params.subscribe(params => {

            var info = params['info'];
            this.showSpaceHostingInfo = (info == 'list-space');
            this.showWorkshopHostingInfo = (info == 'list-experience');


            if(params['email']) {
                this.registrationForm.patchValue({email: params['email']})
            }

            this.creds.UserType = params['type'];
            this.creds.Email = params['email'];
            this.referralId = params['referral'];

            if (this.referralId != null) {
                this.authService.getReferrerName(this.referralId).subscribe(name => {
                    this.referrerName = name;
                });
            }

        });
    }


    setCredentials() {
        console.log("register",this.registrationForm);
        
        this.creds.Email = this.registrationForm.controls.email.value;
        this.creds.Password = this.registrationForm.controls.password.value;
        this.creds.ConfirmPassword = this.registrationForm.controls.confirmPassword.value;
        console.log("set credentials",this.registrationForm.value);
        console.log("set credentials",this.creds);
        
        this.onRegister();
    }

    onRegister() {

        let valid = this.validatePassword();

        if (!valid) {
            return;
        }

        else {

            if (this.referralId == null) {
                console.log(">!!!>!>");
                console.log(this.creds);
                this.authService.register(this.creds)
                    .subscribe(
                         success => {
                            this.router.navigate(["verify-by-email", "registration", this.creds.Email]);
                        },
                         err => { this.displayError(err); 
                        }
                    );
            }
            else {
                this.authService.registerWithReferral(this.creds, this.referralId)
                    .subscribe(
                        success => {
                            this.router.navigate(["verify-by-email", "registration", this.creds.Email]);
                        },
                        err => { this.displayError(err); }
                    );
            }
        }

    }

    private displayError(error: any): void {
        if (error.error == "existing") {
            this.errorMessage = `This email address is already in use. Please use a different email address.
            Please contact us at support@ebento.ch when you need support`
        }
        console.log(error.error);
    }

    //TODO: create validation service which will take all params from server
    private validatePassword(): boolean {

        if (this.creds.Password != this.creds.ConfirmPassword) {
            this.errorMessage = "Passwords do not match.";
            return false;
        }

        let minMaxLength = new RegExp(/^[\s\S]{8,64}$/);
        let upper = new RegExp(/[A-Z]/);
        let lower = new RegExp(/[a-z]/);
        let number = new RegExp(/[0-9]/);

        if (this.creds.Password.match(minMaxLength) &&
            this.creds.Password.match(upper) &&
            this.creds.Password.match(lower) &&
            this.creds.Password.match(number)) {

            return true;
        }

        else {
            this.errorMessage = "Password must contain at least 8 characters. The password must contain at least three character categories among the following:<br/>&#8226 1 Uppercase character(A - Z)<br/>&#8226 1 Lowercase character(a - z)<br/>&#8226 1 Digit(0 - 9)";
            return false;
        }

    }


    handleReset(): void {
        this.captchaSuccess = false;
        this.captchaResponse = undefined;
        this.captchaIsExpired = false;
        this.cdr.detectChanges();
    }

    handleSuccess(captchaResponse: string): void {
        this.captchaSuccess = true;
        this.captchaResponse = captchaResponse;
        this.captchaIsExpired = false;
        this.cdr.detectChanges();
    }

    handleLoad(): void {
        this.captchaIsLoaded = true;
        this.captchaIsExpired = false;
        this.cdr.detectChanges();
    }

    handleExpire(): void {
        this.captchaSuccess = false;
        this.captchaIsExpired = true;
        this.cdr.detectChanges();
    }

    handleError(): void {
        this.captchaSuccess = false;
        this.captchaIsExpired = false;
        this.cdr.detectChanges();
    }

    scrollTop() {
        window.scrollTo(0,0);
    }
}