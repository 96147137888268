import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';

import { Injectable } from "@angular/core";
import { map, catchError, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { throwError } from 'rxjs';
import { EbentoWorkshop } from "../models/workshops/ebento-workshop";
import { PropertyService } from "./property.service";
import { GeolocationService } from "./geolocation.service";
import { UploadService } from "./upload.service";
import { TimeAllocation } from "../models/data-classes/time-allocation";
import { BookingSegment } from "../models/booking/booking-segment";
import { WorkingHours } from "../models/properties/working-hours";
import { WorkshopTile } from "../models/search/workshop-tile";
import { WorkshopPricingRule } from "../models/workshops/workshop-pricing-rule";
import { PricingRule } from "../models/properties/pricing-rule";
import { WorkshopPricingRuleType } from "../models/workshops/workshop-pricing-rule-type";
import { WorkshopTypeTag } from "../models/tags/workshop-type-tag";
import { WorkshopTypeTile } from "../models/search/workshop-type-tile";
import { Geolocation } from "../models/search/geolocation";
import { WorkshopInspirationTag } from "../models/tags/workshop-inspiration-tag";
import { WorkshopBookingMenu } from "../models/booking/workshop-booking-menu";
import { WorkshopMenu } from "../models/workshops/workshop-menu";


@Injectable()
export class WorkshopService {

    public workshopToEdit: EbentoWorkshop
    public workshop: EbentoWorkshop
    public workshops: EbentoWorkshop[]
    public workingHours: WorkingHours
    public workshopDuration = [
        {name: '30 minutes', value: 0.5}, 
        {name: '1 hour', value: 1},
        {name: '1.5 hours', value: 1.5},
        {name: '2 hours', value: 2},
        {name: '2.5 hours', value: 2.5},
        {name: '3 hours', value: 3},
        { name: '4 hours', value: 4 }
        //      { name: 'Half day', value: 'half' },    
        //{ name: 'Full day', value: 'full' }, removed due to type incompatibility and logic problems
    ];
    public selectedIndex:number = 0;
  
    public pricingRuleTypes: WorkshopPricingRuleType[] = [
        { displayText: 'Price per person', id: 1, name: 'perPerson' } as WorkshopPricingRuleType,
        { displayText: 'Fixed price', id: 2, name: 'fixed' } as WorkshopPricingRuleType,
    ]

    public workshopTypeTags: WorkshopTypeTag[]
    public workshopInspirationTags: WorkshopInspirationTag[]

    constructor(
        private http: HttpClient,
        private geolocation_service: GeolocationService,
        private uploadService: UploadService,
        private propertyService: PropertyService,
    ) {
    }
    
    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    public getDurationLabel(duration: number): string
    {
        for (var i = 0; i < this.workshopDuration.length; i++) {
            if (this.workshopDuration[i].value == duration)
                return this.workshopDuration[i].name;
        }
        return '';
    }

    setWorkshopActive(workshopID: number, active: boolean) {
        return this.http.post("api/set-workshop-active/" + workshopID, active)
            .pipe(
                map((result: boolean) => {
                    return true;
                }),
                catchError(this.errorHandler)
            );
    }

    public addWorkshop(hasImages: boolean): Observable<number> {
        if (hasImages) {
            return this.uploadService.uploadImages(this.workshop.images)
                .pipe(
                    switchMap(
                        
                        (res: string[]) => {
                            //console.log(res);
                            this.workshop.photoUrls = res;
                            return this.addWorkshopWithoutUpload();
                        }
                    )
                )

        }
        else {
            return this.addWorkshopWithoutUpload();
        }
    }

    private addWorkshopWithoutUpload(): Observable<number> {
            // console.log("London D MAGNIFISNT");
        /*var closedHours = new Array<TimeAllocation>();
        Ovde baca neki error, pogledati da li dobro kupi vremena 
        zakometarisi for pa probaj da vidis koji mu je andrak @Luka
        for (let timeslot of this.selectedNonWorkingHours) {
            closedHours.push(TimeAllocation.FromBooking(timeslot));
        }
       this.workshop.closedHours = closedHours;*/
        // console.log("WS London calling");
       
    const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };

        return this.http.post<number>("api/Workshops", JSON.stringify(this.workshop), httpOptions)
            .pipe(
            map(res => {
                    return res;
                }
                )
            )
    }

    public editWorkshop(hasImages: boolean): Observable<number> {
        if (hasImages) {
            return this.uploadService.uploadImages(this.workshop.images)
                .pipe(
                    switchMap(
                        (res: string[]) => {
                            for (let url of res)
                                this.workshop.photoUrls.push(url);
                            return this.editWorkshopWithoutUpload();
                        }
                    )
                )

        }
        else {
            return this.editWorkshopWithoutUpload();
        }
    }

    private editWorkshopWithoutUpload(): Observable<number> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };
        return this.http.post<number>("api/UpdateWorkshop", this.workshop, httpOptions)
            .pipe(
                map(res => {
                    return res;
                }
                )
            )

    }

    getWorkshopTiles(): Observable<WorkshopTile[]> {
        return this.http.get<WorkshopTile[]>("api/WorkshopTiles")
            .pipe(
                catchError(this.errorHandler)
            );
    }


    getWorkshops(): Observable<EbentoWorkshop[]> {
        return this.http.get<EbentoWorkshop[]>("api/Workshops")
            .pipe(
                map(result => this.workshops = result),
                catchError(this.errorHandler)
            );
    }

    getWorkshopsThisWeek(cityName): Observable<WorkshopTile[]> {
        return this.http.get<WorkshopTile[]>("api/WorkshopsThisWeek" + ((cityName != null) ? "/"+ cityName : ""))
            .pipe(
            map(result => { return result;}),
                catchError(this.errorHandler)
            );
    }

    getClosestWorkshops(geolocation: Geolocation): Observable<WorkshopTile[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };

        return this.http.post<WorkshopTile[]>("api/ClosestWorkshops", geolocation, httpOptions).
            pipe(
                map(result => { return result; }),
                catchError(this.errorHandler)
            );
    }

    getWorkshop(id: number): Observable<EbentoWorkshop> {        
        return this.http.get<EbentoWorkshop>("api/Workshop/" + id)
            .pipe(
                map
                    (result => { 
                        this.workshop = result;
                        return result;
                }), 
                catchError(this.errorHandler)
            );
    }
    
    /*private updateWorkshops() {
        this.getWorkshops();
        this.propertyService.refreshProperty()
            .subscribe((success) => { },
            (er) => console.error(er)
            )
    }*/

    public getAllWorkshopForProperty(workshopID:number): Observable<WorkshopTile[]> {
        return this.http.get<WorkshopTile[]>("api/WorkshopsForProperty/" + workshopID);
    }


    // Tags

    public getAllWorkshopTypeTags(): Observable<WorkshopTypeTag[]> {
        return this.http.get<WorkshopTypeTag[]>("api/WorkshopTypeTags")
            .pipe(
                map(result => this.workshopTypeTags = result),
                catchError(this.errorHandler)
            );
    }


    public getAllInspirationTypeTags(): Observable<WorkshopInspirationTag[]> {
        return this.http.get<WorkshopInspirationTag[]>("api/WorkshopInspirationTags")
            .pipe(
            map(result => this.workshopInspirationTags = result),
                catchError(this.errorHandler)
            );
    }

    public getHomepageWorkshopTypeTags(): Observable<WorkshopTypeTile[]> {
        return this.http.get<WorkshopTypeTile[]>("api/HomepageWorkshopTypeTags")
            .pipe(
            map(result => { 
                return result; }),
                catchError(this.errorHandler)
            );
    }

    public GetMenuByID(menuID): WorkshopMenu
    {
        return this.workshop.menus.find(m => m.id == menuID);
    }

}