/// <reference types="@types/googlemaps" />

import { Component, ViewChild, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AddEntertainmentService } from "../../../property-manager/services/add-entertainment.service";
import { Entertainment } from "../../../shared/models/entertainment/AddEntertainmentStep1";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { EbentoModalService } from "../../../shared/services/ebento-modal.service";
import { WeekCalendarModal } from "app/modules/shared/modules/modals/components/week-calendar-modal/week-calendar-modal.component";
import { AuthService } from "../../../shared/services/auth.service";
import { PropertyService } from "../../../shared/services/property.service";
import { MessagingService } from "../../../shared/services/messaging.service";
import { EbentoMessage } from "../../../shared/models/messaging/ebento-message";
import { CartService } from "../../services/cart.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { NotificationType } from "../../../../helpers/enums/notificationType";

@Component({
  selector: "modify-cart-entertainment",
  templateUrl: "./modify-cart-entertainment.component.html",
  styleUrls: ["./modify-cart-entertainment.component.scss"],
})
export class ModifyCartEntertainment implements AfterViewInit, OnInit {
  modalRef: NgbModalRef;

  @ViewChild("gmap") gmapElement: any;
  public map: google.maps.Map;
  public entertainmentData: Entertainment;
  public activeImage: string;
  public videoData: any[] = [];
  public audioData: any[] = [];
  public images: any[] = [];
  attendees: number = 0;
  isBookbyEnquiry: boolean = true;
  timeslots: any[];
  cartId: number;
  prevTimeSlots: any[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public propertyService: PropertyService,
    public addEntertainmentService: AddEntertainmentService,
    public messagingService: MessagingService,
    private modalService: EbentoModalService,
    public authService: AuthService,
    private cartService: CartService,
    private notificationService: NotificationService
  ) {}

  ngAfterViewInit() {
    this.setMap();
  }

  ngOnInit(): void {
    let spaceIdParam = this.activatedRoute.snapshot.params;
    console.log("space params", spaceIdParam);

    this.cartService
      .getModify(spaceIdParam.cartId, spaceIdParam.serviceId, "entertainment")
      .subscribe((response) => {
        // this.entertainmentData = response[0];
        window.scrollTo(0, 0);

        this.entertainmentData = response.spaceDetails[0];
        this.entertainmentData.propertyDetails =
          response.spaceDetails[0].property;
        this.addEntertainmentService.entertainmentData = this.entertainmentData;
        response.spaceDetails[0].documentUrl.forEach((e) => {
          const [dot, type] = e.enter_DocumentURL.split(".");

          if (e.enter_DocumentURL.length && type === "jpg") {
            this.images.push(e.enter_DocumentURL);
          }
        });

        this.activeImage = this.images[0];

        this.cartId = response.cartId;
        this.attendees = response.attendees;
        this.prevTimeSlots = response.bookedTimeSlots.map((e) => {
          return {
            startTime: this.formatTime(e.startTime),
            endTime: this.formatTime(e.endTime),
            allocationType: e.allocationType,
          };
        });

        this.timeslots = this.prevTimeSlots.map((e) => {
          return {
            startTime: this.returnDate(e.startTime),
            allocationType: e.allocationType,
            name: "",
            duration: {
              totalseconds: this.calculateDuration(e.startTime, e.endTime),
            },
          };
        });

        this.addEntertainmentService.bookingOptions.attendees = this.attendees;
        this.addEntertainmentService.bookingTimeslots = this.timeslots;
        this.addEntertainmentService.serviceId = response.serviceId;

        this.addEntertainmentService
          .getMedia(response.serviceId)
          .subscribe((response) => {
            console.log("get media", response);
            response.forEach((e) => {
              if (
                e.enter_Documenttype === ".mp4" ||
                (e.enter_Documenttype === ".mkv" && e.enter_DocumentURL.length)
              ) {
                this.videoData.push(e.enter_DocumentURL);
              } else if (
                e.enter_Documenttype === ".m4a" ||
                (e.enter_Documenttype === ".mp3" && e.enter_DocumentURL.length)
              ) {
                this.audioData.push(e.enter_DocumentURL);
              }
            });

            // console.log('audios and videos',this.videoData,this.audioData);
          });
      });
  }

  setMap() {
    var mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    let map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.map = map;

    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        this.entertainmentData.propertyDetails.address.googleMapCoordX,
        this.entertainmentData.propertyDetails.address.googleMapCoordY
      ),
      // position: { lat: -34.397, lng: 150.644 },
      map: this.map,
    });

    var bounds = new google.maps.LatLngBounds();
    bounds.extend(marker.getPosition());

    this.map.fitBounds(bounds);
    var listener = google.maps.event.addListener(map, "idle", function () {
      if (this.map.getZoom() > 16) this.map.setZoom(16);
      google.maps.event.removeListener(listener);
    });
  }

  reduceAttendees() {
    if (this.attendees > 0) {
      --this.attendees;
    }
    this.addEntertainmentService.bookingOptions.attendees = this.attendees;
    // this.bookingService.bookingOptions.attendees = this.attendees;
  }

  incrementAttendees() {
    ++this.attendees;
    this.addEntertainmentService.bookingOptions.attendees = this.attendees;
    // this.bookingService.bookingOptions.attendees = this.attendees;
  }

  setBigImage(i: number) {
    this.activeImage = this.images[i];
  }

  openModal() {
    // const modalRef = this.modalService.open(ModalComponent);
    this.modalRef = this.modalService.open(WeekCalendarModal, { size: "xl" });

    this.modalRef.componentInstance.title = "Reservation Calendar";
    this.modalRef.componentInstance.spaceId =
      this.entertainmentData.entertain_ServiceId;
    this.modalRef.componentInstance.isEntertainment = true;
    //@refer space listing component for more clarity
    // if (this.bookingService.bookingTimeslots != null)
    //     this.modalRef.componentInstance.SetSelections(this.bookingService.bookingTimeslots);
    // //this.modalRef.result.then(() => { this.bookingTimeslots = this.modalRef.componentInstance.result; }, () => { this.bookingTimeslots = this.modalRef.componentInstance.result; })
    this.modalRef.componentInstance.passBookings.subscribe((receivedEntry) => {
      // this.bookingService.bookingTimeslots = receivedEntry;
      console.log("selected slots", receivedEntry);
      this.addEntertainmentService.bookingTimeslots = receivedEntry;
      let temp = receivedEntry.map((e) => {
        let d = new Date(e.startTime).toJSON();
        let [date, ...rest] = d.split("T");

        let time = `${e.startTime.getHours()}:${e.startTime.getMinutes()}:${e.startTime.getSeconds()}`;
        let add = e.startTime.getTime();
        add += e.duration.totalseconds * 1000;
        let newd = new Date(e.startTime).setTime(add);
        console.log("newd", newd);
        let properDate = new Date(newd);
        console.log("newd", properDate);
        let endTime = `${properDate.getHours()}:${properDate.getMinutes()}:${properDate.getSeconds()}`;
        return {
          startTime: `${date} ${time}`,
          endTime: `${date} ${endTime}`,
          allocationType: 3,
        };
      });

      console.log("time allocation", temp);
      this.addEntertainmentService.timeAllocationDTO = temp;
    });
  }

  public AddressText(address: any) {
    let result = `${address.streetAddress},${address.streetNumber},  
            ${address.postalCode},
            ${address.city},
            ${address.country}
        `;
    return result;
  }

  gotoProfile() {
    this.propertyService
      .getProperty(this.entertainmentData.propertyIDEbentoProperty)
      .subscribe(() => this.router.navigate(["/property-details"]));
  }

  sendMessage() {
    var propertyManager =
      this.entertainmentData.propertyDetails.propertyManager;
    this.messagingService.currentRecepient = propertyManager;
    this.messagingService.currentDraft = new EbentoMessage();
    this.router.navigate(["/messages/compose"]);
  }

  AddToCart() {
    let slots;
    if (
      this.addEntertainmentService.timeAllocationDTO !== null &&
      this.addEntertainmentService.timeAllocationDTO.length
    ) {
      slots = this.addEntertainmentService.timeAllocationDTO;
    } else {
      slots = this.prevTimeSlots.filter((e) => {
        return {
          startTime: this.formatTime(e.startTime),
          endTime: this.formatTime(e.endTime),
          allocationType: e.allocationType,
        };
      });
    }

    const temp = {
      cartId: this.cartId,
      bookedTimeSlots: slots,
      attendees: this.attendees,
      spaceType: "Entertainment",
      optionalAmenities: [],
      totalPrice: this.addEntertainmentService.VenuePrice(),
      serviceId: this.entertainmentData.entertain_ServiceId,
      spaceName: this.entertainmentData.entertain_ServiceName,
      propertyName: this.entertainmentData.propertyDetails.Name,
      image: this.images[0],
    };

    this.cartService.updateCartModify(temp).subscribe(
      (resp) => {
        console.log("response from addtocart", resp);
        this.notificationService.notify(
          NotificationType.Success,
          `${this.entertainmentData.entertain_ServiceName} is updated in cart`,
          "Entertainment updated in cart"
        );
        this.router.navigate(["/cart"]);
      },
      (err) => {
        console.log("add cart error", err);
        this.notificationService.notify(
          NotificationType.Error,
          `${this.entertainmentData.entertain_ServiceName} cannot be added to the cart`,
          "Please try again later"
        );
      }
    );
  }

  formatTime(d: string) {
    const [date, time, format] = d.split(" ");
    const [dd, mm, yy] = date.split("/");
    const newDate = `${mm}/${dd}/${yy} ${time}`;
    return newDate;
  }

  calculateDuration(startTime: string, endTime: string) {
    const [sdate, stime, sformat] = startTime.split(" ");
    const [edate, etime, eformat] = endTime.split(" ");
    console.log("stime", stime, "etime", etime, "formats", sformat, eformat);

    const [shours, sminutes, sseconds] = stime.split(":");
    const [ehours, eminutes, eseconds] = etime.split(":");

    let time_start = new Date();
    let time_end = new Date();
    time_start.setHours(Number(shours), Number(sminutes), Number(sseconds), 0);
    time_end.setHours(Number(ehours), Number(eminutes), Number(eseconds), 0);
    console.log("time diff", time_start.getTime(), time_end.getTime());

    const duration = (time_end.getTime() - time_start.getTime())/1000;
    let difference = duration;
    difference = difference / 1000;
    let hourDifference = Math.floor(difference / 3600);
    difference -= hourDifference * 3600;
    let minuteDifference = Math.floor(difference / 60);
    difference -= minuteDifference * 60;
    return duration;
  }

  returnDate(d: string): Date {
    const [date, time] = d.split(" ");
    const [dd, mm, yy] = date.split("/");
    const [hh, mn, ss] = time.split(":");
    const newDate = new Date(
      Number(yy),
      Number(mm) - 1,
      Number(dd),
      Number(hh),
      Number(mn),
      Number(ss)
    );
    console.log("calculated new Date", newDate);
    return newDate;
  }
}
