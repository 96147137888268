import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Administrator } from "app/modules/shared/models/users/administrator";
import { AuthService } from "app/modules/shared/services/auth.service";

@Component({
    selector: "edit-profile-admin",
    templateUrl: "edit-profile-admin.component.html",
    styleUrls: ['../../../shared/css/profile-settings.css']
})
export class EditProfileAdmin implements OnInit {

    public admin: Administrator;
    public coverImage: FormData;
    errorMessage: string;

    constructor(
        public authService: AuthService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.admin = Object.assign({}, this.authService.user as Administrator);
    }

    onImageUploaded(file: File) {
        let formData = new FormData();
        formData.append(file.name, file);
        this.coverImage = formData;
    }

    public editProfile() {
        this.authService.editProfileAdministrator(this.coverImage, this.admin).subscribe(
            () => this.router.navigate(["/admin/profile"])
        )
    }
}