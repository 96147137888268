import { Component, OnInit, Input } from '@angular/core';
import { EbentoWorkshop } from 'app/modules/shared/models/workshops/ebento-workshop';

@Component({
  selector: 'workshop-listing-pricing',
  templateUrl: './workshop-listing-pricing.component.html',
  styleUrls: ['./workshop-listing-pricing.component.scss']
})
export class WorkshopListingPricing implements OnInit {

    @Input() workshop: EbentoWorkshop

  constructor() { }

  ngOnInit() {
  }

}
