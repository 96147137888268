import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'listing-active-cell',
    templateUrl: './listing-active-cell.component.html',
    styleUrls: ['./listing-active-cell.component.scss']
})
export class ListingActiveCell implements ICellRendererAngularComp {
    public params: any;

    constructor() { }


    public invokeParentMethod() {
        console.log(this.params.data.spaceId);
        console.log(this.params.data.workshopId);

        this.params.context.componentParent.openListingActiveModal((this.params.data.spaceId != null) ? this.params.data.spaceId : this.params.data.workshopId, this.params.data.name, this.params.data.managerID);
    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return true;
    }

    

}
