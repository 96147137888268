import { Component, OnInit, Input } from '@angular/core';

import { Router } from "@angular/router";
import { EbentoSpace } from '../../models/properties/ebento-space';
import { AuthService } from '../../services/auth.service';
import { WorkshopTile } from '../../models/search/workshop-tile';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { WorkshopBookingService } from '../../services/workshop-booking.service';

@Component({
    selector: 'property-details-workshop-tile',
    templateUrl: 'property-details-workshop-tile.component.html',
    styleUrls: ['property-details-workshop-tile.component.scss']
})

export class PropertyDetailsWorkshopTile {

    //fa 
    faUsers = faUsers;

    @Input() workshopTile: WorkshopTile

    constructor(private router: Router, private bookingService: WorkshopBookingService, public authService: AuthService) {
    }

    openWorkshopDetails() {
        if (this.authService.userType == "EventPlanner")
            this.bookingService.startNewBooking(this.workshopTile.id);
        else
            this.router.navigate(['experience-details/' + this.workshopTile.id]);
    }

    get minPrice()
    {
        return Math.round(this.workshopTile.pricePerPerson * this.workshopTile.minNumberOfGuests);
    }
}
