/// <reference types="@types/googlemaps" />

import { Component, ViewChild, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AddEntertainmentService } from "../../../property-manager/services/add-entertainment.service";
import { Entertainment } from "../../../shared/models/entertainment/AddEntertainmentStep1";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { EbentoModalService } from "../../../shared/services/ebento-modal.service";
import { WeekCalendarModal } from "app/modules/shared/modules/modals/components/week-calendar-modal/week-calendar-modal.component";
import { AuthService } from "../../../shared/services/auth.service";
import { PropertyService } from "../../../shared/services/property.service";
import { MessagingService } from "../../../shared/services/messaging.service";
import { EbentoMessage } from "../../../shared/models/messaging/ebento-message";
import { CartService } from "../../services/cart.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { NotificationType } from "../../../../helpers/enums/notificationType";
import { TimeAllocation } from "../../../shared/models/data-classes/time-allocation";

@Component({
  selector: "entertainment-details",
  templateUrl: "./entertainment-details.component.html",
  styleUrls: ["./entertainment-details.component.css"],
})
export class EntertainmentDetails implements AfterViewInit, OnInit {

  modalRef: NgbModalRef;

  @ViewChild("gmap") gmapElement: any;
  public map: google.maps.Map;
  public entertainmentData: Entertainment;
  public activeImage: string;
  public videoData: any[] = [];
  public audioData: any[] = [];
  public images: any[] = [];
  attendees: number = 0;
  isBookbyEnquiry: boolean = false;
  propertyImage:string = 'assets/images/profile.png';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public propertyService: PropertyService,
    public addEntertainmentService: AddEntertainmentService,
    public messagingService: MessagingService,
    private modalService: EbentoModalService,
    public authService: AuthService,
    private cartService: CartService,
    private notificationService: NotificationService
  ) {
    console.log("params id", this.activatedRoute.snapshot.params.id);
    this.addEntertainmentService.serviceId =
      this.activatedRoute.snapshot.params.id;
  }

  ngAfterViewInit() {
    this.setMap();
  }

  ngOnInit(): void {
    this.addEntertainmentService
      .getPreviewData(this.addEntertainmentService.serviceId)
      .subscribe((response) => {
        // this.entertainmentData = response[0];
        this.entertainmentData = {
          entertain_ServiceId: response[0].Entertain_ServiceId,
          entertain_ServiceName: response[0].Entertain_ServiceName,
          enter_CatID: response[0].Enter_CatID,
          entertainTypeId: response[0].EntertainTypeId,
          enter_Desription: response[0].Enter_Desription,
          enter_LocationID: response[0].Enter_LocationID,
          Enter_LocationName:response[0].Enter_LocationName,
          enter_DistanceID: response[0].Enter_DistanceID,
          bookingType: response[0].BookingType,
          enter_CovidMeasures: response[0].Enter_CovidMeasures,
          documentUrl: response[0].documentUrl,
          pricingRules: response[0].pricingRules,
          ecancellationRules: response[0].EcancellationRules,
          Enter_priceperEvent: response[0]?.Enter_priceperEvent,
          isActive: response[0].isActive,
          additionalCosts: response[0].AdditionalCosts,
          pricingType: response[0].PricingType,
          availableTimeslots: response[0].avaavailableTimeslots,
          propertyIDEbentoProperty: response[0].PropertyIDEbentoProperty,
          propertyDetails: response[0].Property,
        };

        if (response[0].BookingType == 2) this.isBookbyEnquiry = true;

        console.log("get perview data", this.entertainmentData);
        this.addEntertainmentService.entertainmentData = response[0];
        this.addEntertainmentService.propertyId = String(
          response[0].propertyIDEbentoProperty
        );
        // this.activeImage = response[0].documentUrl[0]['Enter_DocumentURL'];
        // console.log('active Image',this.activeImage);

        response[0].documentUrl.forEach((e) => {
          const [dot, type] = e.Enter_DocumentURL.split(".");

          if (e.Enter_DocumentURL.length && type === "jpg") {
            this.images.push(e.Enter_DocumentURL);
          }
        });

        this.activeImage = this.images[0];
        this.propertyImage = this.entertainmentData.propertyDetails.PhotoURL ? this.entertainmentData.propertyDetails.PhotoURL : 'assets/images/profile.png';
      });

    this.addEntertainmentService
      .getMedia(this.addEntertainmentService.serviceId)
      .subscribe((response) => {
        console.log("get media", response);
        response.forEach((e) => {
          if (
            e.enter_Documenttype === ".mp4" ||
            (e.enter_Documenttype === ".mkv" && e.enter_DocumentURL.length)
          ) {
            this.videoData.push(e.enter_DocumentURL);
          } else if (
            e.enter_Documenttype === ".m4a" ||
            (e.enter_Documenttype === ".mp3" && e.enter_DocumentURL.length)
          ) {
            this.audioData.push(e.enter_DocumentURL);
          }
        });

        // console.log('audios and videos',this.videoData,this.audioData);
        console.log("disabled", this.addEntertainmentService.enableButton());
      });
  }

  setMap() {
    var mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    let map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.map = map;

    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        this.entertainmentData.propertyDetails.Address.GoogleMapCoordX,
        this.entertainmentData.propertyDetails.Address.GoogleMapCoordY
      ),
      // position: { lat: -34.397, lng: 150.644 },
      map: this.map,
    });

    var bounds = new google.maps.LatLngBounds();
    bounds.extend(marker.getPosition());

    this.map.fitBounds(bounds);
    var listener = google.maps.event.addListener(map, "idle", function () {
      if (this.map.getZoom() > 16) this.map.setZoom(16);
      google.maps.event.removeListener(listener);
    });
  }

  reduceAttendees() {
    if (this.attendees > 0) {
      --this.attendees;
    }
    this.addEntertainmentService.bookingOptions.attendees = this.attendees;
    // this.bookingService.bookingOptions.attendees = this.attendees;
  }

  incrementAttendees() {
    ++this.attendees;
    this.addEntertainmentService.bookingOptions.attendees = this.attendees;
    // this.bookingService.bookingOptions.attendees = this.attendees;
  }

  setBigImage(i: number) {
    this.activeImage = this.images[i];
  }

  BookNow() {
    if (this.isBookbyEnquiry) {
      this.router.navigate(["enquiry-booking"]);
    } else {
      this.router.navigate(["entertainment-booking"]);
    }
  }

  openModal() {
    // const modalRef = this.modalService.open(ModalComponent);
    this.modalRef = this.modalService.open(WeekCalendarModal, { size: "xl" });

    this.modalRef.componentInstance.title = "Reservation Calendar";
    this.modalRef.componentInstance.spaceId =
      this.entertainmentData.entertain_ServiceId;
    this.modalRef.componentInstance.isEntertainment = true;
    //@refer space listing component for more clarity
    // if (this.bookingService.bookingTimeslots != null)
    //     this.modalRef.componentInstance.SetSelections(this.bookingService.bookingTimeslots);
    // //this.modalRef.result.then(() => { this.bookingTimeslots = this.modalRef.componentInstance.result; }, () => { this.bookingTimeslots = this.modalRef.componentInstance.result; })
    this.modalRef.componentInstance.passBookings.subscribe((receivedEntry) => {
      // this.bookingService.bookingTimeslots = receivedEntry;
      console.log("selected slots", receivedEntry);
      this.addEntertainmentService.bookingTimeslots = receivedEntry;
      // let temp = receivedEntry.map((e) => {
      //     let d =new Date(e.startTime).toJSON();
      //     let [date,...rest] = d.split('T');

      //     let time = `${e.startTime.getHours()}:${e.startTime.getMinutes()}:${e.startTime.getSeconds()}`;
      //     let add = e.startTime.getTime()
      //     add += (e.duration.totalseconds*1000);
      //     let newd = new Date(e.startTime).setTime(add);
      //     console.log('newd',newd);
      //     let properDate = new Date(newd);
      //     console.log('newd',properDate);
      //     let endTime = `${properDate.getHours()}:${properDate.getMinutes()}:${properDate.getSeconds()}`;
      //     return {
      //         "startTime": `${this.returnFormatedDate(date)}, ${time}`,
      //         "endTime": `${this.returnFormatedDate(date)}, ${endTime}`,
      //         "allocationType": 3,
      //         "isCustom": true
      //     }

      // })
      // console.log('time allocation',temp);

      const allocations = new Array<TimeAllocation>();
      for (let timeslot of receivedEntry) {
        allocations.push(TimeAllocation.FromBooking(timeslot));
      }

      const temp = allocations.map((e) => {
        return {
          startTime: e.startTime,
          endTime: e.endTime,
          allocationType: 3,
          isCustom: true,
        };
      });

      this.addEntertainmentService.timeAllocationDTO = temp;
    });
  }

  public AddressText(address: any) {
    let result = `${address.StreetAddress},${address.StreetNumber},  
            ${address.PostalCode},
            ${address.City},
            ${address.Country}
        `;
    return result;
  }

  returnFormatedDate(date: string): string {
    console.log("return Formated date", date);
    let [yy, mm, dd] = date.split("-");
    return `${mm}/${dd}/${yy}`;
  }

  gotoProfile() {
    this.propertyService
      .getProperty(this.entertainmentData.propertyIDEbentoProperty)
      .subscribe(() => this.router.navigate(["/property-details"]));
  }

  sendMessage() {
    var propertyManager =
      this.entertainmentData.propertyDetails.propertyManager;
    this.messagingService.currentRecepient = propertyManager;
    this.messagingService.currentDraft = new EbentoMessage();
    this.router.navigate(["/messages/compose"]);
  }

  AddToCart() {
    const temp = {
      bookedTimeSlots: this.addEntertainmentService.timeAllocationDTO,
      attendees: this.attendees,
      spaceType: "Entertainment",
      optionalAmenities: [],
      totalPrice: this.addEntertainmentService.VenuePrice(),
      serviceId: this.entertainmentData.entertain_ServiceId,
      spaceName: this.entertainmentData.entertain_ServiceName,
      propertyName: this.entertainmentData.propertyDetails.Name,
      image: this.images[0],
    };

    this.cartService.addToCart(temp).subscribe(
      (resp) => {
        ++this.cartService.cartcount;
        this.notificationService.notify(
          NotificationType.Success,
          `${this.entertainmentData.entertain_ServiceName} is added to the cart`,
          "Event space Added to the cart"
        );
      },
      (err) => {
        console.log("add cart error", err);
        this.notificationService.notify(
          NotificationType.Error,
          `${this.entertainmentData.entertain_ServiceName} cannot be added to the cart`,
          "Please try again later"
        );
      }
    );
  }
}
