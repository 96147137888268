import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { EbentoUser } from '../../../shared/models/users/ebento-user';
import { EbentoMessage } from '../../../shared/models/messaging/ebento-message';
import { AuthService } from '../../../shared/services/auth.service';
import { MessagingService } from '../../../shared/services/messaging.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { PropertyService } from '../../../shared/services/property.service';
import { WorkshopBookingService } from '../../../shared/services/workshop-booking.service';


@Component({
    selector: "workshop-enquiry-booking",
    templateUrl: "./workshop-enquiry-booking.component.html",
    styleUrls: ['./workshop-enquiry-booking.component.scss']
})

export class WorkshopEnquiryBooking implements OnInit{

    public scrHeight: number;
    public scrWidth: number;
    public email: string;
    public eventPlanner: EbentoUser;
    public propertyManager: EbentoUser;
  
    public AdditionalInfo: any = {
      location: "",
      descriptionOfEventspace: "",
      descriptionofEvent: "",
      SpecificRequirements: "",
    };
  
    constructor(
      private router: Router,
      public propertyService: PropertyService,
      public authService: AuthService,
      private messagingService: MessagingService,
      private notificationService: NotificationService,
      public bookingService: WorkshopBookingService
    ) {
      this.getScreenSize();
    }
  
    ngOnInit(): void {
      this.email = this.authService.user.email;
      this.eventPlanner = this.authService.user;
      // console.log("booking service space",this.bookingService.space);
      // console.log("screen width",this.scrWidth);
      
      this.propertyService.getProperty(
        Number(this.bookingService.workshop.propertyId)
      ).subscribe((response) => {
          this.propertyManager = response.propertyManager;
      })
    }
  
    sendMessage(
      id: string,
      spaceName: string,
      propertyName: string,
      spaceType: string
    ) {
      this.messagingService.currentDraft = new EbentoMessage();
      this.messagingService
        .sendEnquiryMessage(
          this.eventPlanner,
          this.propertyManager,
          id,
          spaceName,
          propertyName,
          spaceType
        )
        .subscribe({
          next: (response) => {
            this.router.navigate(["enquiry-booking/success"]);
          },
          error: (error) => {
            console.error("Failed to send message:", error);
            // You can show a toast message or an alert box to the user indicating message sending failure.
          },
        });
    }
  
    sendRequest() {
      if (!this.bookingService.bookingOptions) {
        console.warn("Booking options are missing. Unable to send request.");
        return;
      }
      console.log("additional infor",this.AdditionalInfo);
      
      this.bookingService.sendEnquiryRequest(this.AdditionalInfo,'Workshop').subscribe(
        (res: any) => {
          console.log("res", res);
          this.sendMessage(
            res.requestId,
            this.bookingService.workshop.name,
            this.bookingService.workshop.propertyName,
            "Workshop"
          );
        },
        (err) => {
          console.error("An error occurred while sending the request:", err);
          // Handle the error, e.g. by displaying a message to the user.
        }
      );
    }
  
    goBack() {
      this.router.navigate([
        "experience-listing/" + this.bookingService.workshop.id,
      ]);
    }
  
    @HostListener("window:resize", ["$event"])
    getScreenSize(event?) {
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
    }

}