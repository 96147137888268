import { Injectable } from "@angular/core";
import { Observable, throwError, Subject } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { HubConnectionBuilder, HubConnection } from "@aspnet/signalr";
import { Router } from "@angular/router";
import { NotificationService } from "./notification.service";
import { EbentoUser } from "../models/users/ebento-user";
import { EbentoMessage } from "../models/messaging/ebento-message";
import { PMReport } from "../models/messaging/pm-report";
import { NotificationType } from "app/helpers/enums/notificationType";
import { EmailSupportRequest } from "../models/messaging/email-support-request";

@Injectable()
export class MessagingService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }

  public messageOpen: boolean;
  //TODO: potentially ensitive data - if possible use just email
  public currentRecepient: EbentoUser;

  _currentDraft: EbentoMessage;

  get currentDraft(): EbentoMessage {
    return this._currentDraft;
  }

  set currentDraft(newDraft: EbentoMessage) {
    this._currentDraft = newDraft;
    this.openDraftChanged.next(newDraft);
  }

  openDraftChanged: Subject<EbentoMessage> = new Subject<EbentoMessage>();

  public inboxMessages: EbentoMessage[];
  public sentMessages: EbentoMessage[];
  public draftMessages: EbentoMessage[];
  public deletedMessages: EbentoMessage[];
  public openMessage: EbentoMessage;
  public searchKeyword = "";

  get getUnreadCount() {
    var unreadCount = 0;
    if (this.inboxMessages == null) return 0;
    for (var i = 0; i < this.inboxMessages.length; i++) {
      if (!this.inboxMessages[i].isRead) unreadCount++;
    }
    return unreadCount;
  }

  get recepientName() {
    if (this.currentRecepient == null) return "Unknown User";
    return (
      this.currentRecepient.firstName + " " + this.currentRecepient.lastName
    );
  }

  get recepientAvatarURL() {
    if (this.currentRecepient == null) return "images/shared/Geneva.jpg";
    return this.currentRecepient.avatarURL;
  }

  public sendCurrentDraft(sender: EbentoUser): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    this.currentDraft.sender = sender;
    this.currentDraft.receiver = this.currentRecepient;
    return this.http
      .post<boolean>(
        "api/messaging/sendmessage",
        JSON.stringify(this.currentDraft),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  //returns messageID, to update current draft with subsequent saves
  public saveCurrentDraft(sender: EbentoUser): Observable<number> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    this.currentDraft.sender = sender;
    this.currentDraft.receiver = this.currentRecepient;
    return this.http
      .post<number>(
        "api/messaging/savedraft",
        JSON.stringify(this.currentDraft),
        httpOptions
      )
      .pipe(
        map((result) => {
          this.currentDraft.idMessage = result;
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public reportPM(managerID: string, reportText: string): Observable<boolean> {
    var report = new PMReport();
    report.managerID = managerID;
    report.reportText = reportText;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post<boolean>(
        "api/messaging/sendpmreport",
        JSON.stringify(report),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public setMessageRead(
    message: EbentoMessage,
    status: boolean
  ): Observable<boolean> {
    message.isRead = status;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post<boolean>(
        "api/messaging/setread/" + message.idMessage,
        JSON.stringify(status),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public setTrash(
    message: EbentoMessage,
    status: boolean
  ): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post<boolean>(
        "api/messaging/settrash/" + message.idMessage,
        JSON.stringify(status),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public fullDelete(message: EbentoMessage): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post<boolean>("api/messaging/delete/" + message.idMessage, httpOptions)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public getInbox(sendToasterForUnread: boolean): Observable<EbentoMessage[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .get<EbentoMessage[]>("api/messaging/getreceived", httpOptions)
      .pipe(
        map((result) => {
          this.inboxMessages = result;
          var unreadCount = this.getUnreadCount;
          if (sendToasterForUnread && unreadCount > 0) {
            var toast = this.notificationService.notify(
              NotificationType.Success,
              "Unread messages! (" + unreadCount + ")",
              "Open Inbox"
            );
            toast.onTap.subscribe(() => {
              this.router.navigate(["/messages/inbox"]);
            });
          }
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public getSent(): Observable<EbentoMessage[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .get<EbentoMessage[]>("api/messaging/getsent", httpOptions)
      .pipe(
        map((result) => {
          this.sentMessages = result;
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public getDrafts(): Observable<EbentoMessage[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .get<EbentoMessage[]>("api/messaging/getdrafts", httpOptions)
      .pipe(
        map((result) => {
          this.draftMessages = result;
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public getDeleted(): Observable<EbentoMessage[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .get<EbentoMessage[]>("api/messaging/gettrashed", httpOptions)
      .pipe(
        map((result) => {
          this.deletedMessages = result;
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public sendEmailSupportMessage(
    email: string,
    messageText: string
  ): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    var messageDTO = new EmailSupportRequest(messageText, email);
    return this.http
      .post<boolean>(
        "api/messaging/email-confirmation-support",
        messageDTO,
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public open(message: EbentoMessage) {
    this.openMessage = message;
    if (!message.isRead && !message.isMine) {
      this.setMessageRead(message, true).subscribe((success) => {});
    }
  }

  public unloadMessages() {
    this.currentDraft = null;
    this.currentRecepient = null;
    this.deletedMessages = null;
    this.draftMessages = null;
    this.inboxMessages = null;
    this.openMessage = null;
    this.sentMessages = null;
  }

  // moved from notification service, as it is toastr only related now. this should be moved to signalrService

  private _hubConnection: HubConnection;
  public userToken: string;

  public startNotifications(_token: string) {
    this.userToken = _token;
    this._hubConnection = new HubConnectionBuilder()
      .withUrl("/notify", { accessTokenFactory: () => this.userToken })
      .build();
    this._hubConnection
      .start()
      .then(() => {}) //console.log('Connection started!'))
      .catch((err) => console.error("Notification Connection Error"));

    this._hubConnection.on(
      "BroadcastMessage",
      (heading: string, text: string) => {
        this.getInbox(false).subscribe();
        var mm = this.notificationService.notify(
          NotificationType.Success,
          text,
          heading
        );
        mm.onTap.subscribe(() => {
          this.router.navigate(["/messages/inbox"]);
        });
      }
    );
  }

  public stopNotifications() {
    if (this._hubConnection != null) this._hubConnection.stop();
  }

  // To send enquiry booking message to service provider
  public sendEnquiryMessage(
    sender: EbentoUser,
    receiver: EbentoUser,
    requestId: string,
    spaceName: string,
    propertyName: string,
    spaceType:string
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    console.log("currentDraft", this.currentDraft);
    this.currentDraft.sender = sender;
    this.currentDraft.receiver = receiver;
    this.currentDraft.attachments = [];
    this.currentDraft.messageText = "Enquiry booking request";
    this.currentDraft.subject = "Enquiry booking request";
    this.currentDraft.requestId = requestId;
    this.currentDraft.spaceName = spaceName;
    this.currentDraft.propertyName = propertyName;
    this.currentDraft.type = `Enquiry ${spaceType}`;
    return this.http
      .post<boolean>(
        "api/messaging/sendmessage",
        JSON.stringify(this.currentDraft),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  //send accepted message to event planner
  public sendEnquiryAcceptedMessage(
    sender: EbentoUser,
    receiver: EbentoUser,
    requestId: number,
    spaceName: string,
    propertyName: string,
    spaceType:string
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    // console.log("currentDraft", message);
    this.currentDraft.sender = sender;
    this.currentDraft.receiver = receiver;
    this.currentDraft.attachments = [];
    this.currentDraft.messageText = "Enquiry booking request";
    this.currentDraft.subject = "Enquiry booking request accepted";
    this.currentDraft.requestId = requestId.toString();
    this.currentDraft.spaceName = spaceName;
    this.currentDraft.propertyName = propertyName;
    this.currentDraft.type = `Enquiry ${spaceType}`;
    return this.http
      .post<boolean>(
        "api/messaging/sendmessage",
        JSON.stringify(this.currentDraft),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }

  public sendEnquiryRejectMessage(
    id: number,
    message: EbentoMessage,
    rejectReason: string,
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    this.currentDraft.sender = message.receiver;
    this.currentDraft.receiver = message.sender;
    this.currentDraft.attachments = [];
    this.currentDraft.messageText = rejectReason;
    this.currentDraft.subject = "Enquiry booking request rejected";
    this.currentDraft.requestId = id.toString();
    this.currentDraft.spaceName = message.spaceName;
    this.currentDraft.propertyName = message.propertyName;
    this.currentDraft.type = `Enquiry reject`;
    return this.http
      .post<boolean>(
        "api/messaging/sendmessage",
        JSON.stringify(this.currentDraft),
        httpOptions
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.errorHandler)
      );
  }
}
