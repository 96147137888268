import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EbentoProperty } from '../../../shared/models/properties/ebento-property';
import { AddEntertainmentService } from '../../../property-manager/services/add-entertainment.service';
import { Entertainment } from '../../models/entertainment/AddEntertainmentStep1';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


@Component({
    selector: 'entertainment-property-list',
    templateUrl: 'entertainment-property-list.component.html',
    styleUrls: ['./entertainment-property-list.component.scss']
})

export class EntertainmentPropertyList implements OnInit{

    @Input() property:EbentoProperty;
    public entertainmentData:Entertainment[];
    @ViewChild('carousel', { read: NgbCarousel }) carousel: NgbCarousel;


    scrHeight: any;
    scrWidth: any;

    constructor(private entertainmentService:AddEntertainmentService,private router:Router){}

    ngOnInit(): void {

        console.log('its working');
        this.getScreenSize();
        this.getEntertainmentData();

    }

    getEntertainmentData(){
        this.entertainmentService.getEntertainmentOfProperty(this.property.id)
            .subscribe((response) => {
                // console.log('entertainments of property',response);
                this.entertainmentData = response;
            })
    }

    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        // console.log(this.scrHeight, this.scrWidth);
    }
    
    public numbersOfNplets(n:number): number[] {
        var non = new Array();
        for (var i = 0; i < Math.ceil(this.entertainmentData.length / n); i++) {
            non.push(i);
        }
        return non;
    }

    public GetNplet(index: number, n:number): Entertainment[] {
        var result: Entertainment[] = new Array();
        var i = 0;
        while (i < n && i + index < this.entertainmentData.length) {
            result.push(this.entertainmentData[i + index]);
            i++;
        }
        return result;
    }

    getColumnCount() {
        return (this.scrWidth >= 1200) ? 4 : (this.scrWidth >= 992) ? 3 : this.scrWidth < 576 ? 1 : 2;
    }


    public stop(evt: Event, workshop: Entertainment) {
        evt.stopImmediatePropagation();
        evt.preventDefault();
        var newValue = !workshop.isActive;
        this.entertainmentService.changeEntertainmentStatus(workshop.entertain_ServiceId, newValue).subscribe(
            (res) => { workshop.isActive = newValue; }
        );
    }

    
    public swipeLeft() {
        this.carousel.next();
    }

    public swipeRight() {
        this.carousel.prev();
    }

    redirect(id:number){
        console.log('redirect entertainment',id);
        this.router.navigate([`add-entertainment-preview/${id}`]);        
    }

}