import { Component, OnInit, Input } from '@angular/core';import { SpaceBooking } from 'app/modules/shared/models/booking/space-booking';
import { TimeAllocation } from 'app/modules/shared/models/data-classes/time-allocation';
import { BookingAmenity } from 'app/modules/shared/models/booking/booking-amenity';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { EbentoSpace } from 'app/modules/shared/models/properties/ebento-space';
import { WorkshopBooking } from 'app/modules/shared/models/booking/workshop-booking';
import { WorkshopService } from 'app/modules/shared/services/workshop.service';
import { WorkshopBookingMenu } from 'app/modules/shared/models/booking/workshop-booking-menu';

@Component({
  selector: 'ws-booking-overview-panel',
  templateUrl: './ws-booking-overview-panel.component.html',
    styleUrls: ['./ws-booking-overview-panel.component.scss']
})
export class WsBookingOverviewPanelComponent implements OnInit {

    @Input() booking: WorkshopBooking;
    @Input() originalPrice: number;
    @Input() showPriceDiff: boolean;
    constructor(public spaceService: SpaceService, public workshopService: WorkshopService)
    {
    }

    ngOnInit() {
        console.log(this.booking);
    }

    // copied from booking-table
    timeAllocationsToText(): string {
        var result = "";

        this.booking.timeAllocations.sort(function (a, b) { var date1 = new Date(a.startTime); var date2 = new Date(b.startTime); if (date1 < date2) return -1; if (date1 > date2) return 1; return 0; });
        for (let allocation of this.booking.timeAllocations) {
            var dateStart = new Date(allocation.startTime);
            var dateEnd = new Date(allocation.endTime);
            result += "<b>" + dateStart.toLocaleDateString() + "</b> <br/> " + "    " + dateStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + " - " + dateEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + " <br/> ";
        }
        return result;
    }

    getMenuName(menuID: number): string {
        return this.workshopService.GetMenuByID(menuID).name;
    }

    getMenuInfo(menu: WorkshopBookingMenu): string {
        return "x " + menu.quantity + ((menu.unitPrice > 0) ? " (CHF " + menu.unitPrice + ")" : " (Free)");
    }

    getAmenityName(amenity: BookingAmenity): string
    {
        return this.spaceService.GetAmenityTypeByID(amenity.amenityTypeID).name;
    }

    getAmenityInfo(amenity: BookingAmenity): string {        
        return "x " + amenity.quantity + ((amenity.unitPrice > 0) ? " (CHF " + amenity.unitPrice+")" : " (Free)");
    }

    public PriceDifference(): string {
        //console.log(">>>");
        //console.log(this.booking.price);
        //console.log(this.originalPrice);
        //console.log("<<");

        var result = Math.round((this.booking.price.totalWithDiscount - this.originalPrice) * 100) / 100;
        var sign = (result < 0) ? "- CHF" : "CHF"
        return sign + " " + Math.abs(result);
    }
}
