import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/modules/shared/services/auth.service";
import { NotificationService } from "app/modules/shared/services/notification.service";
import { NotificationType } from 'app/helpers/enums/notificationType';

@Component({
  selector: "app-edit-email",
  templateUrl: "./edit-email.component.html",
  styleUrls: ["./edit-email.component.css"],
})
export class EditEmailComponent implements OnInit {
  errorMessage: string = "";

  public creds = {
    Email: "",
    ConfirmEmail: "",
    Password: "",
  };

  constructor(
    public authService: AuthService,
    public router: Router,
    public notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(["/index"]);
    }
  }

  cancel() {
    this.router.navigate(['account-settings']);
  }

  validateEmails() {
    if (this.creds.Email != this.creds.ConfirmEmail) {
      this.errorMessage = "Email addresses do not match.";
      return false;
    }
    return true;
  }

  editEmail() {
    if (!this.validateEmails()) return;
    this.errorMessage = "";
    this.authService.changeEmail(this.creds).subscribe(
      (res) => {
        if (this.authService.userType == "EventPlanner") {
          this.router.navigate(["index"]);
        } else if (this.authService.userType == "PropertyManager") {
          this.router.navigate(["/property-manager/properties"]);
        }
        this.notificationService.notify(
          NotificationType.Success,
          "Email for changing email address is sent",
          null
        );
        // this.passEntry.emit();
      },
      (err) => (this.errorMessage = err.error)
    );
  }
}
