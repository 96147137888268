import { Component, OnInit, Input, ViewChild, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { CreditCard } from '../../models/users/credit-card';
import { CustomerService } from '../../services/customer.service';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'credit-card',
    templateUrl: './credit-card.component.html'
})
export class CreditCardComponent implements OnInit {

    @Input() creditCard: CreditCard;
    @Output() isValid: EventEmitter = new EventEmitter();
    @ViewChild('creditCardForm') form; 

    constructor(
        private customerService: CustomerService,
        private notificationService: NotificationService) { }

    ngOnInit() {
        if (!this.creditCard) {
            this.creditCard = new CreditCard();
        }
        if (this.creditCard.ccNumber) {
            this.creditCard.ccNumber = this.creditCard.ccNumber.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
        }
    }

    public ccNumberFormat(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        if (this.creditCard.ccNumber != null)
            this.creditCard.ccNumber = this.creditCard.ccNumber.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
    }

    public ccCVVFormat(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
    }

    validate() {
        let formIsValid = this.form["valid"];
        this.isValid.emit(formIsValid);
    }

    cancel() {

    }
  
    save() {
        
    }
}
