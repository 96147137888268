import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-confirmation-modals',
    templateUrl: './confirmation-modals.component.html'
})
export class ConfirmationModalsComponent implements OnInit {

    @Input() body: string
    @Input() title: string
    @Input() actionName: string = 'Confirm'
    @Output() passEntry = new EventEmitter()
    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {
    }

    public confirm(): void {
        this.passEntry.emit('confirmed');
    }

    public close(): void {
        this.activeModal.close('Close click');
    }

}
