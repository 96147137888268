import { Inject, Injectable, Injector } from "@angular/core";
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { Router } from "@angular/router";
import { NotificationType } from "app/helpers/enums/notificationType";

@Injectable()
export class NotificationService {


    constructor(
        private router: Router,
        private http: HttpClient, 
        @Inject(Injector) private injector: Injector) { 
        }

    // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
    private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    public notify(type: NotificationType, message: string, title?: string, config?): ActiveToast<any>
        {
        if (type == NotificationType.Success)
            return this.toastr.success(message, title, config)
        if (type == NotificationType.Error)
            return this.toastr.error(message, title, config)
        if (type == NotificationType.Info)
            return this.toastr.info(message, title, config)
    }
}