import { Component, OnInit, InjectionToken, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { AdminSpaceService } from 'app/modules/admin/services/admin-space.service';
import { InjectableItem } from '../../index-carousel/injectable-item';
import { CarouselTile } from '../carousel-tile.component';
import { CityTile } from 'app/modules/shared/models/search/city-tile';

@Component({
  selector: 'search-carousel-tile',
    templateUrl: 'search-carousel-tile.component.html',
    styleUrls: ['./search-carousel-tile.component.css']
})
export class SearchCarouselTile extends CarouselTile implements OnInit {

        constructor(injection: InjectableItem, private router: Router, public authService: AuthService, public adminSpaceService: AdminSpaceService)
    {
            super(injection);
        // da li sam realan...2
    }
    
    ngOnInit() {
      }
}
