import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NotificationType } from 'app/helpers/enums/notificationType';
import { NotificationService } from 'app/modules/shared/services/notification.service';
import { AdminReviewService } from '../../services/admin-review.service';
import { faExclamationTriangle, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-admin-review-cell',
    templateUrl: './admin-review-cell.component.html',
    styleUrls: ['./admin-review-cell.component.scss']
})
export class AdminReviewCellComponent implements ICellRendererAngularComp {
    //fa
    faExclamationTriangle = faExclamationTriangle;
    faTrash = faTrash;
    faCheck = faCheck;
    //fa

    public params: any;

    constructor(private router: Router, private notificationService: NotificationService, public adminReviewService: AdminReviewService) { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return true;
    }

    hasReports(): boolean {
        return (this.reportCount() > 0);
    }

    reportCount(): number {
        var count = 0;
        if (this.params.data.reports != null) {
            for (var i = 0; i < this.params.data.reports.length; i++) {
                if (!this.params.data.reports[i].adminResolved)
                    count++;
            }
        }
        return count;
    }

    remove() {
        if (confirm('Are you sure you want to delete this review?'))
            this.adminReviewService.removeReview(this.params.data.reviewID).subscribe(
                (success) => {
                    this.notificationService.notify(NotificationType.Success, "Review succesfully removed.", "Review removed");
                    this.params.context.componentParent.reload();                    
                }
            );
    }

    resolve() {
        if (confirm('Are you sure you want to resolve these reports, and keep the review?'))
            this.adminReviewService.resolveReports(this.params.data.reviewID).subscribe(
                (success) => {
                    this.notificationService.notify(NotificationType.Success, "Reports succesfully resolved.", "Reports resolved");
                    this.params.context.componentParent.reload();
                }
            );
    }

}
