import { Component, OnInit, Input, Injector } from "@angular/core";

import { EbentoProperty } from "../../models/properties/ebento-property";
import { WorkshopService } from "../../services/workshop.service";
import { WorkshopTile } from "../../models/search/workshop-tile";
import { PropertyDetailsSpaceTile } from "../property-details-space-tile/property-details-space-tile.component";
import { IndexCarousel } from "app/modules/event-planner/components/carousel/index-carousel/index-carousel.component";
import { SpaceCarouselTile } from "app/modules/event-planner/components/carousel/carousel-tile/space-carousel-tile/space-carousel-tile.component";
import { SpaceService } from "../../services/space.service";
import { PropertyService } from "../../services/property.service";

@Component({
  selector: "app-property-details-space-card",
  templateUrl: '../../../event-planner/components/carousel/index-carousel/index-carousel.component.html',
  styleUrls: ["./property-details-space-card.component.scss"],
})
export class PropertyDetailsSpaceCardComponent extends IndexCarousel implements OnInit {
  @Input() property: EbentoProperty;
  @Input() headerLabel = "Spaces";
  @Input() width: '75%';
  workshops: WorkshopTile[];
  spaces;
  propertySpaces;

  protected tileComponent = SpaceCarouselTile;

  constructor(
    public spaceService: SpaceService,
    public propertyService: PropertyService,
    public workshopService: WorkshopService,
    injector: Injector
  ) {
    super(injector);
  }

  // ngOnInit() {
  //     this.workshopService.getAllWorkshopForProperty(this.property.id).subscribe(
  //         (result) => { this.workshops = result; });
  // }

  getSpaces() {
    this.spaces = this.property.spaces.filter((s) => !s.isDummyForWorkshops);
    return this.property.spaces.filter((s) => !s.isDummyForWorkshops);

    console.log("spaces", this.property.spaces);
  }

  get apiGetter() {
    // return this.spaceService.getPerfectSpaces();
    // return this.workshopService.getAllWorkshopForProperty(this.property.id);
    // return this.property.spaces.filter(s => !s.isDummyForWorkshops);
    // console.log('spaces', this.property.spaces)
    return this.propertyService.getPropertiesSpaces(this.property.id);
    // return this.propertySpaces.spaces;
  }
}
