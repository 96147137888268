import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'highlight'
})

export class HighlightSearch implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }


    transform(value: any, args: any): any {
        if (!args) { return value; }
        var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
        return this.sanitizer.bypassSecurityTrustHtml(value.replace(new RegExp(args, 'gi'), "<span style='background-color: yellow'>$&</span>"));
        ;
    }
}