import { AmenityType } from "../properties/amenity-type";

export class WorkshopAmenity
{
    constructor(
        public price: number,
        public amenitytype: AmenityType,
        public isoptional: boolean,
        public hasadjustablequantity: boolean,
        public priceperperson: boolean,
        public documenturl: string
    ) 
    { }
}