import { Component, HostListener, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EbentoModalService } from "../../../../services/ebento-modal.service";

@Component({
    selector: 'basic-modal',
    templateUrl: "basic-modal.component.html"
})
export class BasicModal implements OnInit {

    scrHeight: any;
    scrWidth: any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
    }
    @Input() title;

    constructor(
        public modalService: EbentoModalService
    ) { }

    ngOnInit() {
    }
}