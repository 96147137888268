import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { throwError, Observable, Subject } from "rxjs";
import { SpaceTile } from "app/modules/shared/models/properties/space-tile";
import { AuthService } from "app/modules/shared/services/auth.service";
import { WorkshopTile } from "app/modules/shared/models/search/workshop-tile";

@Injectable()
export class FavoritesService {

    public myFavoriteSpaces: number[];
    public myFavoriteWorkshops: number[];

    constructor(
        private http: HttpClient
    ) {}

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }


    // Favorite Spaces

    getFavoriteSpaces(userID: string): Observable<SpaceTile[]> {
         return this.http.get<SpaceTile[]>("api/favorite-spaces/" + userID)
            .pipe(
             map(data => {
                    return data;
                }),
                catchError(this.errorHandler)
            );
    }

    getMyFavoriteSpacesIDs(userID: string): Observable<number[]> {

        return this.http.get<number[]>("api/my-favorite-spaces/" + userID)
            .pipe(
                map(data => {
                this.myFavoriteSpaces = data;
                //this.myFavoriteSpaces.next(data);
                    return data;
                }),
                catchError(this.errorHandler)
            );
    }

    setSpaceFavoriteStatus(newStatus: boolean, spaceID: number): Observable<boolean>
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        var dto = { spaceID: spaceID, newStatus: newStatus };

        return this.http.post<boolean>("api/set-favorite-space-status", JSON.stringify(dto),httpOptions)
            .pipe(
                map(data => {
                if (data)
                {
                    if (newStatus)
                    {
                        if (this.myFavoriteSpaces == null)
                            this.myFavoriteSpaces = [];
                        this.myFavoriteSpaces.push(spaceID);
                        //this.myFavoriteSpaces.next(this._myFavoriteSpaces);
                    }
                    else
                    {
                        this.myFavoriteSpaces.splice(this.myFavoriteSpaces.indexOf(spaceID), 1);
                        //this.myFavoriteSpaces.next(this._myFavoriteSpaces);
                    }
                }
                    return data;
                }),
                catchError(this.errorHandler)
        );
    }

    // Favorite Workshops

    getFavoriteWorkshops(userID: string): Observable<WorkshopTile[]> {
        return this.http.get<WorkshopTile[]>("api/favorite-workshops/" + userID)
           .pipe(
            map(data => {
                   return data;
               }),
               catchError(this.errorHandler)
           );
   }

   getMyFavoriteWorkshopsIDs(userID: string): Observable<number[]> {

       return this.http.get<number[]>("api/my-favorite-workshops/" + userID)
           .pipe(
               map(data => {
               this.myFavoriteWorkshops = data;
               //this.myFavoriteSpaces.next(data);
                   return data;
               }),
               catchError(this.errorHandler)
           );
   }

   setWorkshopFavoriteStatus(newStatus: boolean, workshopID: number): Observable<boolean>
   {
       const httpOptions = {
           headers: new HttpHeaders({
               'Content-Type': 'application/json',
           })
       };

       var dto = { workshopID: workshopID, newStatus: newStatus };

       return this.http.post<boolean>("api/set-favorite-workshop-status", JSON.stringify(dto),httpOptions)
           .pipe(
               map(data => {
               if (data)
               {
                   if (newStatus)
                   {
                       if (this.myFavoriteWorkshops == null)
                           this.myFavoriteWorkshops = [];
                       this.myFavoriteWorkshops.push(workshopID);
                       //this.myFavoriteSpaces.next(this._myFavoriteSpaces);
                   }
                   else
                   {
                       this.myFavoriteWorkshops.splice(this.myFavoriteWorkshops.indexOf(workshopID), 1);
                       //this.myFavoriteSpaces.next(this._myFavoriteSpaces);
                   }
               }
                   return data;
               }),
               catchError(this.errorHandler)
       );
   }
}