import { Component, OnInit, InjectionToken, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/modules/shared/services/auth.service";
import { AdminSpaceService } from "app/modules/admin/services/admin-space.service";
import { InjectableItem } from "../../index-carousel/injectable-item";
import { CarouselTile } from "../carousel-tile.component";
import { SpaceBookingService } from "../../../../../shared/services/space-booking.service";

@Component({
  selector: "space-carousel-tile",
  templateUrl: "space-carousel-tile.component.html",
  styleUrls: ["./space-carousel.tile.component.css"],
})
export class SpaceCarouselTile extends CarouselTile implements OnInit {
  public photoUrl: string;

  constructor(
    injection: InjectableItem,
    private router: Router,
    private bookingService: SpaceBookingService,
    public authService: AuthService,
    public adminSpaceService: AdminSpaceService
  ) {
    super(injection);
    // da li sam realan...2
  }

  ngOnInit() {
    console.log("item", this.item);
    if (this.item.photoUrls?.length) {
      this.photoUrl = `${this.item.photoUrls[0]}`;
    } else if (this.item.photourl) {
      this.photoUrl = `${this.item.photourl}`;
    } else this.photoUrl = "assets/images/cardImage.png";
  }

  public Redirect() {
    if (
      this.authService.isLoggedIn() &&
      this.authService.userType == "EventPlanner"
    ) {
      if (this.item.id) {
        this.bookingService.startNewBooking(this.item.id);
      } else if (this.item.spaceId) {
        this.bookingService.startNewBooking(this.item.spaceId);
      }
    } else {
      if (this.item.id) {
        this.router.navigate(["space-details/" + this.item.id]);
      } else if (this.item.spaceId) {
        this.router.navigate(["space-details/" + this.item.spaceId]);
      }
    }
  }
}
