import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { SpaceService } from "app/modules/shared/services/space.service";

@Component({
    selector: 'clickable-space-name',
    templateUrl: 'clickable-space-name.component.html'
})
export class ClickableSpaceName implements ICellRendererAngularComp {
    public params: any;

    constructor(public spaceService: SpaceService){}

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }
}