import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AdminUserService } from 'app/modules/admin/services/admin-user.service';

@Component({
  selector: 'app-invoice-status-checkbox',
  templateUrl: './invoice-status-checkbox.component.html',
  styleUrls: ['./invoice-status-checkbox.component.scss']
})
export class InvoiceStatusCheckboxComponent implements ICellRendererAngularComp {
    public params: any;
    public submitting: boolean;

    constructor(public adminUserService: AdminUserService) { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    statusChanged(isChecked: boolean) {
        this.submitting = true;
        this.adminUserService.approveInvoice(this.params.data.id, isChecked).subscribe(
            (success) => {
                this.submitting = false;
                if (success) {

                }
                else {
                    console.error("api error");
                    this.params.data.isActive = !isChecked;
                }
            }
        );
    }

}
