import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { WorkshopMenu } from "../../../../shared/models/workshops/workshop-menu";
import { Subject } from "rxjs";
import { UploadAttachment } from "app/modules/shared/modules/upload/upload-attachment/upload-attachment.component";
import { Attachment } from "app/modules/shared/models/messaging/attachment";

@Component({
  selector: "menu-uploader",
  templateUrl: "./menu-uploader.component.html",
  styleUrls: ["./menu-uploader.component.scss"],
})
export class MenuUploaderComponent implements OnInit, OnChanges {
  @Input("existingMenus") existingMenus: WorkshopMenu[] = [];
  @Input("validate") validate: boolean;
  @Output("valid") valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output("menusCollection") menusCollection: EventEmitter<any> =
    new EventEmitter<any>();

  attachmentUploaders: UploadAttachment[];
  menus = [];
  error: string;
  update: Subject<any> = new Subject<any>();
  editing: boolean;

  constructor() {}

  ngOnInit() {
    for (let menu of this.existingMenus) {
      this.menus.push({ menu: menu, saved: true });
    }
  }

  ngOnChanges() {
    if (this.validate == true) {
      this.validateMenus();
    }
  }

  addMenu() {
    if (this.menus.length < 5) {
      if (this.menus.length == 0) {
        this.menus.push({ menu: {} as WorkshopMenu, saved: false });
        this.error = "";
      } else {
        if (this.menus[this.menus.length - 1].saved == false) {
          this.error = "Please save the current menu first";
        }

        if (this.menus[this.menus.length - 1].saved == true) {
          this.error = "";
          this.menus.push({ menu: {} as WorkshopMenu, saved: false });
        }
      }
    }
    this.validateMenus();
  }

  onFileUploaded(attachment, i) {
    this.menus[i].menu.url = attachment.url;
    this.menus[i].menu.filename = attachment.fileReference.name;
    this.menus[i].menu.filesize = attachment.fileReference.size;
  }

  onFileRemoved(i) {
    this.menus[i].menu.url = "";
    this.menus[i].saved = false;
    this.validateMenus();
  }

  saveMenu(i) {
    console.log('getting called');
    
    this.menus[i].saved = true;
    this.error = "";
    this.update.next({ index: i, saved: true });
    if (this.menus.length > 0) {
      this.editing = false;
    }
    console.log('save menu',this.menus);
    
    this.validateMenus();
  }

  getPreloadedAttachment(menu: WorkshopMenu): Attachment {
    if (
      this.existingMenus != null &&
      menu != null &&
      this.existingMenus.indexOf(menu) >= 0
    ) {
      var attachment = new Attachment();
      attachment.fileReference = { name: menu.filename, size: menu.filesize };
      attachment.uploadProgress = 100;
      return attachment;
    }
    return null;
  }

  removeMenu(i) {
    this.error = "";
    this.menus.splice(i, 1);

    if (this.menus.length > 0) {
      this.editing = false;
    }

    // this.validateMenus();
  }

  editMenu(i) {
    this.menus[i].saved = false;
    this.update.next({ index: i, saved: false });
    this.editing = true;
    // this.validateMenus();
  }

  validateMenus() {
    if (this.menus.length > 0) {
      const isValid = this.menus.every((item) => item.saved);
      this.valid.emit(isValid);
      this.collectMenus(isValid);
    }

    if (this.menus.length === 0) {
      this.valid.emit(true);
    }
  }

  collectMenus(isValid) {
    if (isValid === true) {
      const savedMenus = [];
      this.menus.forEach((menu) => {
        savedMenus.push(menu.menu);
      });
      this.menusCollection.emit(savedMenus);
    }
  }
}
