import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BankAccount } from "../../../../models/users/bank-account";
import { AuthService } from '../../../../services/auth.service';
import { CustomerService } from '../../../../services/customer.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationType } from 'app/helpers/enums/notificationType';

@Component({
  selector: 'app-bank-account-modal',
  templateUrl: './bank-account-modal.component.html',
})
export class BankAccountModalComponent implements OnInit {

    @Input() title: string
    @Input() bankAccount: BankAccount
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    errorMessage: string = "";

    constructor(
        public authService: AuthService,
        private customerService: CustomerService,
        private router: Router,
        private notificationService: NotificationService
    ) { }

    ngOnInit() {
        if (!this.bankAccount) {
            this.bankAccount = new BankAccount();
        }
        else {
            let baCopy = Object.assign({}, this.bankAccount)
            this.bankAccount = baCopy;
        }
    }

    cancel() {
        this.passEntry.emit();
    }

    save() {
        this.customerService.createOrUpdateBankAccount(this.bankAccount)
            .subscribe(
                res => {
                    if (res) {
                        this.notificationService.notify(NotificationType.Success, "Bank Account successfully updated");
                        this.passEntry.emit();
                    }
                },
                () => {
                    this.notificationService.notify(NotificationType.Error, "Bank Account update failed");
                })
    }

}
