import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FaqDTO } from '../../../../models/simple-pages/faq-dto';
import { SimplePagesService } from '../../../../services/simple-pages.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    model: FaqDTO;
    textEP: string = '';
    textPM: string = '';
    EPfaq = true;

    constructor(private simplePagesService: SimplePagesService, private route: ActivatedRoute) { }

    ngOnInit() {

        var userType = this.route.snapshot.params.type;
        if (userType == "property-manager")
            this.EPfaq = false;
        this.simplePagesService.getFaqPageData().subscribe(
            res => {
                if (res != null) {
                    this.model = res;
                    this.textEP = this.model.bodyEP;
                    this.textPM = this.model.bodyPM;
                }
                else {
                    this.model = new FaqDTO()
                }
            }
        )
    }

}
