import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  PricingRule,
  AdditionalCost,
} from "../../../../shared/models/entertainment/AddEntertainmentStep1";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddEntertainmentService } from "../../../services/add-entertainment.service";
import { MatStepper } from "@angular/material/stepper";

@Component({
  selector: "add-entertainment-5",
  templateUrl: "./add-entertainment-5.component.html",
  styleUrls: ["./add-entertainment-5.component.css"],
})
export class AddEntertainment5Component implements OnInit {

  public rules: PricingRule[] = [
    {
      frompersons: "5",
      topersons: "10",
      priceperperson: "100",
    },
  ];

  public additionalCosts: AdditionalCost[] = [];
  public addedAdditionalCosts: AdditionalCost[] = [];
  public errors: string = "";

  additionCostForm = new FormGroup({
    name: new FormControl("", Validators.required),
    price: new FormControl("", Validators.required),
    optional: new FormControl(false),
  });

  pricingType: string = "1";
  closeResult = "";
  Enter_priceperEvent: number;

  constructor(
    private modalService: NgbModal,
    private addEntertainment: AddEntertainmentService
  ) {}

  ngOnInit(): void {
    if (this.addEntertainment.editMode) {
      this.preLoadValues();
    }
  }

  preLoadValues() {
    if (this.addEntertainment.entertainmentData.pricingRules.length) {
      this.rules = this.addEntertainment.entertainmentData.pricingRules.map(
        (e) => {
          return {
            frompersons: e.frompersons,
            topersons: e.topersons,
            priceperperson: e.priceperperson,
          };
        }
      );
    }
    this.pricingType = String(
      this.addEntertainment.entertainmentData.pricingType
    );
    console.log(
      "pricingtype, priceperevent",
      this.pricingType,
      this.addEntertainment.entertainmentData.Enter_priceperEvent
    );

    if (this.pricingType === "2") {
      this.Enter_priceperEvent =
        this.addEntertainment.entertainmentData.Enter_priceperEvent;
      this.rules = [];
    }
    if (this.addEntertainment.entertainmentData.additionalCosts.length) {
      this.additionalCosts =
        this.addEntertainment.entertainmentData.additionalCosts;

      this.addedAdditionalCosts =
        this.addEntertainment.entertainmentData.additionalCosts;
    }
  }

  removeRule(i: number) {
    this.rules = this.rules.filter((e, index) => {
      return index !== i;
    });
  }

  addRule() {
    if(this.rules.length){
      const length = this.rules.length;
      const last = this.rules[length - 1];
      this.rules[length] = {
        frompersons: last.topersons,
        topersons: Number(last.topersons) + 5,
        priceperperson: Number(last.priceperperson) + 10,
      };
    }else{
      this.rules[0]={
        frompersons: 5,
        topersons: 10,
        priceperperson:100,
      };
    }
  }

  addAdditionalCost() {
    console.log(this.additionCostForm.value);
    const temp = {
      name: this.additionCostForm.value.name,
      price: Number(this.additionCostForm.value.price),
      optional: this.additionCostForm.value.optional,
    };
    this.additionalCosts.push(temp);
  }

  setAdditionalCost(i: number) {
    if (this.checkAdditionalCost(this.additionalCosts[i].name)) {
      this.addedAdditionalCosts = this.addedAdditionalCosts.filter((e) => {
        return e.name !== this.additionalCosts[i].name;
      });
    } else {
      this.addedAdditionalCosts.push(this.additionalCosts[i]);
    }
  }

  checkAdditionalCost(name: string): boolean {
    return this.addedAdditionalCosts.some((e) => e.name === name);
  }

  // clearInputs(){
  //     this.additionCostForm.setValue({
  //         name:'',
  //         price:'',
  //         optional:false
  //     })
  // }

  setPricingType(typeNum: number) {
    this.pricingType = `${typeNum}`;
    if (typeNum === 2) {
      this.rules = [];
    } else if (typeNum === 1) {
      this.rules = [
        {
          frompersons: "5",
          topersons: "10",
          priceperperson: "100",
        },
      ];
    }
  }

  setPricePerEvent(e: any) {
    this.Enter_priceperEvent = e.target.value;
  }

  setPersonFrom(e: any, i: number) {
    this.rules[i].frompersons = e.target.value;
  }

  setPersonTo(e: any, i: number) {
    this.rules[i].topersons = e.target.value;
  }

  setPricePerperson(e: any, i: number) {
    this.rules[i].priceperperson = e.target.value;
  }

  removeAdditionalCost(i: number) {
    this.additionalCosts = this.additionalCosts.filter((e, index) => {
      return index !== i;
    });
  }

  checkPricingRules(): boolean {
    let valid = true;
    if (!this.rules.length) {
      valid = false;
    } else {
      for (let rule of this.rules) {
        if (!rule.frompersons || !rule.topersons || !rule.priceperperson) {
          valid = false;
          break;
        } else {
          if (
            Number(rule.frompersons) > Number(rule.topersons) ||
            rule.frompersons === rule.topersons
          ) {
            valid = false;
            break;
          } else {
            continue;
          }
        }
      }
    }
    return valid;
  }

  onSubmit() {
    if (!this.pricingType) {
      this.errors = "please choose a pricing type";
    } else {
      if (this.pricingType === "1" && !this.checkPricingRules()) {
        this.errors = `Please fill the Pricing Rules correctly
          Person from should be less than Person to and fill Price per person
        `;
      } else if (this.pricingType === "2" && !this.Enter_priceperEvent) {
        this.errors = `Please Enter Price per event`;
      } else {
        if (this.addEntertainment.editMode) {
          if (this.pricingType === "1") {
            this.addEntertainment.updatePricingRules(
              this.rules,
              this.pricingType
            );
          } else {
            this.addEntertainment.updatePricePerEvent(
              String(this.Enter_priceperEvent)
            );
          }
          this.addEntertainment.updateAdditionalCosts(
            this.addedAdditionalCosts
          );
        } else {
          this.addEntertainment.setAdditionalCosts(this.addedAdditionalCosts);
          this.addEntertainment.pricingType = this.pricingType;
          if (this.pricingType === "1") {
            this.addEntertainment.setPricingRules(this.rules);
          } else {
            this.addEntertainment.updatePricePerEvent(
              String(this.Enter_priceperEvent)
            );
          }
        }
        
        this.addEntertainment.stepperNext.next();

      }
    }
  }

  //====== Modal settings ========
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
