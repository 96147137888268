import { Location } from "@angular/common";
import { ComponentFactoryResolver, Injectable, Injector } from "@angular/core";
import { NgbModal, NgbModalConfig, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgbModalStack } from "@ng-bootstrap/ng-bootstrap/modal/modal-stack";

@Injectable()
export class EbentoModalService {
    public activeModal: NgbModalRef;
    constructor(private modalService: NgbModal, private location: Location)
    {

    }
    public close() {
        this.activeModal.close();
        history.back();
    }
    public open(arg1, options?: NgbModalOptions): NgbModalRef
    {
        //
        history.pushState(null, 'modalOpened');
        var backdropIgnore: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        var mergedOptions = { ...options, ...backdropIgnore };
        this.activeModal = this.modalService.open(arg1, mergedOptions);
        this.location.subscribe(() => this.activeModal.close());
        return this.activeModal;
       
    }
}