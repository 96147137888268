import { Component, Input, OnInit } from '@angular/core';
import { Item } from "../../../shared/models/cart/cartItem";
import { Router } from '@angular/router';


@Component({
  selector: 'booked-item',
  templateUrl: './booked-item.component.html',
  styleUrls: ['./booked-item.component.css']
})
export class CartBookedItemComponent implements OnInit {

  @Input() isCart: boolean = false;
  @Input() data: Item;

  constructor(
    public router: Router, 
  ){}

  ngOnInit(): void {
    console.log('isCart success',!this.isCart)
  }

  ViewSpace(){
    if(this.data.spaceType === 'Space'){
      this.router.navigate(['space-details/' + this.data.serviceId]);
    }else if(this.data.spaceType === 'Experience'){
      this.router.navigate(['experience-details/' + this.data.serviceId ]);
    }else{
      this.router.navigate(['entertainment-details/'+this.data.serviceId])
    }
  }

  SendMessage(){
    this.router.navigate(['messages/inbox']);
  }

}
