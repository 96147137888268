import { Component, OnInit , ElementRef ,ViewChild , AfterViewInit} from '@angular/core';
import { Router } from "@angular/router";
import { SpaceService } from 'app/modules/shared/services/space.service';
import { AuthService } from '../../../shared/services/auth.service';
import { AddEntertainmentService } from '../../../property-manager/services/add-entertainment.service';
import { Category } from '../../../shared/models/entertainment/categories';

@Component({
  selector: 'index-planner',
  templateUrl: 'index.component.html',
  styleUrls:['index.component.css']
})
    
export class IndexPagePlanner implements OnInit
{   

    public categoryData:Category[];
    @ViewChild('icon1') icon1:ElementRef;

    mouseOver1(){
        console.log('mouse over working');
        
        // this.icon1.nativeElement.style.display='block';
    }

    mouseLeave1(){
        console.log('mouse leave working');
        // this.icon1.nativeElement.style.display = 'none';
    }

    
    constructor(public authService: AuthService, public spaceService: SpaceService, public router: Router,private entertainmentService:AddEntertainmentService) {
    }
    
    ngOnInit()
    {

        // if(!this.authService.isLoggedIn())  {
        //     this.router.navigate(['landing'])
        // }
        
        if (this.authService.user && !this.authService.user.isActive) {
            let redirectRoute = "complete-registration-" + this.authService.userType.toLowerCase()
            this.router.navigate([redirectRoute, this.authService.user.email]);
        }
        this.entertainmentService.getCatogories().subscribe((response) => {
            console.log('entertainment categories',response);
            this.categoryData = response;
        })
    }

   
    
}
