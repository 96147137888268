import { Component, OnInit, Input, OnChanges, SimpleChanges, forwardRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tag-selector',
    templateUrl: './tag-selector.component.html'
})
export class TagSelectorComponent implements OnInit, OnChanges  {

    @Input() startOptions;
    @Input() enableAdd: boolean = true;
    @Input() itemsModel: any;
    @Output() itemsModelChange = new EventEmitter<any>();
    @ViewChild('mainDiv') mainDiv;

    selectedCompanies;
    options = [];// [{ id: "1", name: "Motovilo" }, { id: "2", name: "QublajKan" }, { id: "3", name: "Xenџа" }]
    tags: any;

    ngOnInit()
    {
        this.initOptions();
    }

    ngOnChanges(changes: SimpleChanges)
    {   
        this.initOptions();        
        this.tags = this.itemsModel;
    }

    initOptions()
    {
        //console.log("Initinin options;")
        if (this.startOptions != null) {
            this.options = this.startOptions;
        }
    }

    addTagFn(name) {
        return { id: -1, name: name };
    }
    
    propagate(value: any)
    {
        //console.log("JARANE !" + this.teslica);
        this.itemsModelChange.emit(this.tags);
        //this.propagateChange(this.value);
    }

    //helper for admin tags.... 
    getPixelHeight()
    {
        return this.mainDiv.nativeElement.offsetHeight;
    }
}
