import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'app/modules/shared/services/notification.service';
import { ReferralService } from 'app/modules/shared/services/referral.service';
import { NotificationType } from 'app/helpers/enums/notificationType';

@Component({
    selector: 'app-pm-referral',
    templateUrl: './pm-referral.component.html',
    styleUrls: ['./pm-referral.component.scss']
})
export class PmReferralComponent implements OnInit {

    constructor(private router: Router, private notificationService: NotificationService, private route: ActivatedRoute, private referralService: ReferralService) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            var referralID = params['id'];
            var email = params['email'];
            if (referralID != null && email != null) {
                this.referralService.checkID(referralID, email).subscribe(
                    (success) => {
                        if (success) {
                            this.notificationService.notify(NotificationType.Success, "Welcome to Ebento! Thank you for following your friend's referral", "Welcome");
                            this.router.navigate(['register-common/PropertyManager/' + email + '/' + referralID]);
                        }
                        else {
                            this.notificationService.notify(NotificationType.Error, "Referral link not usable. Please contact Ebento support team.", "Error");
                            this.router.navigate(['index']);
                        }
                    }
                );
            }
        });
    }

}
