export class Invoice {

    public firstName: string
    public lastName: string
    public companyName: string
    public country: string
    public canton: string
    public city: string
    public postalCode: string
    public street: string
    public streetNumber: string
    public invoiceApproved: boolean

    constructor() { }
}