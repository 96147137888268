import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Category } from "../../shared/models/entertainment/categories";
import { EntertainmentTypes } from "../../shared/models/entertainment/entertainmentTypes";
import {
  AddEntertainmentStep1,
  TimeSlot,
  UnavailableTimeSlot,
  PricingRule,
  EditCancellationRule,
  AdditionalCost,
  Entertainment,
  updateEntertainment,
  MediaUrls,
  MediaType,
  SearchProps,
} from "../../shared/models/entertainment/AddEntertainmentStep1";
import { map, catchError } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class AddEntertainmentService {
  public propertyId: string;
  public serviceId: string;
  public pricingRules: PricingRule[];
  public cacellationRules: EditCancellationRule[];
  public additionalCosts: AdditionalCost[];
  public Enter_priceperEvent: string;
  public pricingType: string;
  public editMode: boolean = false;
  public entertainmentData: any;
  public updatedData: updateEntertainment = {
    entertain_ServiceId: 0,
    Entertain_ServiceName: "",
    isActive: true,
    Enter_CatID: "",
    EntertainTypeId: [],
    Enter_Desription: "",
    Enter_LocationName: "",
    Enter_DistanceID: "",
    BookingType: "",
    Enter_priceperEvent: null,
    Enter_CovidMeasures: "",
    documentUrl: [],
    PricingType: "",
    pricingRules: [],
    additionalCosts: [],
    EcancellationRules: [],
    availableTimeslots: [],
  };

  public selectedIndex: number = 0;
  public mediaUrls: MediaUrls[] = [];
  public searchResult: Entertainment[];
  public custom = {
    type: null,
  };

  public timeAllocationDTO: any;
  public searchCategory: number;
  public enquiryBookingOptions: any;

  public bookingOptions: any = {
    attendees: 0,
    paymentType: {},
    customMessage: "",
    uniqueCode: "",
    paymentIntentID: "",
    optionalAmenities: [],
  };
  public bookingTimeslots: any;
  public formatedDates: [];
  public stepperNext:any;

  constructor(private _http: HttpClient) {}

  getCatogories(): Observable<Category[]> {
    return this._http.get<Category[]>(
      "API/Entertainment/GetEntertainCategories"
    );
  }

  getEntertainmentTypes(id: string | number): Observable<EntertainmentTypes[]> {
    return this._http.get<EntertainmentTypes[]>(
      `API/Entertainment/GetCategorywiseEntertainmentType?categoryId=${id}`
    );
  }

  getEntertainmentACategory(catId: number) {
    return this._http.get<Entertainment[]>(
      `API/Entertainment/GetEntertainServicesbyCategory?categoryId=${catId}`
    );
  }

  getEntertainmentOfProperty(id: number): Observable<Entertainment[]> {
    return this._http.get<Entertainment[]>(
      `API/Entertainment/GetEntertainServiceDetailsByProperty?PropertyID=${id}`
    );
  }

  uploadImages(images: any[]) {
    const formData = new FormData();
    for (let i = 0; images.length > i; i++) {
      formData.append(images[i].name, images[i]);
    }
    const headers = new HttpHeaders()
      // .set('Content-Type','multipart/form-data')
      .set("Authorization", `Bearer ${localStorage.getItem("id_token")}`);
    return this._http.post<string[]>("api/upload/images", formData, {
      headers: headers,
    });
  }

  uploadMultiMedia(media: any) {
    const formData = new FormData();
    formData.append("media", media);
    const headers = new HttpHeaders()
      // .set('Content-Type','multipart/form-data')
      .set("Authorization", `Bearer ${localStorage.getItem("id_token")}`);
    return this._http.post<string>("api/upload/document", formData, {
      headers: headers,
    });
  }

  addMedia(id: any) {
    console.log("media urls", this.mediaUrls);
    return this._http.post<number>("API/Entertainment/uplodMediaDocuments", {
      Entertain_ServiceId: String(id),
      documentUrl: this.mediaUrls,
    });
  }

  getMedia(id: any): Observable<MediaType[]> {
    return this._http.get<MediaType[]>(
      `API/Entertainment/getMediaDocumentsByService/${this.serviceId}`
    );
  }

  createEntertainment(data: AddEntertainmentStep1): Observable<number> {
    console.log("custom type", this.custom);

    let createData = {
      ...data,
      PropertyIDEbentoProperty: Number(this.propertyId),
      EntertainTypeName:
        this.custom.type !== null
          ? this.custom.type.length > 0
            ? this.custom.type
            : null
          : null,
    };

    console.log("custom type", this.custom);

    return this._http.post<number>(
      "API/Entertainment/CreateEntertainment",
      createData
    );
  }

  addAvailableTime(
    data: TimeSlot[],
    unavailableData: any[]
  ): Observable<number> {
    unavailableData = unavailableData.filter((element: UnavailableTimeSlot) => {
      return (
        element.startTime !== "Invalid Date" ||
        element.endTime !== "Invalid Date"
      );
    });

    console.log("unavailable data before processing", unavailableData);
    console.log("available data", data);

    let data2 = unavailableData.map((e) => {
      return {
        Enter_DayName: "",
        Enter_FromTime: this.returnFormattedDate(e.startTime),
        Enter_ToTime: this.returnFormattedDate(e.endTime),
        allocationType: String(e.allocationType),
      };
    });

    const newData = data.map((e) => {
      return {
        Enter_DayName: e.Enter_DayName,
        Enter_FromTime: this.formatLocaledate(e.Enter_FromTime.toLocaleString()),
        Enter_ToTime: this.formatLocaledate(e.Enter_ToTime.toLocaleString()),
        allocationType: e.allocationType,
      };
    });

    return this._http.post<number>(
      "API/Entertainment/SaveEntertainmentTimeSlots",
      {
        Entertain_ServiceId: this.serviceId,
        availableTimeslots: [...newData, ...data2],
      }
    );
  }

  formatLocaledate(d:string):string{
    let [date,time] = d.split(', ');
    const [dd,mm,yy] = date.split('/');
    return `${mm}/${dd}/${yy}, ${time}`;
  }

  returnFormattedDate(dateIn: string): string {
    const [date, time] = dateIn.split(", ");
    console.log("date and time format", date, time);
    return `${date} ${time}`;
  }

  addUnavailableTime(data: UnavailableTimeSlot[]): Observable<number> {
    data = data.filter((element: UnavailableTimeSlot) => {
      return (
        element.startTime !== "Invalid Date" ||
        element.endTime !== "Invalid Date"
      );
    });
    data = data.filter((element: UnavailableTimeSlot) => {
      return {
        Enter_DayName: "",
        Enter_FromTime: element.startTime,
        Enter_ToTime: element.endTime,
        allocationType: element.allocationType,
      };
    });
    return this._http.post<number>(
      "API/Entertainment/SaveEntertainmentTimeSlots",
      {
        Entertain_ServiceId: this.serviceId,
        availableTimeslots: data,
      }
    );
  }

  addRules(): Observable<number> {
    let data: any;
    if (this.pricingType === "1") {
      data = {
        Entertain_ServiceId: this.serviceId,
        PricingType: this.pricingType,
        pricingRules: this.pricingRules,
        AdditionalCosts: this.additionalCosts,
        EcancellationRules: this.cacellationRules,
      };
    } else {
      data = {
        Entertain_ServiceId: this.serviceId,
        PricingType: this.pricingType,
        Enter_priceperEvent: this.Enter_priceperEvent,
        AdditionalCosts: this.additionalCosts,
        EcancellationRules: this.cacellationRules,
      };
    }
    return this._http.post<number>(
      "API/Entertainment/SaveEntertainPriceandCancelRules",
      data
    );
  }

  getPerfectEntertainments(): Observable<Entertainment[]> {
    return this._http.get<Entertainment[]>(
      "API/Entertainment/PerfectEntertainments"
    );
  }

  getUniqueEntertainments(): Observable<Entertainment[]> {
    return this._http.get<Entertainment[]>(
      "API/Entertainment/uniqueEntertainments"
    );
  }

  getNearbyEntertainments(data: any): Observable<Entertainment[]> {
    // console.log("nearby entertainment", data);
    let latitude = 0;
    let longitude = 0;
    if(data){
      latitude = data.latitude;
      longitude = data.longitude;
    }
    return this._http.post<Entertainment[]>(
      "API/Entertainment/GetNearbyEntertainments",
      {
        latitude: latitude,
        longitude: longitude,
      }
    );
  }

  getEntertainmentForEverybody(): Observable<Entertainment[]> {
    return this._http.get<Entertainment[]>(
      "API/Entertainment/GetEntertainServices"
    );
  }

  getAllBookedDates(type: number): Observable<any[]> {
    return this._http.get<any[]>(
      `API/booking/GetAllBookedDatesByPM?id=092e3ae7-7c12-4875-afa3-e79d215fc231&type=${type}`
    );
  }

  searchEntertainment(data: any): Observable<Entertainment[]> {
    return this._http.post<Entertainment[]>(
      "API/Entertainment/GetEntertainmentsbySearch",
      data
    );
  }

  updateEntertainment(): Observable<any> {
    console.log("updatedData", this.updatedData, this.custom);
    return this._http.post<any>(
      "API/Entertainment/updateEntertainmentServices",
      {
        ...this.updatedData,
        PropertyIDEbentoProperty: Number(this.propertyId),
        EntertainTypeName:
          this.custom.type !== null
            ? this.custom.type.length > 0
              ? this.custom.type
              : null
            : null,
      }
    );
  }

  setPricingRules(data: PricingRule[]) {
    this.pricingRules = data;
  }

  updatePricingRules(data: PricingRule[], type: string) {
    this.updatedData.PricingType = type;
    this.updatedData.pricingRules = data;
  }

  setCancellationRules(data: EditCancellationRule[]) {
    this.cacellationRules = data;
  }

  updateCancellationRules(data: EditCancellationRule[]) {
    this.updatedData.EcancellationRules = data;
  }

  setAdditionalCosts(data: AdditionalCost[]) {
    this.additionalCosts = data;
  }

  updateAdditionalCosts(data: AdditionalCost[]) {
    this.updatedData.additionalCosts = data;
  }

  updatePricePerEvent(price: string) {
    if (this.editMode) {
      this.updatedData.PricingType = "2";
      this.updatedData.Enter_priceperEvent = Number(price);
    }
    this.Enter_priceperEvent = price;
  }

  updateFirstThreeSteps(data: AddEntertainmentStep1) {
    this.updatedData.entertain_ServiceId = Number(this.serviceId);
    this.updatedData.Entertain_ServiceName = data.Entertain_ServiceName;
    this.updatedData.Enter_CatID = data.Enter_CatID;
    this.updatedData.EntertainTypeId = data.EntertainTypeId;
    this.updatedData.Enter_Desription = data.Enter_Desription;
    this.updatedData.Enter_LocationName = data.Enter_LocationName;
    this.updatedData.Enter_DistanceID = data.Enter_DistanceID;
    this.updatedData.BookingType = data.BookingType;
    this.updatedData.Enter_CovidMeasures = data.Enter_CovidMeasures;
    if (data.documentUrl.length) {
      console.log("if any documentUrl", data.documentUrl);
      this.updatedData.documentUrl = data.documentUrl;
    } else {
      const urls = this.entertainmentData.documentUrl.map((e) => {
        return {
          Enter_DocumentURL: e.enter_DocumentURL,
        };
      });
      this.updatedData.documentUrl = urls;
    }
  }

  updateAvailablTimeSlots(data: TimeSlot[], unavailableData: any[]) {
    unavailableData = unavailableData.filter((element: UnavailableTimeSlot) => {
      return (
        element.startTime !== "Invalid Date" ||
        element.endTime !== "Invalid Date"
      );
    });

    console.log("unavailable data before processing", unavailableData);
    console.log("available data", data);

    let data2 = unavailableData.map((e) => {
      return {
        Enter_DayName: "",
        Enter_FromTime: this.returnFormattedDate(e.startTime),
        Enter_ToTime: this.returnFormattedDate(e.endTime),
        allocationType: String(e.allocationType),
      };
    });

    const newData = data.map((e) => {
      return {
        Enter_DayName: e.Enter_DayName,
        Enter_FromTime: this.formatLocaledate(e.Enter_FromTime.toLocaleString()),
        Enter_ToTime: this.formatLocaledate(e.Enter_ToTime.toLocaleString()),
        allocationType: e.allocationType,
      };
    });
    this.updatedData.availableTimeslots = [...newData, ...data2];
  }

  getPreviewData(id: string): Observable<any[]> {
    return this._http.get<Entertainment[]>(
      `api/Entertainment/GetEntertainServiceDetailsByService?EServiceID=${id}`
    );
  }

  removeEntertainment(id: string): Observable<any> {
    return this._http.post(
      `api/Entertainment/removeEntertainmentService?ServiceId=${id}`,
      { message: "remove entertainment" }
    );
  }

  publishEntertainment(id: string): Observable<any> {
    return this._http.post(
      `API/Entertainment/activeEntementService?Entertain_ServiceId=${id}&status=true`,
      {
        Entertain_ServiceId: id,
        Status: true,
      }
    );
  }

  changeEntertainmentStatus(id: number, status: boolean): Observable<any> {
    return this._http.post(
      `API/Entertainment/activeEntementService?Entertain_ServiceId=${id}&status=${status}`,
      {
        Entertain_ServiceId: id,
        Status: status,
      }
    );
  }

  errorHandler(error: HttpErrorResponse) {
    console.log(error);
    // return throwError(error.message || "Server error");
  }

  createBooking() {
    console.log("entertainment data", this.entertainmentData);

    let data = {
      entertainmentID: this.entertainmentData.Entertain_ServiceId,
      attendees: this.bookingOptions.attendees,
      timeAllocationDTO: this.timeAllocationDTO[0],
      totalPrice: this.TotalPrice(false),
      optionalAmenities: this.bookingOptions.optionalAmenities,
      message: this.bookingOptions.customMessage,
      CouponDTO: [
        {
          uniqueCode: this.bookingOptions.uniqueCode,
        },
      ],
      currency: "RS",
      paymentType: {
        name: this.bookingOptions.paymentType.name,
      },
      paymentIntentID: this.bookingOptions.paymentIntentID,
    };
    return this._http.post("API/BookEntertainment/bookentertainment", data);
  }

  returnTime(time: string): string {
    const [month, day, year] = time.split("/");
    const date = new Date(
      Number(year),
      Number(month),
      Number(day),
      0,
      0
    ).toISOString();
    console.log("return time date", date);
    const [onlyDate, ...rest] = String(date).split("T");
    console.log("return time", onlyDate);
    return onlyDate;
  }

  public enableButton() {
    let pricetest = false;
    let slottest = false;
    if (this.entertainmentData.PricingType == "1") {
      for (let e of this.entertainmentData.pricingRules) {
        if (
          e.frompersons <= this.bookingOptions.attendees &&
          e.topersons >= this.bookingOptions.attendees
        ) {
          pricetest = true;
          break;
        }
      }
    } else if (this.bookingOptions.attendees > 0) {
      pricetest = true;
    }
    if (this.bookingTimeslots !== null) {
      slottest = true;
    }

    if (pricetest && slottest) {
      return false;
    } else {
      return true;
    }
  }

  public VenuePrice(): number {
    var totalHoursForDay = 0;
    var venuePrice = 0;
    let pricePerHour = 0;
    let totalTime = 0;
    
    const type = this.entertainmentData.PricingType || this.entertainmentData.pricingType;

    if (type == 1) {
      this.entertainmentData.pricingRules.forEach((e) => {
        if (
          e.frompersons <= this.bookingOptions.attendees &&
          e.topersons >= this.bookingOptions.attendees
        ) {
          pricePerHour = e.priceperperson;
        }
      });
      let totalPrice = pricePerHour * this.bookingOptions.attendees;

      if (this.bookingTimeslots) {
        totalTime = this.bookingTimeslots.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.duration.totalseconds,
          0
        );
        totalTime = totalTime / 3600;
      }

      venuePrice = totalPrice * totalTime;
    } else if (type == 2) {
      venuePrice = this.entertainmentData.Enter_priceperEvent;
    }
    return venuePrice;
  }

  public EbentoFee(): number {
    // 5 % fee
    var basePrice = this.VenuePrice();
    return basePrice * 0.05;
  }

  public CalculateOptionalPrice(): number {
    let optionalPrice = 0;
    if (this.bookingOptions.optionalAmenities.length) {
      this.bookingOptions.optionalAmenities.map((e) => {
        optionalPrice += e.price * e.quantity;
      });
    }
    return optionalPrice;
  }

  public TotalPrice(applyDiscount: boolean): number {
    var totalPrice =
      this.VenuePrice() + this.EbentoFee() + this.CalculateOptionalPrice();

    if (this.bookingOptions.coupon != null && applyDiscount)
      totalPrice *=
        (100.0 - this.bookingOptions.coupon.discountPercentage) / 100.0;
    return totalPrice;
  }

  // formatTime(dates: []) {
  //   const format = [];
  //   dates.forEach((d: any) => {
  //     try {
  //       var new_d = d.startTime.split(" ")[0].split("-");
  //       var time = d.startTime.split(" ")[1];
  //       var hour =  time.split(":");
  //       console.log("hour", hour);
  //       if(Number(hour[0]) > 12) {
  //         time.concat(' AM')
  //       } else {
  //         time.concat(' PM')
  //       }

  //       var new_d_1 = d.endTime.split(" ")[0].split("-");
  //       var time_1 = d.endTime.split(" ")[1];
  //       var hour =  time_1.split(":");
  //       if(hour[0] > 12) {
  //         time_1.concat('AM')
  //       } else {
  //         time_1.concat('PM')
  //       }

  //       new_d = `${new_d[1]}/${new_d[2]}/${new_d[0]}`;
  //       new_d_1 = `${new_d_1[1]}/${new_d_1[2]}/${new_d_1[0]}`;

  //       format.push({ "startTime": `${new_d} ${time}`, "endTime": `${new_d_1} ${time_1}`, "allocationType": d.allocationType })
  //       // this.formatedDates.push({ startTime: `${new_d} ${time}`,  });
  //     } catch (err) {
  //       console.log("eror",err);
  //     }
  //   })
  //   console.log("formatedTime", format);
  //   return format;
  // }

  // padTo2Digits(num) {
  //   return num.toString().padStart(2, '0');
  // }

  // formatDate(date: []) {
  //   date.forEach((d: any) => {
  //     console.log("d", d);
  //     return (
  //       [
  //         this.padTo2Digits(d.getMonth() + 1),
  //         this.padTo2Digits(d.getDate()),
  //         d.getFullYear(),
  //       ].join('/') +
  //       ' ' +
  //       [
  //         this.padTo2Digits(d.getHours()),
  //         this.padTo2Digits(d.getMinutes()),
  //         this.padTo2Digits(d.getSeconds()),
  //       ].join(':')
  //     );
  //   })
  // }

  public sendEnquiryRequest(
    {
      descriptionofEvent,
      SpecificRequirements,
      descriptionOfEventspace,
      location,
    },
    spaceType: string
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    this.enquiryBookingOptions = {
      serviceid: this.serviceId,
      requesttype: spaceType,
      attendees: this.bookingOptions.attendees,
      timeAllocationDTOs: this.timeAllocationDTO,
      totalPrice: this.TotalPrice(false),
      message: this.bookingOptions.customMessage,
      currency: "RS",
      venueHire: this.VenuePrice(),
      optionalAmenities: this.bookingOptions.optionalAmenities,
      descriptionofEvent,
      SpecificRequirements,
      descriptionOfEventspace,
      location,
    };

    return this._http
      .post("api/SendBookingRequest", this.enquiryBookingOptions, httpOptions)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  public getEnquiryBookingDetails(id: any, spaceType: string) {
    return this._http.get(
      `api/GetBookingData?requestId=${id}&requestType=${spaceType}`
    );
  }

  public acceptBooking(id: any) {
    const data = {
      requestId: id,
      requestStatus: "accepted",
    };

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this._http.post(`api/AcceptBooking`, data, httpOptions);
  }

  public rejectBooking(id: any, text: string) {
    const data = {
      requestId: id,
      requestStatus: "rejected",
      rejectReason: text,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this._http.post(`api/RejectBooking`, data, httpOptions);
  }
}
