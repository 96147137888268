import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { StripeService } from 'ngx-stripe';
import { HttpClient } from '@angular/common/http';
import { inherits } from 'util';

import { AuthService } from 'app/modules/shared/services/auth.service';
import { SpaceBookingService } from 'app/modules/shared/services/space-booking.service';
import { NotificationService } from 'app/modules/shared/services/notification.service';
import { NotificationType } from 'app/helpers/enums/notificationType';
import { EventPlanner } from 'app/modules/shared/models/users/event-planner';
import { InvoiceModalComponent } from 'app/modules/shared/modules/modals/components/invoice-modal/invoice-modal.component';
import { EbentoModalService } from '../../../shared/services/ebento-modal.service';
import { PaymentService } from '../../services/payment.service';
import { CreatePaymentIntent } from '../../../shared/models/payment/create-payment-intent';
import { UpdatePaymentIntent } from '../../../shared/models/payment/update-payment-intent';
import { SubmissionService } from '../../../shared/services/submission.service';
import { PaymentComponent } from '../payment/payment.component';
import { AddEntertainmentService } from '../../../property-manager/services/add-entertainment.service';

@Component({
    selector: 'entertainment-booking',
    templateUrl: 'entertainment-booking.component.html',
    styleUrls: ['./entertainment-booking.component.css']
})


export class EntertainmentBooking extends PaymentComponent implements OnInit {

    selectedTab: string = 'credit';
    couponCode: string
    email: string
    couponStatus = 'none'
    isSentInvoice: boolean
    eventPlanner: EventPlanner;
    submissionInProgress: boolean = false

    constructor(
        public submissionService: SubmissionService,
        public authService: AuthService,
        public bookingService: SpaceBookingService,
        public entertainmentService:AddEntertainmentService,
        router: Router,
        notificationService: NotificationService,
        paymentService: PaymentService,
        fb: FormBuilder,
        stripeService: StripeService,
        http: HttpClient,
        private modalService: EbentoModalService) {
        super(submissionService, fb, stripeService, bookingService, authService, notificationService, paymentService, router, http)
        this.getScreenSize();
    }

    ngOnInit(): void {
        console.log("entertainment data booking page",this.entertainmentService.entertainmentData);
        
        
        this.email = this.authService.user.email
        this.getPlanner();
        this.paymentService.createPaymentIntent(new CreatePaymentIntent(this.entertainmentService.TotalPrice(true), this.authService.user.id)).subscribe();
        // this.bookingService.getBookingPricing(this.bookingService.bookingOptions).subscribe(
        //     res => {
        //         if (Math.abs(res.totalWithDiscount - this.bookingService.TotalPrice(true)) < 2) {
        //         }
        //         else {
        //             this.notificationService.notify(NotificationType.Error, "Pricing error. Please try again or contact Ebento.", "Pricing Error");
        //         }
        //     });

    }

    selectTab(tab: any) {
        console.log("tabvalue:",tab)
        this.selectedTab = tab;
    }

    checkCouponCode()
    {
        this.couponStatus = 'loading';
        if (this.couponCode == "")
        {
            this.couponStatus = 'none';
        }
        else
        {
            this.entertainmentService.bookingOptions.couponCode = this.couponCode;
            this.bookingService.checkCoupon(this.couponCode, this.entertainmentService.entertainmentData.entertain_ServiceId).subscribe(coupon => {
            if (coupon != null) {
                this.bookingService.bookingOptions.coupon = coupon;
                this.couponStatus = 'valid';
                     }
            else {
                this.bookingService.bookingOptions.coupon = null;
                this.couponStatus = 'invalid';
                }

                this.paymentService.updatePaymentIntent(new UpdatePaymentIntent(this.bookingService.TotalPrice(true), this.paymentService.paymentIntentID)).subscribe();
            });
        }
    }
    payByInvoice() {
        this.submissionService.startSubmission();
        this.submissionInProgress = true;
        this.entertainmentService.bookingOptions.paymentType.name = "invoice";

        this.entertainmentService.createBooking().subscribe(
            (res) => {
                this.submissionService.endSubmission();
                this.submissionInProgress = false;
                console.log("$$$$$" + res);
                if (res) {
                    this.notificationService.notify(NotificationType.Success, "Invoice will be sent to your email", "You successfully booked the space");
                    this.router.navigate(["/index"]);
                }
                else {
                    this.notificationService.notify(NotificationType.Error, "Payment by Invoice not accepted. Please check if your account is eligible for Invoice payment, and try again.", "Payment Error");
                }
            },
                
            (err) => { this.submissionService.endSubmission(); console.error(err) }
        );
    }

  /* invoiceEligible(): boolean
    {
        if (this.eventPlanner == null || this.eventPlanner.invoice == null)
            return false;
        return true;
    }
    */
    openInvoiceModal() {
        const modalRef = this.modalService.open(InvoiceModalComponent);
        if (this.eventPlanner.invoice) {
            modalRef.componentInstance.title = 'Edit Invoice Address';
            modalRef.componentInstance.invoice = this.eventPlanner.invoice;
        }
        else {
            modalRef.componentInstance.title = "Add Invoice Address";
        }
        modalRef.componentInstance.passEntry
            .subscribe(() => {
                this.authService.getUserInfo();
                this.getPlanner();
                this.modalService.close();
            });
    }

    private getPlanner() {
        this.authService.getPlannerInfoByID(this.authService.user.id)
            .subscribe(
                res => {
                    this.eventPlanner = res
                },
                err => console.error(err)
            )
    }

    invoiceExists(): boolean {
        return (this.eventPlanner != null && this.eventPlanner.invoice != null);
    }

    goBack()
    {
        this.router.navigate(["entertainment-details/" + this.entertainmentService.serviceId]);
    }

    scrHeight: any;
    scrWidth: any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
    }
}
