/// <reference types="@types/googlemaps" />

import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  NgWizardConfig,
  NgWizardService,
  StepChangedArgs,
  StepValidationArgs,
  STEP_STATE,
  THEME,
} from "ng-wizard";
import { ActivatedRoute, Router } from "@angular/router";
import { AddEntertainmentService } from "../../services/add-entertainment.service";
import { Category } from "../../../shared/models/entertainment/categories";
import { EntertainmentTypes } from "../../../shared/models/entertainment/entertainmentTypes";
import { UploadService } from "../../../shared/services/upload.service";
import {
  AddEntertainmentStep1,
  Type,
} from "../../../shared/models/entertainment/AddEntertainmentStep1";
import { MatStepper } from "@angular/material/stepper";
import { SubmissionService } from "app/modules/shared/services/submission.service";

@Component({
  selector: "add-entertainment-1",
  templateUrl: "./add-entertainment.component.html",
  styleUrls: ["./add-entertainment.component.css"],
})
export class AddEntertainmentComponent implements OnInit, AfterViewInit {
  
  @ViewChild("addresstext") addresstext: any;
  @ViewChild("stepper") stepper: MatStepper;

  public entertainmentCategories: Category[];
  public entertainmentTypes: EntertainmentTypes[];
  public typeValues: Type[] = [];
  public isLinear: boolean = false;
  public uploadError: string = "";
  public files = [];
  public videoFile: any;
  public audioFile: any;
  public addEntertainmentStep1: AddEntertainmentStep1 = {
    Entertain_ServiceName: "",
    Enter_CatID: "",
    EntertainTypeId: [],
    Enter_Desription: "",
    Enter_LocationName: "",
    Enter_DistanceID: "",
    BookingType: "",
    Enter_CovidMeasures: "",
    documentUrl: [],
  };

  public customEntertainmentType = new FormControl("");
  public typeName: string = "";
  autocomplete: google.maps.places.Autocomplete;
  public errors: string[] = [];

  public previewImages: any[] = [];
  public previewVideo: any;
  public previewAudio: any;

  firstStep = new FormGroup({
    Entertain_ServiceName: new FormControl("", Validators.required),
    Enter_CatID: new FormControl(null, Validators.required),
    entertainmentService: new FormControl("", Validators.required),
    EntertainTypeId: new FormControl(null, Validators.required),
  });

  typeChange(id: number) {
    if (this.typeValues.includes({ EntertainTypeId: id })) {
      this.typeValues = this.typeValues.filter((e) => {
        return e.EntertainTypeId !== id;
      });
    } else {
      this.typeValues.push({ EntertainTypeId: id });
    }
    this.firstStep.patchValue({
      EntertainTypeId: this.typeValues,
    });
  }

  secondStep = new FormGroup({
    Enter_Desription: new FormControl("", Validators.required),
    documentUrl: new FormControl(null, Validators.required),
  });

  thirdStep = new FormGroup({
    Enter_LocationName: new FormControl("", Validators.required),
    Enter_DistanceID: new FormControl("", Validators.required),
    Enter_CovidMeasures: new FormControl("", Validators.required),
    BookingType: new FormControl("", Validators.required),
  });

  constructor(
    private ngWizardService: NgWizardService,
    private addEntertainmentService: AddEntertainmentService,
    private uploadService: UploadService,
    public route: ActivatedRoute,
    private submissionService: SubmissionService
  ) {}

  ngOnInit(): void {
    console.log("prop id", this.route.snapshot.params.id);
    this.addEntertainmentService.propertyId = this.route.snapshot.params.id;
    this.initialise();
    
    
    console.log(this.addEntertainmentService.editMode, "edit mode");
  }

  ngAfterViewInit(): void {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      { types: ["geocode"] }
    );

    google.maps.event.addListener(this.autocomplete, "place_changed", () => {
      const place = this.autocomplete.getPlace();
      console.log("place", place);
      this.thirdStep.patchValue({
        Enter_LocationName: place.formatted_address,
      });
    });

    this.addEntertainmentService.stepperNext = this.stepper;
    console.log("stepper next",this.addEntertainmentService.stepperNext);
  }

  getCategoriesEntertainment() {
    this.addEntertainmentService
      .getCatogories()
      .subscribe((response: Category[]) => {
        console.log(response);
        this.entertainmentCategories = response;
      });
  }

  getTypesEntertainment(id: string): void {
    this.addEntertainmentService
      .getEntertainmentTypes(id)
      .subscribe((response: EntertainmentTypes[]) => {
        console.log("response of get types", response);
        this.entertainmentTypes = response;
      });
  }

  check(id: number) {
    console.log("entertainmentTypes", this.entertainmentTypes);
    const result = this.entertainmentTypes.some(
      (e) => e.entertainTypeId === id
    );
    return result;
  }

  initialise(): void {
    this.getCategoriesEntertainment();
  }

  onPaste(event) {
    event.preventDefault();
    event.stopPropagation();
    const plaintext = event.clipboardData.getData("Text");
    document.execCommand("inserttext", false, plaintext);
  }

  categorySelect(e: any) {
    console.log("categorySelected", e.target.value);
    this.getTypesEntertainment(e.target.value);
    this.customEntertainmentType.reset();
    this.addEntertainmentService.custom.type = null;
    this.typeName = "";
  }

  setEntertainmentType(e: any) {
    console.log("changeInput: --------------", e.target.value);
    this.customEntertainmentType = e.target.value;
  }

  AddCustomEntertainmentType() {
    this.addEntertainmentService.custom.type =
      this.customEntertainmentType.value;
    this.typeName = this.customEntertainmentType.value;
    this.customEntertainmentType.reset();
  }

  removeCustomType() {
    this.customEntertainmentType.reset();
    this.typeName = "";
    this.addEntertainmentService.custom.type = "";
  }

  onFileChange(event) {
    console.log("event files", Object.values(event.target.files));
    this.previewImages = [];
    this.files = Object.values(event.target.files);
    console.log("file selected", this.files);
    for (let i of this.files) {
      var reader = new FileReader();
      reader.readAsDataURL(i);
      reader.onload = (event) => {
        this.previewImages.push((<FileReader>event.target).result);
      };
    }
  }

  onVideoChange(event: any) {
    this.videoFile = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.videoFile);
    reader.onload = (event) => {
      this.previewVideo = (<FileReader>event.target).result;
    };
  }

  onAudioChange(event: any) {
    this.audioFile = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.audioFile);
    reader.onload = (event) => {
      this.previewAudio = (<FileReader>event.target).result;
    };
  }

  uploadMedia(type: string) {
    if (type === "audio") {
      this.addEntertainmentService
        .uploadMultiMedia(this.audioFile)
        .subscribe((response: string) => {
          console.log("media upload response", response);
          const [rest, type] = response.split(".");
          console.log("types of media", type);
          console.log("rest of media", rest);

          this.addEntertainmentService.mediaUrls.push({
            Enter_Documenttype: `.${type}`,
            Enter_DocumentURL: response,
          });
        });
    } else if (type === "video") {
      this.addEntertainmentService
        .uploadMultiMedia(this.videoFile)
        .subscribe((response: string) => {
          console.log("media upload response", response);
          const [rest, type] = response.split(".");
          console.log("types of media", type);
          console.log("rest of media", rest);

          this.addEntertainmentService.mediaUrls.push({
            Enter_Documenttype: `.${type}`,
            Enter_DocumentURL: response,
          });
        });
    }
  }

  uploadImages() {
    return new Promise((resolve, reject) => {
      this.addEntertainmentService.uploadImages(this.files).subscribe(
        (response: string[]) => {
          console.log("images upload response", response);
          let newArray = [];
          response.forEach((value) => {
            console.log("value", value);
            newArray.push({
              Enter_DocumentURL: value,
              Enter_Documenttype: ".jpg",
            });
          });

          console.log("upload image new array", newArray);

          this.addEntertainmentStep1.documentUrl = newArray;
          this.secondStep.patchValue({
            documentUrl: newArray,
          });
          resolve("success");
          console.log("add entertainment setp1", this.addEntertainmentStep1);
        },
        (error: any) => {
          console.log("upload error", error);
          reject("error");
          this.uploadError = error;
        }
      );
    });
  }

  submitFirstStep() {
    console.log("first step", this.firstStep.value.Entertain_ServiceName);
    this.addEntertainmentStep1.Entertain_ServiceName =
      this.firstStep.value.Entertain_ServiceName;
    this.addEntertainmentStep1.Enter_CatID = this.firstStep.value.Enter_CatID;
    this.addEntertainmentStep1.EntertainTypeId =
      this.firstStep.value.EntertainTypeId;

    const validator = new Validator(this.addEntertainmentStep1);
    this.errors = validator.step1();
    if (!this.errors.length) {
      this.stepper.next();
    }
  }

  submitSecondStep() {
    console.log("second step", this.secondStep.value);
    this.addEntertainmentStep1.Enter_Desription =
      this.secondStep.value.Enter_Desription;
    // this.addEntertainmentStep1.documentUrl = this.secondStep.value.documentUrl;
    const validator = new Validator(this.addEntertainmentStep1);
    this.errors = validator.step2();
    if (!this.errors.length && this.files.length) {
      console.log(this.files);
      this.stepper.next();
    }else{
      this.errors.push("Please upload image");
    }
  }

  submitThirdStep() {
    console.log("third step", this.thirdStep.value);
    this.addEntertainmentStep1.BookingType = this.thirdStep.value.BookingType;
    this.addEntertainmentStep1.Enter_CovidMeasures =
      this.thirdStep.value.Enter_CovidMeasures;
    this.addEntertainmentStep1.Enter_LocationName =
      this.thirdStep.value.Enter_LocationName;
    this.addEntertainmentStep1.Enter_DistanceID = String(
      this.thirdStep.value.Enter_DistanceID
    );
    console.log("step3 data", this.addEntertainmentStep1);
    const validator = new Validator(this.addEntertainmentStep1);
    this.errors = validator.step3();

    if (!this.errors.length) {
      this.submissionService.startSubmission();
      if (this.files.length) {
        this.uploadImages()
          .then((s) => {
            this.addEntertainmentService
              .createEntertainment(this.addEntertainmentStep1)
              .subscribe((response: number) => {
                this.addEntertainmentService.serviceId = String(response);
                this.submissionService.endSubmission();
                this.stepper.next();
                this.addEntertainmentService
                  .addMedia(response)
                  .subscribe((res: any) => {
                    console.log("add media", res);
                  });
              });
          })
          .catch((err) => {
            this.submissionService.endSubmission();
            this.uploadError = "Please upload images";
          });
      } else {
        this.submissionService.endSubmission();
        this.uploadError = "Please upload images";
      }
    }
  }
}

class Validator {
  constructor(private entertainmentData: AddEntertainmentStep1) {}

  public step1(): string[] {
    let errors: string[] = [];
    try {
      if (this.entertainmentData !== null) {
        if (!this.entertainmentData.Entertain_ServiceName.length) {
          throw new Error("Please Fill the Entertainment Service name");
        } else if (!this.entertainmentData.Enter_CatID) {
          throw new Error("Please Pick an Entertainment category");
        } else if (!this.entertainmentData.EntertainTypeId) {
          throw new Error("Please select the entertainment types");
        }
      } else {
        throw new Error("Please fill all the details");
      }
    } catch (error) {
      errors.push(error);
    }

    return errors;
  }

  public step2(): string[] {
    let errors: string[] = [];
    try {
      if (this.entertainmentData !== null) {
        if (!this.entertainmentData.Enter_Desription.length) {
          throw new Error("Please add the Description");
        }
      } else {
        throw new Error("Please fill all the details");
      }
    } catch (error) {
      errors.push(error);
    }

    return errors;
  }

  public step3(): string[] {
    let errors: string[] = [];

    try {
      if (this.entertainmentData !== null) {
        if ( !this.entertainmentData.Enter_LocationName) {
          throw new Error("Please add a location name");
        } else if (!this.entertainmentData.Enter_DistanceID) {
          throw new Error("Please add Distance willing to travel");
        } else if (!this.entertainmentData.BookingType) {
          throw new Error("Please choose a booking type");
        } else if (!this.entertainmentData.Enter_CovidMeasures ) {
          throw new Error("Please fill the covid measures");
        }
      } else {
        throw new Error("Please fill all the details");
      }
    } catch (error) {
      errors.push(error);
    }

    return errors;
  }
}
