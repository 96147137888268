import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { map, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { EbentoUser } from "app/modules/shared/models/users/ebento-user";
import { AuthService } from "app/modules/shared/services/auth.service";
import { AdminUserFilterDTO } from '../models/admin-user-filter';

@Injectable()
export class AdminUserService {
    
    public allUsers: EbentoUser[]
    public user: EbentoUser

    constructor(
        private http: HttpClient,
        public authService: AuthService
    )
    { }

    public getAllUsers(): Observable<EbentoUser[]>
    {
        return this.http.get("api/adminusers")
            .pipe(
            map(
                (response: EbentoUser[]) => {
                    this.allUsers = response;
                        return response;
                    },
                    err => { console.error(err.message) }
                )
            )
    }

    public getUserInfo(id: string): Observable<EbentoUser> {
        return this.http.get("api/adminuser/"+id)
            .pipe(
                map(
                    (response: EbentoUser) => {
                        this.user = response;
                        return response;
                    },
                    err => { console.error(err.message) }
                )
            )
    }

    public approveInvoice(id: string, status: boolean) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        const dto = {id: id, status: status};
        return this.http.put("api/adminusers/ApproveInvoice", JSON.stringify(dto), httpOptions).pipe(
            map(
            (response: string) => {                        
                    return response;
                },
                err => { console.error(err.message); }
            )
        );
    }

    public changeAccountStatus(id: string, status: boolean) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        const dto = {id: id, status: status};
        return this.http.put("api/adminusers/ChangeAccountStatus", JSON.stringify(dto), httpOptions).pipe(
            map(
            (response: string) => {                        
                    return response;
                },
                err => { console.error(err.message); }
            )
        );
    }

    getFilteredUsers(filter: AdminUserFilterDTO): Observable<EbentoUser[]> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post("api/adminusers/GetFilteredUsers", JSON.stringify(filter), httpOptions).pipe(
            map(
            (response: EbentoUser[]) => {  
                     
                    return response;
                },
                err => { console.error(err.message); }
            )
        );
    }
}