import { Component } from '@angular/core';
import { faSmile, faCheck, faPuzzlePiece, faLaptop, faLightbulb, faCartArrowDown, faCreditCard } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'why-use-ebento',
    templateUrl: 'why-use-ebento.component.html'
})

export class WhyUseEbento
{
    faSmile = faSmile;
    faCheck = faCheck;
    faPuzzlePiece = faPuzzlePiece;
    faLaptop = faLaptop;
    faLightbulb = faLaptop;
    faCartArrowDown = faCartArrowDown;
    faCreditCard = faCreditCard;
}
