import { Component, Input, OnInit, ViewChild, EventEmitter, Output, AfterViewInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { WeekCalendarView } from "../../../../components/week-calendar-view/week-calendar-view.component";
import { WorkingHours } from "../../../../models/properties/working-hours";
import { BookingSegment } from "../../../../models/booking/booking-segment";

@Component({
    selector: 'week-calendar-modal',
    templateUrl: "week-calendar-modal.component.html"
})
export class WeekCalendarModal implements OnInit, AfterViewInit  {

    @ViewChild('calendarView') calendarView : WeekCalendarView;
    @Input() spaceId: number;
    @Input() title: string;
    @Input() isInputCalendar: boolean;
    @Input() isWorkingTimeSetup: boolean;
    @Input() isEntertainment: boolean;
    @Input() markWorkingHours: boolean;
    @Input() workingHours: WorkingHours;
    @Output() passBookings: EventEmitter<any> = new EventEmitter();
    preloadedSelections: BookingSegment[];

    constructor(public activeModal: NgbActiveModal
    ) { }

    public GetAllSelections () : BookingSegment[]
    {
        var result = this.calendarView.GetAllSelections(); 
        return result;
    }

    public SetSelections(selections: BookingSegment[])
    {
 //       console.log("Temp here");
        this.preloadedSelections = selections;
    }

    ngOnInit() {
        console.log('is entertainment',this.isEntertainment);
        
    }

    ngAfterViewInit() {
        if (this.preloadedSelections != null)
            this.calendarView.SetSelections(this.preloadedSelections);
//        console.log("daj amo");
    }

    Close()
    {
        this.passBookings.emit(this.GetAllSelections());
        this.GetAllSelections();
        this.activeModal.close('Close click');
    }
}