import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { PricingRule } from "../../../../models/properties/pricing-rule";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'modify-booking-info-modal',
    templateUrl: "modify-booking-info-modal.component.html"
})
export class ModifyBookingInfoModal {

    constructor(public activeModal: NgbActiveModal
    ) { }


    Close() {
        this.activeModal.close('Close click');
    }
}