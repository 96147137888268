import { HttpClient, HttpErrorResponse} from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';

import { Injectable } from "@angular/core";
import { map, catchError, switchMap } from "rxjs/operators";

import { Observable } from "rxjs";
import { throwError } from 'rxjs';
import { EbentoProperty } from "../models/properties/ebento-property";
import { UploadService } from "./upload.service";

@Injectable()
export class PropertyService {
    //TODO: add caching logic to avoid unnecesary calls to ss

    public properties: EbentoProperty[];
    public property: EbentoProperty;
    public propertyToEdit: EbentoProperty;

    constructor(
        private http: HttpClient,
        private uploadService: UploadService) {
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    getProperties(): Observable<EbentoProperty[]> {
        return this.http.get<EbentoProperty[]>("api/property")
            .pipe(
                map(result => this.properties = result),
                catchError(this.errorHandler)
            );
    }

    getPropertiesSpaces(propertyID: number) {
        return this.http.get<EbentoProperty>("api/property/" + propertyID)
            .pipe(
                map(result =>  { return result?.spaces.filter(s => !s.isDummyForWorkshops); } ),
                catchError(this.errorHandler)
            );
    }

    getProperty(propertyID: number): Observable<EbentoProperty> {
        return this.http.get<EbentoProperty>("api/property/" + propertyID)
            .pipe(
                map(result => this.property = result),
                catchError(this.errorHandler)
            );
    }

    getPropertiesForUserID(managerID: string): Observable<EbentoProperty[]> {
        return this.http.get<EbentoProperty[]>("api/managerproperties/" + managerID)
            .pipe(
            map(result => { return result; }),
                catchError(this.errorHandler)
            );
    }


    refreshProperty(): Observable<EbentoProperty>  {
        return this.getProperty(this.property.id);
    }

    public deleteProperty(propertyID: number): Observable<boolean> {
        return this.http.delete("api/property/" + propertyID)
            .pipe(
                map((data: EbentoProperty) => {
                    return true;
                }),
                catchError(this.errorHandler)
            );
    }

    public addProperty(prop: EbentoProperty): Observable<number> {
        if (prop.coverImage) {
            return this.uploadService.uploadImage(prop.coverImage).pipe(
                switchMap(
                    (res: string) => {
                        prop.photourl = res;
                        return this.addPropertyWithoutUpload(prop);
                    }
                )
            )

        }
        else {
            return this.addPropertyWithoutUpload(prop);
        }
    }

    private addPropertyWithoutUpload(prop: EbentoProperty): Observable<number> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<number>("api/property", JSON.stringify(prop), httpOptions).
            pipe(
                map(res => {
                    this.getProperties();
                    return res
                }),
                catchError(this.errorHandler)
            )
    }

    public editProperty(prop: EbentoProperty): Observable<number> {
        if (prop.coverImage) {
            return this.uploadService.uploadImage(prop.coverImage).pipe(
                switchMap(
                    (res: string) => {
                        prop.photourl = res;
                        return this.editPropertyWithoutUpload(prop);
                    }
                )
            )

        }
        else {
            return this.editPropertyWithoutUpload(prop);
        }
    }

    private editPropertyWithoutUpload(prop: EbentoProperty): Observable<number> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        console.log("property network call",prop);

        return this.http.post<number>("api/updateProperty", JSON.stringify(prop), httpOptions).
            pipe(
                map(res => {
                    this.getProperties();
                    return res
                }),
                catchError(this.errorHandler)
            )
    }

}