import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { NotificationType } from 'app/helpers/enums/notificationType';
import { AdminNewsService } from '../../services/admin-news.service';
import { NotificationService } from 'app/modules/shared/services/notification.service';
import { faImage, faPaperclip, faEdit, faMinusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'admin-edit-news-cell',
    templateUrl: './admin-edit-news-cell.component.html',
    styleUrls: ['./admin-edit-news-cell.component.scss']
})
export class AdminEditNewsCellComponent implements ICellRendererAngularComp {

    //fa
    faImage = faImage;
    faPaperclip = faPaperclip;
    faEdit = faEdit;
    faMinusCircle = faMinusCircle;
    //fa

    public params: any;

    constructor(private router: Router, public adminNewsService: AdminNewsService, private notificationService: NotificationService) { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return true;
    }

    photoIcon()
    {
        return this.params.data.hasPhoto;
    }

    attachmentIcon() {
        return this.params.data.hasAttachment;
    }
    
    edit() {
        this.router.navigate(['/admin/edit/news/' + this.params.data.idNews]);
    }

    publishToggle()
    {
        this.adminNewsService.setPublished(this.params.data.idNews, !this.params.data.isPublished).subscribe(
            (success) =>
            {
                this.params.data.isPublished = !this.params.data.isPublished;
                if (this.params.data.isPublished) {
                    this.notificationService.notify(NotificationType.Success, "Story successfully published", "Story Published");
                }
                else
                {
                    this.notificationService.notify(NotificationType.Success, "Story successfully unpublished", "Story Unpublished");
                }  
                
                this.params.api.redrawRows();
            }
        );
    }

    delete() {
        if (confirm('Are you sure you want to delete story "' + this.params.data.heading + '?"'))
            this.adminNewsService.delete(this.params.data.idNews).subscribe(
                (success) => {
                    this.adminNewsService.getAllNews().subscribe(
                        (news) =>
                        {
                            this.notificationService.notify(NotificationType.Success, "Story successfully deleted", "Story Deleted");
                            this.params.api.redrawRows();
                        }
                    );
                }
            );
    }
}
