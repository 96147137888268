import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { SpaceBookingService } from "../../services/space-booking.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "multiple-spaces-overview",
  templateUrl: "multiple-spaces-overview.component.html",
  styleUrls: ["multiple-spaces-overview.component.scss"],
})
export class MultipleSpacesOverview implements OnInit {
  
    @Input() type:string;

    ngOnInit(): void {
    this.GetBookings();
  }

  firstOfMonth: Date;
  dates: Array<number>;
  collapsed: Array<boolean>;
  weekdaysAbrev = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  monthAbrevs = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  constructor(
    public bookingService: SpaceBookingService,
    public authService: AuthService
  ) {
    this.firstOfMonth = new Date();
    this.firstOfMonth.setDate(1);

    this.InitializeDates();

    this.collapsed = new Array<boolean>();

    this.collapsed.push(false);
    this.collapsed.push(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.GetBookings();
  }

  GetBookings() {
    let typevalue:number;
    if(this.type=== 'space'){
        typevalue = 1;
    }else if(this.type === 'experience'){
        typevalue = 2;
    }else if(this.type=== 'entertaiment'){
        typevalue = 3;
    }

    this.bookingService
      .getPMBookingOverview(this.authService.user.id, typevalue)
      .subscribe((success) => {
        if (success) {
          this.InitializeDates();
        } else {
          console.error("Error");
        }
      });
  }

  InitializeDates() {
    this.dates = new Array<number>();
    var lastOfMonth = new Date(this.firstOfMonth);
    lastOfMonth.setMonth(lastOfMonth.getMonth() + 1);
    lastOfMonth.setDate(0);
    var daysInMonth = lastOfMonth.getDate();
    for (var i = 0; i < daysInMonth; i++) {
      this.dates.push(i + 1);
    }
  }

  NextMonth() {
    this.firstOfMonth.setMonth(this.firstOfMonth.getMonth() + 1);
    this.InitializeDates();
  }

  PreviousMonth() {
    this.firstOfMonth.setMonth(this.firstOfMonth.getMonth() - 1);
    this.InitializeDates();
  }

  GetCornerText(): string {
    var env = this.findBootstrapEnvironment();
    if (env == "sm" || env == "xs")
      return (
        this.monthAbrevs[this.firstOfMonth.getMonth()] +
        " " +
        this.firstOfMonth.getFullYear()
      );
    else
      return (
        this.monthNames[this.firstOfMonth.getMonth()] +
        " " +
        this.firstOfMonth.getFullYear()
      );
  }

  findBootstrapEnvironment(): string {
    let envs = ["xs", "sm", "md", "lg", "xl"];

    let el = document.createElement("div");
    document.body.appendChild(el);

    let curEnv = envs.shift();

    for (let env of envs.reverse()) {
      el.classList.add(`d-${env}-none`);

      if (window.getComputedStyle(el).display === "none") {
        curEnv = env;
        break;
      }
    }

    document.body.removeChild(el);
    return curEnv;
  }

  GetDay(index: number): string {
    var date = new Date(this.firstOfMonth);
    date.setDate(index);
    var dayindex = date.getDay();
    dayindex--;
    if (dayindex < 0) dayindex = 6;
    return this.weekdaysAbrev[dayindex];
  }

  TooglePropertyCollapse(index: number) {
    this.collapsed[index] = !this.collapsed[index];
  }

  GetPercentage(
    propertyIndex: number,
    spaceIndex: number,
    dateOfMonth: number
  ): number {
    var percentages =
      this.bookingService.bookingOverview.propertyBookings[propertyIndex]
        .spaceBookings[spaceIndex].bookingPercentages;
    if (percentages != null)
      for (let percentage of percentages) {
        var pdate = new Date(Date.parse(percentage.date));
        if (
          pdate.getMonth() == this.firstOfMonth.getMonth() &&
          pdate.getFullYear() == this.firstOfMonth.getFullYear() &&
          pdate.getDate() == dateOfMonth
        )
          return percentage.percentage;
      }
    return 0;
  }

  GetPercentageForProperty(propertyIndex: number, dateOfMonth: number): number {
    if (
      this.bookingService.bookingOverview.propertyBookings[propertyIndex]
        .spaceBookings.length == 0
    )
      return 0;
    var totalPercentage = 0;
    for (
      var spaceIndex = 0;
      spaceIndex <
      this.bookingService.bookingOverview.propertyBookings[propertyIndex]
        .spaceBookings.length;
      spaceIndex++
    ) {
      var percentages =
        this.bookingService.bookingOverview.propertyBookings[propertyIndex]
          .spaceBookings[spaceIndex].bookingPercentages;
      if (percentages != null)
        for (let percentage of percentages) {
          var pdate = new Date(Date.parse(percentage.date));
          if (
            pdate.getMonth() == this.firstOfMonth.getMonth() &&
            pdate.getFullYear() == this.firstOfMonth.getFullYear() &&
            pdate.getDate() == dateOfMonth
          )
            totalPercentage += percentage.percentage;
        }
    }
    totalPercentage /=
      this.bookingService.bookingOverview.propertyBookings[propertyIndex]
        .spaceBookings.length;
    if (totalPercentage == 0) return 0;
    else return totalPercentage;
  }

  GetSpaceCellColor(
    propertyIndex: number,
    spaceIndex: number,
    dateOfMonth: number
  ): string {
    var percent = this.GetPercentage(propertyIndex, spaceIndex, dateOfMonth);
    if (percent < 25) return "#ffffff";
    if (percent < 50) return "#f29046";
    if (percent < 75) return "#f4ea27";
    return "#77d142";
  }

  GetPropertyCellColor(propertyIndex: number, dateOfMonth: number): string {
    var percent = this.GetPercentageForProperty(propertyIndex, dateOfMonth);
    if (percent < 25) return "#ffffff";
    if (percent < 50) return "#f2904680";

    if (percent < 75) return "#f4ea2780";
    return "#77d14280";
  }
}
