import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { SpaceForTile } from '../../../../app/modules/shared/models/search/space-for-tile';
import { BookingSegment } from "app/modules/shared/models/booking/booking-segment";
import { SearchSpaceQuery } from "../../../../app/modules/shared/models/search/search-space-query";
import { SearchWorkshopQuery } from '../../shared/models/search/search-workshop-query';
import { WorkshopTile } from '../../shared/models/search/workshop-tile';
import { SuccessPageAddSpace } from '../../property-manager/components/success-page-add-space/success-page-add-space.component';

@Injectable()
export class SearchService {
    //TODO: redundant class space for tile - refactor to use ebentoSpaceDTO
    public searchResults: SpaceForTile[];
    public spaceForTile: SpaceForTile;
    public searchResultsWorkshop: WorkshopTile[];
    public workshopForTile: WorkshopTile;
    public calendarTimeSelection: BookingSegment[];
    public searchSpaceQuery: SearchSpaceQuery;
    public searchWorkshopQuery: SearchWorkshopQuery;
    public searchType: string;

    public searchPlaceAutocompleteLatLng;
    constructor(private http: HttpClient) {
    }

    //findSpaces(prop: SearchSpaceQuery): Observable<SpacesForTiles> {
    //    const httpOptions = {
    //        headers: new HttpHeaders({
    //            'Content-Type': 'application/json',
    //            'Authorization': 'my-auth-token'
    //        })
    //    };
    //    return this.http.post<SpacesForTiles>("api/searchspace", JSON.stringify(prop), httpOptions).pipe(
    //        map(data => { this.searchResults = data; return data; })
    //    );
    //}

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    findSpaces(): Observable<SpaceForTile[]> {
        //if (this.searchSpaceQuery.address != null && this.searchSpaceQuery.address.streetnumber != null)
        //    this.searchSpaceQuery.address.streetaddress += " " + this.searchSpaceQuery.address.streetnumber;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };
       return this.http.post<SpaceForTile[]>(
            "api/searchspace",
            JSON.stringify(this.searchSpaceQuery),
            httpOptions)
            .pipe(
                map(data => this.searchResults = data),
                catchError(this.errorHandler),
            );
    }

    findWorkshops(): Observable<WorkshopTile[]> {
         if (this.searchWorkshopQuery.address != null && this.searchWorkshopQuery.address.streetnumber != null)
             this.searchWorkshopQuery.address.streetaddress += " " + this.searchWorkshopQuery.address.streetnumber;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'my-auth-token'
            })
        };
        return this.http.post<any[]>(
            "api/searchworkshop",
            JSON.stringify(this.searchWorkshopQuery),
            httpOptions)
            .pipe(
                map(data => this.searchResults = data),
                catchError(this.errorHandler),
            );
    }

}