import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { WorkshopBookingMenu } from "app/modules/shared/models/booking/workshop-booking-menu";
import { WorkshopMenu } from "app/modules/shared/models/workshops/workshop-menu";
import { WorkshopBookingService } from "../../../shared/services/workshop-booking.service";

@Component({
  selector: "workshop-listing-menus",
  templateUrl: "./workshop-listing-menus.component.html",
  styleUrls: ["./workshop-listing-menus.component.scss"],
})
export class WorkshopListingMenus implements OnInit, AfterViewInit, OnChanges {
  @Input() menus: WorkshopMenu[];

  constructor(private bookingService: WorkshopBookingService) {}

  ngOnInit() {
    console.log("menus____",this.menus,this.bookingService.bookingOptions.menus);
    if(this.bookingService.bookingOptions?.menus?.length){
      for(let i=0;i< this.menus.length;i++){
        for(let j=0;j< this.bookingService.bookingOptions.menus.length;j++){
          if(this.menus[i].id === this.bookingService.bookingOptions.menus[j].menuID){
            this.menus[i].quantity = this.bookingService.bookingOptions.menus[j].quantity;
          }else{
            continue;
          }
        }
      }
    }
  }

  ngAfterViewInit() {
    console.log(">@@@" + JSON.stringify(this.menus));
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let change = changes[propName];
      let curVal = JSON.stringify(change.currentValue);
      let prevVal = JSON.stringify(change.previousValue);

      //   console.log(curVal);
      //   console.log(prevVal);
    }
  }

  isChecked(id: number): boolean {
    if (this.bookingService.bookingOptions?.menus?.length) {
      return this.bookingService.bookingOptions.menus.some(
        (e) => e.menuID === id
      );
    } else {
      return false;
    }
  }

  AddToMenus(menu: WorkshopMenu) {
    console.log("menu add ", menu);

    if (this.isChecked(menu.id)) {
      this.bookingService.bookingOptions.menus =
        this.bookingService.bookingOptions.menus.filter((e) => {
          return e.menuID !== menu.id;
        });
    } else {
      let wbm = new WorkshopBookingMenu();
      wbm.menuID = menu.id;
      wbm.quantity = menu.quantity;
      wbm.unitPrice = menu.price;
      this.bookingService.bookingOptions.menus.push(wbm);
    }
    console.log(
      "booking options menus",
      this.bookingService.bookingOptions.menus
    );
  }

  changeCount(i: number, e: any) {
    console.log("count change", e.target.value);

    this.menus[i].quantity = e.target.value;
    if (this.isChecked(this.menus[i].id)) {
      for (let m of this.bookingService.bookingOptions.menus) {
        if (m.menuID == this.menus[i].id) {
          m.quantity = e.target.value;
        }
      }
    }
  }
}
