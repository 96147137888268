import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CreditCard } from '../../../../models/users/credit-card';
import { AuthService } from '../../../../services/auth.service';
import { CustomerService } from '../../../../services/customer.service';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationType } from 'app/helpers/enums/notificationType';

@Component({
  selector: 'app-credit-card-modal',
  templateUrl: './credit-card-modal.component.html',
})
export class CreditCardModalComponent implements OnInit {

    @Input() title: string
    @Input() creditCard: CreditCard
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    errorMessage: string = "";

    constructor(
        public authService: AuthService,
        private customerService: CustomerService,
        private router: Router,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(["/index"]);
        }
        if (this.authService.user.userType != "Event Planner") {
            this.router.navigate(["/index"]);
        }
        if (!this.creditCard) {
            this.creditCard = new CreditCard();
        }
        else
        {
            let ccCopy = Object.assign({}, this.creditCard)
            this.creditCard = ccCopy;
            this.creditCard.ccNumber = this.creditCard.ccNumber.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
        }
    }

    public ccNumberFormat(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        if (this.creditCard.ccNumber != null)
        this.creditCard.ccNumber = this.creditCard.ccNumber.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
    }

    public ccCVVFormat(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
    }

    cancel() {
        this.passEntry.emit();
    }

    save() {
        this.customerService.createOrUpdateCreditCard(this.creditCard)
            .subscribe(
                res => {
                    if (res) {
                        this.notificationService.notify(NotificationType.Success, "Credit card successfully updated");
                        this.passEntry.emit();
                    }
                },
                () => {
                    this.notificationService.notify(NotificationType.Error, "Credit card update failed");
                })
    }
}
