/// <reference types="@types/googlemaps" />

import { Component, ViewChild, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SpaceForTile } from "app/modules/shared/models/search/space-for-tile";
import { PropertyManagerReview } from "app/modules/shared/models/users/property-manager-review";
import { AuthService } from "app/modules/shared/services/auth.service";
import { SpaceBookingService } from "app/modules/shared/services/space-booking.service";
import { PropertyService } from "app/modules/shared/services/property.service";
import { ReviewService } from "app/modules/shared/services/review.service";
import { SpaceService } from "app/modules/shared/services/space.service";
import { MessagingService } from "app/modules/shared/services/messaging.service";
import { SpaceBookingOptions } from "app/modules/shared/models/booking/space-booking-options";
import { WeekCalendarModal } from "app/modules/shared/modules/modals/components/week-calendar-modal/week-calendar-modal.component";
import { PropertyAddress } from "app/modules/shared/models/properties/property-address";
import { Amenity } from "app/modules/shared/models/properties/Amenity";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";
import { EbentoModalService } from "../../../shared/services/ebento-modal.service";
import { CartService } from '../../services/cart.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { NotificationType } from '../../../../helpers/enums/notificationType';

@Component({
  selector: "space-listing",
  templateUrl: "space-listing.component.html",
  styleUrls: ["./space-listing.component.scss"],
})
export class SpaceListing implements OnInit, AfterViewInit {
 
  spaceForTile: SpaceForTile;
  reviews: PropertyManagerReview[];
  modalRef: NgbModalRef;
  attendees: number = 0;
  activeImage: string;
  @ViewChild("gmap") gmapElement: any;
  propertyImage:string = "assets/images/profile.png";

  public map: google.maps.Map;

  public spacephotourl: string;

  amenityQuantity: Object;

  constructor(
    public authService: AuthService,
    public bookingService: SpaceBookingService,
    public propertyService: PropertyService,
    private reviewService: ReviewService,
    public spaceService: SpaceService,
    private modalService: EbentoModalService,
    public messagingService: MessagingService,
    public router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private cartService:CartService,
    private notificationService: NotificationService,

  ) {
    this.amenityQuantity = {};
  }

  ngOnInit() {
    if (
      !(
        this.authService.isLoggedIn() &&
        this.authService.userType == "EventPlanner"
      )
    )
      this.router.navigate(["index"]);
    else {
      let spaceIdParam = this.activatedRoute.snapshot.params.id;
      if (spaceIdParam != null) {
        this.spaceService.getSpaceTile(spaceIdParam).subscribe((spaceTile) => {
          this.spaceForTile = spaceTile;
          this.activeImage = spaceTile.space.photoUrls[0];
          this.propertyImage = spaceTile.propertyphotourl ? spaceTile.propertyphotourl : 'assets/images/profile.png';
          this.init();
        });
      }
    }
  }

  ngAfterViewInit(): void {
    this.SetMap();
  }

  init() {
    this.bookingService.bookingOptions.attendees = this.attendees;
    this.reviewService
      .getReviewsForSpace(this.spaceForTile.space.spaceId)
      .subscribe((reviews) => {
        this.reviews = reviews;
      });

    this.spaceForTile.space = this.bookingService.space;

    if (
      this.spaceForTile != null &&
      this.spaceForTile.space.photoUrls != null &&
      this.spaceForTile.space.photoUrls.length > 0
    ) {
      this.spacephotourl = this.spaceForTile.space.photoUrls[0];
    }

    // this.SetMap();

    window.scrollTo(0, 0);
  }

  SetMap() {
    var mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    var map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.map = map;
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        this.spaceForTile.address.googlemapcoordx,
        this.spaceForTile.address.googlemapcoordy
      ),
      map: this.map,
    });

    var bounds = new google.maps.LatLngBounds();
    bounds.extend(marker.getPosition());

    this.map.fitBounds(bounds);
    var listener = google.maps.event.addListener(map, "idle", function () {
      if (map.getZoom() > 16) map.setZoom(16);
      google.maps.event.removeListener(listener);
    });
  }

  reduceAttendees() {
    if (this.attendees > 0) {
      --this.attendees;
    }
    this.bookingService.bookingOptions.attendees = this.attendees;
  }

  incrementAttendees() {
    ++this.attendees;
    this.bookingService.bookingOptions.attendees = this.attendees;
  }

  setBigImage(i: number) {
    this.activeImage = this.spaceForTile.space.photoUrls[i];
  }

  openModal() {
    // const modalRef = this.modalService.open(ModalComponent);
    this.modalRef = this.modalService.open(WeekCalendarModal, { size: "xl" });

    this.modalRef.componentInstance.title = "Reservation Calendar";
    this.modalRef.componentInstance.spaceId = this.spaceForTile.space.spaceId;
    if (this.bookingService.bookingTimeslots != null)
      this.modalRef.componentInstance.SetSelections(
        this.bookingService.bookingTimeslots
      );
    //this.modalRef.result.then(() => { this.bookingTimeslots = this.modalRef.componentInstance.result; }, () => { this.bookingTimeslots = this.modalRef.componentInstance.result; })
    this.modalRef.componentInstance.passBookings.subscribe((receivedEntry) => {
      this.bookingService.bookingTimeslots = receivedEntry;
    });
  }

  public AddressText(address: PropertyAddress) {
    var result: string = "";
    if (address.streetaddress != null && address.streetaddress != "")
      result += address.streetaddress;
    if (address.streetnumber != null && address.streetnumber != "")
      result += " " + address.streetnumber;
    if (address.city != null && address.city != "") {
      if (result != "") result += ", ";
      if (address.postalcode != null && address.postalcode != "")
        result += address.postalcode + " ";
      result += address.city;
    }
    if (address.country != null && address.country != "") {
      if (result != "") result += ", ";
      result += address.country;
    }
    return result;
  }

  public OptionalAmenities(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.spaceForTile.space.amenities != null)
      for (var a of this.spaceForTile.space.amenities) {
        if (a.isoptional) result.push(a);
      }
    return result;
  }

  public Catering(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.spaceForTile.space.amenities != null)
      for (var a of this.spaceForTile.space.amenities) {
        if (a.amenitytype.isCatering) result.push(a);
      }
    return result;
  }

  public FixedAmenities(): Amenity[] {
    var result: Amenity[] = new Array();
    if (this.spaceForTile.space.amenities != null)
      for (var a of this.spaceForTile.space.amenities) {
        if (!a.isoptional && !a.amenitytype.isCatering) result.push(a);
      }
    return result;
  }

  public SetAttendeeCount(count: number) {
    this.bookingService.bookingOptions.attendees = count;
  }

  //TODO: move to service
  downloadFile(amenity: Amenity) {
    return this.http
      .get(window.location.origin + "/" + amenity.documenturl, {
        responseType: "blob",
      })
      .subscribe(
        (res) => {
          //console.log('start download:', res);
          var url = window.URL.createObjectURL(res);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download =
            this.spaceForTile.space.name +
            "_" +
            amenity.amenitytype.name +
            "_details";
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        },
        (error) => {
          console.error("download error:", JSON.stringify(error));
        },
        () => {
          //console.log('Completed file download.')
        }
      );
  }

  bookNow() {
    this.router.navigate(["event-planner/booking-page"]);
  }

  bookByInquiry(){
    this.bookingService.populateBookingOptions();
    this.router.navigate(["enquiry-booking/space"]);
  }

  AddToCart() {
    this.bookingService.populateBookingOptions();
    
    const slots = this.bookingService.bookingOptions.timeAllocationDTOs.map(
      (e) => {
        return {
          startTime: e.startTime,
          endTime: e.endTime,
          allocationType: e.allocationType,
        };
      }
    );

    const temp = {
        bookedTimeSlots: slots,
        attendees:this.attendees,
        spaceType: 'Space',
        optionalAmenities: this.bookingService.bookingOptions.optionalAmenities,
        totalPrice: this.bookingService.BasePrice(),
        serviceId: this.spaceForTile.space.spaceId,
        spaceName: this.spaceForTile.space.name,
        propertyName: this.spaceForTile.space.propertyName,
        image: this.spaceForTile.space.photoUrls[0]
    }

    this.cartService.addToCart(temp).subscribe(
        (resp) => {
          ++this.cartService.cartcount;
          this.notificationService.notify(NotificationType.Success, `${this.spaceForTile.space.name} is added to the cart`, "Event space Added to the cart");
        },
        (err) => {
            console.log('add cart error',err);
            this.notificationService.notify(NotificationType.Error, `${this.spaceForTile.space.name} cannot be added to the cart`, "Please try again later");
        })

  }

  gotoProfile() {
    this.propertyService
      .getProperty(this.spaceForTile.space.propertyId)
      .subscribe(() => this.router.navigate(["/property-details"]));
  }

  sendMessage() {
    this.messagingService.currentRecepient =
      this.spaceForTile.space.propertyManager;
    this.messagingService.currentDraft = new EbentoMessage();
    this.router.navigate(["/messages/compose"]);
  }
  
}
