import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagerBookingService } from '../../services/manager-booking.service';
import { MessagingService } from 'app/modules/shared/services/messaging.service';
import { PMBookingsFilter } from 'app/modules/shared/models/booking/pm-bookings-filter';
import { EbentoMessage } from 'app/modules/shared/models/messaging/ebento-message';

@Component({
    selector: 'customer-details',
    templateUrl: "customer-details.component.html",
    styleUrls: ['customer-details.component.css']
    
})
export class CustomerDetails implements OnInit {

    fields = [
        { label: 'First Name', fieldname: 'firstName' },
        { label: 'Last Name', fieldname: 'lastName' },
        { label: 'Email', fieldname: 'email' },
        { label: 'Phone', fieldname: 'phoneNumber' },
        { label: 'Location', fieldname: 'workLocation' } 
    ];

    constructor(private route: ActivatedRoute, public managerBookingService: ManagerBookingService, public messagingService: MessagingService, private router: Router)
    {

    }

    ngOnInit(): void {

        this.route.params.subscribe(params => {
            if (params['id'] != null)
                this.managerBookingService.getEventPlannerInfo(params['id']).subscribe(
                    () => {
                        var filter = new PMBookingsFilter();
                        filter.plannerID = this.managerBookingService.eventPlanner.id;
                        this.managerBookingService.getBookingsWithFilter(filter)
                            .subscribe();
                    }
                );
        });

        /*var filter = new PMBookingsFilter();
        filter.plannerID = this.managerBookingService.eventPlanner.id;
        this.managerBookingService.getBookingsWithFilter(filter)
            .subscribe();*/
    }


    sendMessage() {
        this.messagingService.currentRecepient = this.managerBookingService.eventPlanner;
        this.messagingService.currentDraft = new EbentoMessage();
        this.router.navigate(['/messages/compose']);
    }
}

