import { TimeSpan } from "../data-classes/time-span";

export class WorkingHours {

    public starttimes: TimeSpan[]
    public endtimes: TimeSpan[]
    public workingdays: boolean[]

    constructor(
        mondaystart: TimeSpan,
        mondayend: TimeSpan,
        tuesdaystart: TimeSpan,
        tuesdayend: TimeSpan,
        wednesdaystart: TimeSpan,
        wednesdayend: TimeSpan,
        thursdaystart: TimeSpan,
        thursdayend: TimeSpan,
        fridaystart: TimeSpan,
        fridayend: TimeSpan,
        saturdaystart: TimeSpan,
        saturdayend: TimeSpan,
        sundaystart: TimeSpan,
        sundayend: TimeSpan
    ) {
        this.starttimes = [mondaystart, tuesdaystart, wednesdaystart, thursdaystart, fridaystart, saturdaystart, sundaystart];

        this.endtimes = [mondayend, tuesdayend, wednesdayend, thursdayend, fridayend, saturdayend, sundayend];
        this.workingdays = [true, true, true, true, true, false, false]
    }

    public static Default(): WorkingHours {
        return new WorkingHours
            (new TimeSpan(0, 8, 0, 0), new TimeSpan(0, 18, 0, 0),
            new TimeSpan(0, 8, 0, 0), new TimeSpan(0, 18, 0, 0),
            new TimeSpan(0, 8, 0, 0), new TimeSpan(0, 18, 0, 0),
            new TimeSpan(0, 8, 0, 0), new TimeSpan(0, 18, 0, 0),
            new TimeSpan(0, 8, 0, 0), new TimeSpan(0, 18, 0, 0),
            new TimeSpan(0, 8, 0, 0), new TimeSpan(0, 18, 0, 0),
            new TimeSpan(0, 8, 0, 0), new TimeSpan(0, 18, 0, 0)
            );
    }

    public static FromDTO(dto: any): WorkingHours {
        var wh = WorkingHours.Default();
        wh.workingdays = new Array<boolean>();
        for (var i = 0; i < 7; i++) {
            wh.starttimes[i].totalseconds = dto.starttimes[i].totalseconds;
            wh.endtimes[i].totalseconds = dto.endtimes[i].totalseconds;
            wh.workingdays[i] = dto.workingdays[i];
        }
        return wh;

    }
}