import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, LoginRedirectType } from "app/modules/shared/services/auth.service";
import { MessagingService } from "app/modules/shared/services/messaging.service";
import { EbentoMessage } from "app/modules/shared/models/messaging/ebento-message";
import { SpaceBookingService } from "../../../shared/services/space-booking.service";
import { WorkshopBookingService } from "../../../shared/services/workshop-booking.service";
import { CartService } from '../../../event-planner/services/cart.service';

@Component({
    selector: "login",
    templateUrl: "login.component.html",
    styleUrls: ['./login.component.css']
})
export class Login {

    constructor(
        public authService: AuthService, 
        private spaceBookingService: SpaceBookingService, 
        private workshopBookingService: WorkshopBookingService, 
        private router: Router, 
        public messagingService: MessagingService,
        private cartService:CartService
        ) { }

    errorMessage: string = "";
    public creds = {
        Email: "",
        Password: ""
    };

    onLogin() {
        this.errorMessage = "";
        console.log("logn credentials",this.creds);
        
        this.authService.login(this.creds)
            .subscribe(
                success => {
                    if (!this.authService.user.isProfileCompleted)
                    {
                        if (this.authService.userType == "EventPlanner")
                            this.router.navigate(['event-planner/complete-registration']);
                        else if (this.authService.userType == "PropertyManager")
                            this.router.navigate(['property-manager/complete-registration']);
                        else 
                        {
                            console.error("user type unknown");
                        }
                        return;
                    }
                    if (this.authService.userType == "EventPlanner") {
                        this.cartService.getCartCount().subscribe((count) => {
                            this.cartService.cartcount = count.count;
                        })
                        if (this.authService.loginRedirectType == LoginRedirectType.SpaceListing)
                            this.spaceBookingService.startNewBooking(Number.parseInt(this.authService.loginRedirectData));
                        else if (this.authService.loginRedirectType == LoginRedirectType.WorkshopListing)
                            this.workshopBookingService.startNewBooking(Number.parseInt(this.authService.loginRedirectData));
                        else if (this.authService.loginRedirectType == LoginRedirectType.Message)
                        {
                            this.authService.getManagerInfoByID(this.authService.loginRedirectData).subscribe(
                                (manager) => {
                                    this.messagingService.currentRecepient = manager;
                                    this.messagingService.currentDraft = new EbentoMessage();
                                    this.router.navigate(['/messages/compose']);
                                });
                        }
                        else 
                            this.router.navigate(["index"]);
                    } else if (this.authService.userType == "PropertyManager") {
                        this.router.navigate(['/property-manager/properties']);
                    } else if (this.authService.userType == "Administrator") {
                        this.router.navigate(["admin"]);
                    }
                },
                err => {
                    console.log("error",JSON.stringify(err));
                    console.log("error",err);
                err.error ? this.errorMessage = err.error :
                    this.errorMessage = "Failed to login";
                if (err.error.indexOf("#") >0) // TODO: string compare. Improve!
                {
                        var token = err.error.split("#")[1];
                        var type = err.error.split("#")[2];
                         this.router.navigate(["verify-by-email", "registration", this.creds.Email]);
                }
            }
            );
    }

    forgotPassword() {
        this.router.navigate(['forgot-password']);
    }
}