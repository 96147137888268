// CORE MODULES

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
// COMMON COMPONENTS

import { AppComponent } from './app.component';
import { CustomerDetails } from './modules/property-manager/components/customer-details/customer-details.component';
import { PropertyManagerDetails } from './modules/shared/components/property-manager-details/property-manager-details.component';
import { PricingInfoComponent } from './modules/shared/modules/footer-pages/components/pricing-info/pricing-info.component';
import { NewsfeedPage } from './modules/shared/modules/footer-pages/components/newsfeed-page/newsfeed-page.component';
import { NewsPage } from './modules/shared/modules/footer-pages/components/news-page/news-page.component';
import { AboutComponent } from './modules/shared/modules/footer-pages/components/about/about.component';
import { FaqComponent } from './modules/shared/modules/footer-pages/components/faq/faq.component';
import { CareerComponent } from './modules/shared/modules/footer-pages/components/career/career.component';
import { TermsComponent } from './modules/shared/modules/footer-pages/components/terms/terms.component';
import { FeedbackComponent } from './modules/shared/modules/footer-pages/components/feedback/feedback.component';
import { ContactUsComponent } from './modules/shared/modules/footer-pages/components/contact-us/contact-us.component';
import { CovidComponent } from './modules/shared/modules/footer-pages/components/covid/covid.component';
import { EntertainmentComponent } from './modules/shared/components/entertainment/entertainment.component'
import { ListService } from './modules/event-planner/components/list-service/list-service.component';
 
// AUTH COMPONENTS

import { AccountSettingsComponent } from './modules/auth/components/account-settings/account-settings.component';
import { Login } from './modules/auth/components/login/login.component';
import { Register } from './modules/auth/components/register/register/register.component';
import { RegisterCommon } from './modules/auth/components/register/register-common/register-common.component';
import { ChangePassword } from './modules/auth/components/change-password/change-password.component';
import { ChangeEmailComponent } from './modules/auth/components/change-email/change-email.component';
import { ConfirmRegistration } from './modules/auth/components/register/confirm-registration/confirm-registration.component';
import { VerifyByEmail } from './modules/auth/components/register/verify-by-email/verify-by-email.component';
import { EditEmailComponent } from './modules/auth/components/edit-email/edit-email.component';
import { EditPasswordComponent } from './modules/auth/components/edit-password/edit-password.component';

// SPACE & PROPERTY COMPONENTS
import { IndexPagePlanner } from './modules/event-planner/components/index/index.component';
import { AddPropertyForm } from './modules/property-manager/components/add-property/add-property.component';
import { PropertyDetails } from './modules/shared/components/property-details/property-details.component';
import { SpaceIndexPagePlanner } from './modules/event-planner/components/space-index/space-index.component';
import { AddSpaceForm } from './modules/property-manager/components/add-space/add-space.component';
import { SpaceListing } from './modules/event-planner/components/space-listing/space-listing.component';
import { SpaceDetails } from './modules/shared/components/space-details/space-details.component';
import { SpaceSearchResults } from './modules/event-planner/components/space-search-results/space-search-results.component';
import { BookingDetails } from './modules/event-planner/components/booking-details/booking-details.component';
import { AddEntertainmentComponent } from './modules/property-manager/components/add-entertainment/add-entertainment.component';
import { PreviewComponent } from './modules/property-manager/components/add-entertainment/preview-page/preview.component';
import { EditEntertainment } from './modules/property-manager/components/edit-entertainment/edit-entertainment.component';
import { EntertainmentDetails } from './modules/event-planner/components/entertainment-details/entertainment-details.component';
import { EntertainmentBooking } from './modules/event-planner/components/entertainment-booking/entertainment-booking.component';
import { EbentoCart } from './modules/event-planner/components/ebento-cart/ebento-cart.component';
import { CartPaymentComponent } from './modules/event-planner/components/cart-payment/cart-payment.component';
import { EnquiryBookingComponent } from './modules/event-planner/components/enquiry-booking/enquiry-booking.component';
import { EnquirySuccessComponent } from './modules/event-planner/components/enquiry-success/enquiry-success.component';
import { ModifyCartSpace } from './modules/event-planner/components/modify-cart-space/modify-cart-space.component';
import { ModifyCartExperience } from './modules/event-planner/components/modify-cart-experience/modify-cart-experience.component';
import { ModifyCartEntertainment } from './modules/event-planner/components/modify-cart-entertainment/modify-cart-entertainment.component';
import { SpaceEnquiryBooking } from './modules/event-planner/components/space-enquiry-booking/space-enquiy-booking.component';

// WORKSHOP COMPONENTS
import { WorkshopIndexPagePlanner } from './modules/event-planner/components/workshop-index/workshop-index.component';
import { AddWorkshopForm } from './modules/property-manager/components/add-workshop/add-workshop.component';
import { WorkshopListing } from './modules/event-planner/components/workshop-listing/workshop-listing.component';
import { WorkshopDetails } from './modules/shared/components/workshop-details/workshop-details.component';
import { WorkshopBookingPage } from './modules/event-planner/components/workshop-booking-page/workshop-booking-page.component';
import { WorkshopBookingDetails } from './modules/event-planner/components/workshop-booking-details/workshop-booking-details.component';
import { WorkshopSearchResults } from './modules/event-planner/components/workshop-search-results/workshop-search-results.component';
import { EntertainmentSearchResult } from './modules/event-planner/components/entertainment-search-result/entertainment-search-result.component';

// OTHER
import { SuccessPageAddSpace } from './modules/property-manager/components/success-page-add-space/success-page-add-space.component';
import { ReviewPropertyManager } from './modules/event-planner/components/review-property-manager/review-property-manager.component';
import { EpReferralComponent } from './modules/auth/components/ep-referral/ep-referral.component';
import { PmReferralComponent } from './modules/auth/components/pm-referral/pm-referral.component';
import { AccessLevelGuard } from './modules/auth/guards/access-level.guard';
import { ForgotPassword } from './modules/auth/components/forgot-password/forgot-password.component';
import { ForgotPasswordSent } from './modules/auth/components/register/forgot-password-sent/forgot-password-sent.component';
import { ConfirmRegistrationResolver } from './modules/shared/resolvers/confirm-registration.resolver';
import { NotFoundComponent } from './modules/shared/components/not-found/not-found.component';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { LandingComponent } from './modules/event-planner/components/landing/landing.component';
import { PaymentSuccessComponent } from './modules/shared/components/payment-success/payment-success.component';
import { WorkshopEnquiryBooking } from './modules/event-planner/components/workshop-enquiry-booking/workshop-enquiry-booking.component';


// MESSAGING COMPONENTS

// import { Conversations } from './modules/shared/modules/messaging/components/conversations/conversations.component';
// import { MessageComposer } from './modules/shared/modules/messaging/components/message-composer/message-composer.component';
// import { MessageViewer } from './modules/shared/modules/messaging/components/message-viewer/message-viewer.component';


// ADMIN COMPONENTS

// import { AdminEditAboutComponent } from './modules/admin/components/admin-edit-about/admin-edit-about.component';
// import { AdminEditCareerComponent } from './modules/admin/components/admin-edit-career/admin-edit-career.component';
// import { AdminEditTermComponent } from './modules/admin/components/admin-edit-term/admin-edit-term.component';
// import { AdminFeedbackReviewComponent } from './modules/admin/components/admin-feedback-review/admin-feedback-review.component';
// import { AdminIndexComponent } from './modules/admin/components/admin-index/admin-index.component';
// import { AdminSpacePickerComponent } from './modules/admin/components/admin-space-picker/admin-space-picker.component';
// import { AdminEditNewsComponent } from './modules/admin/components/admin-edit-news/admin-edit-news.component';
// import { AdminEditFaqComponent } from './modules/admin/components/admin-edit-faq/admin-edit-faq.component';
// import { AdminNewsEditorComponent } from './modules/admin/components/admin-news-editor/admin-news-editor.component';
// import { AdminManageReviewsComponent } from './modules/admin/components/admin-manage-reviews/admin-manage-reviews.component';
// import { UsersListComponent } from './modules/admin/components/users-list/users-list.component';
// import { ConversationsAdmin } from './modules/admin/modules/messaging/components/admin-conversations/admin-conversations.component';
// import { AdminWorkshopPickerComponent } from './modules/admin/components/admin-experience-picker/admin-experience-picker.component';
// import { AdminEditWsTypeTagsComponent } from './modules/admin/components/admin-wstype-tags/admin-wstype-tags.component';
// import { AdminEditWsInspirationTagsComponent } from './modules/admin/components/admin-wsinspiration-tags/admin-wsinspiration-tags.component';
// import { AdminCleanupComponent } from './modules/admin/components/admin-cleanup/admin-cleanup.component';
// import { AdminEditPricingInfoComponent } from './modules/admin/components/admin-edit-pricing-info/admin-edit-pricing-info.component';
// import { AdminInvoiceStatusOverviewComponent } from './modules/admin/components/admin-invoice-status-overview/admin-invoice-status-overview.component';
// import { AdminEditCovidComponent } from './modules/admin/components/admin-edit-covid/admin-edit-covid.component';
// import { AdminCouponGenerator } from './modules/admin/components/admin-coupon-generator/admin-coupon-generator.component';
// import { AdminProperties } from './modules/admin/components/admin-properties/admin-properties.component';
// import { MyProfileAdmin } from './modules/admin/components/my-profile-admin/my-profile-admin.component';
// import { EditProfileAdmin } from './modules/admin/components/edit-profile-admin/edit-profile-admin.component';

// EVENT PLANNER COMPONENTS

// import { MyProfileEventPlanner } from './modules/event-planner/components/my-profile-event-planner/my-profile-event-planner.component';
// import { EditProfileEventPlanner } from './modules/event-planner/components/edit-profile-event-planner/edit-profile-event-planner.component';
// import { ReservationsComponent } from './modules/event-planner/components/reservations/reservations.component';
// import { ReferralPageEpComponent } from './modules/event-planner/components/referral-page-ep/referral-page-ep.component';
// import { EpReferralComponent } from './modules/event-planner/components/ep-referral/ep-referral.component';
// import { EventPlannerBillingSettingsComponent } from './modules/event-planner/components/event-planner-billing-settings/event-planner-billing-settings.component';

// PROPERTY MANAGER COMPONENTS

// import { CustomerDetails } from './modules/property-manager/components/customer-details/customer-details.component';
// import { ReferralPagePmComponent } from './modules/property-manager/components/referral-page-pm/referral-page-pm.component';
// import { PmReferralComponent } from './modules/property-manager/components/pm-referral/pm-referral.component';
// import { PropertyManagerBillingSettingsComponent } from './modules/property-manager/components/property-manager-billing-settings/property-manager-billing-settings.component';
// import { AddWorkshopForm } from './modules/property-manager/components/add-workshop/add-workshop.component';
// import { ReactivateAccountComponent } from './modules/property-manager/components/reactivate-account/reactivate-account.component';
// import { ModifyBookingReviewPageComponent } from './modules/property-manager/components/modify-booking-review-page/modify-booking-review-page.component';
// import { ModifyWsBookingReviewPageComponent } from './modules/property-manager/components/modify-ws-booking-review-page/modify-ws-booking-review-page.component';
// // import { CancelBookingPMPageComponent } from './modules/property-manager/components/cancel-booking-pm-page/cancel-booking-pm-page.component';
// import { CancelWSBookingPMPageComponent } from './modules/property-manager/components/cancel-ws-booking-pm-page/cancel-ws-booking-pm-page.component';
// import { CouponGenerator } from './modules/property-manager/components/pm-coupon-generator/pm-coupon-generator.component';
// import { BookingIndex } from './modules/property-manager/components/booking-index/booking-index.component';
// import { CalendarPage } from './modules/property-manager/components/calendar-page/calendar-page.component';
// import { AddPropertyForm } from './modules/property-manager/components/add-property/add-property.component';
// import { AddSpaceForm } from './modules/property-manager/components/add-space/add-space.component';
// import { SuccessPageAddSpace } from './modules/property-manager/components/success-page-add-space/success-page-add-space.component';
// import { CompleteRegistrationPropertyManager } from './modules/property-manager/components/complete-registration-property-manager/complete-registration-property-manager.component';
// import { MyProfilePropertyManager } from './modules/property-manager/components/my-profile-property-manager/my-profile-property-manager.component';
// import { EditProfilePropertyManager } from './modules/property-manager/components/edit-profile-property-manager/edit-profile-property-manager.component';
// import { MyProperties } from './modules/property-manager/components/my-properties/my-properties.component';



const routes: Routes = [

    // Lazy modules
    // {path: '', component: AppComponent},
    {path: '', redirectTo: 'index', pathMatch: 'full'},
    {path: 'landing', component: LandingComponent},

    { path: 'messages', loadChildren: () => import('./modules/messaging/messaging.module').then(m => m.MessagingModule)},
    { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule), canActivate: [AccessLevelGuard, AuthGuard], data: {expectedAccessLevel: 0}},
    { path: 'property-manager', loadChildren: () => import('./modules/property-manager/property-manager.module').then(m => m.PropertyManagerModule), canActivate: [AccessLevelGuard, AuthGuard], data: {expectedAccessLevel: 1}},
    { path: 'event-planner', loadChildren: () => import('./modules/event-planner/event-planner.module').then(m => m.EventPlannerModule), canActivate: [AccessLevelGuard, AuthGuard], data: {expectedAccessLevel: 2}},

    // AUTH ROUTES
    { path: 'login', component: Login },
    { path: 'forgot-password', component: ForgotPassword },
    { path: 'forgot-password-link-sent/:action/:email', component: ForgotPasswordSent },
    { path: 'register', component: Register },
    { path: 'reset-password/:token/:email', component: ChangePassword },
    { path: 'change-email/:token/:email/:new-email', component: ChangeEmailComponent },
    { path: 'verify-by-email/:action/:email', component: VerifyByEmail },
    { path: 'confirm-registration/:token/:email/:type', component: ConfirmRegistration, resolve: [ConfirmRegistrationResolver] },
    { path: 'register-common/:type', component: RegisterCommon },
    { path: 'edit-email', component: EditEmailComponent },
    { path: 'edit-password', component: EditPasswordComponent },
    { path: 'register-common/:type/:info', component: RegisterCommon, resolve: [ConfirmRegistrationResolver] },
    { path: 'register-common/:type/:email/:referral', component: RegisterCommon },
    { path: 'list-service', component:ListService },

    { path: 'ep-referral/:id/:email', component: EpReferralComponent },
    { path: 'pm-referral/:id/:email', component: PmReferralComponent },

    // COMMON ROUTES
    { path: 'index', component: IndexPagePlanner },
    { path: 'newsfeed', component: NewsfeedPage },
    { path: 'news-page', component: NewsPage },
    { path: 'about', component: AboutComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'faq/:type', component: FaqComponent },
    { path: 'pricing-info', component: PricingInfoComponent },
    { path: 'career', component: CareerComponent },
    { path: 'covid19', component: CovidComponent },
    { path: 'terms', component: TermsComponent },
    { path: 'feedback', component: FeedbackComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'customer-details', component: CustomerDetails },
    { path: 'account-settings', component: AccountSettingsComponent, canActivate: [AuthGuard] },
    { path: 'success-add-page/:pid/:sid', component: SuccessPageAddSpace, canActivate: [AuthGuard] },
    { path: 'entertainment',component:EntertainmentComponent },
    { path: 'pm-user-details/:id', component: PropertyManagerDetails },
    { path: 'ep-user-details/:id', component: CustomerDetails },

    // SPACE & PROPERTY ROUTES
    { path: 'add-property', component: AddPropertyForm, canActivate: [AuthGuard] },
    { path: 'property-details', component: PropertyDetails, canActivate: [AuthGuard] },
    { path: 'spaces', component: SpaceIndexPagePlanner },
    { path: 'add-space', component: AddSpaceForm },
    { path: 'add-entertainment/:id', component:AddEntertainmentComponent },
    { path: 'edit-entertainment', component:EditEntertainment },
    // { path: 'add-entertainment2', component:AddEntertainment4Component },
    // { path: 'add-entertainment3', component:AddEntertainment5Component },
    // { path: 'add-entertainment4', component:AddEntertainment6Component },
    {path: 'entertainment-details/:id', component: EntertainmentDetails},
    {path: 'entertainment-booking', component:EntertainmentBooking},
    {path: 'cart', component: EbentoCart},
    {path: 'cart/payment', component: CartPaymentComponent},
    {path: 'success', component: PaymentSuccessComponent},
    {path:'enquiry-booking/space', component: SpaceEnquiryBooking},
    {path:'enquiry-booking/experience', component: WorkshopEnquiryBooking},
    {path: 'enquiry-booking', component: EnquiryBookingComponent},
    {path: 'enquiry-booking/success', component: EnquirySuccessComponent},
    
    {path: 'modify/space/:cartId/:serviceId', component: ModifyCartSpace},
    {path: 'modify/experience/:cartId/:serviceId', component: ModifyCartExperience},             
    {path: 'modify/entertainment/:cartId/:serviceId', component: ModifyCartEntertainment},             
    { path: 'add-entertainment-preview/:id', component:PreviewComponent },
    { path: 'add-space', component: AddSpaceForm, canActivate: [AuthGuard] },
    { path: 'add-space/:id', component: AddSpaceForm, canActivate: [AuthGuard] },
    { path: 'space-details/:id', component: SpaceDetails },
    { path: 'space-listing/:id', component: SpaceListing, canActivateChild: [AccessLevelGuard], data: {expectedAccessLevel: 1} },
    { path: 'booking-details/:id', component: BookingDetails },
    { path: 'space-search-results', component: SpaceSearchResults },
    // WORKSHOPS ROUTES
    { path: 'add-experience', component: AddWorkshopForm, canActivate: [AuthGuard] },
    { path: 'add-experience/:id', component: AddWorkshopForm, canActivate: [AuthGuard] },
    { path: 'experiences', component: WorkshopIndexPagePlanner },
    { path: 'experience-details/:id', component: WorkshopDetails },
    { path: 'experience-listing/:id', component: WorkshopListing },
    { path: 'experience-booking-page', component: WorkshopBookingPage },
    { path: 'experience-booking-details/:id', component: WorkshopBookingDetails },
    { path: 'experience-search-results', component: WorkshopSearchResults },
    { path:'entertainment-search-results', component: EntertainmentSearchResult },
    { path: 'review-property-manager', component: ReviewPropertyManager },
    { path: 'review-booking/:id', component: ReviewPropertyManager },
    { path: 'review-experience-booking/:id', component: ReviewPropertyManager },
    {path: '404', component: NotFoundComponent},
    { path: '**', redirectTo: '404' }
    // ADMIN ROUTES

    // { path: 'admin-properties/:id', component: AdminProperties },
    // { path: 'my-profile-admin', component: MyProfileAdmin },
    // { path: 'edit-profile-admin', component: EditProfileAdmin },
    // { path: 'admin/all-users', component: UsersListComponent },
    // { path: 'admin-edit-about', component: AdminEditAboutComponent },
    // { path: 'admin-edit-career', component: AdminEditCareerComponent },
    // { path: 'admin-edit-covid19', component: AdminEditCovidComponent },
    // { path: 'admin-edit-term', component: AdminEditTermComponent },
    // { path: 'admin-feedback', component: AdminFeedbackReviewComponent },
    // { path: 'admin-index', component: AdminIndexComponent },
    // { path: 'admin-spaces', component: AdminSpacePickerComponent },
    // { path: 'admin-workshops', component: AdminWorkshopPickerComponent },
    // { path: 'admin-edit-news', component: AdminEditNewsComponent },
    // { path: 'admin-edit-faq', component: AdminEditFaqComponent },
    // { path: 'admin-edit-pricing-info', component: AdminEditPricingInfoComponent },
    // { path: 'admin-news-editor/create', component: AdminNewsEditorComponent },
    // { path: 'admin-news-editor/edit/:id', component: AdminNewsEditorComponent },
    // { path: 'admin-manage-reviews', component: AdminManageReviewsComponent },
    // { path: 'admin-invoice-status-overview', component: AdminInvoiceStatusOverviewComponent },
    // { path: 'admin-tags', component: AdminEditWsTypeTagsComponent },
    // { path: 'admin-inspiration-tags', component: AdminEditWsInspirationTagsComponent },
    // { path: 'admin-cleanup', component: AdminCleanupComponent },


    // EVENT PLANNER ROUTES

    // { path: 'my-reservations', component: ReservationsComponent },
    // { path: 'refer-event-planner', component: ReferralPageEpComponent },
    // { path: 'event-planner-billing-settings', component: EventPlannerBillingSettingsComponent },
    // { path: 'my-profile-eventplanner', component: MyProfileEventPlanner },
    // { path: 'edit-profile-event-planner', component: EditProfileEventPlanner },
    // { path: 'ep-referral/:id/:email', component: EpReferralComponent },
    // { path: 'experience-listing', redirectTo: 'experience-listing/' },

    // PROPERTY MANAGER ROUTES

    // { path: 'refer-property-manager', component: ReferralPagePmComponent },
    // { path: 'pm-referral/:id/:email', component: PmReferralComponent },
    // { path: 'property-manager-billing-settings', component: PropertyManagerBillingSettingsComponent },
    // { path: 'reactivate-account/:token/:email', component: ReactivateAccountComponent },
    // { path: 'modify-booking-review/:id', component: ModifyBookingReviewPageComponent },
    // { path: 'modify-experience-booking-review/:id', component: ModifyWsBookingReviewPageComponent },
    // { path: 'cancel-booking-pm-page/:id', component: CancelBookingPMPageComponent },
    // { path: 'cancel-ws-booking-pm-page/:id', component: CancelWSBookingPMPageComponent },
    // { path: 'coupon-generator', component: CouponGenerator },
    // { path: 'booking-index', component: BookingIndex },
    // { path: 'calendar-page', component: CalendarPage },
    // { path: 'add-property', component: AddPropertyForm },
    // { path: 'my-profile-propertymanager', component: MyProfilePropertyManager },
    // { path: 'edit-profile-property-manager', component: EditProfilePropertyManager },
    // { path: 'my-properties', component: MyProperties },
    // { path: 'add-workshop/:id', component: AddWorkshopForm },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule],
    providers: [{ provide: APP_BASE_HREF, useValue: '/' }]

})
export class AppRoutingModule { }
