import { Component } from '@angular/core';

@Component({
  selector: 'app-enquiry-success',
  templateUrl: './enquiry-success.component.html',
  styleUrls: ['./enquiry-success.component.scss']
})
export class EnquirySuccessComponent {

}
