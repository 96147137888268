import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { MessagingService } from 'app/modules/shared/services/messaging.service';

@Component({
    selector: 'property-manager-header',
    templateUrl: 'property-manager-ebento-header.component.html',
    styleUrls: ['../../../shared/css/ebento-header.css']
})

export class PropertyManagerHeader implements OnInit {

    active: string = 'listing';

    profileUrl: string;

    public SelectedLanguage = "EN";

    constructor(
        public authService: AuthService,
        private router: Router,
        private translate: TranslateService,
        public messagingService: MessagingService
    ) { }

    ngOnInit(): void {
        this.getScreenSize();
        // ne ovako???
        //this.authService.getUserInfo().subscribe(
        //    () => this.GetUserName()
        //);
    }

    selectTab (value) {
        this.active = value;
    }

    //TODO: refactor/put this functionality somewhere else
    onLogout() {
        this.authService.logout().subscribe(
            () => this.router.navigate(["/index"]),
            () => this.router.navigate(["/login"])
        );
    }

    public isLoggedIn(): boolean {
        this.profileUrl = this.authService.getUserAvatarURL();
        this.profileUrl = this.profileUrl;
        return this.authService.isLoggedIn();
    }

    public IsProfileComplete(): boolean {
        return this.authService.isLoggedIn() && this.authService.user && this.authService.user.isProfileCompleted;
    }

    public GetUserName(): string {
        if (this.authService.user) {
            if (this.authService.user.firstName && this.authService.user.lastName)
                return this.authService.user.firstName + " " + this.authService.user.lastName;
            return this.authService.user.email;
        }
        return '';
    }

    public redirectToProfilePage() {
        let route = "/property-manager/edit-profile";
        this.router.navigate([route]);
    }

    public redirectToIndex() {
        this.router.navigate(["/property-manager/booking"]);
    }

    public redirectToCalendar() {
        this.router.navigate(["/property-manager/calendar"]);
    }

    public redirectToCouponGenerator() {
        this.router.navigate(["/property-manager/coupon-generator"]);
    }
    
    public redirectToMyproperties() {
        this.router.navigate(["/property-manager/properties"]);
    }

    public redirectToBillingPage() {
        this.router.navigate(["/property-manager/billing"]);
    }

    public SetLanguage(val: string) {
        this.SelectedLanguage = val;
        this.translate.use(this.SelectedLanguage);
    }

    scrHeight: any;
    scrWidth: any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
    }
}