import { OnInit, Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
    selector: 'success-page-add-space',
    templateUrl: "success-page-add-space.component.html"
})
export class SuccessPageAddSpace implements OnInit {

    public spaceId: number;
    public propertyId: number;

    constructor(
        private router: Router,
        public route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.propertyId = this.route.snapshot.params.pid;
        this.spaceId = this.route.snapshot.params.sid;
    }

    redirectToNewListing(): void {
        let url = "/space-details/" + this.spaceId;
        this.router.navigate([url]);
    }

    redirectToAddListing(): void {
        let url = "/add-space/" + this.propertyId;
        this.router.navigate([url]);
    }

    redirectToMyProperties(): void {
        this.router.navigate(['/property-manager/properties']);
    }

}