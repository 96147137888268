import { Component, HostListener, Input, ViewChild} from '@angular/core';
import { Entertainment } from '../../models/entertainment/AddEntertainmentStep1';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router'

@Component({
    selector: 'entertainment-space-list',
    templateUrl: 'entertainment-space-list.component.html',
    styleUrls: ['./entertainment-space-list.component.css']
})

export class EntertainmentSpaceList{
    
    @ViewChild('carousel', { read: NgbCarousel }) carousel: NgbCarousel;
    @Input() entertainmentData:Entertainment[];
    @Input() headerLabel:string;
    @Input() color:string;

    scrHeight: any;
    scrWidth: any;

    constructor(private router:Router){
        this.getScreenSize();
    }

    public GetNplet(index: number, n = 3) {
        var result = new Array();
        var i = 0;
        while (i < n && i + index < this.entertainmentData.length) {
            var item = this.entertainmentData[i + index];
            result.push(item);
            i++;
        }
        return result;
    }

    public numbersOfNplets(n = 3): number[]
    {
        if (this.entertainmentData == null)
            return null;
        var non = new Array();
        for (var i = 0; i < Math.ceil(this.entertainmentData.length / n); i++) {
            non.push(i);
        }
        
        return non;
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
       // console.log(this.scrHeight, this.scrWidth);
    }
    
    getColumnCount()
    {
        return (this.scrWidth >= 992) ? 3 : (this.scrWidth >= 773) ? 2 : 1;
    }

    public swipeLeft()
    {
        this.carousel.next();
    }

    public swipeRight() {
          this.carousel.prev();
    }


    public Redirect(id:number)
    {
        console.log('working redirect',id);
        
        this.router.navigate(['entertainment-details/' + id]);
    }

}