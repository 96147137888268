import { Component, OnInit, Input } from '@angular/core';

import { Router } from "@angular/router";
import { EbentoProperty } from '../../models/properties/ebento-property';
import { WorkshopTile } from '../../models/search/workshop-tile';
import { WorkshopService } from '../../services/workshop.service';

@Component({
    selector: 'expandable-property-details-card',
    templateUrl: 'expandable-property-details-card.component.html',
    styleUrls: ['expandable-property-details-card.component.scss']
})

export class ExpandablePropertyDetailsCard {

    @Input() property: EbentoProperty
    @Input() expandable: boolean
    expanded: boolean
    workshops: WorkshopTile[]

    constructor(public workshopService: WorkshopService) { }

    ngOnInit() {
        this.workshopService.getAllWorkshopForProperty(this.property.id).subscribe(
            (result) => { this.workshops = result; });
    }

    ToggleExpand()
    {
        this.expanded = !this.expanded;
    }

    spacesToShow() {
        return this.property.spaces.filter(s => !s.isDummyForWorkshops);
    }
}
