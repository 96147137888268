import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { EntertainmentCarousel } from 'app/modules/event-planner/components/entertainment-carousel/entertainment-carousel.component';
import { EbentoProperty } from '../../models/properties/ebento-property';
import { AddEntertainmentService } from 'app/modules/property-manager/services/add-entertainment.service';
import { IndexCarousel } from 'app/modules/event-planner/components/carousel/index-carousel/index-carousel.component';
import { PropertyService } from '../../services/property.service';
import { WorkshopCarouselTile } from 'app/modules/event-planner/components/carousel/carousel-tile/workshop-carousel-tile/workshop-carousel-tile.component';
import { WorkshopTile } from '../../models/search/workshop-tile';
import { EntertainmentHomePage } from '../entertainment-home-page/entertainment-home-page.component';

@Component({
  selector: 'app-property-details-entertainment-card',
  templateUrl: './property-details-entertainment-card.component.html',
  styleUrls: ['./property-details-entertainment-card.component.scss']
})
export class PropertyDetailsEntertainmentCardComponent extends IndexCarousel implements OnInit {
  @Input() property: EbentoProperty;
  @Input() headerLabel: 'Entertainment';
  // workshops: WorkshopTile[];
  public categoryData
  
  protected tileComponent = EntertainmentHomePage;

  constructor(
    public addEntertainmentService: AddEntertainmentService,
    public propertySerice: PropertyService,
    injector: Injector
  ) {
    super(injector)
  }

  ngOnInit(): void {
    this.addEntertainmentService.getEntertainmentOfProperty(this.property.id).subscribe((response) => {
      this.categoryData = response;
    })
  }

  // get apiGetter() {
  //   return this.addEntertainmentService.getEntertainmentOfProperty(138);
  // }

}
