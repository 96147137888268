import { TimeSpan } from "../data-classes/time-span";
import { TimeAllocation } from "../data-classes/time-allocation";

//TODO: REFACTOR! -- constructor, multiple classes, static method, don't see the point of BookingDTO

export class BookingSegment {

    constructor(public startTime: Date, public duration: TimeSpan, public name: string = "", public bookingID:number=-1) { }

    public GetDateString(): string {
        return this.startTime.toLocaleDateString();
        // const yyyy = this.startTime.getFullYear();
        // let mm = this.startTime.getMonth() + 1; // Months start at 0!
        // let dd = this.startTime.getDate();
        // console.log("dd/mm/yyyy", dd, mm, yyyy);
        // return `${dd}/${mm}/${yyyy}`
    }
    public GetTimeString(): string {
        var startTime = new TimeSpan(0, this.startTime.getHours(), this.startTime.getMinutes(), 0);
        var endTime = new TimeSpan(0, this.startTime.getHours() + this.duration.Hours, this.startTime.getMinutes() + this.duration.Minutes, 0);
        return startTime.HoursMinutesString + " - " + endTime.HoursMinutesString;
    }

    public static BookingFromDTO(dto: BookingDTO): BookingSegment
    {
        var timespan = new TimeSpan(0, 0, 0, 0);
        timespan.totalseconds = dto.durationTotalSeconds;
        return new BookingSegment(new Date(dto.startTimeString), timespan, dto.name, dto.bookingID);
    }

    public static FromTimeAllocation(allocation: TimeAllocation): BookingSegment
    {
        var startDate = new Date(allocation.startTime);
        var endDate = new Date(allocation.endTime);

        var timespan = new TimeSpan(0, 0, 0, 0);
        timespan.totalseconds = (endDate.getTime() - startDate.getTime()) / 1000;
        return new BookingSegment(startDate, timespan);
    }
}

export class BookingDTO {

    constructor(
        public startTimeString: string,
        public durationTotalSeconds: number,
        public name: string = "",
        public bookingID: number)
    {
    }
}
