import { Component, Input, OnInit} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { ManagerBookingService } from "../../services/manager-booking.service";

@Component({
    selector: 'booker-planner-modal',
    templateUrl: "booker-planner-modal.component.html"
})
export class BookerPlannerModal implements OnInit {

    constructor(private activeModal: NgbActiveModal, public managerBookingService: ManagerBookingService, private router: Router
    ) { }

    @Input() plannerID: string;

    fields = [
        { label: 'First Name', fieldname: 'firstName' },
        { label: 'Last Name', fieldname: 'lastName' },
        { label: 'Email', fieldname: 'email' },
        { label: 'Phone', fieldname: 'phoneNumber' },
        { label: 'Location', fieldname: 'workLocation' } //was City, but we only have workLocation in User class and editor?
        //{ label: 'Postal/Zip', fieldname: 'firstName' },
        //{ label: 'Country', fieldname: 'firstName' },
        //{ label: 'Region', fieldname: 'firstName' },
        //{ label: 'Address', fieldname: 'firstName' },
    ];

    ngOnInit()
    {
        this.managerBookingService.getEventPlannerInfo(this.plannerID).subscribe();
    }

    MoreInfo()
    {
        this.activeModal.close('Close click');
        this.router.navigate(['customer-details']);
    }

    Close()
    {
        this.activeModal.close('Close click');
    }
}