import { SpaceBooking } from "../booking/space-booking";
import { EventPlanner } from "./event-planner";
import { PMReviewReport } from "./pm-review-report";
import { WorkshopBooking } from "../booking/workshop-booking";

export class PropertyManagerReview {

    public idReview: number;
    public booking: SpaceBooking;
    public workshopBooking: WorkshopBooking;
    public reviewer: EventPlanner;
    public propertyManagerID: string;
    public locationScore: number = 1;
    public cateringScore: number = 1;
    public amenitiesScore: number = 1;
    public overallScore: number = 1;
    public comment: string;
    public timestamp: Date;
    public reports: PMReviewReport[];

    constructor() { }
}