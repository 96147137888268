import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AgmMap, MouseEvent } from '@agm/core';
import { GmapMarker } from '../../models/misc/gmap-marker';

@Component({
  selector: 'ebento-gmap',
  templateUrl: './ebento-gmap.component.html',
  styleUrls: ['./ebento-gmap.component.scss']
})

export class EbentoGmapComponent implements OnInit, OnChanges, AfterViewInit {

	@ViewChild('gmap') gmap: AgmMap;
	//public static_markers: marker[];
	
	// google maps zoom level
	@Input() zoom: number = 15;

    // initial center position for the map
	@Input() lat: number =47.3769;
	@Input() lng: number = 8.5427;
	@Input() markers: GmapMarker[];

	toUpdate: boolean;
	ready: boolean;

	constructor() { }

    ngOnInit(): void {
		//console.log("YO!");
    }

	ngOnChanges(chanes: SimpleChanges)
	{
		//console.log("CHanged");
		this.SetupMap();
	}

	ngAfterViewInit()
	{
		if (this.toUpdate)
		{
			//console.log(">>>!" + this.markers.length);
			this.FitMarkers();
		}
		this.ready = true;
	}

	SetupMap()
	{
		if (!this.ready)
			this.toUpdate = true;
		else
		{
			//console.log(">>>!" + this.markers.length);
			this.FitMarkers();
		}
		
	}

	FitMarkers()
	{
	/*if (this.markers.length == 0) {
			this.map.setCenter(this.searchService.searchPlaceAutocompleteLatLng);
		}
		else {

			var bounds = new google.maps.LatLngBounds();
			for (var i = 0; i < markers.length; i++) {
				bounds.extend(markers[i].getPosition());
			}

			this.gmap.fitBounds(bounds);
		}*/
		this.gmap.triggerResize(true);
    }

	clickedMarker(label: string, index: number) {
		console.log(`clicked the marker: ${label || index}`)
	}

	mapClicked($event: MouseEvent) {
		/*this.markers.push({
			lat: $event.coords.lat,
			lng: $event.coords.lng,
			draggable: true
		});*/
	}

	markerDragEnd(m: GmapMarker, $event: MouseEvent) {
		console.log('dragEnd', m, $event);
	}

	/*markers: marker[] = [
		{
			lat: 51.673858,
			lng: 7.815982,
			label: 'A',
			draggable: true
		},
		{
			lat: 51.373858,
			lng: 7.215982,
			label: 'B',
			draggable: false
		},
		{
			lat: 51.723858,
			lng: 7.895982,
			label: 'C',
			draggable: true
		}
	]*/
}

// just an interface for type safety.
