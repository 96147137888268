import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ReferralSubmissionEP } from "../models/referral/referral-submission-ep";
import { ReferralSubmissionPM } from "../models/referral/referral-submission-pm";

@Injectable()
export class ReferralService {

    constructor(private http: HttpClient) {
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    sendEPReferral(referral: ReferralSubmissionEP): Observable<boolean>
    {
        return this.http.post<boolean>(
            "api/sendEPReferral", referral)
            .pipe(
                map(success => {
                    return success;
                }),
                catchError(this.errorHandler)
            );
    }

    sendPMReferral(referral: ReferralSubmissionPM): Observable<boolean> {
        return this.http.post<boolean>(
            "api/sendPMReferral", referral)
            .pipe(
                map(success => {
                    return success;
                }),
                catchError(this.errorHandler)
            );
    }


    checkID(referralID: string, email:string): Observable<boolean>
    {
        return this.http.get<boolean>(
            "api/checkEPReferral/" + referralID+"/"+email)
            .pipe(
                map(success => {
                    return success;
                }),
                catchError(this.errorHandler)
            );
    }
}