import { HttpClient, HttpErrorResponse} from "@angular/common/http";
import { HttpHeaders } from '@angular/common/http';

import { Injectable } from "@angular/core";
import { map, catchError, switchMap } from "rxjs/operators";

import { Observable } from "rxjs";
import { throwError } from 'rxjs';
import { AdminSpaceListingItem } from "../models/admin-space-listing-item";
import { AdminSpaceFilter } from "../models/admin-space-filter";
import { ListingActivePeriod } from "../../shared/models/billing/listing-active-period";


@Injectable()
export class AdminSpaceService {

    public spaces: AdminSpaceListingItem[]
    public locations: string[]
    public emails: string[]
    
    constructor(
        private http: HttpClient) {
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    public getSpaceActivity(spaceID): Observable<ListingActivePeriod[]> {
        return this.http.get<ListingActivePeriod[]>("api/getSpaceLAPs/" + spaceID)
            .pipe(
                map(result => { console.log(result); return result; }),
                catchError(this.errorHandler)
            );
    }

    public getAllSpaces(): Observable<AdminSpaceListingItem[]> {
        return this.http.get<AdminSpaceListingItem[]>("api/adminAllSpaces")
            .pipe(
                map(result => this.spaces = result),
                catchError(this.errorHandler)
            );
    }

    public getLocations(): Observable<string[]> {
        return this.http.get<string[]>("api/adminAllLocations")
            .pipe(
                map(result => this.locations = result),
                catchError(this.errorHandler)
            );
    }

    public getEmails(): Observable<string[]> {
        return this.http.get<string[]>("api/adminAllEmails")
            .pipe(
                map(result => this.emails = result),
                catchError(this.errorHandler)
            );
    }

    public getFilteredSpaces(filter: AdminSpaceFilter): Observable<AdminSpaceListingItem[]> {
        return this.http.post<AdminSpaceListingItem[]>("api/adminFilteredSpaces",filter)
            .pipe(
                map(result => this.spaces = result),
                catchError(this.errorHandler)
            );
    }

    setSpaceUnique(id: number, state: boolean): Observable<boolean> {
        return this.http.post<boolean>("api/setSpaceUnique/" + id + "/" + state, null)
            .pipe(
                map(result => {
                    return result;
                },
                    catchError(this.errorHandler)
                ));
    }

    setSpacePerfect(id: number, state: boolean): Observable<boolean> {
        return this.http.post<boolean>("api/setSpacePerfect/" + id + "/" + state, null)
            .pipe(
                map(result => {
                    return result;
                },
                    catchError(this.errorHandler)
                ));
    }

}