import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FavoritesService } from '../../services/favorites.service';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'favorite-space-button',
  templateUrl: './favorite-space-button.component.html',
  styleUrls: ['./favorite-space-button.component.scss']
})
export class FavoriteSpaceButton implements OnInit, OnDestroy {
    // fa
    faHeart = faHeart;

    @Input() spaceID : number
    @Input() isListing : boolean = false;
    //currentState: boolean = true;

    constructor(public favoritesService: FavoritesService, public authService: AuthService) {
      
    }

    get currentState(): boolean
    {
        if (this.favoritesService.myFavoriteSpaces == null)
            return false;
        return this.favoritesService.myFavoriteSpaces.indexOf(this.spaceID) > -1;
    }

    
    changeState(event)
    {
        event.stopPropagation();
        this.favoritesService.setSpaceFavoriteStatus(!this.currentState, this.spaceID).subscribe();
    }

    ngOnInit() {

      /* if (this.favoritesService._myFavoriteSpaces != null)
            this.currentState = this.favoritesService._myFavoriteSpaces.indexOf(this.spaceID) > -1;
        this.favoritesService.myFavoriteSpaces.subscribe(
            (result) => {
                console.log(this.me + " Ola!! " + this.spaceID + "): " + (result.indexOf(this.spaceID) > -1));
                this.currentState = result.indexOf(this.spaceID) > -1;
                return result;
            }
        );*/
    }

    ngOnDestroy() {
     //   this.favoritesService.myFavoriteSpaces.unsubscribe();
    }

}

