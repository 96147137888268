import { Component, OnInit, Input } from '@angular/core';
import { SpaceBooking } from 'app/modules/shared/models/booking/space-booking';
import { BookingAmenity } from 'app/modules/shared/models/booking/booking-amenity';
import { SpaceService } from 'app/modules/shared/services/space.service';

@Component({
  selector: 'booking-overview-panel',
  templateUrl: './booking-overview-panel.component.html',
  styleUrls: ['./booking-overview-panel.component.scss']
})
export class BookingOverviewPanelComponent implements OnInit {

    @Input() booking: SpaceBooking;
    @Input() originalPrice: number;
    @Input() showPriceDiff: boolean;
    constructor(public spaceService: SpaceService)
    {
    }

    ngOnInit() {
        console.log(">>> " + this.booking.price);

        console.log(">2>2> " + this.originalPrice);
    }

    // copied from booking-table
    timeAllocationsToText(): string {
        var result = "";

        this.booking.timeAllocations.sort(function (a, b) { var date1 = new Date(a.startTime); var date2 = new Date(b.startTime); if (date1 < date2) return -1; if (date1 > date2) return 1; return 0; });
        for (let allocation of this.booking.timeAllocations) {
            var dateStart = new Date(allocation.startTime);
            var dateEnd = new Date(allocation.endTime);
            result += "<b>" + dateStart.toLocaleDateString() + "</b> <br/> " + "    " + dateStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + " - " + dateEnd.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + " <br/> ";
        }
        return result;
    }

    getAmenityName(amenity: BookingAmenity): string
    {
        return this.spaceService.GetAmenityTypeByID(amenity.amenityTypeID).name;
    }

    getAmenityInfo(amenity: BookingAmenity): string {        
        return "x " + amenity.quantity + ((amenity.unitPrice > 0) ? " (CHF " + amenity.unitPrice+")" : " (Free)");
    }

    public PriceDifference(): string {
        var result = Math.round((this.booking.price.totalWithDiscount - this.originalPrice) * 100) / 100;
        var sign = (result < 0) ? "- CHF" : "CHF"
        return sign + " " + Math.abs(result);
    }
}
