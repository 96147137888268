import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  OnDestroy,
  DoCheck,
  ViewChild,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import { WorkingHours } from "../../models/properties/working-hours";
import { BookingSegment } from "../../models/booking/booking-segment";
import { SpaceService } from "../../services/space.service";
import { SpaceBookingService } from "../../services/space-booking.service";
import { TimeSpan } from "../../models/data-classes/time-span";
import { concat } from "rxjs";
import { WorkshopTimeAllocation } from "../../models/data-classes/workshop-time-allocation";
import { TimeAllocation } from "../../models/data-classes/time-allocation";
import { listeners } from "cluster";
import { EbentoWorkshop } from "../../models/workshops/ebento-workshop";
import { WorkshopBookingService } from "../../services/workshop-booking.service";
import { WsBookingTimeAllocation } from "../../models/data-classes/ws-booking-time-allocation";
import { time } from "console";
import { type } from "os";

@Component({
  selector: "draggable-calendar-view",
  templateUrl: "draggable-calendar-view.component.html",
  styleUrls: ["draggable-calendar-view.component.scss"],
})
export class DraggableCalendarView
  implements AfterViewInit, OnInit, OnChanges, DoCheck, OnDestroy
{
  @ViewChild("table") tableRef;
  @Input() preloadTimeslots: WorkshopTimeAllocation[];
  @Input() getBookingsOnInit: boolean = true;
  @Input() isDayCalendar: boolean;
  @Input() calendarMode: string;
  @Input() isWorkingTimeSetup: boolean;
  @Input() useSpaceWorkngHours: boolean;
  @Input() markWorkingHours: boolean;
  @Input() workingHours: WorkingHours;
  @Input() spaceId: number;
  @Input() starttime: number = 12;
  @Input() eventDuration: number = 4; // number of half hour steps

  draggables: Array<Draggable>;

  // this can likely be improved, but it works.
  //moved to draggable-calendar as singleton, to optimize event subscription
  //(major performance drop if listeners are added to draggable cards)

  lastCellX = -1;
  lastCellY = -1;
  mouseUpResponder;
  mouseMoveResponder;
  touchEndResponder;
  maxDragDist = 20;
  dragStartX = 0;
  dragStartY = 0;
  scrolling = false;
  startTouchDist = 0;
  scrollStartTop = 0;
  scrollPending = false;
  singleRowHeight = "";

  mouseX: number;
  mouseY: number;
  dragStartMouseX: number;
  dragStartMouseY: number;
  currentDragged: Draggable;

  currentSelectedTimeAllocation: WsBookingTimeAllocation;

  // single "draggable" Timeslot for customTime
  customTimeAllocation: WorkshopTimeAllocation;
  // single custom draggableRef
  customDraggable: Draggable;

  startScrollPosSet: boolean;
  // "draggable" Timeslots
  workshopTimeAllocations: Array<WorkshopTimeAllocation>;

  // we need this for calendar overlaps ?
  bookingSegments: Array<Array<BookingSegment>>;

  dayIndex = 0;
  mousedown = false;
  numbers = [1, 2, 3, 4, 5, 6];
  mondayDate: Date;
  weekdaysAbrev = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  weekdaysLetters = ["M", "T", "W", "T", "F", "S", "S"];
  weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  monthAbbrevs = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  public weekAvailableTime = [
    {
      Enter_DayName: "Monday",
      Enter_FromTime: "09:00",
      Enter_ToTime: "21:00",
      flag: false,
    },
    {
      Enter_DayName: "Tuesday",
      Enter_FromTime: "09:00",
      Enter_ToTime: "21:00",
      flag: false,
    },
    {
      Enter_DayName: "Wednesday",
      Enter_FromTime: "09:00",
      Enter_ToTime: "21:00",
      flag: false,
    },
    {
      Enter_DayName: "Thursday",
      Enter_FromTime: "09:00",
      Enter_ToTime: "21:00",
      flag: false,
    },
    {
      Enter_DayName: "Friday",
      Enter_FromTime: "09:00",
      Enter_ToTime: "21:00",
      flag: false,
    },
    {
      Enter_DayName: "Saturday",
      Enter_FromTime: "09:00",
      Enter_ToTime: "21:00",
      flag: false,
    },
    {
      Enter_DayName: "Sunday",
      Enter_FromTime: "09:00",
      Enter_ToTime: "21:00",
      flag: false,
    },
  ];
  times: Array<string>;
  fulldayToggles: Array<boolean>;

  status;
  lastClickTime = 0;
  lastClickDay = 0;
  fillvalue = false;
  env;

  constructor(
    public spaceService: SpaceService,
    public bookingService: SpaceBookingService,
    public workshopBookingService: WorkshopBookingService
  ) {}

  scrollable;
  tabela;
  rowHeight = 10;
  rowWidth = 10;
  headWidth: string;

  GetHeadWidth(): void {
    if (this.scrollable != null) {
      var newHeadWidth = this.scrollable.clientWidth + "px";

      if (this.headWidth != newHeadWidth) this.headWidth = newHeadWidth;
    }
  }

  GetRowHeight(): void {
    if (this.tabela != null) {
      var tableHeight = this.tabela.offsetHeight;
      var totalRowInTable = this.times.length - this.starttime;

      var trTagHeight = tableHeight / totalRowInTable;
      if (trTagHeight != this.rowHeight) this.rowHeight = trTagHeight;
    }
  }

  GetRowWidth(): void {
    if (this.tabela != null) {
      var tableWidth = this.tabela.offsetWidth;
      var trTagWidth = (0.92 * tableWidth) / 7;
      if (trTagWidth != this.rowWidth) this.rowWidth = trTagWidth;
    }
  }

  AddToAllocatedTime(i: number) {
    const slot = this.weekAvailableTime[i];
    let state = slot.flag;

    console.log("slot state",slot,state);
    if (state) {
      const [starthour, startminutes] = slot.Enter_FromTime.split(":");
      const [endhour, endminutes] = slot.Enter_ToTime.split(":");

      let sDay;
      let eDay;
      if (startminutes === "30") {
        sDay = Number(starthour) * 2 + 1;
      } else if (startminutes === "00") {
        sDay = Number(starthour) * 2;
      }

      if (endminutes === "30") {
        eDay = 46 - (Number(endhour) * 2 + 1);
      } else if (endminutes === "00") {
        eDay = 46 - Number(endhour) * 2;
      }
      this.weekAvailableTime[i].flag =false; 

      if(eDay < 0) {
        eDay = 0
      }
      
      console.log("sday eday", sDay, eDay);

      let j=i;
      for (let day = sDay; day < this.status[j].length - eDay; day++) {
        this.status[j][day] = 3;
      }
    } else {
      console.log(
        "date to",
        new Date(slot.Enter_FromTime),
        new Date(slot.Enter_ToTime)
      );

      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();

      const [starthour, startminutes] = slot.Enter_FromTime.split(":");
      const [endhour, endminutes] = slot.Enter_ToTime.split(":");

      let sDay;
      let eDay;
      if (startminutes === "30") {
        sDay = Number(starthour) * 2 + 1;
      } else if (startminutes === "00") {
        sDay = Number(starthour) * 2;
      }

      if (endminutes === "30") {
        eDay = 46 - (Number(endhour) * 2 + 1);
      } else if (endminutes === "00") {
        eDay = 46 - Number(endhour) * 2;
      }

      this.weekAvailableTime[i].flag =true; 

      let j = i;

      for (let day = sDay; day < this.status[j].length - eDay; day++) {
        this.status[j][day] = 0;
      }
    }
  }

  Increase(i: number, type: string) {
    if(this.weekAvailableTime[i].flag){
        this.weekAvailableTime[i][type] = this.returnNewTime(
          this.weekAvailableTime[i][type],
          "add"
        );
        const slot = this.weekAvailableTime[i];
        const [starthour, startminutes] = slot.Enter_FromTime.split(":");
        const [endhour, endminutes] = slot.Enter_ToTime.split(":");
        this.resetSlots(i);
        let sDay;
        let eDay;
        if (startminutes === "30") {
          sDay = Number(starthour) * 2 + 1;
        } else if (startminutes === "00") {
          sDay = Number(starthour) * 2;
        }
    
        if (endminutes === "30") {
          eDay = 46 - (Number(endhour) * 2 + 1);
        } else if (endminutes === "00") {
          eDay = 46 - Number(endhour) * 2;
        }

        if(eDay < 0) {
          eDay = 0
        }
    
        console.log("sday eday", sDay, eDay);
    
        let j = i;
        for (let day = sDay; day < this.status[j].length - eDay; day++) {
          this.status[j][day] = 0;
        }
    }

    console.log("statussss", this.status);
  }

  Decrease(i: number, type: string) {
    if(this.weekAvailableTime[i].flag){
        this.weekAvailableTime[i][type] = this.returnNewTime(
          this.weekAvailableTime[i][type],
          "reduce"
        );
        const slot = this.weekAvailableTime[i];
        const [starthour, startminutes] = slot.Enter_FromTime.split(":");
        const [endhour, endminutes] = slot.Enter_ToTime.split(":");
        this.resetSlots(i);
    
        let sDay;
        let eDay;
        if (startminutes === "30") {
          sDay = Number(starthour) * 2 + 1;
        } else if (startminutes === "00") {
          sDay = Number(starthour) * 2;
        }
    
        if (endminutes === "30") {
          eDay = 46 - (Number(endhour) * 2 + 1);
        } else if (endminutes === "00") {
          eDay = 46 - Number(endhour) * 2;
        }

        if(eDay < 0) {
          eDay = 0;
        }

        console.log("decrement", sDay, eDay);
    
        let j = i;
    
        for (let day = sDay; day < this.status[j].length - eDay; day++) {
          this.status[j][day] = 0;
        }
    }
  }

  resetSlots(d) {
      for (var t = 0; t < 46; t++) {
        this.status[d][t] = 3;
      }
  }

  returnNewTime(oldTime: string, operation: string): string {
    let [first, second] = oldTime.split(":");
    if(first !== '24' || operation !== 'add') {
      if (operation === "add") {
        if (second === "30") {
          second = "00";
          first = String(Number(first) + 1);
        } else if (second === "00") {
          second = "30";
        }
      } else if (operation === "reduce") {
        if (second === "30") {
          second = "00";
        } else if (second === "00") {
          second = "30";
          first = String(Number(first) - 1);
        }
      }
      
    }
    return `${first}:${second}`;
  }

  @HostListener("window:resize", ["$event"])
  getEnv() {
    this.env = this.findBootstrapEnvironment();
    this.singleRowHeight = "2rem";
    if (this.env == "xs" || this.env == "sm" || this.env == "md")
      this.singleRowHeight = "4rem";
  }

  ngAfterViewInit() {
    this.getEnv();

    this.tableRef.nativeElement.addEventListener(
      "mousemove",
      this.mouseMoveResponder,
      { passive: true, capture: true }
    );
    this.tableRef.nativeElement.addEventListener(
      "touchmove",
      this.mouseMoveResponder,
      { passive: true, capture: true }
    );
  }

  ngDoCheck() {
    this.GetHeadWidth();
    this.GetRowHeight();
    this.GetRowWidth();
  }

  isAvailable(dayIndex: number, timeIndex: number, duration: number): boolean {
    if (this.GetDateForSlot(dayIndex, timeIndex) < this.GetDateToday() && this.calendarMode !== 'add-workshop-wizard' )
      return false;
    var available = true;
    for (
      var i = 0;
      i < duration && timeIndex + i < this.status[dayIndex].length;
      i++ // treba me udaviti [L]
    ) {
      available = available && !this.status[dayIndex][timeIndex + i];
    }
    return available;
  }

  ngOnInit(): void {
    this.fulldayToggles = Array<boolean>();
    for (var b = 0; b < 7; b++) {
      this.fulldayToggles.push(false);
    }

    if (this.workshopTimeAllocations == null)
      this.workshopTimeAllocations = new Array<WorkshopTimeAllocation>();

    this.times = new Array<string>();
    for (var i = 0; i < 23; i++) {
      var hour = i.toString();
      if (i < 10) hour = "0" + hour;
      this.times.push(hour + ":00");
      this.times.push(hour + ":30");
    }

    this.mouseMoveResponder = (e) => {
      /*var recto = e.target.getBoundingClientRect();
            console.log(recto);*/
      // console.log(rect);
      var target;
      if (e.center == null) {
        if (e.touches != null) {
          target = document.elementFromPoint(
            e.touches[0].clientX,
            e.touches[0].clientY
          ).id;
          this.startTouchDist = Math.max(
            this.startTouchDist,
            Math.abs(e.touches[0].clientY - this.dragStartY)
          );
          if (this.scrollPending && this.startTouchDist > this.maxDragDist) {
            this.scrolling = true;
            this.scrollPending = false;
          }
          if (this.scrolling) {
            //console.log("$$$$$$" + (e.touches[0].clientY - this.dragStartY));
            this.tableRef.nativeElement.scrollTop =
              this.scrollStartTop - (e.touches[0].clientY - this.dragStartY);
          }
        }
      }
      if (e.touches == null) {
        this.mouseX = e.clientX;
        this.mouseY = e.clientY;
      } else {
        this.mouseX = e.touches[0].pageX;
        this.mouseY = e.touches[0].pageY;
      }
    };

    this.mouseUpResponder = () => {
      this.mousedown = false;
      if (this.currentDragged != null) {
        this.currentDragged.endDrag();
      }
    };

    this.touchEndResponder = (ev) => {
      if (this.scrollPending) {
        this.MouseDownTable(ev);
      } else {
        this.mouseUpResponder();
      }
      this.scrolling = false;
      this.scrollPending = false;
      this.mousedown = false;
    };

    document.body.addEventListener("mouseup", this.mouseUpResponder);
    document.body.addEventListener("touchend", this.touchEndResponder);

    this.mondayDate = new Date();
    var day = this.mondayDate.getDay();

    day--;
    if (day < 0) day = 6;
    this.dayIndex = day;
    this.mondayDate.setDate(this.mondayDate.getDate() - day);
    this.mondayDate.setHours(0, 0, 0);

    this.Initialize();

    this.draggables = new Array<Draggable>();
    if (this.getBookingsOnInit) this.GetBookings();
    if (this.useSpaceWorkngHours) this.GetWorkingHours();

    if (
      this.calendarMode == "booking-modal" ||
      this.calendarMode == "custom-booking-modal"
    ) {
      this.SetTimeAllocations(
        this.workshopBookingService.workshop.availableTimeslots
      );
      this.SetSelectedTimeAllocation(
        this.workshopBookingService.bookingTimeslot
      );
    }

    if (this.preloadTimeslots != null)
      this.SetTimeAllocations(this.preloadTimeslots);
    this.scrollable = document.getElementById("scrollable");
    this.tabela = document.getElementById("tabela");
  }

  ngOnDestroy(): void {
    document.body.removeEventListener("mouseup", this.mouseUpResponder);
    document.body.removeEventListener("mousemove", this.mouseMoveResponder);
  }

  ngAfterViewChecked() {
    if (!this.startScrollPosSet) {
      this.startScrollPosSet = true;
      document.getElementById("scrollable").scrollTop =
        ((12 - this.starttime) / 3) * 100;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.spaceId != null) {
      if (this.getBookingsOnInit) this.GetBookings();
      if (this.useSpaceWorkngHours) this.GetWorkingHours();
    }
  }

  updateDuration(val) {
    this.eventDuration = 2 * val;
    if (this.workshopTimeAllocations != null)
      for (let timeslot of this.workshopTimeAllocations) {
        var startDT = new Date(timeslot.startTime);
        startDT.setHours(
          startDT.getHours(),
          startDT.getMinutes() + this.eventDuration * 30
        );
        timeslot.endTime = startDT.toLocaleString("en-US");
      }
    this.rebuildDraggables();
  }

  GetBookings() {
    this.bookingService
      .getBookingsForSpace(this.spaceId)
      .subscribe((success) => {
        if (success) {
          if (this.workshopBookingService.workshop != null) {
            this.workshopBookingService
              .getBookingsForWorkshop(this.workshopBookingService.workshop.id)
              .subscribe((success) => {
                if (success) {
                  this.Initialize();
                } else {
                  console.error("Error");
                }
              });
          } else {
            this.Initialize();
          }
        } else {
          console.error("Error");
        }
      });
  }

  GetWorkingHours() {
    this.spaceService.getWorkingHours(this.spaceId).subscribe((success) => {
      if (success) {
        this.workingHours = this.spaceService.workingHours;
        this.Initialize();
      } else {
        console.error("Error");
      }
    });
  }

  SetWorkingHours(dayIndex: number, startTime: TimeSpan, endTime: TimeSpan) {
    var startIndex = startTime.Hours * 2;
    if (startTime.Minutes > 1) startIndex++;
    var endIndex = endTime.Hours * 2;
    if (endTime.Minutes > 1) endIndex++;
    if (!this.markWorkingHours) {
      startIndex = 0;
      endIndex = 46;
    }
    for (var i = startIndex; i < endIndex; i++) {
      this.status[dayIndex][i] = 0;
    }
  }

  SetNonWorkingDay(dayIndex: number) {
    for (var i = 0; i < 46; i++) {
      this.status[dayIndex][i] = 1;
    }
  }

  public SetTimeAllocations(allocations: WorkshopTimeAllocation[]) {
    this.workshopTimeAllocations = allocations;
    this.Initialize();
  }

  Initialize(weekChanged: boolean = false) {
    var defaultCellState =
    (this.workingHours != null &&
      this.calendarMode !== "add-workshop-wizard") ||
    (this.useSpaceWorkngHours && this.calendarMode !== "add-workshop-wizard")
      ? 1
      : 3;

      if (!weekChanged) {
        this.status = new Array<Array<number>>();
        for (var d = 0; d < 7; d++) {
          var tempday2 = new Array<number>();
          for (var t = 0; t < 23; t++) {
            tempday2.push(defaultCellState);
            tempday2.push(defaultCellState);
          }
          this.status.push(tempday2);
        }
      // WAS THIS
      //for (let selection of this.GetAllVisibleSelections())
      //    this.selections.push(selection);
    }else if(weekChanged){
      for (let j = 0; j < this.weekAvailableTime.length; j++) {
        if (this.weekAvailableTime[j].flag) {
          const slot = this.weekAvailableTime[j];
          const [starthour, startminutes] = slot.Enter_FromTime.split(":");
          const [endhour, endminutes] = slot.Enter_ToTime.split(":");
          let sDay;
          let eDay;
          if (startminutes === "30") {
            sDay = Number(starthour) * 2 + 1;
          } else if (startminutes === "00") {
            sDay = Number(starthour) * 2;
          }

          if (endminutes === "30") {
            eDay = 46 - (Number(endhour) * 2 + 1);
          } else if (endminutes === "00") {
            eDay = 46 - Number(endhour) * 2;
          }

          if(eDay < 0) {
            eDay = 0
          }

          console.log("sday eday", sDay, eDay);

          for (let day = sDay; day < this.status[j].length - eDay; day++) {
            this.status[j][day] = 0;
          }
        } else {
          for (var t = 0; t < 46; t++) {
            this.status[j][t] = defaultCellState;
          }
        }
      }
    }

    this.bookingSegments = new Array<Array<BookingSegment>>();
    for (var d = 0; d < 7; d++) {
      var tempSegmentDay = new Array<BookingSegment>();
      for (var t = 0; t < 23; t++) {
        tempSegmentDay.push(null);
        tempSegmentDay.push(null);
      }
      this.bookingSegments.push(tempSegmentDay);
    }

   

    if (
      this.workingHours != null &&
      this.calendarMode !== "add-workshop-wizard"
    )
      for (var i = 0; i < 7; i++) {
        this.SetWorkingHours(
          i,
          this.workingHours.starttimes[i],
          this.workingHours.endtimes[i]
        );
        if (!this.workingHours.workingdays[i]) this.SetNonWorkingDay(i);
      }

    //console.log("BS (lol): " + JSON.stringify(this.bookingService.bookingSegments));
    this.MarkBookings();
    this.rebuildDraggables();
  }

  MarkBookings() {
    if (this.bookingService.bookingSegments != null)
      for (let bookingSegment of this.bookingService.bookingSegments) {
        var nextMonday = new Date(this.mondayDate);
        nextMonday.setDate(nextMonday.getDate() + 7);
        if (
          bookingSegment.startTime > this.mondayDate &&
          bookingSegment.startTime < nextMonday
        ) {
          var dayIndex = bookingSegment.startTime.getDay();
          dayIndex--;
          if (dayIndex < 0) dayIndex = 6;
          var timeIndex = bookingSegment.startTime.getHours() * 2;
          if (bookingSegment.startTime.getMinutes() > 0) timeIndex++;
          var count = bookingSegment.duration.Hours * 2;
          if (bookingSegment.duration.Minutes > 0) count++;
          for (var i = 0; i < count; i++) {
            this.status[dayIndex][i + timeIndex] = 2;
          }
          this.bookingSegments[dayIndex][timeIndex] = bookingSegment;
        }
      }
    //console.log("Ufff.." + JSON.stringify(this.workshopBookingService.existingWorkshopBookings));
    if (this.workshopBookingService.existingWorkshopBookings != null)
      for (let workshopBooking of this.workshopBookingService
        .existingWorkshopBookings) {
        if (
          workshopBooking.timeAllocation != null &&
          workshopBooking.timeAllocation.isCustom
        ) {
          var wsTimeAllocation = workshopBooking.timeAllocation;
          var wsStarTime = new Date(wsTimeAllocation.startTime);
          var wsEndTime = new Date(wsTimeAllocation.endTime);
          var wsDuration = TimeSpan.Diff(wsStarTime, wsEndTime);
          var nextMonday = new Date(this.mondayDate);
          nextMonday.setDate(nextMonday.getDate() + 7);
          if (wsStarTime > this.mondayDate && wsStarTime < nextMonday) {
            var dayIndex = wsStarTime.getDay();
            dayIndex--;
            if (dayIndex < 0) dayIndex = 6;
            var timeIndex = wsStarTime.getHours() * 2;
            if (wsStarTime.getMinutes() > 0) timeIndex++;
            var count = wsDuration.Hours * 2;
            if (wsDuration.Minutes > 0) count++;
            for (var i = 0; i < count; i++) {
              this.status[dayIndex][i + timeIndex] = 1;
            }
            //this.bookingSegments[dayIndex][timeIndex] = wsTimeAllocation;
          }
        }
      }
  }

  NextWeek() {
    if (!this.isDayCalendar || this.dayIndex == 6) {
      this.mondayDate.setDate(this.mondayDate.getDate() + 7);
      this.dayIndex = 0;
      this.Initialize(true);
    } else {
      this.dayIndex++;
    }
  }

  PreviousWeek() {
    if (!this.isDayCalendar || this.dayIndex == 0) {
      this.mondayDate.setDate(this.mondayDate.getDate() - 7);
      this.dayIndex = 6;
      this.Initialize(true);
    } else {
      this.dayIndex--;
    }
  }

  GetCaption(): string {
    var monthIndex = this.mondayDate.getMonth();
    if (this.isDayCalendar) {
      var date = new Date(this.mondayDate);
      date.setDate(date.getDate() + this.dayIndex);
      monthIndex = date.getMonth();
    }
    return this.monthNames[monthIndex] + " " + this.mondayDate.getFullYear();
  }

  OnDown(dayIndex: number, timeIndex: number, event): void {
    if (this.calendarMode == "add-workshop-wizard") {
      let timeOffset = -1;
      let foundEmptySlot = false;

      while (timeOffset < this.eventDuration - 1 && !foundEmptySlot) {
        timeOffset++;
        foundEmptySlot = this.isAvailable(
          dayIndex,
          timeIndex - timeOffset,
          this.eventDuration
        );
      }

      // foundEmptySlot = true;
      console.log('found empty slot',foundEmptySlot);
      

      if (foundEmptySlot) {
        this.mousedown = true;
        var workshopTimeAllocation = new WorkshopTimeAllocation();

        workshopTimeAllocation.startTime = this.GetDateForSlot(
          dayIndex,
          timeIndex - timeOffset
        ).toLocaleString("en-US");
        workshopTimeAllocation.endTime = this.GetDateForSlot(
          dayIndex,
          timeIndex - timeOffset + this.eventDuration
        ).toLocaleString("en-US");
        this.workshopTimeAllocations.push(workshopTimeAllocation);
        this.createDraggableForTimeAllocation(workshopTimeAllocation);
        console.log("draggables",this.draggables);
      }
    }
    if (this.calendarMode == "custom-booking-modal") {
      var timeOffset = -1;
      var foundEmptySlot = false;
      while (timeOffset < this.eventDuration - 1 && !foundEmptySlot) {
        timeOffset++;
        foundEmptySlot = this.isAvailable(
          dayIndex,
          timeIndex - timeOffset,
          this.eventDuration
        );
      }
      if (foundEmptySlot) {
        this.mousedown = true;
        var workshopTimeAllocation = new WorkshopTimeAllocation();

        workshopTimeAllocation.startTime = this.GetDateForSlot(
          dayIndex,
          timeIndex - timeOffset
        ).toLocaleString("en-US");
        workshopTimeAllocation.endTime = this.GetDateForSlot(
          dayIndex,
          timeIndex - timeOffset + this.eventDuration
        ).toLocaleString("en-US");
        //console.log("START @ " + workshopTimeAllocation.startTime);
        if (this.customDraggable != null) {
          this.customDraggable.delete();
        }
        this.customTimeAllocation = workshopTimeAllocation;
        this.customDraggable = this.createDraggableForTimeAllocation(
          workshopTimeAllocation
        );
        this.customDraggable.isCustom = true;
        this.selectDraggable(this.customDraggable);
      }
    }
  }

  //ovo za startTimeIncluded je odvratno
  setSlots(
    dayIndex: number,
    timeIndex: number,
    duration: number,
    occupied: boolean
  ) {
    for (var i = 0; i < duration; i++) {
      //console.log("D" + dayIndex + " T" + (timeIndex + i) + "=" + occupied);
      this.status[dayIndex][timeIndex + i] = occupied;
    }
  }

  createDraggableForTimeAllocation(
    allocation: WorkshopTimeAllocation
  ): Draggable {
    var startTime = new Date(allocation.startTime);
    var endTime = new Date(allocation.endTime);
    var duration =
      (endTime.getHours() - startTime.getHours()) * 2 +
      (endTime.getMinutes() - startTime.getMinutes()) / 30;
    var nextMonday = new Date(this.mondayDate);
    nextMonday.setDate(nextMonday.getDate() + 7);
    if (
      (startTime > this.mondayDate || allocation.isRecurring) &&
      startTime < nextMonday
    ) {
      var dayIndex = startTime.getDay();
      dayIndex--;
      if (dayIndex < 0) dayIndex = 6;
      var timeIndex = startTime.getHours() * 2 - this.starttime;
      if (startTime.getMinutes() > 0) timeIndex++;

      var actualStartDate = this.GetDateForSlot(
        dayIndex,
        timeIndex + this.starttime
      );
      var actualEndDate = this.GetDateForSlot(
        dayIndex,
        timeIndex + duration + this.starttime
      );
      var actualAllocation = new WsBookingTimeAllocation();
      actualAllocation.startTime = actualStartDate.toLocaleString("en-US");
      actualAllocation.endTime = actualEndDate.toLocaleString("en-US");

      var newDraggable = new Draggable(dayIndex, timeIndex, duration, this);
      this.draggables.push(newDraggable);
      newDraggable.timeAllocation = allocation;
      newDraggable.availableSeats =
        this.workshopBookingService.GetAvailableSlotsForAllocation(
          actualAllocation
        );
      if (!newDraggable.invalidInstance)
        this.setSlots(dayIndex, timeIndex + this.starttime, duration, true);
      return newDraggable;
    }
  }

  rebuildDraggables() {
    this.draggables = [];
    if (this.workshopTimeAllocations != null)
      for (var i = 0; i < this.workshopTimeAllocations.length; i++) {
        this.createDraggableForTimeAllocation(this.workshopTimeAllocations[i]);
      }
    this.customDraggable = null;
    if (this.customTimeAllocation != null) {
      this.customDraggable = this.createDraggableForTimeAllocation(
        this.customTimeAllocation
      );
      this.customDraggable.isCustom = true;
    }
  }

  deleteDraggable(draggable: Draggable) {
    var timeslotIndex = this.workshopTimeAllocations.indexOf(
      draggable.timeAllocation
    );
    if (timeslotIndex >= 0)
      this.workshopTimeAllocations.splice(timeslotIndex, 1);
    this.draggables.splice(this.draggables.indexOf(draggable), 1);
  }

  selectDraggable(draggable: Draggable) {
    if (draggable != this.customDraggable) {
      if (this.customDraggable != null) {
        this.customDraggable.delete();
        this.customDraggable = null;
      }
      this.customTimeAllocation = null;
    }
    if (draggable.isBookable) {
      var allocation = new WsBookingTimeAllocation();
      var startTime = draggable.occurenceDate;
      var endTime: Date;
      endTime = new Date(startTime);
      var hours = startTime.getHours() + Math.floor(draggable.duration / 2);
      var minutes =
        startTime.getMinutes() + ((draggable.duration / 2) % 1) * 30;
      while (minutes >= 60) {
        minutes -= 60;
        hours++;
      }
      endTime.setHours(hours);
      endTime.setMinutes(minutes);

      allocation.allocationType = 1;
      allocation.startTime = startTime.toLocaleString("en-US");
      allocation.endTime = endTime.toLocaleString("en-US");
      allocation.isCustom = this.customTimeAllocation != null;

      this.currentSelectedTimeAllocation = allocation;
    }
    //console.log(draggable.occurenceDate.toString());
  }

  GetCurrentSelectedTimeAllocation(): WsBookingTimeAllocation {
    return this.currentSelectedTimeAllocation;
  }

  SetSelectedTimeAllocation(allocation: WsBookingTimeAllocation) {
    if (allocation != null && allocation.isCustom) {
      this.customTimeAllocation = allocation as any as WorkshopTimeAllocation;
    }
    this.currentSelectedTimeAllocation = allocation;
    this.Initialize(true);
  }

  GetDayName(index: number) {
    var env = this.findBootstrapEnvironment();
    if (env == "xs") return this.weekdaysLetters[index];
    if (env == "sm" || env == "md") return this.weekdaysAbrev[index];
    return this.weekdays[index];
  }

  GetDate(index: number) {
    var env = this.findBootstrapEnvironment();
    var date = new Date(this.mondayDate);
    date.setDate(date.getDate() + index);
    if (env == "sm" || env == "md"|| env == 'lg')
      return this.monthAbbrevs[date.getMonth()] + " " + date.getDate();
    else if (env == "xs") return date.getDate();
    else return this.monthNames[date.getMonth()] + " " + date.getDate();
  }

  GetDateHeaderColor(index: number) {
    var date = new Date(this.mondayDate);
    date.setDate(date.getDate() + index);
    if (date < this.GetDateToday()) return "#c5c5d1";
    return "#ffffff";
  }

  findBootstrapEnvironment(): string {
    let envs = ["xs", "sm", "md", "lg", "xl"];

    let el = document.createElement("div");
    document.body.appendChild(el);

    let curEnv = envs.shift();

    for (let env of envs.reverse()) {
      el.classList.add(`d-${env}-none`);

      if (window.getComputedStyle(el).display === "none") {
        curEnv = env;
        break;
      }
    }

    document.body.removeChild(el);
    return curEnv;
  }

  GetColor(dayIndex: number, timeIndex: number): string {
    if (
      this.GetDateForSlot(dayIndex, timeIndex) < this.GetDateToday() &&
      this.calendarMode !== "add-workshop-wizard"
    ) {
      if (timeIndex % 2 != 0) return "#bab9c7"; //some kind of shade white;
      return "#c5c5d1"; //some kind of shade white same as bab9c7;
    }
    var result = "";
    if (this.status[dayIndex][timeIndex] == 1) result = "#b28097"; //light pink;
    else if (this.status[dayIndex][timeIndex] == 2) {
      if (this.calendarMode == "add-workshop-wizard")
        result = "#a02c1c"; //brown
      else result = "#02d13c"; // green
    } else if (this.status[dayIndex][timeIndex] === 3) {
      result = "#b28097";
    } else result = "#F0F2F5"; //white

    if (timeIndex % 2 == 0) {
      if (result == "#F0F2F5") result = "#F9FAFB"; //white
      else result += "EE";
    }
    return result;
  }

  GetDateToday(): Date {
    var date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  GetDateForSlot(dayIndex: number, timeIndex: number): Date {
    var hours = Math.floor(timeIndex / 2);
    var minutes = (timeIndex % 2) * 30;
    var result = new Date(
      this.mondayDate.getFullYear(),
      this.mondayDate.getMonth(),
      this.mondayDate.getDate() + dayIndex,
      hours,
      minutes
    );
    return result;
  }

  testDateSlot(dayIndex: number, timeIndex: number): Date {
    console.log("test date slot", dayIndex, timeIndex);
    var hours = Math.floor(timeIndex / 2);
    var minutes = (timeIndex % 2) * 30;
    var result = new Date(
      this.mondayDate.getFullYear(),
      this.mondayDate.getMonth(),
      this.mondayDate.getDate() + dayIndex,
      hours,
      minutes
    );
    console.log("result", result);
    return result;
  }

  LogAllSelections() {
    console.log("SELECTED CRVITCI:");
    // WAS THIS
    /*
        for (let c of this.GetAllVisibleSelections() ) {
            console.log(c.startTime.toLocaleString('en-US') + " FOR " + c.duration.HoursMinutesString);
        }
        for (let c of this.selections) {
            console.log(c.startTime.toLocaleString('en-US') + " FOR " + c.duration.HoursMinutesString);
        } */
  }

  lol() {
    console.log("lol;");
  }
  /////

  MouseDownTable(e) {
    if (e.touches != null) {
      this.mouseX = e.touches[0].pageX;
      this.mouseY = e.touches[0].pageY;
    }
    var target;
    if (e.center == null) {
      if (e.touches != null)
        target = document.elementFromPoint(
          e.touches[0].clientX,
          e.touches[0].clientY
        ).id;
      else target = document.elementFromPoint(e.clientX, e.clientY).id;
    } else {
      if (isNaN(e.center.x) || isNaN(e.center.y)) return;

      target = document.elementFromPoint(e.center.x, e.center.y).id;
    }
    var strd = target.indexOf("d");

    var timeIndex = parseInt(target.substring(1, strd));
    var dayIndex = parseInt(target.substring(strd + 1, target.length));
    this.OnDown(dayIndex, timeIndex, null);

    this.lastCellX = dayIndex;
    this.lastCellY = timeIndex;
  }

  formatTimeText(time: string) {
    if (this.env == "xs") return time.replace(":", "\n");
    return time;
  }

  panStart(ev) {
    ev.preventDefault();
    if (!this.mousedown) {
      this.scrolling = false;
      this.scrollPending = true;
      this.startTouchDist = 0;
      this.scrollStartTop = this.tableRef.nativeElement.scrollTop;
      this.dragStartX = ev.touches[0].clientX;
      this.dragStartY = ev.touches[0].clientY;
      window.setTimeout(() => {
        if (this.scrollPending) {
          this.scrollPending = false;
          if (this.startTouchDist < this.maxDragDist) this.MouseDownTable(ev);
          else this.scrolling = true;
        }
      }, 100);
    }
  }

  /////
}

class Draggable {
  duration: number; // duration expressed in cell count
  get label(): string {
    return (
      this.tableRef.times[this.tempTableY + this.tableRef.starttime] +
      " - " +
      this.tableRef.times[
        this.tempTableY + this.tableRef.starttime + this.duration
      ]
    );
  }

  get originalOcurrenceDate(): string {
    var startDate = new Date(this.timeAllocation.startTime);
    return startDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  }

  get occurenceDate(): Date {
    return this.tableRef.GetDateForSlot(
      this.tableX,
      this.tableY + this.tableRef.starttime
    );
  }

  isCustom: boolean;
  tableRef: DraggableCalendarView;
  availableSeats: number;
  tableX: number;
  tableY: number;
  isDragged: boolean;
  invalidInstance: boolean;
  timeAllocation: WorkshopTimeAllocation;

  get positionX(): number {
    return this.tableX * this.tableRef.rowWidth + this.tempX;
  }

  get positionY(): number {
    return this.tableY * this.tableRef.rowHeight + this.tempY;
  }

  get xposition():number {
    return this.tableX * this.tableRef.rowWidth + this.tempX +10;
  }

  get tempX(): number {
    if (!this.isDragged) return 0;
    return this.tableRef.mouseX - this.tableRef.dragStartMouseX;
  }

  get tempY(): number {
    if (!this.isDragged) return 0;
    return this.tableRef.mouseY - this.tableRef.dragStartMouseY;
  }

  get tempTableX(): number {
    return this.tableX + Math.round(this.tempX / this.tableRef.rowWidth);
  }

  get tempTableY(): number {
    return this.tableY + Math.round(this.tempY / this.tableRef.rowHeight);
  }

  get color(): string {
    if (
      this.tableRef.calendarMode == "booking-modal" ||
      this.tableRef.calendarMode == "custom-booking-modal"
    ) {
      if (this.isDragged) {
        if (this.validTempPosition) return "yellow";
        return "red";
      }
      if (this.isSelected) return "#f7d720";

      if (this.isPast) return "#bab9c7";

      if (!this.isBookable) return "#e3526c";
    }
    if (!this.isDragged) {
      if (this.invalidInstance) return "#e3526c";
      else return "#5ee682"; //#60db81
    }

    if (this.validTempPosition) return "yellow";
    return "red";
  }

  constructor(
    tableX: number,
    tableY: number,
    duration: number,
    tableRef: DraggableCalendarView
  ) {
    this.duration = duration;
    this.tableX = tableX;
    this.tableY = tableY;
    this.tableRef = tableRef;
    var names = ["Luk", "Dimitr", "Rus", "Anuk", "Aleks", "Senz", "SevnN"];
    this.invalidInstance = !this.validSpawnPosition;
  }

  snap() {
    if (
      this.tableRef.isAvailable(
        this.tempTableX,
        this.tempTableY + this.tableRef.starttime,
        this.duration
      )
    ) {
      this.tableX = this.tempTableX;
      this.tableY = this.tempTableY;
      this.timeAllocation.startTime = this.tableRef
        .GetDateForSlot(this.tableX, this.tableY + this.tableRef.starttime)
        .toLocaleString("en-US");
      this.timeAllocation.endTime = this.tableRef
        .GetDateForSlot(
          this.tableX,
          this.tableY + this.tableRef.starttime + this.duration
        )
        .toLocaleString("en-US");
      this.invalidInstance = false;
      this.tableRef.setSlots(
        this.tableX,
        this.tableY + this.tableRef.starttime,
        this.duration,
        true
      );
    } else if (!this.invalidInstance) {
      this.tableRef.setSlots(
        this.tableX,
        this.tableY + this.tableRef.starttime,
        this.duration,
        true
      );
    }
  }

  get validSpawnPosition(): boolean {
    return this.tableRef.isAvailable(
      this.tableX,
      this.tableY + this.tableRef.starttime,
      this.duration
    );
  }

  get validTempPosition(): boolean {
    return this.tableRef.isAvailable(
      this.tempTableX,
      this.tempTableY + this.tableRef.starttime,
      this.duration
    );
  }

  dragStart(ev) {
    if (ev.touches != null) {
      this.tableRef.mouseX = ev.touches[0].pageX;
      this.tableRef.mouseY = ev.touches[0].pageY;
    }
    if (ev.touches != null) {
      ev.preventDefault();
    }
    if (!this.invalidInstance)
      this.tableRef.setSlots(
        this.tableX,
        this.tableY + this.tableRef.starttime,
        this.duration,
        false
      );
    this.tableRef.dragStartMouseX = this.tableRef.mouseX;
    this.tableRef.dragStartMouseY = this.tableRef.mouseY;
    this.tableRef.currentDragged = this;
    this.isDragged = true;
  }

  endDrag() {
    this.snap();
    this.isDragged = false;
    this.tableRef.currentDragged = null;
    if (this.isCustom) this.tableRef.selectDraggable(this);
  }

  delete(ev = null) {
    if (ev != null) ev.preventDefault();
    this.tableRef.setSlots(
      this.tableX,
      this.tableY + this.tableRef.starttime,
      this.duration,
      false
    );
    this.tableRef.deleteDraggable(this);
  }

  get isBookable(): boolean {
    if (this.availableSeats <= 0) return false;
    if (this.isPast) return false;
    if (this.tableRef.workshopBookingService.bookingOptions == null)
      return true;
    if (this.tableRef.workshopBookingService.bookingOptions.attendees == null)
      return true;
    return (
      this.availableSeats >=
      this.tableRef.workshopBookingService.bookingOptions.attendees
    );
  }

  get isSelected(): boolean {
    if (this.tableRef.currentSelectedTimeAllocation == null) return false;
    return (
      new Date(
        this.tableRef.currentSelectedTimeAllocation.startTime
      ).toLocaleString("en-US") == this.occurenceDate.toLocaleString("en-US")
    );
  }

  get isPast(): boolean {
    return this.occurenceDate <= new Date();
  }
}
