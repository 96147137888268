import { Injectable } from '@angular/core';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { forkJoin } from 'rxjs';


@Injectable({
  providedIn: 'root',
})

export class AppInitService {

  constructor(
    public spaceService: SpaceService,
  ) { }

  Init() {


    return new Promise<void>((resolve, reject) => {

      forkJoin([
        this.spaceService.getSpaceTypes(),
        this.spaceService.getAmenityTypes(),
        this.spaceService.getSittingTypes(),
        this.spaceService.getPricingRuleTypes(),
        this.spaceService.getPerfectSpaces(),
      ]).subscribe(data => {
        resolve();
      }, (error) => {
        console.log(error.error);
      });
      // resolve();
    });
  }
}

