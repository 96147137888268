import { Component, ViewChild } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { WorkshopService } from "app/modules/shared/services/workshop.service";

@Component({
    selector: 'clickable-workshop-name',
    templateUrl: 'clickable-workshop-name.component.html'
})
export class ClickableWorkshopName implements ICellRendererAngularComp {
    public params: any;

    constructor(public workshopService: WorkshopService)
    { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }
}