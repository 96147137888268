import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { EbentoNews } from "../models/misc/ebento-news";

@Injectable()
export class NewsService {

    public news: EbentoNews[];
    public openNews: EbentoNews;

    constructor(private http: HttpClient) {
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error.message || "Server error");
    }

    getNews(): Observable<EbentoNews[]> {

        return this.http.get<EbentoNews[]>(
            "api/getnews")
            .pipe(
            map(success => { 
                this.news = success;
                return success; }),
                catchError(this.errorHandler)
            );
    }
}