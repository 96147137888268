import { Component, OnInit } from '@angular/core';
import { AboutDTO } from '../../../../models/simple-pages/about-dto';
import { SimplePagesService } from '../../../../services/simple-pages.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

    model: AboutDTO;
    imageUrl: string = '';
    text: string = '';

    constructor(private simplePagesService: SimplePagesService) { }

    ngOnInit() {
        this.simplePagesService.getAboutPageData().subscribe(
            res => {
                if (res != null) {
                    this.model = res;
                    this.text = this.model.body;
                    this.imageUrl = this.model.imageUrl;
                }
                else {
                    this.model = new AboutDTO()
                }
            }
        )
    }
    
}
