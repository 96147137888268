/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input, Injector } from '@angular/core';
import { IndexCarousel } from '../index-carousel.component';
import { FavoritesService } from 'app/modules/event-planner/services/favorites.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { FavoriteSpaceCarouselTile } from '../../carousel-tile/favorite-space-carousel-tile/favorite-space-carousel-tile.component';

@Component({
    selector: 'favorite-spaces',
    templateUrl: '../index-carousel.component.html'
})


export class FavoriteSpaces extends IndexCarousel implements OnInit {

    @Input() color:string = '#212529';
    @Input() headerLabel = 'Favorite Spaces';
    protected tileComponent = FavoriteSpaceCarouselTile;
    domID = 'favorite-sp-';

    constructor(private favoriteService: FavoritesService, public authService: AuthService, injector: Injector) {
        super(injector);
    }

    get apiGetter() {
        return this.favoriteService.getFavoriteSpaces(this.authService.user.id);
    }
}
