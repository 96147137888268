import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SpaceBooking } from 'app/modules/shared/models/booking/space-booking';
import { Amenity } from 'app/modules/shared/models/properties/Amenity';
import { EbentoSpace } from 'app/modules/shared/models/properties/ebento-space';
import { SpaceForTile } from 'app/modules/shared/models/search/space-for-tile';
import { SpaceService } from 'app/modules/shared/services/space.service';
import { SearchService } from '../../services/search.service';
import { SpaceBookingService } from 'app/modules/shared/services/space-booking.service';
import { ReviewService } from 'app/modules/shared/services/review.service';
import { MessagingService } from 'app/modules/shared/services/messaging.service';
import { AuthService } from 'app/modules/shared/services/auth.service';
import { ConfirmationModalsComponent } from 'app/modules/shared/modules/modals/components/confirmation-modals/confirmation-modals.component';
import { PropertyManagerReview } from 'app/modules/shared/models/users/property-manager-review';
import { EbentoMessage } from 'app/modules/shared/models/messaging/ebento-message';
import { CancellationRule } from '../../../shared/models/properties/cancellation-rule';
import { WorkshopBookingService } from '../../../shared/services/workshop-booking.service';
import { EbentoModalService } from '../../../shared/services/ebento-modal.service';
import { PropertyService } from '../../../shared/services/property.service';

@Component({
    selector: 'reservation-tile',
    templateUrl: './reservation-tile.component.html',
    styleUrls: ['./reservation-tile.component.scss']
})
export class ReservationTileComponent implements OnInit {

    @Input() booking: SpaceBooking
    cancellationRules: CancellationRule[]

    viewDetails:boolean = false;
    @Input() isActive: boolean;
    @Output() bookingCancelled = new EventEmitter<boolean>();
    @Input() history:string='false';

    public bookingAmenities: Amenity[] = [];
    public cateringAmenities: Amenity[] = [];

    
    public space: EbentoSpace
    public spaceForTile: SpaceForTile;

    constructor(
        public spaceService: SpaceService,
        public propertyService: PropertyService,
        public bookingService: SpaceBookingService,
        private reviewService: ReviewService,
        public router: Router,
        private modalService: EbentoModalService,
        public messagingService: MessagingService,
        public authService: AuthService,
        public workshopBookingService: WorkshopBookingService
    ) { }

    ngOnInit() {
        this.spaceService.getSpace(this.booking.spaceID).subscribe(
            s => {                
                this.space = s
                this.populateAmenities();
            },
            err => console.error(err)
        );

        this.bookingService.getHistoryCancellationRules(this.booking.idBooking).subscribe(
            res => this.cancellationRules = res,
            err => console.error(err)
        )        
    }

    viewMoreDetails(){
        this.viewDetails = !this.viewDetails;
    }

    //TODO: make full bookingAmenity that extends Amenity?
    private populateAmenities(): void {
        this.bookingAmenities = []
        for (let amenity of this.space.amenities) {
            for (let bookingAmenity of this.booking.bookingAmenities) {
                if (amenity.amenitytype.id == bookingAmenity.amenityTypeID) {
                    if(amenity.amenitytype.isCatering){
                        this.cateringAmenities.push(amenity);
                    }else{
                        this.bookingAmenities.push(amenity)
                    }
                }
            }
        }

        // console.log("space tile booking amenities",this.bookingAmenities,this.history);
        
    }

    public bookingDetails(): void
    {
        this.router.navigate(["/booking-details/" + this.booking.idBooking])
    }


    public modifyBooking(): void
    {
        this.router.navigate(["/event-planner/modify-booking/" + this.booking.idBooking])
    }

    public viewPendingModification(): void {
        this.router.navigate(["/event-planner/pending-modify-request/" + this.booking.pendingModificationID]);
    }


    public cancelBooking(): void {
        let startTime = new Date(this.booking.timeAllocations[0].startTime).getTime()
        let dateNow = Date.now()
        let msDelta = startTime - dateNow
        const msInDay = 1000 * 60 * 60 * 24

        let percentageToReturn = 100

        if (this.cancellationRules && this.cancellationRules.length > 0) {
            var lastCR = this.cancellationRules[this.cancellationRules.length - 1]
            if (lastCR.daysFrom * msInDay > msDelta) {
                //if last cr < deltaTime take its percentag
                for (var i = this.cancellationRules.length - 1; i >= 0; i--) {
                    let cr = this.cancellationRules[i]
                    if (cr.daysTo * msInDay < msDelta) {
                        percentageToReturn = cr.percentageToReturn;
                        break;
                    }
                }
            }
        }
        let returnAmount = this.booking.price.totalWithDiscount * percentageToReturn / 100;
        // console.log('return amount is: ', returnAmount)

        const modalRef = this.modalService.open(ConfirmationModalsComponent);
        modalRef.componentInstance.title = 'Confirm cancellation';

        modalRef.componentInstance.body =
            'Are you sure you want to cancel '
            + this.booking.spaceName + '. '
            + 'If you cancel this reservation you will be charged for amount of '
            + returnAmount + ' CHF';
        modalRef.componentInstance.actionName = 'Cancel Booking';

        modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            if (receivedEntry == 'confirmed') {
                this.bookingService.cancelBooking(this.booking.idBooking).subscribe(
                    res => {
                        if (res) {
                            this.bookingCancelled.emit(true);
                        }
                    }
                )
                this.modalService.close();
            };
        })
    }

    leaveReview() {
        // this is a bit ugly. refactor?
        this.reviewService.workshop = null;
        this.reviewService.space = this.space;

        this.reviewService.propertyManagerReview = new PropertyManagerReview();
        this.reviewService.propertyManagerReview.propertyManagerID = this.space.propertyManager.id;
        this.reviewService.propertyManagerReview.booking = this.booking;
        this.router.navigate(['review-property-manager']);
    }

    sendMessage() {
        this.authService.getManagerInfoByID(this.booking.propertyManagerID).subscribe(
            res => this.messagingService.currentRecepient = res);
        this.messagingService.currentDraft = new EbentoMessage();
        this.router.navigate(['/messages/compose']);
    }

    redirectToPropertyManager() {
        this.propertyService.getProperty(this.booking.propertyID)
            .subscribe(
                () => this.router.navigate(['/property-details']));
    }

    twoDigits(a) {
        return Math.round(parseFloat(a) * 100) / 100;
    }
}
